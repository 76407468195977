import { createAsyncAction } from 'typesafe-actions';

import { Data as VendorDetailData } from '../../types/VendorDetailResponse';

export interface VendorDetailPayload {
  failure: () => void;
  id: string;
  success?: (VendorDetailData) => void;
}

export interface VendorDetailSuccessPayload {
  data: VendorDetailData;
  id: string;
}

export interface VendorDetailFailPayload {
  customErrorHandling?: boolean;
  error: Error;
  id: string;
  requestAction: ReturnType<typeof getVendorDetailActions.request>;
}

export const getVendorDetailActions = createAsyncAction(
  'verybuy/GET_VENDOR_DETAIL',
  'verybuy/GET_VENDOR_DETAIL_SUCCESS',
  'verybuy/GET_VENDOR_DETAIL_FAIL'
)<VendorDetailPayload, VendorDetailSuccessPayload, VendorDetailFailPayload>();
