import { translate } from '../../../../../../i18n';
import { KeywordsSuggestion } from '../../../../../../types/SuggestionResponse';
import KeywordItem from '../KeywordItem';
import S from './styles';

export const MAX_SUGGESTION_KEYWORDS = 10;

interface Props {
  submitKeyword: (term: string) => void;
  keywords?: KeywordsSuggestion[];
}

export default ({ keywords = [], submitKeyword }: Props) => {
  if (keywords.length === 0) {
    return null;
  }

  return (
    <>
      <S.Title>{translate('keywords')}</S.Title>
      {keywords.slice(0, MAX_SUGGESTION_KEYWORDS).map((item, index) => {
        return (
          <KeywordItem
            key={`${item.title}-${index}`}
            data={item}
            submitKeyword={submitKeyword}
          />
        );
      })}
    </>
  );
};
