export const getDeferredAppLink = () => {
  return Promise.resolve(null);
};

export const Settings = {
  getAdvertiserTrackingEnabled(): Promise<boolean> {
    return Promise.resolve(true);
  },
  setAdvertiserTrackingEnabled(_ATE: boolean): Promise<boolean> {
    return Promise.resolve(false);
  },
  setDataProcessingOptions() {
    // Not implemented
  },
  initializeSDK() {
    // Not implemented
  },
};

export const AccessToken = {};

export const LoginManager = {};

export const AppEventsLogger = {};

export const AEMReporter = {};
