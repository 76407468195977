import { createAsyncAction } from 'typesafe-actions';

import type { DeliveryData } from '../../types/DeliveryData';

export interface LoadDeliveryDataPayload {}

export interface LoadDeliveryDataSuccessPayload {
  data: DeliveryData;
}

export interface LoadDeliveryDataFailPayload {
  error: { data: { reason: string } };
}

export const loadDeliveryData = createAsyncAction(
  'verybuy/LOAD_DELIVERY_DATA',
  'verybuy/LOAD_DELIVERY_DATA_SUCCESS',
  'verybuy/LOAD_DELIVERY_DATA_FAIL'
)<
  LoadDeliveryDataPayload,
  LoadDeliveryDataSuccessPayload,
  LoadDeliveryDataFailPayload
>();
