import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { CLOSE_FLOAT_ICON } from '../../constants/guideKeys';
import { actionsList } from '../../redux/rootAction';
import { makeHasReadGuideByKey } from '../../redux/selectors/tutorialGuideSelector';
import FloatActionButton from './FloatActionButton';

const { setGuideStatus, logAnalyticsEvent } = actionsList;

const mapStateToProps = () => {
  const selector = createStructuredSelector({
    hasClosedFloatIcon: makeHasReadGuideByKey(CLOSE_FLOAT_ICON, 1),
  });

  return state => selector(state);
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logEvent: logAnalyticsEvent,
      setGuideStatus,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FloatActionButton);
