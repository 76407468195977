import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';

export interface CancelTransactionPayload {
  transactionID: string;
  loadingType: ListLoadingTypes;
  failure?: Function;
}

export interface CancelTransactionSuccessPayload {
  transactionID: string;
}

export interface CancelTransactionFailPayload {
  transactionID: string;
  error: Error;
}

export const patchCancelTransactionActions = createAsyncAction(
  'verybuy/PATCH_CANCEL_TRANSACTION',
  'verybuy/PATCH_CANCEL_TRANSACTION_SUCCESS',
  'verybuy/PATCH_CANCEL_TRANSACTION_FAIL'
)<
  CancelTransactionPayload,
  CancelTransactionSuccessPayload,
  CancelTransactionFailPayload
>();
