import { createAsyncAction } from 'typesafe-actions';

import { MemberInfo } from '../../types/MemberInfo';

export interface MemberInfoSuccessPayload {
  data: MemberInfo;
}

export interface MemberInfoFailPayload {
  error: Error;
  customErrorHandling: boolean;
}

export const getMemberInfoActions = createAsyncAction(
  'verybuy/GET_MEMBER_INFO',
  'verybuy/GET_MEMBER_INFO_SUCCESS',
  'verybuy/GET_MEMBER_INFO_FAIL'
)<undefined, MemberInfoSuccessPayload, MemberInfoFailPayload>();
