import { createSelector } from 'reselect';

import isExists from '../../utils/isExists';
import { State } from '../reducers/cartContentReducer';
import { RootState } from '../rootReducer';

const cartContentReducer = (state: RootState) => state.cartContentReducer;

export const cartContentLoadingSelector = () =>
  createSelector(cartContentReducer, data => {
    const isLoading = data?.isLoading;

    return isExists(isLoading) ? isLoading : true;
  });

export const cartContentAttrSelector = (attr: keyof State) =>
  createSelector(cartContentReducer, data => {
    return data?.[attr];
  });

export const cartContentItemsAttrSelector = () =>
  createSelector(cartContentAttrSelector('cartContent'), data => {
    const cartContent = data || {};

    return Object.keys(cartContent).map(item => ({
      content_id: cartContent[item].marketing_product_id,
      price: cartContent[item].price,
      quantity: cartContent[item].amount,
    }));
  });

export const cartContentItemAttrSelector = (
  vid: string,
  attr: string,
  innerAttr?: string
) =>
  createSelector(
    cartContentAttrSelector('cartContent'),
    cartContentAttrSelector('outOfStock'),
    (cartContent = {}, outOfStock = {}) => {
      const item = cartContent[vid] || outOfStock[vid];

      return innerAttr ? item?.[attr]?.[innerAttr] : item?.[attr];
    }
  );

export const cartContentIdsSelector = (
  itemStateGroup: 'cartContent' | 'outOfStock' = 'cartContent'
) =>
  createSelector(cartContentAttrSelector(itemStateGroup), data => {
    const cartContent = data || {};
    const cartIds = Object.keys(cartContent);
    const result = cartIds.map(
      id => `${cartContent[id]['user_submit_id']}-${id}`
    );

    return result;
  });

export const makeCartContentVidBySku = (sizeText: string, colorText: string) =>
  createSelector(cartContentAttrSelector('cartContent'), (data = {}) => {
    return (
      Object.values(data).find(
        item => item.size_text === sizeText && item.color_text === colorText
      )?.virtual_product_id || ''
    );
  });

export const cartContentHasDataSelector = () =>
  createSelector(cartContentAttrSelector('cartContent'), (data = {}) => {
    return Object.keys(data).length > 0;
  });

export const outOfStockHasDataSelector = () =>
  createSelector(cartContentAttrSelector('outOfStock'), (data = {}) => {
    return Object.keys(data).length > 0;
  });

export const cartContentQuantitySelector = () =>
  createSelector(cartContentReducer, data => {
    const cartContent = data?.cartContent || {};

    return Object.keys(cartContent).reduce((result, vid) => {
      const item = cartContent?.[vid];
      const amount =
        item && item.isChecked !== false && item.amount ? item.amount : 0;

      // because amount is defined in string
      return result + +amount;
    }, 0);
  });

export const cartContentTotalQuantitySelector = () =>
  createSelector(cartContentReducer, data => {
    const cartContent = data?.cartContent;

    if (!isExists(cartContent) || Object.keys(cartContent).length === 0) {
      return 0;
    }

    return Object.values(cartContent).reduce(
      (prev, curr: { amount?: number }) => {
        return isNaN(Number(curr.amount))
          ? Number(prev)
          : Number(prev) + Number(curr.amount);
      },
      0 // initial value
    );
  });

export const cartContentAllCheckedSelector = () =>
  createSelector(cartContentReducer, data => {
    const cartContent = data?.cartContent || {};
    const keys = Object.keys(cartContent);

    if (keys.length < 1) {
      return true;
    }

    return !keys.some(item => cartContent[item].isChecked === false);
  });

export const getCheckedItems = () =>
  createSelector(cartContentReducer, data => {
    const cartContent = data?.cartContent || {};
    const result = [] as string[];

    Object.keys(cartContent).forEach(key => {
      if (cartContent[key].isChecked !== false) {
        result.push(key);
      }
    });

    return result;
  });
