import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { CouponResponse } from '../../types/Coupon';
import { getCouponListActions } from '../actions/getCouponListActions';
import { getCouponList } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';
import { getCheckedItems } from '../selectors/cartContentSelector';

type RequestActionTypes = ActionType<typeof getCouponListActions.request>;

const outputActions = {
  success: getCouponListActions.success,
  failure: getCouponListActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getCouponListEpic = (
  action$: Observable<RootAction>,
  state$: BehaviorSubject<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getCouponListActions.request)),
    concatMap((action: RequestActionTypes) => {
      const { page, limit, couponType, isSelectPage } = action.payload;
      const virtualProducts = getCheckedItems()(state$.value);
      const getCouponListParams: {
        page: number;
        limit: number;
        virtual_products?: string[];
      } = {
        page,
        limit,
      };

      /**
       * 沒有有勾選 item 時 virtualProducts 會是空陣列，
       * 若 virtual_products 是空陣列，後端判斷為沒有選擇商品，會導致抵用券全部都未達門檻
       * 因此當 length 大於 0 時才加入 params。
       * 除上述外，需再判斷是否為 CouponSelectPage，若非此頁，則無須帶 virtual_products。
       */
      if (virtualProducts.length > 0 && isSelectPage) {
        getCouponListParams.virtual_products = virtualProducts;
      }

      return getCouponList(getCouponListParams, couponType)
        .then((response: AxiosResponse<CouponResponse>) => {
          return getCouponListActions.success({
            couponType,
            coupons: response.data.coupons,
            page,
          });
        })
        .catch((error: AxiosError) =>
          getCouponListActions.failure({ error, couponType })
        );
    })
  );
};

export default getCouponListEpic;
