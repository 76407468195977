import { createSelector } from 'reselect';

import { EventErrors } from '../../constants/VBErrorCode';
import { translate } from '../../i18n';
import { RootState } from '../rootReducer';

const couponRedeemRaw = (state: RootState) => state?.couponRedeemReducer || {};

export const makeCouponRedeemIsFetching = () =>
  createSelector(couponRedeemRaw, data => data?.isFetching || false);

export const makeCouponRedeemHasError = () =>
  createSelector(couponRedeemRaw, data => data?.hasError || false);

export const getErrorMsg = (errorCode: EventErrors) => {
  let errorMessage: string;

  switch (errorCode) {
    case EventErrors.ERR_COUPON_NOT_FOUND:
      errorMessage = translate('coupon-error-not-found');
      break;
    case EventErrors.ERR_COUPON_EXPIRED:
      errorMessage = translate('coupon-error-expired');
      break;
    case EventErrors.ERR_COUPON_USED:
      errorMessage = translate('coupon-error-used');
      break;
    case EventErrors.ERR_COUPON_REDEEMED:
      errorMessage = translate('coupon-error-redeemed');
      break;
    case EventErrors.ERR_COUPON_CAN_NOT_BE_REDEEM:
    default:
      errorMessage = translate('coupon-error-else');
      break;
  }

  return errorMessage;
};

export const makeCouponRedeemErrorMessage = () =>
  createSelector(couponRedeemRaw, data => {
    const errorCode = data?.error?.response?.data?.error_code || 0;

    return getErrorMsg(errorCode);
  });

export const makeRedeemedCoupons = () =>
  createSelector(couponRedeemRaw, data => data?.coupons || []);

export const makeRedeemedCouponsObject = () =>
  createSelector(couponRedeemRaw, data => {
    const coupons = data?.coupons || [];
    const couponsObject = {};

    coupons.forEach(coupon => {
      couponsObject[coupon.id] = coupon;
    });

    return couponsObject;
  });
