import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { withLogFirehoseConsumer } from '../../context/devLogFirehose';
import { logImpressionBatch } from '../../redux/actions/analyticsLog';
import FlatListWithImpression from './FlatListWithImpression';

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logImpressionBatch,
    },
    dispatch
  );
};
const enhance = compose(withLogFirehoseConsumer);

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  enhance(FlatListWithImpression)
);
