import { Component, ReactNode } from 'react';
import { Animated, Easing } from 'react-native';

import IconCircleProgress from '../../assets/svg/IconCircleProgress';
import { S } from './styles';

interface Props {
  children?: ReactNode;
}

interface State {
  progress: Animated.Value;
  tickProgress: Animated.Value;
}

const AnimatedIconCircleProgress =
  Animated.createAnimatedComponent(IconCircleProgress);

export default class AnimatedCheck extends Component<Props> {
  state: State = {
    progress: new Animated.Value(100),
    tickProgress: new Animated.Value(100),
  };

  componentDidMount() {
    Animated.timing(this.state.progress, {
      toValue: 0,
      duration: 400,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
    Animated.timing(this.state.tickProgress, {
      toValue: 0,
      duration: 2000,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  }

  render() {
    const { props } = this;
    const { tickProgress, progress } = this.state;

    return (
      <S.Container testID="animatedCheck">
        <S.AnimatedContainer>
          <AnimatedIconCircleProgress
            tickProgress={tickProgress}
            progress={progress}
          />
        </S.AnimatedContainer>
        {props.children}
      </S.Container>
    );
  }
}
