import {
  GestureResponderEvent,
  StyleProp,
  Text,
  TextStyle,
} from 'react-native';
import styled from 'styled-components/native';

import { neutralLight } from '../../constants/color';
import Touchable from '../Touchable';

const S = {
  Touchable: styled(Touchable)`
    flex: 1;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding: 16px 0;
    min-height: 50px;
    border-top-color: ${neutralLight};
    border-top-width: 1px;
  `,
};

interface Props {
  textStyle: StyleProp<TextStyle>;
  onPress?: (e: GestureResponderEvent) => void;
  text?: string;
}

export default function DialogButton(props: Readonly<Props>) {
  const { onPress, text, textStyle } = props;

  return (
    <S.Touchable accessibilityRole="button" onPress={onPress}>
      <Text style={textStyle}>{text}</Text>
    </S.Touchable>
  );
}
