import { createAsyncAction } from 'typesafe-actions';

import { Categories } from '../../types/LandingPageList';

export interface LandingPageListSuccessPayload {
  data: Categories;
}

export interface LandingPageListFailPayload {
  error: Error;
}

export const getLandingPageListActions = createAsyncAction(
  'verybuy/GET_LANDING_PAGE_LIST',
  'verybuy/GET_LANDING_PAGE_LIST_SUCCESS',
  'verybuy/GET_LANDING_PAGE_LIST_FAIL'
)<undefined, LandingPageListSuccessPayload, LandingPageListFailPayload>();
