/* eslint-disable camelcase */
import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { ListLoadingTypes } from '../../types/Common';
import { RecommendListItem } from '../../types/RecommendListResponse';

export interface RecommendListPayload {
  id: string;
  loadingType: ListLoadingTypes;
  page: number;
  per_page?: number;
  success: (products: RecommendListItem[]) => void;
}
export interface RecommendListSuccessPayload {
  id: string;
  items: RecommendListItem[];
  logEvent?: AnalyticsEvent;
  page: number;
}
export interface RecommendListFailPayload {
  error: Error;
  id: string;
  requestAction?: ReturnType<typeof getRecommendList.request>;
}

export const getRecommendList = createAsyncAction(
  'verybuy/GET_RECOMMEND_LIST',
  'verybuy/GET_RECOMMEND_LIST_SUCCESS',
  'verybuy/GET_RECOMMEND_LIST_FAIL'
)<
  RecommendListPayload,
  RecommendListSuccessPayload,
  RecommendListFailPayload
>();
