import { createSelector } from 'reselect';

import isExists from '../../utils/isExists';
import { initialState } from '../reducers/bannersReducer';
import { RootState } from '../rootReducer';

const bannersRaw = (state: RootState) => {
  const reducer = state?.bannersReducer || initialState;

  return reducer;
};

const makeLayoutState = (layoutName: string) => {
  return createSelector(bannersRaw, data => {
    return data.layoutStates?.[layoutName];
  });
};

export const makeIsBannersLoading = (layoutName: string) => {
  return createSelector(makeLayoutState(layoutName), layoutData => {
    const isLoading = layoutData?.isLoading;

    return isExists(isLoading) ? isLoading : false;
  });
};

export const makeBannersLoadingType = (layoutName: string) => {
  return createSelector(makeLayoutState(layoutName), layoutData => {
    return layoutData?.loadingType || 'new';
  });
};

export const makeBannerIDs = (layoutName: string) => {
  return createSelector(
    makeLayoutState(layoutName),
    makeIsBannersLoading(layoutName),
    makeBannersLoadingType(layoutName),
    (layoutData, isLoading, loadingType) => {
      if (isLoading && loadingType === 'new') {
        return ['blank'];
      }

      return layoutData?.bannerIDs || [];
    }
  );
};

export const makeBannerDataByID = (id: string) => {
  return createSelector(bannersRaw, data => {
    return data.data?.[id];
  });
};
