import { memo } from 'react';
import { StyleProp, StyleSheet, TextStyle } from 'react-native';
import styled from 'styled-components/native';

import { neutralDark, warrior } from '../../../constants/color';
import { getFontScale } from '../../../utils/layoutHelpers';
import {
  DEFAULT_LINE_HEIGHT,
  LIST_ITEM_TITLE_DEFAULT_MARGIN_BOTTOM,
} from '../ListItem.style';

interface Props {
  title: string;
  isOffline: boolean;
  linesOfTitle?: number;
  titleStyle?: StyleProp<TextStyle>;
}

function Title(props: Readonly<Props>) {
  const { title, isOffline, titleStyle, linesOfTitle = 2 } = props;

  if (!title) {
    return null;
  }

  const lineHeight =
    (titleStyle && StyleSheet.flatten(titleStyle).lineHeight) ||
    DEFAULT_LINE_HEIGHT;
  const titleHeight = getFontScale() * lineHeight * linesOfTitle;

  return (
    <S.Title
      isOffline={isOffline}
      style={[titleStyle, { height: titleHeight }]}
      numberOfLines={linesOfTitle}
    >
      {title}
    </S.Title>
  );
}

export default memo(Title);

const S = {
  Title: styled.Text<{ isOffline: boolean }>`
    margin-bottom: ${LIST_ITEM_TITLE_DEFAULT_MARGIN_BOTTOM}px;
    font-size: 15px;
    color: ${({ isOffline }) => (isOffline ? neutralDark : warrior)};
    line-height: ${DEFAULT_LINE_HEIGHT}px;
    /* stylelint-disable font-family-no-missing-generic-family-keyword */
    font-family: 'PingFang TC';
  `,
};
