import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { FBAnalyticsEvent } from '../../constants/FBAnalyticsEvent';
import { FireHoseAnalyticsEvent } from '../../constants/FireHoseAnalyticsEvent';
import { IterableEvents } from '../../constants/IterableEvents';
import { RTBHouseAnalyticsEvent } from '../../constants/RTBHouseAnalyticsEvent';
import { SensorsAnalyticsEvent } from '../../constants/SensorsAnalyticsEvent';

export type StndarEvents =
  | FBAnalyticsEvent
  | RTBHouseAnalyticsEvent
  | FireHoseAnalyticsEvent
  | SensorsAnalyticsEvent
  | IterableEvents;

export const transformToStandardEvent = standarEvents =>
  Object.entries(standarEvents).reduce(
    (acc, [eventKey, eventValue]) => ({
      ...acc,
      [AnalyticsEvent[eventKey]]: eventValue,
    }),
    {}
  );
