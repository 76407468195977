import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import {
  ListLoadingTypes,
  ReviewStatus,
  TransactionStatus,
} from '../../types/Common';
import { ListItemForReducer } from '../../types/TransactionItemForList';
import * as arrayHandler from '../../utils/arrayHandler';
import { postRatingActions } from '../actions/postRatingActions';
import { actionsList } from '../rootAction';

const {
  getTransactionListActions,
  patchCancelTransactionActions,
  clearData,
  fetchTransactionDetail,
} = actionsList;

export interface TransactionListState {
  isListLoading: boolean;
  currentPage?: number;
  loadingType?: ListLoadingTypes;
  hasMoreItems?: boolean;
  error?: any;
  items: ListItemForReducer[];
}

export type TransactionListReducerState = Record<
  Exclude<TransactionStatus, 'paid'> | 'all',
  TransactionListState
>;
type ReducerAction = ActionType<
  | typeof patchCancelTransactionActions
  | typeof getTransactionListActions
  | typeof clearData
  | typeof fetchTransactionDetail.success
  | typeof postRatingActions.success
>;

export const initialTransactionListState: TransactionListState = {
  isListLoading: true,
  items: [],
  hasMoreItems: true,
  loadingType: 'new',
};

export const initialReducerState: TransactionListReducerState = {
  all: initialTransactionListState,
  expired: initialTransactionListState,
  stocking: initialTransactionListState,
  unpaid: initialTransactionListState,
  sent: initialTransactionListState,
};

function findTransactionIndex(draft: TransactionListState, transactionID) {
  return draft.items.findIndex(
    item => String(item.transaction_id) === String(transactionID)
  );
}

function handlePatchCancelTransactionActions(
  draft: TransactionListState,
  action: ActionType<typeof patchCancelTransactionActions>
) {
  const { transactionID } = action.payload;
  const foundOrderIndex = findTransactionIndex(draft, transactionID);

  if (foundOrderIndex === -1) {
    return;
  }

  switch (action.type) {
    case getType(patchCancelTransactionActions.request): {
      draft.items[foundOrderIndex].isCancelLoading = true;
      break;
    }
    case getType(patchCancelTransactionActions.success): {
      draft.items[foundOrderIndex].isCancelLoading = false;
      draft.items[foundOrderIndex].transaction_status =
        TransactionStatus.Expired;
      break;
    }
    case getType(patchCancelTransactionActions.failure): {
      draft.items[foundOrderIndex].isCancelLoading = false;
      break;
    }
    default: {
      break;
    }
  }
}

function handleFetchDetailSuccess(
  draft: TransactionListState,
  action: ActionType<typeof fetchTransactionDetail.success>
) {
  const { transactionID, data } = action.payload;
  const foundOrderIndex = findTransactionIndex(draft, transactionID);

  if (foundOrderIndex === -1) {
    return;
  }

  const foundItem = draft.items[foundOrderIndex];

  /**
   * 除了 transaction_id 與 delivery_date 以外的值都給予更新
   *
   * 說明：
   * transaction_id 是 request param 沒有更新的必要
   * delivery_date 則是在 fetchTransactionDetail.success payload 中找不到對應的值
   * list 的 products 為 detail 已出貨商品（可能超過一批）和未出貨商品（只有一批）的總和，故需要進行處理再更新
   */

  const shippedProducts = data.product_data.shipped.flatMap(
    packet => packet.products
  );

  const unshippedProducts = data.product_data.unshipped.flatMap(
    packet => packet.products
  );

  const products = [...shippedProducts, ...unshippedProducts];

  foundItem.transaction_no = data.transaction_no;
  foundItem.created_at = data.created_at;
  foundItem.total_price = data.payment_data.total_price;
  foundItem.currency = data.payment_data.currency;
  foundItem.pay_by = data.payment_data.pay_by;
  foundItem.payment_text = data.payment_data.payment_text;
  foundItem.payment_limit = data.payment_data.payment_limit;
  foundItem.transaction_status = data.transaction_status;
  foundItem.products = products;
  foundItem.is_separated = data.product_data.is_separated;
  foundItem.review_deadline = data.review_deadline;
}

function handleGetList(
  draft: TransactionListState,
  action: ActionType<typeof getTransactionListActions>
) {
  switch (action.type) {
    case getType(getTransactionListActions.request): {
      const { loadingType } = action.payload;

      draft.isListLoading = true;
      draft.loadingType = loadingType;
      break;
    }

    case getType(getTransactionListActions.success): {
      const { items, page } = action.payload;
      const newItems =
        draft.loadingType === 'loadMore'
          ? [...(draft.items || []), ...items]
          : items;

      draft.isListLoading = false;
      draft.error = undefined;
      draft.currentPage = page;
      draft.hasMoreItems = items.length > 0;
      draft.items = arrayHandler.uniqueArray(newItems, x => [
        x.transaction_id,
        x,
      ]);
      break;
    }

    case getType(getTransactionListActions.failure): {
      draft.isListLoading = false;
      draft.error = action.payload.error;
      draft.hasMoreItems = false;
      break;
    }

    default: {
      break;
    }
  }
}

function handlePostRatingSuccess(
  draft: TransactionListState,
  action: ActionType<typeof postRatingActions.success>
) {
  switch (action.type) {
    case getType(postRatingActions.success): {
      const { transactionID } = action.payload;

      const item = draft.items.find(x => x.transaction_id === transactionID);

      if (item) {
        item.products.forEach(product => {
          if (product.review_status === ReviewStatus.NotReviewed) {
            product.review_status = ReviewStatus.Done;
          }
        });
      }

      break;
    }

    default: {
      break;
    }
  }
}

export default produce(
  (draft: TransactionListReducerState, action: ReducerAction) => {
    switch (action.type) {
      case getType(getTransactionListActions.request):
      case getType(getTransactionListActions.success):
      case getType(getTransactionListActions.failure): {
        const { type } = action.payload;

        handleGetList(draft[type], action);
        break;
      }

      case getType(patchCancelTransactionActions.request):
      case getType(patchCancelTransactionActions.success):
      case getType(patchCancelTransactionActions.failure): {
        Object.values(draft).forEach(list =>
          handlePatchCancelTransactionActions(list, action)
        );
        break;
      }

      case getType(fetchTransactionDetail.success): {
        Object.values(draft).forEach(list =>
          handleFetchDetailSuccess(list, action)
        );
        break;
      }

      case getType(postRatingActions.success): {
        return Object.values(draft).forEach(list =>
          handlePostRatingSuccess(list, action)
        );
      }

      case getType(clearData): {
        return initialReducerState;
      }

      default: {
        break;
      }
    }

    return draft;
  },
  initialReducerState
);
