import IconCancel from '../../assets/svg/IconCancel';
import { warrior } from '../../constants/color';
import { translate } from '../../i18n';
import { NavigationParams } from '../../types/NavigationParams';
import { Props as NavLeftProps } from '../NavLeft';

interface AnyForObj {
  [key: string]: any;
}

const screenViewLeftOpts = {
  payment_cc() {
    return {
      contentText: translate('confirmToCancelCreditCardPayment'),
      positiveText: translate('continuePaying'),
      negativeText: translate('later'),
    };
  },
};

export function createNavLeftOpts(
  params: AnyForObj = {}
): Partial<NavLeftProps> {
  const navOpts = Object.keys(params).reduce(
    (result: Partial<NavLeftProps>, key: string) => {
      switch (key) {
        case 'isCancelBtn': {
          if (params[key]) {
            return {
              hasDialog: true,
              icon: <IconCancel size={20} fillColor={warrior} />,
              ...result,
            };
          }

          return result;
        }
        case NavigationParams.BackToPageKey: {
          return {
            ...result,
            [key]: params[key],
          };
        }
        default:
          return result;
      }
    },
    {}
  );

  const specificScreenOpts = screenViewLeftOpts[params.screenViewName]
    ? screenViewLeftOpts[params.screenViewName]()
    : {};

  return {
    ...specificScreenOpts,
    ...navOpts,
  };
}
