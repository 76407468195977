import { memo } from 'react';
import styled, { useTheme } from 'styled-components/native';

import IconReviewStar from '../../assets/svg/IconReviewStar';

interface Props {
  rating: number;
  size?: number;
  showRatingText?: boolean;
}

export default memo(({ rating, size = 12, showRatingText }: Props) => {
  const { palette } = useTheme();
  const { coral, neutralLight } = palette;

  return (
    <S.Stars>
      {Array(5)
        .fill('')
        .map((_, index) => {
          const fillColor = index < rating ? coral : neutralLight;
          const fillColorRight = index <= rating - 1 ? coral : neutralLight;

          return (
            <S.StarBox key={index}>
              <IconReviewStar
                size={size}
                fillColor={fillColor}
                fillColorRight={fillColorRight}
              />
            </S.StarBox>
          );
        })}
      {showRatingText && (
        <S.RatingText testID="AvgRatingText">{rating.toFixed(1)}</S.RatingText>
      )}
    </S.Stars>
  );
});

const S = {
  Stars: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  StarBox: styled.View`
    margin-right: 2px;
  `,
  RatingText: styled.Text`
    padding-left: 6px;
    font-size: 13px;
    color: ${({ theme }) => theme.palette.coral};
    border: 1px solid transparent;
  `,
};
