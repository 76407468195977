/* eslint-disable camelcase */
import { createAsyncAction } from 'typesafe-actions';

export interface PutDirectPayPrimaryCardActionsPayload {
  card_id: number;
  success: Function;
  failure: Function;
}

export interface PutDirectPayPrimaryCardActionsSuccessPayload {
  card_id: number;
}

export interface PutDirectPayPrimaryCardActionsFailPayload {
  card_id: number;
  error: Error;
}

export const putDirectPayPrimaryCardAction = createAsyncAction(
  'verybuy/PUT_DIRECT_PAY_PRIMARY_CARD',
  'verybuy/PUT_DIRECT_PAY_PRIMARY_CARD_SUCCESS',
  'verybuy/PUT_DIRECT_PAY_PRIMARY_CARD_FAIL'
)<
  PutDirectPayPrimaryCardActionsPayload,
  PutDirectPayPrimaryCardActionsSuccessPayload,
  PutDirectPayPrimaryCardActionsFailPayload
>();
