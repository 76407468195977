export enum Code {
  TWD = 'TWD',
  USD = 'USD',
}

export enum CurrencySymbol {
  NT = 'NT$',
  US = 'US$',
}

export default interface Currency {
  code?: Code;
  iso_code?: Code;
  symbol: CurrencySymbol;
}
