import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { patchCancelTransactionActions } from '../actions/patchCancelTransactionActions';
import { patchCancelTransaction } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: patchCancelTransactionActions.success,
  failure: patchCancelTransactionActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;
type RequestActionTypes = ActionType<
  typeof patchCancelTransactionActions.request
>;

const patchCancelTransactionEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(patchCancelTransactionActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { transactionID } = action.payload;

      return patchCancelTransaction(transactionID)
        .then(() => {
          return patchCancelTransactionActions.success({
            transactionID,
          });
        })
        .catch((error: AxiosError) => {
          const failure = action.payload?.failure || undefined;

          if (failure) {
            failure(error.response);
          }

          return patchCancelTransactionActions.failure({
            transactionID,
            error,
          });
        });
    })
  );
};

export default patchCancelTransactionEpic;
