import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes, NotificationGroup } from '../../types/Common';
import { Notification } from '../../types/Notifications';

export interface NotificationsPayload {
  group: NotificationGroup;
  loadingType: ListLoadingTypes;
}

export interface NotificationsSuccessPayload {
  notifications: Notification[];
  group: NotificationGroup;
}

export interface NotificationsFailPayload {
  error: Error;
}

export const getNotificationsActions = createAsyncAction(
  'verybuy/GET_NOTIFICATIONS',
  'verybuy/GET_NOTIFICATIONS_SUCCESS',
  'verybuy/GET_NOTIFICATIONS_FAIL'
)<
  NotificationsPayload,
  NotificationsSuccessPayload,
  NotificationsFailPayload
>();
