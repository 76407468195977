import styled from 'styled-components/native';

import { coral, warrior } from '../../../../../../../constants/color';
import Image from '../../../../../../../elements/Image';
import Touchable from '../../../../../../../elements/Touchable';

export default {
  Touchable: styled(Touchable)`
    flex-direction: row;
    align-items: center;
  `,
  Image: styled(Image)`
    width: 40px;
    height: 40px;
  `,
  Text: styled.Text<{ highlight: boolean }>`
    margin-left: 8px;
    color: ${({ highlight }) => (highlight ? coral : warrior)};
    font-size: 15px;
    line-height: 21px;
    text-decoration: ${({ highlight }) => (highlight ? 'none' : 'underline')};
  `,
};
