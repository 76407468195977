import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { loginByEmail } from '../actions/loginByEmail';
import { postLoginByEmail, setAxiosDefaultHeader } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof loginByEmail.request>;
const outputActions = {
  success: loginByEmail.success,
  failure: loginByEmail.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const loginByEmailEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(loginByEmail.request)),
    switchMap((action: RequestActionTypes) => {
      const { success, failure, loginMethod, ...rest } = action.payload;

      return postLoginByEmail(rest)
        .then(response => {
          const { data } = response;

          setAxiosDefaultHeader('api-key', data.token);

          if (success !== undefined) {
            success(data);
          }

          return loginByEmail.success({
            result: response,
            account: action.payload.account,
            logEvent: AnalyticsEvent.Login,
            method: loginMethod,
          });
        })
        .catch(error => {
          if (failure) {
            failure(error.response);
          }

          return loginByEmail.failure({
            error,
            account: action.payload.account,
            logEvent: AnalyticsEvent.Login,
            method: loginMethod,
          });
        });
    })
  );
};

export default loginByEmailEpic;
