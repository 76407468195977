import { detectIncognito } from 'detectincognitojs';
import safeAwait from 'safe-await';

let _IS_INCOGNITO_MODE;

export default async () => {
  if (_IS_INCOGNITO_MODE === undefined) {
    const [, result] = await safeAwait(detectIncognito());

    if (result) {
      _IS_INCOGNITO_MODE = result.isPrivate;
    }
  }

  return _IS_INCOGNITO_MODE;
};
