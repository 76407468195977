export enum TabKey {
  Home = 'Home',
  Cart = 'Cart',
  Me = 'Me',
  Categories = 'Categories',
}

export function isRouteWithTab(routeName) {
  return Object.values(TabKey).includes(routeName);
}
