import toArray from 'lodash/toArray';
import { createSelector } from 'reselect';

import type { HomeDeliveryDataFromSelector } from '../../types/DeliveryData';
import * as countryInfo from '../../utils/countryInfo';
import { countryCodeHandler } from '../../utils/countryInfo';
import isExists from '../../utils/isExists';
import { RootState } from '../rootReducer';
import { makeCountriesData, makeCountriesList } from './VBConfigSelector';

const deliveryDataRow = (state: RootState) =>
  state?.checkoutReducer?.deliveryData || {};

const zipDataRow = (state: RootState) => state?.checkoutReducer?.zipData || {};

export const makeIsDeliveryDataLoading = () => {
  return createSelector(deliveryDataRow, data => {
    const isLoading = data?.isLoading;

    return isExists(isLoading) ? isLoading : true;
  });
};

export const makeIsDeliveryDataExist = () => {
  return createSelector(deliveryDataRow, data => isExists(data?.data?.[0]));
};

export const makeDeliveryDataByIndex = (
  index,
  countryCode = countryCodeHandler.get()
) => {
  return createSelector(
    deliveryDataRow,
    makeCountriesData(),
    (data, countries) => {
      // TODO: 整合 country & countryInfoFromConfig
      const country = countryInfo.getCountryInfoByCode(countryCode);
      const countryInfoFromConfig = countries[countryCode] || {};
      const result = data?.data?.[index] || {
        area_phone_code:
          country.areaPhoneCode || `+${countryInfoFromConfig.country_code}`,
        global_country_code: countryInfoFromConfig.code,
      };

      return {
        country: country.name,
        ...result,
      } as HomeDeliveryDataFromSelector;
    }
  );
};

export const makeLatestDeliveryData = (
  countryCode = countryCodeHandler.get()
) => {
  return createSelector(makeDeliveryDataByIndex(0, countryCode), data => data);
};

export const makeZipCodeDataForPicker = (
  countryCode = countryCodeHandler.get()
) => {
  return createSelector(zipDataRow, data => {
    const result = data?.[countryCode]?.data || [];

    const zipCodeData = toArray(result).reduce(
      (memo, value) => {
        const city = value.city;

        memo.cityDistricts.push({
          [city]: value.districts.map(val => val.name),
        });

        memo.zipCode.push({
          [city]: value.districts.map(val => val.code),
        });

        return memo;
      },
      { zipCode: [], cityDistricts: [] }
    );

    return zipCodeData;
  });
};

export const makePhoneCodeGCList = () => {
  return createSelector(makeCountriesList(), countries =>
    countries
      .filter(country => country.code !== 'TW')
      .map(country => ({
        label: `+${country.country_code}`,
        value: country.code,
      }))
  );
};

export const makeCountryGCList = () => {
  return createSelector(makeCountriesList(), countries =>
    countries
      .filter(country => country.code !== 'TW')
      .map(country => ({
        label: country.name,
        value: country.code,
      }))
  );
};
