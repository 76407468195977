import { createAsyncAction } from 'typesafe-actions';

import { ApplePayPrimeResponse } from '../../types/ApplePayPrimeResponse';

export interface ApplePayPrimePayload {
  failure?: (data: { error: any; transactionID: number }) => void;
  prime: string;
  success?: (data: ApplePayPrimeResponse) => void;
  transactionID: number;
  screen: string;
}

export interface ApplePayPrimeSuccessPayload {
  data: ApplePayPrimeResponse;
  transactionID: number;
}

export interface ApplePayPrimeFailPayload {
  error: Error;
  transactionID: number;
}

export const postApplePayPrimeActions = createAsyncAction(
  'verybuy/POST_APPLE_PAY_PRIME',
  'verybuy/POST_APPLE_PAY_PRIME_SUCCESS',
  'verybuy/POST_APPLE_PAY_PRIME_FAIL'
)<
  ApplePayPrimePayload,
  ApplePayPrimeSuccessPayload,
  ApplePayPrimeFailPayload
>();
