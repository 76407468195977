import type { Dispatch } from 'react';
import { createAsyncAction } from 'typesafe-actions';

import type { ListLoadingTypes } from '../../types/Common';
import type { Data as ProductReviewListDataData } from '../../types/ProductReviewListDataResponse';

export interface ProductReviewListDataPayload {
  id: string;
  loadingType: ListLoadingTypes;
  dispatch: Dispatch<any>;
  page: number;
}

export interface ProductReviewListDataSuccessPayload {
  data: ProductReviewListDataData;
}

export interface ProductReviewListDataFailPayload {
  error: Error;
}

export const getProductReviewListDataActions = createAsyncAction(
  'verybuy/GET_PRODUCT_REVIEW_LIST_DATA',
  'verybuy/GET_PRODUCT_REVIEW_LIST_DATA_SUCCESS',
  'verybuy/GET_PRODUCT_REVIEW_LIST_DATA_FAIL'
)<
  ProductReviewListDataPayload,
  ProductReviewListDataSuccessPayload,
  ProductReviewListDataFailPayload
>();
