import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getNewArrivalActions } from '../actions/getNewArrivalActions';
import { getNewArrival } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getNewArrivalActions.success,
  failure: getNewArrivalActions.failure,
};

type RequestActionTypes = ActionType<typeof getNewArrivalActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getNewArrivalEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getNewArrivalActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { page, loadingType, success, dispatch, ...restParams } =
        action.payload;

      dispatch(action);

      return getNewArrival({
        page,
        ...restParams,
      })
        .then(response => {
          const { data } = response;

          if (typeof success === 'function') {
            success(data.products);
          }

          const successAction = getNewArrivalActions.success({
            data,
            loadingType,
            page,
          });

          dispatch(successAction);

          return successAction;
        })
        .catch((error: AxiosError) => {
          const failureAction = getNewArrivalActions.failure({
            error,
            requestAction: action,
          });

          dispatch(failureAction);

          return failureAction;
        });
    })
  );
};

export default getNewArrivalEpic;
