import withDisplayImpression from '../../../decorators/withDisplayImpression';
import PromoteBanner, {
  Props,
  bannerPositions,
  layoutName,
} from './PromoteBanner';

export { layoutName, bannerPositions };

export default withDisplayImpression<Props>(PromoteBanner);
