import pickBy from 'lodash/pickBy';
import { Platform } from 'react-native';

import { isWebPlatform } from '../../../boot/utils';
import ElementType from '../../../constants/ElementType';
import { cartContentItemAttrSelector } from '../../../redux/selectors/cartContentSelector';
import { makeIsLogin, makeMemberInfo } from '../../../redux/selectors/member';
import { makeProductByIdSelector } from '../../../redux/selectors/productById';
import { makePromoteItemDataById } from '../../../redux/selectors/promotionalThemesSelector';
import { ItemTypes } from '../../../types/Common';
import deviceInfo from '../../deviceInfo';
import { getAltGhostID, getGhostID } from '../../ghostIDHelpers';
import handleLineId from '../../handleLineId';
import isExists from '../../isExists';
import * as navigationService from '../../navigationService';
import { getFCMToken } from '../../packages/firebase';
import { getIsDisableNotiPermission } from '../../permissionHelpers';
import routeUtils from '../../routes';

declare global {
  interface Window {
    ga: {
      getAll: Function;
    };
  }
}

// Firehose data general rule: Omit value if is empty string or not exists
// Exception: mid should be '' if not login
const firehoseDataFilter = val => isExists(val) && val !== '';

export function getDeviceType() {
  const deviceType = deviceInfo.getDeviceType();

  switch (deviceType) {
    case 'Handset':
    case 'mobile':
      return 'phone';
    case 'Tablet':
    case 'tablet':
      return 'tablet';
    case 'Unknown':
    default:
      return 'computer';
  }
}

export function getPlatform() {
  return isWebPlatform ? 'mweb' : Platform.OS;
}

/**
 * 一般資訊
 * 如裝置、系統、時間、路由 ...
 */
export const getGeneralInfo = async () => {
  const { params, routeName } = navigationService.getActiveRoute() || {};
  const path = isWebPlatform
    ? window.location.href
    : routeUtils.getPathAndParamsFromData(
        routeName,
        pickBy(params, v => typeof v !== 'function')
      ).fullPath;
  const clid = handleLineId.getClid();
  const data: Record<string, any> = {
    device: getDeviceType(),
    platform: getPlatform(),
    when: Math.floor(Date.now() / 1000),
    'utm-source': params?.utm_source,
    'utm-medium': params?.utm_medium,
    'app-version': await deviceInfo.getVersionWithCodePush(),
    'fcm-token': await getFCMToken(),
    ...(clid ? { 'cresclab-clid': clid } : {}),
    path,
  };

  if (!isWebPlatform) {
    data['push-allowed'] = !(await getIsDisableNotiPermission());
  }

  return pickBy(data, firehoseDataFilter);
};

/**
 * 使用者資訊
 * 如 mid, gid, token ...
 */
export const getMemberInfo = async currentStore => {
  const { MID, guestID, token, lineID } = makeMemberInfo()(currentStore);
  const login = makeIsLogin()(currentStore);
  const ghostID = await getGhostID();
  const altGhostID = await getAltGhostID();
  const data: Record<string, any> = {
    mid: MID || '',
    'ghost-id': ghostID,
    ...(altGhostID ? { 'alt-ghost-id': altGhostID } : {}),
    gid: guestID,
    // 根據舊站，僅登入才給 token
    token: login ? token : '',
    login,
  };

  if (isWebPlatform && window.ga && window.ga.getAll) {
    const ga = window.ga.getAll()[0];

    if (ga) {
      data['session-id'] = ga.get('clientId');
    }
  }

  return {
    // mid should be empty string if not login
    mid: '',
    ...pickBy(data, firehoseDataFilter),
    ...(lineID ? { 'line-id': lineID } : {}),
  };
};

const getItemData = (logData, currentStore) => {
  const { id, source } = logData;
  const itemType = ItemTypes.USER_SUBMIT;
  const itemInfo = makeProductByIdSelector(id)(currentStore);

  const mainProductMarketId =
    logData?.marketID || logData?.marketing_product_id;

  return {
    id,
    type: itemType,
    generated: source,
    tid: itemInfo?.marketing_product_id,
    'market-id': mainProductMarketId,
  };
};

const layoutNameMap = {
  item_page: 'item page',
  search_result: 'search result',
  category_list: 'category list',
};

export const formatData = (logData, currentStore): Record<string, any> => {
  let data = {};
  const { pitNumber, id, elementType, amount, layoutName } = logData;
  const commonData: Record<string, any> = {
    where: layoutNameMap[layoutName || ''] || layoutName,
    'pit-number': pitNumber,
    amount,
  };

  switch (elementType) {
    case ElementType.PromoteItem: {
      const info = makePromoteItemDataById(id)(currentStore);

      data = {
        ...commonData,
        'operation-id': info.id || NaN,
        'operation-loading-page': info.landing_page || 'unknown',
        'operation-title': info.title || 'unknown',
        'operation-url': info.url || 'unknown',
      };

      break;
    }
    case ElementType.CartContentItem: {
      const itemData = getItemData(logData, currentStore);
      const { vid } = logData;
      const marketingProductId = cartContentItemAttrSelector(
        vid,
        'marketing_product_id'
      )(currentStore);

      data = {
        ...commonData,
        ...itemData,
        tid: marketingProductId,
      };
      break;
    }
    case ElementType.CategoryListItem: {
      const itemData = getItemData(logData, currentStore);
      const { parent_id: parentCatID, id: catID } = logData.currentSubCat || {};
      const parentCategoryId = isExists(parentCatID) ? parentCatID : catID;

      data = {
        ...commonData,
        ...itemData,
        parent_category_id: parentCategoryId || 'UNKNOWN_PARENT_CATEGORY_ID',
      };

      break;
    }
    case ElementType.HistoryItem: {
      const itemData = getItemData(logData, currentStore);

      // 因為歷史紀錄存在 state，不會存到 redux store
      // 可能拿不到資料，先以該方式處理
      if (!itemData.tid) {
        itemData.tid = logData.tid;
      }

      data = {
        ...commonData,
        ...itemData,
      };

      break;
    }
    case ElementType.Item: {
      const itemData = getItemData(logData, currentStore);

      data = {
        ...commonData,
        ...itemData,
      };

      break;
    }
    default: {
      data = commonData;
      break;
    }
  }

  const additionalLogData = logData?.additionalLogData;

  if (typeof additionalLogData !== 'undefined') {
    data = {
      ...data,
      ...additionalLogData,
    };
  }

  return pickBy(data, firehoseDataFilter);
};
