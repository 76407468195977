import { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { CVSReceivingData } from '../../types/DeliveryData';
import { updateCVSReceivingData } from '../actions/updateCVSReceivingDataActions';
import { putCVSReceivingData } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof updateCVSReceivingData.request>;
const outputActions = {
  success: updateCVSReceivingData.success,
  failure: updateCVSReceivingData.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const updateCVSReceivingDataEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(updateCVSReceivingData.request)),
    switchMap((action: RequestActionTypes) => {
      const { data: cvsItem, success, failure } = action.payload;

      return putCVSReceivingData(cvsItem)
        .then((response: AxiosResponse<CVSReceivingData>) => {
          success();

          return updateCVSReceivingData.success({
            data: response.data.receiving_data[0],
          });
        })
        .catch(error => {
          failure();

          return updateCVSReceivingData.failure({
            error,
          });
        });
    })
  );
};
