import { Component } from 'react';
import { View } from 'react-native';

import { isAndroid } from '../../boot/utils';
import withIsFocused from '../../decorators/withIsFocused';
import Placeholder from '../Placeholder';
import PureImage from './PureImage';
import { ImageProps } from './types';

interface Props extends ImageProps {
  isFocused: boolean;
  loadingConfig?: {
    width: number;
    height: number;
  };
}

interface State {
  isLoading: boolean;
}
export class Image extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: !!props.loadingConfig,
    };
  }

  onLoadEnd = () => {
    const { isLoading } = this.state;
    const { onLoadEnd } = this.props;

    if (isLoading) {
      this.setState({ isLoading: false });
    }

    if (onLoadEnd) {
      onLoadEnd();
    }
  };

  render() {
    const { isLoading } = this.state;
    const { isFocused, loadingConfig, onLoadEnd, ...otherProps } = this.props;
    const { width, height } = loadingConfig || {};

    return (
      <>
        {isLoading && (
          <Placeholder
            boxStyle={{ position: 'absolute', top: 0 }}
            width={width || 0}
            height={height || 0}
          />
        )}
        {isAndroid && !isFocused ? (
          <View testID="coverImage" style={this.props.style} />
        ) : (
          <PureImage
            testID="pureImage"
            onLoadEnd={this.onLoadEnd}
            {...otherProps}
          />
        )}
      </>
    );
  }
}

export default withIsFocused<Props>(Image);
