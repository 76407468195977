import { useEffect, useState } from 'react';
import { View } from 'react-native';
import safeAwait from 'safe-await';
import styled from 'styled-components/native';

import { black, white } from '../../constants/color';
import { translate } from '../../i18n';
import { getIsTW } from '../../utils/countryInfo';
import { getFontScale } from '../../utils/layoutHelpers';
import { getRemoteValue } from '../../utils/packages/firebase';
import styles from './styles';

const REMOTE_CONFIG_KEY = 'promote_first_purchase_vcoin';

export const DEFAULT_VCOIN = '100';

export default function PromoteSection() {
  const [vcoinInfo, setVcoinInfo] = useState({
    vcoin: DEFAULT_VCOIN,
    isFetching: true,
  });

  useEffect(() => {
    (async () => {
      const [, value] = await safeAwait(getRemoteValue(REMOTE_CONFIG_KEY));
      const vcoin = value?.asString() || DEFAULT_VCOIN;

      setVcoinInfo({ vcoin: vcoin, isFetching: false });
    })();
  }, []);

  if (vcoinInfo.isFetching || !getIsTW()) {
    return null;
  }

  return (
    <View>
      <S.Header>
        <S.HeaderGuard />
        <S.HeaderText style={styles.introText}>
          {translate('welcomeLogin-header')}
        </S.HeaderText>
        <S.HeaderGuard />
      </S.Header>
      <S.ShadowText style={styles.shadowText}>
        {translate('welcomeLogin-promote', { vcoin: vcoinInfo.vcoin })}
      </S.ShadowText>
    </View>
  );
}

const S = {
  Header: styled.View`
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3px;
    height: 22px;
  `,
  HeaderGuard: styled.View`
    height: ${(21 / 2) * getFontScale()}px;
    flex: 3;
    border-bottom-width: 0.5px;
    border-color: ${white};
  `,
  HeaderText: styled.Text`
    flex: 3;
    min-height: 21px;
    text-align: center;
  `,
  ShadowText: styled.Text`
    text-shadow-color: ${black}33; /* 0.2 */
    text-shadow-offset: 0 2px;
    text-shadow-radius: 4px;
  `,
};
