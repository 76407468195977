import { StyleSheet } from 'react-native';

import { warrior } from '../../../../../constants/color';

const styles = StyleSheet.create({
  // categories
  categoryContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '50%',
    height: 55,
  },
  categoryTitle: {
    fontSize: 15,
    color: warrior,
  },
  categoryIcon: {
    width: 40,
    height: 40,
    marginRight: 20,
  },
});

export default styles;
