import { createAsyncAction } from 'typesafe-actions';

import { BannerDetail } from '../../types/Banners';
import { ListLoadingTypes } from '../../types/Common';

export interface GetBannerPayload {
  positions: string[];
  layoutName: string;
  loadingType: ListLoadingTypes;
}

export interface GetBannerSuccessPayload {
  banners: BannerDetail[];
  layoutName: string;
}

export interface GetBannerFailPayload {
  layoutName: string;
  error: Error;
}

const getBannersActions = createAsyncAction(
  'verybuy/GET_BANNER',
  'verybuy/GET_BANNER_SUCCESS',
  'verybuy/GET_BANNER_FAIL'
)<GetBannerPayload, GetBannerSuccessPayload, GetBannerFailPayload>();

export default getBannersActions;
