import { ofType } from 'redux-observable';
/* eslint-disable camelcase */
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { putDirectPayPrimaryCardAction } from '../actions/putDirectPayPrimaryCardActions';
import { putDirectPayPrimaryCard } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<
  typeof putDirectPayPrimaryCardAction.request
>;
const outputActions = {
  success: putDirectPayPrimaryCardAction.success,
  failure: putDirectPayPrimaryCardAction.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const putDirectPayPrimaryCardEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(putDirectPayPrimaryCardAction.request)),
    switchMap((action: RequestActionTypes) => {
      const cardId = action.payload.card_id;

      return putDirectPayPrimaryCard(cardId)
        .then(() => {
          const success = action.payload?.success || undefined;

          if (success !== undefined) {
            action.payload.success();
          }

          return putDirectPayPrimaryCardAction.success({
            card_id: action.payload.card_id,
          });
        })
        .catch(respError => {
          const failure = action.payload?.failure;
          const errorCode = respError?.response?.data?.error_code;

          if (failure) {
            failure(errorCode);
          }

          return putDirectPayPrimaryCardAction.failure({
            card_id: action.payload.card_id,
            error: respError?.response?.data,
          });
        });
    })
  );
};

export default putDirectPayPrimaryCardEpic;
