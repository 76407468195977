import i18n from 'i18next';

import { DEFAULT_LANG, isBot } from '../constants/misc';
import getUserLocale from '../utils/locale';
import mmkvStorage from '../utils/mmkvStorage';

class LangHandler {
  _storeKey = 'lang';

  private getInitLang = () => {
    const initLang =
      mmkvStorage.instance.getString(this._storeKey) || getUserLocale();

    if (isBot) {
      const url = new URL(window.location.href);

      return url.searchParams.get('lang') || initLang;
    }

    return initLang;
  };

  _lang = this.getInitLang();

  get = () => this._lang || DEFAULT_LANG;

  set = (lang: string) => {
    this._lang = lang;
    mmkvStorage.instance.set(this._storeKey, lang);
    i18n.changeLanguage(lang);
  };
}

const langHandler = new LangHandler();

export default langHandler;
