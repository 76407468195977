/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */

import { createStandardAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { Impression } from '../../types/CTR';

// only for epit analyticsLogEpic communicate to analyticsLogReducer
export interface AnalyticsLogPayload {
  event: AnalyticsEvent;
  logData: any;
  currentStore: any;
}
export const analyticsLog = createStandardAction(
  'verybuy/ANALYTICS_LOG'
)<AnalyticsLogPayload>();

export interface LogEventPayload {
  logEvent: AnalyticsEvent;
  data?: any;
}

export const logAnalyticsEvent = createStandardAction(
  'verybuy/LOG_ANALYTICS_EVENT'
)<LogEventPayload>();

export interface LogImpressionBatchPayload {
  data: Impression[];
}

export const logImpressionBatch = createStandardAction(
  'verybuy/LOG_IMPRESSION_BATCH'
)<LogImpressionBatchPayload>();
