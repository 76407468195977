import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { DirectPayCardData } from '../../types/DirectPayCard';
import { deleteDirectPayCardActions } from '../actions/deleteDirectPayCardActions';
import getDirectPayCardActions from '../actions/getDirectPayCardAction';
import { postDirectPayCardActions } from '../actions/postDirectPayCardActions';
import { getDirectPayCard } from '../api';
import { RootAction } from '../rootAction';

const outputActions = {
  success: getDirectPayCardActions.success,
  failure: getDirectPayCardActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getDirectPayCardEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(getDirectPayCardActions.request),
      getType(deleteDirectPayCardActions.success),
      getType(postDirectPayCardActions.success)
    ),
    switchMap(() => {
      return getDirectPayCard()
        .then((response: AxiosResponse<DirectPayCardData>) =>
          getDirectPayCardActions.success({
            card_data: response.data.card_data,
          })
        )
        .catch((error: AxiosError) =>
          getDirectPayCardActions.failure({ error })
        );
    })
  );
};

export default getDirectPayCardEpic;
