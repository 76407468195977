import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Data as EventPageCoverData } from '../../types/EventPageCoverResponse';

export interface EventPageCoverPayload {
  loadingType: ListLoadingTypes;
}

export interface EventPageCoverSuccessPayload {
  data: EventPageCoverData;
}

export interface EventPageCoverFailPayload {
  error: Error;
}

export const getEventPageCoverActions = createAsyncAction(
  'verybuy/GET_EVENT_PAGE_COVER',
  'verybuy/GET_EVENT_PAGE_COVER_SUCCESS',
  'verybuy/GET_EVENT_PAGE_COVER_FAIL'
)<
  EventPageCoverPayload,
  EventPageCoverSuccessPayload,
  EventPageCoverFailPayload
>();
