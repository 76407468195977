import { StyleProp, ViewStyle } from 'react-native';

import { neutralDark } from '../../constants/color';
import { S } from './styles';

interface Props {
  text: string;
  color?: string;
  itemLabelStyle?: StyleProp<ViewStyle>;
}

export default function ItemLabel({
  color = neutralDark,
  text,
  itemLabelStyle,
}: Props) {
  return (
    <S.Wrapper color={color} style={itemLabelStyle}>
      <S.Text color={color} allowFontScaling={false}>
        {text}
      </S.Text>
    </S.Wrapper>
  );
}
