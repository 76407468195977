import { useEffect } from 'react';

import { LoginOrRegisterMethod } from '../../../constants/LoginOrRegisterMethod';
import useScript from '../../../hooks/useScript';
import { LoginByApplePayload } from '../../../redux/actions/loginByApple';
import appleAuth from '../../../utils/appleAuth';
import Config from '../../../utils/configs';
import { getFCMToken } from '../../../utils/packages/firebase';
import { openFailDialog } from '../utils';
import AppleLoginButton from './AppleLoginButton';

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: Function;
        signIn: Function;
      };
    };
  }
}

// ref: https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple#3331292
interface AppleSignIn {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user?: {
    name: {
      firstName: string;
      lastName: string;
    };
    email: string;
  };
}

enum AppleAuthError {
  POPUP_CLOSE = 'popup_closed_by_user',
  CANCELLED = 'user_cancelled_authorize',
  NEW_SIGNIN = 'user_trigger_new_signin_flow',
}

interface Props {
  loginByApple: (payload: LoginByApplePayload) => void;
  // FIXME: 暫時的，把 window.appleID 的這些都搬去 utils/appleAuth 搭配 app 的 type check 就可以好好測了
  logOnlyForTest?: (_x: string) => void;
  inviteCode?: string;
}

const APPLE_AUTH_SDK_SRC =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

export default function (props: Props) {
  if (!appleAuth.isSupported) {
    return null;
  }

  const [SDKLoaded, SDKLoadError] = useScript(APPLE_AUTH_SDK_SRC);
  const AppleIDSetup = typeof (window as any).AppleID !== 'undefined';

  useEffect(() => {
    if (SDKLoaded && !SDKLoadError && AppleIDSetup) {
      const theOrigin = window.location ? window.location.origin : '';

      window.AppleID.auth.init({
        clientId: Config.APPLE_AUTH_CLIENT_ID,
        scope: 'name email',
        redirectURI: `${theOrigin}/appleLoginDone`,
        state: 'verybuy-apple-login-web',
        usePopup: true,
      });
    } else if (process.env.NODE_ENV === 'test') {
      props.logOnlyForTest!('window.AppleID is not ready to use');
    }
  }, [SDKLoaded, SDKLoadError, AppleIDSetup]);

  const handleLogin = async () => {
    // FIXME: 因為 window.AppleID 沒有進 effect，又打算之後抽出去處理，先以 window.AppleID 判斷
    if (!SDKLoaded || SDKLoadError || !window.AppleID) {
      if (process.env.NODE_ENV === 'test') {
        props.logOnlyForTest!('window.AppleID is not ready to handleLogin');
      }

      return;
    }

    const { loginByApple, inviteCode } = props;

    try {
      const data: AppleSignIn = await window.AppleID.auth.signIn();
      const {
        authorization: { id_token: token, code },
      } = data;
      const firstName = data?.user?.name?.firstName;
      const fullName = { givenName: firstName || null };

      if (token && code) {
        const fcmToken = await getFCMToken();

        loginByApple({
          identity_token: token,
          auth_code: code,
          fcm_token: fcmToken,
          full_name: fullName,
          loginMethod: LoginOrRegisterMethod.APPLE,
          invite_code: inviteCode,
          success: () => {
            console.log('Apple Logined');
          },
        });
      } else {
        openFailDialog(
          'Apple login/register fail',
          JSON.stringify({ token, code })
        );
      }
    } catch (error) {
      // @ts-expect-error 先讓它 pass
      switch (error.error) {
        case AppleAuthError.CANCELLED:
        case AppleAuthError.POPUP_CLOSE:
        case AppleAuthError.NEW_SIGNIN:
        default: {
          break;
        }
      }
    }
  };

  return (
    <AppleLoginButton
      testID="appleLoginButton"
      isLoading={!SDKLoaded}
      isSupported={appleAuth.isSupported}
      onPress={handleLogin}
    />
  );
}
