import omit from 'lodash/omit';

import { NavigationParams } from '../types/NavigationParams';

export default {
  get illegalNames() {
    return [
      NavigationParams.AlsoLikeGroup,
      NavigationParams.IsUserUsedFilter,
      NavigationParams.LineID,
    ];
  },
  removeIllegalParams(params: Record<string, any>) {
    return omit(params, this.illegalNames);
  },
};
