import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { postRatingActions } from '../actions/postRatingActions';
import { postRating } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: postRatingActions.success,
  failure: postRatingActions.failure,
};

type RequestActionType = ActionType<typeof postRatingActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const postRatingEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(postRatingActions.request)),
    switchMap((action: RequestActionType) => {
      const { success, failure, transactionID, products } = action.payload;

      return postRating({
        transaction_id: transactionID,
        products,
      })
        .then(() => {
          success();

          return postRatingActions.success({
            transactionID,
          });
        })
        .catch((error: AxiosError) => {
          failure();

          return postRatingActions.failure({ error });
        });
    })
  );
};

export default postRatingEpic;
