import { Helmet } from 'react-helmet';

import { translate } from '../../i18n';
import Config from '../../utils/configs';
import jsonLd from './jsonLd.json';

interface Props {
  title: string;
  ogTitle: string;
  ogDescription: string;
  description: string;
  ogImage: string;
  url: string; // this props is only for test
  canonical?: string;
  jsonld?: Array<Record<string, any>> | Record<string, any>;
  keyword?: string;
}

const defaultProps = {
  title: '', // default title comes from i18n
  ogTitle: '', // default ogTitle comes from i18n
  ogDescription: '', // default description comes from i18n
  description: '',
  ogImage: 'https://img.ssl.verybuy.cc/resource/VeryBuy_2023og_image',
  url: '',
  canonical: '',
  keyword: '',
};

const getHrefLangUrl = (url: string, countryCode: string) => {
  const urlObject = new URL(url);
  const searchParams = urlObject.searchParams;
  const searchString = searchParams.toString().replace(/^\?/, '');
  const newSearchString = searchString
    ? `${searchString}&country_code=${countryCode}`
    : `country_code=${countryCode}`;

  urlObject.search = newSearchString;

  return urlObject.toString();
};

export default function AppHelmet(props: Props = defaultProps) {
  const title = props.title || translate('VeryBuy-title');
  const ogTitle = props.ogTitle || translate('VeryBuy-title');
  const ogImage = props.ogImage || defaultProps.ogImage;
  const url = props.url || window.location.href;
  const ogDescription =
    props.ogDescription || translate('VeryBuy-og-description');
  const deepLinkUrl = url.replace('https', `${Config.REACT_APP_SCHEME}`);
  const jsonld = (() => {
    if (!props.jsonld) return jsonLd;

    return jsonLd.concat(
      Array.isArray(props.jsonld) ? props.jsonld : [props.jsonld]
    );
  })();
  const description = props.description || defaultProps.description;
  const defaultHrefLangURL =
    `https://${Config.MHOST}` + (props.canonical || '');

  const keyword = props.keyword || translate('vb-default-keyword');

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="description" name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:secure_url" content={ogImage} />
      <meta property="og:image:type" content="jpeg" />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="600" />
      <meta property="al:ios:url" content={deepLinkUrl} />
      <meta property="al:android:url" content={deepLinkUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="VeryBuy 非常勸敗" />
      <meta property="og:locale" content="zh_TW" />
      <meta property="keyword" content={keyword} />
      {/*
        link Tag 的 rel 不能在最前面，否則會無法正常覆蓋舊 Tag
        https://github.com/nfl/react-helmet/issues/279
      */}
      <link
        href={getHrefLangUrl(defaultHrefLangURL, 'TW')}
        rel="alternate"
        hrefLang="zh-tw"
      />
      <link
        href={getHrefLangUrl(defaultHrefLangURL, 'GC')}
        rel="alternate"
        hrefLang="x-default"
      />
      <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
    </Helmet>
  );
}
