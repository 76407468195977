import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { DataFromApi } from '../../types/FilterConfigResponse';
import { getFilterConfigActions } from '../actions/getFilterConfigActions';
import { getFilterConfig } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getFilterConfigActions.success,
  failure: getFilterConfigActions.failure,
};

type RequestActionTypes = ActionType<typeof getFilterConfigActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getFilterConfigEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getFilterConfigActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { page, dispatch } = action.payload;

      return getFilterConfig(action.payload)
        .then((response: AxiosResponse<DataFromApi>) => {
          const successAction = getFilterConfigActions.success({
            data: response.data,
            page,
          });

          dispatch(successAction);

          return successAction;
        })
        .catch((error: AxiosError) => {
          const failureAction = getFilterConfigActions.failure({ error, page });

          dispatch(failureAction);

          return failureAction;
        });
    })
  );
};

export default getFilterConfigEpic;
