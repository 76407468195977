import { combineReducers } from 'redux';

import {
  AsyncReducers,
  DefaultReducers,
  RootReducer,
} from '../redux/rootReducer';

type EmitChangeFunc = (reducers: RootReducer) => void;

export class ReducerRegistry {
  private _emitChange: EmitChangeFunc | null = null;
  private _reducers = {} as RootReducer;

  set emitChange(listener: EmitChangeFunc) {
    this._emitChange = listener;
  }

  get reducers() {
    return this._reducers;
  }

  setDefaultReducers = (reducers: DefaultReducers) => {
    this._reducers = reducers;
  };

  combine = (reducers: RootReducer) => {
    return combineReducers(reducers);
  };

  register = (name: keyof AsyncReducers, reducer: any) => {
    this._reducers = { ...this._reducers, [name]: reducer };
    if (typeof this._emitChange === 'function') {
      this._emitChange(this._reducers);
    }
  };
}

const reducerRegistry = new ReducerRegistry();

export default reducerRegistry;
