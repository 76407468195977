import { Dispatch } from 'react';
import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import {
  Product,
  Data as QueryItemsData,
} from '../../types/QueryItemsResponse';
import { FormatedQueriesParams } from '../../utils/routes/qsForComplexQueries';

export interface QueryItemsPayload extends FormatedQueriesParams {
  dispatch?: Dispatch<any>;
  loadingType: ListLoadingTypes;
  page: number;
  success: (products: Product[]) => void;
}

export interface QueryItemsSuccessPayload {
  loadingType: ListLoadingTypes;
  data: QueryItemsData;
}

export interface QueryItemsFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getQueryItemsActions.request>;
}

export const getQueryItemsActions = createAsyncAction(
  'verybuy/GET_QUERY_ITEMS',
  'verybuy/GET_QUERY_ITEMS_SUCCESS',
  'verybuy/GET_QUERY_ITEMS_FAIL'
)<QueryItemsPayload, QueryItemsSuccessPayload, QueryItemsFailPayload>();
