import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { compile, pathToRegexp } from 'path-to-regexp';
import queryString from 'query-string';

import Config from '../configs';
import { getIsTW } from '../countryInfo';
import * as qsForComplexQueries from './qsForComplexQueries';
import routesConfig from './routesConfig';

const DEV = Config.REACT_APP_ENV_TYPE !== 'Release';

export const pageFlags: Record<string, boolean | 'twOnly'> = {
  VcoinPage: 'twOnly',
  EventPage: 'twOnly',
  CouponListPage: 'twOnly',
  RefundPage: 'twOnly',
  UIIcons: DEV,
};

export function isSupportRoute(routeName: string) {
  if (typeof pageFlags[routeName] === 'boolean') {
    return pageFlags[routeName];
  }

  if (pageFlags[routeName] === 'twOnly') {
    return getIsTW();
  }

  return true;
}

// 因為 testcafe 會經過 proxy，path 會變成類似：
// '/Axx8UqjfG/http://localhost:3000/member' 會對路徑的判斷會造成問題
export function formatPathname(path) {
  if (window['hammerhead|process-dom-method']) {
    return path
      .replace(/^(\/.+http(s)?:\/\/(.(?!\/))+(.){1})/, '')
      .split('?')[0];
  }

  return path.split('?')[0];
}

export function isMatchPath(scene, testPath = '', isAbsolute = true) {
  const root = isAbsolute ? '/' : '';
  const rule = routesConfig[scene];

  if (typeof rule === 'string' && isSupportRoute(scene)) {
    const thePath = formatPathname(testPath);
    const re = pathToRegexp(`${root}${rule}`);

    return !!re.exec(thePath);
  }

  return false;
}

export function compileToPath(scene, params = {}, isAbsolute = true) {
  const root = isAbsolute ? '/' : '';
  let result = '';
  const rule = routesConfig[scene];
  const ignoreEncodeScenes = [
    'ItemPage',
    'ItemPageRecommends',
    'ItemReviewsPage',
    'SearchResultPageTopId',
    'SearchResultPageTopIdIgnorePage',
  ];
  const compileOptions = {
    encode: x => {
      if (ignoreEncodeScenes.includes(scene) && /\w+:\d+/.test(x)) {
        return x;
      } else {
        return encodeURIComponent(x);
      }
    },
  };

  if (typeof rule === 'string' && isSupportRoute(scene)) {
    // 避免發生缺少參數造成 compile 錯誤
    try {
      result = `${root}${compile(rule, compileOptions)(params)}`;
    } catch (err) {
      if (__DEV__) {
        console.warn(`The path with error`, err);
      } else {
        console.error(`The path with error`, err);
      }
    }
  } else {
    if (__DEV__) {
      console.warn(`The route ${scene} is not found in config.`);
    }

    const defaultRule = routesConfig.Home;

    result = `${root}${compile(defaultRule, compileOptions)(params)}`;
  }

  return result;
}

const stringifySpecialScenesQueries = {
  QueriesPage: qsForComplexQueries.stringify,
};

// 有處理 querystring
export function getPathAndParamsFromData(
  scene,
  data,
  isAbsolute = true,
  opts: {
    liteParamKeys: string[];
  } = { liteParamKeys: ['title'] }
) {
  const { liteParamKeys } = opts;
  const path = compileToPath(scene, data, isAbsolute);
  const paramsInfo = parseFromPath(scene, path, isAbsolute);
  const params = pick(data, Object.keys(paramsInfo));
  const queries = omit(data, Object.keys(paramsInfo));
  // 除了必要的組成，目前對 path 來說，需要某些資料已達到較好的效果，如：分享
  const liteQueries = pick(queries, liteParamKeys);
  const hasSpecialStringify = !!stringifySpecialScenesQueries[scene];
  const stringify = hasSpecialStringify
    ? stringifySpecialScenesQueries[scene]
    : queryString.stringify;
  const search = stringify(queries);
  const liteSearch = stringify(liteQueries);
  const fullPath = [path];
  const litePath = [path];

  if (search.length) {
    fullPath.push(search);
  }
  if (liteSearch.length) {
    litePath.push(liteSearch);
  }

  return {
    fullPath: fullPath.join('?'),
    litePath: litePath.join('?'),
    params,
    path,
    // queries 和 navigation 的 params 保持一致
    queries: hasSpecialStringify ? queryString.parse(search) : queries,
  };
}

export function parseFromPath(scene, testPath, isAbsolute = true) {
  const rule = routesConfig[scene];

  if (typeof rule === 'string') {
    const root = isAbsolute ? '/' : '';
    const keys = [];
    const re = pathToRegexp(`${root}${rule}`, keys);
    const thePath = formatPathname(testPath);
    const m = re.exec(thePath);

    if (!m) {
      return {};
    }

    return keys.reduce((result, key: any, index) => {
      const param = m[index + 1];

      if (param) {
        const data = {
          [key.name]: decodeURIComponent(param),
        };

        // 處理資料可能是陣列的狀況
        if (key.repeat) {
          // @ts-ignore
          data[key.name] = data[key.name].split(key.delimiter);
        }

        return {
          ...result,
          ...data,
        };
      }

      return result;
    }, {});
  }

  return {};
}

export function getParamsForInit(scene, testPath, isAbsolute = true) {
  const initial = isMatchPath(scene, testPath, isAbsolute);
  const params = parseFromPath(scene, testPath);

  return {
    initial,
    ...params,
  };
}

export function getQueryFromPathByKey(testPath, key) {
  return queryString.parse(testPath.split('?')[1] || '')[key] || '';
}

const SECRET_KEY = 'page_params';

export function encodePageParams(params: {
  [key: string]: string | number;
}): string | undefined {
  return params
    ? encodeURIComponent(
        AES.encrypt(JSON.stringify(params), SECRET_KEY).toString()
      )
    : undefined;
}

export function decodePageParams(paramsStr: string | undefined): {
  [key: string]: string | number;
} {
  return paramsStr
    ? JSON.parse(
        AES.decrypt(decodeURIComponent(paramsStr), SECRET_KEY).toString(Utf8)
      )
    : {};
}
