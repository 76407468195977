import type { Dispatch } from 'react';
import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { NewArrivalResponse } from '../../types/NewArrivalResponse';

export interface NewArrivalPayload {
  loadingType: ListLoadingTypes;
  page: number;
  dispatch: Dispatch<any>;
  root_category_ids?: number[];
  per_page?: number;
  success?: Function;
}

export interface NewArrivalSuccessPayload {
  data: NewArrivalResponse;
  loadingType: ListLoadingTypes;
  page: number;
}

export interface NewArrivalFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getNewArrivalActions.request>;
}

export const getNewArrivalActions = createAsyncAction(
  'verybuy/GET_NEW_ARRIVAL',
  'verybuy/GET_NEW_ARRIVAL_SUCCESS',
  'verybuy/GET_NEW_ARRIVAL_FAIL'
)<NewArrivalPayload, NewArrivalSuccessPayload, NewArrivalFailPayload>();
