import queryString from 'query-string';
import { useCallback } from 'react';
import { useMount } from 'react-use';

import { LoginOrRegisterMethod } from '../../../../constants/LoginOrRegisterMethod';
import { NavigationParams } from '../../../../types/NavigationParams';
import Config from '../../../../utils/configs';
import { getFCMToken } from '../../../../utils/packages/firebase';
import routeUtils from '../../../../utils/routes';
import { useRoute } from '../../../../utils/useRoute';
import { openFailDialog } from '../../utils';
import type { UseLineLoginArgs } from './types';

export const getLoginDonePath = (navigationParams?) => {
  const pathInfo = routeUtils.getPathAndParamsFromData('WelcomeLoginPage', {
    [NavigationParams.BackToPageKey]:
      navigationParams[NavigationParams.BackToPageKey] || 'Me',
    [NavigationParams.EncodedPageParams]:
      navigationParams[NavigationParams.EncodedPageParams],
    inviteCode: navigationParams.inviteCode,
  });

  return pathInfo.fullPath;
};

export default function ({
  lineLogin,
  state,
  navigationParams,
  onSuccess,
}: UseLineLoginArgs) {
  const { params } = useRoute();

  useMount(() => {
    async function handleLogin() {
      if (params) {
        const {
          error,
          code,
          error_description: errorDescription,
          path,
          inviteCode,
        } = params;

        if (error && errorDescription) {
          openFailDialog(
            'LINE login/register fail',
            JSON.stringify({ error, errorDescription })
          );

          return;
        }

        if (code) {
          const fcmToken = await getFCMToken();

          lineLogin({
            code,
            fcm_token: fcmToken,
            redirectUri: `${path}&path=${encodeURIComponent(path)}`,
            loginMethod: LoginOrRegisterMethod.LINE,
            inviteCode,
            success: (...args) => {
              onSuccess(...args);
            },
          });
        }
      }
    }

    handleLogin();
  });

  const handleLineLogin = useCallback(() => {
    const lineChannelID = Config.REACT_APP_LINE_CHANNEL_ID;
    const redirectUri = `${window.location.origin}${getLoginDonePath(
      navigationParams
    )}`;
    const paramsForLine = queryString.stringify({
      response_type: 'code',
      client_id: lineChannelID,
      // 使用 path 將 redirect_uri 保存至由 LINE 導回時頁面的 query，才能送給後端
      redirect_uri: `${redirectUri}&path=${encodeURIComponent(redirectUri)}`,
      state: state.current,
      scope: 'profile openid email',
      bot_prompt: 'normal',
    });

    const lineOauthURL =
      'https://access.line.me/oauth2/v2.1/authorize?' + paramsForLine;

    window.location.href = lineOauthURL;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationParams]);

  return handleLineLogin;
}
