import showToast from '../../utils/showToast';

declare const __Config__: any;

const Config = __Config__;

// @ts-ignore-next-line
if (Config.REACT_APP_ENV_TYPE !== 'Release') {
  // rewrite env config from AsyncStorage initially
  require('./utils')
    .applyCustomConfig(Config)
    .then(customEnv => {
      if (customEnv) {
        showToast(
          `[Note] You are in [${customEnv}] Environment!`,
          undefined,
          undefined,
          5000
        );
      }
    })
    .catch(null);
}

export default Config;
