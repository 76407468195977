import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { getFCMToken } from '../../utils/packages/firebase';
import { loginByLine } from '../actions/loginByLine';
import { postLoginByLine, setAxiosDefaultHeader } from '../api';
import { RootAction } from '../rootAction';

const outputActions = {
  success: loginByLine.success,
  failure: loginByLine.failure,
};

type RequestActionTypes = ActionType<typeof loginByLine.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const loginByLineEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(loginByLine.request)),
    switchMap(async (action: RequestActionTypes) => {
      const { success, code, loginMethod, redirectUri, inviteCode } =
        action.payload;
      const fcmToken = await getFCMToken();
      const requestPayload = {
        code,
        redirectUri,
        fcm_token: fcmToken,
        invite_code: inviteCode,
      };

      return postLoginByLine(requestPayload)
        .then(response => {
          const data = response.data;

          setAxiosDefaultHeader('api-key', data.token);
          if (typeof success === 'function') {
            success(data);
          }

          return loginByLine.success({
            result: response,
            lineID: data.user_id,
            account: data.user_id,
            logEvent: data.is_login
              ? AnalyticsEvent.Login
              : AnalyticsEvent.SignUp,
            method: loginMethod,
          });
        })
        .catch((error: AxiosError) => {
          return loginByLine.failure({ error });
        });
    })
  );
};

export default loginByLineEpic;
