/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import pick from 'lodash/pick';
import { StateObservable, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import safeAwait from 'safe-await';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { SubmitBillData } from '../../types/SubmitBill';
import {
  UTM_KEYS,
  checkIsExpired,
  getUTMGroup,
  updateUTMValueByKey,
} from '../../utils/utmHelpers';
import { submitBill } from '../actions/submitBillActions';
import { postSubmitBill } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';
import { makeTransactionDetail } from '../selectors/transactionDetail';

type RequestActionTypes = ActionType<typeof submitBill.request>;
const outputActions = {
  success: submitBill.success,
  failure: submitBill.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const EXPIRED_HOURS = 24;

export async function getSubmitBillParams(submitBillData) {
  const [error, utmGroup] = await safeAwait(getUTMGroup());

  if (error) {
    return submitBillData;
  }

  const pickedUtm = pick(utmGroup, UTM_KEYS);
  const utmKeys = Object.keys(pickedUtm);
  const isExpired = checkIsExpired(utmGroup.updatedAt, EXPIRED_HOURS);

  if (utmKeys.length === 0 || isExpired) {
    return submitBillData;
  }

  const formattedUTM = utmKeys.reduce((acc, key) => {
    const formattedKey = key.replace('utm_', '');

    return {
      ...acc,
      [formattedKey]: pickedUtm[key],
    };
  }, {});

  return {
    ...submitBillData,
    ...pick(utmGroup, ['sbid']),
    utm: formattedUTM,
  };
}

const submitBillEpic = (
  action$: Observable<RootAction>,
  state$: StateObservable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(submitBill.request)),
    switchMap(async (action: RequestActionTypes) => {
      const submitBillData = makeTransactionDetail()(state$.value);
      const submitBillParams = await getSubmitBillParams(submitBillData);

      return postSubmitBill(submitBillParams)
        .then((response: AxiosResponse<SubmitBillData>) => {
          const success = action.payload?.success || undefined;

          if (success !== undefined) {
            success(response.data);
          }

          // MT-553 [FE] submit 完要將 sbid 移除
          updateUTMValueByKey('sbid', '');

          return submitBill.success({
            data: response.data,
            logEvent: AnalyticsEvent.ECommercePurchase,
          });
        })
        .catch((error: AxiosError) => {
          if (typeof action.payload.failure === 'function') {
            action.payload.failure(error.response);
          }

          return submitBill.failure({
            error,
            customErrorHandling: true,
          });
        });
    })
  );
};

export default submitBillEpic;
