import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { postDirectPayCardActions } from '../actions/postDirectPayCardActions';
import { postDirectPayCard } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof postDirectPayCardActions.request>;

const outputActions = {
  success: postDirectPayCardActions.success,
  failure: postDirectPayCardActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const postDirectPayCardEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(postDirectPayCardActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { prime } = action.payload;

      return postDirectPayCard(prime)
        .then(resp => {
          const success = action.payload?.success;

          if (success) {
            success(resp);
          }

          return postDirectPayCardActions.success();
        })
        .catch(error => {
          const failure = action.payload?.failure || undefined;

          const { data } = error.response;
          const errorCode = data.error_code;

          if (failure !== undefined) {
            failure({ error_code: errorCode });
          }

          // 假裝 v4 response，會改過 AxiosError 造成 type 不一致
          return postDirectPayCardActions.failure({
            error,
          });
        });
    })
  );
};

export default postDirectPayCardEpic;
