import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { PostPeriodTaskResponse } from '../../types/PeriodTaskResponse';
import { postPeriodTaskActions } from '../actions/postPeriodTaskActions';
import { postPeriodTask } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: postPeriodTaskActions.success,
  failure: postPeriodTaskActions.failure,
};

type RequestActionTypes = ActionType<typeof postPeriodTaskActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const postPeriodTaskEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(postPeriodTaskActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { id, onSuccess, onFailure } = action.payload;

      return postPeriodTask(id)
        .then((response: AxiosResponse<PostPeriodTaskResponse>) => {
          const { status, success } = response.data;

          if (success) {
            onSuccess();

            return postPeriodTaskActions.success({ status });
          } else {
            onFailure(status);

            return postPeriodTaskActions.failure({
              status,
              customErrorHandling: true,
            });
          }
        })
        .catch((error: AxiosError) => {
          return postPeriodTaskActions.failure({
            error,
          });
        });
    })
  );
};

export default postPeriodTaskEpic;
