import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getItemComboListActions } from '../actions/getItemComboListActions';
import { getItemComboList } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getItemComboListActions.success,
  failure: getItemComboListActions.failure,
};

type RequestActionType = ActionType<typeof getItemComboListActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getItemComboListEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getItemComboListActions.request)),
    switchMap((action: RequestActionType) => {
      const { id } = action.payload;

      return getItemComboList(action.payload)
        .then(response =>
          getItemComboListActions.success({
            id,
            data: response.data,
          })
        )
        .catch((error: AxiosError) =>
          getItemComboListActions.failure({ error })
        );
    })
  );
};

export default getItemComboListEpic;
