import { createAsyncAction } from 'typesafe-actions';

import { Data as CategoryListData } from '../../types/CategoryListResponse';

export interface CategoryListPayload {}

export interface CategoryListSuccessPayload {
  data: CategoryListData;
}

export interface CategoryListFailPayload {
  error: Error;
}

export const getCategoryListActions = createAsyncAction(
  'verybuy/GET_CATEGORY_LIST',
  'verybuy/GET_CATEGORY_LIST_SUCCESS',
  'verybuy/GET_CATEGORY_LIST_FAIL'
)<CategoryListPayload, CategoryListSuccessPayload, CategoryListFailPayload>();
