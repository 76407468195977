import pick from 'lodash/pick';
import safeAwait from 'safe-await';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { SensorsAnalyticsEvent } from '../../constants/SensorsAnalyticsEvent';
import { getPlatformCode, patchNotificationRead } from '../../redux/api';
import sensors from '../analytics/sensors';
import { transformToStandardEvent } from '../analytics/transformToStandardEvent';
import firebase from '../packages/firebase';
import { getIsDisableNotiPermission } from '../permissionHelpers';
import { registerSuperProperties } from '../sensorsHelpers';
import {
  UTM_KEYS,
  getUTMGroupFromURL,
  handleSaveUTMIfExists,
} from '../utmHelpers';
import configurePushNotification from './configurePushNotification';
import { PushNotificationNavigationParams } from './types';

export const parsePushData = logData => {
  const data = logData || {};
  const { messageId, title, content, routePath, pushType } = data;

  return {
    pushID: messageId || 'UNKNOWN_PUSH_ID',
    push_title: title || 'UNKNOWN_PUSH_TITLE',
    push_content: content || 'UNKNOWN_PUSH_CONTENT',
    page_redirect: decodeURI(routePath || 'UNKNOWN_PUSH_REDIRECT'),
    push_type: pushType || 'UNKNOWN_PUSH_TYPE',
  };
};

/**
 * CAUTION: 推播不參與 App 生命週期，不能使用 store 與 action
 */
export default async (
  remoteMessage,
  {
    type,
    eventName,
  }: Pick<PushNotificationNavigationParams, 'type' | 'eventName'>
) => {
  const { logData, bootstrap } = configurePushNotification(remoteMessage, type);

  if (logData) {
    const logPayload = parsePushData(logData);

    firebase
      .analytics()
      .setUserProperties({
        platform_type: getPlatformCode().toString(),
      })
      .catch(e => {
        console.log('firebase logEvent error = ', e);
      });

    switch (eventName) {
      case AnalyticsEvent.PushReceived: {
        const [error, isDisabled] = await safeAwait(
          getIsDisableNotiPermission()
        );

        if (error || isDisabled) {
          break;
        }

        sensors.track(
          transformToStandardEvent(SensorsAnalyticsEvent)[eventName],
          logPayload
        );
        sensors.flush();

        firebase
          .analytics()
          .logEvent(eventName, logPayload)
          .catch(e => {
            console.log('firebase logEvent error = ', e);
          });
        break;
      }
      case AnalyticsEvent.PushClick:
        let logPayloadWithUTM = Object.assign({}, logPayload);
        const redirectURL = logPayload.page_redirect;

        if (redirectURL) {
          const utmGroup = getUTMGroupFromURL(redirectURL);

          if (utmGroup) {
            handleSaveUTMIfExists(redirectURL);

            const utmGroupForSensor = Object.keys(utmGroup).reduce(
              (result, key: string) => {
                result[`$${key}`] = utmGroup[key];
                result[`$latest_${key}`] = utmGroup[key];

                return result;
              },
              {}
            );
            const superProperties = pick(
              utmGroupForSensor,
              UTM_KEYS.map(v => `$latest_${v}`)
            );

            registerSuperProperties({ ...superProperties, useDynamic: true });

            logPayloadWithUTM = {
              ...logPayloadWithUTM,
              ...utmGroupForSensor,
            };
          }
        }

        sensors.track(
          transformToStandardEvent(SensorsAnalyticsEvent)[eventName],
          logPayloadWithUTM
        );

        firebase
          .analytics()
          .logEvent(eventName, logPayloadWithUTM)
          .catch(e => {
            console.log('firebase logEvent error = ', e);
          });
        break;
      default:
        if (__DEV__) {
          console.error('log event not handled');
        }
        break;
    }
  }

  if (eventName === AnalyticsEvent.PushClick) {
    const notificationId = logData.notificationId;

    if (notificationId) {
      patchNotificationRead(notificationId).catch(console.log);
    }

    bootstrap();
  }
};
