import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import {
  LandingPageType,
  TopKeywords,
  TopKeywordsStyle,
} from '../../types/TopKeywordsResponse';
import { actionsList } from '../rootAction';

const { getTopKeywordsActions } = actionsList;

export interface State {
  isFetching: boolean;
  hasError: boolean;
  error?: Error | null;
  data: TopKeywords[];
  placeholderIndex: number;
}

export const defaultState: State = {
  isFetching: false,
  hasError: false,
  error: null,
  data: [],
  placeholderIndex: -1,
};

const allowedTypes = Object.values(LandingPageType).reduce(
  (acc, cur) => acc.add(cur),
  new Set()
);

const allowedStyles = Object.values(TopKeywordsStyle).reduce(
  (acc, cur) => acc.add(cur),
  new Set()
);

const allowedKeywords = ({ landing_page: landingPage, style }: TopKeywords) => {
  return allowedTypes.has(landingPage) && allowedStyles.has(style);
};

export default produce(
  (draft: State, action: ActionType<typeof getTopKeywordsActions>) => {
    switch (action.type) {
      case getType(getTopKeywordsActions.request): {
        draft.isFetching = true;
        break;
      }
      case getType(getTopKeywordsActions.success): {
        const { data } = action.payload;

        draft.isFetching = false;
        draft.hasError = false;
        draft.error = null;
        draft.data = data.top_keywords.filter(allowedKeywords);

        if (draft.data.length > 0) {
          const randomIndex = Math.floor(Math.random() * draft.data.length);

          draft.placeholderIndex = randomIndex;
        }

        break;
      }
      case getType(getTopKeywordsActions.failure): {
        draft.isFetching = false;
        draft.hasError = true;
        draft.error = action.payload.error;
        break;
      }
      default: {
        break;
      }
    }
  },
  defaultState
);
