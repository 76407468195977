import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { CVSItem } from '../../types/DeliveryData';
import { actionsList } from '../rootAction';

const { loadDeliveryData, updateCVSReceivingData } = actionsList;

export interface State {
  isLoading: boolean;
  data: CVSItem[];
  hasError: boolean;
  error: any | null;
}

export const defaultState = {
  isLoading: true,
  data: [] as CVSItem[],
  hasError: false,
  error: null,
};

type Actions =
  | typeof loadDeliveryData.request
  | typeof loadDeliveryData.success
  | typeof loadDeliveryData.failure
  | typeof updateCVSReceivingData.success
  | typeof updateCVSReceivingData.failure;

export default produce((draft: State, action: ActionType<Actions>) => {
  switch (action.type) {
    case getType(loadDeliveryData.request): {
      draft.isLoading = true;
      break;
    }
    case getType(loadDeliveryData.success): {
      draft.isLoading = false;
      draft.hasError = false;
      draft.error = null;
      draft.data = action.payload.data.cvs_list;
      break;
    }
    case getType(updateCVSReceivingData.failure):
    case getType(loadDeliveryData.failure): {
      draft.isLoading = false;
      draft.hasError = true;
      draft.error = action.payload.error;
      break;
    }
    case getType(updateCVSReceivingData.success): {
      const newData = draft.data
        ? [action.payload.data, ...draft.data].slice(0, 10)
        : [action.payload.data];

      draft.isLoading = false;
      draft.hasError = false;
      draft.error = null;
      draft.data = newData;
      break;
    }
    default:
      break;
  }
}, defaultState);
