// 不能接 navigationService、會造成 circular dependency error
import queryString from 'query-string';

import { NavigationParams } from '../types/NavigationParams';
import { sendWebScreenView } from './analytics/screenViewEvent';
import handleIllegalParams from './handleIllegalParams';
import handleLineId from './handleLineId';
import routesConfig from './routes/routesConfig';
import * as routeUtils from './routes/utils';

function getPathAndParamsFromLocation(_location) {
  const path = encodeURI(_location.pathname.substr(1));
  const params = queryString.parse(_location.search);

  return { path, params };
}

function getFullPathFromLocation(_location, isAbsolute = true): string {
  const fullPath = `${_location.pathname}${_location.search}`;

  return isAbsolute ? fullPath : fullPath.slice(1);
}

interface Breakpoints {
  [key: string]: number;
}

export class BrowserHistoryPatch {
  constructor(history, location) {
    this._history = history;
    this._location = location;
  }

  _history?: History;
  _location?: Location;
  _replaceRoutes: string[] = ['Cart', 'Me'];
  _current: number = Date.now();
  _breakpoints: Breakpoints = this._replaceRoutes.reduce((result, val) => {
    return {
      ...result,
      [val]: this._current,
    };
  }, {});
  _pathHistories: string[] = [];

  get breakpointRules() {
    return [
      // 在 onHistoryMatchNavigation 都會更新 _current
      {
        screen: 'Cart',
        always: false,
        rules: [
          /^member\/cart(\/)?$/,
          /^member\/stackcart(\/)?$/,
          /^review_bill\/result\/(\d)+$/,
        ],
      },
      {
        screen: 'Me',
        always: false,
        rules: [/^member\/support_faq$/, /^member\/mailbox$/],
      },
      // 在 onHistoryChange, onHistoryMatchNavigation 都會更新 _current
      // {
      //   screen: 'TransactionListPage',
      //   always: true,
      //   rules: [/(.)+/],
      // },
    ];
  }

  get illegalRules() {
    return [
      {
        screen: 'Cart',
        rules: [/^member\/cart\/(.)+$/],
      },
      {
        screen: 'Me',
        rules: [/^member\/support(.+)?$/],
      },
      // {
      //   screen: 'TransactionListPage',
      //   rules: [/^transactions\/external$/],
      // },
    ];
  }

  getHistoryState() {
    return this._history?.state || {};
  }

  getDocumentTitle() {
    const defaultTitle = '';
    const title = window.document ? window?.document?.title : defaultTitle;

    return title || defaultTitle;
  }

  getScreenByPathWithRules = (testPath, checkRules) => {
    const replaceInfo = checkRules.find(info => {
      return info.rules.some(rule => rule.test(testPath));
    });

    return replaceInfo ? replaceInfo.screen : '';
  };

  getScreensByPathWithBreakRules = (testPath, checkRules, eType?) => {
    const { t: stateT } = this.getHistoryState();
    const replaceInfos = checkRules.reduce((result: string[], info) => {
      const isMatchRule = info.rules.some(rule => rule.test(testPath));

      if (isMatchRule) {
        const shouldReturnResult =
          eType === 'onHistoryChange'
            ? info.always
            : stateT !== this._breakpoints[info.screen];

        if (shouldReturnResult) {
          result.push(info.screen);
        }
      }

      return result;
    }, [] as string[]);

    return replaceInfos;
  };

  updateBreakpoints = (testPath, eType?) => {
    const theScreens = this.getScreensByPathWithBreakRules(
      testPath,
      this.breakpointRules,
      eType
    );

    theScreens.forEach(screen => {
      this._breakpoints[screen] = this._current;
    });
  };

  get mergeTheseScreensIfTwice() {
    return ['Categories'];
  }

  getReplaceScreenWithRules = testPath => {
    const specialScreens = this.mergeTheseScreensIfTwice;

    if (this._pathHistories.length) {
      const prevPath = this._pathHistories.slice(-1)[0];
      const currentPath = `/${testPath}`;
      const replaceScreen = specialScreens.find(screen => {
        const isPrevMatch = routeUtils.isMatchPath(screen, prevPath, true);
        const isCurrMatch = routeUtils.isMatchPath(screen, currentPath, true);

        return isPrevMatch && isCurrMatch;
      });

      return replaceScreen;
    }

    return undefined;
  };

  // 直接進到這些頁面，前面會塞一個首頁
  get replaceHomeForInitScreens() {
    return ['ItemPage'];
  }

  get shouldReplaceHomeForInit() {
    if (this._history && this._location) {
      const currentPathAndParams = getPathAndParamsFromLocation(this._location);

      return this.replaceHomeForInitScreens.some(screen => {
        return routeUtils.isMatchPath(screen, currentPathAndParams.path, false);
      });
    }

    return false;
  }

  getInitUrl = _location => {
    const currentPathAndParams = getPathAndParamsFromLocation(_location);
    const { clid } = currentPathAndParams.params;
    const legalParams = handleIllegalParams.removeIllegalParams(
      currentPathAndParams.params
    );
    const legalParamsStr = queryString.stringify(legalParams);

    // Note: filter clid parameters and save line id
    // https://verybuycc.atlassian.net/browse/FT-96
    if (clid) {
      // save in BrowserHistoryPatch instance
      handleLineId.setClid(clid);
    }

    return [
      _location.pathname,
      legalParamsStr.length ? '?' : '',
      legalParamsStr,
    ].join('');
  };

  initial = initActionFromReactNavigation => {
    if (this._history && this._location) {
      const initState = this._history.state || {};
      const initTitle = this.getDocumentTitle();
      const initUrl = this.getInitUrl(this._location);

      // RNWEB-635 - iOS 14 safari 會碰到進入網站後的第一個 browser history push 失敗的問題 沒有追到確定的原因
      // 但藉由手動 push 一個首頁進來可以修正這個問題
      // 故暫時拿掉 this.shouldReplaceHomeForInit 的判斷
      this._history.replaceState(
        {
          t: this._current,
        },
        'VeryBuy Fashion | 多風格時尚購物',
        '/'
      );

      this._history.pushState(
        {
          ...initState,
          t: this._current,
        },
        initTitle,
        initUrl
      );

      this._pathHistories.push(getFullPathFromLocation(this._location));
    }

    const { params, ...initAction } = initActionFromReactNavigation;

    return {
      ...initAction,
      params: handleIllegalParams.removeIllegalParams(params || {}),
    };
  };

  // action: it is from history event, PUSH or POP
  // pathAndParams: it is created by react-navigation from createBrowserApp
  // returns:
  //   true -> it will continue
  //   false -> it will stop
  onHistoryChange = ({ action, pathAndParams }) => {
    sendWebScreenView();
    if (!this._history || !this._location) {
      return true;
    }

    if (action === 'PUSH') {
      const thePath = getFullPathFromLocation(this._location);

      this._pathHistories.push(thePath);
    }

    if (action === 'POP') {
      const replaceScreen = this.getScreenByPathWithRules(
        pathAndParams.path,
        this.illegalRules
      );
      const { t: stateT } = this.getHistoryState();

      this.updateBreakpoints(pathAndParams.path, 'onHistoryChange');

      // 符合購物流程 path + state.count < breakpoint 的時候要 replace
      if (replaceScreen.length && stateT < this._breakpoints[replaceScreen]) {
        this._location.replace(routeUtils.compileToPath(replaceScreen));

        return false;
      }

      this._pathHistories.pop();
    }

    this._current = Date.now();

    return true;
  };

  // pathAndParams: it is created by react-navigation from createBrowserApp
  onHistoryMatchNavigation = ({ pathAndParams }) => {
    if (!this._history || !this._location) {
      return;
    }

    const { t: stateT } = this.getHistoryState();

    this.updateBreakpoints(pathAndParams.path, 'onHistoryMatchNavigation');

    // 沒有 state.count 的話，要加進去
    if (!stateT) {
      this._history.replaceState(
        {
          ...(this._history.state || {}),
          t: this._current,
        },
        this.getDocumentTitle()
      );
    }
  };

  // it called after onHistoryMatchNavigation but before dispatch react-navigation action
  // navigationAction: it is same as the parameter for react-navigation dispatch
  onHistoryTriggerNavigate = navigationAction => {
    let { routeName, params } = navigationAction;

    // tab
    if (navigationAction.action) {
      routeName = navigationAction.action.routeName;
      params = navigationAction.action.params;
    }

    const currentNavigatePath = routeUtils.getPathAndParamsFromData(
      routeName,
      params
    );

    if (this._pathHistories.length) {
      const theLatestPathHistory = this._pathHistories.slice(-1)[0];

      if (theLatestPathHistory !== currentNavigatePath.fullPath) {
        this._pathHistories.push(currentNavigatePath.fullPath);
      }
    } else {
      this._pathHistories.push(currentNavigatePath.fullPath);
    }
  };

  // pathAndParams: it is created by react-navigation from createBrowserApp
  getCustomNavOpts = pathAndParams => {
    if (
      !this._location ||
      pathAndParams.params[NavigationParams.BackToPageKey]
    ) {
      return {};
    }

    return this._location.hash.length > 1
      ? { key: this._location.hash.slice(1) }
      : {};
  };

  // returns
  // invalid:
  //   true: not found
  //   false: found
  // path: 主要是要處理有 page 的列表，不希望在 AppRoutes 的部分在多個定義
  detectRouteNotFound = (): { invalid: boolean; path?: string } => {
    if (!this._history || !this._location) {
      return {
        invalid: true,
      };
    }

    const { _location } = this;
    const currentPathAndParams = getPathAndParamsFromLocation(_location);
    const allRoutes = Object.keys(routesConfig);
    let thePath = '';

    const result = allRoutes.some(theScreen => {
      const theResult = routeUtils.isMatchPath(
        theScreen,
        currentPathAndParams.path,
        false
      );

      if (theResult && /Ignore(.)+$/.test(theScreen)) {
        const newScreen = theScreen.replace(/Ignore(.)+$/, '');
        const theParams = routeUtils.parseFromPath(
          theScreen,
          window.decodeURIComponent(currentPathAndParams.path),
          false
        );

        thePath = routeUtils.getPathAndParamsFromData(newScreen, {
          ...currentPathAndParams.params,
          ...theParams,
        }).fullPath;
      }

      return theResult;
    });

    return {
      invalid: !result,
      path: thePath,
    };
  };

  setHash = newHash => {
    if (this._history) {
      this._history.replaceState(
        this._history.state || {},
        this.getDocumentTitle(),
        `#${newHash}`
      );
    }
  };

  redirectWithScene = (
    scene: string,
    params: any = {},
    shouldReplace: boolean = false
  ) => {
    if (!this._location) {
      return;
    }

    const path = routeUtils.getPathAndParamsFromData(scene, params).fullPath;

    if (shouldReplace) {
      this._location.replace(path);
    } else {
      this._location.href = path;
    }
  };

  /********************************************************************************************
   * 替代 react-navigation 在 web 組成 path 的功能
   * 原來的行為會將特殊符號 encode
   * 目前希望
   * 1. 只針對 ItemPage 的 id 做處理（不 encode）
   * 2. query 一樣要 encode
   *
   * note
   * 1. 若 react-navigation 升級，該方式無法運作
   * 2. 目前已無 tab，不需考慮 tab
   *********************************************************************************************/
  getPathAndParamsForState = (state, _action) => {
    // refs: https://github.com/react-navigation/react-navigation/blob/4.x/packages/core/src/routers/StackRouter.js#L618
    const route = state.routes[state.index];
    const legalParams = state.index
      ? route.params
      : handleIllegalParams.removeIllegalParams(route.params);
    const isAbsolute = false;
    const pathAndParams = routeUtils.getPathAndParamsFromData(
      route.routeName,
      legalParams,
      isAbsolute
    );

    const replaceScreen = this.getReplaceScreenWithRules(
      pathAndParams.fullPath
    );

    if (this._history && replaceScreen) {
      const theReplacePath = `/${pathAndParams.fullPath}`;

      this._history.replaceState(
        this._history.state || {},
        this.getDocumentTitle(),
        theReplacePath
      );
      this._pathHistories.splice(-1, 1, theReplacePath);

      return 'replaceState';
    }

    return {
      path: pathAndParams.path,
      params: pathAndParams.queries,
      fullPath: pathAndParams.fullPath,
    };
  };

  /********************************************************************************************
   * !!!!!!!!!! DANGER !!!!!!!!!!
   * 可由外部操作，請『小心謹慎的評估』並且『在正確的時間』使用
   *
   * 強制更新該 screen 的 breakpoint，中斷其流程，讓返回該流程頁面的時候必須從頭開始
   * 如：
   * CheckoutPage -> PaymentPage -> _DANGER_updateBreakpoints('Cart') -> 返回 CheckoutPage
   * 會將 CheckoutPage 導至 Cart 重新開始
   *
   * 在錯誤的時間使用，如：
   * CheckoutPage -> CouponSelectPage -> _DANGER_updateBreakpoints('Cart') -> 返回 CheckoutPage
   * 會將 CheckoutPage 導至 Cart 重新開始
   *********************************************************************************************/
  _DANGER_updateBreakpoints = screen => {
    this._breakpoints[screen] = Date.now();
  };
}

export default new BrowserHistoryPatch(window.history, window.location);
