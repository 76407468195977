import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { updateCartItemAmount } from '../actions/updateCartItemAmountActions';
import { patchCartVirtualProduct } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof updateCartItemAmount.request>;
const outputActions = {
  success: updateCartItemAmount.success,
  failure: updateCartItemAmount.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const updateCartItemAmountEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(updateCartItemAmount.request)),
    debounceTime(200),
    switchMap((action: RequestActionTypes) => {
      const { virtualProductID, amount } = action.payload;

      return patchCartVirtualProduct(virtualProductID, amount)
        .then(() => {
          return updateCartItemAmount.success({
            virtualProductID,
            amount,
          });
        })
        .catch((error: AxiosError) =>
          updateCartItemAmount.failure({
            virtualProductID,
            prevAmount: amount - 1,
            error,
          })
        );
    })
  );
};
