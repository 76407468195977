// @ts-ignore 避掉 no-unused-vars 規則
import { Platform } from 'react-native';

import { DeviceType } from '../types/Common';
import Config from '../utils/configs';
import { getDeviceType } from '../utils/layoutHelpers';

// @ts-ignore 避掉 no-unused-vars 規則
const DEV = Config.REACT_APP_ENV_TYPE !== 'Release';

export default {
  /**
   * - 大尺寸螢幕尚有問題，先開 mobile 就好
   * - 若穩定後可將此 Flag 替換成 Platform.OS === 'web'
   */
  CX_659_PULL_TO_ANCHOR_RECOMMEND:
    Platform.OS === 'web' && getDeviceType() === DeviceType.MOBILE,
  // NOTE: 壓貨暫停開發
  GROWTH_715_OVERSTOCK_CONTAINER: false,
  CX_2310_MULTI_PIECE_DISCOUNT: DEV,
  // before remove: 移除時向 PM 確認「延遲發貨補償金」狀態。
  CX_2415_DISABLED_LATE_DELIVERY_PROMISE: true,
  SEARCH_174_OPTIMIZE_PRODUCT_LIST: false,
  SEARCH_211_SELLS_AMOUNT_FIELD: true,
  // SEARCH-175 目前先上線陽春版功能、待後續開票繼續開發原先功能、並將此 Flag 票號改為新票號
  SEARCH_XXX_RECOMMEND_COMBO_EXPECTED: false,
  CX_2464_COUPON_VCOIN_SELECT: true,
  // CX-2444 另有 remote config 控制功能開關
  CX_2444_OVERSEA_SHIPPING_FEE: true,
  IM_78_R18_HINT: true,
};
