export const isCodePushEnabled = () => false;

export const getCodePushMetadata = () => Promise.resolve(null);

export const withCodePush = App => App;

export default {
  CheckFrequency: {},
  InstallMode: {},
  DeploymentStatus: {},
  SyncStatus: {},
  UpdateState: {},
  allowRestart: console.log,
  checkForUpdate: () => Promise.resolve(),
  clearUpdates: console.log,
  disallowRestart: console.log,
  getUpdateMetadata: () => Promise.resolve(),
  notifyAppReady: () => Promise.resolve(),
  restartApp: console.log,
  sync: console.log,
};
