import { createAsyncAction } from 'typesafe-actions';

export interface DirectPayCardPayload {
  prime: string;
  success?: Function;
  failure?: Function;
}

export interface DirectPayCardSuccessPayload {
  data?: {
    use_three_domain_secure: boolean;
    redirect_url: string;
    status: boolean;
  };
}

export interface DirectPayCardFailPayload {
  error: Error;
}

export const postDirectPayCardActions = createAsyncAction(
  'verybuy/POST_DIRECT_PAY_CARD',
  'verybuy/POST_DIRECT_PAY_CARD_SUCCESS',
  'verybuy/POST_DIRECT_PAY_CARD_FAIL'
)<DirectPayCardPayload, void, DirectPayCardFailPayload>();
