import { translate } from '../../../i18n';
import { testIDs } from '../../../constants/testIDs';
import S from './styles';

interface Props {
  isCollected: boolean;
  children?: JSX.Element;
  handleOnPress: () => void;
}

export default ({ isCollected, children, handleOnPress }: Props) => {
  const collectionWord = isCollected
    ? 'already-collection'
    : 'not-yet-collection';

  return (
    <S.LovesElementContent
      testID={testIDs.LovesElement.Button}
      accessibilityRole="button"
      onPress={handleOnPress}
    >
      {children}
      <S.LovesElementText numberOfLines={1} accessibilityRole="text">
        {translate(collectionWord)}
      </S.LovesElementText>
    </S.LovesElementContent>
  );
};
