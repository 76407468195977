export const getActiveRouteName = ({ routeName, params }) => {
  if (routeName === 'WebViewPage' || routeName === 'ShoppingWebView') {
    return params.screenViewName;
  } else if (routeName === 'ItemPage') {
    return `${routeName}-${params.id}`;
  }

  return routeName;
};

export const processScreenView = (currentRouteName: string): string => {
  if (currentRouteName === 'Home') {
    return 'home';
  } else if (currentRouteName === 'Cart') {
    return 'cartPage';
  } else if (currentRouteName === 'Me') {
    return 'myPage';
  }

  return currentRouteName[0].toLowerCase() + currentRouteName.slice(1);
};

export const processItemPageScreenView = (currentRouteName: string): string => {
  if (!currentRouteName.startsWith('ItemPage')) {
    return currentRouteName;
  }

  const [, id] = currentRouteName.split('-');

  return `user_submit/item/${id}`;
};
