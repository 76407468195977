import pick from 'lodash/pick';
import { PureComponent } from 'react';
import { View } from 'react-native';

import IconListItemLike from '../../assets/svg/IconListItemLike';
import IconListItemLikeFilled from '../../assets/svg/IconListItemLikeFilled';
import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { neutralDark } from '../../constants/color';
import ElementType from '../../constants/ElementType';
import FeatureFlags from '../../constants/FeatureFlags';
import { LAYOUT_MAX_WIDTH } from '../../constants/layout';
import { MktType } from '../../constants/MktType';
import withDisplayImpression from '../../decorators/withDisplayImpression';
import AvgRatingStars from '../../elements/AvgRatingStars';
import ItemLabel from '../../elements/ItemLabel';
import LineThroughPrice from '../../elements/LineThroughPrice';
import PriceRange from '../../elements/PriceRange';
import Touchable from '../../elements/Touchable';
import { translate } from '../../i18n';
import { ItemTypes, WebLinkOpenType } from '../../types/Common';
import { WebPropsInterface } from '../../types/WebPropsInterface';
import * as navigationService from '../../utils/navigationService';
import routeUtils from '../../utils/routes';
import LovesElement from '../LovesElement';
import ColorAmountTag from './ColorAmountTag';
import EmptyLoadingScene from './EmptyLoadingScene';
import styles, { S } from './ListItem.style';
import R18Hint from './R18Hint';
import R18HintModal from './R18Hint/R18HintModal';
import Ribbon from './Ribbon';
import { Props } from './types';
import Vendor from './Vendor';
import {
  HotSaleIcon,
  InvalidHint,
  ProductImage,
  SalesAmount,
  Title,
} from './widget';
import withIntersectionObserver from './withIntersectionObserver';

export interface State {
  showLoading: boolean;
  isModalOpen: boolean;
}

class ListItem extends PureComponent<Props, State> {
  static defaultProps = {
    showLoading: true,
    showRibbon: true,
    itemWidth: LAYOUT_MAX_WIDTH / 2,
    itemMargin: 2,
    enableWebImpression: false,
    showTitlesAndPrice: true,
    elementType: ElementType.Item,
    withBorder: false,
    shouldDisplayImpressionForDebug: false,
    isShowItemLabel: true,
    showLineThroughPrice: true,
    itemType: ItemTypes.USER_SUBMIT,
    navigateParams: {},
    showVendor: false,
    showPrice: true,
    showRating: false,
    showRatingText: false,
    ratingStarSize: 12,
    // TODO: showSalesAmount is for SEARCH-218
    showSalesAmount: true,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showLoading !== prevState.showLoading) {
      return {
        showLoading: nextProps.showLoading,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      showLoading: props.showLoading,
      isModalOpen: false,
    };
  }

  createWebProps = (): WebPropsInterface => {
    const params = pick(this.props, ['id', 'marketID', 'country_code']);
    const pathInfo = routeUtils.getPathAndParamsFromData('ItemPage', {
      ...params,
      ...this.props.navigateParams,
    });

    return {
      accessibilityRole: 'link',
      href: pathInfo.fullPath,
      target: WebLinkOpenType.SELF,
    };
  };

  onImageLoad = () => {
    this.setState({ showLoading: false });
  };

  goToItemPage = () => {
    const {
      id,
      itemType,
      index,
      marketID,
      logEvent,
      layoutName,
      source,
      elementType,
      currentSubCat,
      navigateParams,
      item,
      country_code,
      onNavigate,
      isHomePageClick,
    } = this.props;

    if (
      item.child_category_id === 180 &&
      !this.props.hasReadGuide &&
      FeatureFlags.IM_78_R18_HINT
    ) {
      this.setState({ isModalOpen: !this.state.isModalOpen });

      if (!this.state.isModalOpen) {
        return;
      }
    }

    logEvent({
      logEvent: AnalyticsEvent.Click,
      data: {
        id,
        itemType,
        pitNumber: index + 1,
        layoutName,
        source,
        mktType: MktType.Image,
        marketID,
        elementType,
        currentSubCat,
        title: item.title,
      },
    });
    if (isHomePageClick) {
      logEvent({
        logEvent: AnalyticsEvent.HomePageClick,
        data: {
          index: index + 1,
          layoutName,
          title: item.title,
        },
      });
    }

    if (onNavigate) {
      onNavigate();
    }

    navigationService.navigate('ItemPage', {
      id,
      marketID,
      country_code,
      ...navigateParams,
    });
  };

  webImg: HTMLImageElement | null = null;

  withWrapper = child => {
    const { _debugOnLayout } = this.props;

    if (__DEV__) {
      if (_debugOnLayout) {
        return <View onLayout={_debugOnLayout}>{child}</View>;
      }
    }

    return child;
  };

  bindWebImgRef = (_: HTMLImageElement) => (this.webImg = _);

  renderImage = itemSize => {
    const {
      index,
      item,
      hasHotSaleIcon,
      showRibbon,
      isOutOfStock,
      isNotAllowed,
    } = this.props;

    // IM-78 情趣內睡衣子分類為 180
    const showR18Hint =
      item.child_category_id === 180 &&
      !this.props.hasReadGuide &&
      FeatureFlags.IM_78_R18_HINT;

    return (
      <>
        {showR18Hint && <R18Hint size={itemSize} />}
        <ProductImage
          imageUrl={item.image_url}
          webImgRef={this.bindWebImgRef}
          size={itemSize}
          onLoad={this.onImageLoad}
        />
        {(isOutOfStock || isNotAllowed) && (
          <InvalidHint size={itemSize} isNotAllowed={isNotAllowed} />
        )}
        {hasHotSaleIcon && <HotSaleIcon index={index} />}
        {showRibbon && <Ribbon id={`${item.id}`} />}
      </>
    );
  };

  renderContent = itemSize => {
    const {
      item,
      contentWrapperStyle,
      titleStyle,
      priceContainerStyle,
      priceCurrencyStyle,
      priceTildeStyle,
      pricePostfixStyle,
      priceStyle,
      lineThroughPriceStyle,
      showTitlesAndPrice,
      labelColor,
      isShowItemLabel,
      showLineThroughPrice,
      isOutOfStock,
      isNotAllowed,
      showSKUTag,
      showVendor,
      showPrice,
      showRating,
      showRatingText,
      ratingStarSize,
      linesOfTitle,
      showSalesAmount,
    } = this.props;
    const contentStyle = [{ width: itemSize }, contentWrapperStyle];

    return (
      showTitlesAndPrice && (
        <S.Content style={contentStyle}>
          <View>
            {showSKUTag && this.renderSKUTag()}
            <Title
              title={item.title}
              isOffline={isOutOfStock || isNotAllowed}
              titleStyle={titleStyle}
              linesOfTitle={linesOfTitle}
            />
            {/* TODO: 移除 FeatureFlags 前記得找 PM 確認，賣家名稱是否要拿掉。 */}
            {!FeatureFlags.SEARCH_174_OPTIMIZE_PRODUCT_LIST && showVendor && (
              <Vendor id={item.id} />
            )}
            {showRating && !!item.reviews?.average_score && (
              <S.AvgRatingWrapper>
                <AvgRatingStars
                  size={ratingStarSize}
                  rating={item.reviews?.average_score}
                  showRatingText={showRatingText}
                />
              </S.AvgRatingWrapper>
            )}
            {/* TODO: set text from api response */}
            {FeatureFlags.GROWTH_715_OVERSTOCK_CONTAINER && isShowItemLabel && (
              <ItemLabel
                text={translate('fast-delivery-text')}
                itemLabelStyle={styles.itemLabelStyle}
                color={labelColor}
              />
            )}
          </View>
          {showPrice && (
            <S.PriceWrapper>
              <PriceRange
                currencyStyle={[styles.priceCurrency, priceCurrencyStyle]}
                priceStyle={[styles.priceText, priceStyle]}
                tildeStyle={[styles.tildeText, priceTildeStyle]}
                postfixStyle={pricePostfixStyle}
                priceContainerStyle={[
                  styles.priceContainer,
                  priceContainerStyle,
                ]}
                minPrice={item.min_price}
                maxPrice={item.max_price}
              />
              {showLineThroughPrice && (
                <LineThroughPrice
                  currentPrice={item.max_price}
                  originalPrice={item.original_max_price}
                  style={lineThroughPriceStyle}
                />
              )}
            </S.PriceWrapper>
          )}
          {FeatureFlags.SEARCH_174_OPTIMIZE_PRODUCT_LIST && showSalesAmount && (
            <SalesAmount id={item.id} />
          )}
        </S.Content>
      )
    );
  };

  renderActiveLoveIcon = () => {
    return (
      <S.LoveIconWrapper>
        <S.LoveImage source={require('./assets/ic_wishlist_bg.png')} />
        <S.IconWrapper>
          <IconListItemLikeFilled size={12} />
        </S.IconWrapper>
      </S.LoveIconWrapper>
    );
  };

  renderInactiveLoveIcon = () => {
    return (
      <S.LoveIconWrapper>
        <S.LoveImage source={require('./assets/ic_wishlist_bg.png')} />
        <S.IconWrapper>
          <IconListItemLike size={12} fillColor={neutralDark} />
        </S.IconWrapper>
      </S.LoveIconWrapper>
    );
  };

  renderSKUTag = () => {
    const { id } = this.props;

    return (
      <S.SKUTagWrapper>
        <ColorAmountTag id={id} />
      </S.SKUTagWrapper>
    );
  };

  render() {
    const {
      itemWidth,
      itemMargin,
      index,
      wrapperStyle,
      logData,
      withBorder,
      withBorderRadius,
      id,
      showLovesElement,
      layoutName,
      showTitlesAndPrice,
      showRating,
    } = this.props;
    // itemWidth 已是 ListItem 該有的寬度，只需扣掉左右兩邊即是圖片的大小
    const itemSize = itemWidth - itemMargin * 2;
    const { showLoading } = this.state;
    const itemStyle = [
      styles.listItem,
      // Don't use marginHorizontal because wrapperStyle cannot override it
      { maxWidth: itemSize, marginLeft: itemMargin, marginRight: itemMargin },
      wrapperStyle,
    ];

    if (showLoading) {
      return (
        <View
          style={[
            itemStyle,
            showTitlesAndPrice && styles.emptyLoadingSceneWrapper,
          ]}
        >
          <EmptyLoadingScene
            width={itemSize}
            showTitlesAndPrice={showTitlesAndPrice}
            showRating={showRating}
          />
        </View>
      );
    }

    const webProps = this.createWebProps();
    const otherProps = logData ? { logData } : {};

    return (
      <Touchable
        testID={`listItem_${index}`}
        onPress={this.goToItemPage}
        style={itemStyle}
        {...otherProps}
        {...webProps}
      >
        {this.withWrapper(
          <>
            <S.ImageWrapper
              size={itemSize}
              withBorder={withBorder}
              withBorderRadius={withBorderRadius}
            >
              {this.renderImage(itemSize)}
              {showLovesElement && (
                <LovesElement
                  id={id}
                  layoutName={layoutName}
                  wrapperStyle={styles.loveElement}
                  renderActiveIcon={this.renderActiveLoveIcon}
                  renderInactiveIcon={this.renderInactiveLoveIcon}
                />
              )}
            </S.ImageWrapper>
            {this.renderContent(itemSize)}
          </>
        )}
        <R18HintModal
          isModalOpen={this.state.isModalOpen}
          onPositivePress={this.goToItemPage}
          onNegativePress={() => this.setState({ isModalOpen: false })}
        />
      </Touchable>
    );
  }
}

export default withIntersectionObserver(withDisplayImpression(ListItem));
