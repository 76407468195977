import { getIsTW } from '../../utils/countryInfo';

export const DOWNLOAD_APP_URLS = {
  // 懸浮按鈕_商品頁-> 商品頁
  get ItemPage() {
    if (getIsTW()) {
      return 'https://onelink.to/6nk287';
    }

    return 'https://onelink.to/6nk287';
  },
  // 懸浮按鈕_搜尋結果頁-> 搜尋結果頁
  get SearchResultPage() {
    if (getIsTW()) {
      return 'https://onelink.to/6nk287';
    }

    return 'https://onelink.to/6nk287';
  },
  // 懸浮按鈕_分類頁-> 分類頁
  get ListPage() {
    if (getIsTW()) {
      return 'https://onelink.to/6nk287';
    }

    return 'https://onelink.to/6nk287';
  },
};

export const WHITE_LIST = ['ItemPage', 'SearchResultPage', 'ListPage'];
