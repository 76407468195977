import { Animated, StyleSheet } from 'react-native';
import styled from 'styled-components/native';

import { neutralDark, neutralWhite } from '../../../../constants/color';
import { GET_BANNER_HEIGHT } from '../../../../elements/Banner/styles';

const marginHorizontal = 15;

export const HOME_BANNER_MARGIN = 23;
export const GET_HOME_BANNER_HEIGHT = () =>
  GET_BANNER_HEIGHT() + HOME_BANNER_MARGIN;

export const styles = StyleSheet.create({
  bannerWrapper: {
    minHeight: GET_HOME_BANNER_HEIGHT(),
  },
  bannerContainer: {
    shadowColor: neutralDark,
  },
  banner: {
    borderRadius: 3,
    marginHorizontal: -marginHorizontal,
  },
});

export default {
  // overflow: hidden will cut the shadow
  // separate shadow and banner
  BannerShadow: styled(Animated.View)<{ height: number; width: number }>`
    background-color: ${neutralWhite};
    z-index: 0;
    margin-top: 10px;
    left: ${marginHorizontal}px;
    width: ${({ width }) => width - marginHorizontal * 2}px;
    height: ${({ height }) => height}px;
    position: absolute;
    border-radius: 3px;
    shadow-color: ${neutralDark};
    shadow-offset: 0 3px;
    shadow-radius: 6px;
  `,
  BannerWrapper: styled.View`
    border-radius: 3px;
    margin: 10px ${marginHorizontal}px 13px ${marginHorizontal}px;
    overflow: hidden;
  `,
};
