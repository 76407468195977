/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */

import { createAsyncAction } from 'typesafe-actions';

import { CategoriesPosition } from '../../types/Categories';

export interface CategoriesPayload {
  position: CategoriesPosition;
}

export interface CategoriesSuccessPayload {
  position: CategoriesPosition;
  categories: number[];
}

export interface CategoriesFailPayload {
  position: CategoriesPosition;
  error: Error;
}

export const fetchCategories = createAsyncAction(
  'verybuy/CATEGORIES',
  'verybuy/CATEGORIES_SUCCESS',
  'verybuy/CATEGORIES_FAIL'
)<CategoriesPayload, CategoriesSuccessPayload, CategoriesFailPayload>();
