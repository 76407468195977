import styled from 'styled-components/native';

import { coral, warrior } from '../../../../../../constants/color';
import Touchable from '../../../../../../elements/Touchable';

export const S = {
  AutocompleteTouchable: styled(Touchable)`
    flex-direction: row;
    padding: 12px 0;
    width: 100%;
  `,
  Text: styled.Text<{ highlight: boolean }>`
    color: ${({ highlight }) => (highlight ? coral : warrior)};
    font-size: 15px;
    line-height: 21px;
  `,
  IconSearchContainer: styled.View`
    margin-right: 12px;
  `,
};
