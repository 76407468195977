import { createStandardAction } from 'typesafe-actions';

import { ErrorCode } from '../../constants/VBErrorCode';
import { RoleTypes } from '../../types/Common';

export interface UpdateApiCommonErrorPayload {
  httpStatus: number;
  errorCode: ErrorCode;
  role: RoleTypes;
}

export const updateApiCommonError = createStandardAction(
  'verybuy/UPDATE_API_COMMON_ERROR'
)<UpdateApiCommonErrorPayload>();

export const clearApiCommonError = createStandardAction(
  'verybuy/CLEAR_API_COMMON_ERROR'
)<void>();
