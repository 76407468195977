import { countryCodeHandler } from './countryInfo';

export function pipeValidators(...validators: Function[]) {
  return (data: string) =>
    validators.reduce(
      (error, validator) => error || validator(data),
      undefined
    );
}

export function required(data: string): boolean {
  return data.replace(/(^\s+)|(\s+$)/g, '').length > 0;
}

export function minLength(data: string, limit: number = 10): boolean {
  return data.length >= limit;
}

export function maxLength(data: string, limit: number = 30): boolean {
  return data.length <= limit;
}

// 允許中英數，部分符號如：,.\/()-_
export function normalTypo(data: string): boolean {
  return /^[\w\s()\\/,.\-\u4e00-\u9fa5]+$/.test(data);
}

export function receipt(data: string): boolean {
  return /^\d{8}$/.test(data);
}

export function phone(
  data: string,
  countryCode = countryCodeHandler.get()
): boolean {
  const _countryCode = countryCode.toUpperCase();

  switch (_countryCode) {
    case 'TW':
      return /^0?9(\d{8})$/.test(data);
    case 'HK':
    case 'SG':
      return /^\d{8}$/.test(data);
    case 'MO':
      return /^\d{8,9}$/.test(data);
    default:
      return /^\d{1,20}$/.test(data);
  }
}

export function zipCode(data = '', countryCode = countryCodeHandler.get()) {
  const _countryCode = countryCode.toUpperCase();

  switch (_countryCode) {
    case 'TW':
      return /^[\d]+$/.test(data);
    default:
      return /^.{1,15}$/.test(data);
  }
}

// ref: https://stackoverflow.com/questions/24442564/need-a-regex-for-password-validation-that-allows-all-special-characters
// 小英 -> 小寫英文； 大英 -> 大寫英文； 數 -> 數字； 符 -> 特殊符號
// (((小英大英) | (小英數) | (小英符) | (大英數) | (大英符) | (數符)){1}).{8, 16}
// 符號(無跳脫字元)：¬!"£$%^&*()`{}[]:@~;'#<>?,./\-=_+|
export function password(data = ''): boolean {
  // eslint-disable-next-line
  return /^(((?=(?:[^a-z]*[a-z]){1})(?=(?:[^A-Z]*[A-Z]){1}))|((?=(?:[^a-z]*[a-z]){1})(?=(?:[^\d]*[\d]){1}))|((?=(?:[^a-z]*[a-z]){1})(?=(?:[^¬!"£$%^&*()`{}[\]:@~;'#<>?,./\\\-=_+|]*[¬!"£$%^&*()`{}[\]:@~;'#<>?,./\\\-=_+|]){1}))|((?=(?:[^A-Z]*[A-Z]){1})(?=(?:[^\d]*[\d]){1}))|((?=(?:[^A-Z]*[A-Z]){1})(?=(?:[^¬!"£$%^&*()`{}[\]:@~;'#<>?,./\\\-=_+|]*[¬!"£$%^&*()`{}[\]:@~;'#<>?,./\\\-=_+|]){1}))|((?=(?:[^¬!"£$%^&*()`{}[\]:@~;'#<>?,./\\\-=_+|]*[¬!"£$%^&*()`{}[\]:@~;'#<>?,./\\\-=_+|]){1})(?=(?:[^\d]*[\d]){1}))).{8,16}$/.test(
    data
  );
}

// https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
// https://www.regextester.com/?fam=110026
// 與後端的正則一致，若需調整記得與後端確認
export function email(data = ''): boolean {
  // eslint-disable-next-line
  return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/.test(
    data
  );
}
