import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getItemDetail as getItemDetailAction } from '../actions/getItemDetail';
import { getUserSubmitDetail as getUserSubmitDetailAction } from '../actions/getUserSubmitDetail';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof getItemDetailAction>;
const getItemDetailEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
) => {
  return action$.pipe(
    ofType(getType(getItemDetailAction)),
    map((action: RequestActionTypes) => {
      const { id, success, failure, loadingType } = action.payload;

      return getUserSubmitDetailAction.request({
        id,
        success,
        failure,
        loadingType,
      });
    })
  );
};

export default getItemDetailEpic;
