import { createAsyncAction } from 'typesafe-actions';

import { LinePayPrimeResponse } from '../../types/LinePayPrime';

export interface LinePayPrimePayload {
  failure?: (data: { error: any; transactionID: number }) => void;
  prime: string;
  success?: (data: LinePayPrimeResponse) => void;
  transactionID: number;
  screen: string;
}

export interface LinePayPrimeSuccessPayload {
  data: LinePayPrimeResponse;
  transactionID: number;
}

export interface LinePayPrimeFailPayload {
  error: Error;
  transactionID: number;
}

export const postLinePayPrimeActions = createAsyncAction(
  'verybuy/POST_LINE_PAY_PRIME',
  'verybuy/POST_LINE_PAY_PRIME_SUCCESS',
  'verybuy/POST_LINE_PAY_PRIME_FAIL'
)<LinePayPrimePayload, LinePayPrimeSuccessPayload, LinePayPrimeFailPayload>();
