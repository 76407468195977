import styled from 'styled-components/native';

import { neutralLight, warrior } from '../../../../../constants/color';
import Touchable from '../../../../../elements/Touchable';

export const S = {
  BubbleBtn: styled(Touchable)<{ bubbleColor?: string }>`
    border: solid 1px;
    border-color: ${props => props.bubbleColor || neutralLight};
    border-radius: 17px;
    padding: 7.5px 10.5px 6.5px;
    margin-right: 10px;
    margin-bottom: 10px;
  `,
  BubbleText: styled.Text<{ bubbleColor?: string }>`
    color: ${props => props.bubbleColor || warrior};
    font-size: 15px;
    line-height: 21px;
  `,
};
