/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
import { createAsyncAction } from 'typesafe-actions';

import { DeliveryItem } from '../../types/DeliveryData';

export interface UpdateDeliveryDataPayload {
  data: DeliveryItem;
  success?: Function;
  failure?: Function;
}

export interface UpdateDeliveryDataSuccessPayload {
  data: DeliveryItem;
}

export interface UpdateDeliveryDataFailPayload {
  error: Error;
}

export const updateDeliveryData = createAsyncAction(
  'verybuy/UPDATE_DELIVERY_DATA',
  'verybuy/UPDATE_DELIVERY_DATA_SUCCESS',
  'verybuy/UPDATE_DELIVERY_DATA_FAIL'
)<
  UpdateDeliveryDataPayload,
  UpdateDeliveryDataSuccessPayload,
  UpdateDeliveryDataFailPayload
>();
