import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { MailType, SupportType } from '../../types/Common';
import { Support } from '../../types/MailboxListResponse';
import { convertArrayToObject } from '../../utils/arrayHandler';
import { getMailboxListActions } from '../actions/getMailboxListActions';
import { getSupportDetailActions } from '../actions/getSupportDetailActions';

export type StateDetail = {
  isFetching: boolean;
  itemsIds: string[];
  page: number;
  loadingType: ListLoadingTypes;
  hasMoreItems: boolean;
};

export type State = Record<MailType, StateDetail> & {
  data: Record<string, Support>;
};

export const defaultStateDetail = {
  isFetching: false,
  itemsIds: [],
  page: 1,
  loadingType: 'new',
  hasMoreItems: true,
};

export const defaultState = Object.values(MailType).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: defaultStateDetail,
  }),
  { data: {} } as State
);

type Action = ActionType<
  typeof getMailboxListActions | typeof getSupportDetailActions
>;

const supportTypesSet = new Set(Object.values(SupportType));

function handleMailTypeState(draft: StateDetail, action: Action) {
  switch (action.type) {
    case getType(getMailboxListActions.request): {
      const { loadingType } = action.payload;

      draft.isFetching = true;
      draft.loadingType = loadingType;
      break;
    }
    case getType(getMailboxListActions.success): {
      const { data, page, loadingType } = action.payload;
      const itemsIds = data
        .filter(datum => supportTypesSet.has(datum.type))
        .map(datum => datum.id);
      const newItemsIds =
        loadingType === 'loadMore' ? draft.itemsIds.concat(itemsIds) : itemsIds;

      draft.isFetching = false;
      draft.itemsIds = newItemsIds;
      draft.page = page;
      draft.hasMoreItems = data.length > 0;
      break;
    }
    case getType(getMailboxListActions.failure): {
      draft.isFetching = false;
      break;
    }
    default: {
      break;
    }
  }
}

export default produce((draft: State, action: Action) => {
  switch (action.type) {
    case getType(getMailboxListActions.request):
    case getType(getMailboxListActions.failure): {
      const { mailType } = action.payload;

      handleMailTypeState(draft[mailType], action);
      break;
    }
    case getType(getMailboxListActions.success): {
      const { data, mailType } = action.payload;

      draft.data = convertArrayToObject(data, 'id', draft.data);
      handleMailTypeState(draft[mailType], action);
      break;
    }
    case getType(getSupportDetailActions.success): {
      const { id } = action.payload;

      if (!draft.data[id]) {
        break;
      }

      draft.data[id].unread = false;
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
