import hoistStatics from 'hoist-non-react-statics';
import { Component, FC, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { isWebPlatform } from '../../boot/utils';
import { GetGuestTokenPayload } from '../../redux/actions/getGuestTokenActions';
import { setAxiosDefaultHeader } from '../../redux/api';
import type { MemberData } from '../../redux/reducers/memberReducer';
import { actionsList } from '../../redux/rootAction';
import * as memberSelector from '../../redux/selectors/member';
import { RoleTypes } from '../../types/Common';
import { NavigationParams } from '../../types/NavigationParams';
import { checkBrowserTokenAndRegister } from '../../utils/analytics/iterableFactory/iterableEvents';
import * as navigationService from '../../utils/navigationService';
import routeUtils from '../../utils/routes';
import { setUserContext } from '../../utils/userContextHelpers';

const { getGuestToken, logoutAction, clearData } = actionsList;

const mapStateToProps = () => {
  const selector = createStructuredSelector({
    isLoginLoading: memberSelector.makeIsLoading(),
    isLogin: memberSelector.makeIsLogin(),
    isBlocked: memberSelector.makeMemberInfoByKey('is_blocked'),
    memberInfo: memberSelector.makeMemberInfo(),
    role: memberSelector.makeMemberRole(),
  });

  return state => selector(state);
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchGuestToken: getGuestToken.request,
      logout: logoutAction,
      clearData,
    },
    dispatch
  );
};

export interface AuthProps {
  fetchGuestTokenIfNeeded: (args: GetGuestTokenPayload) => void;
  isLogin: boolean;
  isLoginLoading: boolean;
  isBlocked: boolean;
  login: () => void;
  loginedAndReplaceWith: (
    isLogin: boolean,
    backToPageKey?: string,
    params?: {},
    key?: string
  ) => void;
  logout: () => void;
  memberInfo: MemberData;
  role: RoleTypes;
}

interface Props {
  isLoginLoading: boolean;
  isLogin: boolean;
  isBlocked: boolean;
  memberInfo: MemberData;
  role: RoleTypes;
  logout: Function;
  fetchGuestToken: Function;
  clearData: Function;
}

const decorate = (WrappedComponent: typeof Component | FC<any>) => {
  class Auth extends PureComponent<Props> {
    static defaultProps: Props = {
      isLoginLoading: false,
      isLogin: false,
      isBlocked: false,
      memberInfo: { notifications: 0 },
      role: 'ghost',
      logout: () => null,
      fetchGuestToken: () => null,
      clearData: () => null,
    };

    // 用途：某些頁面在登入的狀況下，不需呈現該頁面
    // * 可參考 WelcomeLoginPage, LoginForm
    static loginedAndReplaceWith = (
      isLogin,
      backToPageKey = 'Home',
      params = {},
      key = ''
    ) => {
      if (isLogin) {
        if (isWebPlatform) {
          const redirectUrl = routeUtils.getPathAndParamsFromData(
            backToPageKey,
            params
          ).fullPath;

          window.location.replace(redirectUrl);
        } else {
          // 因為可能會橫跨 tab 、故無法使用 replace
          navigationService.goBack(backToPageKey, params, key);
        }
      }
    };

    state = {
      showDialog: false,
    };

    constructor(props) {
      super(props);
      if (!__DEV__) {
        setUserContext({
          ...props.memberInfo,
          role: props.role,
        });
      }

      const token = props?.memberInfo?.token;

      if (token) {
        setAxiosDefaultHeader('api-key', token);
      }
    }

    componentDidUpdate(prevProps, _prevState) {
      const nextProps = this.props;
      const { memberInfo, role } = nextProps;

      // set userInfo context
      if (
        !__DEV__ &&
        (prevProps.memberInfo.token !== memberInfo.token ||
          prevProps.role !== role)
      ) {
        setUserContext({
          ...memberInfo,
          role,
        });
      }

      // trigger if user login
      if (!prevProps.isLogin && this.props.isLogin) {
        if (isWebPlatform) {
          checkBrowserTokenAndRegister({
            email: memberInfo.email,
            userId: memberInfo.MID,
          });
        }
      }
    }

    private clearData = () => {
      this.props.clearData();
    };

    public login = () => {
      const activeRoute = navigationService.getActiveRoute() || {};
      const { routeName, params, key: backWithKey } = activeRoute;

      navigationService.navigate('WelcomeLoginPage', {
        [NavigationParams.BackToPageKey]: routeName,
        [NavigationParams.BackWithKey]: backWithKey,
        [NavigationParams.EncodedPageParams]:
          routeUtils.encodePageParams(params),
      });
    };

    public logout = () => {
      this.props.logout();
      this.clearData();
    };

    public fetchGuestTokenIfNeeded = (...args) => {
      if (this.props.role === 'ghost') {
        this.props.fetchGuestToken(...args);
      }
    };

    render() {
      // filter props here
      const {
        isLoginLoading,
        isLogin,
        isBlocked,
        memberInfo,
        role,
        logout,
        fetchGuestToken,
        ...parentsProps
      } = this.props;

      const authProps = {
        fetchGuestTokenIfNeeded: this.fetchGuestTokenIfNeeded,
        isLogin,
        isLoginLoading,
        isBlocked,
        login: this.login,
        loginedAndReplaceWith: Auth.loginedAndReplaceWith,
        logout: this.logout,
        memberInfo,
        role,
      };

      return (
        <WrappedComponent testID="withAuth" {...parentsProps} {...authProps} />
      );
    }
  }

  // 因為 decorator 不會知道 WrappedComponent 的 static，在此造成一些問題
  // 因此透過 hoist-non-react-statics 將 static 由 child 傳給 parent
  // 當然、也可以自己手動處理
  return hoistStatics(Auth, WrappedComponent);
};

const enhance = (WrappedComponent: typeof Component | FC<any>) => {
  const Auth = decorate(WrappedComponent);

  return connect(mapStateToProps, mapDispatchToProps)(Auth);
};

export default (WrappedComponent: typeof Component | FC<any>) =>
  enhance(WrappedComponent);
