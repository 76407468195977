import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { getPlatformCode } from '../../redux/api/utils';
import Config from '../configs';
import sendGtagEvent from '../sendGtagEvent';
import getLogPayload from './firebase/getLogPayloadForFirebase';

export function googleAnalyticsEvents({ logEvent, logData, currentStore }) {
  const logPayloadForGA = getLogPayload(logEvent, logData, currentStore);

  // filter empty view screenView event
  if (logEvent === AnalyticsEvent.ScreenView) return;

  // event blacklist
  if (logEvent === AnalyticsEvent.UpdateCart) return;

  // 動態更換事件名稱
  if (logEvent === AnalyticsEvent.GeneralEvent) {
    if (!logData?.data?.action_name) return;

    logEvent = logData?.data?.action_name;
  }

  sendGtagEvent('set', 'user_properties', {
    platform_type: getPlatformCode().toString(),
  });

  if (logEvent === AnalyticsEvent.ECommercePurchase) {
    sendGtagEvent('event', 'purchase', {
      ...logPayloadForGA,
      send_to: Config.REACT_APP_GA_ID,
    });

    sendGtagEvent('event', 'purchase', {
      ...logPayloadForGA,
      send_to: Config.REACT_APP_GA_PLUS_APP_ID,
    });
  } else {
    sendGtagEvent('event', logEvent, {
      ...logPayloadForGA,
      send_to: Config.REACT_APP_GA_ID,
    });

    sendGtagEvent('event', logEvent, {
      ...logPayloadForGA,
      send_to: Config.REACT_APP_GA_PLUS_APP_ID,
    });
  }
}
