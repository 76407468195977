import { Animated } from 'react-native';
import styled, { css } from 'styled-components/native';

const SIZE = 32;
const MARGIN = 4;

export const TOTAL_WIDTH = SIZE + MARGIN * 2;

const iconSize = css`
  width: ${SIZE}px;
  height: ${SIZE}px;
`;

export const S = {
  IconWrapper: styled(Animated.View)`
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 16px;
    margin: 0px ${MARGIN}px;
    ${iconSize};
  `,
};
