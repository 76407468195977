import { createSelector } from 'reselect';

import { DeliveryMode } from '../../types/Common';
import { PaymentData } from '../../types/Payment';
import isExists from '../../utils/isExists';
import { RootState } from '../rootReducer';
import { getCheckedItems } from './cartContentSelector';
import { makeReceiptFormData } from './receiptFormSelector';
import { makeCVSListByIndex } from './receivingShopDetailSelector';
import { makeDeliveryDataByIndex } from './recipientDetail';

export const submitBillReducerRaw = (state: RootState) =>
  state?.submitBillReducer || {};

const checkoutReducerRow = (state: RootState) => state?.checkoutReducer || {};

export const makeIsTransactionExistByName = name => {
  return createSelector(submitBillReducerRaw, data => isExists(data?.[name]));
};

export const getCurrentPayBy = () => {
  return createSelector(
    [submitBillReducerRaw, checkoutReducerRow],
    (submitBillData, checkoutData) => {
      const payBy = submitBillData?.pay_by;
      const paymentMethods: PaymentData = checkoutData?.paymentMethods || {};
      const currentPaymentMethod =
        typeof payBy !== 'undefined' && paymentMethods[payBy];

      if (!currentPaymentMethod) {
        return null;
      }

      return payBy;
    }
  );
};

export const getCurrentSubmitBillDataByName = name => {
  return createSelector(submitBillReducerRaw, submitBillData => {
    return submitBillData?.[name];
  });
};

export const makeTransactionDetail = () => {
  return createSelector(
    state => state,
    submitBillReducerRaw,
    (rawData, data) => {
      let dataByDeliveryMode;
      const invoiceType = data?.receiptType;
      const receiptFormData = makeReceiptFormData()(rawData);

      switch (data.delivery_mode) {
        case DeliveryMode.CONVENIENCE_STORE_PICKUP:
          dataByDeliveryMode = makeCVSListByIndex(0)(rawData);
          break;
        case DeliveryMode.HOME_DELIVERY:
        default:
          dataByDeliveryMode = makeDeliveryDataByIndex(data.deliveryData)(
            rawData
          );
      }

      return {
        ...dataByDeliveryMode,
        pay_by: data.pay_by,
        selected_delivery_mode: data.delivery_mode,
        coupon_code: data?.couponData?.code,
        virtual_products: getCheckedItems()(rawData),
        invoice_type: invoiceType,
        ...receiptFormData,
      };
    }
  );
};

export const makeIsTransactionResultLoading = () => {
  return createSelector(submitBillReducerRaw, data => data.isLoading || false);
};
