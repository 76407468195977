import { createSelector } from 'reselect';

import {
  BasePropsItem,
  MetaBaseContent,
  SkuMeta,
  SkuRaw,
} from '../../types/ItemContent';
import isExists from '../../utils/isExists';
import { RootState } from '../rootReducer';

const itemContent = (state: RootState) => state?.itemContentReducer?.SKU || {};

export const itemContentSelector = (id: string) =>
  createSelector(itemContent, (data): SkuRaw => {
    return data?.[`${id}`] || {};
  });

export const itemContentMetaSelector = (id: string) =>
  createSelector(itemContent, (data): SkuMeta => {
    const dataById = data[`${id}`];

    return dataById?.meta || {};
  });

export const itemContentLoadingSelector = (id: string) =>
  createSelector(itemContentSelector(id), data => {
    const isLoading = data?.isLoading;

    // to avoid init state missing isLoading trigger rerender twice ( undefined => true => false)
    return isExists(isLoading) ? isLoading : true;
  });

export const itemContentAttrSelector = (id: string, attr: string) =>
  createSelector(itemContentMetaSelector(id), data => {
    return data?.[attr];
  });

export const makeContentOptions = id => {
  return createSelector(
    itemContentAttrSelector(id, 'color'),
    itemContentAttrSelector(id, 'size'),
    (colorList = {} as MetaBaseContent, sizeList = {} as MetaBaseContent) => {
      const list = [...(colorList?.props || []), ...(sizeList?.props || [])];

      return list;
    }
  );
};

export const makeAllSizeColorImageAndQuantityForSku = id => {
  return createSelector(makeContentOptions(id), (list: BasePropsItem[]) => {
    return list.map(
      ({
        image_url,
        total_quantity,
      }: BasePropsItem): Pick<
        BasePropsItem,
        'image_url' | 'total_quantity'
      > => {
        return {
          image_url,
          total_quantity,
        };
      }
    );
  });
};

export const makeAllImagesForSku = id => {
  return createSelector(makeContentOptions(id), (list: BasePropsItem[]) => {
    const imageUrls = list.reduce(
      (result: string[], { image_url: imageUrl }) => {
        if (imageUrl && imageUrl.length) {
          result.push(imageUrl);
        }

        return result;
      },
      []
    );

    return imageUrls;
  });
};
