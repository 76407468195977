import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { LoveListData } from '../../types/LoveList';
import { getLoveListActions } from '../actions/getLoveListActions';
import { getLoveList } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof getLoveListActions.request>;
const outputActions = {
  success: getLoveListActions.success,
  failure: getLoveListActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getLoveListEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getLoveListActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { loadingType, page, success } = action.payload;

      return getLoveList(page)
        .then((response: AxiosResponse<LoveListData>) => {
          const { items } = response.data;

          success(items);

          return getLoveListActions.success({
            items,
            loadingType,
            page,
          });
        })
        .catch((error: AxiosError) =>
          getLoveListActions.failure({ error, requestAction: action })
        );
    })
  );
};

export default getLoveListEpic;
