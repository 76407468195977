import { createAsyncAction } from 'typesafe-actions';

import { GuestToken } from '../../types/GuestToken';

export interface GetGuestTokenPayload {
  success?: Function;
  failure?: Function;
}

export interface GetGuestTokenSuccessPayload {
  data: GuestToken;
}

export interface GetGuestTokenFailPayload {
  error: Error;
}

export const getGuestToken = createAsyncAction(
  'verybuy/GET_GUEST_TOKEN',
  'verybuy/GET_GUEST_TOKEN_SUCCESS',
  'verybuy/GET_GUEST_TOKEN_FAIL'
)<
  GetGuestTokenPayload,
  GetGuestTokenSuccessPayload,
  GetGuestTokenFailPayload
>();
