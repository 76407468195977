import { useNavigationState } from 'react-navigation-hooks';

export const useRoute = () => {
  const { routeName, ...rest } = useNavigationState();

  return {
    ...rest,
    name: routeName,
  };
};
