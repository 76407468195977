import safeAwait from 'safe-await';

import { isServer, isWebPlatform } from '../boot/utils';
import { getRemoteValue } from '../utils/packages/firebase';
import justDetectAdblock from './packages/justDetectAdblock';
import sendGtagEvent from './sendGtagEvent';
import { registerSuperProperties } from './sensorsHelpers';

type ResolveFunc = (value: Experiment | PromiseLike<Experiment>) => void;
interface ExperimentListItem {
  name: ExperimentName;
  developID: string;
  productionID: string;
  featureFlag?: boolean;
}

export enum Experiment {
  CONTROL_GROUP,
  VARIANT,
  VARIANT2,
  VARIANT3,
}

/**
 * ```ts
 * export enum ExperimentName {
 *   ItemPageBeltStyle = 'item_page_belt_style',
 * }
 * ```
 */
export enum ExperimentName {}

/**
 * ```ts
 * export const ActivationEvents = {
 *   [ExperimentName.ItemPageBeltStyle]: 'optimize.activate.item_page_belt_style',
 * };
 * ```
 */

export const ActivationEvents = {};

/**
 * ```ts
 * export let experimentList: ExperimentListItem[] = [
 *   {
 *     name: ExperimentName.ItemPageBottomCount,
 *     developID: 'xxxxxxx',
 *     productionID: 'xxxxxxx',
 *     featureFlag: FeatureFlags.CX_XXXX_FEATURE,
 *   },
 * ];
 * ```
 */
export let experimentList: ExperimentListItem[] = [];

export const defaultGroup = Experiment.CONTROL_GROUP;

const _abTestingResolve = {} as Record<ExperimentName, ResolveFunc>;
const _abTestingPromise = {} as Record<ExperimentName, Promise<Experiment>>;
const _abTestingNameSet = new Set();

function preparePromise() {
  experimentList.forEach(({ name }) => {
    _abTestingPromise[name] = new Promise<Experiment>(resolve => {
      _abTestingResolve[name] = resolve;
    });
  });
}

/**
 * IMPORTANT: This function can only be used in unit tests
 */
export function _DANGER_resetForTest() {
  preparePromise();
  _registerSensorSuperProperties = registerSensorSuperPropertiesClosure();
  _abTestingNameSet.clear();
}
export function _DANGER_setExperimentList(list: typeof experimentList) {
  experimentList = list;
}

export function getABTestingGroup(name: ExperimentName) {
  return Promise.race([_abTestingPromise[name], handleException()]);
}

export function handleException() {
  return new Promise<Experiment>(async resolve => {
    const [, isBlocked] = await safeAwait(
      justDetectAdblock.detectAnyAdblocker()
    );

    if (isBlocked) {
      resolve(defaultGroup);
    }

    // PM: avoid exception, force to the control group after few seconds
    setTimeout(() => {
      resolve(defaultGroup);
    }, 5000);
  });
}

function registerSensorSuperPropertiesClosure() {
  const expGroups: string[] = [];
  const expName: ExperimentName[] = [];

  return (name: ExperimentName, group: Experiment) => {
    expGroups.push(`G${group}_${name}`);
    expName.push(name);

    registerSuperProperties({
      // 因神策會改變源資料，故需 copy
      exp_groups: expGroups.slice(),
      exp_name: expName.slice(),
    });
  };
}

let _registerSensorSuperProperties = registerSensorSuperPropertiesClosure();

const handleSetExperimentRole =
  (name: ExperimentName, featureFlag: boolean = true) =>
  (value: string) => {
    const hasBeenExecuted = _abTestingNameSet.has(name);

    if (
      !featureFlag ||
      typeof _abTestingResolve[name] !== 'function' ||
      hasBeenExecuted
    ) {
      return;
    }

    const group: Experiment = Experiment[value] ? +value : defaultGroup;

    _abTestingNameSet.add(name);
    _abTestingResolve[name](group);
    _registerSensorSuperProperties(name, group);
  };

function setAppABTestingGroup() {
  const handleExperiment = async (args: ExperimentListItem) => {
    const { name, featureFlag } = args;
    const config = await getRemoteValue(name as unknown as string);
    const group = config.asString();

    if (group) {
      handleSetExperimentRole(name, featureFlag)(group);
    }
  };

  experimentList.forEach(handleExperiment);
}

export function abTestingSetup() {
  if (isServer) {
    return;
  }

  preparePromise();

  if (isWebPlatform) {
    // @ts-expect-error: 'handleSetExperimentRole' does not exist on window
    // 釋出 handleSetExperimentRole 給 optimize 調用，透過告知分組資訊。
    window.handleSetExperimentRole = handleSetExperimentRole;

    const isProd = window.location.host === 'm.verybuy.cc';

    experimentList.forEach(({ productionID, developID, name, featureFlag }) => {
      // ref: https://support.google.com/optimize/answer/9059383?hl=en#zippy=%2Cin-this-article
      sendGtagEvent('event', 'optimize.callback', {
        name: isProd ? productionID : developID,
        callback: handleSetExperimentRole(name, featureFlag),
      });
    });
  } else {
    setAppABTestingGroup();
  }
}
