import { Platform, StyleSheet } from 'react-native';

import { white } from '../../constants/color';
import statusBarHeight from '../../constants/statusBarHeight';

const styles: any = StyleSheet.create({
  close: {
    position: 'absolute',
    left: 0,
    top: 0,
    paddingTop: 50,
    paddingBottom: 10,
    paddingLeft: 17,
    paddingRight: 17,
    zIndex: 200,
  },
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  background: {
    width: '100%',
    height: '100%',
  },
  bgImageWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bgImage: {
    maxWidth: 800,
    width: '100%',
    height: '100%',
  },
  flex: {
    flex: 1,
  },
  logoWrapper: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 293,
    height: 72,
  },
  board: {
    width: '100%',
    alignItems: 'center',
    maxWidth: 800,
    paddingRight: 30,
    paddingLeft: 30,
    marginBottom: Platform.select({
      android: statusBarHeight + 50,
      default: 50,
    }),
  },
  introText: {
    color: white,
    fontSize: 15,
    lineHeight: 21,
    paddingLeft: 8,
    paddingRight: 8,
    marginTop: 10,
  },
  shadowText: {
    color: white,
    fontSize: 15,
    lineHeight: 21,
    paddingLeft: 8,
    paddingRight: 8,
    marginTop: 2,
  },
  facebookLogin: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 10,
  },
  btnGroup: {
    flex: 1,
    width: '100%',
    minHeight: 45,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 19.5,
    alignItems: 'stretch',
  },
  actionBtn: {
    minHeight: 45,
    flex: 2,
    borderWidth: 1,
    borderColor: white,
    borderRadius: 40,
    textAlign: 'center',
    justifyContent: 'center',
  },
  actionBtnLeft: {
    marginRight: 5,
  },
  actionBtnRight: {
    marginLeft: 5,
  },
  btnText: {
    color: white,
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: 15,
    lineHeight: 21,
  },
  lastLoginMethod: {
    color: white,
    marginTop: 30,
    fontSize: 15,
    lineHeight: 21,
  },
  divider: {
    width: '100%',
    height: 1,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: white,
  },
});

export default styles;
