import { getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import * as arrayHandler from '../../utils/arrayHandler';
import { getUserSubmitList } from '../actions/getUserSubmitList';

export default function (
  state: {
    data: Array<number | string>;
    isFetching: boolean;
    loadingType: ListLoadingTypes;
    queryText: string;
    scroll_id?: number;
    hasMoreItems: boolean;
  } = {
    data: [],
    isFetching: false,
    loadingType: 'new',
    hasMoreItems: true,
    queryText: '',
  },
  action
) {
  switch (action.type) {
    case getType(getUserSubmitList.request): {
      const { queryText, page, loadingType } = action.payload;
      const newItems = loadingType === 'loadMore' ? state.data : [];
      const hasMoreItems =
        loadingType === 'loadMore' ? state.hasMoreItems : true;

      return {
        ...state,
        data: newItems,
        isFetching: true,
        queryText,
        currentPage: page,
        loadingType,
        hasMoreItems,
      };
    }

    case getType(getUserSubmitList.success): {
      const { loadingType, data } = action.payload;
      const defaultSearchUserSubmit = {
        products: [],
        is_fuzzy: false,
        search_term: [],
      };
      const { products: items, ...otherData } = data || defaultSearchUserSubmit;

      const itemsIds = items?.map(d => `${d.id}`) || [];
      const newItems =
        loadingType === 'loadMore' ? [...state.data, ...itemsIds] : itemsIds;

      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: arrayHandler.uniqueArray(newItems),
        hasMoreItems: items.length > 0,
        loadingType,
        ...otherData,
      };
    }

    case getType(getUserSubmitList.failure): {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
