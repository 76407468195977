import { Subject } from 'rxjs';

export type KeyAction =
  | 'hamburgerMenuOpen'
  | 'hamburgerMenuClose'
  | 'filterBackdropOpen'
  | 'filterBackdropClose'
  | 'privateRouteOpen';

export default new Subject<KeyAction>();
