import 'fast-text-encoding';

import {
  FirehoseClient,
  PutRecordBatchCommand,
} from '@aws-sdk/client-firehose';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

import {
  CIPHER_TEXT,
  REGION,
  SECRET_KEY,
} from '../../../constants/awsFirehose';
import Config from '../../../utils/configs';

const bytes = AES.decrypt(CIPHER_TEXT, SECRET_KEY);
const decryptedData = JSON.parse(bytes.toString(Utf8));

const firehose = new FirehoseClient({
  region: REGION,
  credentials: {
    accessKeyId: decryptedData.promote_id,
    secretAccessKey: decryptedData.promote_key,
  },
});

const sendAWSFirehose = (recordsData: { Data: string }[]): void => {
  if (recordsData.length <= 0) return;

  // encode to records data
  const recordsList: { Data: Uint8Array }[] = recordsData.map(({ Data }) => {
    const textEncoder = new TextEncoder();

    return {
      Data: textEncoder.encode(Data),
    };
  });

  const { FIREHOSE_DELIVERY_STREAM_NAME } = Config;
  const params = new PutRecordBatchCommand({
    Records: recordsList,
    DeliveryStreamName: FIREHOSE_DELIVERY_STREAM_NAME,
  });

  firehose.send(params).catch(error => {
    console.log(error, error.stack);
  });
};

export default sendAWSFirehose;
