import styled from 'styled-components/native';

export const S = {
  ViewedWrapper: styled.View`
    width: 100%;
    opacity: 0.6;
    background-color: yellow;
    border-width: 1px;
    position: absolute;
    z-index: 2;
  `,
  ViewedText: styled.Text`
    width: 100%;
    text-align: center;
    font-size: 20px;
    padding: 4px;
    z-index: 2;
  `,
};
