import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { FormatedQueriesParams } from '../../utils/routes/qsForComplexQueries';
import { getQueryItemsActions } from '../actions/getQueryItemsActions';
import { getQueryItems } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getQueryItemsActions.success,
  failure: getQueryItemsActions.failure,
};

type RequestActionType = ActionType<typeof getQueryItemsActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getQueryItemsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getQueryItemsActions.request)),
    switchMap((action: RequestActionType) => {
      const { loadingType, success, dispatch, ...restParams } = action.payload;

      if (dispatch) {
        dispatch(action);
      }

      // 避免送出不預期 type 的參數(主要排除 undefined 和 function)
      const queries = Object.entries(restParams).reduce((result, [k, v]) => {
        const theValueType = typeof v;

        if (theValueType !== 'undefined' && theValueType !== 'function') {
          return {
            ...result,
            [k]: v,
          };
        }

        return result;
      }, {} as FormatedQueriesParams);

      return getQueryItems(queries)
        .then(response => {
          const data = response.data;

          success(data.products);

          const successAction = getQueryItemsActions.success({
            loadingType,
            data,
          });

          if (dispatch) {
            dispatch(successAction);
          }

          return successAction;
        })
        .catch((error: AxiosError) => {
          const failureAction = getQueryItemsActions.failure({
            error,
            requestAction: action,
          });

          if (dispatch) {
            dispatch(failureAction);
          }

          return failureAction;
        });
    })
  );
};

export default getQueryItemsEpic;
