import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getSearchWithTokenActions } from '../actions/getSearchWithTokenActions';
import { getSearchUserSubmitWithToken } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getSearchWithTokenActions.success,
  failure: getSearchWithTokenActions.failure,
};

type RequestActionType = ActionType<typeof getSearchWithTokenActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getSearchWithTokenEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getSearchWithTokenActions.request)),
    switchMap((action: RequestActionType) => {
      const { loadingType, success, dispatch, ...restParams } = action.payload;

      dispatch(action);

      return getSearchUserSubmitWithToken(restParams)
        .then(response => {
          const data = response.data;

          success(data.products);

          const successAction = getSearchWithTokenActions.success({
            loadingType,
            data,
          });

          if (dispatch) {
            dispatch(successAction);
          }

          return successAction;
        })
        .catch((error: AxiosError) => {
          const responseAction = getSearchWithTokenActions.failure({
            error,
            requestAction: action,
          });

          dispatch(responseAction);

          return responseAction;
        });
    })
  );
};

export default getSearchWithTokenEpic;
