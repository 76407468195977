import { useEffect } from 'react';
import { AppState } from 'react-native';

import { isIOS } from '../../boot/utils';
import firebase from '../../utils/packages/firebase';
import handlePushNotification from '../../utils/pushNotification/handlePushNotification';
import showNotification from '../../utils/pushNotification/showNotification';

const handleOnMessage = () => {
  let removeOnMessage: (() => void) | null = null;
  let workaroundTimeout: NodeJS.Timer | null = null;

  const clearListeners = () => {
    if (removeOnMessage) {
      removeOnMessage();
      removeOnMessage = null;
    }

    if (workaroundTimeout) {
      clearTimeout(workaroundTimeout);
      workaroundTimeout = null;
    }
  };

  /**
   * (1) iOS 有重複顯示推播的問題，原因是系統會在 app 開啟時在 native 額外觸發 didReceiveRemoteNotification，
   *     加上延遲以避免上述問題
   *
   *     ref: https://verybuycc.atlassian.net/browse/FT-91?focusedCommentId=19226
   *
   * (2) 當 app 在背景時也會觸發 iOS 重複推播的 bug，需要用 app state change 控制 onMessage handler
   *
   *     ref: https://verybuycc.atlassian.net/browse/FT-95
   *
   * NOTE: Android 不會遇到這個問題，但為了好維護程式碼還是寫一致，
   *       下方 (3) 以平台設定 duration 來選擇是否啟用 workaround，AppState 監聽實作在 Android 不影響功能
   */
  const appStateListener = AppState.addEventListener('change', nextState => {
    // 當 AppState 有變動時，移除 onMessage 監聽與 timeout
    clearListeners();

    // 判斷 nextState 選擇是否監聽 onMessage (2)
    if (nextState === 'active') {
      // 用 setTimeout 避免 iOS 重複顯示推播問題 (1)
      workaroundTimeout = setTimeout(
        () => {
          removeOnMessage = firebase
            .messaging()
            .onMessage(message => showNotification(message));
        },
        isIOS ? 2000 : 0 // 以平台設定 duration 來選擇是否啟用 workaround (3)
      );
    }
  });

  return () => {
    clearListeners();
    appStateListener.remove();
  };
};

export default ({}) => {
  // 因 onMessage 有額外的事項要處理(如上所述)，在這裡與其他推播相關事項拆開
  useEffect(handleOnMessage, []);

  // 其他推播相關事項
  useEffect(handlePushNotification, []);

  return null;
};
