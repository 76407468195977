import { Platform } from 'react-native';

import { isWebPlatform } from '../boot/utils';
import deviceInfo from './deviceInfo';
import * as navigationService from './navigationService';
import routeUtils from './routes';

export async function getUserInfo() {
  const { params, routeName } = navigationService.getActiveRoute() || {};
  const version = await deviceInfo.getVersionWithCodePush();
  const path = isWebPlatform
    ? window.location.href
    : routeUtils.getPathAndParamsFromData(routeName, params).fullPath;
  const userAgent = isWebPlatform ? window.navigator.userAgent : undefined;

  return {
    platform: Platform.OS,
    version,
    path,
    userAgent,
  };
}
