import styled, { css } from 'styled-components/native';

import { warrior, white } from '../../constants/color';
import { dialogWidth } from '../../elements/Dialog/styles';
import { PureImage } from '../../elements/Image';

const textColor = css`
  color: ${warrior};
`;

export const S = {
  Wrapper: styled.View`
    width: 100%;
  `,
  GuideImage: styled(PureImage)`
    width: 100%;
    height: ${(dialogWidth * 220) / 316}px;
    margin: 20px 0;
  `,
  InfoWrapper: styled.View`
    justify-content: center;
    padding: 0 25px 20px 25px;
  `,
  TitleText: styled.Text`
    font-size: 18px;
    line-height: 24px;
    ${textColor};
    font-weight: 700;
    padding-bottom: 10px;
  `,
  ContentText: styled.Text`
    font-size: 15px;
    line-height: 18px;
    ${textColor};
    margin-bottom: 10px;
  `,
  LovesOverLimitWrapper: styled.View`
    justify-content: center;
    align-items: center;
    margin: 10px;
  `,
  LovesOverLimitText: styled.Text`
    color: ${white};
    font-size: 13px;
    line-height: 16px;
    margin-top: 5px;
  `,
};
