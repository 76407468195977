import { StyleSheet } from 'react-native';

import { coral } from '../../constants/color';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  postfixStyle: {
    marginLeft: 1,
    fontSize: 13,
    lineHeight: 15,
    color: coral,
  },
});

export default styles;
