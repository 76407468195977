import { AppRegistry } from 'react-native';

import App from './boot/setup';
import browserHistoryPatch from './utils/browserHistoryPatch';
import deviceInfo, { inapp } from './utils/deviceInfo';

const detectRouteInfo = browserHistoryPatch.detectRouteNotFound();

const { invalid: isRouteNotFound, path } = detectRouteInfo;

/* WARNING: WORKAROUND Start */
if (inapp) {
  // @ts-ignore
  // Warning: Android Facebook inApp will cut bottom
  // This is a workaround for fix it
  // android will be cut bottom 48px in FB
  // @ts-ignore
  if (inapp.isInApp && inapp.browser === 'facebook') {
    if (deviceInfo.getSystem && deviceInfo.getSystem() === 'Android') {
      const height = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );

      // @ts-ignore
      window.document.getElementById('root').style.height = height + 1 + 'px';

      // force to hide facebook "Send to Messenger Section"
      setTimeout(() => {
        window.scrollTo(0, height + 1);
      }, 500);
    }
  }
}
/* WARNING: WORKAROUND End */

if (isRouteNotFound) {
  const shouldReplace = true;

  browserHistoryPatch.redirectWithScene(
    'NotFoundPage',
    {
      from: window.encodeURIComponent(window.location.pathname),
    },
    shouldReplace
  );
  throw new Error('404 - not found');
}

if (!isRouteNotFound && path) {
  window.location.replace(path);

  throw new Error('301 - moved permanently');
}

AppRegistry.registerComponent('verybuy', () => App);

const rootTag = document.getElementById('root');

if (rootTag) {
  rootTag.innerText = '';
  AppRegistry.runApplication('verybuy', {
    rootTag,
  });
}

// temperary disable PWA
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const serviceWorker = require('./serviceWorker');
// serviceWorker.register();
