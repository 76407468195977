import { createAction, createAsyncAction } from 'typesafe-actions';

import { SortBy } from '../../constants/sortData';
import { ListLoadingTypes } from '../../types/Common';
import {
  MarketingLandingPage,
  MarketingLandingPageItem,
} from '../../types/MarketingLandingPageResponse';
import { PayloadWithFilter } from '../../types/PayloadWithFIlter';

export interface MarketingLandingPagePayload
  extends Pick<PayloadWithFilter, 'category' | 'minPrice' | 'maxPrice'> {
  failure?: Function;
  id: string;
  loadingType: ListLoadingTypes;
  page: number;
  sortBy: SortBy;
  success: (products: MarketingLandingPageItem[]) => void;
  perpage?: number;
}

export interface MarketingLandingPageSuccessPayload {
  data: MarketingLandingPage;
  id: string;
  loadingType: ListLoadingTypes;
  page: number;
  logEvent?: string;
}

export interface MarketingLandingPageFailPayload {
  customErrorHandling?: boolean;
  error: Error;
  id: string;
  requestAction?: ReturnType<typeof getMarketingLandingPageActions.request>;
}

export const getMarketingLandingPageActions = createAsyncAction(
  'verybuy/GET_MARKETING_LANDING_PAGE',
  'verybuy/GET_MARKETING_LANDING_PAGE_SUCCESS',
  'verybuy/GET_MARKETING_LANDING_PAGE_FAIL'
)<
  MarketingLandingPagePayload,
  MarketingLandingPageSuccessPayload,
  MarketingLandingPageFailPayload
>();

export const leaveMarketingLandingPageAction = createAction(
  'verybuy/LEAVE_MARKETING_LANDING_PAGE'
);
