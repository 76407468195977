import { isServer } from '../../boot/utils';
import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { isCypress } from '../../constants/Cypress';
import { isBot } from '../../constants/misc';
import { RootState } from '../../redux/rootReducer';
import Config from '../configs';
import sendAWSFirehose from './awsFirehose';
import { sendPixelEvents } from './FacebookPixel/sendPixelEvents';
import { googleAnalyticsEvents } from './googleAnalytics';
import { sendIterableEvents } from './iterableFactory/iterableEvents';
import { sendLineTagEvent } from './lineTag/sendLineTagEvent';
import { sendRTBHouseEventFactory } from './RTBHouse/sendRTBHouseEventFactory';
import sensorsLogEvents from './sendSensorsEvents';

// Firebase log event ref: https://support.google.com/firebase/answer/6317485?hl=zh-Hant

// Mapping data to firebase payload
export default function processLogEvents({
  logEvent,
  logData,
  currentStore,
}: {
  logEvent: AnalyticsEvent;
  logData: any;
  currentStore: RootState;
}) {
  // Don't send event if testing
  if (Config.REACT_APP_ENV_TYPE === 'TESTING') {
    return false;
  }
  // ignore bot request
  if (isBot || isServer) {
    return;
  }
  try {
    // FIXME: it is removed after mock the requests in cypress
    if (!isCypress) {
      googleAnalyticsEvents({ logEvent, logData, currentStore });
      sendRTBHouseEventFactory(logEvent, logData, currentStore);
      sendPixelEvents({ logEvent, logData, currentStore });
      sendIterableEvents({ logEvent, logData, currentStore });
      sendLineTagEvent(logEvent, logData);
    }
    sensorsLogEvents(logEvent, logData, currentStore);
    sendAWSFirehose(logEvent, logData, currentStore);
  } catch (err) {
    console.error(err);
  }

  return true;
}
