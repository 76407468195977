import styled from 'styled-components/native';

export const LIST_CONTAINER_PADDING = 10;

export const S = {
  Container: styled.View`
    background-color: ${({ theme }) => theme.palette.white};
    flex: 1 auto;
    padding: 0 10px;
  `,
};
