import { createAsyncAction } from 'typesafe-actions';

export interface NotificationReadPayload {
  id: string;
}

export interface NotificationReadSuccessPayload {
  id: string;
}

export interface NotificationReadFailPayload {
  id: string;
  error: Error;
}

export const patchNotificationReadActions = createAsyncAction(
  'verybuy/PATCH_NOTIFICATION_READ',
  'verybuy/PATCH_NOTIFICATION_READ_SUCCESS',
  'verybuy/PATCH_NOTIFICATION_READ_FAIL'
)<
  NotificationReadPayload,
  NotificationReadSuccessPayload,
  NotificationReadFailPayload
>();
