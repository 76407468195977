import produce from 'immer';
import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';

import { getZipCode } from '../actions/getZipCodeActions';

export interface State {
  isLoading: boolean;
  data: object;
  hasError: boolean;
}

export const defaultState = {
  isLoading: false,
  data: {},
  hasError: false,
};

export default produce((draft: State, action: AnyAction) => {
  const { countryCode, data } = action.payload;

  switch (action.type) {
    case getType(getZipCode.request): {
      draft.isLoading = true;
      break;
    }
    case getType(getZipCode.success): {
      draft.hasError = false;
      draft.isLoading = false;
      draft[countryCode] = {
        data,
      };
      break;
    }
    case getType(getZipCode.failure): {
      draft.isLoading = false;
      draft.hasError = true;
      draft[countryCode] = {
        error: action.payload.error.data,
      };
      break;
    }
    default:
      break;
  }
}, defaultState);
