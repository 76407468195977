import { createAsyncAction } from 'typesafe-actions';

import { Data as FreeDeliveryData } from '../../types/FreeDeliveryResponse';

export interface FreeDeliverySuccessPayload {
  data: FreeDeliveryData;
}

export interface FreeDeliveryFailPayload {
  error: Error;
}

export const getFreeDeliveryActions = createAsyncAction(
  'verybuy/GET_FREE_DELIVERY',
  'verybuy/GET_FREE_DELIVERY_SUCCESS',
  'verybuy/GET_FREE_DELIVERY_FAIL'
)<undefined, FreeDeliverySuccessPayload, FreeDeliveryFailPayload>();
