import { Component } from 'react';
import {
  DeviceEventEmitter,
  EmitterSubscription,
  Linking,
  UIManager,
} from 'react-native';
import safeAwait from 'safe-await';

import {
  isAndroid,
  isApp,
  isIOS,
  isServer,
  isWebPlatform,
  setUserInfo,
} from '../../boot/utils';
import FeatureFlags from '../../constants/FeatureFlags';
import type { AuthProps } from '../../decorators/withAuth';
import { CategoriesPayload } from '../../redux/actions/categoriesActions';
import getBannersActions from '../../redux/actions/getBannersActions';
import { getLandingPageListActions } from '../../redux/actions/getLandingPageListActions';
import { TopKeywordsPayload } from '../../redux/actions/getTopKeywordsActions';
import { VBConfigPayload } from '../../redux/actions/getVBConfigActions';
import { MemberNotificationsPayload } from '../../redux/actions/updateMemberInfoActions';
import { UpdateRemoteValuesPayload } from '../../redux/actions/updateRemoteValuesAction';
import { patchFCMToken } from '../../redux/api';
import { CategoriesPosition } from '../../types/Categories';
import { initIterable } from '../../utils/analytics/iterableFactory/iterableEvents';
import { InitLineTag } from '../../utils/analytics/lineTag/sendLineTagEvent';
import Config from '../../utils/configs';
import handleLineId from '../../utils/handleLineId';
import NativeLinking from '../../utils/nativeLinking';
import { Settings, getDeferredAppLink } from '../../utils/packages/fbsdk';
import firebase, {
  getFCMToken,
  getRemoteValue,
} from '../../utils/packages/firebase';
import pageNavigation from '../../utils/pageNavigation';
import * as sensorsHelpers from '../../utils/sensorsHelpers';
import { handleSaveUTMIfExists } from '../../utils/utmHelpers';
import {
  layoutName as homeBannerLayoutName,
  bannerPositions as homeBannerPositions,
} from '../HomePage/Banner';
import {
  layoutName as promoteBannerLayoutName,
  bannerPositions as promoteBannerPositions,
} from '../HomePage/PromoteBanner';
import AltGhostIdHandler from './AltGhostIdHandler';
import LogNotiPermissionEvent from './LogNotiPermissionEvent';
import PushNotificationHandler from './PushNotificationHandler';

const isVeryBuyLink = (url: string) => {
  const checker =
    url.startsWith(Config.REACT_APP_WEB_VIEW_URL!) ||
    url.startsWith(`https://${Config.MHOST}`) ||
    url.startsWith(`${Config.REACT_APP_SCHEME}://`);

  return checker;
};

interface Props extends Pick<AuthProps, 'isLogin' | 'memberInfo'> {
  getVBConfig: (payload: VBConfigPayload) => void;
  fetchCategories: (payload: CategoriesPayload) => void;
  getBanners: typeof getBannersActions.request;
  getLandingPageList: typeof getLandingPageListActions.request;
  getMemberInfo: () => void;
  getTopKeyWords: (payload: TopKeywordsPayload) => void;
  getRecommendSubject: () => void;
  updateMemberNotifications: (payload: MemberNotificationsPayload) => void;
  getCats: ({}) => void;
  getCategoryList: ({}) => void;
  updateRemoteValuesAction: (payload: UpdateRemoteValuesPayload) => void;
}

const updateFcmToken = async () => {
  try {
    const fcmToken = await getFCMToken();

    if (fcmToken) {
      await patchFCMToken({ fcmToken });
    }
  } catch (error) {
    console.log('updateFcmToken error', error);
  }
};

export default class AppInit extends Component<Props> {
  firebaseDBListener;
  dynamicLinksUnSubscriber;
  urlSubscription?: EmitterSubscription;

  constructor(props: Props) {
    super(props);

    /**
     * The `autoInitEnabled` option is removed from facebook-ios-sdk, should initialize manually
     * See https://github.com/facebook/facebook-ios-sdk/blob/master/CHANGELOG.md#removed
     */
    if (isIOS) {
      Settings.initializeSDK();
    }

    const { isLogin, memberInfo, updateRemoteValuesAction } = props;

    // 神策調用順序：先設置公共屬性，再激活 install，後才用戶關聯
    sensorsHelpers.registerSuperProperties({ is_login: isLogin });
    // 因目前第二參數還沒決定要給什麼，故將空值。
    sensorsHelpers.trackInstallation({});
    sensorsHelpers.initSensorsID({ MID: memberInfo.MID, isLogin });

    if (isLogin && memberInfo.MID) {
      sensorsHelpers.handleLogin(memberInfo.MID);
      setUserInfo(memberInfo);
      !isServer && updateFcmToken();
    }
    // initial Iterable
    initIterable().catch(err => console.error(err));

    if (FeatureFlags.CX_2444_OVERSEA_SHIPPING_FEE) {
      getRemoteValue('CX_2444_cross_border_shipping_fee').then(value => {
        updateRemoteValuesAction({
          CX_2444_cross_border_shipping_fee: value?.asBoolean() || false,
        });
      });
    }
    this.enableLayoutAnimation();
    this.fetchData();
  }

  openLinkEventListener: EmitterSubscription | undefined = undefined;

  componentDidMount() {
    if (isServer) {
      return;
    }

    const {
      memberInfo: { MID },
    } = this.props;

    this.openLinkEventListener = DeviceEventEmitter.addListener(
      'OpenLinkEvent',
      event => {
        if (event?.url) {
          pageNavigation(event?.url);
        }
      }
    );

    if (isWebPlatform) {
      handleSaveUTMIfExists(window.location.href);
    } else {
      this.handleDeepLink();
    }

    if (MID) {
      this.handleFirebaseDB({ MID, listen: true }).catch(console.log);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      memberInfo: { MID },
    } = this.props;

    if (prevProps.memberInfo.MID !== MID) {
      this.handleFirebaseDB({
        MID: prevProps.memberInfo.MID,
        listen: false,
      }).catch(console.log);
      this.handleFirebaseDB({ MID, listen: true }).catch(console.log);
    }
  }

  componentWillUnmount() {
    if (!isWebPlatform && this.urlSubscription) {
      this.urlSubscription.remove();
    }
    if (this.dynamicLinksUnSubscriber) {
      this.dynamicLinksUnSubscriber();
    }

    if (this.openLinkEventListener) {
      this.openLinkEventListener.remove();
    }
  }

  fetchData = () => {
    const {
      isLogin,
      getCats,
      getCategoryList,
      fetchCategories,
      getVBConfig,
      getBanners,
      getMemberInfo,
      getTopKeyWords,
      getRecommendSubject,
      getLandingPageList,
    } = this.props;

    getCats({});
    getCategoryList({});
    fetchCategories({ position: CategoriesPosition.Home });
    getVBConfig({});
    getBanners({
      positions: homeBannerPositions,
      layoutName: homeBannerLayoutName,
      loadingType: 'new',
    });
    getBanners({
      positions: promoteBannerPositions,
      layoutName: promoteBannerLayoutName,
      loadingType: 'new',
    });
    getTopKeyWords({});
    getRecommendSubject();
    getLandingPageList();

    if (isLogin) {
      getMemberInfo();
    }
  };

  handleInitialURL = (url: string) => {
    if (isIOS) {
      // 因 iOS 觸發 deep link 有機率會卡在目的頁無法做任何操作，故嘗試延遲進入目的頁可以改善。
      // 試過 InteractionManager.runAfterInteractions()，不起作用。
      setTimeout(() => {
        this.processDeepLinkUrl(url);
      }, 300);
    } else {
      this.processDeepLinkUrl(url);
    }
  };

  handleDeepLink = () => {
    /**
     * 因 getInitialURL() 在 RN 0.6 的 Android 上改成需等 runAfterInteractions() 後才會執行 getInitialURL()，
     * 故造成 deep link 效果會慢很多才執行。
     * 參考: https://github.com/facebook/react-native/blob/master/Libraries/Linking/Linking.js#L86-L92
     *
     * 暫時解決方式是用原本的 NativeLinking 去執行 getInitialURL()。
     * 參考: https://github.com/Plant-for-the-Planet-org/treecounter-app/pull/2199/files#diff-00178860800856051b5855aed1007902R68
     *
     * 更新: 發現使用 react-native-bootsplash 造成 iOS deep link 壞掉，將 iOS 也執行 NativeLinking.getInitialURL 可以解決。
     */
    NativeLinking.getInitialURL()
      .then((url: string | null) => {
        if (url) {
          this.handleInitialURL(url);
        }
      })
      .catch(err => console.error('An error occurred', err));

    getDeferredAppLink()
      .then(url => {
        if (url) {
          this.processDeepLinkUrl(url);
        }
      })
      .catch(err => console.error('deferred deep link error', err));

    this.urlSubscription = Linking.addEventListener('url', this.handleUrlEvent);

    /**
     * Dynamic Links
     * foreground
     * https://rnfirebase.io/dynamic-links/usage#foreground-events
     * When the component is unmounted, remove the listener
     */
    this.dynamicLinksUnSubscriber = firebase
      .dynamicLinks()
      .onLink(this.handleDynamicLink);
    // background
    firebase.dynamicLinks().getInitialLink().then(this.handleDynamicLink);
  };

  handleDynamicLink = link => {
    const dynamicURL = link?.url;

    if (dynamicURL && isVeryBuyLink(dynamicURL)) {
      handleSaveUTMIfExists(dynamicURL);
      pageNavigation(dynamicURL);
    }
  };

  handleUrlEvent = event => {
    const url = event?.url;

    this.processDeepLinkUrl(url);
  };

  processDeepLinkUrl = async (url?: string | null) => {
    if (!url) {
      return;
    }

    /**
     *  handle fire dynamic link
     *  checkout unit test describe('dynamicLinks') part
     */
    const [dynamicLinksError, dynamicLinksLink] = await safeAwait(
      firebase.dynamicLinks().resolveLink(url)
    );

    if (!dynamicLinksError && dynamicLinksLink?.url) {
      // if the link is dynamicLink, then break;
      return;
    } else {
      handleSaveUTMIfExists(url);
      // note: it will filter clid params and return a new url
      // replace url without clid params
      const _url = handleLineId.setClidFromURL(url);

      if (_url) {
        url = _url;
      }
    }

    /**
     * Handling in-app messaging url depends on platform
     *
     * [iOS]
     * Click on an in-app messaging with url will be handled by handleOpenURL,
     * we should check if is a verybuy link or not.
     *
     * [Android]
     * Clicking an in-app messaging with url will open an in-app WebView using
     * androidx.browser.customtabs.CustomTabsIntent, the url will be treated as
     * usual.
     */
    if (url.startsWith('https://blog.verybuy.cc')) {
      const blogPath = encodeURIComponent(
        url.replace('https://blog.verybuy.cc', '')
      );

      // formatting blog url with pageNavigation
      const navUrl = `https://${Config.MHOST}/blog?path=${blogPath}`;

      pageNavigation(navUrl);
    } else if (isIOS) {
      if (isVeryBuyLink(url)) {
        pageNavigation(url);
      } else if (url.startsWith('http')) {
        Linking.openURL(url).catch(console.log);
      }
    } else {
      pageNavigation(url);
    }
  };

  handleFirebaseDB = async ({ MID, listen }) => {
    const { updateMemberNotifications } = this.props;

    if (!MID) {
      updateMemberNotifications({ notifications: 0 });

      return;
    }

    const [error, database] = await safeAwait(firebase.database());

    if (error) {
      return;
    }

    if (listen) {
      this.firebaseDBListener = database()
        .ref(`/notifications/${MID}`)
        .on('value', snapshot => {
          const count = snapshot.numChildren();

          updateMemberNotifications({ notifications: count });
        });
    } else {
      database()
        .ref(`/notifications/${MID}`)
        .off('value', this.firebaseDBListener);
    }
  };

  render() {
    return (
      <>
        {isApp && <PushNotificationHandler />}
        {isApp && <AltGhostIdHandler />}
        {isWebPlatform && <InitLineTag />}
        <LogNotiPermissionEvent />
      </>
    );
  }

  enableLayoutAnimation = () => {
    if (isAndroid) {
      if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
      }
    }
  };
}
