import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Item } from '../../types/similarItems';

export interface SimilarItemsPayload {
  itemId: number | string;
  loadingType: ListLoadingTypes;
  success: (products: Item[]) => void;
  page?: number;
  perpage?: number;
}

export interface SimilarItemsSuccessPayload {
  itemId: number | string;
  products: Item[];
  loadingType: ListLoadingTypes;
}

export interface SimilarItemsFailPayload {
  error: Error;
}

export const getSimilarItemsActions = createAsyncAction(
  'verybuy/GET_SIMILAR_ITEMS',
  'verybuy/GET_SIMILAR_ITEMS_SUCCESS',
  'verybuy/GET_SIMILAR_ITEMS_FAIL'
)<SimilarItemsPayload, SimilarItemsSuccessPayload, SimilarItemsFailPayload>();
