import { getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Coupon } from '../../types/Coupon';
import * as arrayHandler from '../../utils/arrayHandler';
import { actionsList } from '../rootAction';

const { getCouponListActions } = actionsList;

interface CouponListState {
  coupons: Coupon[];
  isFetching: boolean;
  hasMoreItems?: boolean;
  loadingType?: ListLoadingTypes;
  hasError?: boolean;
  page: number;
  error?: any;
}

function process(
  state: CouponListState = {
    coupons: [],
    isFetching: false,
    hasMoreItems: true,
    loadingType: 'new',
    page: 0,
    hasError: false,
  },
  action
) {
  switch (action.type) {
    case getType(getCouponListActions.request): {
      const { loadingType } = action.payload;

      return {
        ...state,
        isFetching: true,
        loadingType,
      };
    }
    case getType(getCouponListActions.success): {
      const { coupons, page } = action.payload;

      const newData =
        state.loadingType === 'loadMore'
          ? (state.coupons || []).concat(coupons)
          : coupons;

      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: null,
        hasMoreItems: coupons.length > 0,
        coupons: arrayHandler.uniqueArray(newData),
        page,
      };
    }
    case getType(getCouponListActions.failure): {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        hasMoreItems: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}

export default function (state = {}, action) {
  switch (action.type) {
    case getType(getCouponListActions.request):
    case getType(getCouponListActions.success):
    case getType(getCouponListActions.failure): {
      const { couponType } = action.payload;

      return {
        ...state,
        [couponType]: process(state[couponType], action),
      };
    }
    default: {
      return state;
    }
  }
}
