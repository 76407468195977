import pick from 'lodash/pick';

import { isWebPlatform } from '../../boot/utils';
import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import ElementType from '../../constants/ElementType';
import { isBot } from '../../constants/misc';
import { SensorsAnalyticsEvent } from '../../constants/SensorsAnalyticsEvent';
import { sortText } from '../../constants/sortData';
import { RootState } from '../../redux/rootReducer';
import { cartContentItemAttrSelector } from '../../redux/selectors/cartContentSelector';
import {
  makeErrorReason,
  makeHasError,
  makeMemberInfo,
  makeMemberInfoByKey,
} from '../../redux/selectors/member';
import { makeNotificationById } from '../../redux/selectors/notificationsSelector';
import { makeProductByIdSelector } from '../../redux/selectors/productById';
import { makePromoteItemDataById } from '../../redux/selectors/promotionalThemesSelector';
import { Notification } from '../../types/Notifications';
import * as sensorsHelpers from '../../utils/sensorsHelpers';
import detectIncognito from '../detect-incognito';
import isExists from '../isExists';
import sensors from './sensors';
import { transformToStandardEvent } from './transformToStandardEvent';

// doc: https://docs.google.com/spreadsheets/d/133K0-WwwuGXn6dqQXSx4BA3kgys2KffAy8ysrQh35MA/edit#gid=0
const notificationTypeMapping = {
  '1': '訂單成立',
  '2': '入帳成功',
  '3': '刷卡未完成',
  '4': '催付通知',
  '5': '出貨',
  '6': '超取到貨',
  '7': '二次超取到貨',
  '8': '發票開立',
  '9': '退現金',
  '10': '退款通知',
  '11': '退刷通知',
};

function generateEventData(
  logEvent: AnalyticsEvent,
  logData: Record<string, any>,
  currentStore: RootState
) {
  // ignore bot request
  if (isBot) return;

  const generalInfo = sensorsHelpers.getGeneralInfo();
  let logPayload: Record<string, any> = {};

  switch (logEvent) {
    case AnalyticsEvent.PushClick:
    case AnalyticsEvent.PushReceived:
      // implemented at src/utils/pushNotification/logPushNotification.ts
      return;
    case AnalyticsEvent.Login:
    case AnalyticsEvent.SignUp: {
      const { account, method } = logData;
      const hasError = makeHasError()(currentStore);
      const errorReason = makeErrorReason()(currentStore);
      const methodKey =
        logEvent === AnalyticsEvent.Login ? 'login_method' : 'register_method';
      const failReason = (hasError && errorReason) || '';
      const MID = makeMemberInfoByKey('MID')(currentStore) || '';

      logPayload = {
        account,
        is_success: !hasError,
        [methodKey]: method,
        fail_reason: failReason,
      };

      if (!hasError) {
        sensorsHelpers.handleLogin(MID);
      }

      break;
    }
    case AnalyticsEvent.CreditCardRepay:
    case AnalyticsEvent.CreditCardRepayAtm:
    case AnalyticsEvent.CreditCardRepaySubmit: {
      logPayload = {
        order_id: logData?.transactionID || 'UNKNOWN_ORDER_ID',
      };

      break;
    }
    case AnalyticsEvent.CartRemove: {
      logPayload = {
        vid: `${logData.data.vid}`,
        stockout: logData.data.isOutOfStock || false,
      };

      break;
    }
    case AnalyticsEvent.AddToCart: {
      logPayload = {
        commodity_name: logData?.title || 'EMPTY_ITEM_NAME',
        commodity_id: logData?.marketing_product_id || 'EMPTY_ITEM_ID',
        commodity_tag: logData?.tags || [],
        commodity_quantity: logData?.amount || 0,
        commodity_Var1: logData?.color_name || '',
        commodity_Var2: logData?.size_name || '',
        present_price: logData?.priceNTD || 0,
      };
      break;
    }
    case AnalyticsEvent.ApplyFilter: {
      const {
        min_price,
        max_price,
        categories,
        colors,
        categoryId,
        applyType,
        patterns,
        sellerId = '',
      } = logData?.data || {};

      logPayload = {
        minPrice: min_price || '',
        maxPrice: max_price || '',
        categories: categories || [],
        colors: colors || [],
        patterns: patterns || [],
        categoryId: categoryId || '',
        applyType: applyType || '',
        track_id: sellerId,
      };
      break;
    }
    case AnalyticsEvent.GeneralEvent: {
      const data = logData?.data || {};

      // ref: getLogPayloadForFirebase.ts
      logPayload = {
        action_name: data.action_name,
        event_category: data.event_category,
        event_label: data.event_label,
        non_interaction: data.non_interaction || false,
      };

      break;
    }
    case AnalyticsEvent.ScreenView:
      const view = logData?.data?.view || 'UNKNOWN_SCREEN_VIEW';
      const screenName = logData?.data?.screenName || 'UNKNOWN_SCREEN_NAME';
      const currentPath = logData?.data?.currentPath || 'UNKNOWN_PATH';

      setCurrentScreen(view, screenName, currentPath);

      return;
    case AnalyticsEvent.ItemMarketEvent:
      const { openType } = logData?.data || {};

      logPayload = {
        open_type: openType || '',
      };
      break;
    case AnalyticsEvent.ViewError: {
      const { error = {} } = logData?.data || {};

      const errorStack = `${error.stack || 'unknown'}`.slice(0, 200);

      logPayload = {
        errorMsg: error.message || 'UNKNOWN_MESSAGE',
        errorName: error.name || 'UNKNOWN_NAME',
        errorStack,
      };
      break;
    }
    case AnalyticsEvent.ViewItem:
      logPayload = {
        commodity_id: `${logData?.data?.marketing_product_id}`,
        commodity_name: `${logData?.data?.title}`,
        commodity_tag: logData?.data?.tags || [],
        commodity_type: logData?.data?.itemType || '',
        present_price: logData?.data?.price_ntd || 0,
        video_count: logData?.data?.videoCount || 0,
      };
      break;
    case AnalyticsEvent.Search:
      logPayload = {
        key_word: `${logData?.data?.queryText}`,
        search_type: logData?.data?.searchType,
        is_history_word_applied: logData?.data?.isHistoryWordApplied || false,
      };
      break;
    case AnalyticsEvent.ViewSearchResults:
      const minPrice = logData?.minPrice;
      const maxPrice = logData?.maxPrice;
      const sortBy = logData?.sortBy;
      const hasFilter = minPrice || maxPrice;
      const filter = hasFilter
        ? `價格範圍: ${minPrice} ~ ${maxPrice}`
        : 'NO_FILTER_SET';

      logPayload = {
        key_word: logData?.queryText || 'UNKNOWN_KEY_WORD',
        is_history_word_applied: logData?.isHistoryWordApplied || false,
        is_success: logData?.isSuccess || false,
        result_number: logData?.resultNumber || 0,
        sort_by: sortText[sortBy] || 'UNKNOWN_SORT',
        filter,
      };
      break;
    case AnalyticsEvent.Click: {
      const elementType = logData?.data?.elementType;
      const id = logData?.data?.id || 'UNKNOWN_ID';

      logPayload = {
        mkt_type: logData?.data?.mktType || 'UNKNOWN_MKT_TYPE',
        mkt_name: logData?.data?.layoutName || 'UNKNOWN_MKT_NAME',
        mkt_location: logData?.data?.pitNumber,
        element_content: logData?.data?.element_content,
      };

      if (isWebPlatform) {
        /**
         * @description: It's use to get web path.
         * @example: localhost/recommend/item/123
         */
        logPayload.mkt_page =
          window.location.hostname + window.location.pathname;
      }

      if (
        elementType === ElementType.Item ||
        elementType === ElementType.RecommendComboItem
      ) {
        const itemInfo = makeProductByIdSelector(id)(currentStore);
        const source = logData?.data?.source;

        logPayload = {
          ...logPayload,
          commodity_id:
            itemInfo?.marketing_product_id || 'UNKNOWN_COMMODITY_ID',
          commodity_name: itemInfo?.title || 'UNKNOWN_COMMODITY_NAME',
          source: source || 'UNKNOWN_SOURCE',
        };
      } else if (elementType === ElementType.CartContentItem) {
        const vid = logData?.data?.vid;
        const source = logData?.data?.source;
        const marketingProductId = cartContentItemAttrSelector(
          vid,
          'marketing_product_id'
        )(currentStore);
        const title = cartContentItemAttrSelector(vid, 'title')(currentStore);

        logPayload = {
          ...logPayload,
          commodity_id: marketingProductId || 'UNKNOWN_COMMODITY_ID',
          commodity_name: title || 'UNKNOWN_COMMODITY_NAME',
          source,
        };
      } else if (elementType === ElementType.CategoryListItem) {
        const currentSubCat = logData?.data?.currentSubCat;
        const itemInfo = makeProductByIdSelector(id)(currentStore);
        const source = logData?.data?.source;
        const vbParentCategory =
          currentSubCat.name || 'UNKNOWN_VB_PARENT_CATEGORY';
        const vbCategory = isExists(currentSubCat.name)
          ? currentSubCat.name
          : 'UNKNOWN_VB_CATEGORY';

        logPayload = {
          ...logPayload,
          commodity_id:
            itemInfo?.marketing_product_id || 'UNKNOWN_COMMODITY_ID',
          commodity_name: itemInfo?.title || 'UNKNOWN_COMMODITY_NAME',
          source: source || 'UNKNOWN_SOURCE',
          vb_parent_category: vbParentCategory,
          vb_category: vbCategory,
        };
      } else if (elementType === ElementType.HistoryItem) {
        const itemInfo = makeProductByIdSelector(id)(currentStore);
        const title =
          logData?.data?.title || itemInfo?.title || 'UNKNOWN_COMMODITY_NAME';
        const tid =
          logData?.data?.marketID ||
          itemInfo?.marketing_product_id ||
          'UNKNOWN_COMMODITY_ID';
        const source = logData?.data?.source || 'UNKNOWN_SOURCE';

        logPayload = {
          ...logPayload,
          commodity_id: tid,
          commodity_name: title,
          source,
        };
      } else if (elementType === ElementType.PromoteItem) {
        const info = makePromoteItemDataById(id)(currentStore);

        logPayload = {
          ...logPayload,
          operation_id: info.id || NaN,
          operation_loading_page: info.landing_page || 'unknown',
          operation_title: info.title || 'unknown',
          operation_url: info.url || 'unknown',
        };
      }

      break;
    }
    case AnalyticsEvent.CreditCardAdd: {
      logPayload = {
        credit_card_error: logData?.creditCardError,
      };
      break;
    }
    case AnalyticsEvent.AddToWishList: {
      const id = logData?.id;
      const itemInfo = makeProductByIdSelector(id)(currentStore);

      logPayload = {
        commodity_id: itemInfo.marketing_product_id || 'UNKNOWN_COMMODITY_ID',
        commodity_name: itemInfo.title || 'UNKNOWN_COMMODITY_NAME',
        present_price: Number(itemInfo.min_price || 0),
        layout_name: logData?.layoutName || 'UNKNOWN_LAYOUT',
      };
      break;
    }
    case AnalyticsEvent.ECommercePurchaseFE: {
      const data = logData?.data || {};

      logPayload = {
        transportation_costs: data.shippingFee || 0,
        order_amount: data.totalPriceTW || data.totalPrice || 0,
      };

      break;
    }
    case AnalyticsEvent.ShareClick: {
      const { id } = logData?.data;
      const itemInfo = makeProductByIdSelector(id)(currentStore);

      logPayload = {
        commodity_id: itemInfo?.marketing_product_id || 'UNKNOWN_COMMODITY_ID',
        commodity_name: itemInfo?.title || 'UNKNOWN_COMMODITY_NAME',
        commodity_tag: itemInfo?.tags || [],
        present_price: itemInfo?.min_price_ntd || 0,
      };

      break;
    }
    case AnalyticsEvent.ScreenShot: {
      const id = logData?.data?.id;
      const itemInfo = makeProductByIdSelector(id)(currentStore);

      logPayload = {
        commodity_id: itemInfo?.marketing_product_id || 'UNKNOWN_COMMODITY_ID',
        commodity_name: itemInfo?.title || 'UNKNOWN_COMMODITY_NAME',
        commodity_tag: itemInfo?.tags || [],
        present_price: itemInfo?.min_price_ntd || 0,
      };

      break;
    }
    case AnalyticsEvent.TelexpressOpen: {
      logPayload = {
        screen_name: generalInfo.route_name,
      };

      break;
    }
    case AnalyticsEvent.NotificationBellClick:
      logPayload = {
        notification_place: logData?.data?.location || 'UNKNOWN',
      };

      break;
    case AnalyticsEvent.TelexpressClose: {
      logPayload = {
        close_by: logData?.data?.closeBy || 'UNKNOWN',
      };

      break;
    }
    case AnalyticsEvent.ShareResult: {
      const { id, isSuccess, shareType } = logData?.data;
      const itemInfo = makeProductByIdSelector(id)(currentStore);

      logPayload = {
        commodity_id: itemInfo?.marketing_product_id || 'UNKNOWN_COMMODITY_ID',
        commodity_name: itemInfo?.title || 'UNKNOWN_COMMODITY_NAME',
        commodity_tag: itemInfo?.tags || [],
        present_price: itemInfo?.min_price_ntd || 0,
        is_success: isSuccess,
        share_type: shareType,
      };

      break;
    }
    case AnalyticsEvent.NotificationListClick: {
      const { id, isRead } = logData?.data || {};
      const { title, description, type }: Notification =
        makeNotificationById(id)(currentStore);

      logPayload = {
        notification_title: title ?? 'UNKNOWN_TITLE',
        notification_content: description ?? 'UNKNOWN_CONTENT',
        notification_type: notificationTypeMapping[type] || 'UNKNOWN_TYPE',
        is_notification_read: isRead,
      };
      break;
    }
    case AnalyticsEvent.SendLightbox:
    case AnalyticsEvent.LightboxButtonClick: {
      const mapping = {
        [AnalyticsEvent.SendLightbox]: [
          'lightbox_name',
          'lightbox_title',
          'lightbox_content',
          'lightbox_page',
        ],
        [AnalyticsEvent.LightboxButtonClick]: [
          'lightbox_name',
          'lightbox_title',
          'lightbox_content',
          'lightbox_button_name',
          'lightbox_page',
        ],
      };

      logPayload = pick(logData?.data || {}, mapping[logEvent]);
      break;
    }
    case AnalyticsEvent.View500KERKER: {
      const { MID, guestID } = makeMemberInfo()(currentStore);

      if (!MID && !guestID) return;

      logPayload = logData?.data;

      if (MID) {
        logPayload.MID = MID;
      } else {
        logPayload.GID = guestID;
      }

      break;
    }
    case AnalyticsEvent.SearchOpen: {
      const { placeholder, location } = logData?.data;

      logPayload = {
        screen_name: generalInfo.route_name.replace('_ROUTE', '_SCREEN_NAME'),
        placeholder: placeholder ?? 'UNKNOWN_PLACEHOLDER',
        mkt_name: location || 'UNKNOWN',
      };

      break;
    }
    case AnalyticsEvent.PushRedeem: {
      const { isAllowNoti } = logData?.data || {};

      logPayload = {
        value: isAllowNoti,
        screenName: generalInfo.route_name.replace('_ROUTE', '_SCREEN_NAME'),
      };

      break;
    }
    default:
      break;
  }

  const additionalLogData = logData.data?.additionalLogData || {};

  logPayload = {
    ...generalInfo,
    ...logPayload,
    ...additionalLogData,
  };

  return logPayload;
}

export default function sendSensorsEvents(
  logEvent: AnalyticsEvent,
  logData: Record<string, any>,
  currentStore: RootState
) {
  let logPayload: Record<string, any> | Record<string, any>[] = [];

  const generatedData = generateEventData(logEvent, logData, currentStore);

  if (!generatedData) {
    return;
  }

  logPayload = generatedData;

  if (transformToStandardEvent(SensorsAnalyticsEvent)[logEvent]) {
    sensorsHelpers.batchTrack(
      transformToStandardEvent(SensorsAnalyticsEvent)[logEvent],
      logPayload
    );
  }
}

export function setCurrentScreen(view = '', screenName = '', currentPath = '') {
  if (isWebPlatform) {
    // ignore bot request
    if (isBot) return;
    // 目前 Web 神策數據由這邊統一發送 pageView 事件
    // Ref: https://www.sensorsdata.cn/2.0/manual/js_sdk_autotrack.html
    detectIncognito().then(isInCognitoMode => {
      // @ts-expect-error
      sensors.quick('autoTrackSinglePage', {
        isInCognitoMode,
      });
    });
  } else {
    const logPayload = {
      $screen_name: screenName,
      $title: view,
      path: currentPath,
    };

    sensors.track(
      transformToStandardEvent(SensorsAnalyticsEvent)[
        AnalyticsEvent.ScreenView
      ],
      logPayload
    );
  }
}
