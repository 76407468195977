import { isServer, isWebPlatform } from '../boot/utils';
import { isBot } from '../constants/misc';
import { DeviceType } from '../types/Common';
import { MobileWebOnClickAction } from '../types/Sensors';
import { getPlatform } from './analytics/awsFirehose/util';
import postToCypress from './analytics/postToCypress';
import sensors from './analytics/sensors';
import Config from './configs';
import { countryCodeHandler } from './countryInfo';
import { getGhostID, removeGhostID } from './ghostIDHelpers';
import isExists from './isExists';
import { getDeviceType } from './layoutHelpers';
import * as navigationService from './navigationService';
import pageNavigation from './pageNavigation';
import * as routeUtils from './routes/utils';

export function handleSetSensorGhostID() {
  getGhostID().then(ghostID => {
    sensors.identify(ghostID);
  });
}

const _tracesSampleRate: { [key: string]: number } = {};

export function setSampleRate(name: string, rate: number) {
  _tracesSampleRate[name] = rate;
}

export function couldTrack(name: string): boolean {
  // alwaysCouldTrack is true only for develop
  const alwaysCouldTrack =
    process.env.NODE_ENV !== 'test' && Config.REACT_APP_ENV_TYPE !== 'Release';

  const rate = _tracesSampleRate[name] || 1;

  return alwaysCouldTrack || Math.random() < rate;
}

export function initSensorsID({ isLogin, MID }) {
  if (isLogin) {
    sensors.identify(MID);
  } else {
    handleSetSensorGhostID();
  }
}

export function registerSuperProperties(args: Record<string, any> = {}) {
  // It may avoid analytics events when isServer
  if (isServer) {
    return;
  }

  const { useDynamic, ...rest } = args;
  const platForm = getPlatform();
  const payload = {
    // 只有 web 的 platform_type 需要調整開頭為大寫
    platform_type: isWebPlatform ? 'MWeb' : platForm,
    site_region: countryCodeHandler.get(),
    ...rest,
  };

  if (isWebPlatform) {
    // @ts-expect-error
    sensors.registerPage(payload);
  } else {
    if (useDynamic) {
      // https://manual.sensorsdata.cn/sa/2.3/react-native-1574001.html#id-.ReactNativev1.13-%E8%AE%BE%E7%BD%AE%E4%BA%8B%E4%BB%B6%E5%8A%A8%E6%80%81%E5%85%AC%E5%85%B1%E5%B1%9E%E6%80%A7
      const dynamicSuperProperties = sensors.registerDynamicSuperProperties();

      // @ts-expect-error
      dynamicSuperProperties.properties = payload;
    } else {
      sensors.registerSuperProperties(payload);
    }
  }
}

export function handleLogin(MID: string) {
  sensors.login(MID);
  registerSuperProperties({ is_login: true });
}

export function handleLogout() {
  sensors.logout();
  removeGhostID().then(handleSetSensorGhostID);
  registerSuperProperties({ is_login: false });
}

function handleDesktopWebPopup(apiBaseUrl: string) {
  import(
    'sa-sdk-javascript/dist/web/plugin/sf-sdk-web/webPopup.esm.min.js'
  ).then(() => {
    // @ts-expect-error: there is no "use" in App module
    sensors.use('WebPopup', {
      api_base_url: apiBaseUrl,
      show_log: false,
      popup_campaign_listener: {
        /** 由返回值决定弹窗是否可以触达，返回 true 可以触达，false 不触达 */
        shouldStart(_SFCampaign) {
          return true;
        },
        /** 触达开始回调函数 */
        onStart(_SFCampaign) {
          // @TODO
        },
        /** 触达点击 */
        onClick(_SFCampaign) {
          // @TODO
        },
        /** 触达结束回调函数 */
        onEnd(_SFCampaign) {
          // @TODO
        },
        /** 触达失败回调函数 */
        onFailed(_SFCampaign, _errorCode, _errorMessage) {
          // @TODO
        },
      },
    });
  });
}

function handleMobileWebPopup(apiBaseUrl: string) {
  import('sa-sdk-javascript/dist/web/plugin/sf-sdk-web/popup.esm.min.js').then(
    () => {
      // @ts-expect-error: there is no "use" in App module
      sensors.use('Popup', {
        api_base_url: apiBaseUrl,
        show_log: false,
        popup_listener: {
          onClick(_planId: string, action: MobileWebOnClickAction) {
            if (action.type === 'openlink' && action.value) {
              pageNavigation(action.value);
            }
          },
          onLoadSuccess(_planId: string) {
            // @TODO
          },
          onLoadFailed(
            _planId: string,
            _errorCode: number,
            _errorMessage: string
          ) {
            // @TODO
          },
          onClose(_planId: string) {
            // @TODO
          },
        },
      });
    }
  );
}

function handlePopup() {
  const deviceType = getDeviceType();
  const isTablet = deviceType === DeviceType.TABLET;
  const isMobile = deviceType === DeviceType.MOBILE;
  const POPUP_API_BASE_URL = 'https://www.data-di.com/dataDi';
  const handlePopupFunc =
    isTablet || isMobile ? handleMobileWebPopup : handleDesktopWebPopup;

  handlePopupFunc(POPUP_API_BASE_URL);
}

export function trackInstallation(param) {
  if (isWebPlatform) {
    // ignore bot request
    if (isBot) return;

    const sensorParameter = {
      server_url: Config.SENSORS_SERVER_URL,
      heatmap: {
        clickmap: 'default',
        scroll_notice_map: 'default',
        scroll_delay_time: 4000,
        custom_property: target => {
          const accessibilityLabel = target.getAttribute('aria-label');

          if (!!accessibilityLabel) {
            return {
              $element_content: accessibilityLabel,
            };
          }

          return null;
        },
      },
      send_type: 'beacon',
      show_log: false,
      /**
       * we did navigate tracking by ourself, so do not enable 'track_single_page' or tracking data will be a mess
       * see document:
       * https://manual.sensorsdata.cn/sa/latest/tech_sdk_client_web_high-7549300.html
       * is_track_single_page: true,
       */
      batch_send: false,
    };

    sensors.init(sensorParameter);
    handlePopup();
  } else {
    sensors.trackInstallation('AppInstall', param);
  }
}

export function trackAllHeatMap(target) {
  if (isWebPlatform) {
    // @ts-expect-error
    sensors.quick('trackAllHeatMap', target);
  }
}

export function batchTrack(event, data, couldTrackFunction = couldTrack) {
  if (typeof data.forEach === 'function') {
    data.forEach(item => {
      if (couldTrackFunction(event)) {
        postToCypress('sensors', event, item);
        sensors.track(event, item);
      }
    });
  } else {
    if (couldTrackFunction(event)) {
      postToCypress('sensors', event, data);
      sensors.track(event, data);
    }
  }
}

enum FirehoseToSensorsAlias {
  'pit-number' = 'mkt_location',
  generated = 'source',
  tid = 'commodity_id',
  where = 'mkt_name',
}

export function formatDataFromFirehose(firehoseData, customProperties?) {
  if (customProperties) {
    return customProperties.reduce((result, key) => {
      const newKey = FirehoseToSensorsAlias[key] || key;
      const theValue = firehoseData[key];

      if (isExists(theValue)) {
        return {
          ...result,
          [newKey]: theValue,
        };
      }

      return result;
    }, {});
  }

  return {};
}

export function getGeneralInfo() {
  const currentScreen =
    navigationService.screenSubject$.getValue()?.currentScreen;
  const { routeName, params } = currentScreen || {};
  const fullPath = routeName
    ? decodeURIComponent(
        routeUtils.getPathAndParamsFromData(routeName, params).fullPath
      )
    : 'UNKNOWN_PATH';

  return {
    route_name: routeName || 'UNKNOWN_ROUTE',
    path: fullPath || 'UNKNOWN_PATH',
  };
}
