import { createAsyncAction } from 'typesafe-actions';

import { CommonErrors } from '../../constants/VBErrorCode';
import { Announcements } from '../../types/Announcement';

export interface AnnouncementDetailPayload {
  id: string;
  onFailure: (errorCode: CommonErrors) => void;
}

export interface AnnouncementDetailSuccessPayload {
  id: string;
  data: Announcements;
}

export interface AnnouncementDetailFailPayload {
  id: string;
  error: Error;
  customErrorHandling?: true;
}

export const getAnnouncementDetailActions = createAsyncAction(
  'verybuy/GET_ANNOUNCEMENT_DETAIL',
  'verybuy/GET_ANNOUNCEMENT_DETAIL_SUCCESS',
  'verybuy/GET_ANNOUNCEMENT_DETAIL_FAIL'
)<
  AnnouncementDetailPayload,
  AnnouncementDetailSuccessPayload,
  AnnouncementDetailFailPayload
>();
