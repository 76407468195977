import axios from 'axios';

import { AnalyticsEvent } from '../constants/AnalyticsEvent';
import { isCypress } from '../constants/Cypress';
import { translate } from '../i18n';
import { requestWithRetry, setAxiosDefaultHeader } from '../redux/api/utils';
import Configs from '../utils/configs';
import * as cookieHelper from '../utils/cookieHelper';
import sensors from './analytics/sensors';
import { getCountryCode } from './locale';
import mmkvStorage from './mmkvStorage';

export const countryInfo = new Proxy(
  {
    TW: {
      get name() {
        return translate('country.Taiwan');
      },
      areaPhoneCode: '+886',
    },
    // 待新加坡支援後再打開
    // SG: {
    //   get name() {
    //     return translate('country.Singapore');
    //   },
    //   areaPhoneCode: '+65',
    // },
  },
  {
    get(target, prop) {
      const _prop = typeof prop === 'string' ? prop.toUpperCase() : prop;

      return Reflect.get(target, _prop);
    },
  }
);

export function getCountryInfoByCode(code: string, key?: string) {
  return key ? countryInfo?.[code]?.[key] : countryInfo[code] || {};
}

export function convertToLegacyForOldWeb(upperCaseCountryCode: string) {
  return upperCaseCountryCode === 'TW' ? 'tw' : 'gc';
}

class CountryCodeHandler {
  getInitCountryCode = () => {
    const countryCode =
      mmkvStorage.instance.getString(this._storeKey) || getCountryCode();
    const uppercaseCode = countryCode.toUpperCase();

    cookieHelper.setCookie({
      name: 'country_code',
      value: convertToLegacyForOldWeb(uppercaseCode),
    });

    return uppercaseCode;
  };

  _storeKey = 'countryCode';
  _countryCode = this.getInitCountryCode();

  get = () => {
    // FIXME: e2e 目前只有 TW 的測試，故一率回傳 TW
    if (isCypress) {
      return 'TW';
    }

    return this._countryCode;
  };

  getLegacyForOldWeb = () => {
    return convertToLegacyForOldWeb(this.get());
  };

  set = (countryCode: string) => {
    const uppercaseCode = countryCode.toUpperCase();

    this._countryCode = uppercaseCode;
    mmkvStorage.instance.set(this._storeKey, uppercaseCode);
    setAxiosDefaultHeader('country-code', uppercaseCode);
    cookieHelper.setCookie({
      name: 'country_code',
      value: convertToLegacyForOldWeb(uppercaseCode),
    });
  };
}

export const countryCodeHandler = new CountryCodeHandler();

export const getIsTW = () => countryCodeHandler.get() === 'TW';

interface CountryCodeResponse {
  countryCode: string;
}

export const handleSetCurrentCountryCode = () => {
  const promiseFn = () =>
    axios.get<CountryCodeResponse>(`https://${Configs.MHOST}/.country_code`);

  return requestWithRetry(promiseFn)
    .then(resp => {
      sensors.track(AnalyticsEvent.CountryCodeApi, {
        countryCode: resp.data.countryCode,
      });
      countryCodeHandler.set(resp.data.countryCode);
    })
    .catch(error => {
      sensors.track(AnalyticsEvent.CountryCodeApi, {
        error: error?.message || error,
      });
      console.log(error);
    });
};
