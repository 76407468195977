import AsyncStorage from '@react-native-async-storage/async-storage';
import { Component } from 'react';
import * as React from 'react';
import { Animated, StyleProp, View, ViewStyle } from 'react-native';
import safeAwait from 'safe-await';

import IconNewLike from '../../assets/svg/IconNewLike';
import IconNewLikeFilled from '../../assets/svg/IconNewLikeFilled';
import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { isCypress } from '../../constants/Cypress';
import ElementType from '../../constants/ElementType';
import { testIDs } from '../../constants/testIDs';
import { CommonErrors, MemberErrors } from '../../constants/VBErrorCode';
import { globalNormalDialogProxy } from '../../elements/Dialog/GlobalNormalDialog';
import Touchable from '../../elements/Touchable';
import { translate } from '../../i18n';
import { getItemDetail as getItemDetailAction } from '../../redux/actions/getItemDetail';
import { RoleTypes } from '../../types/Common';
import showToast from '../../utils/showToast';
import { FirstLoveDialog } from './FirstLoveDialog';
import LoveElementContent from './LoveElementContent';
import { LovesOverLimit } from './LovesOverLimit';
import { LovesElementContentType } from './types';

interface PropsForWorkaround {
  getItemDetail: typeof getItemDetailAction;
  itemDetail: any;
}
interface Props extends PropsForWorkaround {
  activeFillColor?: string;
  deleteItemLoves: Function;
  fetchGuestTokenIfNeeded: Function;
  fillColor?: string;
  id: string | number;
  isCollected: boolean;
  isFetchingLoves: boolean;
  layoutName: string;
  loveElementContentType?: LovesElementContentType;
  marketID?: string;
  putItemLoves: Function;
  role: RoleTypes;
  size?: number;
  style?: StyleProp<ViewStyle>;
  wrapperStyle?: StyleProp<ViewStyle>;
  renderActiveIcon?: () => React.ReactElement;
  renderInactiveIcon?: () => React.ReactElement;
}

interface State {
  isIconFilled: boolean;
}

export default class LovesElement extends Component<Props, State> {
  static defaultProps = {
    isCollected: false,
  };

  AnimateIconLike = Animated.createAnimatedComponent(IconNewLike);

  state = {
    isIconFilled: this.props.isCollected,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.isFetchingLoves) {
      if (nextProps.isCollected !== prevState.isIconFilled) {
        return {
          isIconFilled: nextProps.isCollected,
        };
      }
    }

    return null;
  }

  static hasEverCollected = async () => {
    const [, value] = await safeAwait(AsyncStorage.getItem('hasBeenCollected'));

    return Boolean(value);
  };

  static displayTutorial = async () => {
    if (!isCypress) {
      globalNormalDialogProxy.open({
        positiveText: translate('lightboxLoading-error-positive'),
        children: <FirstLoveDialog />,
      });
    }

    const [error] = await safeAwait(
      AsyncStorage.setItem('hasBeenCollected', 'Truthy')
    );

    if (error) {
      return console.log(error);
    }
  };

  static handleAPISuccess = async (isCollectAction: boolean) => {
    const hasBeenCollected = await LovesElement.hasEverCollected();

    const message = translate(
      isCollectAction ? 'collection-successful' : 'collection-canceled'
    );

    if (isCollectAction) {
      if (hasBeenCollected) {
        showToast(message, 'PLATFORM');
      } else {
        LovesElement.displayTutorial();
      }
    } else {
      showToast(message, 'PLATFORM');
    }
  };

  static handleAPIFailure = (isCollectAction: boolean, errorResp) => {
    const errorCode = errorResp?.data.error_code;

    if (isCollectAction) {
      switch (errorCode) {
        case MemberErrors.ERR_MEMBER_LOVE_QUANTITY_EXCEEDED:
          return showToast('', 'PLATFORM', <LovesOverLimit />);
        case CommonErrors.ERR_NOT_FOUND:
          return showToast(translate('collection-offline'), 'PLATFORM');

        default:
          return showToast(translate('collection-failed'), 'PLATFORM');
      }
    } else {
      showToast(translate('collection-cancellation-failed'), 'PLATFORM');
    }
  };

  handleOnPress = (e?) => {
    e?.preventDefault();

    const { isIconFilled } = this.state;
    const {
      id,
      role,
      fetchGuestTokenIfNeeded,
      putItemLoves,
      deleteItemLoves,
      layoutName,
      marketID,
    } = this.props;
    const theApiFunc = isIconFilled ? deleteItemLoves : putItemLoves;
    const apiPayload = {
      elementType: ElementType.Item,
      id,
      layoutName,
      logEvent: isIconFilled ? '' : AnalyticsEvent.AddToWishList,
      marketID,
      success: async (isCollectAction: boolean) => {
        await LovesElement.handleAPISuccess(isCollectAction);
      },
      failure: (isCollectAction: boolean, errorResp) =>
        LovesElement.handleAPIFailure(isCollectAction, errorResp),
    };

    /**
     * 【警告】這是一個 workaround
     *
     * 原因：
     * - 現在列表不會給數據所需商品資料，導致使用者在列表收藏時數據無法取得分類資訊
     * - 數據所需商品資料：child_category_id, parent_category_id
     *
     * 解決辦法：
     * - 在呼叫 `putItemLoves` 前先打 `getItemDetail` 取得商品詳情
     * - 此方法會導致使用者收藏延遲
     *
     * 後續處理：
     * - 若將來列表 api 有提供分類資訊，我們需要 revert 這段 workaround
     * 1. 將 `callAction` 移除並以 `theApiFunc(apiPayload)` 替換
     * 2. 從 props 移除不需要的 `itemDetail`
     */
    const callAction = () => {
      const { parent_category_id, child_category_id } = this.props.itemDetail;
      const hasLogInfo =
        typeof parent_category_id !== 'undefined' &&
        typeof child_category_id !== 'undefined';

      // 如果 reducer 已有分類資訊，則不打 getItemDetail 以節省流量
      if (isIconFilled || hasLogInfo) {
        theApiFunc(apiPayload);
      } else {
        this.props.getItemDetail({
          id: `${id}`,
          success: item => {
            const childCatId = item?.child_category_id;
            const parentCatId = item?.parent_category_id;

            theApiFunc({
              ...apiPayload,
              childCatId,
              parentCatId,
            });
          },
          failure: () => theApiFunc(apiPayload),
          loadingType: 'new',
        });
      }
    };

    if (role === 'ghost') {
      fetchGuestTokenIfNeeded({
        success: () => callAction(),
      });
    } else {
      callAction();
    }

    this.setState(prevState => ({ isIconFilled: !prevState.isIconFilled }));
  };

  renderLikeFilledIcon = () => {
    const { activeFillColor, size, renderActiveIcon } = this.props;

    if (renderActiveIcon) {
      return renderActiveIcon();
    }

    return <IconNewLikeFilled size={size} fillActiveColor={activeFillColor} />;
  };

  renderUnfilledIcon = () => {
    const { size, fillColor, renderInactiveIcon } = this.props;

    if (renderInactiveIcon) {
      return renderInactiveIcon();
    }

    const AnimateIconLike = this.AnimateIconLike;

    return (
      <AnimateIconLike
        testID="animateIconLike"
        size={size}
        fillColor={fillColor}
      />
    );
  };

  renderLoveIcon = () => {
    const { isIconFilled } = this.state;

    if (isIconFilled) {
      return this.renderLikeFilledIcon();
    } else {
      return this.renderUnfilledIcon();
    }
  };

  selectLovesElementContent = () => {
    const { loveElementContentType, isCollected, style, wrapperStyle } =
      this.props;

    switch (loveElementContentType) {
      case LovesElementContentType.LoveCommentElement: {
        return (
          <LoveElementContent
            isCollected={isCollected}
            handleOnPress={this.handleOnPress}
          >
            <View>{this.renderLoveIcon()}</View>
          </LoveElementContent>
        );
      }
      default: {
        return (
          <View testID="lovesElement" style={wrapperStyle}>
            <Touchable
              animated
              testID={testIDs.LovesElement.Button}
              hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
              style={style}
              onPress={this.handleOnPress}
            >
              {this.renderLoveIcon()}
            </Touchable>
          </View>
        );
      }
    }
  };

  render() {
    return this.selectLovesElementContent();
  }
}
