import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getPromotionalThemesActions } from '../actions/getPromotionalThemesActions';
import { getPromotionalThemes } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getPromotionalThemesActions.success,
  failure: getPromotionalThemesActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getPromotionalThemesEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getPromotionalThemesActions.request)),
    switchMap(() => {
      return getPromotionalThemes()
        .then(response =>
          getPromotionalThemesActions.success({
            data: response.data.promotional_themes,
          })
        )
        .catch((error: AxiosError) =>
          getPromotionalThemesActions.failure({ error })
        );
    })
  );
};

export default getPromotionalThemesEpic;
