import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Data as LoveVendorsData } from '../../types/LoveVendorsResponse';

export interface LoveVendorsPayload {
  loadingType: ListLoadingTypes;
  page: number;
}

export interface LoveVendorsSuccessPayload {
  data: LoveVendorsData;
  loadingType: ListLoadingTypes;
}

export interface LoveVendorsFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getLoveVendorsActions.request>;
}

export const getLoveVendorsActions = createAsyncAction(
  'verybuy/GET_LOVE_VENDORS',
  'verybuy/GET_LOVE_VENDORS_SUCCESS',
  'verybuy/GET_LOVE_VENDORS_FAIL'
)<LoveVendorsPayload, LoveVendorsSuccessPayload, LoveVendorsFailPayload>();
