import { useCallback } from 'react';
import { FlatList } from 'react-native';

import { translate } from '../../../../../i18n';
import { makeCategorySelector } from '../../../../../redux/selectors/categorySelector';
import { useSelector } from '../../../../../redux/utils';
import { CategoriesPosition } from '../../../../../types/Categories';
import { S } from '../HotSearchSection/style';
import CategoryItem from './CategoryItem';

interface Props {
  closeSearchModal: () => void;
}

export default function HotCategories({ closeSearchModal }: Props) {
  const categories = useSelector(makeCategorySelector(CategoriesPosition.Home));

  const renderItem = useCallback(
    ({ item, index }) => (
      <CategoryItem
        afterNavigate={closeSearchModal}
        item={item}
        layoutName="search_category"
        pitNumber={index + 1}
      />
    ),
    [closeSearchModal]
  );

  if (categories.length === 0) {
    return null;
  }

  return (
    <>
      <S.TitleContainer>
        <S.Title>{translate('hot-categories')}</S.Title>
      </S.TitleContainer>
      <FlatList
        data={categories.slice(0, 8)}
        numColumns={2}
        renderItem={renderItem}
        keyboardShouldPersistTaps="handled"
      />
    </>
  );
}
