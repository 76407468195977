import AES from 'crypto-js/aes';

import Config from './configs';

export const dynamicLinkToCopy = dynamicLinkURL => {
  if (!dynamicLinkURL) {
    return null;
  }

  const currentURL = `${Config.REACT_APP_WEB_VIEW_URL}${window.location.pathname}?utm_source%3Dcode%26utm_medium%3Dweb`;
  const copyURL = `${Config.REACT_APP_DYNAMICLINK_PREFIX}/?link=${currentURL}&apn=tw.com.verybuy&isi=572399530&ibi=com.verybuy.verybuy&_osl=${dynamicLinkURL}&_icp=1`;

  return copyURL;
};

const ALTG_SECRET_KEY = 'altg20220418';

export const encryptAltGhostID = (ghostID: string) => {
  const encryptGhostID = encodeURIComponent(
    AES.encrypt(ghostID, ALTG_SECRET_KEY).toString()
  );

  return encryptGhostID;
};

export const decryptAltGhostID = (ghostID: string) => {
  return ghostID;
};

export const withAltGhostID = (url: string, ghostID: string) => {
  if (!url) {
    return null;
  }

  if (ghostID) {
    const encryptedGhostID = encryptAltGhostID(ghostID);

    return `${url}&altg=${encryptedGhostID}`;
  }

  return url;
};
