import styled from 'styled-components/native';

export const S = {
  Wrapper: styled.View<{ color: string }>`
    border: 1px solid ${({ color }) => color};
    color: ${({ color }) => color};
    border-radius: 3px;
    padding: 1px 4px;
    align-self: flex-start;
    justify-content: center;
  `,
  Text: styled.Text<{ color: string }>`
    font-size: 11px;
    letter-spacing: 0px;
    line-height: 14px;
    color: ${({ color }) => color};
  `,
};
