import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';

import { RootState } from '../redux/rootReducer';

/**
 * 因安裝 @types/react-redux 會造成多處 type 壞掉，故只好直接拿需要的 type 來用。
 * from: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/0b007c8/types/react-redux/index.d.ts#L590
 */
interface TypedUseSelectorHook<TState> {
  <TSelected>(
    selector: (state: TState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
  ): TSelected;
}

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
// TODO: 補 type，參考: https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
export const useDispatch = () => useReduxDispatch();
