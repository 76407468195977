import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { CategoryInfo } from '../../types/LandingPageList';
import { convertArrayToObject } from '../../utils/arrayHandler';
import { getLandingPageListActions } from '../actions/getLandingPageListActions';

// FIXME: 之後 object key 會以 ID 為主，到時調整 naming
type CategoryName = string;

export interface State {
  isFetching: boolean;
  data: Record<CategoryName, CategoryInfo>;
}

export const defaultState: State = {
  isFetching: false,
  data: {},
};

type Action = ActionType<
  | typeof getLandingPageListActions.request
  | typeof getLandingPageListActions.success
  | typeof getLandingPageListActions.failure
>;

export default produce((draft: State, action: Action) => {
  switch (action.type) {
    case getType(getLandingPageListActions.request): {
      draft.isFetching = true;
      break;
    }
    case getType(getLandingPageListActions.success): {
      const { data } = action.payload;

      draft.isFetching = false;
      draft.data = convertArrayToObject(Object.values(data), 'name');
      break;
    }
    case getType(getLandingPageListActions.failure): {
      draft.isFetching = false;
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
