import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { RecommendList } from '../../types/RecommendListResponse';
import { getRecommendLandingListActions } from '../actions/getRecommendLandingListActions';
import { getRecommendList } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<
  typeof getRecommendLandingListActions.request
>;
const outputActions = {
  success: getRecommendLandingListActions.success,
  failure: getRecommendLandingListActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export default (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getRecommendLandingListActions.request)),
    mergeMap((action: RequestActionTypes) => {
      const { page, id, per_page, success, dispatch } = action.payload;

      dispatch(action);

      return getRecommendList({ page, id, per_page })
        .then((response: AxiosResponse<RecommendList>) => {
          // filter not auto quote items
          const products = response.data?.products || [];

          success(products);

          const responseAction = getRecommendLandingListActions.success({
            id,
            items: products,
            logEvent: AnalyticsEvent.RecommendList,
            page,
          });

          dispatch(responseAction);

          return responseAction;
        })
        .catch((error: AxiosError) => {
          const responseAction = getRecommendLandingListActions.failure({
            id,
            error,
            requestAction: action,
          });

          dispatch(responseAction);

          return responseAction;
        });
    })
  );
};
