import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { DeliveryItem } from '../../types/DeliveryData';
import { actionsList } from '../rootAction';

const { loadDeliveryData, updateDeliveryData, clearData } = actionsList;

export interface State {
  isLoading: boolean;
  data: DeliveryItem[];
  hasError: boolean;
  error: any | null;
}

export const defaultState = {
  isLoading: true,
  data: [] as DeliveryItem[],
  hasError: false,
  error: null,
};

type Actions =
  | typeof loadDeliveryData.request
  | typeof loadDeliveryData.success
  | typeof loadDeliveryData.failure
  | typeof updateDeliveryData.success
  | typeof updateDeliveryData.failure
  | typeof clearData;

export default produce((draft: State, action: ActionType<Actions>) => {
  switch (action.type) {
    case getType(loadDeliveryData.request): {
      draft.isLoading = true;
      break;
    }
    case getType(loadDeliveryData.success): {
      draft.isLoading = false;
      draft.hasError = false;
      draft.error = null;
      draft.data = action.payload.data.home_list;
      break;
    }
    case getType(updateDeliveryData.failure):
    case getType(loadDeliveryData.failure): {
      draft.isLoading = false;
      draft.hasError = true;
      draft.error = action.payload.error;
      break;
    }
    case getType(updateDeliveryData.success): {
      const newData = draft.data
        ? [action.payload.data, ...draft.data.slice(0)]
        : [action.payload.data];

      draft.isLoading = false;
      draft.hasError = false;
      draft.error = null;
      draft.data = newData.length > 10 ? newData.slice(0, 10) : newData;
      break;
    }
    case getType(clearData): {
      draft.data = [];
      break;
    }
    default:
      break;
  }
}, defaultState);
