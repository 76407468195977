import { createAsyncAction } from 'typesafe-actions';

import { ZipItem } from '../../types/ZipCode';

export interface GetZipCodePayload {
  countryCode: string;
}

export interface GetZipCodeSuccessPayload {
  countryCode: string;
  data: ZipItem[];
}

export interface GetZipCodeFailPayload {
  error: Error;
}

export const getZipCode = createAsyncAction(
  'verybuy/GET_ZIP_CODE',
  'verybuy/GET_ZIP_CODE_SUCCESS',
  'verybuy/GET_ZIP_CODE_FAIL'
)<GetZipCodePayload, GetZipCodeSuccessPayload, GetZipCodeFailPayload>();
