import styled from 'styled-components/native';

import Image from '../../../elements/Image';

const R18Hint = ({ size }) => {
  return (
    <S.Image source={require('./assets/img_adult_watermark.png')} size={size} />
  );
};

const S = {
  Image: styled(Image)<{ size: number }>`
    max-width: ${({ size }) => size || 0}px;
    max-height: ${({ size }) => size || 0}px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 100%;
  `,
};

export default R18Hint;
