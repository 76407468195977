import { getType } from 'typesafe-actions';

import { LightBox } from '../../types/LightBox';
import { actionsList } from '../rootAction';

const { getLightBoxActions } = actionsList;

export interface State {
  isLoading: boolean;
  data: LightBox;
}

const initialState: State = { data: {}, isLoading: false };

export default function (state = initialState, action) {
  switch (action.type) {
    case getType(getLightBoxActions.request): {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getType(getLightBoxActions.success): {
      const { data } = action.payload || {};

      return {
        ...state,
        isLoading: false,
        data,
      };
    }
    case getType(getLightBoxActions.failure): {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
