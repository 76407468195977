import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import md5 from 'md5';
import safeAwait from 'safe-await';

import { isWebPlatform } from '../../../boot/utils';
import { AnalyticsEvent } from '../../../constants/AnalyticsEvent';
import { IterableEvents } from '../../../constants/IterableEvents';
import { isBot } from '../../../constants/misc';
import { makeMemberInfo } from '../../../redux/selectors/member';
import Config from '../../configs';
import getLogPayload from '../firebase/getLogPayloadForFirebase';
import { transformToStandardEvent } from '../transformToStandardEvent';
import { findValidEmail, getIterableUpdateCartPayload } from './utils';

declare global {
  interface Window {
    _iaq: any;
  }
}

const importScript = async ({
  src,
  id,
  isAsync,
}: {
  src: string;
  id?: string;
  isAsync?: boolean;
}) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    const firstScript = document.getElementsByTagName('script')[0];

    script.type = 'text/javascript';
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    if (isAsync) {
      script.async = isAsync;
    }
    if (id) {
      script.setAttribute('id', id);
    }
    if (firstScript && firstScript.parentNode) {
      firstScript.parentNode.insertBefore(script, firstScript);
    }
  });
};

// iterable web 沒有和 app 類似的處理流程
export const migrateUserEmail = (_email, _shouldMigrate) => {
  return undefined;
};

export const initIterable = async () => {
  if (isWebPlatform) {
    if (!window._iaq) {
      // initial iterable service.
      return Promise.all([
        importScript({
          src: 'https://js.iterable.com/analytics.js',
        }),
        importScript({
          src: 'https://js.iterable.com/iterableUtils.js',
        }),
      ]).then(() => {
        window._iaq.push(['account', Config.ITERABLE_SERVICE_ID]);
      });
    }
  }

  return Promise.resolve();
};

export const sendEvents = ({ logEvent, logData, currentStore }) => {
  if (isBot) return;

  const {
    MID,
    nickname,
    email: memberEmail,
    line_id,
    subscribe_edm,
    account: memberAccount,
  } = makeMemberInfo()(currentStore);

  // Because SignIn / LogIn event will cause reducer data race condition so that get data from logData
  const email = findValidEmail(
    memberEmail,
    memberAccount,
    logData.account,
    logData.email
  );

  if (!email) return;
  // filter event which not in the white list
  if (
    Object.values(IterableEvents).indexOf(
      transformToStandardEvent(IterableEvents)[logEvent]
    ) < 0
  )
    return;

  // 根據 document, updateCart 並不是一個 "event“, 如果當成 event直接送的話會更新不到 user_profile
  if (logEvent !== AnalyticsEvent.UpdateCart) {
    window._iaq.push([
      'identify',
      email,
      { MID, nickname, email, line_id, subscribe_edm },
    ]);
    const logPayload = getLogPayload(logEvent, logData, currentStore);

    window._iaq.push([
      'track',
      transformToStandardEvent(IterableEvents)[logEvent],
      logPayload,
    ]);
  } else {
    // firebase/ga 不會有 update_cart events
    const cartContents = getIterableUpdateCartPayload(logData);

    window._iaq.push([
      'identify',
      email,
      { MID, email, line_id, nickname, subscribe_edm, ...cartContents },
    ]);

    // 從文件上看不出 updateCart 和 identify 的差異, 分成兩個 request 感覺蠻奇怪的 就先使用 identify
    // _iaq.UpdateCart(cartContents.shoppingCartItems);
  }
};

export function sendIterableEvents({ logEvent, logData, currentStore }) {
  initIterable()
    .then(() => sendEvents({ logEvent, logData, currentStore }))
    .catch(err => console.error(err));
}

export function registerBrowserToken({ browserToken, email, userId }) {
  const iterableAxios = axios.create();

  iterableAxios.post(
    'https://api.iterable.com/api/users/registerBrowserToken',
    {
      email,
      browserToken,
      userId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Api-Key': Config.REACT_APP_ITERABLE_JS_SDK_API_KEY,
      },
    }
  );
}

const ITERABLE_ENCRYPT_KEY = 'i-am-groot';
const getItemKey = () => md5('iterableBrowserToken');

// save browser token to async storage
export async function saveBrowserToken(token) {
  const itemValue = AES.encrypt(token, ITERABLE_ENCRYPT_KEY).toString();

  await safeAwait(AsyncStorage.setItem(getItemKey(), itemValue));
}

let _checkBrowserTokenAndRegisterPromise;

// check if browserToken is exist in async storage
// if exist, registerBrowserToken to iterable service
// then remove browserToken from async storage
async function _checkBrowserTokenAndRegister({ email, userId }) {
  const [, itemValue] = await safeAwait(AsyncStorage.getItem(getItemKey()));

  if (!itemValue) return;

  const token = AES.decrypt(itemValue, ITERABLE_ENCRYPT_KEY).toString(Utf8);

  registerBrowserToken({ browserToken: token, email, userId });

  await safeAwait(AsyncStorage.removeItem(getItemKey()));
}

export async function checkBrowserTokenAndRegister({ email, userId }) {
  if (!_checkBrowserTokenAndRegisterPromise) {
    _checkBrowserTokenAndRegisterPromise = _checkBrowserTokenAndRegister({
      email,
      userId,
    });
  }
}
