import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { CountryInfo } from '../../types/Countries';
import { Code, CurrencySymbol } from '../../types/Currency';
import { VBConfigData } from '../../types/VBConfigResponse';
// FIXME: import actions from '../rootActions'
import { getCountriesActions } from '../actions/getCountriesActions';
import { getDefaultShippingFeesActions } from '../actions/getDefaultShippingFeesActions';
import { getVBConfigActions } from '../actions/getVBConfigActions';
import { updateRemoteValuesAction } from '../actions/updateRemoteValuesAction';

type CountriesState = {
  data: {
    [country: string]: CountryInfo & { name: string };
  };
  isFetching: boolean;
};

interface State {
  isFetching: boolean;
  hasError: boolean;
  data: VBConfigData;
  countries: CountriesState;
  remoteValues: { [key: string]: boolean };
  error?: Error | null;
}

type Actions =
  | typeof getVBConfigActions.request
  | typeof getVBConfigActions.success
  | typeof getVBConfigActions.failure
  | typeof getCountriesActions.request
  | typeof getCountriesActions.success
  | typeof getCountriesActions.failure
  | typeof getDefaultShippingFeesActions.request
  | typeof getDefaultShippingFeesActions.success
  | typeof getDefaultShippingFeesActions.failure
  | typeof updateRemoteValuesAction;

export const defaultState: State = {
  isFetching: false,
  hasError: false,
  data: {
    delivery_methods: [],
    payment_methods: [],
    default_shipping_fees: [],
    currencies: [
      {
        symbol: CurrencySymbol.NT,
        code: Code.TWD,
      },
    ],
  },
  remoteValues: {},
  countries: {
    data: {},
    isFetching: false,
  },
};

export default produce((draft: State, action: ActionType<Actions>) => {
  switch (action.type) {
    case getType(getVBConfigActions.request): {
      draft.isFetching = true;
      break;
    }
    case getType(getVBConfigActions.success): {
      draft.isFetching = false;
      draft.hasError = false;
      draft.error = null;
      draft.data = action.payload.data;
      break;
    }
    case getType(getVBConfigActions.failure): {
      draft.isFetching = false;
      draft.hasError = true;
      draft.error = action.payload.error;
      break;
    }
    case getType(getCountriesActions.request): {
      draft.countries.isFetching = true;
      break;
    }
    case getType(getCountriesActions.success): {
      const { data } = action.payload;
      const formattedData = Object.entries(data).reduce<CountriesState['data']>(
        (acc, [name, country]) => ({
          ...acc,
          [country.code]: { name, ...country },
        }),
        {}
      );

      draft.countries.data = formattedData;
      draft.countries.isFetching = false;
      break;
    }
    case getType(getCountriesActions.failure): {
      draft.countries.isFetching = false;
      break;
    }
    case getType(getDefaultShippingFeesActions.request): {
      draft.isFetching = true;
      break;
    }
    case getType(getDefaultShippingFeesActions.success): {
      const { data } = action.payload;

      draft.isFetching = false;
      draft.data.default_shipping_fees = data.shipping_fees;
      break;
    }
    case getType(getDefaultShippingFeesActions.failure): {
      draft.isFetching = false;
      break;
    }
    case getType(updateRemoteValuesAction): {
      draft.remoteValues = {
        ...draft.remoteValues,
        ...action.payload,
      };
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
