import { createStandardAction } from 'typesafe-actions';

export interface MemberNotificationsPayload {
  notifications: number;
}

export const updateMemberNotifications = createStandardAction(
  'verybuy/UPDATE_MEMBER_NOTIFICATIONS'
)<MemberNotificationsPayload>();

export const setViewedHome = createStandardAction(
  'verybuy/SET_VIEWED_HOME'
)<void>();

export const updateMemberInfoAction = createStandardAction(
  'verybuy/UPDATE_MEMBER_INFO'
)<{ data: Record<string, any> }>();
