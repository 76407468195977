import { useCallback, useMemo, useRef } from 'react';
import { RefreshControl } from 'react-native';

import { useOrientation } from '../../boot/OrientationContext';
import {
  GET_LAYOUT_MAX_WIDTH,
  GET_LIST_ITEM_NUM,
} from '../../constants/layout';
import { checkItemViewed } from '../../context/devLogFirehose';
import FlatListWithImpression, {
  FlatListWithImpressionType,
} from '../FlatListWithImpression';
import { itemProducer } from '../FlatListWithImpression/utils';
import ListItem from '../ListItem';
import { useData } from './hooks';
import { LIST_CONTAINER_PADDING, S } from './styles';

const keyExtractor = item => `${item}`;
const layoutName = 'landingpage_rated_item';
const ITEM_MARGIN = 5;
const numColumns = GET_LIST_ITEM_NUM();

const TopRatedListPage = () => {
  const impressionList = useRef<FlatListWithImpressionType>(null);

  useOrientation();

  const CONTAINER_WIDTH = GET_LAYOUT_MAX_WIDTH() - LIST_CONTAINER_PADDING * 2;
  const ITEM_WIDTH = CONTAINER_WIDTH / GET_LIST_ITEM_NUM();

  const {
    loadingType,
    hasMoreItems,
    fetchData,
    page,
    data,
    sources,
    isFetching,
  } = useData();

  const onRefresh = useCallback(() => {
    const _impressionList = impressionList.current;

    if (
      typeof _impressionList?.clearImpressionAccumulatedItems === 'function'
    ) {
      _impressionList.clearImpressionAccumulatedItems();
    }

    fetchData({ loadingType: 'reload' });
  }, [fetchData]);

  const refreshControl = useMemo(() => {
    return (
      <RefreshControl
        testID="topRatedRefreshControl"
        onRefresh={onRefresh}
        refreshing={isFetching && loadingType === 'reload'}
      />
    );
  }, [isFetching, loadingType, onRefresh]);

  const onEndReached = useCallback(() => {
    if (hasMoreItems && !isFetching) {
      const nextPage = page + 1;

      fetchData({
        page: nextPage,
        loadingType: 'loadMore',
      });
    }
  }, [hasMoreItems, isFetching, page, fetchData]);

  const renderItem = useCallback(
    ({ item, index }: { item: string; index: number }) => {
      return (
        <ListItem
          index={index}
          key={item}
          id={item}
          showLoading={`${item}`.includes('blank')}
          itemWidth={ITEM_WIDTH}
          itemMargin={ITEM_MARGIN}
          layoutName={layoutName}
          source={sources.get(item)}
          shouldDisplayImpressionForDebug={checkItemViewed(
            impressionList.current?.accumulatedItems,
            item
          )}
          linesOfTitle={1}
          showSKUTag
          // getTopRatedList API 不支援回傳收藏狀態，但仍因應需求顯示收藏按鈕，因此收藏行為可能與預期有出入
          showLovesElement
          showRating
        />
      );
    },
    [ITEM_WIDTH, sources]
  );

  if (!data?.length) {
    return null;
  }

  return (
    <S.Container>
      <FlatListWithImpression
        ref={impressionList}
        testID="topRatedListPage"
        data={data}
        dataProducer={itemProducer}
        numColumns={numColumns}
        key={numColumns}
        renderItem={renderItem}
        onEndReached={onEndReached}
        layoutName={layoutName}
        keyExtractor={keyExtractor}
        sources={sources}
        refreshControl={refreshControl}
      />
    </S.Container>
  );
};

export default TopRatedListPage;
