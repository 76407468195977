import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getSupportMessagesActions } from '../actions/getSupportMessagesActions';
import { getSupportMessages } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getSupportMessagesActions.success,
  failure: getSupportMessagesActions.failure,
};

type RequestActionType = ActionType<typeof getSupportMessagesActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getSupportMessagesEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getSupportMessagesActions.request)),
    switchMap((action: RequestActionType) => {
      const { id, page, loadingType, perPage } = action.payload;
      const apiArgs = { page, per_page: perPage };

      return getSupportMessages(id, apiArgs)
        .then(response =>
          getSupportMessagesActions.success({
            id,
            data: response.data.messages,
            page,
            perPage,
            loadingType,
          })
        )
        .catch((error: AxiosError) =>
          getSupportMessagesActions.failure({ id, error })
        );
    })
  );
};

export default getSupportMessagesEpic;
