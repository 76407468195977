import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { setUserInfo } from '../../boot/utils';
import { MemberInfoResponse } from '../../types/MemberInfo';
import { getMemberInfo } from '../api';
import { RootAction, actionsList } from '../rootAction';
import { RootState } from '../rootReducer';

const {
  getMemberInfoActions,
  loginByApple,
  loginByFacebook,
  loginByLine,
  loginByEmail,
  postRegisterActions,
} = actionsList;

const outputActions = {
  success: getMemberInfoActions.success,
  failure: getMemberInfoActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getMemberInfoEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(getMemberInfoActions.request),
      getType(loginByApple.success),
      getType(loginByFacebook.success),
      getType(loginByEmail.success),
      getType(loginByLine.success),
      getType(postRegisterActions.success)
    ),
    switchMap(() => {
      return getMemberInfo()
        .then((response: MemberInfoResponse) => {
          setUserInfo(response.data);

          return getMemberInfoActions.success({ data: response.data });
        })
        .catch((error: AxiosError) =>
          getMemberInfoActions.failure({ error, customErrorHandling: true })
        );
    })
  );
};

export default getMemberInfoEpic;
