import { AxiosError } from 'axios';
import pick from 'lodash/pick';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { postForgetPasswordActions } from '../actions/postForgetPasswordActions';
import { postForgetPassword } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: postForgetPasswordActions.success,
  failure: postForgetPasswordActions.failure,
};

type RequestActionType = ActionType<typeof postForgetPasswordActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const postForgetPasswordEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(postForgetPasswordActions.request)),
    switchMap((action: RequestActionType) => {
      const { onSuccess, onFailure } = action.payload;
      const apiPayload = pick(action.payload, ['recaptcha_token', 'email']);

      return postForgetPassword(apiPayload)
        .then(() => {
          onSuccess();

          return postForgetPasswordActions.success({});
        })
        .catch((error: AxiosError<void>) => {
          onFailure(error.response);

          return postForgetPasswordActions.failure({ error });
        });
    })
  );
};

export default postForgetPasswordEpic;
