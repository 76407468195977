export {};
export default {
  get isSupported() {
    // ref: https://stackoverflow.com/a/9039885/9636125
    const iOSList = ['iPad Simulator', 'iPhone Simulator', 'iPad', 'iPhone'];
    const otherDetect =
      /Mac/.test(window.navigator.userAgent) && 'ontouchend' in document;

    return iOSList.includes(window.navigator.platform) || otherDetect;
  },
};
