import { createAsyncAction } from 'typesafe-actions';

import { VBConfigData } from '../../types/VBConfigResponse';

export interface VBConfigPayload {}

export interface VBConfigSuccessPayload {
  data: VBConfigData;
}

export interface VBConfigFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getVBConfigActions.request>;
}

export const getVBConfigActions = createAsyncAction(
  'verybuy/GET_VB_CONFIG',
  'verybuy/GET_VB_CONFIG_SUCCESS',
  'verybuy/GET_VB_CONFIG_FAIL'
)<VBConfigPayload, VBConfigSuccessPayload, VBConfigFailPayload>();
