import { FC, useCallback, useRef } from 'react';
import { Animated, Share, StyleProp, ViewStyle } from 'react-native';
import safeAwait from 'safe-await';

import IconShare from '../../assets/svg/IconShare';
import { isWebPlatform } from '../../boot/utils';
import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import useActions from '../../hooks/useActions';
import { logAnalyticsEvent } from '../../redux/actions/analyticsLog';
import Touchable from '../Touchable';
import { S } from './styles';
import { getEventData } from './utils';

const AnimateIconShare = Animated.createAnimatedComponent(IconShare);

interface Props {
  shareMessage: string;
  iconWrapperStyle?:
    | StyleProp<ViewStyle>
    | Animated.WithAnimatedObject<ViewStyle>;
  iconFillColor?: Animated.AnimatedInterpolation | string;
  logEventData?: Record<string, string>;
}

const canShare = (): boolean => {
  if (isWebPlatform) {
    const { share } = window.navigator;

    if (!share) return false;

    return true;
  }

  return true;
};

const ShareBtn: FC<Props> = props => {
  const logEvent = useActions(logAnalyticsEvent);
  const { iconWrapperStyle, iconFillColor, shareMessage, logEventData } = props;
  const hitSlop = useRef({ top: 10, right: 10, bottom: 10, left: 10 });

  const onShare = useCallback(() => {
    const _share = async () => {
      const basicEventData = {
        ...logEventData,
      };

      logEvent({
        logEvent: AnalyticsEvent.ShareClick,
        data: basicEventData,
      });

      const [error, data] = await safeAwait(
        Share.share({ message: shareMessage })
      );

      logEvent({
        logEvent: AnalyticsEvent.ShareResult,
        data: getEventData(basicEventData, { error, data }),
      });
    };

    _share();
  }, [logEvent, logEventData, shareMessage]);

  if (!canShare()) {
    return null;
  }

  return (
    <Touchable
      accessibilityRole="button"
      onPress={onShare}
      hitSlop={hitSlop.current}
      testID="shareBtn"
    >
      <S.IconWrapper style={iconWrapperStyle}>
        <AnimateIconShare size={22} fillColor={iconFillColor} />
      </S.IconWrapper>
    </Touchable>
  );
};

ShareBtn.defaultProps = {
  logEventData: {},
};

export default ShareBtn;
