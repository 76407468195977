export interface TopKeywordsResponse {
  data: TopKeywordsData;
}

export interface TopKeywordsData {
  top_keywords: TopKeywords[];
}

export interface TopKeywords {
  title: string;
  display_name: string;
  url: string;
  landing_page: string;
  style: string;
}

export enum TopKeywordsStyle {
  FIRE = 'fire',
  NONE = 'none',
}

export enum LandingPageType {
  SEARCH = 'search',
  BESTSELLER = 'best_seller',
}
