import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { PaymentData } from '../../types/Payment';
import ReceiptData from '../../types/ReceiptData';
import { PaymentMethodsInfo } from '../../types/Subtotal';
import { clearData } from '../actions/clearDataActions';
import { getPayment } from '../actions/getPaymentActions';
import { getZipCode } from '../actions/getZipCodeActions';
import { loadDeliveryData } from '../actions/loadDeliveryDataActions';
import { putSubtotalActions } from '../actions/putSubtotalActions';
import resetTransaction from '../actions/resetTransaction';
import { updateCVSReceivingData } from '../actions/updateCVSReceivingDataActions';
import { updateDeliveryData } from '../actions/updateDeliveryDataActions';
import updateReceiptData from '../actions/updateReceiptDataAction';
import cvsReducer, {
  defaultState as cvsReducerDefaultState,
  State as cvsReducerState,
} from './cvsReducer';
import deliveryReducer, {
  defaultState as deliveryReducerDefaultState,
  State as deliveryReducerState,
} from './deliveryReducer';
import zipCodeReducer, {
  defaultState as zipCodeReducerDefaultState,
  State as zipCodeReducerState,
} from './zipCodeReducer';

interface State {
  deliveryData: deliveryReducerState;
  cvsList: cvsReducerState;
  zipData: zipCodeReducerState;
  receiptData: ReceiptData;
  hasError: boolean;
  isLoading: boolean;
  paymentMethods: PaymentData;
  errorMessage: Error | null;
}

const defaultState: State = {
  deliveryData: deliveryReducerDefaultState,
  cvsList: cvsReducerDefaultState,
  zipData: zipCodeReducerDefaultState,
  receiptData: {
    buyer_identifier: '',
    buyer_name: '',
  },
  hasError: false,
  isLoading: true,
  paymentMethods: {},
  errorMessage: null,
};

type Actions =
  | typeof loadDeliveryData.request
  | typeof loadDeliveryData.success
  | typeof loadDeliveryData.failure
  | typeof updateDeliveryData.request
  | typeof updateDeliveryData.success
  | typeof updateDeliveryData.failure
  | typeof updateCVSReceivingData.request
  | typeof updateCVSReceivingData.success
  | typeof updateCVSReceivingData.failure
  | typeof getZipCode.request
  | typeof getZipCode.success
  | typeof getZipCode.failure
  | typeof getPayment.success
  | typeof getPayment.failure
  | typeof putSubtotalActions.success
  | typeof updateReceiptData
  | typeof resetTransaction
  | typeof clearData;

export default produce((draft: State, action: ActionType<Actions>) => {
  switch (action.type) {
    case getType(loadDeliveryData.request):
    case getType(loadDeliveryData.success):
    case getType(loadDeliveryData.failure): {
      draft.deliveryData = deliveryReducer(draft.deliveryData, action);
      draft.cvsList = cvsReducer(draft.cvsList, action);
      break;
    }
    case getType(updateDeliveryData.success):
    case getType(updateDeliveryData.failure): {
      draft.deliveryData = deliveryReducer(draft.deliveryData, action);
      break;
    }
    case getType(updateCVSReceivingData.success):
    case getType(updateCVSReceivingData.failure): {
      draft.cvsList = cvsReducer(draft.cvsList, action);
      break;
    }
    case getType(getZipCode.request):
    case getType(getZipCode.success):
    case getType(getZipCode.failure): {
      draft.zipData = zipCodeReducer(draft.zipData, action);
      break;
    }
    case getType(getPayment.success): {
      const paymentData: PaymentData = action.payload.data;

      draft.paymentMethods = paymentData;
      draft.isLoading = false;
      draft.hasError = false;
      break;
    }
    case getType(getPayment.failure): {
      console.log('fail to get paymentMethod data', action.payload.error);

      draft.errorMessage = action.payload.error;
      draft.hasError = true;
      draft.isLoading = false;
      break;
    }
    case getType(putSubtotalActions.success): {
      const paymentMethodInfo: PaymentMethodsInfo =
        action.payload.data.payment_method_info;

      Object.keys(paymentMethodInfo).forEach(payBy => {
        draft.paymentMethods[payBy] = {
          ...draft.paymentMethods[payBy],
          ...paymentMethodInfo[payBy],
        };
      });
      break;
    }
    case getType(updateReceiptData): {
      draft.receiptData = {
        buyer_identifier: action.payload?.buyer_identifier || '',
        buyer_name: action.payload?.buyer_name || '',
      };
      break;
    }
    case getType(resetTransaction): {
      draft.receiptData = {
        buyer_identifier: '',
        buyer_name: '',
      };
      break;
    }
    case getType(clearData): {
      draft.paymentMethods = {};
      break;
    }
    default:
      break;
  }
}, defaultState);
