let store;
let assigned = false;

export const assignStore = obj => {
  // only assignStore once
  if (!assigned) {
    store = obj;
    assigned = true;
  }
};

export const DANGER_GET_STORE_BY = key => {
  if (!store) {
    return null;
  }
  const state = store.getState();
  const theValue = state[key];

  if (!theValue) {
    return null;
  }

  // return a copy of data
  return Object.assign({}, theValue);
};
