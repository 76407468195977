import { ActionType, getType } from 'typesafe-actions';

import formatSKUData from '../../utils/apiFormatters';
import { getItemContentData } from '../actions/itemContentActions';

export default function (
  state: { SKU: object } = {
    SKU: {},
  },
  action: ActionType<typeof getItemContentData>
) {
  switch (action.type) {
    case getType(getItemContentData.request): {
      const { id } = action.payload;

      return {
        ...state,
        SKU: {
          ...state.SKU,
          [`${id}`]: {
            meta: state.SKU[`${id}`],
            isLoading: true,
          },
        },
      };
    }
    case getType(getItemContentData.success): {
      const { data, id } = action.payload;
      const itemData = formatSKUData(data);

      return {
        ...state,
        SKU: {
          ...state.SKU,
          [`${id}`]: { meta: { ...itemData }, isLoading: false },
        },
      };
    }

    case getType(getItemContentData.failure): {
      const { id, error } = action.payload;

      console.log('fail to get item content data', error);

      return {
        ...state,
        SKU: {
          ...state.SKU,
          [`${id}`]: {
            meta: { error },
            isLoading: false,
            hasError: true,
          },
        },
      };
    }

    default:
      return state;
  }
}
