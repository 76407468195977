import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { Platform } from 'react-native';

import { isApp } from '../../boot/utils';
import { isBot } from '../../constants/misc';
import { SortBy } from '../../constants/sortData';
import { createFormData } from '../../elements/UploadImage/utils';
import { Announcements, AnnouncementsData } from '../../types/Announcement';
import { ApplePayPrimeResponse } from '../../types/ApplePayPrimeResponse';
import { Banners } from '../../types/Banners';
import { CartContent } from '../../types/CartContent';
import { CategoriesData } from '../../types/Categories';
import { Data as CategoryListData } from '../../types/CategoryListResponse';
import { Data as CatsData } from '../../types/CatsResponse';
import { MailType, NotificationGroup } from '../../types/Common';
import { CookieData } from '../../types/Cookie';
import { CountriesData } from '../../types/Countries';
import { CVSReceivingData, DeliveryData } from '../../types/DeliveryData';
import { Data as EventCouponData } from '../../types/EventCoupon';
import { Data as EventPageCoverData } from '../../types/EventPageCoverResponse';
import { DataFromApi } from '../../types/FilterConfigResponse';
import { RequestPayload as ForgetPasswordRequestPayload } from '../../types/ForgetPassword';
import { Data as FreeDeliveryData } from '../../types/FreeDeliveryResponse';
import { GuestToken } from '../../types/GuestToken';
import { Data as LabelItemsData } from '../../types/LabelItemsResponse';
import { LandingPageListData } from '../../types/LandingPageList';
import { Data as LoveVendorsData } from '../../types/LoveVendorsResponse';
import { Data as MailboxListData } from '../../types/MailboxListResponse';
import MarketingEventsForResponse from '../../types/MarketingEventsForResponse';
import { GetMarketingLandingPageRequest } from '../../types/MarketingLandingPageResponse';
import {
  MemberLoginByAppleResponse,
  MemberLoginByEmailResponse,
  MemberLoginByFacebookResponse,
  MemberLoginByLineResponse,
  MemberRegisterByEmailResponse,
} from '../../types/Member';
import { MemberDeletePayload } from '../../types/MemberDeleteResponse';
import { PatchMemberData } from '../../types/MemberInfo';
import { MemberProfileData } from '../../types/MemberProfileResponse';
import { Data as MemberVerifyData } from '../../types/MemberVerify';
import {
  MemberVerifyTokenData,
  RequestPayload as MemberVerifyTokenRequestPayload,
} from '../../types/MemberVerifyToken';
import { NavigationParams } from '../../types/NavigationParams';
import { NewArrivalResponse } from '../../types/NewArrivalResponse';
import { NotificationsData } from '../../types/Notifications';
import { OnboardingResponse } from '../../types/OnboardingResponse';
import { OnSaleResponse } from '../../types/OnSaleResponse';
import { PaymentData } from '../../types/Payment';
import { Data as PeriodTaskData } from '../../types/PeriodTaskResponse';
import { Data as ProductReviewDetailData } from '../../types/ProductReviewDetailResponse';
import { Data as ProductReviewListDataData } from '../../types/ProductReviewListDataResponse';
import { Data as ProductTagsData } from '../../types/ProductTagsResponse';
import { Data as PromotionalThemesData } from '../../types/PromotionalThemesResponse';
import { Data as QueryItemsData } from '../../types/QueryItemsResponse';
import { RecommendList } from '../../types/RecommendListResponse';
import { RecommendProductsResponse } from '../../types/RecommendProductsResponse';
import { SearchRecommend } from '../../types/SearchRecommend';
import { SearchRequest, SearchUserSubmit } from '../../types/SearchUserSubmit';
import { Data as SendVerificationCodeData } from '../../types/SendVerificationCodeResponse';
import {
  PreviewTransactionsData,
  ShippingLogisticsResponse,
} from '../../types/ShippingLogistics';
import { SubmitBillData } from '../../types/SubmitBill';
import { SubtotalRequest } from '../../types/Subtotal';
import {
  Data as SupportDetailData,
  SupportMessagesData,
} from '../../types/SupportDetailResponse';
import { SupportsAddPayload } from '../../types/SupportFaq';
import { TopKeywordsData } from '../../types/TopKeywordsResponse';
import { TopRated } from '../../types/TopRated';
import { TransactionDetail } from '../../types/TransactionDetail';
import { TransactionListRequest } from '../../types/TransactionListForResponse';
import {
  TransactionRecentData,
  RequestPayload as TransactionRecentRequestPayload,
} from '../../types/TransactionRecent';
import { VBConfigData } from '../../types/VBConfigResponse';
import { Data as VendorDetailData } from '../../types/VendorDetailResponse';
import { Data as WordsLabelsResponseData } from '../../types/WordsLabelsResponse';
import { ZipData } from '../../types/ZipCode';
import Config from '../../utils/configs';
import { countryCodeHandler } from '../../utils/countryInfo';
import { getGhostID } from '../../utils/ghostIDHelpers';
import routeUtils from '../../utils/routes';
import sessionStorage from '../../utils/sessionStorage';
import { FaqFailPayload } from '../actions/getFaqActions';
import {
  CategoryPayload,
  FilterConfigPayloadWithoutDispatch,
  PayloadWithId,
  SearchPayload,
} from '../actions/getFilterConfigActions';
import { LoginByApplePayload } from '../actions/loginByApple';
import { LoginByEmailPayload } from '../actions/loginByEmail';
import { LoginByFacebookPayload } from '../actions/loginByFacebook';
import { LoginByLinePayload } from '../actions/loginByLine';
import { PostRegisterPayload } from '../actions/postRegisterActions';
import * as apiUtils from './utils';

let API_TOKEN = null;
const defaultPerPage = apiUtils.getListItemPerPage();

export const BEAxios = apiUtils.BEAxios;
export const setupBaseUrl = apiUtils.setupBaseUrl;
export const getPlatformCode = apiUtils.getPlatformCode;
export const deleteAxiosDefaultHeader = (key: string) => {
  if (key === 'api-key') {
    API_TOKEN = null;
  }

  apiUtils.deleteAxiosDefaultHeader(key);
};
export const setAxiosDefaultHeader = (key, val) => {
  if (key === 'api-key') {
    API_TOKEN = val;

    return;
  }

  return apiUtils.setAxiosDefaultHeader(key, val);
};

export const axiosConfigWithToken = (
  config: AxiosRequestConfig = {}
): AxiosRequestConfig => {
  if (!API_TOKEN) {
    return config;
  }
  const headers = config.headers || {};

  return {
    ...config,
    headers: {
      ...headers,
      'api-key': API_TOKEN,
    },
  };
};

// set country code from querystring if is prerender
if (Platform.OS === 'web' && isBot) {
  const url = new URL(window.location.href);

  apiUtils.setAxiosDefaultHeader(
    'country-code',
    url.searchParams.get('country_code') || countryCodeHandler.get()
  );
} else {
  apiUtils.setAxiosDefaultHeader('country-code', countryCodeHandler.get());
}

// TODO: 這邊之後應該是要接設定檔
// FIXME: fix web api header
if (Platform.OS !== 'web') {
  apiUtils.setAxiosDefaultHeader('lang', 'zh-Hant');
}

/**
 * Provides the default AxiosRequestConfig for v4
 * @param params (Optional)
 * @param configOverride Configs to override (Optional)
 */
export const v4AxiosRequestConfig = (
  params: object | null = null,
  configOverride: AxiosRequestConfig = {}
): AxiosRequestConfig => ({
  params,
  ...configOverride,
});

export const putAddToCart = (
  id: string,
  amount: number,
  colorId: string,
  sizeId: string
): AxiosPromise => {
  return BEAxios.put(
    `${apiUtils.getBaseUrl('v4')}/cart/product/${id}`,
    {
      amount,
      color_id: colorId,
      size_id: sizeId,
    },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getLoadCartContent = () => {
  return BEAxios.get<CartContent>(
    `${apiUtils.getBaseUrl('v4')}/cart`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const patchCartVirtualProduct = (
  virtualProductId: number,
  amount: number
): AxiosPromise => {
  return BEAxios.patch(
    `${apiUtils.getBaseUrl('v4')}/cart/virtual_product/${virtualProductId}`,
    {
      amount,
    },
    axiosConfigWithToken()
  );
};

export const deleteCartVirtualProduct = (
  virtualProductIds: string[]
): AxiosPromise => {
  return BEAxios.delete(
    `${apiUtils.getBaseUrl('v4')}/cart/batch_items`,
    axiosConfigWithToken(
      v4AxiosRequestConfig({
        virtual_product_ids: virtualProductIds,
      })
    )
  );
};

export const getItemContent = (id): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/contents/product/${id}`,
    v4AxiosRequestConfig()
  );
};

export const getRecommendList = (params): AxiosPromise => {
  return BEAxios.get<RecommendList>(
    `${apiUtils.getBaseUrl('v4')}/items/recommends`,
    axiosConfigWithToken({
      params: {
        per_page: defaultPerPage,
        top_items_quantity: 42,
        alsolike_group: sessionStorage.getItem(NavigationParams.AlsoLikeGroup),
        source_type: 5,
        ...params,
      },
    })
  );
};

export const getRecommendSubjects = (): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/items/recommended_subjects`,
    v4AxiosRequestConfig()
  );
};

export const getItemComboList = ({ id }: { id: string }): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/items/collocation`,
    axiosConfigWithToken({
      params: {
        id,
      },
    })
  );
};

export const getUserSubmitDetail = (id): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/items/user_submits/${id}`,
    axiosConfigWithToken()
  );
};

export const getCategoryList = () => {
  return BEAxios.get<CategoryListData>(
    `${apiUtils.getBaseUrl('v4')}/categories/shelves`,
    v4AxiosRequestConfig()
  );
};

export const getDeliveryData = (): AxiosPromise => {
  return BEAxios.get<DeliveryData>(
    `${apiUtils.getBaseUrl('v4')}/cart/delivery_data`,
    axiosConfigWithToken()
  );
};

export const getDefaultShippingFees = (): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/cart/default_shipping_fees`,
    axiosConfigWithToken()
  );
};

export const getCats = () => {
  return BEAxios.get<CatsData>(
    `${apiUtils.getBaseUrl('v4')}/categories`,
    v4AxiosRequestConfig()
  );
};

export const putDeliveryData = (deliveryItem): AxiosPromise => {
  return BEAxios.put<DeliveryData>(
    `${apiUtils.getBaseUrl('v4')}/cart/receiving_data`,
    deliveryItem,
    axiosConfigWithToken()
  );
};

export const getMailboxList = (args: {
  type: MailType;
  page: number;
  per_page: number;
}) => {
  return BEAxios.get<MailboxListData>(
    `${apiUtils.getBaseUrl('v4')}/supports`,
    axiosConfigWithToken(v4AxiosRequestConfig(args))
  );
};

export const getVendorDetail = ({ id }) => {
  return BEAxios.get<VendorDetailData>(
    `${apiUtils.getBaseUrl('v4')}/sellers/detail/${id}`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const putCVSReceivingData = cvsReceivingItem => {
  return BEAxios.put<CVSReceivingData>(
    `${apiUtils.getBaseUrl('v4')}/cart/receiving_data/cvs`,
    cvsReceivingItem,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getZipCodeByCountry = (countryCode: string): AxiosPromise => {
  return BEAxios.get<ZipData>(
    `${apiUtils.getBaseUrl('v4')}/cart/zip_data/${countryCode}`,
    v4AxiosRequestConfig()
  );
};

export const getPaymentsSA = (): AxiosPromise => {
  return BEAxios.get<PaymentData>(
    `${apiUtils.getBaseUrl('v4')}/payments`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getMemberInfo = (): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/members`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const putLovesSeller = (id: string) => {
  return BEAxios.put<void>(
    `${apiUtils.getBaseUrl('v4')}/loves/seller/${id}`,
    {},
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const deleteLovesSeller = (id: string) => {
  return BEAxios.delete<void>(
    `${apiUtils.getBaseUrl('v4')}/loves/seller/${id}`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postGuestToken = () => {
  return BEAxios.post<GuestToken>(
    `${apiUtils.getBaseUrl('v4')}/customers/guest`,
    null,
    v4AxiosRequestConfig()
  );
};

export const getTransactionList = (
  params: TransactionListRequest
): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/transactions`,
    axiosConfigWithToken({
      params,
    })
  );
};

export const getTransaction = (transaction_id: number): AxiosPromise => {
  return BEAxios.get<TransactionDetail>(
    `${apiUtils.getBaseUrl('v4')}/transactions/${transaction_id}`,
    axiosConfigWithToken()
  );
};

export const postSubmitBill = (submitBill): AxiosPromise => {
  return BEAxios.post<SubmitBillData>(
    `${apiUtils.getBaseUrl('v4')}/cart/submit_bill`,
    submitBill,
    axiosConfigWithToken()
  );
};

export const postForgetPassword = (data: ForgetPasswordRequestPayload) => {
  return BEAxios.post<void>(
    `${apiUtils.getBaseUrl('v4')}/members/forget_password`,
    data,
    v4AxiosRequestConfig()
  );
};

export const postVerifyToken = (payload: MemberVerifyTokenRequestPayload) => {
  return BEAxios.post<MemberVerifyTokenData>(
    `${apiUtils.getBaseUrl('v4')}/members/verify_token`,
    payload,
    v4AxiosRequestConfig()
  );
};

export const postResetPassword = (token: string, password: string) => {
  return BEAxios.post<void>(
    `${apiUtils.getBaseUrl('v4')}/members/reset_password`,
    { verify_token: token, password },
    v4AxiosRequestConfig()
  );
};

export const postSendVerificationCode = ({
  method,
  value,
}: {
  method: string;
  value: string;
}) => {
  return BEAxios.post<SendVerificationCodeData>(
    `${apiUtils.getBaseUrl('v4')}/members/send_verification_code/${method}`,
    { value },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postDeleteAccount = (payload: MemberDeletePayload) => {
  return BEAxios.post<void>(
    `${apiUtils.getBaseUrl('v4')}/members/delete_account`,
    payload,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getProductReviewDetail = ({ id }: { id: string }) => {
  return BEAxios.get<ProductReviewDetailData>(
    `${apiUtils.getBaseUrl('v4')}/reviews/product/${id}`,
    v4AxiosRequestConfig({})
  );
};

export const patchMemberVerified = ({
  method,
  code,
}: {
  method: string;
  code: string;
}) => {
  return BEAxios.patch(
    `${apiUtils.getBaseUrl('v4')}/members/verified/${method}`,
    { code },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postLoginByEmail = (
  args: Omit<LoginByEmailPayload, 'success' | 'failure' | 'loginMethod'>
) => {
  return BEAxios.post<MemberLoginByEmailResponse['data']>(
    `${apiUtils.getBaseUrl('v4')}/members/login_account`,
    args,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postLoginByFacebook = (
  args: Pick<LoginByFacebookPayload, 'fb_access_token' | 'fcm_token'>
) => {
  return BEAxios.post<MemberLoginByFacebookResponse['data']>(
    `${apiUtils.getBaseUrl('v4')}/members/login_fb`,
    args,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postLoginByApple = (
  args: Pick<
    LoginByApplePayload,
    'identity_token' | 'auth_code' | 'fcm_token' | 'full_name'
  >
) => {
  return BEAxios.post<MemberLoginByAppleResponse['data']>(
    `${apiUtils.getBaseUrl('v4')}/members/login_apple`,
    args,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postLoginByLine = (
  args: Pick<LoginByLinePayload, 'code' | 'redirectUri'> & {
    fcm_token?: string;
  }
) => {
  const { code, redirectUri, ...rest } = args;
  const tokenKey = isApp ? 'id_token' : 'code';

  return BEAxios.post<MemberLoginByLineResponse['data']>(
    `${apiUtils.getBaseUrl('v4')}/members/login_line`,
    { ...rest, redirect_uri: redirectUri, [tokenKey]: code },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postRegister = (
  args: Omit<PostRegisterPayload, 'success' | 'failure' | 'loginMethod'>
) => {
  return BEAxios.post<MemberRegisterByEmailResponse['data']>(
    `${apiUtils.getBaseUrl('v4')}/members/register`,
    args,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getCookie = () => {
  return BEAxios.get<CookieData>(
    `${apiUtils.getBaseUrl('v4')}/members/cookie`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const logout = () => {
  return BEAxios.put(
    `${apiUtils.getBaseUrl('v4')}/members/logout`,
    null,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getCategories = (): AxiosPromise => {
  return BEAxios.get<CategoriesData>(
    `${apiUtils.getBaseUrl('v4')}/placements/operation_categories`,
    v4AxiosRequestConfig()
  );
};

export const getSearchUserSubmitWithToken = ({
  category: category_ids,
  color: colors,
  maxPrice,
  minPrice,
  pattern: patterns,
  queryText: q,
  sortBy: sort_by,
  topIds: top_ids,
  ...restQueries
}: SearchRequest) => {
  return BEAxios.get<SearchUserSubmit>(
    `${apiUtils.getBaseUrl('v4')}/search/items`,
    axiosConfigWithToken(
      v4AxiosRequestConfig({
        category_ids,
        colors,
        fuzzy: 0,
        max_price: maxPrice || null,
        min_price: minPrice || null,
        patterns,
        q,
        sort: 'desc',
        sort_by,
        top_ids,
        ...restQueries,
      })
    )
  );
};

export const searchUserSubmit = ({
  queryText: q,
  page,
  topIds: top_ids,
  minPrice,
  maxPrice,
  sortBy: sort_by,
  color: colors,
  category: category_ids,
  fuzzy = 0,
  pattern: patterns,
  seller_id,
  isListPageDefaultSort,
}: SearchRequest): AxiosPromise => {
  return BEAxios.get<SearchUserSubmit>(
    `${apiUtils.getBaseUrl('v4')}/search/items`,
    v4AxiosRequestConfig({
      q,
      page,
      top_ids,
      sort_by,
      min_price: minPrice || null,
      max_price: maxPrice || null,
      category_ids,
      fuzzy,
      q_and_filter_case: fuzzy,
      sort: 'desc',
      colors,
      patterns,
      seller_id,
      source_type: isListPageDefaultSort ? 'category_default_df_v1' : undefined,
    })
  );
};

export const searchRecommend = ({
  sortBy: sort_by,
}: {
  sortBy: string;
}): AxiosPromise => {
  return BEAxios.get<SearchRecommend>(
    `${apiUtils.getBaseUrl('v4')}/search/items/recommend`,
    v4AxiosRequestConfig({
      sort_by,
      sort: 'desc',
      page: 1,
      perpage: 40,
    })
  );
};

export const getFilterConfig = (param: FilterConfigPayloadWithoutDispatch) => {
  const renamedParams = {
    search_page: param.page,
    q: (param as SearchPayload).queryText,
    category_id: (param as CategoryPayload).catId,
    id: (param as PayloadWithId).id,
  };

  return BEAxios.get<DataFromApi>(
    `${apiUtils.getBaseUrl('v4')}/search/filter_items`,
    v4AxiosRequestConfig(renamedParams)
  );
};

export const getRecentTransactions = (
  params: TransactionRecentRequestPayload
) => {
  return BEAxios.get<TransactionRecentData>(
    `${apiUtils.getBaseUrl('v4')}/transactions/recent`,
    axiosConfigWithToken(v4AxiosRequestConfig(params))
  );
};

export const getSupportFaqURL = `${apiUtils.getS3ApiUrl()}/config/resources/support_questions.json`;
export const getFaq = (faqVersionCode: string) => {
  return BEAxios.get<FaqFailPayload>(
    `${apiUtils.getS3ApiUrl()}/config/resources/faq${faqVersionCode}.json`,
    v4AxiosRequestConfig()
  );
};

export const getSupportDetail = (supportId: string) => {
  return BEAxios.get<SupportDetailData>(
    `${apiUtils.getBaseUrl('v4')}/supports/detail/${supportId}`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getSupportMessages = (
  supportId: string,
  args: {
    page: number;
    per_page: number;
  }
) => {
  return BEAxios.get<SupportMessagesData>(
    `${apiUtils.getBaseUrl('v4')}/supports/messages/${supportId}`,
    axiosConfigWithToken(v4AxiosRequestConfig(args))
  );
};

export const putSubtotal = (subtotal: SubtotalRequest): AxiosPromise => {
  return BEAxios.put(
    `${apiUtils.getBaseUrl('v4')}/cart/subtotal`,
    subtotal,
    axiosConfigWithToken()
  );
};

export const patchTransaction = (transactionID: string, payBy: number) => {
  return BEAxios.patch(
    `${apiUtils.getBaseUrl('v4')}/transactions/${transactionID}`,
    {
      pay_by: payBy,
    },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const patchCancelTransaction = (transactionID: string) => {
  return BEAxios.patch(
    `${apiUtils.getBaseUrl('v4')}/transactions/cancel/${transactionID}`,
    null,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getMarketingEvents = (): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/events/marketing`,
    axiosConfigWithToken({
      transformResponse: (r: MarketingEventsForResponse) => {
        if (r.data) {
          return r.data.events;
        }

        return {
          ...r,
          data: {},
        };
      },
    })
  );
};

export const postLinePayPrime = (
  transactionID: number,
  prime: string,
  screen: string
): AxiosPromise => {
  const opts =
    Platform.OS === 'web'
      ? {
          custom_url: `${location.origin}${routeUtils.compileToPath(screen, {
            transactionID,
          })}`,
        }
      : {};

  return BEAxios.post(
    `${apiUtils.getBaseUrl('v4')}/payments/line_pay_prime`,
    {
      transaction_id: transactionID,
      prime,
      ...opts,
    },
    axiosConfigWithToken()
  );
};

export const postDirectPayCard = (prime: string): AxiosPromise => {
  return BEAxios.post(
    `${apiUtils.getBaseUrl('v4')}/payments/direct_pay_card`,
    {
      prime,
      is_primary: true,
    },
    axiosConfigWithToken(v4AxiosRequestConfig({}))
  );
};

export const postDirectPayToken = (
  transactionID: number,
  cardID: number,
  screen: string
): AxiosPromise => {
  return BEAxios.post(
    `${apiUtils.getBaseUrl('v4')}/payments/direct_pay_token`,
    {
      transaction_id: transactionID,
      card_id: cardID,
      custom_url: `https://${Config.MHOST}${routeUtils.compileToPath(screen, {
        transactionID,
      })}`,
    },
    axiosConfigWithToken()
  );
};

export const getTelexpressToken = (token: string): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/members/telexpress`,
    axiosConfigWithToken(
      v4AxiosRequestConfig({
        token,
      })
    )
  );
};

export const postRating = (data: {
  transaction_id: string;
  products: { id: string; score: number }[];
}) => {
  return BEAxios.post(
    `${apiUtils.getBaseUrl('v4')}/reviews/details`,
    data,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const patchFCMToken = ({ fcmToken: fcm_token }) => {
  return BEAxios.patch<CategoriesData>(
    `${apiUtils.getBaseUrl('v4')}/members/token`,
    { fcm_token },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getDirectPayCard = (): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/cart/direct_pay_card`,
    axiosConfigWithToken()
  );
};

export const getOnSale = ({
  page,
  category: category_ids,
  color: colors,
  maxPrice,
  minPrice,
  pattern: patterns,
  sort_by = SortBy.UPDATE_AT,
}: {
  page: number;
  sort_by?: SortBy;
  category?: string[];
  color?: string[];
  maxPrice?: string;
  minPrice?: string;
  pattern?: string[];
}) => {
  return BEAxios.get<OnSaleResponse>(
    `${apiUtils.getBaseUrl('v4')}/search/on_sale_items`,
    axiosConfigWithToken(
      v4AxiosRequestConfig({
        category_ids,
        colors,
        min_price: minPrice || null,
        max_price: maxPrice || null,
        page,
        patterns,
        perpage: defaultPerPage * 3,
        sort_by,
      })
    )
  );
};

export const putDirectPayPrimaryCard = (card_id: number): AxiosPromise => {
  return BEAxios.put(
    `${apiUtils.getBaseUrl('v4')}/payments/primary_card/${card_id}`,
    null,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getCouponList = (
  params: {
    page: number;
    limit: number;
    virtual_products?: string[];
  },
  couponType
): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/coupons/list/${couponType}`,
    axiosConfigWithToken(v4AxiosRequestConfig(params))
  );
};

export const postApplePayPrime = (transactionID: number, prime: string) => {
  return BEAxios.post<ApplePayPrimeResponse>(
    `${apiUtils.getBaseUrl('v4')}/payments/apple_pay_prime`,
    {
      transaction_id: transactionID,
      prime,
    },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getVBConfig = () => {
  return BEAxios.get<VBConfigData>(
    `${apiUtils.getBaseUrl('v4')}/config`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postCouponRedeem = (couponCode: string): AxiosPromise => {
  return BEAxios.post(
    `${apiUtils.getBaseUrl('v4')}/coupons`,
    { coupon_code: couponCode },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const putItemLoves = (id): AxiosPromise => {
  return BEAxios.put(
    `${apiUtils.getBaseUrl('v4')}/loves/product/${id}`,
    null,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const deleteItemLoves = (id): AxiosPromise => {
  return BEAxios.delete(
    `${apiUtils.getBaseUrl('v4')}/loves/product/${id}`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getBanners = (positions: string[]) => {
  return BEAxios.get<Banners>(
    `${apiUtils.getBaseUrl('v4')}/banners`,
    v4AxiosRequestConfig({
      positions,
    })
  );
};

export const getLandingPageList = () => {
  return BEAxios.get<LandingPageListData>(
    `${apiUtils.getBaseUrl('v4')}/items/landing_page_list`,
    v4AxiosRequestConfig()
  );
};

export const getQueryItems = query => {
  // 經過討論後把複雜的參數放到 body，所以這裡是 post 而不是 get，但在行為上還是 get (拿商品)
  return BEAxios.post<QueryItemsData>(
    `${apiUtils.getBaseUrl('v4')}/queries/items`,
    query,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postSupportsAdd = (payload: SupportsAddPayload) => {
  const { categoryId, content, images, transactionId } = payload;
  const args = {
    body: { category_id: categoryId, content, transaction_id: transactionId },
    assetsKey: 'images[]',
  };
  const data = createFormData(images, args);

  return BEAxios.post<void>(
    `${apiUtils.getBaseUrl('v4')}/supports/add`,
    data,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getLabelItems = data => {
  // NOTE: 因為標籤的機制還不太穩定，這 api 可能是暫時的，朝向標籤 api 的方向前進
  return BEAxios.get<LabelItemsData>(
    `${apiUtils.getBaseUrl('v4')}/items/label_items`,
    axiosConfigWithToken(v4AxiosRequestConfig(data))
  );
};

export const postSupportsReply = data => {
  return BEAxios.post(
    `${apiUtils.getBaseUrl('v4')}/supports/reply`,
    data,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getLightBox = (): AxiosPromise => {
  return BEAxios.get(`${apiUtils.getBaseUrl('v4')}/events/lightbox`);
};

export const getPreviewTransactions = (transactionID: string) => {
  return BEAxios.get<PreviewTransactionsData>(
    `${apiUtils.getBaseUrl('v4')}/transactions/preview/${transactionID}`,
    v4AxiosRequestConfig()
  );
};

export const getShippingLogistics = (
  transactionID: string,
  packageNum?: number
) => {
  const path =
    typeof packageNum === 'undefined'
      ? `/${transactionID}`
      : `/${transactionID}/${packageNum}`;

  return BEAxios.get<ShippingLogisticsResponse['data']>(
    `${apiUtils.getBaseUrl('v4')}/transactions/shipping_logistics${path}`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getLoveList = (page: number): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/loves/products`,
    axiosConfigWithToken({
      params: {
        page,
      },
    })
  );
};

export const getLoveVendors = (page: number) => {
  return BEAxios.get<LoveVendorsData>(
    `${apiUtils.getBaseUrl('v4')}/loves/sellers`,
    axiosConfigWithToken(v4AxiosRequestConfig({ page }))
  );
};

export const getTopSales = ({
  page,
  limit,
}: {
  page: number;
  limit?: number;
}): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/items/top_sales`,
    v4AxiosRequestConfig({ page, limit })
  );
};

export const getTopRated = ({
  page,
  per_page = defaultPerPage,
}: {
  page: number;
  per_page?: number;
}): AxiosPromise => {
  return BEAxios.get<TopRated>(
    `${apiUtils.getBaseUrl('v4')}/items/rating`,
    v4AxiosRequestConfig({ page, per_page })
  );
};

export const getRecommendProducts = async ({
  page,
  perPage = defaultPerPage,
}: {
  page: number;
  perPage?: number;
}) => {
  const ghostID = await getGhostID();

  return BEAxios.get<RecommendProductsResponse>(
    `${apiUtils.getBaseUrl('v4')}/items/recommend_products/${ghostID}`,
    axiosConfigWithToken(
      v4AxiosRequestConfig({
        page,
        per_page: perPage,
        source_type: 3,
      })
    )
  );
};

export const deleteDirectPayCard = (card_id: number): AxiosPromise => {
  return BEAxios.delete(
    `${apiUtils.getBaseUrl('v4')}/payments/direct_pay_card/${card_id}`,
    axiosConfigWithToken()
  );
};

export const getSimilarItems = (getSimilarValues: {
  page: number;
  perpage: number;
  product_id: number | string;
}): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/items/similar_items`,
    v4AxiosRequestConfig({
      product_type: 'user_submit',
      ...getSimilarValues,
    })
  );
};

export const getSameSellerItems = ({
  page,
  perpage,
  product_id,
}: {
  page: number;
  perpage: number;
  product_id: number | string;
}): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/items/same_seller_items`,
    v4AxiosRequestConfig({
      product_type: 'user_submit',
      page,
      limit: perpage,
      product_id,
    })
  );
};

export const getNotifications = (args: { group: NotificationGroup[] }) => {
  return BEAxios.get<NotificationsData>(
    `${apiUtils.getBaseUrl('v4')}/members/notifications`,
    axiosConfigWithToken(v4AxiosRequestConfig(args))
  );
};

export const patchNotificationRead = (id: string): AxiosPromise => {
  return BEAxios.patch(
    `${apiUtils.getBaseUrl('v4')}/members/notifications/${id}`,
    {
      is_read: true,
    },
    axiosConfigWithToken()
  );
};

export const putThemeFollow = (id: string): AxiosPromise => {
  return BEAxios.put(
    `${apiUtils.getBaseUrl('v4')}/loves/theme_shopping/${id}`,
    null,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getCountries = () => {
  return BEAxios.get<CountriesData>(
    `${apiUtils.getBaseUrl('v4')}/config/countries`,
    v4AxiosRequestConfig()
  );
};

export const getMarketingLandingPage = (
  params: GetMarketingLandingPageRequest
): AxiosPromise => {
  const {
    id,
    minPrice: min_price,
    maxPrice: max_price,
    category: category_ids,
    sortBy: sort_by,
    ...restParams
  } = params;

  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/items/marketing_landing_page/${id}`,
    axiosConfigWithToken(
      v4AxiosRequestConfig({
        min_price,
        max_price,
        category_ids,
        sort_by,
        ...restParams,
      })
    )
  );
};

export const getWordsLabels = (q: string) => {
  return BEAxios.get<WordsLabelsResponseData>(
    `${apiUtils.getBaseUrl('v4')}/search/word_labels`,
    v4AxiosRequestConfig({ q })
  );
};

export const getOnboarding = async (page: number) => {
  const ghostID = await getGhostID();

  return BEAxios.get<OnboardingResponse>(
    `${apiUtils.getBaseUrl('v4')}/items/onboarding_items/${ghostID}`,
    v4AxiosRequestConfig({
      page,
      per_page: defaultPerPage,
      source_type: 3,
    })
  );
};

export const putPhoneVerificationCode = () => {
  return BEAxios.put(
    `${apiUtils.getBaseUrl('v4')}/members/phone_verification_code`,
    null,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const deleteThemeFollow = (id: string): AxiosPromise => {
  return BEAxios.delete(
    `${apiUtils.getBaseUrl('v4')}/loves/theme_shopping/${id}`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getNewArrival = query => {
  return BEAxios.get<NewArrivalResponse>(
    `${apiUtils.getBaseUrl('v4')}/items/new_arrival`,
    axiosConfigWithToken(
      v4AxiosRequestConfig({
        per_page: defaultPerPage,
        ...query,
      })
    )
  );
};

export const putMemberVerifyPhone = (
  phoneNumber: string
): AxiosPromise<MemberVerifyData> => {
  return BEAxios.put(
    `${apiUtils.getBaseUrl('v4')}/members/phone`,
    { phone_number: phoneNumber },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getFreeDelivery = () => {
  return BEAxios.get<FreeDeliveryData>(
    `${apiUtils.getBaseUrl('v4')}/events/free_delivery`,
    v4AxiosRequestConfig()
  );
};

export const postMemberVerifySMS = (
  verificationCode: string
): AxiosPromise<MemberVerifyData> => {
  return BEAxios.post(
    `${apiUtils.getBaseUrl('v4')}/members/phone`,
    { verification_code: verificationCode },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getEventPageCover = () => {
  return BEAxios.get<EventPageCoverData>(
    `${apiUtils.getBaseUrl('v4')}/banners/event`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const patchMember = (data: PatchMemberData) => {
  return BEAxios.patch<PatchMemberData>(
    `${apiUtils.getBaseUrl('v4')}/members`,
    data,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getPromotionalThemes = async () => {
  const ghostID = await getGhostID();

  return BEAxios.get<PromotionalThemesData>(
    `${apiUtils.getBaseUrl('v4')}/placements/promotional_themes`,
    v4AxiosRequestConfig({
      source_type: 1,
      ghost_id: ghostID,
    })
  );
};

export const checkTransaction = (transactionId): AxiosPromise => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/transactions/check/${transactionId}`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getProductTags = ({ id }: { id: string }) => {
  return BEAxios.get<ProductTagsData>(
    `${apiUtils.getBaseUrl('v4')}/items/tags/${id}`,
    v4AxiosRequestConfig({})
  );
};

export const getAnnouncements = () => {
  return BEAxios.get<AnnouncementsData>(
    `${apiUtils.getBaseUrl('v4')}/announcements`,
    v4AxiosRequestConfig()
  );
};

export const getAnnouncementDetail = (id: string) => {
  return BEAxios.get<Announcements>(
    `${apiUtils.getBaseUrl('v4')}/announcements/detail/${id}`,
    v4AxiosRequestConfig()
  );
};

export const getEventCoupon = () => {
  return BEAxios.get<EventCouponData>(
    `${apiUtils.getBaseUrl('v4')}/events/coupon`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getProductReviewListData = ({
  id,
  page,
}: {
  id: string;
  page: number;
}) => {
  return BEAxios.get<ProductReviewListDataData>(
    `${apiUtils.getBaseUrl('v4')}/reviews/product_skus`,
    v4AxiosRequestConfig({ marketing_product_id: id, page })
  );
};

export const putUpdatePassword = (params: {
  old_password: string;
  new_password: string;
  confirm_password: string;
}) => {
  return BEAxios.put(
    `${apiUtils.getBaseUrl('v4')}/members/update_password`,
    params,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const postPeriodTask = (id: string) => {
  return BEAxios.post(
    `${apiUtils.getBaseUrl('v4')}/missions/reward`,
    { id },
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getPeriodTask = () => {
  return BEAxios.get<PeriodTaskData>(
    `${apiUtils.getBaseUrl('v4')}/missions/period_task`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const searchCVSStoresCart = (query: {
  q: string;
  city?: string;
  district?: string;
  road?: string;
}): any => {
  return BEAxios.get(
    `${apiUtils.getBaseUrl('v4')}/cart/cvs_stores`,
    axiosConfigWithToken(v4AxiosRequestConfig({ ...query }))
  );
};

export const getMemberProfile = () => {
  return BEAxios.get<MemberProfileData>(
    `${apiUtils.getBaseUrl('v4')}/members/member_profile`,
    axiosConfigWithToken(v4AxiosRequestConfig())
  );
};

export const getTopKeywords = () => {
  return BEAxios.get<TopKeywordsData>(
    `${apiUtils.getBaseUrl('v4')}/placements/top_keywords`,
    v4AxiosRequestConfig()
  );
};
