import { ActionType, getType } from 'typesafe-actions';

import { addToCart } from '../actions/addToCartActions';
import {
  getItemComboListActions,
  updateComboListAction,
} from '../actions/getItemComboListActions';

export interface State {
  productIds: { [key: string]: string[] };
  comboListIds: { [key: string]: string[] };
  comboProducts: {
    [key: string]: {
      colorName: string;
      sizeName: string;
      amount: number;
    };
  };
  hasError: boolean;
  isFetching: boolean;
}

export const defaultState: State = {
  isFetching: false,
  hasError: false,
  productIds: {},
  comboListIds: {},
  comboProducts: {},
};

export default function (
  state: State = defaultState,
  action: ActionType<
    | typeof getItemComboListActions
    | typeof updateComboListAction
    | typeof addToCart.success
  >
) {
  switch (action.type) {
    case getType(getItemComboListActions.request): {
      return {
        ...state,
        isFetching: true,
      };
    }
    case getType(getItemComboListActions.success): {
      const { data, id } = action.payload;

      const productIds = data.products?.map(product => product.id);

      return {
        ...state,
        isFetching: false,
        productIds: {
          ...state.productIds,
          [id]: productIds,
        },
      };
    }
    case getType(getItemComboListActions.failure): {
      return {
        ...defaultState,
        hasError: true,
      };
    }
    case getType(updateComboListAction): {
      const { comboListIds, id } = action.payload;

      return {
        ...state,
        comboListIds: {
          ...state.comboListIds,
          [id]: comboListIds,
        },
      };
    }
    case getType(addToCart.success): {
      const { isComboCart, colorName, sizeName, id, amount } = action.payload;

      if (isComboCart) {
        return {
          ...state,
          comboProducts: {
            ...state.comboProducts,
            [id]: {
              colorName,
              sizeName,
              amount,
            },
          },
        };
      }

      return state;
    }
    default:
      return state;
  }
}
