/* eslint-disable camelcase,no-restricted-globals */
import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import * as arrayHandler from '../../utils/arrayHandler';
import { actionsList } from '../rootAction';

const { getMarketingLandingPageActions, leaveMarketingLandingPageAction } =
  actionsList;

export interface State {
  banner: {
    sm?: string;
    md?: string;
    lg?: string;
  };
  currentPage: number;
  display_name: string;
  error: Error | null;
  hasError: boolean;
  isDetailLoading: boolean;
  products: string[];
  topProducts: string[];
  tags: string[];
  description?: string;
  hasMoreItems?: boolean;
  id?: string;
  imageUrlForOg?: string;
  listLoadingType?: ListLoadingTypes;
  next_page?: number;
  title?: string;
}

export const defaultState: State = {
  banner: {},
  currentPage: 1,
  display_name: '',
  error: null,
  hasError: false,
  isDetailLoading: true,
  products: [],
  topProducts: [],
  tags: [],
};

type Action = ActionType<
  | typeof getMarketingLandingPageActions.request
  | typeof getMarketingLandingPageActions.success
  | typeof getMarketingLandingPageActions.failure
  | typeof leaveMarketingLandingPageAction
>;

export default produce((draft: State, action: Action) => {
  switch (action.type) {
    case getType(getMarketingLandingPageActions.request): {
      const { id, loadingType, page } = action.payload;
      const newItems = loadingType === 'new' ? [] : draft.products;
      const newTopItems = loadingType === 'new' ? [] : draft.topProducts;
      const newDisplayName = loadingType === 'new' ? '' : draft.display_name;
      const hasMoreItems = loadingType === 'new' ? true : draft.hasMoreItems;
      const newBanner = loadingType === 'new' ? {} : draft.banner;

      draft.error = null;
      draft.hasError = false;
      draft.hasMoreItems = hasMoreItems;
      draft.id = id;
      draft.isDetailLoading = true;
      draft.banner = newBanner;
      draft.listLoadingType = loadingType;
      draft.products = newItems;
      draft.topProducts = newTopItems;
      draft.display_name = newDisplayName;
      draft.currentPage = page;

      break;
    }
    case getType(getMarketingLandingPageActions.success): {
      const { data, id, loadingType, page } = action.payload;
      const { products, ...restData } = data;

      const itemsId = data.products
        .filter(x => x.layout_name !== 'top')
        .map(d => `${d.id}`);
      const newItems =
        loadingType === 'loadMore' ? [...draft.products, ...itemsId] : itemsId;

      const topItemsId = data.products
        .filter(x => x.layout_name === 'top')
        .map(d => `${d.id}`);
      const newTopItems =
        loadingType === 'loadMore'
          ? [...draft.topProducts, ...topItemsId]
          : topItemsId;

      const imageUrlForOg = draft.imageUrlForOg || data.products[0]?.image_url;

      Object.keys(restData).forEach(key => {
        draft[key] = restData[key];
      });
      draft.currentPage = page;
      draft.error = null;
      draft.hasError = false;
      draft.id = id;
      draft.isDetailLoading = false;
      draft.products = arrayHandler.uniqueArray(newItems);
      draft.topProducts = arrayHandler.uniqueArray(newTopItems);
      draft.hasMoreItems = restData.next_page;
      draft.imageUrlForOg = imageUrlForOg;

      break;
    }
    case getType(getMarketingLandingPageActions.failure): {
      const { error, id } = action.payload;

      draft.error = error;
      draft.hasError = true;
      draft.hasMoreItems = false;
      draft.id = id;
      draft.isDetailLoading = false;
      break;
    }
    case getType(leaveMarketingLandingPageAction): {
      draft.banner = {};
      draft.currentPage = 1;
      draft.display_name = '';
      draft.error = null;
      draft.hasError = false;
      draft.isDetailLoading = true;
      draft.products = [];
      draft.topProducts = [];
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
