import { AxiosResponse } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { RequestPayload } from '../../types/ForgetPassword';

export interface ForgetPasswordPayload extends RequestPayload {
  onSuccess: () => void;
  onFailure: (error?: AxiosResponse<void>) => void;
}

export interface ForgetPasswordSuccessPayload {}

export interface ForgetPasswordFailPayload {
  error: Error;
}

export const postForgetPasswordActions = createAsyncAction(
  'verybuy/POST_FORGET_PASSWORD',
  'verybuy/POST_FORGET_PASSWORD_SUCCESS',
  'verybuy/POST_FORGET_PASSWORD_FAIL'
)<
  ForgetPasswordPayload,
  ForgetPasswordSuccessPayload,
  ForgetPasswordFailPayload
>();
