import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import {
  RecommendProduct,
  RecommendProductsResponse,
} from '../../types/RecommendProductsResponse';

export interface RecommendProductsPayload {
  page: number;
  loadingType: ListLoadingTypes;
  success: (products: RecommendProduct[]) => void;
  perPage?: number;
}

export interface RecommendProductsSuccessPayload {
  data: RecommendProductsResponse;
  page: number;
  loadingType: ListLoadingTypes;
}

export interface RecommendProductsFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getRecommendProductsActions.request>;
}

export const getRecommendProductsActions = createAsyncAction(
  'verybuy/GET_RECOMMEND_PRODUCTS',
  'verybuy/GET_RECOMMEND_PRODUCTS_SUCCESS',
  'verybuy/GET_RECOMMEND_PRODUCTS_FAIL'
)<
  RecommendProductsPayload,
  RecommendProductsSuccessPayload,
  RecommendProductsFailPayload
>();
