import { createAsyncAction } from 'typesafe-actions';

export interface MemberVerifyPhonePayload {
  phoneNumber: string;
  success: Function;
  failure: Function;
}

export interface MemberVerifyPhoneFailPayload {
  customErrorHandling: boolean;
  error: Error;
}

export const putMemberVerifyPhoneActions = createAsyncAction(
  'verybuy/PUT_MEMBER_VERIFY_PHONE',
  'verybuy/PUT_MEMBER_VERIFY_PHONE_SUCCESS',
  'verybuy/PUT_MEMBER_VERIFY_PHONE_FAIL'
)<MemberVerifyPhonePayload, void, MemberVerifyPhoneFailPayload>();
