import { NavigationStackOptions } from 'react-navigation-stack';

import headerContainerCreator from '../headerContainerCreator';
import createLinearGradientNavbar from '../linearGradient';
import { DefaultCustomNavbarParams } from '../navFactoryTypes';
import SearchNavBar from './SearchNavBarContainer';

export default function createDefaultCustomNavbar({
  enableHelmet,
  leftOpts,
  navigation,
  screen,
  shouldLogin,
  ...rest
}: DefaultCustomNavbarParams): NavigationStackOptions {
  const params = navigation?.state?.params || {};
  const queryText = params?.queryText || '';
  const bannerId = params?.banner_id;

  if (bannerId) {
    const campaignTitle = params?.campaignTitle || '';

    return createLinearGradientNavbar({
      enableHelmet: true,
      headerTitle: campaignTitle,
      leftOpts,
      navigation,
      screen,
      shouldLogin,
      ...rest,
    });
  }

  return {
    header: ({ scene }) => {
      const HeaderContainer = headerContainerCreator(SearchNavBar, {
        shouldLogin,
      });

      return (
        <HeaderContainer
          index={scene.index}
          isFocused={scene.isActive}
          navigation={navigation}
          queryText={queryText}
          screen={screen}
          leftOpts={leftOpts}
          shouldLogin={shouldLogin}
          enableHelmet={enableHelmet}
        />
      );
    },
    ...(rest || {}),
  };
}
