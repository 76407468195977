import { AnalyticsEvent } from '../../../constants/AnalyticsEvent';
import { makeCatById } from '../../../redux/selectors/catsSelector';
import { makeProductByIdSelector } from '../../../redux/selectors/productById';
import { setCurrentScreen } from './utils.web';

/**
 * Facebook log event document
 * Pixel(Web): https://developers.facebook.com/docs/facebook-pixel/reference
 * APP: https://developers.facebook.com/docs/marketing-api/app-event-api
 */
export const getPixelEventPayload = (logEvent, logData, currentStore) => {
  const data = logData?.data;

  switch (logEvent) {
    case AnalyticsEvent.ScreenView:
      setCurrentScreen();

      return;
    case AnalyticsEvent.ViewItem: {
      const id = logData?.data?.id;
      const itemInfo = makeProductByIdSelector(id)(currentStore);

      const parentCategory = makeCatById(logData?.data?.parent_category_id)(
        currentStore
      );
      const childCategory = makeCatById(logData?.data?.child_category_id)(
        currentStore
      );

      return {
        content_ids: logData?.data?.marketing_product_id,
        content_name: logData?.data?.title || 'EMPTY_ITEM_NAME',
        content_category: childCategory?.name,
        content_child_category: childCategory?.name,
        content_parent_category: parentCategory?.name,
        content_seller_nick: itemInfo?.seller_nick,
        content_type: 'product',
        currency: 'TWD',
        value: logData?.data?.price,
      };
    }
    case AnalyticsEvent.AddToCart: {
      const parentCategory = makeCatById(logData?.parent_category_id)(
        currentStore
      );
      const childCategory = makeCatById(logData?.child_category_id)(
        currentStore
      );
      const id = logData?.id;
      const itemInfo = makeProductByIdSelector(id)(currentStore);

      return {
        content_ids: logData?.marketing_product_id,
        content_name: logData?.title,
        content_type: 'product',
        content_category: childCategory?.name,
        content_child_category: childCategory?.name,
        content_parent_category: parentCategory?.name,
        content_seller_nick: itemInfo?.seller_nick,
        currency: 'TWD',
        value: logData?.price * logData?.amount || 1,
      };
    }
    case AnalyticsEvent.BeginCheckout:
      return {
        currency: 'TWD',
        value: logData?.total_price_tw,
      };
    case AnalyticsEvent.AddToWishList: {
      const id = logData?.id;
      const itemInfo = makeProductByIdSelector(id)(currentStore);

      const parentCatId = logData?.parentCatId || itemInfo?.parent_category_id;
      const childCatId = logData?.childCatId || itemInfo?.child_category_id;

      const parentCategory = makeCatById(parentCatId)(currentStore);
      const childCategory = makeCatById(childCatId)(currentStore);

      return {
        content_ids: itemInfo?.marketing_product_id,
        content_name: itemInfo?.title,
        content_type: 'product',
        content_category: childCategory?.name,
        content_child_category: childCategory?.name,
        content_parent_category: parentCategory?.name,
        content_seller_nick: itemInfo?.seller_nick,
        currency: 'TWD',
        value: itemInfo?.min_price || 1,
      };
    }

    case AnalyticsEvent.ECommercePurchase:
      const eCommerceItems = data?.detail_data;
      const categories = data?.purchased_categories;

      return {
        content_ids: eCommerceItems.map(item => item.marketing_product_id),
        content_name: eCommerceItems.map(item => item.product_name),
        content_type: 'product',
        contents: eCommerceItems.map(content => ({
          id: content.marketing_product_id,
          quantity: content.quantity,
          item_price: content.product_price,
        })),
        content_seller_nick: eCommerceItems.map(content => content.seller_nick),
        currency: 'TWD',
        // 文件沒有寫這個 是為了ＦＢ追蹤漏訂單而加的
        order_id: data?.transaction_id,
        purchased_categories: categories,
        value: data.price,
      };

    case AnalyticsEvent.SignUp:
      const { method } = logData;

      return {
        status: true,
        currency: 'TWD',
        fb_registration_method: method,
      };

    case AnalyticsEvent.ViewSearchResults:
      const resultProducts = logData?.data?.products || [];

      return {
        content_ids: resultProducts.map(
          product => product.marketing_product_id
        ),
        /*
          https://developers.facebook.com/docs/marketing-api/audiences/guides/dynamic-product-audiences#setuppixel
          根據文件，content_type 定義為 'product'
        */
        content_type: 'product',
        search_string: logData?.queryText || 'UNKNOWN_SEARCH_STRING',
      };

    default:
      return;
  }
};
