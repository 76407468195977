import styled from 'styled-components/native';

import { neutralDark } from '../../../../../constants/color';

export const S = {
  TitleContainer: styled.View`
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  `,
  Title: styled.Text`
    color: ${neutralDark};
    font-size: 15px;
    line-height: 21px;
  `,
  HotBubblesContainer: styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  `,
};
