import { createContext } from 'react';

export function couldLogFirehose() {
  return false;
}

export function toastFirehoseEvent() {
  return undefined;
}

export function logFirehose() {
  return undefined;
}

export function checkItemViewed() {
  return false;
}

export const LogFirehoseContext = createContext({
  firehoseLogStateForDebug: false,
  updateFirehoseLogStateForDebug: () => {
    return undefined;
  },
});

export function LogFirehoseProvider({ children }) {
  return children;
}

export function withLogFirehoseConsumer(WrappedComponent) {
  return WrappedComponent;
}
