import { createSelector } from 'reselect';

import { GET_LIST_ITEM_NUM } from '../../constants/layout';
import * as listWithPlaceholder from '../../utils/listWithPlaceholder';
import { State } from '../reducers/recommendProductsReducer';
import { RootState } from '../rootReducer';

const recommendProductsRaw = (state: RootState) =>
  state?.recommendProductsReducer || {};

export const makeRecommendProducts = () =>
  createSelector(recommendProductsRaw, (data = {}) => {
    const numColumns = GET_LIST_ITEM_NUM();
    const { items = [], isFetchingRecommendProducts, hasMoreItems } = data;
    const cloneItems = items.slice();

    /*
      這段處理是為了避免首頁 FlatList 對單獨存在於一個 Column 的 ListItem（除以 numColumns !== 0）
      在 loadMore 會重新創造 ListItem 的問題
    */
    if (hasMoreItems) {
      while (cloneItems.length % numColumns !== 0) {
        cloneItems.pop();
      }
    }

    const result = listWithPlaceholder.makeResultIdsWithFetching(
      cloneItems,
      isFetchingRecommendProducts
    );

    return result;
  });

export const getRecommendProductsDataByKey = (
  key: keyof State,
  defaultValue?: any
) => {
  return createSelector(
    recommendProductsRaw,
    (data: State) => data[key] ?? defaultValue
  );
};
