import { FC, createContext, useContext, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

export const OrientationContext = createContext(false);

export const OrientationProvider: FC = ({ children }) => {
  const { width, height } = useWindowDimensions();

  const isLandscape = useMemo(() => width > height, [width, height]);

  return (
    <OrientationContext.Provider value={isLandscape}>
      {children}
    </OrientationContext.Provider>
  );
};

export const useOrientation = () => {
  const context = useContext(OrientationContext);

  if (context === undefined) {
    throw new Error('useOrientation must be used within a OrientationProvider');
  }

  return context;
};
