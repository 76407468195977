import { createStandardAction } from 'typesafe-actions';

export interface SetGuideStatusPayload {
  guideKey: string;
}

export const setGuideStatus = createStandardAction(
  'verybuy/SET_GUIDE_STATUS'
)<SetGuideStatusPayload>();

export const clearGuideStatus = createStandardAction(
  'verybuy/CLEAR_GUIDE_STATUS'
)<SetGuideStatusPayload>();
