import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getFreeDeliveryActions } from '../actions/getFreeDeliveryActions';
import logoutAction from '../actions/logoutAction';
import { getFreeDelivery } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getFreeDeliveryActions.success,
  failure: getFreeDeliveryActions.failure,
};

type RequestActionType = ActionType<typeof getFreeDeliveryActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getFreeDeliveryEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getFreeDeliveryActions.request), getType(logoutAction)),
    switchMap((_action: RequestActionType) => {
      return getFreeDelivery()
        .then(response =>
          getFreeDeliveryActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) =>
          getFreeDeliveryActions.failure({ error })
        );
    })
  );
};

export default getFreeDeliveryEpic;
