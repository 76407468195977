import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { RecommendProductsResponse } from '../../types/RecommendProductsResponse';
import { getRecommendProductsActions } from '../actions/getRecommendProductsActions';
import { getRecommendProducts } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getRecommendProductsActions.success,
  failure: getRecommendProductsActions.failure,
};

type RequestActionTypes = ActionType<
  typeof getRecommendProductsActions.request
>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getRecommendProductsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getRecommendProductsActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { page, perPage, loadingType, success } = action.payload;

      return getRecommendProducts({ page, perPage })
        .then((response: AxiosResponse<RecommendProductsResponse>) => {
          const { data } = response;

          success(data.products);

          return getRecommendProductsActions.success({
            data,
            page,
            loadingType,
          });
        })
        .catch((error: AxiosError) =>
          getRecommendProductsActions.failure({
            error,
            requestAction: action,
          })
        );
    })
  );
};

export default getRecommendProductsEpic;
