export enum ItemTypes {
  TUANGO = 'tuango',
  USER_SUBMIT = 'user_submit',
  RECOMMEND = 'recommend',
}
export type CheckOutPages = 'cart' | 'checkout' | 'payment' | 'combo';
export type RoleTypes = 'ghost' | 'guest' | 'member';
export type ListLoadingTypes = 'new' | 'reload' | 'loadMore';
export type CheckoutSteps = 'begin_checkout';
export enum PriceRangeType {
  OmitRange = 'OmitRange',
  Range = 'Range',
}
export enum TransactionStatus {
  // NOTE：CX-264 後，Paid 定義有調整（新增 Stocking 取代），調整相關 code 時需注意
  Paid = 'paid',
  Unpaid = 'unpaid',
  Sent = 'sent',
  Expired = 'expired',
  Stocking = 'stocking',
}

export enum ReviewStatus {
  Disabled = 0,
  NotReviewed = 1,
  Done = 2,
}
export type ID = string;
export enum DeliveryMode {
  HOME_DELIVERY = 1,
  CONVENIENCE_STORE_PICKUP = 2,
}
export enum ReceiptType {
  TRIPLICATE = 1,
  DUPLICATE = 2,
  ZERO_ORDER = 3,
  FULL_REFUND = 4,
}
export enum WebLinkOpenType {
  SELF = '_self',
  BLANK = '_blank',
  PARENT = '_parent',
  TOP = '_top',
}

export enum MemberLoginType {
  EMAIL = 1,
  FACEBOOK = 2,
  APPLE = 6,
  LINE = 7,
}

export enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

/**
 * 客服信類別
 */
export enum SupportType {
  /**
   * 付款問題
   */
  PaymentIssue = 1,
  /**
   * 送貨問題
   */
  DeliveryIssue = 2,
  /**
   * 退貨問題
   */
  ReturnIssue = 3,
  /**
   * 勸敗點
   */
  VCoin = 4,
  /**
   * 其他
   */
  Other = 6,
  /**
   * V小編通知
   */
  VEditorNotice = 7,
  /**
   * 退現金通知
   */
  CashRefundNotice = 13,
  /**
   * 退款通知
   */
  RefundNotice = 14,
  /**
   * 退貨申請通知
   */
  ReturnApplicationNotice = 15,
  /**
   * 刷退通知
   */
  CreditCardCancelNotice = 16,
  /**
   * 刷卡未完成通知
   */
  CreditCardIncompleteNotice = 17,
  /**
   * 電子發票開立通知
   */
  ElectronicInvoiceNotice = 18,
  /**
   * 商品資訊
   */
  ProductInformation = 19,
  /**
   * 網站活動
   */
  Activity = 20,
  /**
   * 訂單問題
   */
  OrderIssue = 22,
  /**
   * 會員問題
   */
  MemberIssue = 23,
  /**
   * 延遲通知
   */
  DelayNotice = 24,
  /**
   * 缺貨通知
   */
  OutOfStockNotice = 25,
}

export enum NotificationType {
  /**
   * 建立訂單
   */
  TransactionCreated = '1',
  /**
   * 入帳成功
   */
  PaymentReceived = '2',
  /**
   * 刷卡未完成
   */
  CreditCardUncompleted = '3',
  /**
   * 催付
   */
  PaymentDemand = '4',
  /**
   * 出貨
   */
  Shipment = '5',
  /**
   * 到貨
   */
  ShipmentArrived = '6',
  /**
   * 二次超取
   */
  SecondCVS = '7',
  /**
   * 發票開立
   */
  CreateReceipt = '8',
  /**
   * 退現金
   */
  RefundCash = '9',
  /**
   * 退款
   */
  Refund = '10',
  /**
   * 退刷
   */
  RefundCreditCard = '11',
  /**
   * 缺退
   */
  OutOfStockRefund = '12',
  /**
   * 退貨
   */
  Return = '13',
  /**
   * 可評價
   */
  Review = '15',
  /**
   * 提醒用戶結帳
   */
  CartWakeUp = '16',
}

export enum NotificationGroup {
  All = '0',
  Transaction = '1',
}

export enum MailType {
  /**
   * 信件
   */
  Mail = '0',
  /**
   * 通知
   */
  Notification = '1',
}
