import { createStandardAction } from 'typesafe-actions';

export interface UpdateSuggestionActionPayload {
  suggestion: string;
}
export interface UpdateSelectionActionPayload {
  index: number;
  value: boolean;
}

export const updateSuggestionAction = createStandardAction(
  'verybuy/DELETE_ACCOUNT_UPDATE_SUGGESTION'
)<UpdateSuggestionActionPayload>();

export const updateSelectionAction = createStandardAction(
  'verybuy/DELETE_ACCOUNT_UPDATE_SELECTION'
)<UpdateSelectionActionPayload>();

export const clearFormAction = createStandardAction(
  'verybuy/DELETE_ACCOUNT_CLEAR_FORM'
)<void>();
