import { Alert } from 'react-native';
import safeAwait from 'safe-await';

import { isServer, isWebPlatform } from '../boot/utils';
import { translate } from '../i18n';
import firebase from '../utils/packages/firebase';
import { openSettings } from './RNPermission';

export function requestNotiPermissionAlert(contentKey: string) {
  const textPrefix = 'request-noti-permission-alert';

  Alert.alert(
    translate(`${textPrefix}.title`),
    translate(`${textPrefix}.${contentKey}`),
    [
      {
        text: translate(`${textPrefix}.negative-text`),
      },
      {
        text: translate(`${textPrefix}.positive-text`),
        onPress: () => {
          openSettings();
        },
      },
    ],
    { cancelable: false }
  );
}

export async function getIsDisableNotiPermission() {
  if (isWebPlatform || isServer) {
    return false;
  }

  const { messaging } = firebase;
  const [error, permission] = await safeAwait(messaging().hasPermission());

  if (error) {
    return false;
  }

  return (
    permission === messaging.AuthorizationStatus.NOT_DETERMINED ||
    permission === messaging.AuthorizationStatus.DENIED
  );
}
