import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { Platform, Share, ShareAction } from 'react-native';

import { isWebPlatform } from '../../boot/utils';
import { translate } from '../../i18n';
import Config from '../../utils/configs';
import * as navigationService from '../../utils/navigationService';
import routeUtils from '../../utils/routes';

export function getEventData(
  basicEventData = {},
  { error, data }: { error?: any; data?: ShareAction }
) {
  const successShareType =
    data?.action === Share.sharedAction && data?.activityType;
  const successData = {
    isSuccess: true,
    shareType: successShareType || 'unknown',
  };
  const errorData = { isSuccess: false, shareType: '' };
  let otherData;

  if (error) {
    console.log(error.message);
    otherData = errorData;
    // 因 web Share.share() 沒有回傳值，故沒 error 都視為成功。
  } else if (isWebPlatform) {
    otherData = successData;
  } else {
    otherData = data?.action === Share.sharedAction ? successData : errorData;
  }

  return { ...basicEventData, ...otherData };
}

interface PathParams {
  [key: string]: number | string;
}

export function getFullPath(
  routeInfo?,
  moreParams: PathParams = {},
  beLitePath = false
) {
  const { params, routeName } =
    routeInfo || navigationService.getActiveRoute() || {};

  if (!routeName) {
    return '';
  }

  const pathDefinition = beLitePath ? 'litePath' : 'fullPath';
  const liteParamKeys = Object.keys(moreParams).concat(['title']);

  return routeUtils.getPathAndParamsFromData(
    routeName,
    pickBy(
      {
        ...(params || {}),
        ...moreParams,
      },
      v => typeof v !== 'function'
    ),
    undefined,
    { liteParamKeys }
  )[pathDefinition];
}

export interface ShareMessageOpts {
  beLitePath?: boolean;
}
export function getShareMessage(
  leaderMsg?: string,
  moreParams: PathParams = {},
  shareOpts: ShareMessageOpts = {
    beLitePath: false,
  }
) {
  const routeInfo = navigationService.getActiveRoute() || {};
  const { beLitePath } = shareOpts;

  if (!routeInfo.routeName) {
    return '';
  }

  let path = '';
  // Support url only, because it is from webview
  const { url: pathFromRouteParams } = routeInfo.params || {};
  const theLeaderMsg = leaderMsg
    ? `${leaderMsg}\r\n`
    : translate('default-share-message');
  const theSearch = {
    utm_source: 'user_share',
    utm_medium: Platform.OS,
    ...moreParams,
  };

  if (pathFromRouteParams) {
    const theSearchString = queryString.stringify({
      ...theSearch,
    });

    path = `${pathFromRouteParams.split('?')[0]}?${theSearchString}`;
  } else {
    path = `${Config.REACT_APP_WEB_VIEW_URL}${getFullPath(
      routeInfo,
      theSearch,
      beLitePath
    )}`;
  }

  return `${theLeaderMsg}${path}`;
}
