import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import withAuth from '../../decorators/withAuth';
import { rootAction } from '../../redux';
import { getCatsActions } from '../../redux/actions/getCatsActions';
import { updateRemoteValuesAction } from '../../redux/actions/updateRemoteValuesAction';
import AppInit from './AppInit';

const {
  fetchCategories,
  getCategoryListActions,
  getMemberInfoActions,
  getBannersActions,
  getVBConfigActions,
  getTopKeywordsActions,
  getRecommendSubjectActions,
  updateMemberNotifications,
  getLandingPageListActions,
} = rootAction.actionsList;

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCategories: fetchCategories.request,
      getBanners: getBannersActions.request,
      getMemberInfo: getMemberInfoActions.request,
      getVBConfig: getVBConfigActions.request,
      getTopKeyWords: getTopKeywordsActions.request,
      getRecommendSubject: getRecommendSubjectActions.request,
      getCats: getCatsActions.request,
      getCategoryList: getCategoryListActions.request,
      getLandingPageList: getLandingPageListActions.request,
      updateMemberNotifications,
      updateRemoteValuesAction,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(withAuth(AppInit));
