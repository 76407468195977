import safeAwait from 'safe-await';

import { BrowseHistory } from '../types/BrowseHistories';
import isExists from './isExists';
import mmkvStorage from './mmkvStorage';

interface HistoriesRaw {
  data: BrowseHistory[];
}

export const LIMITE_HISTORIES = 100;
let historyMarketIdIndex: string[] = [];

export const _utils = {
  getProductHistoriesRawData() {
    return mmkvStorage.asAsyncStorage.getItem('productHistories');
  },
  setProductHistoriesToStorage(histories: HistoriesRaw) {
    return mmkvStorage.asAsyncStorage.setItem(
      'productHistories',
      JSON.stringify(histories)
    );
  },
  get historyMarketIdIndex() {
    return historyMarketIdIndex;
  },
};

export async function _getFullHistoryInfo(marketID?: string) {
  const [_err, dataFromStorage] = await safeAwait(
    _utils.getProductHistoriesRawData()
  );
  let histories = {} as HistoriesRaw;

  if (dataFromStorage) {
    histories = JSON.parse(dataFromStorage);
  }

  const historyData: BrowseHistory[] = histories.data || [];

  if (
    historyData.length !== historyMarketIdIndex.length ||
    (historyData.length && historyData[0].market_id !== historyMarketIdIndex[0])
  ) {
    historyMarketIdIndex = historyData.map(item => item.market_id);
  }

  const theIndex = marketID
    ? historyMarketIdIndex.findIndex(i => i === marketID)
    : -1;

  return {
    histories,
    theHistory: historyData[theIndex],
    theIndex,
  };
}

export async function getHistories() {
  const [_err, dataFromStorage] = await safeAwait(
    _utils.getProductHistoriesRawData()
  );
  let histories = {} as HistoriesRaw;

  if (dataFromStorage) {
    histories = JSON.parse(dataFromStorage);
  }

  return histories.data || [];
}

export async function updateHistory(productInfo) {
  const {
    marketing_product_id,
    id,
    type,
    max_price,
    min_price,
    original_max_price,
    original_min_price,
    image_url,
    title,
    is_offline,
    is_not_allowed,
    seller,
  } = productInfo;

  if (!isExists(marketing_product_id) || !image_url?.length) {
    return;
  }

  const newHistory = {
    id,
    image_url,
    is_offline,
    is_not_allowed,
    seller,
    market_id: marketing_product_id,
    max_price,
    min_price,
    original_max_price,
    original_min_price,
    product_type: type,
    title,
    update_at: Math.floor(Date.now() / 1000),
  };

  const { theIndex, histories } = await _getFullHistoryInfo(
    marketing_product_id
  );
  const historyData: BrowseHistory[] = histories.data || [];

  if (theIndex > -1) {
    historyData.splice(theIndex, 1);
    historyMarketIdIndex.splice(theIndex, 1);
  }
  historyData.unshift(newHistory);
  historyMarketIdIndex.unshift(marketing_product_id);

  const overLength = historyMarketIdIndex.length - LIMITE_HISTORIES;

  if (overLength > 0) {
    historyData.pop();
    historyMarketIdIndex.pop();
  }

  histories.data = historyData;

  await safeAwait(_utils.setProductHistoriesToStorage(histories));
}

export async function deleteHistory(marketID: string) {
  const { theIndex, histories } = await _getFullHistoryInfo(marketID);
  const historyData = histories.data || [];

  if (theIndex > -1) {
    historyData.splice(theIndex, 1);
    historyMarketIdIndex.splice(theIndex, 1);
  }

  histories.data = historyData;

  await safeAwait(_utils.setProductHistoriesToStorage(histories));
}

export async function _clearHistories() {
  await safeAwait(_utils.setProductHistoriesToStorage({ data: [] }));
}
