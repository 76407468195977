import produce from 'immer';
import shuffle from 'lodash/shuffle';
import { ActionType, getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import {
  AllowedLandingPage,
  PromotionalTheme,
} from '../../types/PromotionalThemesResponse';
import { actionsList } from '../rootAction';

const { getPromotionalThemesActions } = actionsList;

export interface State {
  isFetching: boolean;
  loadingType: ListLoadingTypes;
  hasError: boolean;
  error?: Error | null;
  data: Record<string, PromotionalTheme>;
  dataKeyList: string[];
}

export const defaultState: State = {
  isFetching: false,
  loadingType: 'new',
  hasError: false,
  data: {},
  dataKeyList: [],
};

const allowedLandingPage = Object.values(AllowedLandingPage).reduce(
  (acc, cur) => acc.add(cur),
  new Set()
);

const allowedItem = ({ landing_page: landingPage }: PromotionalTheme) => {
  return allowedLandingPage.has(landingPage);
};

export default produce(
  (draft: State, action: ActionType<typeof getPromotionalThemesActions>) => {
    switch (action.type) {
      case getType(getPromotionalThemesActions.request): {
        const { loadingType } = action.payload;

        draft.loadingType = loadingType;
        draft.isFetching = true;
        break;
      }
      case getType(getPromotionalThemesActions.success): {
        const { data } = action.payload;

        data.filter(allowedItem).forEach(item => {
          const id = `promote-${item.id}`;

          draft.data[id] = item;
          draft.data[id].image_urls = shuffle(draft.data[id].image_urls);
        });

        draft.dataKeyList = data
          .filter(allowedItem)
          .map(item => `promote-${item.id}`);

        draft.isFetching = false;
        draft.hasError = false;
        draft.error = null;

        break;
      }
      case getType(getPromotionalThemesActions.failure): {
        draft.isFetching = false;
        draft.hasError = true;
        draft.error = action.payload.error;
        break;
      }
      default: {
        break;
      }
    }
  },
  defaultState
);
