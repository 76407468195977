import 'react-native-gesture-handler';

import { NavigationActions, StackActions } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import { neutralWhite, warrior } from '../../constants/color';
import { NavigationType } from '../../constants/NavigationType';
import { isWebPlatform } from '../utils';
import { routeConfigMap } from './routeConfigMap';
import * as stackUtils from './stackUtils';

let currentRouteName;

const RootStack = createStackNavigator(routeConfigMap, {
  headerMode: 'screen',
  initialRouteName: 'Home',
  // web 登入流程會發生神秘的 security error
  // https://github.com/react-navigation/web/issues/27
  // https://reactnavigation.org/docs/en/switch-navigator.html
  // @ts-ignore
  backBehavior: 'history',
  headerLayoutPreset: 'center',
  cardStyle: { flex: 1, backgroundColor: neutralWhite },
  defaultNavigationOptions: {
    headerTitleStyle: {
      color: warrior,
    },
  },
  // disable stack animation if web
  // ref: https://stackoverflow.com/a/58614607
  ...(isWebPlatform
    ? {
        transitionConfig: () => ({
          transitionSpec: {
            duration: 0,
          },
        }),
      }
    : {}),
});

// https://github.com/react-navigation/react-navigation/issues/774
const prevGetStateForActionRootStack = RootStack.router.getStateForAction;

RootStack.router.getStateForAction = (action, state) => {
  // There are three pairs of actions and states while navigating:
  // actions: Navigate(has routeName), CompleteTransition and CompleteTransition
  // states: (isTransitioning: false), (isTransitioning: true) and (isTransitioning: false)
  if (state && action.type === NavigationActions.NAVIGATE) {
    currentRouteName = action.routeName;
  }

  if (state && action.type === StackActions.COMPLETE_TRANSITION) {
    const { index } = state;
    const checkRoot = item => item.routeName === 'Home';
    const checkRouteName = item => item.routeName === currentRouteName;
    const removeIndex = stackUtils.getRemoveStackIndex(
      state.routes,
      index,
      checkRoot,
      checkRouteName
    );

    if (removeIndex > -1 && !state.isTransitioning) {
      const routes =
        removeIndex > 0
          ? [
              ...state.routes.slice(0, removeIndex),
              ...state.routes.slice(removeIndex + 1),
            ]
          : state.routes.slice(1);

      return {
        ...state,
        routes,
        index: index - 1,
      };
    }
  }

  if (state && action.type === NavigationType.Replace) {
    const routes = state.routes.slice(0, state.routes.length - 1);

    routes.push(action);

    return {
      ...state,
      routes,
      index: routes.length - 1,
    };
  }

  return prevGetStateForActionRootStack(action, state);
};

export default RootStack;
