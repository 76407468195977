// https://reactnavigation.org/docs/en/navigating-without-navigation-prop.html
import fastDeepEqual from 'fast-deep-equal';
import md5 from 'md5';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import {
  NavigationActions,
  NavigationState,
  StackActions,
} from 'react-navigation';
import { BehaviorSubject } from 'rxjs';

import { isAndroid, isApp, isWebPlatform } from '../boot/utils';
import { NavigationType } from '../constants/NavigationType';
import { TabKey, isRouteWithTab } from '../constants/TabKey';
import { NavigationParams } from '../types/NavigationParams';
import browserHistoryPatch from '../utils/browserHistoryPatch';
import routeUtils from '../utils/routes';
import * as qsForComplexQueries from '../utils/routes/qsForComplexQueries';
import codePush from './packages/codePush';

const ScreenKeyMap: Map<string, string> = new Map();

export function saveTabScreenInfo(route) {
  if (isRouteWithTab(route.routeName)) {
    ScreenKeyMap.set(route.routeName, route);
  }
}

export function _clearTabScreenInfo() {
  ScreenKeyMap.clear();
}

// 產生 navigate/replace 用的 key
function createNavKey(routeName) {
  const defaultKey = `${routeName}-${Math.floor(Date.now() / 1000)}`;

  if (isWebPlatform) {
    return md5(defaultKey);
  }

  return defaultKey;
}

// navigationService.goBack 會用到，藉由 createNavKey 的 key 可避免 browser back 行為異常
// 這把 key 存放於 queryString 的 ts
function getNavOptsFromPath() {
  const opts = {} as any;

  if (isWebPlatform) {
    const params = queryString.parse(window.location.search);
    const key = params.ts || '';

    if (key.length) {
      opts.key = key;
    }
  }

  return opts;
}

let _resolve;
let _navigator;

export const navigatorPromise: any = new Promise(resolve => {
  _resolve = resolve;
});

navigatorPromise.resolve = _resolve;

export function setTopLevelNavigator(navigatorRef) {
  if (navigatorRef) {
    _navigator = navigatorRef;
    navigatorPromise.resolve();
  }
}

interface NavigationOptions {
  action?: any;
  key?: string;
}

const formatSpecialScenesQueries = {
  QueriesPage(data: { [key: string]: any }) {
    return queryString.parse(qsForComplexQueries.stringify(data));
  },
};

export function navigate(
  routeName,
  params: any = {},
  opts: NavigationOptions = {}
) {
  const defaultOpts: NavigationOptions = {
    key: createNavKey(routeName),
  };
  const navigateOpts: NavigationOptions = {
    ...defaultOpts,
    ...opts,
  };
  const allRoutes = _navigator?.state?.nav?.routes;
  let lastRoute;
  let isSameRoute = false;

  // 取最後一個 route 和即將前往的 route 做檢查，避免去到一樣的地方
  // 不能單純只用時間判斷
  if (allRoutes && allRoutes.length > 0) {
    lastRoute = allRoutes[allRoutes.length - 1];

    // 檢查 routeName 和參數是否相同
    isSameRoute =
      routeName === lastRoute.routeName &&
      fastDeepEqual(params, lastRoute.params);
  }

  if (isSameRoute) {
    return lastRoute.key;
  }

  if (isRouteWithTab(routeName)) {
    return reset(routeName, params);
  }

  const formatedParams = formatSpecialScenesQueries[routeName]
    ? formatSpecialScenesQueries[routeName](params)
    : params;
  const navigateAction = NavigationActions.navigate({
    ...navigateOpts,
    routeName,
    params: formatedParams,
  });

  navigatorPromise.then(() => {
    _navigator.dispatch(navigateAction);

    if (!params[NavigationParams.BackToPageKey]) {
      browserHistoryPatch.setHash(navigateOpts.key);
    }
  });

  return navigateOpts.key;
}

// https://reactnavigation.org/docs/4.x/navigation-actions/
// action navigate with key: back if it is exist already
// action back with key: back from the key
type BackKey = undefined | string;
export function goBack(routeName?: BackKey, params: any = {}, key?) {
  const backOptsFromPath = getNavOptsFromPath();
  const stackIndex = _navigator?._navigation?.state?.index;
  const closeSelf = isWebPlatform
    ? routeUtils.getQueryFromPathByKey(location.search, 'closeSelf')
    : '';
  const routeInfo = !!routeName ? { routeName } : {};
  const backAction = !!routeName
    ? NavigationActions.navigate({
        params,
        ...backOptsFromPath,
        ...routeInfo,
      })
    : NavigationActions.back(backOptsFromPath);

  if (stackIndex === 0) {
    if (closeSelf === '1') {
      window.close();
    } else {
      reset();
    }
  } else {
    // react-navigation 的 goback/navigate 會讓 history 往前、不太符合實際狀況
    if (isWebPlatform && !routeName) {
      window.history.back();
    } else {
      const keyInfo = key ? { key } : {};

      _navigator.dispatch({ ...backAction, ...keyInfo });
    }
  }
}

export function replace(routeName, params: any = {}, shouldWebReplace = false) {
  if (isWebPlatform && shouldWebReplace) {
    browserHistoryPatch.redirectWithScene(routeName, params, shouldWebReplace);
  } else {
    const defaultOpts: NavigationOptions = {
      key: createNavKey(routeName),
    };

    _navigator.dispatch({
      ...defaultOpts,
      type: NavigationType.Replace,
      routeName,
      params,
    });

    if (!params[NavigationParams.BackToPageKey]) {
      browserHistoryPatch.setHash(defaultOpts.key);
    }
  }
}

export function reset(
  routeName = 'Home',
  params?,
  shouldPreserveState = false
) {
  if (isApp && routeName === 'Home') {
    /**
     * 用 codePush.restartApp 取代原本的 reset
     * - 參數帶 true 代表只在有更新檔時會重啟 app，沒更新檔時不會做任何事情
     * - 重啟時會順便安裝 codePush 的更新檔，使用者會短暫看到 native background
     *
     * https://github.com/microsoft/react-native-code-push/blob/master/docs/api-js.md#codepushrestartapp
     */
    if (!isAndroid) {
      codePush.restartApp(true);
    }
  }

  const preserveScreenInfo: any =
    shouldPreserveState && ScreenKeyMap.has(routeName)
      ? ScreenKeyMap.get(routeName)
      : {};
  let index = 0;

  const actions = [
    NavigationActions.navigate({
      routeName,
      params: preserveScreenInfo.params || params,
      key: preserveScreenInfo.key,
    }),
  ];

  // * 換成 stack 後，reset 仍需保留 Home 避免只剩下當前頁面
  // * performance
  //   首頁組成複雜效能很差，轉為 stack 後更差
  //   因此 reset 時，在 shouldPreserveState 的時會保留首頁
  if (routeName !== TabKey.Home) {
    const preserveHomeInfo: any =
      shouldPreserveState && ScreenKeyMap.has(TabKey.Home)
        ? ScreenKeyMap.get(TabKey.Home)
        : {};

    actions.unshift(
      NavigationActions.navigate({
        routeName: TabKey.Home,
        key: preserveHomeInfo.key,
      })
    );

    index = 1;
  }

  const resetAction = StackActions.reset({
    index,
    actions,
  });

  _navigator.dispatch(resetAction);
}

export function resetWithPreservedState(routeName = 'Home', params?) {
  reset(routeName, params, true);
}

interface RefreshParams {
  [key: string]: any;
}
export function refresh(params: RefreshParams = {}) {
  const activeRouteKey = getActiveRoute(_navigator.state.nav, 'key');

  _navigator.dispatch(
    NavigationActions.setParams({
      key: activeRouteKey,
      params,
    })
  );
}

// gets the current screen from navigation state
export function getActiveRoute(
  navigationState?: NavigationState,
  attrName: string = ''
) {
  if (!navigationState) {
    const currentState = _navigator?.state?.nav;

    return currentState ? getActiveRoute(currentState, attrName) : null;
  }
  const route = navigationState.routes[navigationState.index];
  // dive into nested navigators

  if (route.routes) {
    return getActiveRoute(route, attrName);
  }

  if (attrName && route[attrName]) {
    return route[attrName];
  }

  return route;
}

interface NavState extends NavigationState {
  routeName: string;
}

// 註冊取得 screen 的改變
// - App 層 onNavigationStateChange 的時候會改變狀態
// - 可參考 src/components/CheckoutBar/CheckboxChooseAll 註冊取得 screen 的改變
interface ScreenSubjectValue {
  currentScreen: NavState | undefined;
  prevScreen: NavState | undefined;
}
export const screenSubject$: BehaviorSubject<ScreenSubjectValue> =
  new BehaviorSubject<ScreenSubjectValue>({
    currentScreen: undefined,
    prevScreen: undefined,
  });

export const useScreenSubject = () => {
  const [state, setState] = useState<ScreenSubjectValue>({
    currentScreen: undefined,
    prevScreen: undefined,
  });

  useEffect(() => {
    const subscription = screenSubject$.subscribe(setState);

    return () => {
      subscription.unsubscribe();
    };
  }, [setState]);

  return state;
};

// navbar 和 component 溝通的橋樑
// from: `${routeKey}-component`, `${routeKey}-navright` （navright 可替換 navleft...etc）
// data: 彼此需要的資料
export const navbarSubject$ = new BehaviorSubject({
  from: '',
  data: {},
});
