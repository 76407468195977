import { createSelector } from 'reselect';

import { TopKeywords } from '../../types/TopKeywordsResponse';
import { State } from '../reducers/topKeywordsReducer';
import { RootState } from '../rootReducer';

const reducer = (state: RootState) => state.topKeywordsReducer;

export const getTopKeywordsData = <
  T = number | boolean | Error | TopKeywords[]
>(
  key: keyof State,
  defaultValue: T
) => {
  return createSelector(reducer, data => {
    return (data?.[key] ?? defaultValue) as T;
  });
};

export const makePlaceholder = () => {
  return createSelector(
    getTopKeywordsData('data', [] as TopKeywords[]),
    getTopKeywordsData('placeholderIndex', -1),
    (data, placeholderIndex) => {
      return (data[placeholderIndex] || null) as TopKeywords | null;
    }
  );
};
