import * as palette from './color';

export const defaultTheme = {
  palette,
};

declare module 'styled-components' {
  export interface DefaultTheme {
    palette: typeof palette;
  }
}
