import { Component } from 'react';
import {
  GestureResponderEvent,
  TouchableWithoutFeedbackProps,
} from 'react-native';

import { isWebPlatform } from '../../boot/utils';
import { translate } from '../../i18n';
import { trackAllHeatMap } from '../../utils/sensorsHelpers';
import defaultStyles from './default.style';
import primaryStyles from './primary.style';
import secondaryStyles from './secondary.style';

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  DEFAULT,
}

interface Props extends TouchableWithoutFeedbackProps {
  testID?: string;
  text?: string;
  buttonType?: ButtonType;
  withRadius?: boolean;
  paddingVertical?: number;
  paddingHorizontal?: number;
  isLoading?: boolean;
}

interface State {
  pressed: boolean;
}

export default class CommonButton extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    withRadius: true,
    isLoading: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      pressed: false,
    };
  }

  static getStyledComponent(buttonType?: ButtonType) {
    switch (buttonType) {
      case ButtonType.PRIMARY:
        return primaryStyles;

      case ButtonType.SECONDARY:
        return secondaryStyles;

      case ButtonType.DEFAULT:
      default:
        return defaultStyles;
    }
  }

  onHideUnderlay = () => {
    this.setState({ pressed: false });
  };

  onShowUnderlay = () => {
    this.setState({ pressed: true });
  };

  onPress = (e: GestureResponderEvent) => {
    const { onPress } = this.props;

    if (onPress) {
      onPress(e);
    }

    if (isWebPlatform) {
      trackAllHeatMap(e.currentTarget);
    }
  };

  renderButtonContent = () => {
    const { pressed } = this.state;
    const { text, buttonType, disabled, isLoading } = this.props;
    const buttonText = text || translate('submit');
    const S = CommonButton.getStyledComponent(buttonType);

    return (
      <>
        {/* @ts-expect-error incorrect theme implementation */}
        {isLoading && <S.LoadingView testID="loadingView" size="small" />}
        <S.Text pressed={pressed} disabled={disabled} isLoading={isLoading}>
          {buttonText}
        </S.Text>
      </>
    );
  };

  render() {
    const { pressed } = this.state;
    const {
      testID,
      buttonType,
      isLoading,
      withRadius,
      paddingVertical,
      paddingHorizontal,
      onPress,
      ...otherProps
    } = this.props;
    const S = CommonButton.getStyledComponent(buttonType);

    return (
      <S.Button
        withRadius={withRadius}
        paddingVertical={paddingVertical}
        paddingHorizontal={paddingHorizontal}
        disabled={isLoading}
        underlayColor={
          buttonType === ButtonType.PRIMARY ? '#D9454D' : 'transparent'
        }
        activeOpacity={1}
        onHideUnderlay={this.onHideUnderlay}
        onShowUnderlay={this.onShowUnderlay}
        onPress={this.onPress}
        pressed={pressed}
        testID={testID || 'commonButton'}
        {...otherProps}
      >
        {this.renderButtonContent()}
      </S.Button>
    );
  }
}
