import dayjs from 'dayjs';

import { translate } from '../../../i18n';
import Config from '../../../utils/configs';
import routeUtils from '../../../utils/routes';
import { makeBreadcrumbJsonLd, makeCatById } from '../catsSelector';
import { itemContentAttrSelector } from '../itemContentSelector';
import {
  makeProductByIdIsInvalidSelector,
  makeProductByIdSelector,
} from '../productById';
import { makeDefaultCurrencyData } from '../VBConfigSelector';

const getDateWithMonthsAfter = (months = 0) => {
  const currentDate = dayjs().add(months, 'month').format('YYYY-MM-DD');

  return currentDate || '';
};

const defaultURL = Config.REACT_APP_WEB_VIEW_URL;
const schemaURL = 'http://schema.org';

export const defaultProps = {
  id: '',
  title: '',
  image_url: '',
  min_price: 0,
};

export const makeItemAggregateRating = (state, id: string) => {
  const reviews = itemContentAttrSelector(id, 'reviews')(state);

  if (!reviews || reviews?.review_count <= 0) {
    return {};
  }

  return {
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: reviews.average_score,
      reviewCount: reviews.review_count,
    },
  };
};

export const getItemDetailJsonLd = (state, id: string, tags: string[] = []) => {
  const data = {
    ...defaultProps,
    ...makeProductByIdSelector(id as string)(state),
  };
  const dataID = data.id;
  const dataTitle = data.title;
  const dataImageUrl = data.image_url;
  const dataMinPrice = data.min_price;
  const colors = itemContentAttrSelector(id, 'color')(state);
  const sizes = itemContentAttrSelector(id, 'size')(state);
  const isInvalid = makeProductByIdIsInvalidSelector(id)(state);
  const parentCateName = makeCatById(data.parent_category_id)(state)?.name;
  const childCateName = makeCatById(data.child_category_id)(state)?.name;
  const defaultTitle = translate('VeryBuy-title');
  const currency = makeDefaultCurrencyData()(state);

  if (!dataID) return null;

  const itemPageUrl =
    defaultURL +
    routeUtils.compileToPath('ItemPage', {
      id: dataID,
    });

  const availability = isInvalid ? 'OutOfStock' : 'InStock';

  const color = colors?.props.map(item => item.name).join('/') || '';
  const size = sizes?.props.map(item => item.name).join('/') || '';

  const webpageJsonLd = {
    '@context': schemaURL,
    '@type': 'WebPage',
    potentialAction: {
      '@type': 'ViewAction',
      target: [
        itemPageUrl,
        `android-app://tw.com.verybuy/verybuy201109/${itemPageUrl}`,
        `ios-app://572399530/verybuy201109/${itemPageUrl}`,
      ],
    },
    speakable: {
      '@type': 'SpeakableSpecification',
      xPath: [
        'html/head/title',
        'html/head/meta[@name=\\"description\\"]/@content',
      ],
    },
    url: itemPageUrl,
  };

  const breadcrumbJsonld = makeBreadcrumbJsonLd(data.child_category_id)(state);
  const theListItem = {
    '@type': 'ListItem',
    position: breadcrumbJsonld.itemListElement?.length,
    name: dataTitle,
  };
  const itemBreadcrumbListJsonld = breadcrumbJsonld.itemListElement
    ? {
        ...breadcrumbJsonld,
        itemListElement: [...breadcrumbJsonld.itemListElement, theListItem],
      }
    : {};

  const aggregateRating = makeItemAggregateRating(state, id);
  const productJsonLd = {
    '@context': schemaURL,
    '@type': 'Product',
    name: dataTitle,
    description: dataTitle,
    image: dataImageUrl,
    productID: dataID,
    url: itemPageUrl,
    ...aggregateRating,
    offers: {
      '@type': 'Offer',
      price: `${dataMinPrice}`,
      priceCurrency: currency?.code,
      availability: availability,
      priceValidUntil: getDateWithMonthsAfter(2),
      url: itemPageUrl,
      seller: {
        '@type': 'Organization',
        name: defaultTitle,
      },
      shippingDetails: {
        '@type': 'OfferShippingDetails',
        shippingRate: {
          '@type': 'MonetaryAmount',
          currency: currency?.code,
        },
      },
    },
    category: `${parentCateName}/${childCateName}/`,
    color: color || size,
    brand: {
      '@type': 'Store',
      name: defaultTitle,
      logo: 'https://img.ssl.verybuy.cc/resource/icon_40x40@3x.png',
      image: 'https://img.ssl.verybuy.cc/resource/icon_40x40@3x.png',
      url: defaultURL,
      address:
        'No. 40, Sec. 3, Zhongshan N. Rd., Zhongshan Dist., Taipei City 104, Taiwan (R.O.C.)',
      priceRange: '$$',
      paymentAccepted: 'TWD',
      openingHours: 'Mo,Tu,We,Th,Fr 10:00-19:00',
      event: {
        '@type': 'Event',
        name: '首次訂購付款後再送 100 元購物金。下載 APP 再享天天 490 免運！',
        description: '首購',
        startDate: getDateWithMonthsAfter(),
        endDate: getDateWithMonthsAfter(1),
        url: defaultURL,
        image: 'https://img.ssl.verybuy.cc/resource/icon_40x40@3x.png',
        location: {
          name: defaultTitle,
          address:
            'No. 40, Sec. 3, Zhongshan N. Rd., Zhongshan Dist., Taipei City 104, Taiwan (R.O.C.)',
        },
        offers: {
          url: defaultURL,
          price: '80',
          priceCurrency: 'TWD',
          availability: 'InStock',
          validFrom: getDateWithMonthsAfter(1),
        },
        organizer: {
          name: defaultTitle,
          url: defaultURL,
        },
        eventStatus: 'https://schema.org/eventStatus',
        eventAttendanceMode: 'https://schema.org/MixedEventAttendanceMode',
        performer: {
          name: defaultTitle,
        },
      },
    },
    keywords: (tags || []).join(' '),
  };

  const imageObjectJsonLd = {
    '@context': schemaURL,
    '@type': 'ImageObject',
    contentUrl: dataImageUrl,
    name: dataTitle,
  };

  const organizationJsonLd = {
    '@context': schemaURL,
    '@type': 'Organization',
    sameAs: [
      'https://www.facebook.com/verybuy/',
      'https://www.instagram.com/verybuy_tw/',
    ],
    name: defaultTitle,
    url: defaultURL,
    logo: 'https://img.ssl.verybuy.cc/resource/icon_40x40@3x.png',
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer service',
    },
  };

  const mobileAndroidJsonLd = {
    '@context': schemaURL,
    '@type': 'MobileApplication',
    name: defaultTitle,
    description:
      'VeryBuy Fashion 是專注於女性多風格時尚的購物平台，你的日常、通勤、約會穿搭 VeryBuy 全都包，快來 VeryBuy 輕鬆搭配你的每一天！妳可以透過 APP 輕鬆下單，並追蹤貨況與收到貨況更新通知，線上聯繫客服也是好方便快來逛逛吧！若有任何寶貴意見，請到 VeryBuy Fashion 網站告訴我們，讓我們馬上幫妳解決改善！https://www.verybuy.cc/',
    operatingSystem: 'ANDROID',
    applicationCategory: 'ShoppingApplication',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.2',
      ratingCount: '2043',
    },
    offers: {
      '@type': 'Offer',
      price: '0.0',
      priceCurrency: 'TWD',
      availability: 'InStock',
    },
    image: 'https://img.ssl.verybuy.cc/resource/icon_40x40@3x.png',
    url: 'https://play.google.com/store/apps/details?id=tw.com.verybuy&hl=zh_TW',
    downloadUrl:
      'https://play.google.com/store/apps/details?id=tw.com.verybuy&hl=zh_TW',
  };

  const mobileIOSJsonLd = {
    '@context': schemaURL,
    '@type': 'MobileApplication',
    name: defaultTitle,
    description:
      'VeryBuy Fashion 是一個海外購物服務，妳可以透過app輕鬆下單300萬件海外直送商品，並追蹤貨況與收到貨況更新通知，線上聯繫客服也是好方便快來逛逛吧！海外購物選擇多多，跟小編們一起發掘台灣找不到的驚喜商品！若有任何寶貴意見，請到我們的粉絲團告訴我們，讓我們馬上幫妳解決改善！https://www.facebook.com/verybuy @verybuy 也順手在instagram上追蹤我們',
    operatingSystem: 'IOS',
    applicationCategory: 'ShoppingApplication',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '3.9',
      ratingCount: '858',
    },
    offers: {
      '@type': 'Offer',
      price: '0.0',
      priceCurrency: 'TWD',
      availability: 'InStock',
    },
    image: 'https://img.ssl.verybuy.cc/resource/icon_40x40@3x.png',
    url: 'https://apps.apple.com/tw/app/verybuy/id572399530',
    downloadUrl: 'https://apps.apple.com/tw/app/verybuy/id572399530',
  };

  const jsonld = [
    webpageJsonLd,
    productJsonLd,
    imageObjectJsonLd,
    organizationJsonLd,
    mobileAndroidJsonLd,
    mobileIOSJsonLd,
    itemBreadcrumbListJsonld,
  ];

  return jsonld;
};
