import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';

import IconCart from '../../../assets/svg/IconCart/IconCart';
import { neutralDark } from '../../../constants/color';
import { makeProductSalesAmountByIdSelector } from '../../../redux/selectors/productById';
import { useSelector } from '../../../redux/utils';

interface Props {
  id: string;
}

export const getDisplayedSalesAmount = (quantity: number): number => {
  switch (true) {
    case quantity >= 300:
      return 300;
    case quantity >= 100:
      return 100;
    case quantity >= 70:
      return 70;
    case quantity >= 50:
      return 50;
    case quantity >= 30:
      return 30;
    case quantity >= 10:
      return 10;
    case quantity >= 3:
      return 3;
    default:
      return 0;
  }
};

export default function SalesAmount({ id }: Props) {
  const { t } = useTranslation();
  const quantity = useSelector(makeProductSalesAmountByIdSelector(id));
  const amount = getDisplayedSalesAmount(quantity);

  if (!amount) {
    return <S.Space testID="space" />;
  }

  return (
    <S.Wrapper>
      <IconCart size={14} fillColor={neutralDark} />
      <S.Text>{t('sales-amount', { amount })}</S.Text>
    </S.Wrapper>
  );
}

const S = {
  Wrapper: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
  `,
  Text: styled.Text`
    color: ${({ theme }) => theme.palette.neutralDark};
    font-size: 12px;
    line-height: 17px;
    margin-left: 4px;
  `,
  Space: styled.View`
    height: 17px;
  `,
};
