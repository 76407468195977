import hoistStatics from 'hoist-non-react-statics';
import type { ComponentType } from 'react';
import { forwardRef } from 'react';
import { useIsFocused } from 'react-navigation-hooks';

export interface WithIsFocusedProps {
  isFocused: boolean;
}

type PropsWithoutIsFocused<P> = Omit<P, keyof WithIsFocusedProps>;

export default function withIsFocused<P, T = {}>(Comp: ComponentType<P>) {
  const WarpComp = (props, ref) => {
    const isFocused = useIsFocused();

    return <Comp ref={ref} isFocused={isFocused} {...props} />;
  };

  return hoistStatics(forwardRef<T, PropsWithoutIsFocused<P>>(WarpComp), Comp);
}
