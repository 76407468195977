import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Product } from '../../types/TopRated';

export interface TopRatedPayload {
  page: number;
  loadingType: ListLoadingTypes;
  success: (products: Product[]) => void;
  per_page?: number;
}

export interface TopRatedSuccessPayload {
  items: Product[];
  page: number;
  loadingType: ListLoadingTypes;
}

export interface TopRatedFailPayload {
  error: Error;
}

export const getTopRatedActions = createAsyncAction(
  'verybuy/GET_TOP_RATED',
  'verybuy/GET_TOP_RATED_SUCCESS',
  'verybuy/GET_TOP_RATED_FAIL'
)<TopRatedPayload, TopRatedSuccessPayload, TopRatedFailPayload>();
