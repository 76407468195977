import { globalNormalDialogProxy } from '../elements/Dialog/GlobalNormalDialog';
import { translate } from '../i18n';
import Config from './configs';
import { getActiveRoute } from './navigationService';

/**
 * Warning: this is workaround to force logout www.verybuy.cc
 * '/member/empty_logout' will remove VerybuySession and do logout
 */
export const ForceLogoutClassicWeb = () => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');

    img.src = `${Config.REACT_APP_WEB_VIEW_URL}/member/empty_logout`;
    img.onload = resolve;
    img.onerror = reject;
  });
};

interface LoginOrRegisterDialogArgs {
  contentText: string;
  lightboxName: string;
  positiveText?: string;
  negativeText?: string;
  lightboxContent?: string;
  handleDialogNegativeAction?: () => void;
}

export const openLoginOrRegisterFailDialog = (
  args: LoginOrRegisterDialogArgs
) => {
  const {
    contentText,
    lightboxName,
    lightboxContent,
    negativeText,
    positiveText,
    handleDialogNegativeAction,
  } = args;
  const { routeName } = getActiveRoute() || {};

  globalNormalDialogProxy.open({
    positiveText: positiveText || translate('lightboxLoading-error-positive'),
    negativeText,
    contentText,
    lightboxName,
    lightboxContent: lightboxContent || contentText,
    lightboxPage: routeName,
    handleDialogNegativeAction,
  });
};
