import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { Item } from '../../types/CategoryListResponse';
import { convertArrayToObject } from '../../utils/arrayHandler';
import { actionsList } from '../rootAction';

const { getCategoryListActions } = actionsList;

export interface State {
  isFetching: boolean;
  data: Record<string, Item>;
  parentIds: string[];
}

export const defaultState: State = {
  isFetching: false,
  data: {},
  parentIds: [],
};

type Action = typeof getCategoryListActions;

export default produce((draft: State, action: ActionType<Action>) => {
  switch (action.type) {
    case getType(getCategoryListActions.request): {
      draft.isFetching = true;
      break;
    }
    case getType(getCategoryListActions.success): {
      draft.isFetching = false;
      draft.parentIds = action.payload.data.items.map(({ id }) => id);
      draft.data = convertArrayToObject(
        action.payload.data.items,
        'id',
        draft.data
      );
      break;
    }
    case getType(getCategoryListActions.failure): {
      draft.isFetching = false;
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
