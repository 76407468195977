import { FC } from 'react';
import { View } from 'react-native';

import IconNewNavRight from '../../assets/svg/IconNewNavRight';
import { warrior } from '../../constants/color';
import Touchable from '../../elements/Touchable';
import S from './style';

export interface Props {
  title: string;
  desc?: string;
  onPress: () => Promise<void> | void;
  index: number;
  warning?: boolean;
}
const FunctionButton: FC<Props> = ({
  title,
  onPress,
  desc,
  index,
  warning,
}) => {
  return (
    <S.RowItem>
      <Touchable onPress={onPress}>
        <S.FlexRow>
          <View>
            <S.Label warning={warning}>{`${index + 1}. ${title}`}</S.Label>
            {!!desc && <S.Desc warning={warning}>{desc}</S.Desc>}
          </View>
          <IconNewNavRight size={12} fillColor={warrior} />
        </S.FlexRow>
      </Touchable>
    </S.RowItem>
  );
};

FunctionButton.defaultProps = {
  desc: '',
  warning: false,
};
export default FunctionButton;
