import queryString from 'query-string';
import { useEffect } from 'react';
import styled from 'styled-components/native';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { coral, neutralWhite, warrior } from '../../constants/color';
import useActions from '../../hooks/useActions';
import { translate } from '../../i18n';
import { logAnalyticsEvent } from '../../redux/actions/analyticsLog';
import Touchable from '../Touchable';

function handleWebReload() {
  const parsed = queryString.parse(window.location.search);

  if (!parsed.reloaded) {
    parsed.reloaded = '1';

    const stringified = queryString.stringify(parsed);

    window.location.search = `?${stringified}`;
  }
}

// ref: https://mitchgavan.com/code-splitting-react-safely/
// solution 3
export default function ErrorFallback({ error }) {
  const logEvent = useActions(logAnalyticsEvent);

  useEffect(() => {
    const errorMsg = error?.message;

    if (!errorMsg) {
      return;
    }

    const chunkFailedRegex = /Loading chunk [\d]+ failed/;
    const isChunkError = chunkFailedRegex.test(errorMsg);

    if (isChunkError) {
      handleWebReload();
    } else {
      logEvent({ logEvent: AnalyticsEvent.ViewError, data: { error } });
    }
  }, [error]);

  return (
    <S.Container>
      <S.Text>Something went wrong.</S.Text>
      <S.Touchable accessibilityRole="link" href="/">
        <S.BtnText>{translate('back-to-home-page')}</S.BtnText>
      </S.Touchable>
    </S.Container>
  );
}

const S = {
  Container: styled.View`
    flex: 1 auto;
    align-items: center;
    justify-content: center;
    background-color: ${neutralWhite};
  `,
  Text: styled.Text`
    font-size: 18px;
    margin-bottom: 20px;
    color: ${warrior};
  `,
  Touchable: styled(Touchable)`
    padding: 12px 20px;
    border: solid 1px ${coral};
    border-radius: 3px;
    background-color: transparent;
    justify-content: center;
    align-items: center;
  `,
  BtnText: styled.Text`
    color: ${coral};
    font-size: 15px;
    line-height: 21px;
  `,
};
