import { parseUrl, stringify } from 'query-string';

let _CLID = '';

export default {
  setClidFromURL: (url: string): string => {
    if (!url) {
      return url;
    }
    const params = parseUrl(url);

    if (!params.query) {
      return url;
    }

    const { clid, ...restParams } = params.query;

    // Note: filter clid parameters and save line id
    // https://verybuycc.atlassian.net/browse/FT-96
    if (typeof clid !== 'string' || !clid) {
      return url;
    }
    // save in BrowserHistoryPatch instance
    _CLID = clid as string;
    const _url = url.split('?');

    return `${_url[0]}?${stringify(restParams)}`;
  },
  setClid: clid => {
    _CLID = clid;
  },
  getClid: () => _CLID,
};
