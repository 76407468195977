import { FunctionComponent, memo } from 'react';

import { translate } from '../../i18n';
import langHandler from '../../i18n/langHandler';
import { S } from './styles';

export const FirstLoveDialog: FunctionComponent = memo(() => {
  const imageSource = langHandler.get().includes('zh')
    ? require('./assets/img_favorite_initial.png')
    : require('./assets/img_favorite_initial_en.png');

  return (
    <S.Wrapper>
      <S.GuideImage source={imageSource} />
      <S.InfoWrapper>
        <S.TitleText>{translate('collection-successful')}</S.TitleText>
        <S.ContentText>{translate('first-love-dialog-content')}</S.ContentText>
      </S.InfoWrapper>
    </S.Wrapper>
  );
});
