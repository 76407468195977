import { getType } from 'typesafe-actions';

import {
  CommonErrors,
  CustomErrors,
  MemberErrors,
  PaymentErrors,
} from '../../constants/VBErrorCode';
import { translate } from '../../i18n';
import { UpdateApiCommonErrorPayload } from '../actions/apiCommonErrorActions';
import { actionsList } from '../rootAction';

interface State {
  contentText: string;
  positiveText?: string;
  negativeText?: string;
}

const { updateApiCommonError, clearApiCommonError } = actionsList;
const INITIAL_STATE = {
  contentText: '',
};

function processContentDirectly(payload, _errorCode): State {
  const { contentText, positiveText, negativeText } = payload;

  if (contentText) {
    return {
      contentText,
      positiveText,
      negativeText,
    };
  }

  return INITIAL_STATE as State;
}

function process(
  payload: UpdateApiCommonErrorPayload,
  errorCode: CommonErrors | CustomErrors | MemberErrors | PaymentErrors
): State {
  const { role } = payload;

  switch (errorCode) {
    case CommonErrors.ERR_UNAUTHORIZED_USER: {
      const result = {} as State;

      if (role === 'member') {
        result.contentText = translate('askLogin-desc');
        result.positiveText = translate('askLogin-positive');
        result.negativeText = translate('later');
      } else {
        result.contentText = translate('guest-back-desc');
        result.positiveText = translate('refresh');
      }

      return result;
    }

    case MemberErrors.ERR_MEMBER_MEMBERS_ONLY:
    case CustomErrors.GET_PRIME_RESPONSE_ERROR: {
      return {
        contentText: translate('api-common-error-msg-try-again', { errorCode }),
        positiveText: translate('alert-default-positive-text'),
      };
    }

    case PaymentErrors.ERR_PAYMENT_ERROR: {
      return {
        contentText: translate('save-credit-card-create-error'),
        positiveText: translate('alert-default-positive-text'),
      };
    }

    case CommonErrors.ERR_SYSTEM_ERROR: {
      return {
        contentText: translate('api-common-error-msg', { errorCode }),
        positiveText: translate('go-to-home-page'),
      };
    }

    case MemberErrors.ERR_MEMBER_LOGIN_FAILED: {
      return {
        contentText: translate('login-fail-default-desc'),
        positiveText: translate('lightboxLoading-error-positive'),
      };
    }
    case CommonErrors.ERR_INVALID_ARGUMENT:
    default:
      return INITIAL_STATE as State;
  }
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case getType(updateApiCommonError): {
      const { errorCode } = action.payload;
      const { contentText } = action.payload;
      const processMethod = contentText ? processContentDirectly : process;

      return {
        ...state,
        ...processMethod(action.payload, errorCode),
        errorCode,
      };
    }

    case getType(clearApiCommonError): {
      return INITIAL_STATE;
    }

    default: {
      return state;
    }
  }
}
