import { Linking } from 'react-native';

import { getTitleByType } from '../../components/MailboxDetailPage/utils';
import { NotificationType } from '../../types/Common';
import { NavigationParams } from '../../types/NavigationParams';
import { FirebaseMessagingTypes } from '../packages/firebase';
import routeUtils from '../routes';
import { PushNotificationType } from './types';

const gotoHomePage = async () => {
  const { reset } = await import('../navigationService');

  reset();
};

const dynamicNavigate = async (scene: string, params) => {
  const { navigate } = await import('../navigationService');

  navigate(scene, params);
};

const dynamicPageNavigation = async (url: string) => {
  const { default: pageNavigation } = await import('../pageNavigation');

  pageNavigation(url);
};

const parseMessageData = ({
  id,
  queryText,
  topIds,
  transaction_id,
  internalUrl,
  externalUrl,
  messageId,
  body,
  title,
  push_type,
  type,
  notification_id,
  support_id,
  support_type,
  sf_data,
}: Record<string, string | undefined>) => {
  let routePath = '';
  let bootstrap: () => Promise<void>;

  if (support_id) {
    const theRouteName = 'MailboxDetailPage';
    const theParams = {
      id: support_id,
      title: getTitleByType(Number(support_type)),
    };

    routePath = routeUtils.compileToPath(theRouteName, theParams);
    bootstrap = () => dynamicNavigate(theRouteName, theParams);
  } else if (sf_data) {
    const parsedSfData = JSON.parse(sf_data);

    if (parsedSfData?.sf_landing_type === 'LINK') {
      routePath = parsedSfData.sf_link_url;
      bootstrap = () => dynamicPageNavigation(parsedSfData.sf_link_url);
    } else {
      routePath = routeUtils.compileToPath('Home');
      bootstrap = () => gotoHomePage();
    }
  } else if (id) {
    const theRouteName = 'ItemPage';
    const theParams = { id };

    routePath = routeUtils.compileToPath(theRouteName, theParams);
    bootstrap = () => dynamicNavigate(theRouteName, theParams);
  } else if (queryText) {
    const theRouteName = topIds ? 'SearchResultPageTopId' : 'SearchResultPage';
    const theParams = { queryText, topIds };

    routePath = routeUtils.compileToPath(theRouteName, theParams);
    bootstrap = () => dynamicNavigate(theRouteName, theParams);
  } else if (transaction_id) {
    const transactionNo = transaction_id.replace('999239', 'A');
    const theRouteName = 'TransactionDetailPage';
    const theParams = {
      transactionID: transaction_id,
      transactionNo,
    };

    if (type === NotificationType.RefundCreditCard) {
      theParams[NavigationParams.ShouldScrollToProductList] = true;
    }

    routePath = routeUtils.compileToPath(theRouteName, theParams);
    bootstrap = () => dynamicNavigate(theRouteName, theParams);
  } else if (internalUrl) {
    routePath = internalUrl;
    bootstrap = () => dynamicPageNavigation(internalUrl);
  } else if (externalUrl) {
    routePath = externalUrl;
    bootstrap = () =>
      Linking.openURL(externalUrl).catch(err => {
        console.log('An error occurred', err);
        gotoHomePage();
      });
  } else {
    // everything else return to home page
    routePath = routeUtils.compileToPath('Home');
    bootstrap = () => gotoHomePage();
  }

  return {
    logData: {
      messageId,
      title,
      content: body,
      routePath,
      pushType: push_type,
      ...(notification_id ? { notificationId: notification_id } : {}),
    },
    bootstrap,
  };
};

const configureFCMMessage = (
  remoteMessage: FirebaseMessagingTypes.RemoteMessage
) => {
  const notification = remoteMessage?.notification || {};
  const { noti, fcm_options, notifee_options, image, ...data } =
    remoteMessage?.data || {};
  const _noti = typeof noti === 'string' ? JSON.parse(noti) : {};

  const messageId = remoteMessage?.messageId || 'UNKNOWN_MESSAGE_ID';
  const title = notification.title || _noti.title || 'UNKNOWN_PUSH_TITLE';
  const body = notification.body || _noti.body || 'UNKNOWN_PUSH_BODY';

  const parsedData = parseMessageData({
    messageId,
    title,
    body,
    ...data,
  });

  return parsedData;
};

const configureNotifeeEventDetail = eventDetail => {
  const notification = eventDetail?.notification || {};
  const messageId = notification.id || 'UNKNOWN_MESSAGE_ID';
  const title = notification.title || 'UNKNOWN_PUSH_TITLE';
  const body = notification.body || 'UNKNOWN_PUSH_BODY';
  const data = notification.data || {};

  const parsedData = parseMessageData({
    messageId,
    title,
    body,
    ...data,
  });

  return parsedData;
};

const configureIterableMessage = message => {
  const messageId = message.messageId || 'UNKNOWN_MESSAGE_ID';
  const { data } = message;
  const title = (data && data.title) || 'UNKNOWN_PUSH_TITLE';
  const body = (data && data.body) || 'UNKNOWN_PUSH_BODY';
  const { itbl } = data;
  const parsedItbl = JSON.parse(itbl);
  const defaultActionType = parsedItbl?.defaultAction?.type;
  const defaultActionData = parsedItbl?.defaultAction?.data;
  const internalUrl =
    defaultActionType === 'openUrl'
      ? defaultActionData
      : routeUtils.compileToPath('Home');

  const parsedData = parseMessageData({
    ...data,
    messageId,
    title,
    body,
    internalUrl,
  });

  return parsedData;
};

export default (remoteMessage, type: PushNotificationType) => {
  let configureFunction;

  switch (type) {
    case PushNotificationType.Firebase:
      configureFunction = configureFCMMessage;
      break;
    case PushNotificationType.Iterable:
      configureFunction = configureIterableMessage;
      break;
    case PushNotificationType.Notifee:
      configureFunction = configureNotifeeEventDetail;
      break;
    default:
      return {};
  }

  const { logData, bootstrap } = configureFunction(remoteMessage);

  return { logData, bootstrap };
};
