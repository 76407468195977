import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { Label } from '../../types/WordsLabelsResponse';
import { actionsList } from '../rootAction';

const { getWordsLabelsActions } = actionsList;

type QueryText = string;
type LabelKey = string;

export interface KeyState {
  isFetching: boolean;
  data: Record<LabelKey, Label>;
  dataIds: LabelKey[];
}
export type State = Record<QueryText, KeyState>;

export const defaultState: State = {};
export const defaultKeyState: KeyState = {
  isFetching: false,
  data: {},
  dataIds: [],
};

const produceByKey = produce(
  (draft: KeyState, action: ActionType<typeof getWordsLabelsActions>) => {
    switch (action.type) {
      case getType(getWordsLabelsActions.request): {
        draft.isFetching = true;
        break;
      }
      case getType(getWordsLabelsActions.success): {
        const { labels } = action.payload.data;

        draft.isFetching = false;
        draft.dataIds = labels.map(x => x.id);
        draft.data = labels.reduce((acc, cur) => {
          acc[cur.id] = cur;

          return acc;
        }, {});
        break;
      }
      case getType(getWordsLabelsActions.failure): {
        draft.isFetching = false;
        break;
      }
      default: {
        break;
      }
    }
  },
  defaultKeyState
);

export default produce(
  (draft: State, action: ActionType<typeof getWordsLabelsActions>) => {
    switch (action.type) {
      case getType(getWordsLabelsActions.request):
      case getType(getWordsLabelsActions.success):
      case getType(getWordsLabelsActions.failure): {
        const key = action.payload.queryText;

        draft[key] = produceByKey(draft[key], action);
        break;
      }
      default: {
        break;
      }
    }
  },
  defaultState
);
