import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { Linking } from 'react-native';

import { isWebPlatform } from '../boot/utils';
import { BannerType } from '../constants/bannerType';
import { translate } from '../i18n';
import { BannerDetail } from '../types/Banners';
import { NavigationParams } from '../types/NavigationParams';
import routeUtils from '../utils/routes';
import Config from './configs';
import illegalParamsHandler from './handleIllegalParams';
import isExists from './isExists';
import * as navigationService from './navigationService';
import { routesConfig } from './routes';

interface AllowedParams {
  pageType?: PAGE_TYPE;
  title?: string;
}

export enum PAGE_TYPE {
  CampaignThemePage = 'tuango/theme/',
  SearchResultPage = 'search/user_submit/',
  WelcomeLoginPage = 'member/welcome',
}

export const parseUrlForWebView = (parsedUrl: queryString.ParsedUrl) => {
  const destUrl = `${parsedUrl.url}?${queryString.stringify({
    ...parsedUrl.query,
    no_header: '1',
    no_footer: '1',
  })}`;

  return destUrl.replace(`${Config.REACT_APP_SCHEME}://`, 'https://');
};

function getSearchParam(url: string, re: RegExp) {
  const found = url.match(re);

  return found && found[1];
}

export function formatUrlToPath(url: string, isAbsolute = true): string {
  const path = url
    .replace(/^(.+)?\/\//, '')
    .split('/')
    .slice(1)
    .join('/');

  return `${isAbsolute ? '/' : ''}${path}`;
}

const navigateToSearchResultPage = (
  parsedUrl: queryString.ParsedUrl,
  campaignTitle?: string
) => {
  const { query } = parsedUrl;
  const { banner_id, ...others } = pick(query, [
    'banner_id',
    'maxPrice',
    'minPrice',
  ]);
  // 因正則用斜線 (/) 來切分字元，故結尾加個斜線會讓正則比較好寫。
  const formatUrl = decodeURIComponent(`${parsedUrl.url}/`);
  const queryText = getSearchParam(formatUrl, /search\/user_submit\/(.+?)\//);
  const topIds = getSearchParam(formatUrl, /top\/(.+?)\//);
  const sortBy = getSearchParam(formatUrl, /sort_by\/(.+?)\//) || query.sortBy;

  const activeRoute = navigationService.getActiveRoute() || {};
  const { routeName } = activeRoute;
  const navigateRoute = 'SearchResultPage';

  const navigateFunc =
    routeName === navigateRoute
      ? navigationService.replace
      : navigationService.navigate;

  const campaignParams = banner_id
    ? {
        banner_id,
        campaignTitle: campaignTitle || translate('loading'),
      }
    : {};
  // Filter undefined and null value
  const navigateParams = pickBy(
    { ...query, queryText, topIds, sortBy, ...others, ...campaignParams },
    v => isExists(v)
  );

  navigateFunc(navigateRoute, navigateParams);
};

/**
 * 在 pageNavigate 外請改用 checkPageNavigationAbility 作為檢查函式，否則無法支援 default switch case
 *
 * @deprecated
 */
const _checkUrlPageType = (url: string): PAGE_TYPE | undefined => {
  const pageTypeValues = Object.values(PAGE_TYPE);
  const pageTypeKeys = Object.keys(PAGE_TYPE);

  const matchIndex = pageTypeValues.findIndex(val => {
    const match = url.match(val);

    if (match) {
      return match.length > 0;
    }

    return false;
  });

  if (matchIndex !== -1) {
    return PAGE_TYPE[pageTypeKeys[matchIndex]];
  }

  return undefined;
};

export const bannerNavigation = (
  bannerData: BannerDetail,
  handleNavigate = pageNavigation
) => {
  const parsedUrl = queryString.parseUrl(bannerData.url);

  const { query, url } = parsedUrl;

  switch (bannerData.type) {
    case BannerType.Search: {
      const params = queryString.stringify({
        ...query,
        banner_id: `${bannerData.id}`,
      });
      const bannerUrl = queryString.parseUrl(`${url}?${params}`);

      navigateToSearchResultPage(bannerUrl, bannerData.title);
      break;
    }
    case BannerType.EventPage:
      navigationService.navigate('EventPage', query);
      break;
    case BannerType.WebView:
      const webViewUrl = parseUrlForWebView(parsedUrl);

      const bannerDisplayIdRegex = /tuango\/theme\/([a-z_\-0-9]+)[\/]?[\?]?/gi;

      const displayIdRes = bannerDisplayIdRegex.exec(webViewUrl);
      let displayId;

      if (displayIdRes) {
        displayId = displayIdRes[1];
      }

      navigationService.navigate('CampaignThemePage', {
        ...query,
        id: displayId,
        url: webViewUrl,
        title: bannerData.title,
      });
      break;
    case BannerType.InternalUrl: {
      handleNavigate(bannerData.url, { title: bannerData.title, ...query });

      break;
    }
    case BannerType.ExternalUrl: {
      Linking.openURL(bannerData.url).catch(console.log);

      break;
    }
    default:
      break;
  }
};

export const checkPageNavigationAbility = (
  url: string,
  navParams: AllowedParams = {}
) => {
  let result: { navigate: () => void; enabled: boolean } = {
    navigate: () => void 0,
    enabled: false,
  };

  const parsedUrl = queryString.parseUrl(url);
  const thePath = formatUrlToPath(parsedUrl.url);
  const pageType = _checkUrlPageType(url);

  const { query } = parsedUrl;

  switch (pageType) {
    case PAGE_TYPE.SearchResultPage:
      result = {
        enabled: true,
        navigate: () => navigateToSearchResultPage(parsedUrl),
      };
      break;
    case PAGE_TYPE.CampaignThemePage: {
      const webViewUrl = parseUrlForWebView(parsedUrl);
      const otherProps = isWebPlatform
        ? {
            title: navParams.title,
          }
        : {};

      // TODO:
      // 從 routeConfig.CampaignThemePage 應避免 undefined title
      // 但這個是 deeplink，title 會影響顯示，所以先拿掉，url 的規則需要再定義

      result = {
        enabled: true,
        navigate: () =>
          navigationService.navigate('CampaignThemePage', {
            ...query,
            ...otherProps,
            url: webViewUrl,
          }),
      };
      break;
    }
    case PAGE_TYPE.WelcomeLoginPage: {
      const activeRoute = navigationService.getActiveRoute() || {};
      const { routeName, params, key: backWithKey } = activeRoute;

      result = {
        enabled: true,
        navigate: () =>
          navigationService.navigate('WelcomeLoginPage', {
            ...query,
            [NavigationParams.BackWithKey]: backWithKey,
            [NavigationParams.BackToPageKey]: routeName,
            [NavigationParams.EncodedPageParams]:
              routeUtils.encodePageParams(params),
          }),
      };

      break;
    }
    default: {
      const routeName = Object.keys(routesConfig).find(scene =>
        routeUtils.isMatchPath(scene, thePath)
      );

      if (!routeName) {
        result = {
          enabled: false,
          navigate: () =>
            navigationService.navigate('NotFoundPage', {
              from: thePath,
            }),
        };

        break;
      }

      const params = routeUtils.parseFromPath(routeName, thePath);
      const allParams = {
        ...params,
        ...query,
        ...navParams,
      };
      const legalParams = illegalParamsHandler.removeIllegalParams(allParams);

      result = {
        enabled: true,
        navigate: () =>
          navigationService.navigate(
            routeName.replace(/Ignore(.)+$/, ''),
            legalParams
          ),
      };

      break;
    }
  }

  return result;
};

export default function pageNavigation(
  url: string,
  navParams: AllowedParams = {}
) {
  const checkResult = checkPageNavigationAbility(url, navParams);

  checkResult.navigate();
}
