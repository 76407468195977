import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { TopSalesData } from '../../types/TopSales';
import { getTopSalesActions } from '../actions/getTopSalesActions';
import { getTopSales } from '../api';
import { RootAction } from '../rootAction';

const outputActions = {
  success: getTopSalesActions.success,
  failure: getTopSalesActions.failure,
};

type RequestActionTypes = ActionType<typeof getTopSalesActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getTopSalesEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getTopSalesActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { page, limit, loadingType, success } = action.payload;

      return getTopSales({ page, limit })
        .then((response: AxiosResponse<TopSalesData>) => {
          const items = response.data.products || [];

          success(items);

          return getTopSalesActions.success({
            items,
            page,
            loadingType,
          });
        })
        .catch((error: AxiosError) => getTopSalesActions.failure({ error }));
    })
  );
};

export default getTopSalesEpic;
