import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { LinePayPrimeResponse } from '../../types/LinePayPrime';
import { postLinePayPrimeActions } from '../actions/postLinePayPrimeActions';
import { postLinePayPrime } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof postLinePayPrimeActions.request>;
const outputActions = {
  success: postLinePayPrimeActions.success,
  failure: postLinePayPrimeActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const postLinePayPrimeEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(postLinePayPrimeActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { transactionID, prime, screen } = action.payload;

      return postLinePayPrime(transactionID, prime, screen)
        .then((response: AxiosResponse<LinePayPrimeResponse>) => {
          const success = action.payload?.success || undefined;

          if (success !== undefined) {
            success(response.data);
          }

          return postLinePayPrimeActions.success({
            data: response.data,
            transactionID,
          });
        })
        .catch((error: AxiosError) => {
          const failure = action.payload?.failure || undefined;

          if (failure !== undefined) {
            failure({ error, transactionID });
          }

          return postLinePayPrimeActions.failure({ error, transactionID });
        });
    })
  );
};

export default postLinePayPrimeEpic;
