import fastDeepEqual from 'fast-deep-equal';
import { Component, Suspense } from 'react';
import { StatusBar } from 'react-native';

import { LogFirehoseProvider } from '../context/devLogFirehose';
import LoadingView from '../elements/LoadingView';
import {
  getActiveRouteName,
  processItemPageScreenView,
  processScreenView,
} from '../utils/analytics/getScreenView';
import {
  sendScreenViewEvent,
  sendWebScreenView,
  setCurrentScreen,
} from '../utils/analytics/screenViewEvent';
import * as navigationService from '../utils/navigationService';
import RNBootSplash from '../utils/RNBootSplash';
import routeUtils from '../utils/routes';
import AppContent from './nav/AppContent';
import TabBar from './TabBar';
import {
  isAndroid,
  isWebPlatform,
  limitGlobalFontSize,
  setDefaultRequestHeaders,
} from './utils';

function replaceIfInitalPageIllegal() {
  if (isWebPlatform) {
    const pathname = decodeURIComponent(window.location.pathname);
    // 如需要定義 path 、又不希望可以直接抵達的頁面可在這裡加入設定處理
    const illegalRegex = {
      Cart: /^\/member\/cart(\/.)+/,
      Home: /^\/+external((\/.)+|$)/,
      MemberEditPage: /^\/member\/basic\/\w+\/(edit|(auth\/\w+))$/,
      TransactionListPage: /^\/member\/review_bill\/\d+\/reviews\/create$/,
      ItemPage: /^\/user_submit\/item\/\w+:\d+\/combo$/,
    };
    let params = {};

    const replaceScreen =
      Object.keys(illegalRegex).find(screen =>
        illegalRegex[screen].test(pathname)
      ) || '';

    if (replaceScreen === 'ItemPage') {
      params = { id: pathname.match(/\w+:\d+/)?.[0] };
    }

    if (replaceScreen.length) {
      window.location.replace(routeUtils.compileToPath(replaceScreen, params));
    }
  }
}

function onNavigationStateChange(prevState, currentState) {
  const currentScreen = navigationService.getActiveRoute(currentState);
  const prevScreen = navigationService.getActiveRoute(prevState);

  sendScreenViewEvent(prevState, currentState);

  if (!fastDeepEqual(currentScreen, prevScreen)) {
    // TODO: desktop web 行為可能不一樣
    navigationService.saveTabScreenInfo(currentScreen);
    navigationService.screenSubject$.next({
      currentScreen,
      prevScreen,
    });
  }
}

function sendInitialScreenViewEvent(navigatorRef) {
  if (!navigatorRef) {
    return;
  }

  const navigationState = navigatorRef?.state?.nav;
  const route = navigationService.getActiveRoute(navigationState);
  const routeName = getActiveRouteName(route);
  const processedScreenView = processScreenView(
    processItemPageScreenView(routeName)
  );

  navigationService.saveTabScreenInfo(route);
  navigationService.screenSubject$.next({
    currentScreen: route,
    prevScreen: undefined,
  });

  // SearchResultPage, ListPage 透過 logEvent 送 ScreenView 的關係
  // 會導致部分數據工具在事件在 browser back/forward 多送事件
  // 因此 web 的部分，將 logEvent 分開處理 sendWebScreenView 和 setCurrentScreen
  if (isWebPlatform) {
    sendWebScreenView();
  }

  if (['SearchResultPage', 'ListPage'].includes(routeName)) {
    return;
  }

  const currentPath = routeUtils.getPathAndParamsFromData(
    route.routeName,
    route.params
  ).fullPath;

  setCurrentScreen(processedScreenView, routeName, currentPath);
}

setDefaultRequestHeaders();

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.screenTimeout = setTimeout(() => {
      RNBootSplash.hide({ duration: 300 });
      this.screenTimeout = 0;

      if (isAndroid) {
        StatusBar.setTranslucent(true);
        StatusBar.setBackgroundColor('rgba(0, 0, 0, 0)', false);
        StatusBar.setBarStyle('dark-content');
      }
    }, 500);

    if (!isWebPlatform) {
      limitGlobalFontSize();
    }
  }

  componentWillUnmount() {
    if (this.screenTimeout) {
      clearTimeout(this.screenTimeout);
    }
  }

  private screenTimeout: any = 0;

  render() {
    return (
      <Suspense fallback={<LoadingView />}>
        <LogFirehoseProvider>
          <AppContent
            ref={navigatorRef => {
              sendInitialScreenViewEvent(navigatorRef);
              navigationService.setTopLevelNavigator(navigatorRef);
              replaceIfInitalPageIllegal();
            }}
            onNavigationStateChange={onNavigationStateChange}
          />
          <TabBar />
        </LogFirehoseProvider>
      </Suspense>
    );
  }
}
