import { createSelector } from 'reselect';

import { defaultSortBy } from '../../constants/sortData';
import { CatItem } from '../../types/CatsResponse';
import Configs from '../../utils/configs';
import routeUtils from '../../utils/routes';
import { State, defaultState } from '../reducers/catsReducer';

const catsReducer = state => (state?.catsReducer || defaultState) as State;

export const makeCatsIsFetching = () => {
  return createSelector(catsReducer, data => {
    return data.isFetching;
  });
};

const emptyArray = [];

export const makeCatData = () => {
  return createSelector(catsReducer, data => {
    return data.data;
  });
};

export const makeCatChildrenList = (parentId: string | number) => {
  return createSelector(catsReducer, data => {
    return data.parentCatChildIds[`${parentId}`] || emptyArray;
  });
};

export const makeChildrenNames = (catId: string | number) => {
  return createSelector(
    catsReducer,
    makeParentCatById(catId),
    (reducer, parentCat) => {
      const childrenIds =
        reducer.parentCatChildIds[`${parentCat?.id}`] || emptyArray;

      return childrenIds
        .map(id => reducer.data[id]?.name)
        .filter(name => !!name);
    }
  );
};

export const makeCatById = (id: string | number) => {
  return createSelector(catsReducer, reducer => {
    return reducer.data?.[`${id}`];
  });
};

export const makeParentCatById = (catId: string | number) => {
  return createSelector(catsReducer, makeCatById(catId), (reducer, item) => {
    const parentId = item?.parent_id;

    return !!parentId && parentId !== '0' ? reducer.data[`${parentId}`] : item;
  });
};

export const makeBreadcrumbList = (categoryId: string) => {
  return createSelector(catsReducer, reducer => {
    const result: CatItem[] = [];

    const handleCategoryItem = (id?: string | number) => {
      if (typeof id === 'undefined' || `${id}` === '0') return;

      const categoryData = reducer.data[id];

      if (!categoryData) return;

      result.unshift(categoryData);

      const isRecommendLabel = categoryData.type === 'recommend_label';
      const nextId = isRecommendLabel
        ? categoryData.filter?.[0]?.cat_ids?.[0]?.value?.[0]
        : categoryData.parent_id;

      handleCategoryItem(nextId);
    };

    handleCategoryItem(categoryId);

    return result;
  });
};

export const makeBreadcrumbJsonLd = (categoryId: string) => {
  return createSelector(makeBreadcrumbList(categoryId), categoryList => {
    if (categoryList.length === 0) return {};

    const itemListElement = categoryList.map((item, index) => {
      const isRecommendLabel = item.type === 'recommend_label';
      const routeName = isRecommendLabel ? 'CategoriesLabelPage' : 'ListPage';
      const params = isRecommendLabel
        ? { title: item.name, labelId: item.id, id: categoryList[0].id }
        : { typeId: item.id, sortBy: defaultSortBy };
      const href = routeUtils.getPathAndParamsFromData(
        routeName,
        params
      ).fullPath;

      return {
        '@type': 'ListItem',
        position: index + 1,
        name: item.name,
        item: `https://${Configs.MHOST}${href}`,
      };
    });

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement,
    };
  });
};
