import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { deleteDirectPayCardActions } from '../actions/deleteDirectPayCardActions';
import { deleteDirectPayCard } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: deleteDirectPayCardActions.success,
  failure: deleteDirectPayCardActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;
type RequestActionTypes = ActionType<typeof deleteDirectPayCardActions.request>;

const deleteDirectPayCardEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(deleteDirectPayCardActions.request)),
    switchMap((action: RequestActionTypes) => {
      const cardID = action.payload.card_id;

      return deleteDirectPayCard(cardID)
        .then(() => deleteDirectPayCardActions.success({ card_id: cardID }))
        .catch((error: AxiosError) =>
          deleteDirectPayCardActions.failure({ error })
        );
    })
  );
};

export default deleteDirectPayCardEpic;
