import { AxiosError } from 'axios';
import omit from 'lodash/omit';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { NotificationGroup } from '../../types/Common';
import { getNotificationsActions } from '../actions/getNotificationsActions';
import { getNotifications } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

export function getNotificationGroup(
  group: NotificationGroup
): NotificationGroup[] {
  if (group === NotificationGroup.All) {
    return Object.values(omit(NotificationGroup, ['All']));
  }

  return [group];
}

const outputActions = {
  success: getNotificationsActions.success,
  failure: getNotificationsActions.failure,
};

type RequestActionType = ActionType<typeof getNotificationsActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getNotificationsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getNotificationsActions.request)),
    switchMap((action: RequestActionType) => {
      const { group } = action.payload;
      const requestBody = {
        group: getNotificationGroup(group),
      };

      return getNotifications(requestBody)
        .then(response =>
          getNotificationsActions.success({
            notifications: response.data.notifications,
            group,
          })
        )
        .catch((error: AxiosError) =>
          getNotificationsActions.failure({ error })
        );
    })
  );
};

export default getNotificationsEpic;
