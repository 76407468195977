import { css } from 'styled-components/native';

const FONT_LINE_HEIGHT = 21;

export interface ButtonProps {
  pressed: boolean;
  paddingVertical?: number;
  paddingHorizontal?: number;
  withRadius?: boolean;
  disabled?: boolean | null;
}

export interface TextProps {
  pressed: boolean;
  disabled?: boolean | null;
  isLoading?: boolean;
}

export const buttonStyle = ({
  paddingVertical,
  paddingHorizontal,
  withRadius,
}: ButtonProps) => css`
  padding: ${paddingVertical ?? 12}px ${paddingHorizontal ?? 20}px;
  border: solid 1px;
  border-radius: ${withRadius ? '3px' : '0'};
  justify-content: center;
  align-items: center;
`;

export const textStyle = ({ isLoading }: TextProps) => css`
  font-size: 15px;
  line-height: ${FONT_LINE_HEIGHT}px;
  text-align: center;
  opacity: ${isLoading ? 0 : 1};
`;

export const loadingViewStyle = css`
  position: absolute;
  background-color: transparent;
`;
