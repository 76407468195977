import { createAsyncAction } from 'typesafe-actions';

import { CVSItem } from '../../types/DeliveryData';

export interface UpdateCVSReceivingDataPayload {
  data: CVSItem;
  success: () => void;
  failure: () => void;
}

export interface UpdateCVSReceivingDataSuccessPayload {
  data: CVSItem;
}

export interface UpdateCVSReceivingDataFailPayload {
  error: Error;
}

export const updateCVSReceivingData = createAsyncAction(
  'verybuy/UPDATE_CVS_RECEIVING_DATA',
  'verybuy/UPDATE_CVS_RECEIVING_DATA_SUCCESS',
  'verybuy/UPDATE_CVS_RECEIVING_DATA_FAIL'
)<
  UpdateCVSReceivingDataPayload,
  UpdateCVSReceivingDataSuccessPayload,
  UpdateCVSReceivingDataFailPayload
>();
