import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { SortBy } from '../../constants/sortData';
import { SearchRecommend } from '../../types/SearchRecommend';
import { searchRecommendActions } from '../actions/searchRecommendActions';
import { searchRecommend } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof searchRecommendActions.request>;
const outputActions = {
  success: searchRecommendActions.success,
  failure: searchRecommendActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const searchRecommendActionsEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(searchRecommendActions.request)),
    switchMap((action: RequestActionTypes) => {
      const sortBy = action.payload?.sortBy || SortBy.UPDATE_AT;
      const { queryText } = action.payload;

      return searchRecommend({ sortBy })
        .then((response: AxiosResponse<SearchRecommend>) => {
          const successPayload = {
            data: response.data,
            queryText,
          };

          return searchRecommendActions.success(successPayload);
        })
        .catch((error: AxiosError) => {
          return searchRecommendActions.failure({ error });
        });
    })
  );
};

export default searchRecommendActionsEpic;
