import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import MarketingEvent from '../../types/MarketingEvent';
import getMarketingEventsActions from '../actions/getMarketingEvents';
import { getMarketingEvents } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getMarketingEventsActions.success,
  failure: getMarketingEventsActions.failure,
};

type RequestActionTypes = ActionType<typeof getMarketingEventsActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getMarketingEventsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getMarketingEventsActions.request)),
    switchMap((action: RequestActionTypes) => {
      return getMarketingEvents()
        .then((response: AxiosResponse<MarketingEvent[]>) =>
          getMarketingEventsActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) =>
          getMarketingEventsActions.failure({ error, requestAction: action })
        );
    })
  );
};

export default getMarketingEventsEpic;
