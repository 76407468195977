import { createStandardAction } from 'typesafe-actions';

export interface UpdateSelectedCartPayload {
  virtualProductID: number | string;
  checked: boolean;
}

export const updateSelectedCart = createStandardAction(
  'verybuy/UPDATE_SELECT_CART'
)<UpdateSelectedCartPayload>();

export const selectedAllCart = createStandardAction(
  'verybuy/SELECT_All_CART'
)<boolean>();
