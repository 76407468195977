import styled from 'styled-components/native';

import { coral, warrior } from '../../../../../constants/color';
import Touchable from '../../../../../elements/Touchable';

export const S = {
  HotBubbleBtn: styled(Touchable)`
    border: solid 1px;
    border-color: ${coral}4D;
    border-radius: 17px;
    padding: 7.5px 10.5px 6.5px;
    margin-right: 10px;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: center;
  `,
  HotBubbleText: styled.Text`
    color: ${warrior};
    font-size: 15px;
    line-height: 21px;
    margin-left: 4px;
  `,
};
