import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { TopRatedData } from '../../types/TopRated';
import { getTopRatedActions } from '../actions/getTopRatedActions';
import { getTopRated } from '../api';
import { RootAction } from '../rootAction';

const outputActions = {
  success: getTopRatedActions.success,
  failure: getTopRatedActions.failure,
};

type RequestActionTypes = ActionType<typeof getTopRatedActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getTopRatedEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getTopRatedActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { page, per_page, loadingType, success } = action.payload;

      return getTopRated({ page, per_page })
        .then((response: AxiosResponse<TopRatedData>) => {
          const items = response.data.products || [];

          success(items);

          return getTopRatedActions.success({
            items,
            page,
            loadingType,
          });
        })
        .catch((error: AxiosError) => getTopRatedActions.failure({ error }));
    })
  );
};

export default getTopRatedEpic;
