import { createAsyncAction } from 'typesafe-actions';

import { PatchMemberData } from '../../types/MemberInfo';

export interface MemberPayload {
  data: PatchMemberData;
  success: () => void;
  failure: () => void;
}

export interface MemberSuccessPayload {
  data: PatchMemberData;
}

export interface MemberFailPayload {
  error: Error;
  customErrorHandling: boolean;
}

export const patchMemberActions = createAsyncAction(
  'verybuy/PATCH_MEMBER',
  'verybuy/PATCH_MEMBER_SUCCESS',
  'verybuy/PATCH_MEMBER_FAIL'
)<MemberPayload, MemberSuccessPayload, MemberFailPayload>();
