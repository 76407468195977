import { createAsyncAction } from 'typesafe-actions';

import { LightBox } from '../../types/LightBox';

export interface GetLightBoxPayload {}

export interface GetLightBoxSuccessPayload {
  data: LightBox;
}

export interface GetLightBoxFailPayload {
  error: Error;
}

const getLightBoxActions = createAsyncAction(
  'verybuy/GET_LIGHT_BOX',
  'verybuy/GET_LIGHT_BOX_SUCCESS',
  'verybuy/GET_LIGHT_BOX_FAIL'
)<GetLightBoxPayload, GetLightBoxSuccessPayload, GetLightBoxFailPayload>();

export default getLightBoxActions;
