import Clipboard from '@react-native-clipboard/clipboard';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useMMKVNumber } from 'react-native-mmkv';

import { isAndroid } from '../../boot/utils';
import Config from '../../utils/configs';
import { decryptAltGhostID } from '../../utils/dynamicLinkToCopy';
import { setAltGhostID } from '../../utils/ghostIDHelpers';

/**
  ┌─────────────────────┐    Y    ┌────────────────────────────┐
  │  lastLaunchedDate?  ├────────►│  lastLaunchedDate > 1day ? │
  └──────────┬──────────┘         └──────────────┬─────────────┘
             │N                                  │Y
  ┌──────────▼──────────────┐                    │
  │ Clipboard.hasWebURL()?  │◄───────────────────┘
  └──────────┬──────────────┘
             │Y
     ┌───────▼───────┐
     │ altg params ? │
     └───────┬───────┘
             │Y
     ┌───────▼───────┐
     │ setAltGhostID │
     └───────────────┘
 */

const altGhostParamsName = 'altg';

export const filterURLWithAltGhostID = url => {
  let altGhost = '';
  const urlObj = queryString.parseUrl(url);

  // only dynamic link url would pass
  if (urlObj.url !== `${Config.REACT_APP_DYNAMICLINK_PREFIX}/`) {
    return '';
  }

  const altg = urlObj?.query?.[altGhostParamsName] || '';

  if (typeof altg === 'string') {
    altGhost = decryptAltGhostID(altg);
  }

  return altGhost;
};

export default () => {
  const [lastLaunchedDateNumber, setLastLaunchedDateString] = useMMKVNumber(
    'app.lastLaunchedDateNumber'
  );

  useEffect(() => {
    /**
     * 1. 寫入條件：第一次開啟而且 clipboard 有正確網址
     * 2. 上次開啟的時間距離一天以上，重新計算
     */
    //
    // 若是沒有 lastLaunchedDateString 就是第一次開啟 app, 需要檢查 clipboard
    let shouldCheckClipboard = !lastLaunchedDateNumber;

    if (lastLaunchedDateNumber && typeof lastLaunchedDateNumber === 'number') {
      const now = Date.now();

      // 超過一天, 也需要檢查
      if (+now - lastLaunchedDateNumber > 86400000) {
        shouldCheckClipboard = true;
      }
    }

    /** THEN GET CLIPBOARD */
    const readClipboard = async () => {
      let shouldReadClipboard = false;
      let clipboardData = '';

      if (isAndroid) {
        shouldReadClipboard = true;
      } else {
        shouldReadClipboard = await Clipboard.hasWebURL();
      }

      if (shouldReadClipboard) {
        clipboardData = await Clipboard.getString();
      }

      return clipboardData;
    };

    if (shouldCheckClipboard) {
      readClipboard().then(data => {
        const altGhostID = filterURLWithAltGhostID(data);

        if (altGhostID) {
          setAltGhostID(altGhostID);
        }
      });

      setLastLaunchedDateString(Date.now());
    }
    // for debug
    // setLastLaunchedDateString(undefined);
  }, []);

  return null;
};
