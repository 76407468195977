import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { OnboardingResponse } from '../../types/OnboardingResponse';

export interface OnboardingPayload {
  loadingType: ListLoadingTypes;
  page: number;
  success?: Function;
}
export interface OnboardingSuccessPayload {
  data: OnboardingResponse;
  loadingType: ListLoadingTypes;
  page: number;
}

export interface OnboardingFailPayload {
  error: Error;
}

export const getOnboardingActions = createAsyncAction(
  'verybuy/GET_ONBOARDING',
  'verybuy/GET_ONBOARDING_SUCCESS',
  'verybuy/GET_ONBOARDING_FAIL'
)<OnboardingPayload, OnboardingSuccessPayload, OnboardingFailPayload>();
