import { TouchableHighlight } from 'react-native';
import styled from 'styled-components/native';

import { neutralGrey, warrior } from '../../constants/color';
import LoadingView from '../LoadingView';
import NoScaleText from '../NoScaleText';
import {
  ButtonProps,
  TextProps,
  buttonStyle,
  loadingViewStyle,
  textStyle,
} from './basicStyle';

const backgroundColor = 'transparent';
const textColor = warrior;
const borderColor = neutralGrey;
const pressedBorderColor = warrior;

const getButtonBorderColor = (pressed, disabled) => {
  if (disabled) {
    return `${borderColor}66`;
  }

  return pressed ? pressedBorderColor : borderColor;
};

const defaultStyles = {
  Button: styled(TouchableHighlight)<ButtonProps>`
    ${buttonStyle};
    background-color: ${backgroundColor};
    border-color: ${({ pressed, disabled }) =>
      getButtonBorderColor(pressed, disabled)};
  `,
  Text: styled(NoScaleText)<TextProps>`
    ${textStyle};
    color: ${({ disabled }) => (disabled ? `${textColor}66` : textColor)};
  `,
  LoadingView: styled(LoadingView)`
    ${loadingViewStyle};
  `,
};

export default defaultStyles;
