import { LinearGradientProps as Props } from 'react-native-linear-gradient';

import LinearGradient from './LinearGradient';

export default LinearGradient;

// Web 的 linear gradient 只支援 colors, locations, start, end
export type LinearGradientProps = Pick<
  Props,
  'colors' | 'locations' | 'start' | 'end'
>;
