export interface AdditionalCategory {
  id: string;
  name: string;
  href: string;
  imageSource: NodeRequire;
  parent_id?: undefined;
}

export interface CategoriesData {
  category_ids: number[];
}

export interface CategoriesFail {
  reason: string;
}

export interface CategoriesResponse {
  status: boolean;
  data: CategoriesData | CategoriesFail;
}

export enum CategoriesPosition {
  Home = 'index',
  Hamburger = 'hamburger_menu',
}
