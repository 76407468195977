// 定義 web 用的 pathname
// [screen-key]: 該 scene 的 path 定義
const routes = {
  AboutLegal: 'aboutus/legal',
  AboutPage: 'about',
  AboutPrivacy: 'aboutus/privacy',
  AnnouncementPage: 'member/announcements/:id',
  BestItemPage: 'best_items/:id',
  BestItemPageIgnorePage: 'best_items/:id/page/:page?',
  BestSellerPage: 'best_sellers/:sellerId',
  BestSellerPageIgnorePage: 'best_sellers/:sellerId/page/:page?',
  BlogPage: 'blog',
  BrowseHistoryListPage: 'member/history',
  CampaignThemePage: 'tuango/theme/:app?/:id?',
  Cart: 'member/cart',
  CartPage: 'member/stackcart',
  Categories: 'categories',
  CategoriesLabelPage: 'categories/:id/labels/:labelId',
  CheckoutPage: 'member/cart/checkout',
  ChooseCreditCardPage: 'member/cart/creditcard',
  CreateCreditCardPage: 'member/cart/creditcard/create',
  CouponListPage: 'member/coupons',
  EventPage: 'member/event',
  FaqPage: 'aboutus/faq/:categoryId?',
  FaqDetailPage: 'aboutus/faq/:categoryId/:questionId',
  Home: '',
  IconsPage: 'icons',
  ItemPage: 'user_submit/item/:id',
  ItemPageRecommends: 'user_submit/item/:id/recommends',
  ItemComboPage: 'user_submit/item/:id/combo',
  ItemReviewsPage: 'user_submit/item/:id/reviews',
  LabelPage: 'labels/:labelType/:labelId',
  ListPage: 'user_submit/list/category/:typeId',
  ListPageIgnorePage: 'user_submit/list/category/:typeId/page/:page?',
  ListPageIgnoreSort: 'user_submit/list/category/:typeId/sort/:sortId',
  ListPageIgnoreSortPage:
    'user_submit/list/category/:typeId/sort/:sortId/page/:page?',
  LoginPage: 'member/login',
  LoveListPage: 'member/love',
  MailboxDetailPage: 'member/mailbox/:id',
  MailboxPage: 'member/mailbox',
  Me: 'member',
  MemberBasicInfoEditPage: 'member/basic/info/edit',
  MemberContactAuthPage: 'member/basic/contact/auth/:method',
  MemberContactEditPage: 'member/basic/contact/edit',
  MemberEditPage: 'member/basic',
  MemberSettingsPage: 'member/settings',
  MemberVerifyPhonePage: 'member/cart/verify_phone',
  MemberVerifySMSPage: 'member/cart/verify_sms',
  NewArrivalsPage: 'new_arrivals',
  NotFoundPage: '404',
  NotificationsPage: 'member/notifications',
  OnSalePage: 'on_sale',
  OnboardingPage: 'onboarding',
  PaymentPage: 'member/cart/payment',
  ProductTagsPage: 'product_tags/:tag',
  QueriesPage: 'df/:id',
  ReceiptPage: 'member/cart/receipt',
  ReceivingShopDetailPage: 'member/cart/shop',
  RecipientDetailPage: 'member/cart/recipient',
  RefundPage: 'member/refund_request',
  RegisterPage: 'member/register',
  ResetPasswordPage: 'member/reset_password/:token',
  SearchResultPage: 'search/user_submit/:queryText',
  SearchResultPageTopId: 'search/user_submit/:queryText/top/:topIds',
  SearchResultPageTopIdIgnorePage:
    'search/user_submit/:queryText/top/:topIds/page/:page?',
  SearchResultPageIgnorePage: 'search/user_submit/:queryText/page/:page?',
  SetNewPwdPage: 'member/set_new_pwd',
  ShareFriendPage: 'member/share_friend',
  ShippingLogisticsPage: 'member/cargo_progress/:transactionID',
  ShoppingWebView: 'member/cart/external',
  SupportPage: 'member/support',
  SupportFaqPage: 'member/support_faq',
  TopSalesListPage: 'topsales',
  TopRatedListPage: 'top_rated',
  TransactionDetailPage: 'member/review_bill/:transactionID',
  TransactionListPage: 'member/transactions',
  TransactionRatesCreatePage:
    'member/review_bill/:transactionID/reviews/create',
  TransactionResultPage: 'member/review_bill/result/:transactionID',
  VcoinPage: 'member/vcoin',
  VcoinToCashPage: 'member/vcoin_to_cash',
  VendorPage: 'vendors/:id',
  WebViewPage: 'external',
  WelcomeLoginPage: 'member/welcome',
};

export type RouteName = keyof typeof routes;

export default routes;
