import { SupportType } from './Common';

export enum MessageFrom {
  Admin = 'admin',
  User = 'user',
}

export interface SupportDetailResponse {
  data: Data;
}

export interface Data {
  id: string;
  type: SupportType;
  can_reply: boolean;
  additional_data: AdditionalData;
}

export interface Message {
  id: string;
  content: string;
  created_at: number;
  from: MessageFrom;
  image_srcs: string[];
}

export interface AdditionalData {
  transaction_id?: number;
}

export interface SupportMessagesData {
  messages: Message[];
}

export interface SupportMessagesResponse {
  data: SupportMessagesData;
}
