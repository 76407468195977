import { combineEpics } from 'redux-observable';

import { addToCartEpic } from './epics/addToCartEpic';
import analyticsLogEpic from './epics/analyticsLogEpic';
import apiCommonErrorEpic from './epics/apiCommonErrorEpic';
import fetchCategoriesEpic from './epics/categoriesEpic';
import cookie from './epics/cookieEpic';
import { deleteCartItemEpic } from './epics/deleteCartItemEpic';
import deleteDirectPayCardEpic from './epics/deleteDirectPayCardEpic';
import directPayTokenEpic from './epics/directPayTokenEpic';
import getAnnouncementDetailEpic from './epics/getAnnouncementDetailEpic';
import getAnnouncementsEpic from './epics/getAnnouncementsEpic';
import getBannersEpic from './epics/getBannersEpic';
import getCategoryListEpic from './epics/getCategoryListEpic';
import getCatsEpic from './epics/getCatsEpic';
import getCountriesEpic from './epics/getCountriesEpic';
import getCouponListEpic from './epics/getCouponListEpic';
import { getDefaultShippingFeesEpic } from './epics/getDefaultShippingFeesEpic';
import getDirectPayCardEpic from './epics/getDirectPayCardEpic';
import getEventCouponEpic from './epics/getEventCouponEpic';
import getEventPageCoverEpic from './epics/getEventPageCoverEpic';
import getFaqEpic from './epics/getFaqEpic';
import getFilterConfigEpic from './epics/getFilterConfigEpic';
import getFreeDeliveryEpic from './epics/getFreeDeliveryEpic';
import getIndexOnSaleEpic from './epics/getIndexOnSaleEpic';
import getItemComboListEpic from './epics/getItemComboListEpic';
import getItemDetailEpic from './epics/getItemDetailEpic';
import getLabelItemsEpic from './epics/getLabelItemsEpic';
import getLandingPageListEpic from './epics/getLandingPageListEpic';
import getLightBox from './epics/getLightBoxEpic';
import getLoveListEpic from './epics/getLoveListEpic';
import getLoveVendorsEpic from './epics/getLoveVendorsEpic';
import getMailboxListEpic from './epics/getMailboxListEpic';
import getMarketingEventsEpic from './epics/getMarketingEvents';
import getMarketingLandingPageEpic from './epics/getMarketingLandingPageEpic';
import getMemberInfoEpic from './epics/getMemberInfoEpic';
import getMemberProfileEpic from './epics/getMemberProfileEpic';
import getNewArrivalEpic from './epics/getNewArrivalEpic';
import getNotificationsEpic from './epics/getNotificationsEpic';
import getOnboardingEpic from './epics/getOnboardingEpic';
import getOnSaleEpic from './epics/getOnSaleEpic';
import getPayment from './epics/getPaymentEpic';
import getPeriodTaskEpic from './epics/getPeriodTaskEpic';
import getPreviewTransactionsEpic from './epics/getPreviewTransactionsEpic';
import getProductReviewDetailEpic from './epics/getProductReviewDetailEpic';
import getProductReviewListDataEpic from './epics/getProductReviewListDataEpic';
import getPromotionalThemesEpic from './epics/getPromotionalThemesEpic';
import getQueryItemsEpic from './epics/getQueryItemsEpic';
import getRecommendLandingListEpic from './epics/getRecommendLandingListEpic';
import getRecommendProductsEpic from './epics/getRecommendProductsEpic';
import getRecommendSubjectEpic from './epics/getRecommendSubjectEpic';
import getSearchWithTokenEpic from './epics/getSearchWithTokenEpic';
import getShippingLogisticsEpic from './epics/getShippingLogisticsEpic';
import getSimilarItemsEpic from './epics/getSimilarItemsEpic';
import getSupportDetailEpic from './epics/getSupportDetailEpic';
import getSupportMessagesEpic from './epics/getSupportMessagesEpic';
import getTopKeywordsEpic from './epics/getTopKeywordsEpic';
import getTopRatedEpic from './epics/getTopRatedEpic';
import getTopSalesEpic from './epics/getTopSalesEpic';
import getTransactionListEpic from './epics/getTransactionList';
import getVBConfigEpic from './epics/getVBConfigEpic';
import getVendorDetailEpic from './epics/getVendorDetailEpic';
import getWordsLabelsEpic from './epics/getWordsLabelsEpic';
import getZipEpic from './epics/getZipEpic';
import guestTokenEpic from './epics/guestTokenEpic';
import impressionBatchEpic from './epics/impressionBatchEpic';
import { fetchItemContentEpic } from './epics/itemContentEpic';
import itemLovesEpic from './epics/itemLovesEpic';
import lastLoginMethodEpic from './epics/lastLoginMethodEpic';
import { loadCartContentEpic } from './epics/loadCartContentEpic';
import { loadDeliveryDataEpic } from './epics/loadDeliveryDataEpic';
import loginByAppleEpic from './epics/loginByAppleEpic';
import loginByEmailEpic from './epics/loginByEmailEpic';
import loginByFacebookEpic from './epics/loginByFacebookEpic';
import loginByLineEpic from './epics/loginByLineEpic';
import patchCancelTransactionEpic from './epics/patchCancelTransactionEpic';
import patchMemberEpic from './epics/patchMemberEpic';
import patchNotificationReadEpic from './epics/patchNotificationReadEpic';
import patchTransactionEpic from './epics/patchTransactionEpic';
import postApplePayPrimeEpic from './epics/postApplePayPrimeEpic';
import postCouponRedeemEpic from './epics/postCouponRedeemEpic';
import postDirectPayCardEpic from './epics/postDirectPayCardEpic';
import postForgetPasswordEpic from './epics/postForgetPasswordEpic';
import postLinePayPrimeEpic from './epics/postLinePayPrimeEpic';
import postMemberVerifySMSEpic from './epics/postMemberVerifySMSEpic';
import postPeriodTaskEpic from './epics/postPeriodTaskEpic';
import postRatingEpic from './epics/postRatingEpic';
import postRegisterEpic from './epics/postRegisterEpic';
import postSupportsReplyEpic from './epics/postSupportsReplyEpic';
import putDirectPayPrimaryCardEpic from './epics/putDirectPayPrimaryCardEpic';
import putMemberVerifyPhoneEpic from './epics/putMemberVerifyPhoneEpic';
import putPhoneVerificationCodeEpic from './epics/putPhoneVerificationCodeEpic';
import putSubtotalEpic from './epics/putSubtotalEpic';
import putVerifyCouponEpic from './epics/putVerifyCouponEpic';
import { getRecommendListEpic } from './epics/recommendListEpic';
import searchEpic from './epics/searchEpic';
import searchRecommendEpic from './epics/searchRecommendEpic';
import submitBillEpic from './epics/submitBillEpic';
import fetchTransactionDetailEpic from './epics/transactionDetailEpic';
import { updateCartItemAmountEpic } from './epics/updateCartItemAmountEpic';
import { updateCVSReceivingDataEpic } from './epics/updateCVSReceivingDataEpic';
import { updateDeliveryDataEpic } from './epics/updateDeliverDataEpic';
import { getUserSubmitDetailEpic } from './epics/userSubmitDetailEpic';
import { getUserSubmitListEpic } from './epics/userSubmitListEpic';
import vendorCollectEpic from './epics/vendorCollectEpic';

// equivalent to
// const rootEpic = (action$, state$) => merge(
//   foo(actions$, state$),
//   bar(actions$, state$),
// )

export default combineEpics(
  getLandingPageListEpic,
  getCountriesEpic,
  getProductReviewListDataEpic,
  getProductReviewDetailEpic,
  postApplePayPrimeEpic,
  postRatingEpic,
  vendorCollectEpic,
  getVendorDetailEpic,
  getLoveVendorsEpic,
  getRecommendLandingListEpic,
  postForgetPasswordEpic,
  patchMemberEpic,
  getCategoryListEpic,
  getPreviewTransactionsEpic,
  getSupportMessagesEpic,
  getAnnouncementDetailEpic,
  postSupportsReplyEpic,
  getWordsLabelsEpic,
  getAnnouncementsEpic,
  getMailboxListEpic,
  getFreeDeliveryEpic,
  getFaqEpic,
  getQueryItemsEpic,
  getLabelItemsEpic,
  getSearchWithTokenEpic,
  getIndexOnSaleEpic,
  getOnSaleEpic,
  getCatsEpic,
  getNewArrivalEpic,
  getRecommendProductsEpic,
  getPromotionalThemesEpic,
  getTopKeywordsEpic,
  getSupportDetailEpic,
  getItemComboListEpic,
  getMemberProfileEpic,
  getVBConfigEpic,
  getOnboardingEpic,
  getItemDetailEpic,
  analyticsLogEpic,
  impressionBatchEpic,
  getUserSubmitListEpic,
  getRecommendListEpic,
  guestTokenEpic,
  addToCartEpic,
  fetchItemContentEpic,
  getUserSubmitDetailEpic,
  loadCartContentEpic,
  updateCartItemAmountEpic,
  deleteCartItemEpic,
  loadDeliveryDataEpic,
  updateDeliveryDataEpic,
  updateCVSReceivingDataEpic,
  getZipEpic,
  getMarketingLandingPageEpic,
  getPayment,
  submitBillEpic,
  loginByEmailEpic,
  loginByFacebookEpic,
  loginByLineEpic,
  getRecommendSubjectEpic,
  loginByAppleEpic,
  cookie,
  fetchCategoriesEpic,
  searchEpic,
  searchRecommendEpic,
  fetchTransactionDetailEpic,
  putSubtotalEpic,
  putVerifyCouponEpic,
  patchTransactionEpic,
  postDirectPayCardEpic,
  patchCancelTransactionEpic,
  getMarketingEventsEpic,
  lastLoginMethodEpic,
  getMemberInfoEpic,
  getNotificationsEpic,
  patchNotificationReadEpic,
  getTransactionListEpic,
  postLinePayPrimeEpic,
  postMemberVerifySMSEpic,
  directPayTokenEpic,
  getDirectPayCardEpic,
  getEventCouponEpic,
  getPeriodTaskEpic,
  postPeriodTaskEpic,
  postCouponRedeemEpic,
  putDirectPayPrimaryCardEpic,
  putMemberVerifyPhoneEpic,
  getBannersEpic,
  getShippingLogisticsEpic,
  postRegisterEpic,
  getLoveListEpic,
  getTopSalesEpic,
  getTopRatedEpic,
  itemLovesEpic,
  apiCommonErrorEpic,
  getCouponListEpic,
  getEventPageCoverEpic,
  putPhoneVerificationCodeEpic,
  deleteDirectPayCardEpic,
  getSimilarItemsEpic,
  getLightBox,
  getFilterConfigEpic,
  getDefaultShippingFeesEpic
);
