import { createSelector } from 'reselect';

import * as listWithPlaceholder from '../../utils/listWithPlaceholder';
import { InitialState } from '../reducers/topRatedReducer';
import { RootState } from '../rootReducer';

const topRatedRaw = (state: RootState) => state?.topRatedReducer || {};

export const makeTopRatedList = (maxNum = -1) =>
  createSelector(topRatedRaw, data => {
    const list = data?.items || [];
    const isFetching = data?.isFetching || false;
    const loadingType = data?.loadingType || 'new';

    const topRatedList = listWithPlaceholder.makeResultIdsWithFetching(
      list,
      isFetching && loadingType === 'new'
    );

    return maxNum > -1 ? topRatedList.slice(0, maxNum) : topRatedList;
  });

export const makeTopRatedDataByKey = <K extends keyof InitialState>(key: K) => {
  return createSelector(topRatedRaw, data => data?.[key]);
};

export const makeIsTopRatedEmpty = () => {
  return createSelector(
    makeTopRatedDataByKey('items'),
    (list = []) => !list.length
  );
};
