import * as arrayHandler from './arrayHandler';

const LIST_PLACEHOLDER_NUMBER = 6;

export const makePlaceHolderKeys = (n: number, startIndex: number = 0) => {
  return Array.from({ length: n }, (_v, k) => `blank${k + startIndex}`);
};

export const makeResultIdsWithFetching = (
  _list: any[] = [],
  isFetching: boolean,
  adConfig: { key: string; index: number }[] = []
) => {
  const list = arrayHandler.uniqueArray(_list);

  // TODO: only for column = 2
  const placeholderNumber =
    list.length % 2 === 0
      ? LIST_PLACEHOLDER_NUMBER
      : LIST_PLACEHOLDER_NUMBER - 1;
  let result = [] as any;

  if (isFetching) {
    if (!list.length) {
      result = makePlaceHolderKeys(placeholderNumber);
    } else {
      result = list.concat(makePlaceHolderKeys(placeholderNumber));
    }
  } else {
    result = list.slice();
  }

  if (adConfig.length > 0) {
    let offset = 0;

    [...adConfig]
      .sort((a, b) => a.index - b.index)
      .forEach(({ key, index }) => {
        if (result.length - 1 > index) {
          result.splice(index + offset++, 0, key);
        }
      });
  }

  return result;
};
