import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { CommonErrors } from '../../constants/VBErrorCode';
import { UserSubmitItem } from '../../types/UserSubmitItem';
import { getUserSubmitDetail as getUserSubmitDetailAction } from '../actions/getUserSubmitDetail';
import { getUserSubmitDetail } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof getUserSubmitDetailAction.request>;
const outputActions = {
  success: getUserSubmitDetailAction.success,
  failure: getUserSubmitDetailAction.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const getUserSubmitDetailEpic = (
  action$: Observable<RootAction>,
  _state$: BehaviorSubject<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getUserSubmitDetailAction.request)),
    switchMap((action: RequestActionTypes) => {
      const { id, success, failure } = action.payload;

      return getUserSubmitDetail(id)
        .then((response: AxiosResponse<UserSubmitItem>) => {
          const data = response.data;

          success(data);

          return getUserSubmitDetailAction.success({
            id,
            data,
          });
        })
        .catch((error: AxiosError) => {
          const particularError = [
            CommonErrors.ERR_INVALID_ARGUMENT,
            CommonErrors.ERR_NOT_FOUND,
          ];
          const { error_code: errorCode } = error?.response?.data || {};
          const preventCommonError = particularError.some(
            theError => errorCode === theError
          );

          failure(errorCode);

          return getUserSubmitDetailAction.failure({
            // set customErrorHandling undefined, then go through common error
            customErrorHandling: preventCommonError || undefined,
            id,
            error,
            requestAction: action,
          });
        });
    })
  );
};
