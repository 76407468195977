import { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { PutDeliveryData } from '../../types/DeliveryData';
import { updateDeliveryData } from '../actions/updateDeliveryDataActions';
import { putDeliveryData } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof updateDeliveryData.request>;
const outputActions = {
  success: updateDeliveryData.success,
  failure: updateDeliveryData.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const updateDeliveryDataEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(updateDeliveryData.request)),
    switchMap((action: RequestActionTypes) => {
      const deliveryItem = action.payload.data;

      return putDeliveryData(deliveryItem)
        .then((response: AxiosResponse<PutDeliveryData>) => {
          if (typeof action.payload.success === 'function') {
            action.payload.success();
          }

          return updateDeliveryData.success({
            data: response.data.receiving_data[0],
          });
        })
        .catch(error => {
          const failure = action.payload?.failure || undefined;

          if (failure) {
            failure(error.response);
          }

          return updateDeliveryData.failure({
            error,
          });
        });
    })
  );
};
