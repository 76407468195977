import { useMemo } from 'react';

import { DEVICE_TYPE } from '../constants/layout';
import { DeviceType } from '../types/Common';
import { DynamicLinkPayload, DynamicLinkResponse } from '../types/DynamicLink';
import Config from '../utils/configs';
import useRequest from './useRequest';

export const URL = `${Config.FIREBASE_API_URL}/dynamic-links`;

function useDynamicLink(payload: DynamicLinkPayload) {
  const isDesktop = DEVICE_TYPE === DeviceType.DESKTOP;
  const { fallbackLink } = payload;
  const requestArgs = useMemo(() => ({ params: payload }), [payload]);
  const { data, isValidating } = useRequest<DynamicLinkResponse>(
    URL,
    requestArgs
  );

  return useMemo(
    () => ({
      link: isDesktop ? fallbackLink : data?.link || fallbackLink,
      isFetching: isDesktop ? false : isValidating,
    }),
    [data, fallbackLink, isValidating, isDesktop]
  );
}

export default useDynamicLink;
