import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { CONFIRM_R18_HINT } from '../../constants/guideKeys';
import {
  logAnalyticsEvent,
  logImpressionBatch,
} from '../../redux/actions/analyticsLog';
import {
  makeProductByIdSelector,
  makeProductIsNotAllowedByIdSelector,
  makeProductIsOutOfStockByIdSelector,
} from '../../redux/selectors/productById';
import { makeHasReadGuideByKey } from '../../redux/selectors/tutorialGuideSelector';
import ListItem from './ListItem';

const makeSelector = (_initialState, initialOwnProps) => {
  const { id, item, isOutOfStock, isNotAllowed } = initialOwnProps;

  const selector = createStructuredSelector({
    item: makeProductByIdSelector(id),
    isOutOfStock: makeProductIsOutOfStockByIdSelector(id),
    isNotAllowed: makeProductIsNotAllowedByIdSelector(id),
    hasReadGuide: makeHasReadGuideByKey(CONFIRM_R18_HINT),
  });

  return state => {
    const result = selector(state);

    if (item) {
      result.item = item;
    }

    if (isOutOfStock) {
      result.isOutOfStock = isOutOfStock;
    }

    if (isNotAllowed) {
      result.isNotAllowed = isNotAllowed;
    }

    return result;
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logEvent: logAnalyticsEvent,
      logImpressionBatch,
    },
    dispatch
  );
};

export default connect(makeSelector, mapDispatchToProps)(ListItem);
