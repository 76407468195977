import { memo } from 'react';
import { View } from 'react-native';

import AvgRatingStars from '../../../elements/AvgRatingStars';
import Placeholder from '../../../elements/Placeholder';
import { getFontScale } from '../../../utils/layoutHelpers';
import styles from './styles';

interface Props {
  width: number;
  showTitlesAndPrice: boolean;
  showRating?: boolean;
}

function EmptyLoadingScene(props: Props) {
  const { width, showTitlesAndPrice, showRating } = props;
  const fontScale = getFontScale();
  const secondaryHeight = 15 * fontScale;

  return (
    <>
      <Placeholder width={width} height={width} />
      {showTitlesAndPrice && (
        <Placeholder
          width={width}
          height={secondaryHeight}
          boxStyle={styles.listItemBox}
        />
      )}
      {showTitlesAndPrice && (
        <Placeholder width={width * 0.6} height={secondaryHeight} />
      )}
      {showRating && (
        <View style={styles.avgRatingStars}>
          <AvgRatingStars rating={0} size={10} />
        </View>
      )}
    </>
  );
}

export default memo(EmptyLoadingScene, () => true);
