import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { Data as FaqData } from '../../types/Faq';
import { getFaqActions } from '../actions/getFaqActions';

interface State {
  faq: FaqData;
  isFetching: boolean;
  hasError: boolean;
  faqVersionCode: string;
}

export const defaultState: State = {
  faq: { categories: [] } as FaqData,
  isFetching: false,
  hasError: false,
  faqVersionCode: '',
};

export default produce(
  (draft: State, action: ActionType<typeof getFaqActions>) => {
    switch (action.type) {
      case getType(getFaqActions.request): {
        const { faqVersionCode } = action.payload;

        draft.isFetching = true;
        draft.faqVersionCode = faqVersionCode;
        break;
      }
      case getType(getFaqActions.success): {
        const { data } = action.payload;

        draft.isFetching = false;
        draft.hasError = false;
        draft.faq = data;
        break;
      }
      case getType(getFaqActions.failure): {
        draft.isFetching = false;
        draft.hasError = true;
        break;
      }
      default: {
        break;
      }
    }
  },
  defaultState
);
