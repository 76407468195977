import AsyncStorage from '@react-native-async-storage/async-storage';
import Clipboard from '@react-native-clipboard/clipboard';
import ReactNativeIdfaAaid from '@sparkfabrik/react-native-idfa-aaid';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Alert, ScrollView } from 'react-native';

import { isIOS, isWebPlatform } from '../../boot/utils';
import type { AuthProps } from '../../decorators/withAuth';
import FastImage from '../../elements/Image/FastImage';
import Touchable from '../../elements/Touchable';
import { useTranslation } from '../../i18n';
import { persiststore } from '../../redux/persiststore';
import Config from '../../utils/configs';
import { DevConfig, applyCustomConfigByEnv } from '../../utils/configs/utils';
import isExists from '../../utils/isExists';
import mmkvStorage from '../../utils/mmkvStorage';
import * as navigationService from '../../utils/navigationService';
import codePush from '../../utils/packages/codePush';
import { isCodePushEnabled } from '../../utils/packages/codePush';
import { getFCMToken } from '../../utils/packages/firebase';
import { getIsDisableNotiPermission } from '../../utils/permissionHelpers';
import { _clearHistories } from '../../utils/rnProductHistory';
import Expander from './Expander';
import FunctionButton from './FunctionButton';
import NavigateForm from './NavigateForm';
import { startNetworkLogging } from './networkLogger';
import OnlyDevCountryCode from './OnlyDevCountryCode';
import OnlyDevLogFirehose from './OnlyDevLogFirehose';
import OnlyDevSetDomain from './OnlyDevSetDomain';
import S from './style';
import useABTestingGroup from './useABTestingGroup';

interface Props extends Pick<AuthProps, 'memberInfo' | 'role'> {
  showToast: Function;
  closeFullScreenLoading: Function;
}

const GeneralPage = ({ showToast, memberInfo, role }: Readonly<Props>, ref) => {
  const { t } = useTranslation();
  const PASSWORD = useRef('vb1');
  const DEFAULT_PASSWORD = useRef(
    Config.REACT_APP_ENV_TYPE === 'Release' ? '' : PASSWORD.current
  );
  const DEFAULT_DOMAIN =
    Config.REACT_APP_API_URL === 'https://api.verybuy.cc' ? 'prod' : 'sa';
  const [domain, setDomain] = useState(DEFAULT_DOMAIN);
  const abTestingGroup = useABTestingGroup();

  useEffect(() => {
    AsyncStorage.getItem('customEnv')
      .then(_domain => _domain && setDomain(_domain))
      .catch(null);
  }, []);
  const [password, setPassword] = useState(DEFAULT_PASSWORD.current);

  const setDomainFunc = () => {
    console.log(domain);
    if (domain) {
      AsyncStorage.setItem('customEnv', domain).catch(null);

      applyCustomConfigByEnv(Config, domain);
    } else {
      AsyncStorage.removeItem('customEnv').catch(null);
    }
  };

  useEffect(() => {
    if (Config.REACT_APP_ENV_TYPE !== 'Release') {
      setDomainFunc();
    }
  }, [domain]);

  const trackRequest = () => {
    startNetworkLogging();
    showToast('Started to track api request');
  };

  const goToRequestPage = () => {
    navigationService.navigate('NetworkLogger');
  };

  const handleGetIDFAOrAAID = async () => {
    const resp = await ReactNativeIdfaAaid.getAdvertisingInfo();

    if (resp.id && !resp.isAdTrackingLimited) {
      Clipboard.setString(resp.id);
      showToast('已複製到剪貼簿');
    } else {
      showToast('Can not get IDFA or AAID');
    }
  };

  const getFCMTokenFunc = async () => {
    const fcmToken = await getFCMToken();

    if (fcmToken) {
      Clipboard.setString(fcmToken);

      showToast('已複製到剪貼簿');
    } else {
      showToast('Can not get FCM Token');
    }
  };

  const clearImageCache = () => {
    Promise.all([
      FastImage.clearDiskCache(),
      FastImage.clearMemoryCache(),
    ]).then(() => {
      showToast('Cleared successfully');
    });
  };

  const clearAsyncStorage = async () => {
    // https://stackoverflow.com/questions/46736268/react-native-asyncstorage-clear-is-failing-on-ios
    // When AsyncStorage is empty.
    // AsyncStorage.clear() : errors on iOS but not Android.
    // AsyncStorage.getAllKeys().then(AsyncStorage.multiRemove) : errors on Android but not iOS.
    const asyncStorageKeys = await AsyncStorage.getAllKeys();

    if (asyncStorageKeys.length > 0) {
      await AsyncStorage.clear();
    }

    mmkvStorage.instance.clearAll();

    persiststore.purge();
    showToast('clearAsyncStorage');
  };

  const clearProductHistory = async () => {
    _clearHistories();
  };

  const forPlatformFunctions = isWebPlatform
    ? []
    : [
        {
          title: 'Start to track api request',
          desc: '',
          onPress: trackRequest,
        },
        {
          title: 'Go to api request page',
          desc: '',
          onPress: goToRequestPage,
        },
        {
          title: 'Get IDFA or AAID',
          desc: '',
          onPress: handleGetIDFAOrAAID,
        },
      ];

  const onlyDevFunctions =
    Config.REACT_APP_ENV_TYPE === 'Release'
      ? []
      : [
          <OnlyDevSetDomain
            key="OnlyDevSetDomain"
            domain={domain}
            setDomain={setDomain}
            showToast={showToast}
          />,
          <OnlyDevLogFirehose key="OnlyDevLogFirehose" />,
          <OnlyDevCountryCode key="countryCode" />,
        ];

  const [isDisableNotiPermission, setIsDisableNotiPermission] = useState(false);

  useEffect(() => {
    getIsDisableNotiPermission()
      .then(setIsDisableNotiPermission)
      .catch(console.log);
  }, []);

  // 方便用 index 加上項目編號。
  // 格式如 FunctionButton.tsx 的 Props
  const functions = [
    ...forPlatformFunctions,
    {
      title: 'Get FCM token',
      desc: '',
      onPress: getFCMTokenFunc,
    },
    {
      title: 'Clear Image Cache',
      desc: '',
      onPress: clearImageCache,
      warning: true,
    },
    {
      title: 'Clear AsyncStorage',
      desc: '',
      onPress: clearAsyncStorage,
      warning: true,
    },
    {
      title: 'Clear ProductHistory',
      desc: '',
      onPress: clearProductHistory,
      warning: true,
    },
  ];

  const codePushState = useRef({ lastBackPressed: Date.now(), counter: 0 });
  const [codePushFetching, setCodePushFetching] = useState(false);

  const info = [
    {
      title: 'CONFIG ENV',
      desc: Config.REACT_APP_ENV_TYPE,
    },
    {
      title: 'CodePush',
      desc: (() => {
        const defaultText = isCodePushEnabled() ? 'Enable' : 'Disable';

        return codePushFetching ? 'Checking for update' : defaultText;
      })(),
      enabled: !codePushFetching && isCodePushEnabled(),
      onPress: async () => {
        if (codePushState.current.counter >= 5) {
          codePushState.current.counter = 0;

          setCodePushFetching(true);

          const stagingKey = isIOS
            ? 'GgqGMdUMkV29MsNxJTN9NHHdWA0GpV-PV7A1_'
            : 'eDj-Ja8u2dGg-dS_AdS9K0S3b4sn3wUzCcASi';
          const packageInfo = await codePush.checkForUpdate(stagingKey);

          if (packageInfo) {
            Alert.alert(
              'Found a update from Staging, do you wish to install?',
              `${packageInfo?.appVersion} (${packageInfo?.label})`,
              [
                { text: 'No thanks' },
                {
                  text: 'Yes please',
                  onPress: () => {
                    codePush.sync({
                      deploymentKey: stagingKey,
                      installMode: codePush.InstallMode.IMMEDIATE,
                    });
                  },
                },
              ]
            );
          }

          setCodePushFetching(false);
        }

        if (
          isExists(codePushState.current.lastBackPressed) &&
          codePushState.current.lastBackPressed + 2000 >= Date.now()
        ) {
          codePushState.current.counter++;
        }

        codePushState.current.lastBackPressed = Date.now();
      },
    },
    {
      title: 'API_URL',
      desc:
        Config.REACT_APP_ENV_TYPE === 'Release'
          ? Config.REACT_APP_API_URL
          : DevConfig(domain || DEFAULT_DOMAIN, 'REACT_APP_API_URL'),
    },
    {
      title: 'SA',
      desc: Config.SENSORS_SERVER_URL,
    },
    {
      title: 'lang',
      desc: `${t('code')}/${t('name')}`,
    },
    {
      title: 'MID',
      desc: memberInfo.MID,
    },
    {
      title: 'GID',
      desc: memberInfo.guestID,
    },
    {
      title: 'role',
      desc: role,
    },
    {
      title: 'AB testing group',
      desc: abTestingGroup,
    },
    {
      title: 'Push Notification',
      desc: isDisableNotiPermission ? 'DisAllowed' : 'Allowed',
    },
  ];

  if (password !== PASSWORD.current) {
    return (
      <S.FormInput
        testID="passwordInput"
        centered
        placeholder="Please enter your password"
        onChangeText={v => setPassword(v)}
        value={password}
        autoCapitalize="none"
      />
    );
  }

  return (
    <ScrollView ref={ref}>
      {info.map((x, i) => (
        <Touchable
          key={`info${i}`}
          disabled={!x.enabled}
          onPress={x.onPress}
          withoutFeedback
          accessibilityRole="button"
        >
          <S.InfoItem>
            <S.Label flex>{x.title}</S.Label>
            <S.Label>{x.desc}</S.Label>
          </S.InfoItem>
        </Touchable>
      ))}
      <S.Spacer />
      <Expander title="Tools">
        <NavigateForm />
        {onlyDevFunctions.map(f => f)}
        {functions.map((x, i) => (
          <FunctionButton key={`fbtn${i}`} {...x} index={i} />
        ))}
      </Expander>
      <S.Spacer />
    </ScrollView>
  );
};

export default forwardRef(GeneralPage);
