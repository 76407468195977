import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getLandingPageListActions } from '../actions/getLandingPageListActions';
import { getLandingPageList } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getLandingPageListActions.success,
  failure: getLandingPageListActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getLandingPageListEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getLandingPageListActions.request)),
    switchMap(() => {
      return getLandingPageList()
        .then(response =>
          getLandingPageListActions.success({
            data: response.data.categories,
          })
        )
        .catch((error: AxiosError) =>
          getLandingPageListActions.failure({ error })
        );
    })
  );
};

export default getLandingPageListEpic;
