import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { CouponRedeemResponse } from '../../types/Coupon';
import { postCouponRedeem } from '../api';
import { RootAction, actionsList } from '../rootAction';

const { postCouponRedeemActions } = actionsList;

const outputActions = {
  success: postCouponRedeemActions.success,
  failure: postCouponRedeemActions.failure,
};

type RequestActionTypes = ActionType<typeof postCouponRedeemActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const postCouponRedeemEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(postCouponRedeemActions.request)),
    switchMap((action: RequestActionTypes) => {
      const {
        couponCode,
        shouldVerify = true,
        success,
        redeemSuccess,
        redeemFailure,
        verifyFailure,
      } = action.payload;

      return postCouponRedeem(couponCode)
        .then((response: CouponRedeemResponse) => {
          if (typeof redeemSuccess === 'function') {
            redeemSuccess();
          }

          return postCouponRedeemActions.success({
            couponCode,
            couponData: response.data,
            logEvent: AnalyticsEvent.CouponRedeem,
            shouldVerify,
            success,
            failure: verifyFailure,
          });
        })
        .catch((error: AxiosError) => {
          if (typeof redeemFailure === 'function') {
            redeemFailure(error);
          }

          return postCouponRedeemActions.failure({
            error,
            couponCode,
            logEvent: AnalyticsEvent.CouponRedeem,
            customErrorHandling: true,
          });
        });
    })
  );
};

export default postCouponRedeemEpic;
