import { getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { SearchRecommend } from '../../types/SearchRecommend';
import * as arrayHandler from '../../utils/arrayHandler';
import { SearchSuccessPayload, searchActions } from '../actions/searchActions';
import { searchRecommendActions } from '../actions/searchRecommendActions';

export type State = {
  data: string[];
  isFetching: boolean;
  loadingType: ListLoadingTypes;
  hasMoreItems: boolean;
  hasMoreDiscoveryItems: boolean;
  queryText: string;
  discoveryData: string[];
  isDiscoveryFetching: boolean;
  isFuzzyDiscoverySearch: boolean;
  currentPage?: number;
  is_fuzzy?: boolean;
  search_term?: string;
  isFuzzyResult?: boolean;
};

export const defaultState: State = {
  data: [],
  isFetching: false,
  discoveryData: [],
  isDiscoveryFetching: false,
  loadingType: 'new' as ListLoadingTypes,
  hasMoreItems: true,
  hasMoreDiscoveryItems: true,
  queryText: '',
  isFuzzyResult: false,
  isFuzzyDiscoverySearch: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case getType(searchActions.request): {
      const {
        isFuzzyDiscoverySearch = false,
        queryText,
        page,
        loadingType,
      } = action.payload;
      const isLoadMore = loadingType === 'loadMore';
      const newItems = isLoadMore ? state.data : [];
      const newDiscoveryItems = isLoadMore ? state.discoveryData : [];
      const hasMoreItems = isLoadMore ? state.hasMoreItems : true;
      const hasMoreDiscoveryItems = isLoadMore
        ? state.hasMoreDiscoveryItems
        : true;

      const dataParams = isFuzzyDiscoverySearch
        ? {
            isDiscoveryFetching: true,
            discoveryData: newDiscoveryItems,
            hasMoreDiscoveryItems,
          }
        : {
            isFetching: true,
            data: newItems,
            hasMoreItems,
          };

      return {
        ...state,
        ...dataParams,
        isFuzzyDiscoverySearch,
        queryText,
        currentPage: page,
        loadingType,
      };
    }

    case getType(searchActions.success): {
      const {
        isFuzzyDiscoverySearch = false,
        isFuzzyResult,
        loadingType,
        data,
      }: SearchSuccessPayload = action.payload;

      const defaultSearchUserSubmit = {
        products: [],
        search_term: [],
      };
      const { products: items, ...otherData } = data || defaultSearchUserSubmit;

      const itemsIds = items?.map(d => `${d.id}`) || [];
      const newItems =
        loadingType === 'loadMore' ? [...state.data, ...itemsIds] : itemsIds;

      const newDiscoveryItems =
        loadingType === 'loadMore'
          ? [...state.discoveryData, ...itemsIds]
          : itemsIds;

      const dataParams = isFuzzyDiscoverySearch
        ? {
            isDiscoveryFetching: false,
            discoveryData: arrayHandler.uniqueArray(newDiscoveryItems),
            hasMoreDiscoveryItems: items.length > 0,
          }
        : {
            isFetching: false,
            data: arrayHandler.uniqueArray(newItems),
            hasMoreItems: items.length > 0,
          };

      return {
        ...state,
        error: undefined,
        isFuzzyDiscoverySearch,
        loadingType,
        isFuzzyResult,
        ...dataParams,
        ...otherData,
      };
    }

    case getType(searchActions.failure): {
      return {
        ...state,
        isFetching: false,
      };
    }

    case getType(searchRecommendActions.request): {
      return {
        ...state,
        isDiscoveryFetching: true,
        isFuzzyDiscoverySearch: false,
      };
    }

    case getType(searchRecommendActions.success): {
      const { products } = action.payload.data as SearchRecommend;

      const itemsId = (products && products.map(d => `${d.id}`)) || [];

      return {
        ...state,
        isDiscoveryFetching: false,
        discoveryData: arrayHandler.uniqueArray(itemsId),
      };
    }

    case getType(searchRecommendActions.failure): {
      return {
        ...state,
        isDiscoveryFetching: false,
      };
    }

    default: {
      return state;
    }
  }
}
