/* eslint-disable camelcase */
import { createAsyncAction } from 'typesafe-actions';

export interface PatchTransactionActionsPayload {
  transactionID: string;
  pay_by: number;
  success?: Function;
  failure?: Function;
}

export interface PatchTransactionActionsSuccessPayload {
  transactionID: string;
}

export interface PatchTransactionActionsFailPayload {
  transactionID: string;
}

export const patchTransactionAction = createAsyncAction(
  'verybuy/PATCH_TRANSACTION',
  'verybuy/PATCH_TRANSACTION_SUCCESS',
  'verybuy/PATCH_TRANSACTION_FAIL'
)<
  PatchTransactionActionsPayload,
  PatchTransactionActionsSuccessPayload,
  PatchTransactionActionsFailPayload
>();
