import deepEqual from 'fast-deep-equal';
import { ActionType, getType } from 'typesafe-actions';

import { ListLoadingTypes, ReviewStatus } from '../../types/Common';
import { ShippingLogistics } from '../../types/ShippingLogistics';
import { TransactionDetailData } from '../../types/TransactionDetail';
import { getPreviewTransactionsActions } from '../actions/getPreviewTransactionsActions';
import { getShippingLogisticsActions } from '../actions/getShippingLogisticsActions';
import { patchCancelTransactionActions } from '../actions/patchCancelTransactionActions';
import { patchTransactionAction } from '../actions/patchTransactionActions';
import { postRatingActions } from '../actions/postRatingActions';
import { fetchTransactionDetail } from '../actions/transactionDetailActions';

type Actions = ActionType<
  | typeof fetchTransactionDetail.request
  | typeof fetchTransactionDetail.success
  | typeof fetchTransactionDetail.failure
  | typeof patchCancelTransactionActions.request
  | typeof patchCancelTransactionActions.failure
  | typeof patchTransactionAction.request
  | typeof patchTransactionAction.failure
  | typeof getPreviewTransactionsActions.request
  | typeof getPreviewTransactionsActions.success
  | typeof getPreviewTransactionsActions.failure
  | typeof getShippingLogisticsActions.request
  | typeof getShippingLogisticsActions.success
  | typeof getShippingLogisticsActions.failure
  | typeof postRatingActions.success
>;

type IDState = typeof initialIDState;

export const initialIDState = {
  isLoading: false,
  isShippingLogisticsLoading: false,
  data: {} as TransactionDetailData,
  shippingLogistics: {},
  loadingType: 'new' as ListLoadingTypes,
  shippingLogisticsLoadingType: 'new' as ListLoadingTypes,
};
const initialState: Record<string, IDState> = {};

function process(state = initialIDState, action: Actions) {
  switch (action.type) {
    case getType(fetchTransactionDetail.request): {
      const { loadingType } = action.payload;

      if (action.payload.silent) {
        return state;
      }

      return {
        ...state,
        isLoading: true,
        loadingType,
      };
    }

    case getType(fetchTransactionDetail.failure): {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case getType(fetchTransactionDetail.success): {
      const item = {
        ...state.data,
        ...action.payload.data,
      };

      const newItem = deepEqual(state.data, item) ? state.data : item;

      return {
        ...state,
        isLoading: false,
        data: newItem,
      };
    }

    case getType(patchCancelTransactionActions.request): {
      const { loadingType } = action.payload;

      return {
        ...state,
        isLoading: true,
        loadingType,
      };
    }

    case getType(patchTransactionAction.request): {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getType(patchTransactionAction.failure): {
      return {
        ...state,
        isLoading: false,
      };
    }

    case getType(patchCancelTransactionActions.failure): {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case getType(getPreviewTransactionsActions.request): {
      const { loadingType } = action.payload;

      return {
        ...state,
        shippingLogisticsLoadingType: loadingType,
        isShippingLogisticsLoading: true,
      };
    }
    case getType(getPreviewTransactionsActions.success): {
      const packageNum = 0;
      const { data } = action.payload;

      return {
        ...state,
        isShippingLogisticsLoading: false,
        shippingLogistics: {
          [packageNum]: data,
        },
      };
    }
    case getType(getPreviewTransactionsActions.failure): {
      return {
        ...state,
        isShippingLogisticsLoading: false,
      };
    }

    case getType(getShippingLogisticsActions.request): {
      const { loadingType } = action.payload;

      return {
        ...state,
        shippingLogisticsLoadingType: loadingType,
        isShippingLogisticsLoading: true,
      };
    }
    case getType(getShippingLogisticsActions.success): {
      const packageNum = action.payload.packageNum || 0;

      return {
        ...state,
        isShippingLogisticsLoading: false,
        error: undefined,
        shippingLogistics: {
          [packageNum]: action.payload.data as ShippingLogistics,
        },
      };
    }
    case getType(getShippingLogisticsActions.failure): {
      return {
        ...state,
        isShippingLogisticsLoading: false,
        error: action.payload.error,
      };
    }

    case getType(postRatingActions.success): {
      const data = state.data as TransactionDetailData;

      if (data?.product_data?.shipped?.length > 0) {
        data.product_data.shipped = data.product_data.shipped.map(packet => {
          const newProducts = packet.products.map(item => {
            return {
              ...item,
              review_status: ReviewStatus.Done,
            };
          });

          return {
            ...packet,
            products: newProducts,
          };
        });
      }

      return {
        ...state,
        data,
      };
    }

    default: {
      return state;
    }
  }
}

const orderById = (state = initialState, action) => {
  switch (action.type) {
    case getType(fetchTransactionDetail.request):
    case getType(fetchTransactionDetail.success):
    case getType(fetchTransactionDetail.failure):
    case getType(patchCancelTransactionActions.request):
    case getType(patchCancelTransactionActions.failure):
    case getType(patchTransactionAction.request):
    case getType(patchTransactionAction.failure):
    case getType(getPreviewTransactionsActions.request):
    case getType(getPreviewTransactionsActions.success):
    case getType(getPreviewTransactionsActions.failure):
    case getType(getShippingLogisticsActions.request):
    case getType(getShippingLogisticsActions.success):
    case getType(getShippingLogisticsActions.failure):
    case getType(postRatingActions.success): {
      return {
        ...state,
        [action.payload.transactionID]: process(
          state[action.payload.transactionID],
          action
        ),
      };
    }

    default:
      return state;
  }
};

export default orderById;
