// 針對 QueriesPage 的 query string 解析，範例 path 如下
// https://${host}/queries?f=color*[white]*1,pattern*[dot]&f=cat_ids*[3,5],color*[maroon]*5&min_price=123&max_price=456&sort_by=create_at&sort=desc&item_types[]=2&title=哈囉尼好
// 更多資訊可參考下方連結
// https://verybuycc.atlassian.net/browse/CX-1056
// https://verybuycc.atlassian.net/wiki/spaces/PT/pages/2405335041/CX-1056
// 目前不處理也不預期發生這樣的狀況：
// * f 欄位 score 相同(含沒設定) 的合併或檢查

import pick from 'lodash/pick';
import queryString from 'query-string';

// 目前 LabelKeys 僅限於紀錄特別的篩選項目，沒有實際運用
// const LabelKeys = ['color', 'cat_ids', 'pattern'];
const SortVals = ['asc', 'desc'];
const SortByVals = ['created_at', 'updated_at', 'list_price'];
// f0,f1,f2 -> FSplitter is 『,』
const FSplitter = ',';
// key*[v1,v2]*score -> KeyValueScoreSplitter is 『*』; ValuesSplitter is 『,』
const KeyValueScoreSplitter = '*';
const ValuesSplitter = ',';

type ParsedFValue = {
  value: string[] | number[];
  score?: number;
};
export type ParsedF = Record<string, ParsedFValue[] | undefined>;
export interface FormatedQueriesParams {
  [k: string]: unknown;
  f?: ParsedF[];
  item_types?: number[];
  max_price?: number;
  min_price?: number;
  sort?: typeof SortVals[number];
  sort_by?: typeof SortByVals[number];
  title?: string;
}
interface UnformatedQueriesParams {
  [k: string]: unknown;
  f?: string[];
  item_types?: string[];
  max_price?: string;
  min_price?: string;
  sort?: string;
  sort_by?: string;
  title?: string;
}

export function turnNumber(s: string) {
  return s && !Number.isNaN(+s) ? +s : undefined;
}

export function checkKeyValueScore(s: string) {
  return /^([\w-]+\*(\[([\w-,])+\])(\*\d+)?,)*([\w-]+\*(\[([\w-,])+\])(\*\d+)?)$/.exec(
    s
  );
}

export function checkNumberValueInKeyValueScore(s: string) {
  return /^\[([\d]+,)*([\d])+\]$/.test(s);
}

export function splitValueInKeyValueScore(s: string): string[] {
  return s.replace(/(\[)|(\])/g, '').split(ValuesSplitter);
}

export const checkSpecialProperitesFormated: {
  f: (value: undefined | ParsedF[] | string[]) => boolean;
  max_price: (value: undefined | number | string) => boolean;
  min_price: (value: undefined | number | string) => boolean;
  item_types: (value: undefined | number[] | string[]) => boolean;
} = {
  f(value) {
    // 不預期得到的資料為部分處理，因此只檢查第一個
    // 不預期有處理的情況下欄位異常，因此採取簡單檢查
    return !value || !value.length || typeof value[0] !== 'string';
  },
  max_price(value) {
    return !value || typeof value === 'number';
  },
  min_price(value) {
    return !value || typeof value === 'number';
  },
  item_types(value) {
    // 不預期得到的資料為部分處理，因此只檢查第一個
    return !value || !value.length || typeof value[0] === 'number';
  },
};

export function checkIsFormated(
  params: FormatedQueriesParams | UnformatedQueriesParams
) {
  return Object.keys(checkSpecialProperitesFormated).every(k => {
    return checkSpecialProperitesFormated[k](params[k]);
  });
}

export const parseParams: {
  f: (fs: string | string[]) => ParsedF[];
  item_types: (q: string | string[]) => number[];
  max_price: (q: string) => number | undefined;
  min_price: (q: string) => number | undefined;
  title: (q: string) => string;
  sort: (q: string) => string | undefined;
  sort_by: (q: string) => string | undefined;
} = {
  f(fs) {
    // 簡單的做一些判斷，目的是避免 runtime error
    if (!fs || !fs.length) {
      return [];
    }

    return ([] as string[]).concat(fs).reduce((result, f) => {
      // 簡單的做一些判斷，目的是避免 runtime error
      if (!f || !checkKeyValueScore(f)) {
        return result;
      }

      const conditions: string[] = [];
      let matches: null | string[];
      let stringF = f;

      while ((matches = checkKeyValueScore(stringF))) {
        const theMatch = matches[1] || matches[0];

        conditions.push(theMatch.replace(/,$/, ''));
        stringF = stringF.replace(theMatch, '');
      }

      const parsedF = conditions.reduce((tmpF: ParsedF, condi: string) => {
        const [key, value, score] = condi.split(KeyValueScoreSplitter);
        const isScoreValid =
          typeof score === 'undefined' || /^\d+$/.test(score);

        if (!value || !isScoreValid) {
          return tmpF;
        }

        const numProperties = ['cat_ids'];
        const isNumberProperties = numProperties.includes(key);

        if (isNumberProperties && !checkNumberValueInKeyValueScore(value)) {
          return tmpF;
        }

        const splittedValue = splitValueInKeyValueScore(value);
        const newValue = isNumberProperties
          ? (splittedValue
              .map(turnNumber)
              .filter(id => typeof id !== 'undefined') as number[])
          : splittedValue;
        const newScore = turnNumber(score);
        const newData: ParsedFValue[] = tmpF[key] || [];

        if (newValue) {
          newData.push({
            value: newValue,
            ...(newScore ? { score: newScore } : {}),
          });
        }

        return {
          ...tmpF,
          [key]: newData,
        };
      }, {} as ParsedF);

      if (Object.keys(parsedF).length) {
        result.push(parsedF);
      }

      return result;
    }, [] as ParsedF[]);
  },
  item_types(q) {
    return ([] as string[]).concat(q).reduce((result: number[], i: string) => {
      const newData = turnNumber(i);

      if (newData) {
        result.push(newData);
      }

      return result;
    }, [] as number[]);
  },
  max_price(q) {
    return turnNumber(q);
  },
  min_price(q) {
    return turnNumber(q);
  },
  title(q) {
    return decodeURIComponent(q);
  },
  sort(q) {
    return SortVals.includes(q) ? q : undefined;
  },
  sort_by(q) {
    return SortByVals.includes(q) ? q : undefined;
  },
};

// dataFromImperfactQueries 沒檢查是否經過處理，可接受的資料的正確來源如下
// 1. 經過 queryString.parse 的 location.search
// 2. navigationService.getActiveRoute（或 react-navigation) 的 params
export function parse(
  dataFromImperfactQueries: UnformatedQueriesParams
): FormatedQueriesParams {
  // 目的是避免 runtime error
  if (!dataFromImperfactQueries) {
    return {} as FormatedQueriesParams;
  }

  return Object.keys(dataFromImperfactQueries).reduce((result, p) => {
    const data = parseParams[p]
      ? parseParams[p](dataFromImperfactQueries[p])
      : dataFromImperfactQueries[p];

    if (data) {
      return {
        ...result,
        [p]: data,
      };
    }

    return result;
  }, {} as FormatedQueriesParams);
}

// 不預期 stringify 的 input 有太多異常，只做簡單處理
export const stringifyParams = {
  f(data: ParsedF[]): string[] {
    // 目的是避免 runtime error
    if (!data) {
      return [];
    }

    return data
      .map(item => {
        const strF = Object.keys(item)
          .reduce((result, key) => {
            if (!item[key]) {
              return result;
            }

            const tmp = item[key]!.map((subItem: ParsedFValue) => {
              // 目的是避免 runtime error
              if (!subItem?.value?.join) {
                return '';
              }

              const fArray = [key, `[${subItem.value.join(ValuesSplitter)}]`];

              if (typeof subItem.score !== 'undefined') {
                fArray.push(subItem.score.toString());
              }

              return fArray.join(KeyValueScoreSplitter);
            });

            return result.concat(tmp);
          }, [] as string[])
          .join(FSplitter);

        return encodeURIComponent(strF);
      })
      .filter(s => s.length > 0);
  },
  title(str: string) {
    // 簡單的做一些判斷，目的是避免 runtime error
    return str ? encodeURIComponent(str) : undefined;
  },
};

// 不預期 stringify 的 input 有太多異常，只做簡單處理
export function stringify(
  params: FormatedQueriesParams | UnformatedQueriesParams,
  customCheckIsFormated = checkIsFormated
): string {
  // 目的是避免 runtime error
  if (!params) return '';

  let stringifyHandler;

  if (customCheckIsFormated(params)) {
    stringifyHandler = stringifyParams;
  } else {
    stringifyHandler = pick(stringifyParams, ['title']);
  }

  const theParams = Object.keys(params).reduce((result, key) => {
    const newData = stringifyHandler[key]
      ? stringifyHandler[key](params[key])
      : params[key];

    return {
      ...result,
      [key]: newData,
    };
  }, {});

  return queryString.stringify(theParams, { encode: false });
}
