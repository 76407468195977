import AsyncStorage from '@react-native-async-storage/async-storage';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { RootAction, actionsList } from '../rootAction';

const {
  loginByEmail,
  loginByApple,
  loginByFacebook,
  loginByLine,
  postRegisterActions,
} = actionsList;

type RequestActionTypes = ActionType<
  | typeof loginByEmail.success
  | typeof loginByApple.success
  | typeof loginByFacebook.success
  | typeof loginByLine.success
  | typeof postRegisterActions.success
>;

export default (action$: Observable<RootAction>) => {
  return action$.pipe(
    ofType(
      getType(loginByApple.success),
      getType(loginByFacebook.success),
      getType(loginByLine.success),
      getType(loginByEmail.success),
      getType(postRegisterActions.success)
    ),
    switchMap((action: RequestActionTypes) => {
      const { method } = action.payload;

      return AsyncStorage.setItem('lastLoginMethod', method)
        .then(() => null)
        .catch(() => null);
    }),
    ignoreElements()
  );
};
