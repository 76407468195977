import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { DeliveryMode } from '../../types/Common';
import { CVSItem, DeliveryItem } from '../../types/DeliveryData';
import { SubtotalData, SubtotalRequest } from '../../types/Subtotal';
import { deleteCartItem } from '../actions/deleteCartItemActions';
import { loadCartContent } from '../actions/loadCartContentActions';
import { loadDeliveryData } from '../actions/loadDeliveryDataActions';
import { putSubtotalActions } from '../actions/putSubtotalActions';
import {
  selectedAllCart,
  updateSelectedCart,
} from '../actions/selectCartActions';
import { updateCartItemAmount } from '../actions/updateCartItemAmountActions';
import { updateCVSReceivingData } from '../actions/updateCVSReceivingDataActions';
import { updateDeliveryData } from '../actions/updateDeliveryDataActions';
import { putSubtotal } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';
import { getCheckedItems } from '../selectors/cartContentSelector';

const outputActions = {
  success: putSubtotalActions.success,
  failure: putSubtotalActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const putSubtotalEpic = (
  action$: Observable<RootAction>,
  state$: BehaviorSubject<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(putSubtotalActions.request),
      getType(loadCartContent.success),
      getType(loadDeliveryData.success),
      getType(updateDeliveryData.success),
      getType(updateCVSReceivingData.success),
      getType(deleteCartItem.success),
      getType(updateCartItemAmount.success),
      getType(updateSelectedCart),
      getType(selectedAllCart)
    ),
    switchMap((action: any = {}) => {
      const virtual_products = getCheckedItems()(state$.value);
      const pay_by = state$.value?.submitBillReducer?.pay_by;
      const paymentMethods =
        state$.value?.checkoutReducer?.paymentMethods || {};
      const payBys = Object.keys(paymentMethods).map(x => Number(x));

      const deliveryMode = state$.value?.submitBillReducer?.delivery_mode;
      const coupon_code = state$.value?.submitBillReducer?.couponData?.code;

      const data: Partial<DeliveryItem & CVSItem> =
        deliveryMode === DeliveryMode.HOME_DELIVERY
          ? state$.value?.checkoutReducer?.deliveryData?.data?.[0] || {}
          : state$.value?.checkoutReducer?.cvsList?.data?.[0] || {};
      const { city, district, zip_code, store } = data;
      const selectDeliveryMode = action.payload?.selected_delivery_mode;

      const subtotalRequest = {
        virtual_products,
        pay_by,
        selected_delivery_mode: selectDeliveryMode || deliveryMode,
        coupon_code,
        city,
        district,
        zip_code,
        store,
        check_pay_bys: payBys,
        use_vcoin: 1,
      };

      return putSubtotal(subtotalRequest as SubtotalRequest)
        .then((response: AxiosResponse<SubtotalData>) => {
          const success = action.payload?.success;

          if (success) {
            success(response.data);
          }

          const logEvent = action.payload?.checkoutStep;

          return putSubtotalActions.success({
            data: response.data,
            logEvent,
          });
        })
        .catch((error: AxiosError) => {
          const failure = action.payload?.failure;

          if (failure) {
            failure(error);
          }

          return putSubtotalActions.failure({ error });
        });
    })
  );
};

export default putSubtotalEpic;
