import { ActionType, getType } from 'typesafe-actions';

import { BannerType } from '../../constants/bannerType';
import { BannerDetail } from '../../types/Banners';
import { ListLoadingTypes } from '../../types/Common';
import { actionsList } from '../rootAction';

const { getBannersActions } = actionsList;

const allowedBannerTypes = Object.values(BannerType);

type BannerIDStr = string;
type LayoutName = string;

interface IState {
  bannerIDs: BannerIDStr[];
  isLoading: boolean;
  loadingType: ListLoadingTypes;
}

export type State = {
  layoutStates: Record<LayoutName, IState | undefined>;
  data: Record<BannerIDStr, BannerDetail | undefined>;
};

export const initialState: State = {
  layoutStates: {},
  data: {},
};

const getPreviousLayoutState = (state: State, layoutName: string) => {
  return (
    state.layoutStates[layoutName] || {
      bannerIDs: [],
      isLoading: false,
      loadingType: 'new',
    }
  );
};

export default function (
  state = initialState,
  action: ActionType<typeof getBannersActions>
) {
  switch (action.type) {
    case getType(getBannersActions.request): {
      const { layoutName, loadingType } = action.payload;

      const prevLayoutState = getPreviousLayoutState(state, layoutName);

      return {
        ...state,
        layoutStates: {
          ...state.layoutStates,
          [layoutName]: {
            ...prevLayoutState,
            isLoading: true,
            loadingType,
          },
        },
      };
    }
    case getType(getBannersActions.success): {
      const { layoutName, banners } = action.payload;

      const bannerIDs: BannerIDStr[] = [];
      const nextData = banners
        .filter(x => allowedBannerTypes.includes(x.type as BannerType))
        .reduce(
          (acc, cur) => {
            const bannerID = `${cur.id}`;

            if (bannerID) {
              acc[bannerID] = cur;
              bannerIDs.push(bannerID);
            }

            return acc;
          },
          { ...state.data }
        );

      const prevLayoutState = getPreviousLayoutState(state, layoutName);
      const nextLayoutState = {
        ...prevLayoutState,
        isLoading: false,
        bannerIDs,
      };

      return {
        ...state,
        data: nextData,
        layoutStates: {
          ...state.layoutStates,
          [layoutName]: nextLayoutState,
        },
      };
    }
    case getType(getBannersActions.failure): {
      const { layoutName } = action.payload;

      const prevLayoutState = getPreviousLayoutState(state, layoutName);

      return {
        ...state,
        layoutStates: {
          ...state.layoutStates,
          [layoutName]: {
            ...prevLayoutState,
            isLoading: false,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}
