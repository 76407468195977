import type { IDialog } from './types';

export default new Proxy(
  {
    open(_value: IDialog) {
      console.log('not init');
    },
  },
  {
    set: (target, prop, value) => {
      target[prop] = value;

      return true;
    },
  }
);
