import ElementType from '../../../constants/ElementType';
import { makeProductByIdSelector } from '../../../redux/selectors/productById';
import { Impression } from '../../../types/CTR';
import * as navigationService from '../../navigationService';

export function getCTREventKey(data: Impression, currentStore) {
  const { id, pitNumber, layoutName, currentSubCat, elementType } = data;
  let theKey: string;

  switch (elementType) {
    case ElementType.CategoryListItem: {
      theKey = `${layoutName}_${currentSubCat.id}`;
      theKey += `_user_submit`;
      break;
    }
    case ElementType.Item: {
      const itemInfo = makeProductByIdSelector(id!)(currentStore);
      const source = data.source || itemInfo.source;

      theKey = `${layoutName}_${source}`;
      break;
    }
    default: {
      theKey = `${layoutName}_${pitNumber}`;
      break;
    }
  }

  return theKey;
}

export function getGeneralInfo() {
  const currentScreen =
    navigationService.screenSubject$.getValue()?.currentScreen;
  const { routeName } = currentScreen || {};

  return {
    route_name: routeName || 'UNKNOWN_ROUTE',
  };
}
