import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { PaymentSuccess } from '../../types/Payment';
import { getPayment } from '../actions/getPaymentActions';
import { postDirectPayCardActions } from '../actions/postDirectPayCardActions';
import { getPaymentsSA } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getPayment.success,
  failure: getPayment.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getPaymentEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(getPayment.request),
      getType(postDirectPayCardActions.success)
    ),
    switchMap(() => {
      return getPaymentsSA()
        .then((response: AxiosResponse<PaymentSuccess>) =>
          getPayment.success({
            data: response.data.payment_methods,
          })
        )
        .catch((error: AxiosError) => getPayment.failure({ error }));
    })
  );
};

export default getPaymentEpic;
