import { createSelector } from 'reselect';

import { makePromoteDataByKey } from './promotionalThemesSelector';
import { getRecommendProductsDataByKey } from './recommendProductsSelector';

export const makeIsFetchingAboutWholeHome = (defaultValue?: boolean) => {
  return createSelector(
    getRecommendProductsDataByKey('isFetchingRecommendProducts', true),
    makePromoteDataByKey('isFetching', defaultValue),
    (isFetchingRecommendProducts, isFetchingPromotionalTheme) => {
      return isFetchingRecommendProducts || isFetchingPromotionalTheme;
    }
  );
};

export const makeIsNewFetchAboutWholeHome = () => {
  return createSelector(
    getRecommendProductsDataByKey('loadingType'),
    makePromoteDataByKey('loadingType', 'new'),
    (loadingTypeOfRecommendProducts, loadingTypeOfPromoteItems) => {
      return (
        loadingTypeOfRecommendProducts === 'new' &&
        loadingTypeOfPromoteItems === 'new'
      );
    }
  );
};

export const makeIsReloadAboutWholeHome = () => {
  return createSelector(
    getRecommendProductsDataByKey('loadingType'),
    getRecommendProductsDataByKey('isFetchingRecommendProducts'),
    (loadingType, isFetchingRecommendProducts) => {
      return loadingType === 'reload' && isFetchingRecommendProducts;
    }
  );
};
