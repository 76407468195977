import { AxiosError, AxiosResponse } from 'axios';
import pick from 'lodash/pick';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { RecommendList } from '../../types/RecommendListResponse';
import { getRecommendList as recommendListAction } from '../actions/getRecommendList';
import { getRecommendList } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof recommendListAction.request>;
const outputActions = {
  success: recommendListAction.success,
  failure: recommendListAction.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const getRecommendListEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(recommendListAction.request)),
    mergeMap((action: RequestActionTypes) => {
      const params = pick(action.payload, [
        'id',
        'limit',
        'per_page',
        'page',
        'source_type',
      ]);

      return getRecommendList(params)
        .then((response: AxiosResponse<RecommendList>) => {
          const { id, page, success } = action.payload;
          // filter not auto quote items
          const products = response.data?.products || [];

          success(products);

          return recommendListAction.success({
            id,
            items: products,
            logEvent: AnalyticsEvent.RecommendList,
            page,
          });
        })
        .catch((error: AxiosError) => {
          const { id } = action.payload;

          return recommendListAction.failure({
            id,
            error,
            requestAction: action,
          });
        });
    })
  );
};
