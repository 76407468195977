import { memo, useMemo } from 'react';
import styled from 'styled-components/native';

import { neutralLight } from '../../../constants/color';
import Image from '../../../elements/Image';

interface Props {
  imageUrl: string;
  size: number;
  onLoad: () => void;
  webImgRef: (_: HTMLImageElement) => void;
}

function ProductImage(props: Readonly<Props>) {
  const { imageUrl, size, webImgRef, onLoad } = props;
  const source = useMemo(() => ({ uri: imageUrl }), [imageUrl]);

  return (
    <S.ProductImage
      webImgRef={webImgRef}
      size={size}
      source={source}
      onLoad={onLoad}
      enableLazyLoad
    />
  );
}

export default memo(ProductImage);

const S = {
  ProductImage: styled(Image)<{ size: number }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    background-color: ${neutralLight};
  `,
};
