import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import processLogEvents from '../../utils/analytics/processLogEvents';
import { RootAction, actionsList } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = [actionsList.analyticsLog, actionsList.logAnalyticsEvent];

type OutputActionTypes = ActionType<typeof outputActions>;

// 這個 epic 做的事情有點像是 thunk, 將 store 裡面的資料 和 合法action的資料 打包起來發一個 analyticsLog
//  analyticsLogReducer 統一處理 邏輯 跟 mapping
//
//     input                   output
//  合法的 actions => analyticsLog(action payload + storeData)

// todo： 想要把 logEvent 這個 payload 變成 action 默認的 payload
const analyticsLogEpic = (
  action$: Observable<RootAction>,
  state$: BehaviorSubject<RootState>
): Observable<OutputActionTypes> =>
  action$.pipe(
    filter(
      // 只會處理以下兩種情況
      // 包含有 logEvent 這個參數的 的 action
      // 或是唯一合法的 Action: logAnalyticsEvent
      (action: any = {}) =>
        action.type === getType(actionsList.logAnalyticsEvent) ||
        action?.payload?.logEvent
    ),
    map((action: any = {}) => {
      const { logEvent, ...logData } = action.payload;

      processLogEvents({ logEvent, logData, currentStore: state$.value });

      // no one subscribe this action for debug tool only
      return actionsList.analyticsLog({
        event: logEvent,
        logData,
        currentStore: state$.value,
      });
    })
  );

export default analyticsLogEpic;
