import { createAsyncAction } from 'typesafe-actions';

export interface VendorCollectPayload {
  id: string;
  success: () => void;
  failure: (errResp) => void;
}

export interface VendorCollectSuccessPayload {
  id: string;
}

export interface VendorCollectFailPayload {
  id: string;
  error: Error;
}

export const putVendorCollectActions = createAsyncAction(
  'verybuy/PUT_VENDOR_COLLECT',
  'verybuy/PUT_VENDOR_COLLECT_SUCCESS',
  'verybuy/PUT_VENDOR_COLLECT_FAIL'
)<
  VendorCollectPayload,
  VendorCollectSuccessPayload,
  VendorCollectFailPayload
>();
