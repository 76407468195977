import { ComponentType, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import IconBrandCheck from '../../assets/svg/IconBrandCheck';
import IconNewNavRight from '../../assets/svg/IconNewNavRight';
import { translate } from '../../i18n';
import { VendorType } from '../../types/VendorType';
import Placeholder from '../Placeholder';
import { S } from './styles';

interface Props {
  name: string;
  type: VendorType;
  img: string;
  isLoading?: boolean;
  isOffline?: boolean;
  isSearchBarVendor?: boolean;
  Footer?: ComponentType;
}

export default (props: Props) => {
  const { name, type, img, isOffline, isLoading, isSearchBarVendor, Footer } =
    props;

  const { palette } = useTheme();

  const imgSrc = useMemo(() => {
    return !img ? require('./assets/ic_brand_default.png') : { uri: img };
  }, [img]);

  if (isLoading) {
    return (
      <S.Container>
        <S.LogoContainer borderWidth={0}>
          <Placeholder width={62} height={62} />
        </S.LogoContainer>
        <S.Body>
          <Placeholder width={167} height={18} boxStyle={styles.placeholder} />
          <Placeholder width={100} height={18} boxStyle={styles.placeholder} />
        </S.Body>
      </S.Container>
    );
  }

  return (
    <S.Container isSearchBarVendor={isSearchBarVendor}>
      <S.LogoContainer
        borderWidth={isOffline ? 0 : 1}
        isSearchBarVendor={isSearchBarVendor}
      >
        <S.Logo source={imgSrc} isSearchBarVendor={isSearchBarVendor} />
        {isOffline && <S.OfflineMask />}
        {isOffline && (
          <S.OfflineBanner>
            <S.OfflineBannerText>
              {translate('vendor-offline')}
            </S.OfflineBannerText>
          </S.OfflineBanner>
        )}
      </S.LogoContainer>
      <S.Body>
        <S.Title disabled={isOffline} numberOfLines={1} ellipsizeMode="tail">
          {name}
        </S.Title>
        <S.Subtitle>
          {type === VendorType.Flagship && (
            <S.BrandIconWrapper>
              <IconBrandCheck size={14} />
            </S.BrandIconWrapper>
          )}
          <S.SubtitleText disabled={isOffline}>
            {translate(`vendor-type.${type}`)}
          </S.SubtitleText>
        </S.Subtitle>
      </S.Body>
      {Footer ? <Footer /> : null}
      {isSearchBarVendor && (
        <S.NavIconWrapper>
          <IconNewNavRight size={16} fillColor={palette.warrior} />
        </S.NavIconWrapper>
      )}
    </S.Container>
  );
};

const styles = StyleSheet.create({
  placeholder: {
    marginVertical: 2.5,
  },
});
