import { useEffect, useState } from 'react';
import { Text } from 'react-native';

import { coral } from '../../constants/color';
import { translate } from '../../i18n';
import * as navigationService from '../../utils/navigationService';
import LoadingView from '../LoadingView';
import Touchable from '../Touchable';
import styles from './style';

interface Props {
  screen: string;
  screenKey: string;
  isFocused?: boolean | undefined;
  text: string;
}

function defaultFunction() {
  return false;
}

export default function NavRightSaveButton({
  isFocused,
  screenKey,
  text,
}: Props) {
  const [data, setData] = useState({
    invalid: true,
    submitting: false,
    text: undefined,
    remoteSubmit: () => false,
  });

  function subscribeHeader(target, info) {
    const { type, ...restData } = info.data;

    if (info.from === `${target}-component` && type === 'navright') {
      setData({
        ...data,
        ...restData,
      });
    }
  }

  useEffect(() => {
    let observableSource;

    if (isFocused) {
      observableSource = navigationService.navbarSubject$.subscribe(info => {
        subscribeHeader(screenKey, info);
      });
    }

    return () => {
      if (observableSource) {
        observableSource.unsubscribe();
      }
    };
  }, [isFocused]);

  const isDisabled = data.invalid || data.submitting;
  const onPress =
    !isDisabled && data.remoteSubmit ? data.remoteSubmit : defaultFunction;

  return (
    <Touchable
      style={
        data.invalid
          ? [styles.submitReceivingShopButton, styles.disabled]
          : styles.submitReceivingShopButton
      }
      disabled={isDisabled}
      onPress={onPress}
      testID={isDisabled ? 'DisabledNavRightSaveButton' : 'NavRightSaveButton'}
    >
      {data.submitting ? (
        <LoadingView size="small" color={coral} testID="LoadingView" />
      ) : (
        <Text style={styles.submitReceivingShopText} testID="saveText">
          {data.text || text}
        </Text>
      )}
    </Touchable>
  );
}

NavRightSaveButton.defaultProps = {
  get text() {
    return translate('save');
  },
};
