import { getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Product } from '../../types/TopSales';
import * as arrayHandler from '../../utils/arrayHandler';
import { actionsList } from '../rootAction';

interface InitialState {
  items: Product[];
  loadingType: ListLoadingTypes;
  isFetching: boolean;
  hasMoreItems: boolean;
  page: number;
  error?: Error;
}

const { getTopSalesActions } = actionsList;
const INITIAL_STATE: InitialState = {
  items: [],
  loadingType: 'new',
  isFetching: false,
  hasMoreItems: false,
  page: 1,
};

export default function (state = INITIAL_STATE, action): InitialState {
  switch (action.type) {
    case getType(getTopSalesActions.request): {
      const { loadingType, page } = action.payload;
      const newItems = loadingType === 'new' ? [] : state.items;
      const hasMoreItems =
        loadingType === 'loadMore' ? state.hasMoreItems : true;

      return {
        ...state,
        items: newItems,
        isFetching: true,
        hasMoreItems,
        loadingType,
        page,
      };
    }
    case getType(getTopSalesActions.success): {
      const { items, loadingType, page, ...otherData } = action.payload;
      const itemsID = items.map(d => `${d.id}`);
      const newItems =
        loadingType === 'loadMore' ? state.items.concat(itemsID) : itemsID;

      return {
        ...state,
        isFetching: false,
        error: null,
        items: arrayHandler.uniqueArray(newItems),
        hasMoreItems: items.length > 0,
        loadingType,
        page,
        ...otherData,
      };
    }
    case getType(getTopSalesActions.failure): {
      return {
        ...state,
        isFetching: false,
        hasMoreItems: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
