import i18n, { Resource } from 'i18next';
import memoize from 'lodash/memoize';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANG } from '../constants/misc';
import enUS from './locales/en_US';
import zhTW from './locales/zh_TW';

function getResources(languages: Record<'key' | 'value', any>[]) {
  return languages.reduce<Resource>(
    (acc, cur) => ({ ...acc, [cur.key]: { global: cur.value } }),
    {}
  );
}

export function init(locale: string) {
  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
      resources: getResources([
        { key: 'zh', value: zhTW },
        { key: 'en', value: enUS },
      ]),
      // Fix compatibility issue: https://stackoverflow.com/a/70521614/9636125
      compatibilityJSON: 'v3',
      lng: locale,
      fallbackLng: DEFAULT_LANG,
      defaultNS: 'global',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        prefix: '%{',
        suffix: '}',
      },
    });

  return i18n;
}

type FunctionType = (key: string, config?: any) => string;

export const translate = memoize<FunctionType>(
  (key, config?) => i18n.t(key, config),
  (key, config?) => (config ? key + JSON.stringify(config) : key)
);

export default i18n;

export { useTranslation, withTranslation, Trans } from 'react-i18next';
