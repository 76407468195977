/**
 * Provide environment varibale to use firebase config
 * and /firebase-messaging-sw.js
 * ref: https://stackoverflow.com/a/71264193
 */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
var __EsaberifConfig = {
  apiKey: 'AIzaSyCWzQJmqk2kDC0DQK8zARQCUcRPgy2AZZw',
  authDomain: 'api-project-824146380774.firebaseapp.com',
  databaseURL: 'https://api-project-824146380774.firebaseio.com',
  projectId: 'api-project-824146380774',
  storageBucket: 'api-project-824146380774.appspot.com',
  messagingSenderId: '824146380774',
  appId: '1:824146380774:web:201fdcccf12d852de87827',
  measurementId: 'G-PX55MJS7CN',
};

try {
  // @ts-ignore
  global.__EsaberifConfig = __EsaberifConfig;
} catch (e) {}
