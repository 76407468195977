import hoistStatics from 'hoist-non-react-statics';
import { PureComponent } from 'react';

import { isWebPlatform } from '../../boot/utils';
import ElementType from '../../constants/ElementType';
import { MktType } from '../../constants/MktType';
import { couldLogFirehose } from '../../context/devLogFirehose';
import { ItemTypes } from '../../types/Common';
import ItemSources from '../../utils/ItemSources';

interface Props {
  id: number | string;
  itemType: ItemTypes;
  index: number;
  marketID?: string;
  layoutName?: string;
  logImpressionBatch: Function;
  enableWebImpression?: boolean;
  elementType?: ElementType;
  source?: string;
  sources?: ItemSources;
  shouldDisplayImpressionForDebug?: boolean;
  mktType?: MktType;
}

interface State {
  viewed: boolean;
}
const withIntersectionObserver = WrappedComponent => {
  class IntersectionObserverImpression extends PureComponent<Props, State> {
    static defaultProps = {
      enableWebImpression: false,
      firehoseLogStateForDebug: false,
      shouldDisplayImpressionForDebug: false,
      elementType: ElementType.Item,
    };

    constructor(props) {
      super(props);

      this.state = {
        viewed: false,
      };
    }
    wrappedComponent: any = null;
    /**
     * Important Note:
     * use IntersectionObserver to send impression in web
     * only callback if in web
     */
    viewedObserver: any | null = null;
    observerCallback = (entry: any) => {
      const isIntersecting = entry[0].isIntersecting;

      if (isIntersecting) {
        this.setState({ viewed: true });
        const {
          id,
          index,
          itemType,
          layoutName,
          marketID,
          source,
          sources,
          logImpressionBatch,
          elementType,
          mktType,
        } = this.props;
        const impressionData = {
          itemType,
          pitNumber: index + 1,
          id,
          layoutName,
          marketID,
          elementType,
          source: sources ? sources.get(`${id}`) : source,
          mktType,
        };

        logImpressionBatch({ data: [impressionData] });

        this.clearObserver();
      }
    };

    clearObserver = () => {
      // clear viewed observer
      if (!this.viewedObserver) {
        return;
      }
      this.viewedObserver.disconnect();
      this.viewedObserver = null;
    };

    componentWillUnmount() {
      this.clearObserver();
    }

    componentDidMount() {
      /**
       * Important Note:
       * use IntersectionObserver to send impression in web
       */
      if (!this.wrappedComponent || !this.wrappedComponent.webImg) {
        // no wrappedComponent
        return;
      }
      if (this.props.enableWebImpression && isWebPlatform) {
        const viewed = this.state.viewed;

        if (!viewed) {
          try {
            this.viewedObserver = new IntersectionObserver(
              this.observerCallback,
              {
                threshold: [0.8],
              }
            );

            this.viewedObserver.observe(this.wrappedComponent.webImg);
          } catch (e) {
            console.log('not support IntersectionObserver');
          }
        }
      }
    }
    render() {
      // there are tow sources from shouldDisplayImpressionForDebug
      // * props.shouldDisplayImpressionForDebug (FlatListWithImpression uses this, eq: TopSalesList in HomePage)
      // * state.viewed (viewedObserver uses this, eq: RecommentList in ItemPage)
      const shouldDisplayImpressionForDebug =
        couldLogFirehose() &&
        (this.state.viewed || this.props.shouldDisplayImpressionForDebug);

      return (
        <WrappedComponent
          ref={_ => (this.wrappedComponent = _)}
          {...this.props}
          shouldDisplayImpressionForDebug={shouldDisplayImpressionForDebug}
        />
      );
    }
  }

  return hoistStatics(IntersectionObserverImpression, WrappedComponent);
};

export default withIntersectionObserver;
