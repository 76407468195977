import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { addToCart } from '../actions/addToCartActions';
import { loadCartContent } from '../actions/loadCartContentActions';
import { putAddToCart } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof addToCart.request>;
const outputActions = {
  success: addToCart.success,
  failure: addToCart.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const addToCartEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(addToCart.request)),
    switchMap((action: RequestActionTypes) => {
      const {
        id,
        amount,
        color_id: colorId,
        size_id: sizeId,
        color_name: colorName,
        size_name: sizeName,
        failure,
        isComboCart = false,
      } = action.payload;

      return from(putAddToCart(id, amount, colorId, sizeId))
        .pipe(
          concatMap(() => {
            return [
              addToCart.success({
                id,
                colorName,
                sizeName,
                isComboCart,
                amount,
              }),
              loadCartContent.request(),
            ];
          })
        )
        .pipe(
          catchError((error: AxiosError) => {
            const errorResp = error.response;

            failure(errorResp);

            return of(
              addToCart.failure({
                id,
                error,
              })
            );
          })
        );
    })
  );
};
