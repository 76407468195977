import styled from 'styled-components/native';

import { makeVendorNameById } from '../../../redux/selectors/productById';
import { useSelector } from '../../../redux/utils';

interface Props {
  id: string;
}

export default function Vendor({ id }: Props) {
  const vendor = useSelector(makeVendorNameById(id));

  if (vendor.length === 0) {
    return null;
  }

  return (
    <S.Text numberOfLines={1} ellipsizeMode="tail">
      {vendor}
    </S.Text>
  );
}

const S = {
  Text: styled.Text`
    color: ${({ theme }) => theme.palette.neutralDark};
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 12px;
  `,
};
