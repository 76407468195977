import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PanResponder, View } from 'react-native';
import { Animated, ListRenderItem, StyleProp, ViewStyle } from 'react-native';
import { useLatest } from 'react-use';

import { useOrientation } from '../../boot/OrientationContext';
import { isAndroid, isWebPlatform } from '../../boot/utils';
import FlatListWithImpression, {
  FlatListWithImpressionType,
} from '../../components/FlatListWithImpression';
import { basicProducer } from '../../components/FlatListWithImpression/utils';
import { GET_LAYOUT_MAX_WIDTH } from '../../constants/layout';
import { MktType } from '../../constants/MktType';
import { SetPartial } from '../../types';
import Pagination, { DotStyle } from './Pagination';

interface Props {
  bannerWidth: number;
  layoutName: string;
  carouselData: string[];
  renderSlide: ListRenderItem<string>;
  isVisible?: boolean;
  play?: boolean;
  duration?: number;
  paginationWrapperStyle?: StyleProp<ViewStyle>;
  dotStyle?: DotStyle;
  shouldLogImpression?: boolean;
}

const keyExtractor = (item: string) => item;

const viewabilityConfig = {
  viewAreaCoveragePercentThreshold: 50,
};

export interface ExportProperties {
  accumulatedItems?: Map<string, number>;
}

const useAndroidWorkaround = () => {
  const ref = useRef<number | null>(null);

  const clear = useCallback(() => {
    if (ref.current !== null) {
      cancelAnimationFrame(ref.current);
      ref.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  return useCallback(
    (callback: () => void) => {
      clear();

      if (isAndroid) {
        ref.current = requestAnimationFrame(callback);
      } else {
        callback();
      }
    },
    [clear]
  );
};

const BannerCarousel = forwardRef<ExportProperties, Props>((props, ref) => {
  const {
    carouselData,
    renderSlide,
    play,
    duration,
    bannerWidth,
    paginationWrapperStyle,
    dotStyle,
    layoutName,
    isVisible,
    shouldLogImpression,
  } = props;
  const theCarousel = useRef<FlatListWithImpressionType>(null);
  const [bannerIndex, setBannerIndex] = useState(0);
  const bannerIndexRef = useLatest(bannerIndex);
  const lastIndexRef = useLatest(carouselData.length - 1);

  const bannerProgress = useRef(new Animated.Value(0));
  const timing = useRef<Animated.CompositeAnimation>(
    Animated.timing(bannerProgress.current, {
      toValue: 1,
      duration,
      delay: 300,
      useNativeDriver: false,
    })
  );

  useOrientation();

  useImperativeHandle(ref, () => ({
    get accumulatedItems() {
      return theCarousel.current?.accumulatedItems;
    },
  }));

  const [containerWidth, setContainerWidth] = useState(GET_LAYOUT_MAX_WIDTH());

  const fixAndroidWorkaround = useAndroidWorkaround();

  const scrollToIndex = useCallback(newIndex => {
    theCarousel.current?.scrollToIndex({
      index: newIndex,
      animated: false,
    });

    bannerProgress.current.setValue(0);

    /**
     * 【workaround】修正 Android 不預期的 banner 閃爍問題
     * 發現 translateX 的 setValue 跟 scrollToIndex 同時執行在 Android 上會造成 banner 閃爍
     * translateX 的 setValue 必須晚於 scrollToIndex
     */
    fixAndroidWorkaround(() => {
      animatedTranslateX.current.setValue(0);
    });
  }, []);

  const animateTranslateX = useCallback(
    (toValue: number, scrollIndexOnFinished?: number) => {
      Animated.timing(animatedTranslateX.current, {
        toValue,
        duration: 200,
        useNativeDriver: !isWebPlatform,
      }).start(({ finished }) => {
        if (finished && typeof scrollIndexOnFinished !== 'undefined') {
          scrollToIndex(scrollIndexOnFinished);
        }
      });
    },
    [scrollToIndex]
  );

  const getNextIndex = useCallback(currentIndex => {
    return currentIndex >= lastIndexRef.current ? 0 : currentIndex + 1;
  }, []);

  const scrollNext = useCallback(() => {
    const currentIndex = bannerIndexRef.current;

    const newIndex = getNextIndex(currentIndex);

    animateTranslateX(-containerWidth, newIndex);
  }, [getNextIndex, containerWidth, animateTranslateX]);

  const getPreviousIndex = useCallback(currentIndex => {
    const lastIndex = lastIndexRef.current;

    return currentIndex <= 0 ? lastIndex : currentIndex - 1;
  }, []);

  const scrollPrevious = useCallback(() => {
    const currentIndex = bannerIndexRef.current;

    const newIndex = getPreviousIndex(currentIndex);

    animateTranslateX(containerWidth, newIndex);
  }, [getPreviousIndex, containerWidth, animateTranslateX]);

  const stopAutoPlay = useCallback(() => {
    if (timing.current) {
      timing.current.stop();
    }
  }, []);

  const startAutoPlay = useCallback(() => {
    timing.current.start(({ finished }) => {
      if (finished) {
        timing.current.reset();
        scrollNext();
      }
    });
  }, [scrollNext]);

  const onViewableItemsChanged = useCallback(({ viewableItems }) => {
    if (viewableItems.length > 0) {
      const currentIndex = viewableItems[0].index;

      setBannerIndex(currentIndex);
    }
  }, []);

  useEffect(() => {
    if (play) {
      startAutoPlay();
    }

    return () => {
      stopAutoPlay();
    };
  }, [startAutoPlay, stopAutoPlay, play, bannerIndex]);

  const getItemLayout = useCallback(
    (_, index) => ({
      length: bannerWidth,
      offset: bannerWidth * index,
      index,
    }),
    [bannerWidth]
  );

  const animatedTranslateX = useRef(new Animated.Value(0));
  const dxRef = useRef(0);

  const onPanResponderFinish = useCallback(() => {
    if (Math.abs(dxRef.current) > 30) {
      if (dxRef.current > 0) {
        scrollPrevious();
      } else {
        scrollNext();
      }
    } else {
      animateTranslateX(0);
    }

    dxRef.current = 0;
  }, [scrollNext, scrollPrevious]);

  const panResponder = useMemo(
    () =>
      PanResponder.create({
        onMoveShouldSetPanResponderCapture: (_, gestureState) => {
          const { dx } = gestureState;

          return dx > 2 || dx < -2;
        },
        onPanResponderMove: (_, gestureState) => {
          dxRef.current = gestureState.dx;
          animatedTranslateX.current.setValue(gestureState.dx);
        },
        onPanResponderTerminationRequest: () => true,
        onPanResponderRelease: onPanResponderFinish,
        onPanResponderTerminate: onPanResponderFinish,
      }),
    [onPanResponderFinish]
  );

  const onContainerLayout = useCallback(({ nativeEvent }) => {
    setContainerWidth(nativeEvent.layout.width);
  }, []);

  const containerStyle = useMemo(
    () => ({ transform: [{ translateX: animatedTranslateX.current }] }),
    []
  );

  const lastIndex = getPreviousIndex(bannerIndex);
  const nextIndex = getNextIndex(bannerIndex);

  const renderFakeBanner = useCallback(
    (left: number, index: number, key: string) => {
      return (
        <View key={key} style={{ position: 'absolute', left }}>
          {
            // @ts-expect-error separator is not defined, but it's okay we are not using it
            renderSlide({ item: carouselData[index], index })
          }
        </View>
      );
    },
    [carouselData]
  );

  return (
    <>
      <Animated.View
        onLayout={onContainerLayout}
        style={containerStyle}
        {...panResponder.panHandlers}
      >
        {renderFakeBanner(-containerWidth, lastIndex, 'fake-banner-left')}
        <FlatListWithImpression
          ref={theCarousel}
          snapToAlignment="start"
          decelerationRate="fast"
          data={carouselData}
          renderItem={renderSlide}
          onEndReachedThreshold={0}
          keyExtractor={keyExtractor}
          showsHorizontalScrollIndicator={false}
          snapToInterval={bannerWidth}
          getItemLayout={getItemLayout}
          onTouchStart={stopAutoPlay}
          onTouchEnd={startAutoPlay}
          horizontal
          pagingEnabled
          scrollEnabled={false}
          removeClippedSubviews={false}
          onViewableItemsChanged={onViewableItemsChanged}
          viewabilityConfig={viewabilityConfig}
          layoutName={layoutName}
          mktType={MktType.Image}
          dataProducer={shouldLogImpression && basicProducer}
          isVisible={isVisible}
        />
        {renderFakeBanner(containerWidth, nextIndex, 'fake-banner-right')}
      </Animated.View>
      {carouselData.length > 1 && (
        <Pagination
          bannerProgress={bannerProgress}
          currentSlideIndex={bannerIndex}
          slideCount={carouselData.length}
          wrapperStyle={paginationWrapperStyle}
          dotStyle={dotStyle}
        />
      )}
    </>
  );
});

BannerCarousel.defaultProps = {
  play: true,
  duration: 3000,
};

export default memo(
  forwardRef<ExportProperties, SetPartial<Props, 'carouselData'>>(
    (props, ref) => {
      const { carouselData, renderSlide, ...rest } = props;

      if (!carouselData || carouselData.length === 0) {
        // @ts-expect-error separator is not defined, but it's okay we are not using it
        return renderSlide({ item: 'default', index: 0 });
      }

      return (
        <BannerCarousel
          ref={ref}
          renderSlide={renderSlide}
          carouselData={carouselData}
          {...rest}
        />
      );
    }
  )
);
