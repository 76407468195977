import { createAsyncAction } from 'typesafe-actions';

export interface DeleteDirectPayCardPayload {
  card_id: number;
}

export interface DeleteDirectPayCardSuccessPayload {
  card_id: number;
}
export interface DeleteDirectPayCardFailPayload {
  error: Error;
}

export const deleteDirectPayCardActions = createAsyncAction(
  'verybuy/DELETE_DIRECT_PAY_CARD',
  'verybuy/DELETE_DIRECT_PAY_CARD_SUCCESS',
  'verybuy/DELETE_DIRECT_PAY_CARD_FAIL'
)<
  DeleteDirectPayCardPayload,
  DeleteDirectPayCardSuccessPayload,
  DeleteDirectPayCardFailPayload
>();
