import { createAsyncAction } from 'typesafe-actions';

import { SortBy } from '../../constants/sortData';
import { ListLoadingTypes } from '../../types/Common';
import { PayloadWithFilter } from '../../types/PayloadWithFIlter';
import {
  SearchUserSubmit,
  SearchUserSubmitItem,
} from '../../types/SearchUserSubmit';

export interface SearchPayload extends PayloadWithFilter {
  queryText: string;
  loadingType: ListLoadingTypes;
  page: number;
  topIds?: string;
  sortBy: SortBy;
  isHistoryWordApplied: boolean;
  success: (products: SearchUserSubmitItem[]) => void;
  fuzzy?: number;
  isFuzzyDiscoverySearch?: boolean;
}

export interface SearchSuccessPayload {
  data: SearchUserSubmit;
  loadingType: ListLoadingTypes;
  page: number;
  queryText: string;
  logEvent?: string;
  isHistoryWordApplied?: boolean;
  isSuccess?: boolean;
  resultNumber?: number;
  sortBy?: SortBy;
  minPrice?: string;
  maxPrice?: string;
  isFuzzyResult?: boolean;
  isFuzzyDiscoverySearch?: boolean;
  fuzzy?: number;
}

export interface SearchFailPayload {
  error: Error;
}

export const searchActions = createAsyncAction(
  'verybuy/SEARCH',
  'verybuy/SEARCH_SUCCESS',
  'verybuy/SEARCH_FAIL'
)<SearchPayload, SearchSuccessPayload, SearchFailPayload>();
