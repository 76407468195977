import { PureComponent } from 'react';

import { LoginOrRegisterMethod } from '../../../constants/LoginOrRegisterMethod';
import { ThirdPartyBtn } from '../ThirdPartySection';

interface Props {
  isLoading: boolean;
  isSupported: boolean;
  onPress: () => void;
  testID: string;
}

export default class AppleLoginButton extends PureComponent<Props> {
  static defaultProps = {
    isLoading: false,
  };

  onPress = () => {
    const { isLoading, onPress } = this.props;

    if (isLoading) {
      return;
    }

    onPress();
  };

  render() {
    const { isLoading, isSupported } = this.props;

    if (!isSupported) {
      return null;
    }

    return (
      <ThirdPartyBtn
        type={LoginOrRegisterMethod.APPLE}
        onPress={this.onPress}
        isLoading={isLoading}
      />
    );
  }
}
