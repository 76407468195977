import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Coupon } from '../../types/EventCoupon';

export interface EventCouponPayload {
  loadingType: ListLoadingTypes;
}

export interface EventCouponSuccessPayload {
  coupons: Coupon[];
}

export interface EventCouponFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getEventCouponActions.request>;
}

export const getEventCouponActions = createAsyncAction(
  'verybuy/GET_EVENT_COUPON',
  'verybuy/GET_EVENT_COUPON_SUCCESS',
  'verybuy/GET_EVENT_COUPON_FAIL'
)<EventCouponPayload, EventCouponSuccessPayload, EventCouponFailPayload>();
