import { createAsyncAction } from 'typesafe-actions';

export interface ItemLovesPayload {
  id: string;
  success?: Function;
  failure?: Function;
  shouldRemoveFromList?: boolean;
}

export interface ItemLovesSuccessPayload {
  id: string;
  shouldRemoveFromList?: boolean;
}

export interface ItemLovesFailPayload {
  id: string;
  error: Error;
}

export const deleteItemLovesActions = createAsyncAction(
  'verybuy/DELETE_ITEM_LOVES',
  'verybuy/DELETE_ITEM_LOVES_SUCCESS',
  'verybuy/DELETE_ITEM_LOVES_FAIL'
)<ItemLovesPayload, ItemLovesSuccessPayload, ItemLovesFailPayload>();
