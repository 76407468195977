import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { OnSaleItem, OnSaleResponse } from '../../types/OnSaleResponse';
import { PayloadWithFilter } from '../../types/PayloadWithFIlter';

export interface OnSalePayload extends PayloadWithFilter {
  page: number;
  loadingType: ListLoadingTypes;
  success: (products: OnSaleItem[]) => void;
}

export interface OnSaleSuccessPayload {
  data: OnSaleResponse;
  page: number;
}

export interface OnSaleFailPayload {
  error: Error;
  requestAction:
    | ReturnType<typeof getOnSaleActions.request>
    | ReturnType<typeof getIndexOnSaleActions.request>;
}

export const getOnSaleActions = createAsyncAction(
  'verybuy/GET_ON_SALE',
  'verybuy/GET_ON_SALE_SUCCESS',
  'verybuy/GET_ON_SALE_FAIL'
)<OnSalePayload, OnSaleSuccessPayload, OnSaleFailPayload>();

export const getIndexOnSaleActions = createAsyncAction(
  'verybuy/GET_INDEX_ON_SALE',
  'verybuy/GET_INDEX_ON_SALE_SUCCESS',
  'verybuy/GET_INDEX_ON_SALE_FAIL'
)<
  Pick<OnSalePayload, 'success' | 'loadingType'>,
  Pick<OnSaleSuccessPayload, 'data'>,
  OnSaleFailPayload
>();
