import { createAsyncAction } from 'typesafe-actions';

import { TopKeywordsData } from '../../types/TopKeywordsResponse';

export interface TopKeywordsPayload {}

export interface TopKeywordsSuccessPayload {
  data: TopKeywordsData;
}

export interface TopKeywordsFailPayload {
  error: Error;
}

export const getTopKeywordsActions = createAsyncAction(
  'verybuy/GET_TOP_KEYWORDS',
  'verybuy/GET_TOP_KEYWORDS_SUCCESS',
  'verybuy/GET_TOP_KEYWORDS_FAIL'
)<TopKeywordsPayload, TopKeywordsSuccessPayload, TopKeywordsFailPayload>();
