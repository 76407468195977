import Currency from './Currency';
import { ShippingFee } from './DefaultShippingFeesData';

export enum PaymentMethods {
  CREDIT_CARD = 1,
  COD = 2,
  CVS = 3,
  ATM = 4,
  LINE_PAY = 5,
  APPLE_PAY = 6,
}

export enum DeliveryMethods {
  HOME = 1,
  CVS = 2,
}

export interface VBConfigData {
  payment_methods: PaymentMethods[];
  delivery_methods: DeliveryMethods[];
  currencies: Currency[];
  default_shipping_fees: ShippingFee[];
}
