import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getOnboardingActions } from '../actions/getOnboardingActions';
import { getOnboarding } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof getOnboardingActions.request>;
const outputActions = {
  success: getOnboardingActions.success,
  failure: getOnboardingActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getOnboardingEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getOnboardingActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { page, loadingType, success } = action.payload;

      // TODO
      // loading type
      return getOnboarding(page)
        .then(response => {
          const { data } = response;

          if (typeof success === 'function') {
            success(data.products);
          }

          return getOnboardingActions.success({
            data,
            loadingType,
            page,
          });
        })
        .catch((error: AxiosError) => getOnboardingActions.failure({ error }));
    })
  );
};

export default getOnboardingEpic;
