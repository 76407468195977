import { createSelector } from 'reselect';

import { MemberData } from '../reducers/memberReducer';
import { State as MemberVerifyState } from '../reducers/memberVerifyReducer';
import { RootState } from '../rootReducer';

const memberRaw = (state: RootState) => state?.memberReducer || {};
const memberVerifyRaw = state => state?.memberReducer?.verifyData || {};

export const makeIsLoading = () => {
  return createSelector(memberRaw, data => data?.isLoading || false);
};

export const makeIsMemberInfoLoading = () => {
  return createSelector(memberRaw, data => data?.isMemberInfoLoading || false);
};

export const makeIsPostForgetPasswordFetching = () => {
  return createSelector(
    memberRaw,
    data => data.isPostForgetPasswordFetching || false
  );
};

export const makeHasError = () => {
  return createSelector(memberRaw, data => data?.hasError || false);
};

export const makeLoginMethod = () => {
  return createSelector(memberRaw, data => data?.loginMethod || '');
};

export const makeErrorReason = () => {
  return createSelector(memberRaw, data => {
    const reason = data?.error?.response?.data?.reason;
    const message = data?.error?.response?.data?.message;

    return reason || message || '';
  });
};

export const makeMemberInfo = () => {
  return createSelector(memberRaw, data => data?.data || {});
};

export const makeMemberInfoByKey = <K extends keyof MemberData>(key: K) => {
  return createSelector(makeMemberInfo(), data => {
    return data?.[key];
  });
};

export const makeIsNewCustomer = () => {
  return createSelector(memberRaw, data => data.isNewCustomer || false);
};

export const makeIsFetchingNewCustomer = () => {
  return createSelector(memberRaw, data => data.isFetchingNewCustomer || false);
};

export const makeMemberRole = () => {
  return createSelector(
    makeMemberInfoByKey('MID'),
    makeMemberInfoByKey('token'),
    (MID, token) => {
      if (MID && token) {
        return 'member';
      }
      if (!MID && token) {
        return 'guest';
      }

      return 'ghost';
    }
  );
};

export const makeIsLogin = () => {
  return createSelector(makeMemberRole(), role => role === 'member');
};

export const makeMemberVerifyData = (key: keyof MemberVerifyState) => {
  return createSelector(memberVerifyRaw, data => data[key]);
};

export const makeHasReachedVerifyLimit = () => {
  return createSelector(memberVerifyRaw, data => data?.remainingTimes === 0);
};
