import { StyleSheet } from 'react-native';

import { coral } from '../../constants/color';

const styles: any = StyleSheet.create({
  submitReceivingShopButton: {
    marginRight: 15,
  },
  submitReceivingShopText: {
    color: coral,
    fontSize: 15,
  },
  disabled: {
    opacity: 0.4,
  },
});

export default styles;
