import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getLabelItemsActions } from '../actions/getLabelItemsActions';
import { getLabelItems } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getLabelItemsActions.success,
  failure: getLabelItemsActions.failure,
};

type RequestActionType = ActionType<typeof getLabelItemsActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getLabelItemsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getLabelItemsActions.request)),
    switchMap((action: RequestActionType) => {
      const { loadingType, success, dispatch, failure, ...restParams } =
        action.payload;

      if (dispatch) {
        dispatch(action);
      }

      return getLabelItems(restParams)
        .then(response => {
          const data = response.data;

          success(data.products);

          const successAction = getLabelItemsActions.success({
            loadingType,
            data,
          });

          if (dispatch) {
            dispatch(successAction);
          }

          return successAction;
        })
        .catch((error: AxiosError) => {
          failure(error.response?.status || 404);

          const failureAction = getLabelItemsActions.failure({
            error,
            requestAction: action,
          });

          if (dispatch) {
            dispatch(failureAction);
          }

          return failureAction;
        });
    })
  );
};

export default getLabelItemsEpic;
