/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { CategoriesData } from '../../types/Categories';
import { fetchCategories } from '../actions/categoriesActions';
import { getCategories } from '../api';
import { RootAction } from '../rootAction';

const outputActions = {
  success: fetchCategories.success,
  failure: fetchCategories.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const fetchCategoriesEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(fetchCategories.request)),
    concatMap((action: ActionType<typeof fetchCategories.request>) => {
      const { position } = action.payload;

      return getCategories()
        .then((response: AxiosResponse<CategoriesData>) => {
          return fetchCategories.success({
            categories: response.data.category_ids,
            position,
          });
        })
        .catch((error: AxiosError) =>
          fetchCategories.failure({ position, error })
        );
    })
  );
};

export default fetchCategoriesEpic;
