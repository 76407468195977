import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { CommonErrors } from '../../constants/VBErrorCode';
import { CartContent } from '../../types/CartContent';
import { clearData } from '../actions/clearDataActions';
import { loadCartContent } from '../actions/loadCartContentActions';
import { getLoadCartContent } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof loadCartContent.request>;
const outputActions = {
  success: loadCartContent.success,
  failure: loadCartContent.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const loadCartContentEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(loadCartContent.request)),
    switchMap((action: RequestActionTypes) => {
      const preserveSelection =
        (action.payload && action.payload.preserveSelection) || false;

      return from(getLoadCartContent()).pipe(
        concatMap((response: AxiosResponse<CartContent>) => {
          const { data } = response;
          const { success } = action.payload || {};

          if (success) {
            success(data.cart_contents);
          }

          return of(
            loadCartContent.success({
              result: data,
              preserveSelection,
              logEvent: AnalyticsEvent.UpdateCart,
            })
          );
        }),
        catchError((error: AxiosError) => {
          const errorCode = error?.response?.data?.error_code;
          const resultActions: RootAction[] = [
            loadCartContent.failure({
              error,
              customErrorHandling: true,
            }),
          ];

          if (errorCode === CommonErrors.ERR_INVALID_ARGUMENT) {
            resultActions.push(clearData());
          }

          return resultActions;
        })
      );
    })
  );
};
