import isUndefined from 'lodash/isUndefined';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';

import { CommonErrors } from '../../constants/VBErrorCode';
import * as memberSelector from '../../redux/selectors/member';
import { clearData } from '../actions/clearDataActions';
import { RootAction, actionsList } from '../rootAction';
import { RootState } from '../rootReducer';

const { updateApiCommonError } = actionsList;

const apiCommonErrorEpic = (
  action$: Observable<RootAction>,
  state$: BehaviorSubject<RootState>
) => {
  return action$.pipe(
    map((action: any = {}) => action?.payload),
    // 若有 api 要自己處理錯誤的話，帶 customErrorHandling
    filter((payload = {}) => isUndefined(payload.customErrorHandling)),
    filter(payload => !isUndefined(payload?.error?.response)),
    concatMap(payload => {
      const {
        error: { response: errorResp },
        requestAction,
      } = payload;
      const { status: httpStatus } = errorResp;
      const data = errorResp.data || {};
      const role = memberSelector.makeMemberRole()(state$.value);
      const errorCode = data.error_code;

      if (requestAction && errorCode === CommonErrors.ERR_UNAUTHORIZED_USER) {
        return of(clearData(), requestAction);
      }

      return of(updateApiCommonError({ httpStatus, errorCode, role }));
    })
  );
};

export default apiCommonErrorEpic;
