import { createAsyncAction } from 'typesafe-actions';

import { Data as FaqData } from '../../types/Faq';

export interface FaqPayload {
  faqVersionCode: string;
  failure: () => void;
}
export interface FaqSuccessPayload {
  data: FaqData;
}

export interface FaqFailPayload {
  error: Error;
}

export const getFaqActions = createAsyncAction(
  'verybuy/GET_FAQ',
  'verybuy/GET_FAQ_SUCCESS',
  'verybuy/GET_FAQ_FAIL'
)<FaqPayload, FaqSuccessPayload, FaqFailPayload>();
