import { createAsyncAction } from 'typesafe-actions';

import { PaymentData } from '../../types/Payment';

export interface GetPaymentPayload {}

export interface GetPaymentSuccessPayload {
  data: PaymentData;
}

export interface GetPaymentFailPayload {
  error: Error;
}

export const getPayment = createAsyncAction(
  'verybuy/GET_PAYMENT',
  'verybuy/GET_PAYMENT_SUCCESS',
  'verybuy/GET_PAYMENT_FAIL'
)<GetPaymentPayload, GetPaymentSuccessPayload, GetPaymentFailPayload>();
