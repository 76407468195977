import { memo } from 'react';
import { HeaderTitle } from 'react-navigation-stack';

import withHelmet from '../../decorators/withHelmet';
import { useMeta } from '../../hooks/useSEO';

export const headerTitleHelmetFactory = Wrapper => {
  return memo(
    withHelmet(({ children, ...props }) => {
      return <Wrapper {...props}>{children}</Wrapper>;
    })
  );
};

const HeaderTitleWithHelmet = ({
  headerTitle,
  headerSuffix,
  description,
  hidden = false,
}: {
  description?: string;
  headerTitle?: string;
  headerSuffix?: string;
  hidden?: boolean;
}) => {
  const meta = useMeta(headerTitle, headerSuffix, description);
  const Title = headerTitleHelmetFactory(() => {
    if (hidden) {
      return <></>;
    }

    return <HeaderTitle>{headerTitle}</HeaderTitle>;
  });

  return <Title meta={meta} />;
};

export default memo(HeaderTitleWithHelmet);
