import { Component, createRef } from 'react';
import { Keyboard } from 'react-native';
import { connect } from 'react-redux';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import Dialog from '../../elements/Dialog';
import { AnimationFrameUtils, Methods } from '../../hooks/useAnimationFrame';
import {
  LogEventPayload,
  logAnalyticsEvent,
} from '../../redux/actions/analyticsLog';
import globalNormalDialogProxy from './globalNormalDialogProxy';
import type { IDialog } from './types';

interface State extends IDialog {
  showDialog: boolean;
}

interface Props {
  logEvent: (payload: LogEventPayload) => void;
}

class GlobalNormalDialog extends Component<Props> {
  private readonly INIT_STATE: IDialog = {
    useVerticalBtn: false,
    positiveText: '',
    titleText: undefined,
    negativeText: undefined,
    contentText: undefined,
    dialogImageUrl: undefined,
    handleDialogPositiveAction: undefined,
    handleDialogNegativeAction: undefined,
    handleTouchOutside: undefined,
    handleDismiss: undefined,
    children: undefined,
    testID: undefined,
  };

  state: State = {
    ...this.INIT_STATE,
    showDialog: false,
  };

  animationFrameUtils = createRef<Methods>();

  componentDidMount() {
    globalNormalDialogProxy.open = (params: IDialog) => {
      Keyboard.dismiss();

      this.animationFrameUtils.current?.requestFrame(() => {
        this.setState(
          {
            // 清除前一次的資料
            ...this.INIT_STATE,
            ...params,
            showDialog: true,
          },
          () => this.sendLightboxEvent(AnalyticsEvent.SendLightbox)
        );
      });
    };
  }

  sendLightboxEvent = (
    eventName: AnalyticsEvent,
    otherParams: Record<string, any> = {}
  ) => {
    const { logEvent } = this.props;
    const { lightboxName, lightboxTitle, lightboxContent, lightboxPage } =
      this.state;

    logEvent({
      logEvent: eventName,
      data: {
        lightbox_name: lightboxName,
        lightbox_title: lightboxTitle,
        lightbox_content: lightboxContent,
        lightbox_page: lightboxPage,
        ...otherParams,
      },
    });
  };

  sendLightboxClickEvent = (btnText: string) => {
    this.sendLightboxEvent(AnalyticsEvent.LightboxButtonClick, {
      lightbox_button_name: btnText,
    });
  };

  handleCloseDialog = () => {
    // Don't set other states, if so, dialog becomes empty first, then close.
    this.setState({ showDialog: false });
  };

  handleDialogPositiveAction = () => {
    const { positiveText, handleDialogPositiveAction } = this.state;

    if (handleDialogPositiveAction) {
      handleDialogPositiveAction();
    }

    this.sendLightboxClickEvent(positiveText);
    this.handleCloseDialog();
  };

  handleDialogNegativeAction = () => {
    const { negativeText, handleDialogNegativeAction } = this.state;

    if (handleDialogNegativeAction) {
      handleDialogNegativeAction();
    }

    this.sendLightboxClickEvent(negativeText!);
    this.handleCloseDialog();
  };

  handleTouchOutside = () => {
    const { handleTouchOutside } = this.state;

    if (handleTouchOutside) {
      handleTouchOutside();
    }

    this.sendLightboxClickEvent('LightBox 空白處');
  };

  render() {
    const {
      titleText,
      useVerticalBtn,
      positiveText,
      negativeText,
      contentText,
      dialogImageUrl,
      showDialog,
      children,
      testID,
      handleDismiss,
    } = this.state;

    return (
      <>
        <AnimationFrameUtils ref={this.animationFrameUtils} />
        <Dialog
          titleText={titleText}
          positiveText={positiveText}
          negativeText={negativeText}
          contentText={contentText}
          dialogImageUrl={dialogImageUrl}
          handleCloseDialog={this.handleCloseDialog}
          handleDialogPositiveAction={this.handleDialogPositiveAction}
          handleDialogNegativeAction={this.handleDialogNegativeAction}
          handleTouchOutside={this.handleTouchOutside}
          handleDismiss={handleDismiss}
          showDialog={showDialog}
          useVerticalBtn={useVerticalBtn}
          testID={testID}
        >
          {children}
        </Dialog>
      </>
    );
  }
}

const mapDispatchToProps = {
  logEvent: logAnalyticsEvent,
};

export {
  GlobalNormalDialog,
  // FIXME: 因為還有很多 globalNormalDialogProxy 是從這個檔案拿，先還是讓他 export
  globalNormalDialogProxy,
};

export default connect(null, mapDispatchToProps)(GlobalNormalDialog);
