import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { putMemberVerifyPhoneActions } from '../actions/putMemberVerifyPhoneActions';
import { putMemberVerifyPhone } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: putMemberVerifyPhoneActions.success,
  failure: putMemberVerifyPhoneActions.failure,
};

type RequestActionTypes = ActionType<
  typeof putMemberVerifyPhoneActions.request
>;
type OutputActionTypes = ActionType<typeof outputActions>;

const putMemberVerifyPhoneEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(putMemberVerifyPhoneActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { phoneNumber, success, failure } = action.payload;

      return putMemberVerifyPhone(phoneNumber)
        .then(() => {
          success();

          return putMemberVerifyPhoneActions.success();
        })
        .catch((error: AxiosError) => {
          failure(error);

          return putMemberVerifyPhoneActions.failure({
            error,
            customErrorHandling: true,
          });
        });
    })
  );
};

export default putMemberVerifyPhoneEpic;
