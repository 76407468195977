import pickBy from 'lodash/pickBy';
import safeAwait from 'safe-await';

import firebaseConfig from '../../../firebase.json';
import { isAndroid, isIOS } from '../../boot/utils';
import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { coral } from '../../constants/color';
import notifee, {
  AndroidImportance,
  AndroidStyle,
  IOSNotificationAttachment,
  NotificationAndroid,
  NotificationIOS,
} from '../packages/notifee';
import { getIsDisableNotiPermission } from '../permissionHelpers';
import logPushNotification from './logPushNotification';
import { PushNotificationType } from './types';

/**
 * Android notification configuration
 */
export function getAndroidConfiguration({
  imageUrl,
  body,
}: {
  imageUrl?: string;
  body?: string;
}) {
  /**
   * set importance because of some devices don't support Channel
   */
  const importance = AndroidImportance.HIGH;

  /**
   * set largeIcon
   *
   * https://notifee.app/react-native/docs/android/appearance#large-icons
   */
  const largeIcon = imageUrl;

  let style;

  if (imageUrl) {
    /**
     * set notification style to Big Picture if has imageUrl
     *
     * https://notifee.app/react-native/docs/android/styles#big-picture
     */
    style = {
      type: AndroidStyle.BIGPICTURE,
      picture: imageUrl,
    };
  } else if (body) {
    // android should use big text style which is able to expand
    style = {
      type: AndroidStyle.BIGTEXT,
      text: body,
    };
  }

  /**
   * set Small Icon to ic_notification
   *
   * https://notifee.app/react-native/docs/android/appearance#small-icons
   */
  const smallIcon = 'ic_notification';

  /**
   * The device uses the color to change various parts of the notification:
   * - Tinting the small icon.
   * - Setting text color on notification action text.
   * - Tinting the progress bar color when using progress indicators.
   * - Setting the background color of a notification input box.
   * - Changing the background color of the entire notification when used
   * with Foreground Services.
   *
   * To learn more, see:
   * https://notifee.app/react-native/docs/android/appearance#color
   */
  const color = coral;

  const androidConfig = {
    smallIcon,
    color,
    channelId:
      firebaseConfig['react-native'].messaging_android_notification_channel_id,
    importance,
    largeIcon,
    style,
    pressAction: {
      id: 'default',
      launchActivity: 'default',
    },
  };

  // do not set key if is undefined or null
  return pickBy(androidConfig, value => !!value);
}

/**
 * iOS notification configuration
 */
function getIOSConfiguration({ imageUrl }: { imageUrl?: string }) {
  const attachments: IOSNotificationAttachment[] = [];

  /**
   * To show image on iOS,
   * download image source from imageUrl and add to iOS attachments
   */
  if (imageUrl) {
    attachments.push({ url: imageUrl });
  }

  return {
    attachments,
  };
}

/**
 * Display notification using Notifee
 */
const displayNotification = async ({ data, title, body, imageUrl }) => {
  let ios: NotificationIOS | undefined;
  let android: NotificationAndroid | undefined;

  if (isAndroid) {
    android = getAndroidConfiguration({ imageUrl, body });
  }

  if (isIOS) {
    ios = getIOSConfiguration({ imageUrl });
  }

  // notifee only accept string value for data
  const stringData = pickBy(data, value => typeof value === 'string');
  const config = {
    title,
    body,
    ios,
    android,
    data: stringData,
  };

  // only pick identify value to avoid type error
  const notifeeConfig = pickBy(config, value => !!value);

  await notifee.displayNotification(notifeeConfig);
};

/**
 * Parse firebase message and display
 */
export const showFirebaseNotification = async message => {
  try {
    const { notification = {}, data = {} } = message;

    const { noti, fcm_options, notifee_options, image, ...restData } = data;

    const _noti = typeof noti === 'string' ? JSON.parse(noti) : {};

    const notiData = {
      ...notification,
      ..._noti,
    };

    if (Object.keys(notiData).length > 0) {
      const { title, body, image: notiImg } = notiData;

      const imageUrl =
        fcm_options?.image || notifee_options?.image || image || notiImg;

      await displayNotification({ data: restData, title, body, imageUrl });
    }
  } catch (e) {
    console.log('firebaseNotification error', e);
  }
};

/**
 * Parse iterable message and display
 */
export const showIterableNotification = async message => {
  try {
    const { data } = message;

    if (data) {
      const { itbl, title, body } = data;
      const parsedItbl = JSON.parse(itbl);
      const imageUrl = parsedItbl['attachment-url'];

      await displayNotification({
        data: {
          ...data,
          internalUrl: parsedItbl.defaultAction.data,
        },
        title,
        body,
        imageUrl,
      });
    }
  } catch (e) {
    console.log('iterableNotification error', e);
  }
};

export default async message => {
  const [error, isDisabled] = await safeAwait(getIsDisableNotiPermission());

  if (error || isDisabled) {
    return;
  }

  // iterable notification has itbl in data
  const isIterable = message.data && message.data.itbl;
  let type;

  if (!!isIterable) {
    await showIterableNotification(message);
    type = PushNotificationType.Iterable;
  } else {
    await showFirebaseNotification(message);
    type = PushNotificationType.Firebase;
  }

  logPushNotification(message, {
    eventName: AnalyticsEvent.PushReceived,
    type,
  });
};
