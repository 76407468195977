import deepEqual from 'fast-deep-equal';
import omit from 'lodash/omit';
import { ActionType, getType } from 'typesafe-actions';

import { CartContentElement } from '../../types/CartContent';
import isExists from '../../utils/isExists';
import { clearData } from '../actions/clearDataActions';
import { deleteCartItem } from '../actions/deleteCartItemActions';
import { loadCartContent } from '../actions/loadCartContentActions';
import {
  selectedAllCart,
  updateSelectedCart,
} from '../actions/selectCartActions';
import { updateCartItemAmount } from '../actions/updateCartItemAmountActions';

type TheActions = ActionType<
  | typeof loadCartContent
  | typeof updateSelectedCart
  | typeof selectedAllCart
  | typeof updateCartItemAmount
  | typeof deleteCartItem
  | typeof updateCartItemAmount
  | typeof deleteCartItem
  | typeof clearData
>;

interface CartItem extends CartContentElement {
  isChecked: boolean;
}

interface CartContent {
  [vid: string]: CartItem;
}

interface OutOfStock {
  [vid: string]: CartContentElement;
}

export interface State {
  cartContent: CartContent;
  outOfStock: OutOfStock;
  isLoading: boolean;
}

export const initialState: State = {
  cartContent: {},
  outOfStock: {},
  isLoading: false,
};

// check default is true
const setAllSelect = (cartContent: object, checked = true) => {
  const newCartContent = { ...cartContent };

  Object.keys(newCartContent).map(key => {
    newCartContent[key].isChecked = checked;
  });

  return newCartContent;
};

function cartContentSpecialProcess(
  actionType: string,
  newItem: CartContentElement,
  oldItem?: CartItem
) {
  switch (actionType) {
    case getType(loadCartContent.success): {
      // 確保 undefined 的時候是 true
      const isChecked =
        oldItem === undefined || (oldItem as CartItem).isChecked;

      return {
        ...oldItem,
        ...newItem,
        isChecked,
      };
    }

    default: {
      return oldItem;
    }
  }
}

export function splitVirtualProductsWhenLoadSuccess<
  CartData extends { cartContent: CartContent; outOfStock: OutOfStock }
>(
  currentData: CartData,
  rawData: CartContentElement[],
  action: TheActions
): CartData {
  return (rawData || []).reduce(
    (result, item) => {
      const { virtual_product_id: id } = item;

      if (item.in_stock) {
        const newItem = cartContentSpecialProcess(
          action.type,
          item,
          currentData.cartContent[id]
        );

        if (newItem) {
          result.cartContent[id] = newItem;
        }
      } else {
        result.outOfStock[id] = item;
      }

      return result;
    },
    {
      cartContent: {},
      outOfStock: {},
    } as CartData
  );
}

export default function (state = initialState, action: TheActions) {
  switch (action.type) {
    case getType(loadCartContent.request): {
      const preserveSelection =
        (action.payload || {}).preserveSelection || false;
      const newCartContent = preserveSelection
        ? state.cartContent
        : setAllSelect(state.cartContent, true);

      return {
        ...state,
        cartContent: newCartContent,
        isLoading: true,
      };
    }

    case getType(loadCartContent.success): {
      const { result } = action.payload;
      const data = result.cart_contents || ([] as CartContentElement[]);
      const splitResult = splitVirtualProductsWhenLoadSuccess(
        { cartContent: state.cartContent, outOfStock: state.outOfStock },
        data,
        action
      );
      const nextCartContent = deepEqual(
        splitResult.cartContent,
        state.cartContent
      )
        ? state.cartContent
        : splitResult.cartContent;
      const nextOutOfStock = deepEqual(splitResult.outOfStock, state.outOfStock)
        ? state.outOfStock
        : splitResult.outOfStock;

      return {
        ...state,
        cartContent: nextCartContent,
        outOfStock: nextOutOfStock,
        isLoading: false,
      };
    }

    case getType(updateSelectedCart): {
      const { virtualProductID, checked } = action.payload;
      const newCartContent = { ...state.cartContent };
      const target = newCartContent[virtualProductID];

      if (target && isExists(checked) && virtualProductID) {
        target.isChecked = checked;
      }

      return {
        ...state,
        cartContent: newCartContent,
      };
    }

    case getType(selectedAllCart): {
      const checked = action.payload;
      const newCartContent = setAllSelect({ ...state.cartContent }, checked);

      return {
        ...state,
        cartContent: newCartContent,
      };
    }

    case getType(loadCartContent.failure): {
      const { error } = action.payload;

      return { ...state, isLoading: false, hasError: true, error };
    }

    case getType(updateCartItemAmount.request): {
      const virtualProductID = action.payload.virtualProductID;
      const amount = action.payload.amount;

      return {
        ...state,
        cartContent: {
          ...state.cartContent,
          [virtualProductID]: {
            ...state.cartContent[virtualProductID],
            amount,
          },
        },
        isLoading: true,
      };
    }
    case getType(deleteCartItem.success):
    case getType(updateCartItemAmount.success): {
      return {
        ...state,
        isLoading: false,
      };
    }
    case getType(updateCartItemAmount.failure): {
      console.log('fail to update cart item amount', action.payload.error);
      const error = action.payload.error;

      return {
        ...state,
        hasError: true,
        error,
        isLoading: false,
      };
    }

    case getType(deleteCartItem.request): {
      const { virtualProductIDs } = action.payload || {};

      if (!virtualProductIDs) {
        return {
          ...state,
        };
      }

      // 目前不預期批次刪除混雜 cartContent/outOfStock 的商品，因此只檢查第一個商品
      const theFirstVid = virtualProductIDs[0];
      const itemStateGroup = state.cartContent[theFirstVid]
        ? 'cartContent'
        : 'outOfStock';

      return {
        ...state,
        [itemStateGroup]: omit(state[itemStateGroup], virtualProductIDs),
        isLoading: true,
      };
    }
    case getType(clearData): {
      return {
        ...initialState,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
