import {
  StyleProp,
  Text,
  TextProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import { testIDs } from '../../constants/testIDs';
import { makeDefaultCurrencyData } from '../../redux/selectors/VBConfigSelector';
import { useSelector } from '../../redux/utils';
import isExists from '../../utils/isExists';
import { showNumberWithComma } from '../../utils/stringHelper';
import defaultStyles from './styles';

interface Props {
  containerStyle?: StyleProp<ViewStyle>;
  currencyStyle?: StyleProp<TextStyle>;
  currencySymbol?: string;
  hideCurrency?: boolean;
  label?: string;
  labelStyle?: StyleProp<TextStyle>;
  price?: number | string;
  priceStyle?: StyleProp<TextStyle>;
  otherTextProps?: TextProps;
  testID?: string;
  headerSymbol?: string;
  allowFontScaling?: boolean;
}

const Price = (props: Props) => {
  const currencies = useSelector(makeDefaultCurrencyData());
  const { symbol: defaultCurrencySymbol } = currencies;

  const {
    containerStyle,
    currencyStyle,
    currencySymbol,
    hideCurrency,
    label,
    labelStyle,
    price,
    priceStyle,
    otherTextProps,
    headerSymbol,
    allowFontScaling = true,
  } = props;

  return (
    <View style={[defaultStyles.currencyPrice, containerStyle]}>
      {isExists(label) && (
        <Text
          key="label"
          style={[defaultStyles.labelStyle, labelStyle]}
          {...otherTextProps}
          allowFontScaling={allowFontScaling}
        >
          {label}
        </Text>
      )}
      {isExists(price) && !!headerSymbol && (
        <Text
          key="header-symbol"
          style={[defaultStyles.priceText, priceStyle]}
          allowFontScaling={allowFontScaling}
          {...otherTextProps}
        >
          {headerSymbol}
        </Text>
      )}
      {!hideCurrency && isExists(price) && (
        <Text
          key="currency"
          style={[defaultStyles.priceText, priceStyle, currencyStyle]}
          allowFontScaling={allowFontScaling}
          {...otherTextProps}
        >
          {currencySymbol || defaultCurrencySymbol}
        </Text>
      )}
      {isExists(price) && (
        <Text
          key="price"
          style={[defaultStyles.priceText, priceStyle]}
          testID={testIDs.Price.priceText}
          allowFontScaling={allowFontScaling}
          {...otherTextProps}
        >
          {showNumberWithComma(Math.abs(Number(price))).toString()}
        </Text>
      )}
    </View>
  );
};

export default Price;
