import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getIndexOnSaleActions } from '../actions/getOnSaleActions';
import { getOnSale } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getIndexOnSaleActions.success,
  failure: getIndexOnSaleActions.failure,
};

type RequestActionTypes = ActionType<typeof getIndexOnSaleActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getOnSaleEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getIndexOnSaleActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { success } = action.payload;

      return getOnSale({ page: 1 })
        .then(response => {
          const { data } = response;

          success(data.products);

          return getIndexOnSaleActions.success({
            data: response.data,
          });
        })
        .catch((error: AxiosError) =>
          getIndexOnSaleActions.failure({ error, requestAction: action })
        );
    })
  );
};

export default getOnSaleEpic;
