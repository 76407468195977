import { AxiosError } from 'axios';
import { getType } from 'typesafe-actions';

import { LoginOrRegisterMethod } from '../../constants/LoginOrRegisterMethod';
import { Member } from '../../types/Member';
import { MemberAdditionalInfo, MemberInfo } from '../../types/MemberInfo';
import * as cookieHelper from '../../utils/cookieHelper';
import {
  MemberProfileSuccessPayload,
  getMemberProfileActions,
} from '../actions/getMemberProfileActions';
import { initMemberInfo } from '../actions/initMemberInfoActions';
import { loginByApple } from '../actions/loginByApple';
import { loginByEmail } from '../actions/loginByEmail';
import { postMemberVerifySMSActions } from '../actions/postMemberVerifySMSActions';
import { putMemberVerifyPhoneActions } from '../actions/putMemberVerifyPhoneActions';
import { putPhoneVerificationCodeActions } from '../actions/putPhoneVerificationCodeActions';
import { updateMemberInfoAction } from '../actions/updateMemberInfoActions';
import { deleteAxiosDefaultHeader } from '../api';
import { actionsList } from '../rootAction';
import memberVerifyReducer, {
  defaultState as memberVerifyReducerDefaultState,
  State as memberVerifyReducerState,
} from './memberVerifyReducer';

const {
  getGuestToken,
  loginByFacebook,
  loginByLine,
  postRegisterActions,
  getMemberInfoActions,
  clearData,
  updateMemberNotifications,
  setViewedHome,
  patchMemberActions,
  postForgetPasswordActions,
} = actionsList;

/**
 * Types of data shall be partial because it might be empty before api returns.
 *
 * - The type `Member` is already Partial.
 * - `MemberAdditionalInfo` has default value in `INITIAL_STATE`
 */
export type MemberData = Partial<MemberInfo> &
  Partial<Member> &
  MemberAdditionalInfo & { guestID?: string };

export interface MemberReducerState {
  data: MemberData;
  verifyData: memberVerifyReducerState;
  isLoading: boolean;
  isMemberInfoLoading: boolean;
  hasError: boolean;
  loginMethod?: LoginOrRegisterMethod;
  error?: AxiosError;
  isNewCustomer: boolean;
  isFetchingNewCustomer: boolean;
  isPostForgetPasswordFetching: boolean;
}

export const INITIAL_STATE = {
  data: { notifications: 0 },
  verifyData: memberVerifyReducerDefaultState,
  isNewCustomer: true,
  isFetchingNewCustomer: false,
  isPostForgetPasswordFetching: false,
} as MemberReducerState;

// TODO: 要從 action 取得 type
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case getType(initMemberInfo): {
      return {
        ...state,
        data: action.payload,
      };
    }
    case getType(getGuestToken.request):
    case getType(loginByApple.request):
    case getType(loginByFacebook.request):
    case getType(loginByLine.request):
    case getType(loginByEmail.request):
    case getType(postRegisterActions.request): {
      const loginMethod = action?.payload?.loginMethod;

      return {
        ...state,
        isLoading: true,
        loginMethod,
      };
    }
    case getType(loginByApple.success):
    case getType(loginByFacebook.success):
    case getType(loginByLine.success):
    case getType(loginByEmail.success):
    case getType(postRegisterActions.success): {
      const { result, lineID } = action.payload;
      const data = result?.data || {};

      const newData = { ...state.data, ...data, lineID };

      // 當登入後，需清除 guestID
      const { guestID, ...newDataWithoutGuestID } = newData;

      return {
        ...state,
        isLoading: false,
        // 因登入 / 註冊成功後需立即 fetch member info api
        isMemberInfoLoading: true,
        hasError: false,
        data: newDataWithoutGuestID,
      };
    }
    case getType(getMemberInfoActions.request): {
      return {
        ...state,
        isMemberInfoLoading: true,
      };
    }
    case getType(getMemberInfoActions.success): {
      const { data } = action.payload;
      const newData = { ...state.data, ...data };

      return {
        ...state,
        isMemberInfoLoading: false,
        hasError: false,
        data: newData,
      };
    }
    case getType(getMemberInfoActions.success): {
      const { data } = action.payload;
      const newData = { ...state.data, ...data };

      return {
        ...state,
        isMemberInfoLoading: false,
        hasError: false,
        data: newData,
      };
    }
    case getType(updateMemberInfoAction):
    case getType(patchMemberActions.success): {
      const { data } = action.payload;

      const newData = { ...state.data, ...data.items };

      return {
        ...state,
        data: newData,
      };
    }
    case getType(loginByApple.failure):
    case getType(loginByFacebook.failure):
    case getType(loginByLine.failure):
    case getType(loginByEmail.failure):
    case getType(postRegisterActions.failure): {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload.error,
      };
    }
    case getType(getMemberInfoActions.failure): {
      return {
        ...state,
        isMemberInfoLoading: false,
        hasError: true,
        error: action.payload.error,
      };
    }

    case getType(getGuestToken.success): {
      const data = action?.payload?.data || {};
      const { guest_id: guestID, guest_token: token } = data;

      return {
        ...state,
        isLoading: false,
        data: { ...state.data, guestID, token },
        hasError: false,
      };
    }
    case getType(getGuestToken.failure): {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload.error,
      };
    }

    case getType(getMemberProfileActions.request): {
      return {
        ...state,
        isFetchingNewCustomer: true,
      };
    }
    case getType(getMemberProfileActions.success): {
      const { data }: MemberProfileSuccessPayload = action.payload;

      return {
        ...state,
        isFetchingNewCustomer: false,
        isNewCustomer: data.is_new_customer,
        hasError: false,
        error: null,
      };
    }
    case getType(getMemberProfileActions.failure): {
      return {
        ...state,
        isFetchingNewCustomer: false,
        hasError: true,
        error: action.payload.error,
      };
    }

    case getType(postMemberVerifySMSActions.request):
    case getType(postMemberVerifySMSActions.success):
    case getType(postMemberVerifySMSActions.failure):
    case getType(putMemberVerifyPhoneActions.request):
    case getType(putMemberVerifyPhoneActions.success):
    case getType(putMemberVerifyPhoneActions.failure):
    case getType(putPhoneVerificationCodeActions.request):
    case getType(putPhoneVerificationCodeActions.success):
    case getType(putPhoneVerificationCodeActions.failure): {
      return {
        ...state,
        verifyData: memberVerifyReducer(state.verifyData, action),
      };
    }

    case getType(postForgetPasswordActions.request): {
      return {
        ...state,
        isPostForgetPasswordFetching: true,
      };
    }
    case getType(postForgetPasswordActions.success):
    case getType(postForgetPasswordActions.failure): {
      return {
        ...state,
        isPostForgetPasswordFetching: false,
      };
    }

    case getType(clearData): {
      deleteAxiosDefaultHeader('api-key');
      cookieHelper.clearMemberCookie().catch(console.log);

      return INITIAL_STATE;
    }
    case getType(updateMemberNotifications): {
      return {
        ...state,
        data: {
          ...state.data,
          notifications: action.payload.notifications,
        },
      };
    }
    case getType(setViewedHome): {
      const { isViewedHome } = state.data;

      if (isViewedHome) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          isViewedHome: true,
        },
      };
    }
    default: {
      return state;
    }
  }
}
