// Alpha Hex Value
// https://stackoverflow.com/questions/5445085/understanding-colors-on-android-six-characters/11019879#11019879

// ------------- Constant Color -----------------
// Don't import constants color, import usage color
// General Color
export const white = '#FFFFFF';
export const black = '#000000';

// Branding Color
export const veryBuyPink = '#F78895';
export const veryBuyBlue = '#5FD1D9';

// Primary Color
export const coral = '#FC6068';
export const peacock = '#00A2AD';
export const warrior = '#33384D';
export const tangerine = '#ff7c00';

// Complementary Color
export const neutralDark = '#888B94';
export const neutralGrey = '#AEB2BA';
export const neutralLight = '#E1E3E6';
export const neutralWhite = '#F5F7FA';
export const brightGrey = '#E6ECF5';
export const textDarkGrey = '#6E7076';

// Status Color
export const statusError = coral;
export const statusInProgress = '#FCB932';
export const statusSuccess = '#66CC47';
export const statusNormal = '#52A9EB';

// UI
export const darkSlateBlue = '#44495C';
export const maskBackgroundColor = `${black}99`; // alpha 0.6
export const FBBlue = '#1877F2';
export const buttonPressedColor = '#D9454D';
export const lightCoral = '#FEEFEF';
export const lightBrown = '#644833';
export const lightOrange = '#fbd0ae';
export const lightPeacock = '#e1f5f7';
export const bloom = '#FFAF73';
export const placeholder = '#CED0D3';
