import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import * as arrayHandler from '../../utils/arrayHandler';
import { deleteVendorCollectActions } from '../actions/deleteVendorCollectActions';
import { getLoveVendorsActions } from '../actions/getLoveVendorsActions';

export interface State {
  isFetching: boolean;
  items: string[];
  loadingType?: ListLoadingTypes;
  hasMoreItems?: boolean;
}

export const INITIAL_STATE: State = {
  isFetching: true,
  items: [],
  hasMoreItems: true,
  loadingType: 'new',
};

export default produce(
  (
    draft: State,
    action: ActionType<
      typeof getLoveVendorsActions | typeof deleteVendorCollectActions
    >
  ) => {
    switch (action.type) {
      case getType(getLoveVendorsActions.request): {
        const { loadingType } = action.payload;
        const newItems = loadingType === 'new' ? [] : draft.items;

        return {
          ...draft,
          isFetching: true,
          items: newItems,
          loadingType,
        };
      }
      case getType(getLoveVendorsActions.success): {
        const { data, loadingType } = action.payload;
        const itemsIds = data.sellers.map(d => d.seller_id);
        const newItems =
          loadingType === 'loadMore' ? [...draft.items, ...itemsIds] : itemsIds;

        return {
          ...draft,
          isFetching: false,
          items: arrayHandler.uniqueArray(newItems),
          hasMoreItems: itemsIds.length > 0,
          loadingType,
        };
      }
      case getType(getLoveVendorsActions.failure): {
        return {
          ...draft,
          isFetching: false,
        };
      }
      case getType(deleteVendorCollectActions.success): {
        const { id, shouldRemoveFromList } = action.payload;

        if (shouldRemoveFromList) {
          const newItems = draft.items.filter(item => item !== `${id}`);

          draft.items = newItems;
        }

        return;
      }
      default: {
        return;
      }
    }
  },
  INITIAL_STATE
);
