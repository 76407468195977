import { Component } from 'react';
import { LogBox } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components/native';

import ApiCommonError from '../components/ApiCommonError';
import AppInit from '../components/AppInit';
import { defaultTheme } from '../constants/themes';
import { AppHelmet } from '../decorators/withHelmet';
import GlobalNormalDialog from '../elements/Dialog/GlobalNormalDialog';
import ErrorFallback from '../elements/ErrorFallback';
import GlobalErrorBoundary from '../elements/GlobalErrorBoundary';
import { init as i18nInit } from '../i18n';
import langHandler from '../i18n/langHandler';
import { configureStore } from '../redux';
import { setupBaseUrl } from '../redux/api';
import { setPersistStore } from '../redux/persiststore';
import { abTestingSetup } from '../utils/abTestingHelpers';
import Config from '../utils/configs';
import { handleSetCurrentCountryCode } from '../utils/countryInfo';
import { withCodePush } from '../utils/packages/codePush';
import { fetchConfig as fetchRemoteConfig } from '../utils/packages/firebase';
import smoothPolyfill from '../utils/smoothPolyfill';
import App from './App';
import CustomWidget from './CustomWidget';
import { OrientationProvider } from './OrientationContext';
import { isAndroid, isApp, isWebPlatform } from './utils';

smoothPolyfill.polyfill();

// 目前版本 react-native-web 沒有實作 LogBox
if (!isWebPlatform) {
  LogBox.ignoreAllLogs(true);
}

declare const global: any;

declare const window: any;

const AppRoot = App;

// this is for CartBadge animation
Animatable.initializeRegistryWithDefinitions({
  customPulse: {
    0: {
      scaleX: 1,
      scaleY: 1,
    },
    0.5: {
      scaleX: 1.2,
      scaleY: 1.2,
    },
    1: {
      scaleX: 1,
      scaleY: 1,
    },
  },
});

export interface Props {}

class Setup extends Component<Props> {
  state = {
    isReady: !isApp,
  };
  store;
  persistor;

  constructor(props) {
    super(props);

    if (__DEV__) {
      // https://github.com/jhen0409/react-native-debugger/blob/master/docs/network-inspect-of-chrome-devtools.md
      global.XMLHttpRequest = global.originalXMLHttpRequest
        ? global.originalXMLHttpRequest
        : global.XMLHttpRequest;
      global.FormData = global.originalFormData
        ? global.originalFormData
        : global.FormData;

      // fetch; // Ensure to get the lazy property

      if (window.__FETCH_SUPPORT__) {
        // it's RNDebugger only to have
        window.__FETCH_SUPPORT__.blob = false;
      } else {
        /*
         * Set __FETCH_SUPPORT__ to false is just work for `fetch`.
         * If you're using another way you can just use the native Blob and remove the `else` statement
         */
        global.Blob = global.originalBlob ? global.originalBlob : global.Blob;
        global.FileReader = global.originalFileReader
          ? global.originalFileReader
          : global.FileReader;
      }
    }

    this.store = configureStore();
    this.persistor = setPersistStore(this.store);

    const intiLang = langHandler.get();

    i18nInit(intiLang);
    setupBaseUrl(Config.REACT_APP_API_URL);
    abTestingSetup();
    fetchRemoteConfig();
  }

  componentDidMount() {
    if (isApp) {
      handleSetCurrentCountryCode().finally(() => {
        this.setState({ isReady: true });
      });
    }
  }

  render() {
    const { isReady } = this.state;

    if (!isReady) {
      return null;
    }

    return (
      <Provider store={this.store}>
        <PersistGate loading={null} persistor={this.persistor}>
          <GlobalErrorBoundary FallbackComponent={ErrorFallback}>
            <ThemeProvider theme={defaultTheme}>
              <OrientationProvider>
                <AppHelmet />
                <AppRoot />
                <AppInit />
                <ApiCommonError />
                <GlobalNormalDialog />
                <CustomWidget />
              </OrientationProvider>
            </ThemeProvider>
          </GlobalErrorBoundary>
        </PersistGate>
      </Provider>
    );
  }
}

export default isAndroid ? Setup : withCodePush(Setup);
