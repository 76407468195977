export enum AnalyticsEvent {
  AddToCart = 'add_to_cart',
  AddToWishList = 'add_to_wishlist',
  AllowTrackingRequest = 'allow_tracking_request',
  ApplyFilter = 'apply_filter',
  BeginCheckout = 'begin_checkout',
  CartRemove = 'CartRemove',
  Click = 'click',
  // TODO: app 的部分還需要處理
  ClickFloatIcon = 'click_float_icon',
  ClickLightBox = 'lightbox_click',
  // TODO: app 的部分還需要處理
  CloseFloatIcon = 'close_float_icon',
  CloseLightBox = 'lightbox_close',
  CountryCodeApi = 'country_code_api',
  CouponGet = 'coupon_get',
  CouponRedeem = 'coupon_redeem',
  CreditCardAdd = 'credit_card_add',
  CreditCardRepay = 'credit_card_repay',
  CreditCardRepayAtm = 'credit_card_repay_atm',
  CreditCardRepaySubmit = 'credit_card_repay_submit',
  ECommercePurchase = 'ecommerce_purchase',
  ECommercePurchaseFE = 'ecommerce_purchase_fe',
  EnterItemPage = 'enter_item_page',
  Follow = 'follow',
  GeneralEvent = 'general_event',
  HomePageClick = 'homepage_click',
  Impression = 'view',
  ItemMarketEvent = 'item_market_event',
  LightboxButtonClick = 'lightbox_button_click',
  Login = 'login',
  NotificationBellClick = 'notification_click',
  NotificationListClick = 'notification_list_click',
  OrderConfirmation = 'order_confirmation',
  PushClick = 'push_click',
  PushReceived = 'push_received',
  PushRedeem = 'push_redeem',
  RecommendList = 'recommend_list',
  RevisitHome = 'revisit_home',
  ScreenShot = 'screen_shot',
  ScreenView = 'screen_view',
  Search = 'search',
  SearchOpen = 'search_open',
  SendLightbox = 'send_light_box',
  ShareClick = 'share_click',
  ShareResult = 'share_result',
  SignUp = 'sign_up',
  TelexpressClose = 'telexpress_close',
  TelexpressOpen = 'telexpress_open',
  UpdateCart = 'update_cart',
  VCoinGet = 'vcoin_get',
  ViewBestSeller = 'view_best_seller',
  ViewCarouselEndPanel = 'view_carousel_end_panel',
  ViewCart = 'view_cart',
  ViewError = 'view_error',
  ViewFloatIcon = 'view_float_icon',
  ViewHome = 'view_home',
  ViewItem = 'view_item',
  ViewItemInvalid = 'view_item_invalid',
  ViewItemList = 'view_item_list',
  ViewLightBox = 'lightbox_view',
  ViewNewArrivals = 'view_new_arrivals',
  ViewPayment = 'view_payment',
  ViewSearchResults = 'view_search_results',
  ViewTransactionResult = 'view_transaction_result',
  ViewWatchMoreButton = 'watchmore_button_view',
  View500KERKER = 'view_500_kerker',
  AppJSException = 'AppJSException',
  AppNativeException = 'AppNativeException',
}
