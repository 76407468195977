import { createAsyncAction } from 'typesafe-actions';

export interface ItemLovesPayload {
  id: string;
  success?: Function;
  failure?: Function;
}

export interface ItemLovesSuccessPayload {
  id: string;
}

export interface ItemLovesFailPayload {
  id: string;
  error: Error;
}

export const putItemLovesActions = createAsyncAction(
  'verybuy/PUT_ITEM_LOVES',
  'verybuy/PUT_ITEM_LOVES_SUCCESS',
  'verybuy/PUT_ITEM_LOVES_FAIL'
)<ItemLovesPayload, ItemLovesSuccessPayload, ItemLovesFailPayload>();
