import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { Asset } from '../../elements/UploadImage/types';
import { SupportDetail } from '../../types/SupportDetail';
import { MessageFrom } from '../../types/SupportDetailResponse';
import { actionsList } from '../rootAction';

const {
  getSupportDetailActions,
  postSupportsReplyActions,
  getSupportMessagesActions,
} = actionsList;

export function createNewMessage(text: string, photos: Asset[]) {
  return {
    id: Date.now().toString(),
    content: text.replace(/\n/g, '<br />'),
    created_at: Math.floor(Date.now() / 1000),
    from: MessageFrom.User,
    image_srcs: photos.map(photo => photo.uri) || [],
  };
}

export const defaultDetailData: SupportDetail = {
  data: null,
  messages: [],
  isFetching: false,
  isMessagesFetching: false,
  isSendingReply: false,
  page: 1,
  loadingType: 'new',
  hasMoreItems: true,
  hasError: false,
  error: null,
};

const defaultState: Record<string, SupportDetail> = {};

export default produce(
  (
    draft,
    action: ActionType<
      | typeof getSupportDetailActions
      | typeof postSupportsReplyActions
      | typeof getSupportMessagesActions
    >
  ) => {
    switch (action.type) {
      case getType(getSupportDetailActions.request): {
        const { id } = action.payload;
        const prevData = draft[id] || defaultDetailData;

        draft[id] = {
          ...prevData,
          isFetching: true,
          hasError: false,
          error: null,
        };
        break;
      }
      case getType(getSupportDetailActions.success): {
        const { id, data } = action.payload;

        draft[id] = { ...draft[id], data, isFetching: false };
        break;
      }
      case getType(getSupportDetailActions.failure): {
        const { id, error } = action.payload;

        draft[id] = {
          ...draft[id],
          isFetching: false,
          hasError: true,
          error,
        };
        break;
      }

      case getType(postSupportsReplyActions.request): {
        const { id } = action.payload;

        draft[id] = draft[id] || {};
        draft[id].isSendingReply = true;
        draft[id].hasError = false;
        draft[id].error = null;

        break;
      }
      case getType(getSupportMessagesActions.request): {
        const { id, loadingType } = action.payload;

        draft[id] = draft[id] || {};
        draft[id].isMessagesFetching = true;
        draft[id].loadingType = loadingType;

        break;
      }
      case getType(getSupportMessagesActions.success): {
        const { id, data, page, perPage, loadingType } = action.payload;
        const prevMessages = draft[id]?.messages || [];
        const newMessages =
          loadingType === 'loadMore' ? prevMessages.concat(data) : data;

        draft[id] = draft[id] || {};
        draft[id].isMessagesFetching = false;
        draft[id].page = page;
        draft[id].hasMoreItems = data.length >= perPage;
        draft[id].messages = newMessages;

        break;
      }
      case getType(getSupportMessagesActions.failure): {
        const { id } = action.payload;

        draft[id] = draft[id] || {};
        draft[id].isMessagesFetching = false;

        break;
      }

      case getType(postSupportsReplyActions.success): {
        const { id, text, photos } = action.payload;
        const newMessage = createNewMessage(text, photos);

        draft[id] = draft[id] || {};
        draft[id].isSendingReply = false;
        draft[id]?.messages.unshift(newMessage);

        break;
      }

      case getType(postSupportsReplyActions.failure): {
        const { id, error } = action.payload;

        draft[id] = draft[id] || {};
        draft[id].isSendingReply = false;
        draft[id].hasError = true;
        draft[id].error = error;

        break;
      }
      default: {
        break;
      }
    }
  },
  defaultState
);
