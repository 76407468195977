import { getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import * as arrayHandler from '../../utils/arrayHandler';
import { actionsList } from '../rootAction';

export interface State {
  items: string[];
  loadingType: ListLoadingTypes;
  isFetchingRecommendProducts: boolean;
  hasMoreItems?: boolean;
  page: number;
  error?: Error | null;
}

const { getRecommendProductsActions } = actionsList;

const INITIAL_STATE: State = {
  items: [],
  loadingType: 'new',
  isFetchingRecommendProducts: false,
  hasMoreItems: true,
  page: 1,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case getType(getRecommendProductsActions.request): {
      const { loadingType } = action.payload;
      const newItems = loadingType === 'new' ? [] : state.items;

      return {
        ...state,
        items: newItems,
        isFetchingRecommendProducts: true,
        loadingType,
        error: null,
      };
    }
    case getType(getRecommendProductsActions.success): {
      const { data, loadingType, page, ...otherData } = action.payload;
      const rawProducts = data.products;
      const itemsID = rawProducts.map(d => `${d.id}`);
      const newItems =
        loadingType === 'loadMore' ? state.items.concat(itemsID) : itemsID;
      const hasMoreItems = data.has_next_page;

      return {
        ...state,
        isFetchingRecommendProducts: false,
        error: null,
        items: arrayHandler.uniqueArray(newItems),
        hasMoreItems,
        page,
        loadingType,
        ...otherData,
      };
    }
    case getType(getRecommendProductsActions.failure): {
      return {
        ...state,
        isFetchingRecommendProducts: false,
        hasMoreItems: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
