import { createSelector } from 'reselect';

import * as listWithPlaceholder from '../../utils/listWithPlaceholder';
import { State } from '../reducers/searchReducer';
import { RootState } from '../rootReducer';

const reducer = (state: RootState) => state.searchReducer || {};

export const getSearchResultCount = () =>
  createSelector(reducer, searchResult => {
    return searchResult.data?.length || 0;
  });

export const makeSearchResultIdSelector = () =>
  createSelector(reducer, searchResult => {
    const list = searchResult?.data || [];
    const isFetching = searchResult?.isFetching || false;

    return listWithPlaceholder.makeResultIdsWithFetching(list, isFetching);
  });

export const makeSearchDiscoveryItems = () =>
  createSelector(reducer, searchResult => {
    const list = searchResult?.discoveryData || [];
    const isDiscoveryFetching = searchResult?.isDiscoveryFetching || false;

    return listWithPlaceholder.makeResultIdsWithFetching(
      list,
      isDiscoveryFetching
    );
  });

export const getSearchResultByKeySelector = (key: keyof State) =>
  createSelector(reducer, searchResult => searchResult[key]);

export const getShouldRenderSearchBarEmptyHintSelector = () =>
  createSelector(reducer, searchResult => {
    return searchResult.data?.length === 0 && !searchResult.isFetching;
  });
