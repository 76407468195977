import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { SubmitBillData } from '../../types/SubmitBill';

export interface SubmitBillPayload {
  success(data: SubmitBillData): void;
  failure?(errResp): void;
}

export interface SubmitBillSuccessPayload {
  data: SubmitBillData;
  logEvent: AnalyticsEvent;
}

export interface SubmitBillFailPayload {
  error: Error;
  customErrorHandling: boolean;
}

export const submitBill = createAsyncAction(
  'verybuy/SUBMIT_BILL',
  'verybuy/SUBMIT_BILL_SUCCESS',
  'verybuy/SUBMIT_BILL_FAIL'
)<SubmitBillPayload, SubmitBillSuccessPayload, SubmitBillFailPayload>();
