import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { CommonErrors } from '../../constants/VBErrorCode';
import { getAnnouncementDetailActions } from '../actions/getAnnouncementDetailActions';
import { getAnnouncementDetail } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getAnnouncementDetailActions.success,
  failure: getAnnouncementDetailActions.failure,
};

type RequestActionType = ActionType<
  typeof getAnnouncementDetailActions.request
>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getAnnouncementDetailEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getAnnouncementDetailActions.request)),
    switchMap((action: RequestActionType) => {
      const { id, onFailure } = action.payload;

      return getAnnouncementDetail(id)
        .then(response =>
          getAnnouncementDetailActions.success({ data: response.data, id })
        )
        .catch((error: AxiosError) => {
          const particularError = [
            CommonErrors.ERR_NOT_FOUND,
            CommonErrors.ERR_INVALID_ARGUMENT,
          ];
          const { error_code: errorCode } = error?.response?.data || {};
          const preventCommonError = particularError.some(
            theError => errorCode === theError
          );

          onFailure(errorCode);

          return getAnnouncementDetailActions.failure({
            id,
            error,
            customErrorHandling: preventCommonError || undefined,
          });
        });
    })
  );
};

export default getAnnouncementDetailEpic;
