// Sensors standard event
// https://docs.google.com/spreadsheets/d/1744oaWCk-qU2s_TH9PYu9QWp5zfQf9QO/edit#gid=696670582
export enum SensorsAnalyticsEvent {
  AddToCart = 'AddToShoppingCart',
  AddToWishList = 'Collect',
  AllowTrackingRequest = 'AllowTrackingRequest',
  ApplyFilter = 'ApplyFilter',
  CartRemove = 'CartRemove',
  Click = 'MktClick',
  CreditCardAdd = 'CreditCardAdd',
  CreditCardRepay = 'CreditCardRepay',
  CreditCardRepayAtm = 'CreditCardRepayAtm',
  CreditCardRepaySubmit = 'CreditCardRepaySubmit',
  ECommercePurchaseFE = 'SubmitOrderFE',
  Follow = 'Follow',
  GeneralEvent = 'GeneralEvent',
  ItemMarketEvent = 'ItemMarketEvent',
  LightboxButtonClick = 'LightboxButtonClick',
  Login = 'LoginResult',
  NotificationBellClick = 'NotificationClick',
  NotificationListClick = 'NotificationListClick',
  PushClick = 'PushClick',
  PushReceived = 'PushReceived',
  PushRedeem = 'PushRedeem',
  ScreenShot = 'ScreenShot',
  ScreenView = '$AppViewScreen',
  Search = 'SearchButtonClick',
  SearchOpen = 'SearchOpen',
  SendLightbox = 'SendLightbox',
  ShareClick = 'ShareClick',
  ShareResult = 'ShareResult',
  SignUp = 'RegisterResult',
  TelexpressClose = 'TelexpressClose',
  TelexpressOpen = 'TelexpressOpen',
  ViewCarouselEndPanel = 'ViewCarouselEndPanel',
  ViewError = 'ViewError',
  ViewItem = 'CommodityDetail',
  ViewSearchResults = 'SearchResult',
  View500KERKER = 'View500KERKER',
}
