import { isWebPlatform } from '../../../boot/utils';
import { AnalyticsEvent } from '../../../constants/AnalyticsEvent';
import { isBot } from '../../../constants/misc';
import { makeCouponRedeemErrorMessage } from '../../../redux/selectors/couponRedeemSelector';
import {
  makeHasError,
  makeMemberInfoByKey,
} from '../../../redux/selectors/member';
import { makeProductByIdSelector } from '../../../redux/selectors/productById';
import { getCurrentPayBy } from '../../../redux/selectors/transactionDetail';
import { Impression } from '../../../types/CTR';
import Config from '../../configs';
import isExists from '../../isExists';
import firebase, { FirebaseAnalyticsTypes } from '../../packages/firebase';
import sendGtagEvent from '../../sendGtagEvent';
import { getCTREventKey, getGeneralInfo } from './utils';

declare global {
  interface Window {
    gtag: Function;
    fbq: Function;
  }
}

export default function getLogPayloadForFirebase(
  logEvent,
  logData,
  currentStore
) {
  // todo: use selector replace these data getter
  const MID = makeMemberInfoByKey('MID')(currentStore);
  const payBy = getCurrentPayBy()(currentStore);

  const generalInfo = getGeneralInfo();
  let logPayload = {};

  // todo: need to divide events and mapping
  switch (logEvent) {
    case AnalyticsEvent.ScreenView:
      const view = logData?.data?.view || 'UNKNOWN_SCREEN_VIEW';

      setCurrentScreen({ screen_name: view });

      break;
    case AnalyticsEvent.Login:
    case AnalyticsEvent.SignUp: {
      const hasError = makeHasError()(currentStore);

      if (!hasError) {
        logPayload = {
          method: logData.method,
        };
      }

      break;
    }
    case AnalyticsEvent.RecommendList: {
      const productIds = logData?.items?.map(d => d.marketing_product_id) || [];

      if (isWebPlatform && process.env.NODE_ENV === 'production') {
        sendGtagEvent('event', 'page_view', {
          send_to: Config.REACT_APP_GOOGLE_ADS_ID,
          ecomm_pagetype: 'searchresults',
          ecomm_prodid: productIds.slice(0, 15),
        });
      }

      logPayload = {
        list_items:
          logData?.items?.map(d => ({
            id: d.marketing_product_id.toString(),
            image_url: d.image_url,
            title: d.title,
            price: d.min_price,
            item_url: `https://verybuy.cc/${d.item_type}/item/${d.id}`,
          })) || [],
      };

      break;
    }
    case AnalyticsEvent.AddToCart:
      const TID = logData?.marketing_product_id;
      const value = logData?.price || 0;
      const quantity = logData?.amount || 0;
      const totalValue = value * quantity;

      logPayload = {
        MID,
        quantity: logData?.amount || 0,
        item_type: 'user_submit',
        item_name: logData?.title || 'EMPTY_ITEM_NAME',
        item_id: logData?.id || 0,
        value: logData?.price || 0,
        price: logData?.price || 0,
        image_url: logData?.image_url,
        marketing_product_id: TID,
        parent_category_id: logData?.parent_category_id,
        child_category_id: logData?.child_category_id,
        tags: logData?.tags,
        currency: 'TWD',
        ecomm_pagetype: 'cart',
        ecomm_prodid: [TID],
        ecomm_totalvalue: totalValue || 1,
      };

      if (isWebPlatform && process.env.NODE_ENV === 'production') {
        // @ts-ignore Event snippet for 加入購物車-RNWeb-GoogleAds conversion
        sendGtagEvent('event', 'conversion', {
          send_to: Config.GOOGLE_ADS_ADD_TO_CART_CONVERSION_KEY,
        });

        sendGtagEvent('event', 'page_view', {
          send_to: Config.REACT_APP_GOOGLE_ADS_ID,
          ecomm_pagetype: 'cart',
          ecomm_prodid: [TID],
          ecomm_totalvalue: totalValue || 1,
        });
      }
      break;
    case AnalyticsEvent.ECommercePurchase: {
      const data = logData?.data || {};
      const puchaseDatum =
        data?.detail_data?.map(d => d.marketing_product_id) || [];

      // for GA 1.0 ECommerce
      const itemDatum = data?.detail_data?.map(d => ({
        id: d.marketing_product_id,
        name: d.product_name,
        quantity: d.quantity,
        price: d.product_price,
      }));

      logPayload = {
        currency: 'TWD',
        MID,
        transaction_id: data?.transaction_id || 0,
        price: data?.price || 0,
        shipping: data?.shipping_fee?.toString() || 0,
        checkout_option: payBy?.toString() || '',
        value: data?.price || 0,
        items: itemDatum,
        ecomm_pagetype: 'purchase',
        ecomm_prodid: puchaseDatum,
        ecomm_totalvalue: data?.price || 1,
      };

      if (isWebPlatform && process.env.NODE_ENV === 'production') {
        // @ts-ignore  Event snippet for 購買-RNWeb-GoogleAds
        sendGtagEvent('event', 'conversion', {
          send_to: Config.GOOGLE_ADS_PURCHASE_CONVERSION_KEY,
          value: data?.price || 0,
          currency: 'TWD',
          transaction_id: data?.transaction_id || '',
        });

        sendGtagEvent('event', 'page_view', {
          send_to: Config.REACT_APP_GOOGLE_ADS_ID,
          ecomm_pagetype: 'purchase',
          ecomm_prodid: puchaseDatum,
          ecomm_totalvalue: data?.price || 1,
        });
      }
      break;
    }
    case AnalyticsEvent.BeginCheckout: {
      logPayload = {
        currency: 'TWD',
        MID,
        price: logData?.data?.cart_price || 0,
      };
      break;
    }
    case AnalyticsEvent.ViewItem: {
      const id = logData?.data?.id || 'UNKNOWN_ID';
      const itemType = logData?.data?.itemType || 'UNKNOWN_ITEM_TYPE';
      const itemInfo = makeProductByIdSelector(id)(currentStore);

      const categoryLevel1 = itemInfo?.parent_category_id || 'UNKNOWN_CATEGORY';
      const categoryLevel2 = itemInfo?.child_category_id || 'UNKNOWN_CATEGORY';

      logPayload = {
        item_id: id,
        item_type: itemType,
        item_name: itemInfo?.title || 'EMPTY_ITEM_NAME',
        category_level1: `${categoryLevel1}`,
        category_level2: `${categoryLevel2}`,
        price: itemInfo?.min_price || 0,
        currency: 'TWD',
        marketing_product_id: itemInfo?.marketing_product_id,
        ecomm_pagetype: 'product',
        ecomm_prodid: [itemInfo?.marketing_product_id],
        ecomm_totalvalue: itemInfo?.min_price || 1,
        image_url: itemInfo?.image_url,
        item_url: `https://verybuy.cc/user_submit/item/${id}`,
        MID,
      };

      if (isWebPlatform && process.env.NODE_ENV === 'production') {
        sendGtagEvent('event', 'page_view', {
          send_to: Config.REACT_APP_GOOGLE_ADS_ID,
          ecomm_pagetype: 'product',
          ecomm_prodid: [itemInfo?.marketing_product_id],
          ecomm_totalvalue: itemInfo?.min_price || 1,
        });
      }
      break;
    }
    case AnalyticsEvent.ViewFloatIcon: {
      if (isWebPlatform) {
        logPayload = {
          event_label: 'smart app banner',
          non_interaction: true,
        };
      }
      break;
    }
    case AnalyticsEvent.ViewLightBox: {
      logPayload = {
        event_category: 'observation metric',
        non_interaction: true,
      };
      break;
    }
    case AnalyticsEvent.ViewWatchMoreButton: {
      if (isWebPlatform) {
        logPayload = {
          event_category: 'observation metric',
          non_interaction: true,
        };
      }
      break;
    }
    case AnalyticsEvent.ViewItemInvalid: {
      const title = logData.data?.title || 'UNKNOWN_ITEM_TITLE';
      const id = logData.data?.id || 0;

      logPayload = {
        item_id: id,
        invalid_reason: logData.data?.invalid_reason || '',
        item_name: `${title} #${id}`,
        item_type: logData.data?.itemType || 'EMPTY_ITEM_TYPE',
      };
      break;
    }
    case AnalyticsEvent.ViewItemList: {
      logPayload = {
        item_category_id: logData.data?.item_category_id || 0,
        item_category: logData.data?.item_category || 'EMPTY_ITEM_NAME',
      };
      break;
    }
    case AnalyticsEvent.Search:
      logPayload = {
        search_term: logData?.data?.queryText || 'EMPTY_SEARCH_TERM',
        search_type: logData?.data?.searchType,
      };
      break;
    case AnalyticsEvent.ViewSearchResults: {
      const products = logData?.products || [];
      const productIds = products.map(product => product.marketing_product_id);

      logPayload = {
        search_term: logData?.queryText || 'EMPTY_SEARCH_TERM',
        ecomm_pagetype: 'searchresults',
        ecomm_prodid: productIds,
      };

      if (isWebPlatform && process.env.NODE_ENV === 'production') {
        sendGtagEvent('event', 'page_view', {
          send_to: Config.REACT_APP_GOOGLE_ADS_ID,
          ecomm_pagetype: 'searchresults',
          ecomm_prodid: productIds,
        });
      }
      break;
    }
    case AnalyticsEvent.AddToWishList: {
      const id = logData?.id;
      const itemInfo = makeProductByIdSelector(id)(currentStore);

      logPayload = {
        item_id: id || 0,
        item_name: itemInfo.title || 'EMPTY_ITEM_NAME',
        price: itemInfo.min_price || 0,
        image_url: itemInfo.image_url,
        currency: 'TWD',
        ecomm_pagetype: 'cart',
        ecomm_prodid: [itemInfo.marketing_product_id],
        ecomm_totalvalue: itemInfo.min_price || 1,
        item_url: `https://verybuy.cc/user_submit/item/${id}`,
      };

      if (isWebPlatform && process.env.NODE_ENV === 'production') {
        sendGtagEvent('event', 'page_view', {
          send_to: Config.REACT_APP_GOOGLE_ADS_ID,
          ecomm_pagetype: 'cart',
          ecomm_prodid: [itemInfo.marketing_product_id],
          ecomm_totalvalue: itemInfo.min_price || 1,
        });
      }
      break;
    }
    case AnalyticsEvent.Impression: {
      const data = logData?.data || [];

      logPayload = data.reduce(
        (
          acc: { [key: string]: string | number },
          impressionValue: Impression
        ) => {
          const theKey = getCTREventKey(impressionValue, currentStore);
          const location = impressionValue?.pitNumber || -1;

          if (isExists(acc[theKey])) {
            (acc[theKey] as number)++;
          } else {
            acc[theKey] = 1;
          }

          const pitNumberLocationKey = `pit_no_${impressionValue.layoutName}`;

          if (isExists(acc[pitNumberLocationKey])) {
            acc[pitNumberLocationKey] += `,${location}`;
          } else {
            acc[pitNumberLocationKey] = `${location}`;
          }

          return acc;
        },
        {}
      );

      break;
    }
    case AnalyticsEvent.Click: {
      const data = logData?.data || {};
      const theKey = getCTREventKey(data, currentStore);
      const pitNumberLocationKey = `pit_no_${data.layoutName}`;

      logPayload = {
        [theKey]: 1,
        [pitNumberLocationKey]: data?.pitNumber || -1,
      };

      break;
    }
    case AnalyticsEvent.GeneralEvent: {
      const data = logData?.data || {};

      // 名詞定義參考
      // https://support.google.com/analytics/answer/1033068?hl=en#zippy=%2Cin-this-article
      logPayload = {
        action_name: data.action_name,
        event_category: data.event_category,
        event_label: data.event_label,
        // 參照透過 gtag 送 ga 的格式，type 是 boolean，default 是 false
        non_interaction: data.non_interaction || false,
      };
      break;
    }
    case AnalyticsEvent.HomePageClick: {
      const data = logData?.data || {};

      const { type, title, index, layoutName } = data;

      logPayload = {
        click_position: index,
        type,
        title,
        mkt_name: layoutName,
      };

      break;
    }
    case AnalyticsEvent.ClickFloatIcon: {
      // TODO: app 的部分還需要處理
      logPayload = {
        event_label: 'smart app banner',
      };

      break;
    }
    case AnalyticsEvent.CloseFloatIcon: {
      // TODO: app 的部分還需要處理
      logPayload = {
        event_label: 'smart app banner',
      };

      break;
    }
    case AnalyticsEvent.ClickLightBox: {
      logPayload = {
        event_category: 'observation metric',
      };
      break;
    }
    case AnalyticsEvent.CloseLightBox: {
      logPayload = {
        event_category: 'observation metric',
      };

      break;
    }
    case AnalyticsEvent.CouponRedeem: {
      const couponCode = logData?.couponCode;
      const error = logData?.error || {};
      const hasError = Object.keys(error).length > 0;
      const result = hasError
        ? makeCouponRedeemErrorMessage()(currentStore)
        : '兌換成功';

      logPayload = {
        coupon_redeem_value: couponCode || 'UNKNOWN_VALUE',
        coupon_redeem_result: result || 'UNKNOWN_RESULT',
      };

      break;
    }
    case AnalyticsEvent.VCoinGet:
    case AnalyticsEvent.CouponGet: {
      const title = logData?.title || 'UNKNOWN_TITLE';

      logPayload = { title };

      break;
    }
    case AnalyticsEvent.PushRedeem: {
      const { isAllowNoti } = logData?.data || {};

      logPayload = {
        value: isAllowNoti,
        screenName: generalInfo.route_name.replace('_ROUTE', '_SCREEN_NAME'),
      };

      break;
    }
    case AnalyticsEvent.EnterItemPage: {
      logPayload = logData.data;

      break;
    }
    default:
      break;
  }

  return logPayload;
}

export function setCurrentScreen(
  view: FirebaseAnalyticsTypes.ScreenViewParameters
) {
  // ignore bot request
  if (isBot) return;

  firebase
    .analytics()
    .logScreenView(view)
    .catch(e => {
      console.log('firebase setCurrentScreen error = ', e);
    });

  if (isWebPlatform) {
    const path = window.location.pathname;

    sendGtagEvent('config', Config.REACT_APP_GA_ID, { page_path: path });
    sendGtagEvent('config', Config.REACT_APP_GA_PLUS_APP_ID, {
      page_path: path,
    });
    sendGtagEvent('config', Config.REACT_APP_GOOGLE_ADS_ID, {
      page_path: path,
    });
  }
}
