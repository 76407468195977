import { useCallback } from 'react';
import { Text } from 'react-native';

import IconSearch from '../../../../../../assets/svg/IconSearch';
import { warrior } from '../../../../../../constants/color';
import { WebLinkOpenType } from '../../../../../../types/Common';
import { KeywordsSuggestion } from '../../../../../../types/SuggestionResponse';
import routeUtils from '../../../../../../utils/routes';
import { S } from './styles';
import * as utils from './utils';

interface Props {
  data: KeywordsSuggestion;
  submitKeyword: (term: string) => void;
}

export default function KeywordItem({ data, submitKeyword }: Props) {
  const { title, finder } = data;
  const href = routeUtils.compileToPath('SearchResultPage', {
    queryText: title,
  });

  const handlePress = useCallback(() => {
    submitKeyword(title);
  }, [submitKeyword, title]);

  return (
    <S.AutocompleteTouchable
      accessibilityRole="link"
      href={href}
      onPress={handlePress}
      target={WebLinkOpenType.SELF}
    >
      <S.IconSearchContainer>
        <IconSearch fillColor={warrior} size={18} />
      </S.IconSearchContainer>
      <Text>
        {utils.getTextFromFinder(finder).map(({ highlight, text }, index) => {
          return (
            <S.Text highlight={highlight} key={`${text}-${index}`}>
              {text}
            </S.Text>
          );
        })}
      </Text>
    </S.AutocompleteTouchable>
  );
}
