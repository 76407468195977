import { ScrollView, StyleSheet } from 'react-native';

import { SearchType } from '../../../../../types/SearchType';
import { SuggestionResponse } from '../../../../../types/SuggestionResponse';
import CategorySection from './CategorySection';
import KeywordSection from './KeywordSection';
import SellerSection from './SellerSection';
import S from './styles';

const styles = StyleSheet.create({
  scrollContent: {
    marginHorizontal: 15,
    marginBottom: 15,
  },
});

interface Props {
  data: Partial<SuggestionResponse>;
  submitKeyword: (term: string) => void;
  logAndCloseModal: (text: string, searchType: SearchType) => void;
}

export default function AutocompleteMenu(props: Props) {
  const { data, submitKeyword, logAndCloseModal } = props;
  const { keywords = [], categories = [], filters = [], sellers = [] } = data;

  const showSeparator =
    keywords.length > 0 && (categories.length > 0 || filters.length > 0);

  return (
    <ScrollView
      contentContainerStyle={styles.scrollContent}
      keyboardShouldPersistTaps="handled"
    >
      <SellerSection sellers={sellers} />
      <CategorySection
        categories={categories}
        filters={filters}
        logAndCloseModal={logAndCloseModal}
      />
      {showSeparator && <S.Separator testID="separator" />}
      <KeywordSection keywords={keywords} submitKeyword={submitKeyword} />
    </ScrollView>
  );
}
