import { LoginOrRegisterMethod } from '../../../constants/LoginOrRegisterMethod';
import { LoginByFacebookPayload } from '../../../redux/actions/loginByFacebook';
import { getFCMToken } from '../../../utils/packages/firebase';
import { openFailDialog } from '../utils';

declare const window: any;

export enum FBLoginStatus {
  CONNECTED = 'connected',
  NOT_AUTHORIZED = 'not_authorized',
  UNKNOWN = 'unknown',
}

// ref: https://developers.facebook.com/docs/facebook-login/web/
interface FBLoginResponse {
  status: FBLoginStatus;
  authResponse: null | {
    accessToken: string;
    expiresIn: string;
    signedRequest: string;
    userID: string;
  };
}

type FBOauthCallback = (FBLoginResponse) => void;

export interface FBSdk {
  login: (FBOauthCallback) => void;
  getLoginStatus: (FBOauthCallback) => void;
}

let _fbLoginState: FBLoginResponse | undefined;

async function loginAfterConnected(
  loginStatus: FBLoginResponse,
  loginByFacebook: Function,
  cb: Function,
  inviteCode?: string
) {
  const accessToken = loginStatus?.authResponse?.accessToken || undefined;
  const userID = loginStatus?.authResponse?.userID || '';

  if (typeof accessToken === 'undefined') {
    openFailDialog('FB login/register fail', 'accessToken undefined');
  } else {
    const fcmToken = await getFCMToken();

    loginByFacebook({
      fb_access_token: accessToken,
      fcm_token: fcmToken,
      loginMethod: LoginOrRegisterMethod.FACEBOOK,
      userID,
      success: cb,
      invite_code: inviteCode,
    });
  }
}

export function detectFromFBAuth() {
  return /https:\/\/((mobile|m)\.)?facebook\.com\/(.+)oauth(.+)/.test(
    document.referrer
  );
}

export function redirectIfFromFBAuth(
  loginStatus: FBLoginResponse,
  loginByFacebook,
  callback,
  inviteCode?: string
) {
  const isConnected = loginStatus.status === FBLoginStatus.CONNECTED;

  if (detectFromFBAuth() && isConnected) {
    loginAfterConnected(
      loginStatus,
      loginByFacebook,
      () => {
        callback();
      },
      inviteCode
    );
  }
}

interface Props {
  loginByFacebook: (payload: LoginByFacebookPayload) => void;
  inviteCode?: string;
}

export function getLoginStatus(): Promise<[null, FBLoginResponse]> {
  return new Promise(resolve => {
    (window.FB as FBSdk).getLoginStatus((response: FBLoginResponse) => {
      _fbLoginState = response;

      resolve([null, response]);
    });
  });
}

export function redirectIfFromFBAuthWhoIsConnected(props: Props) {
  async function getStatusAfterFbInit(resolveGetStatus, rejectGetStatus) {
    const { loginByFacebook, inviteCode } = props;
    const [getStatusError, response] = await getLoginStatus();

    function callback() {
      console.log('Facebook Logined');
    }

    if (getStatusError) {
      rejectGetStatus([getStatusError]);
    } else {
      redirectIfFromFBAuth(response, loginByFacebook, callback, inviteCode);
      resolveGetStatus([null]);
    }
  }

  return new Promise(async (resolve, reject) => {
    if (window.fbInited) {
      await getStatusAfterFbInit(resolve, reject);
    } else {
      window.fbAsyncInitPromise.then(async () => {
        await getStatusAfterFbInit(resolve, reject);
      });
    }
  });
}

export default async function facebookLogin(props: Props) {
  const { loginByFacebook, inviteCode } = props;

  function callback() {
    console.log('Facebook Logined');
  }

  function loginAfterFBConnected() {
    (window.FB as FBSdk).login((response: FBLoginResponse) => {
      // connected means that login was successful.
      if (response.status === FBLoginStatus.CONNECTED) {
        loginAfterConnected(response, loginByFacebook, callback, inviteCode);
      } else {
        console.warn('User cancelled login or did not fully authorize.');
      }
    });
  }

  switch (true) {
    case !_fbLoginState: {
      const [getStatusError, response] = await getLoginStatus();

      if (getStatusError) {
        console.warn('User cancelled login or did not fully authorize.');
      } else {
        if (response.status === FBLoginStatus.CONNECTED) {
          loginAfterConnected(response, loginByFacebook, callback, inviteCode);
        } else {
          loginAfterFBConnected();
        }
      }
      break;
    }
    case !!_fbLoginState && _fbLoginState.status === FBLoginStatus.CONNECTED: {
      loginAfterConnected(
        _fbLoginState!,
        loginByFacebook,
        callback,
        inviteCode
      );
      break;
    }
    default: {
      loginAfterFBConnected();
      break;
    }
  }
}
