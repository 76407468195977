import omit from 'lodash/omit';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import ElementType from '../../constants/ElementType';
import { FireHoseAnalyticsEvent } from '../../constants/FireHoseAnalyticsEvent';
import { isBot } from '../../constants/misc';
import { logFirehose, toastFirehoseEvent } from '../../context/devLogFirehose';
import { DANGER_GET_STORE_BY } from '../../redux/store';
import { countryCodeHandler } from '../countryInfo';
import sendAWSFirehose from './awsFirehose/sendAWSFirehose';
import { formatData, getGeneralInfo, getMemberInfo } from './awsFirehose/util';
import { transformToStandardEvent } from './transformToStandardEvent';

const awsFirehose = async (logEvent: AnalyticsEvent, logData, store) => {
  // ignore bot request
  if (isBot) return;

  let recordsData: Record<string, any>[] = [];
  let toastContent = '';
  let currentStore = store;

  const firehoseEvent = transformToStandardEvent(FireHoseAnalyticsEvent)[
    logEvent
  ];

  if (!firehoseEvent) {
    return;
  }

  switch (logEvent) {
    case AnalyticsEvent.ScreenView: {
      const memberReducer = DANGER_GET_STORE_BY('memberReducer');
      const country_code = countryCodeHandler.get();

      currentStore = {
        ...currentStore,
        memberReducer,
      };

      recordsData = [{ country_code }];

      break;
    }
    case AnalyticsEvent.Impression: {
      recordsData = logData.data.map(data => {
        const formData = formatData(data, currentStore);

        return formData;
      });

      break;
    }
    case AnalyticsEvent.Click: {
      const { data } = logData;
      const formData = formatData(data, currentStore);

      toastContent = `firehose-event: ${firehoseEvent}`;
      recordsData = [formData];

      break;
    }
    case AnalyticsEvent.ViewItem: {
      // firehose generated 欄位: 沒有 source 時帶 type
      logData.data.source = logData.data.itemType;

      const formData = formatData(logData.data, currentStore);

      recordsData = [formData];
      toastContent = `firehose: ${firehoseEvent}, where: ${formData?.where}`;

      break;
    }
    case AnalyticsEvent.AddToCart: {
      const formData = formatData(logData, currentStore);

      recordsData = [formData];
      toastContent = `firehose-event: ${firehoseEvent}`;

      break;
    }
    case AnalyticsEvent.ECommercePurchase: {
      const { detail_data, shipping_fee, price, transaction_id } = logData.data;

      toastContent = `firehose-event: ${firehoseEvent}`;

      recordsData = detail_data.map(data => {
        const formData = formatData(
          {
            ...data,
            id: data.submit_id,
            amount: data.quantity,
            elementType: ElementType.Item,
          },
          currentStore
        );

        return {
          ...omit(formData, ['tid']), // only view, click need tid
          revenue: price,
          shipping_fee,
          transaction_id,
        };
      });

      break;
    }
    case AnalyticsEvent.AddToWishList: {
      const formData = formatData(logData, currentStore);

      recordsData = [formData];
      toastContent = `firehose-event: ${firehoseEvent}`;

      break;
    }
    default: {
      break;
    }
  }

  const [memberInfo, generalInfo] = await Promise.all([
    getMemberInfo(currentStore),
    getGeneralInfo(),
  ]);

  toastFirehoseEvent(toastContent);
  logFirehose(firehoseEvent, recordsData, generalInfo, memberInfo);

  const recordsDataStringArray = recordsData.map(data => {
    return {
      Data: JSON.stringify({
        event: firehoseEvent,
        ...generalInfo,
        ...memberInfo,
        ...data,
      }),
    };
  });

  sendAWSFirehose(recordsDataStringArray);

  return recordsDataStringArray;
};

export default awsFirehose;

export function setCurrentScreen() {
  awsFirehose(AnalyticsEvent.ScreenView, {}, {});
}
