import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { ZipData } from '../../types/ZipCode';
import { getZipCode } from '../actions/getZipCodeActions';
import { getZipCodeByCountry } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof getZipCode.request>;
const outputActions = {
  success: getZipCode.success,
  failure: getZipCode.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getZipCodeEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getZipCode.request)),
    switchMap((action: RequestActionTypes) => {
      const countryCode = action.payload.countryCode;

      return getZipCodeByCountry(countryCode)
        .then((response: AxiosResponse<ZipData>) =>
          getZipCode.success({
            countryCode,
            data: response.data.zip_data,
          })
        )
        .catch((error: AxiosError) => getZipCode.failure({ error }));
    })
  );
};

export default getZipCodeEpic;
