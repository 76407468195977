import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import {
  clearFormAction,
  updateSelectionAction,
  updateSuggestionAction,
} from '../actions/deleteAccountActions';

export const initialState = {
  suggestionMaxLength: 100,
  selection: new Array(8).fill(false) as boolean[],
  suggestion: '',
};

type RequestActionTypes = ActionType<
  | typeof updateSuggestionAction
  | typeof updateSelectionAction
  | typeof clearFormAction
>;

export default produce(
  (draft: Draft<typeof initialState>, action: RequestActionTypes) => {
    switch (action.type) {
      case getType(updateSelectionAction): {
        const { index, value } = action.payload;

        draft.selection[index] = value;
        break;
      }
      case getType(updateSuggestionAction): {
        const { suggestion } = action.payload;

        draft.suggestion = suggestion;
        break;
      }
      case getType(clearFormAction): {
        draft.selection = new Array(8).fill(false) as boolean[];
        draft.suggestion = '';
        break;
      }
      default: {
        break;
      }
    }
  },
  initialState
);
