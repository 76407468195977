import S from './style';

export default function OnlyDevSetDomain({ domain, setDomain, showToast }) {
  return (
    <S.RowItem>
      <S.Label>Domain</S.Label>
      <S.FlexRow>
        <S.FormInput
          placeholder={domain}
          onChangeText={v => setDomain(v.trim())}
          onBlur={({ nativeEvent: { text } }) =>
            showToast(
              `OK, Set Env to ["${text}"], clean AsyncStorage if you want to reset`,
              undefined,
              undefined,
              5000
            )
          }
          value={domain}
          autoCorrect={false}
          autoCapitalize="none"
        />
      </S.FlexRow>
    </S.RowItem>
  );
}
