import { useMemo } from 'react';

import { translate } from '../i18n';
import { useRoute } from '../utils/useRoute';

// 取得活動頁 url 的 pathname
export const getCampaignThemePathname = (url: string): string => {
  return url.replace(/^https?\:\/\/([^\/?#]+)/i, '').split('?')[0];
};

export const useCanonical = (): string => {
  const route = useRoute();

  if (!window.location) return '';

  const canonical = route?.params?.url
    ? getCampaignThemePathname(route.params.url)
    : window.location.pathname + window.location.search;

  return canonical || '';
};

export const useMeta = (
  title?: string,
  suffix?: string,
  description?: string
) => {
  const canonical = useCanonical();

  return useMemo(() => {
    let displayTitle = '';

    if (title) {
      displayTitle = `${title} | `;

      if (suffix) {
        displayTitle += suffix;
      } else {
        displayTitle += translate('vb-title-suffix-long');
      }
    } else {
      displayTitle += translate('VeryBuy-title');
    }

    return {
      title: displayTitle,
      ogTitle: displayTitle,
      canonical,
      description,
      ogDescription: description,
    };
  }, [canonical, title, suffix, description]);
};
