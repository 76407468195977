import { Dispatch } from 'react';
import { createAsyncAction } from 'typesafe-actions';

import { SortBy } from '../../constants/sortData';
import { ListLoadingTypes } from '../../types/Common';
import { PayloadWithFilter } from '../../types/PayloadWithFIlter';
import {
  SearchUserSubmit,
  SearchUserSubmitItem,
} from '../../types/SearchUserSubmit';

type RequiredKeys<T, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>;

interface SearchParams {
  queryText?: string;
  seller_id?: string;
  topIds?: string;
  tags?: string[];
}

export interface DefaultSearchWithTokenPayload
  extends PayloadWithFilter,
    SearchParams {
  dispatch: Dispatch<any>;
  loadingType: ListLoadingTypes;
  page: number;
  per_page?: number;
  sortBy: SortBy;
  success: (products: SearchUserSubmitItem[]) => void;
}

export type SearchWithTokenPayload =
  | RequiredKeys<DefaultSearchWithTokenPayload, 'queryText'>
  | RequiredKeys<DefaultSearchWithTokenPayload, 'seller_id'>
  | RequiredKeys<DefaultSearchWithTokenPayload, 'category'>
  | RequiredKeys<DefaultSearchWithTokenPayload, 'tags'>;

export interface SearchWithTokenSuccessPayload {
  data: SearchUserSubmit;
  loadingType: ListLoadingTypes;
}

export interface SearchWithTokenFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getSearchWithTokenActions.request>;
}

export const getSearchWithTokenActions = createAsyncAction(
  'verybuy/GET_SEARCH_WITH_TOKEN',
  'verybuy/GET_SEARCH_WITH_TOKEN_SUCCESS',
  'verybuy/GET_SEARCH_WITH_TOKEN_FAIL'
)<
  SearchWithTokenPayload,
  SearchWithTokenSuccessPayload,
  SearchWithTokenFailPayload
>();
