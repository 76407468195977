import { useEffect } from 'react';

import type { AnalyticsEvent } from '../../../constants/AnalyticsEvent';
import useScript from '../../../hooks/useScript';
import { getLineTagEvenData } from './getLineTagEventData';

declare let _lt: Function;

let initialized = false;

const savedEvent: ReturnType<typeof getLineTagEvenData>[] = [];

export const sendLineTagEvent = (
  eventName: AnalyticsEvent,
  params: Record<string, string | number> = {}
) => {
  if (!process.env.REACT_APP_LINE_TAG_ID) {
    return;
  }

  const event = getLineTagEvenData(eventName, params);

  if (!event) {
    return;
  }

  if (!initialized) {
    savedEvent.push(event);

    return;
  }

  _lt('send', 'cv', event, [process.env.REACT_APP_LINE_TAG_ID]);
};

const useLineTag = () => {
  const baseUrl =
    location.protocol === 'https:'
      ? 'https://d.line-scdn.net'
      : 'http://d.line-cdn.net';

  return useScript(
    `${baseUrl}/n/line_tag/public/release/v1/lt.js`,
    undefined,
    () => '_lt' in window
  );
};

export const InitLineTag = () => {
  const [loaded, loadError] = useLineTag();

  useEffect(() => {
    if (!loaded || loadError || !process.env.REACT_APP_LINE_TAG_ID) {
      return;
    }

    _lt('init', {
      customerType: 'lap',
      tagId: process.env.REACT_APP_LINE_TAG_ID,
    });

    _lt('send', 'pv', [process.env.REACT_APP_LINE_TAG_ID]);

    initialized = true;

    while (savedEvent.length) {
      const event = savedEvent.pop();

      if (event) {
        _lt('send', 'cv', event, [process.env.REACT_APP_LINE_TAG_ID]);
      }
    }
  }, [loadError, loaded]);

  return null;
};
