import { StyleSheet } from 'react-native';

import { coral, warrior } from '../../constants/color';

export const PRICE_DEFAULT_PADDING_BOTTOM = 3;

const defaultStyles = StyleSheet.create({
  currencyPrice: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  labelStyle: {
    fontSize: 12,
    marginRight: 8,
    paddingBottom: 2,
    color: warrior,
  },
  priceText: {
    color: coral,
    // Fix OPPO R9 display issue
    // https://blog.csdn.net/u010379595/article/details/84990908
    fontFamily: 'System',
    borderWidth: 1,
    borderColor: 'transparent',
  },
});

export default defaultStyles;
