import type { Dispatch } from 'react';
import { createAsyncAction } from 'typesafe-actions';

import type { ListLoadingTypes } from '../../types/Common';
import type { Data as ProductReviewDetailData } from '../../types/ProductReviewDetailResponse';

export interface ProductReviewDetailPayload {
  id: string;
  loadingType: ListLoadingTypes;
  dispatch: Dispatch<any>;
}

export interface ProductReviewDetailSuccessPayload {
  data: ProductReviewDetailData;
}

export interface ProductReviewDetailFailPayload {
  error: Error;
}

export const getProductReviewDetailActions = createAsyncAction(
  'verybuy/GET_PRODUCT_REVIEW_DETAIL',
  'verybuy/GET_PRODUCT_REVIEW_DETAIL_SUCCESS',
  'verybuy/GET_PRODUCT_REVIEW_DETAIL_FAIL'
)<
  ProductReviewDetailPayload,
  ProductReviewDetailSuccessPayload,
  ProductReviewDetailFailPayload
>();
