// 狀況跟 HeaderLeft 一樣，可參考 HeaderLeft 的處理方式
import { ComponentType } from 'react';

import { PropsNavigationType } from '../navFactoryTypes';

interface HeaderRightProps {
  CustomNavRight?: ComponentType<any>;
  isFocused?: boolean | undefined;
  navigation: PropsNavigationType | undefined;
  screen: string;
  screenKey?: string;
}

export default function HeaderRight({
  CustomNavRight,
  screenKey,
  ...rest
}: HeaderRightProps) {
  if (CustomNavRight) {
    const defaultScreenKey = screenKey || rest.screen;
    const navigation = rest.navigation || ({} as PropsNavigationType);

    return (
      <CustomNavRight
        screenKey={navigation?.state?.key || defaultScreenKey}
        {...rest}
      />
    );
  }

  return null;
}
