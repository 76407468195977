import styled from 'styled-components/native';

import { translate } from '../../../i18n';
import { makeItemColorAmountById } from '../../../redux/selectors/itemDetailSelector';
import { useSelector } from '../../../redux/utils';

const ColorAmountTag = ({ id }) => {
  const colorAmount = useSelector(makeItemColorAmountById(id));

  return (
    <S.ColorAmountTag>
      <S.ColorAmountTagText>
        {colorAmount === 1
          ? translate('single-color-amount-tag')
          : translate('color-amount-tag', {
              amount: colorAmount > 99 ? '99+' : colorAmount,
            })}
      </S.ColorAmountTagText>
    </S.ColorAmountTag>
  );
};

const S = {
  ColorAmountTag: styled.View`
    border: ${({ theme }) => theme.palette.neutralLight} 1px solid;
    border-radius: 12px;
    margin-bottom: 5px;
    margin-left: -4px;
    padding: 1px 6px;
  `,
  ColorAmountTagText: styled.Text`
    font-size: 13px;
    border: 1px solid transparent;
    color: ${({ theme }) => theme.palette.neutralDark};
  `,
};

export default ColorAmountTag;
