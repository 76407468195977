import { Component, createRef } from 'react';
import { Animated, FlatList, FlatListProps, ViewToken } from 'react-native';

import { LogFirehoseContextInterface } from '../../context/devLogFirehose';
import { Impression } from '../../types/CTR';
import Config from '../../utils/configs';
import { ImpressionDataProps } from './types';

interface Props extends FlatListProps<any>, ImpressionDataProps {
  dataProducer?: (data: ViewToken, props: ImpressionDataProps) => Impression[];
  animated?: boolean;
  isFocused?: boolean;
}

interface ContainerProps extends LogFirehoseContextInterface {
  logImpressionBatch: Function;
}

const VIEWABLE_CONFIG = {
  itemVisiblePercentThreshold: 0.8,
  minimumViewTime: 500,
};

export default class FlatListWithImpression extends Component<
  Props & ContainerProps
> {
  static defaultProps = {
    isVisible: true,
    firehoseLogStateForDebug: false,
    updateFirehoseLogStateForDebug: () => undefined,
    isFocused: true,
  };

  ref = createRef<FlatList<any>>();

  savedImpressions: ViewToken[] = [];
  accumulatedItems: Map<string, number> = new Map();

  get listCurrent() {
    return this.ref.current;
  }

  onViewableItemsChanged = (e: {
    viewableItems: ViewToken[];
    changed: ViewToken[];
  }) => {
    const { viewableItems } = e;
    const {
      logImpressionBatch,
      isVisible,
      dataProducer,
      isFocused,
      onViewableItemsChanged,
    } = this.props;

    if (onViewableItemsChanged) {
      onViewableItemsChanged(e);
    }

    if (isFocused === false || typeof dataProducer === 'undefined') {
      return;
    }

    const items: ViewToken[] = viewableItems.reduce((acc: ViewToken[], cur) => {
      const { key } = cur;
      const accumulated = this.accumulatedItems.has(key);
      const isBlank = /^blank/.test(key);

      if (accumulated || isBlank) {
        return acc;
      }

      this.accumulatedItems.set(key, 1);

      return acc.concat(cur);
    }, []);

    if (items.length > 0) {
      // If isVisible is false, should save impression to savedImpressions,
      // waiting for isVisible changes to true.
      if (isVisible) {
        const data: Impression[] = items.reduce((acc: Impression[], cur) => {
          return acc.concat(dataProducer(cur, this.props));
        }, []);

        logImpressionBatch({ data });

        // force update to log impression
        this.props.updateFirehoseLogStateForDebug();
      } else {
        this.savedImpressions = this.savedImpressions.concat(items);
      }
    }
  };

  componentDidUpdate(prevProps: Props) {
    /**
     * If visibility changes from false to true,
     * log and clear saved impressions.
     */
    if (!prevProps.isVisible && this.props.isVisible) {
      const { logImpressionBatch, dataProducer } = this.props;

      if (
        this.savedImpressions.length > 0 &&
        typeof dataProducer !== 'undefined'
      ) {
        const data: Impression[] = this.savedImpressions.reduce(
          (acc: Impression[], cur) => {
            return acc.concat(dataProducer(cur, this.props));
          },
          []
        );

        logImpressionBatch({
          data,
        });

        this.savedImpressions.length = 0;

        // force update to log impression
        this.props.updateFirehoseLogStateForDebug();
      }
    }
  }

  /**
   * FlatList use viewableItems to determine weather the viewable item is changed or not,
   * clear it to let the next onViewableItemsChanged to be called even is same data.
   *
   * To learn more of the source code, see the url below
   * https://github.com/facebook/react-native/blob/v0.60.6/Libraries/Lists/ViewabilityHelper.js#L234
   */
  clearFlatListViewableItems = () => {
    if (this.ref.current!) {
      // @ts-ignore
      const virtualizedList = this.ref.current._listRef;

      if (virtualizedList) {
        const viewabilityTuples = virtualizedList._viewabilityTuples;

        if (viewabilityTuples && viewabilityTuples.length > 0) {
          for (const item of viewabilityTuples) {
            const viewabilityHelper = item.viewabilityHelper;

            if (viewabilityHelper) {
              const viewableItems = viewabilityHelper._viewableItems;

              if (viewableItems && typeof viewableItems.clear === 'function') {
                viewableItems.clear();
              }
            }
          }
        }
      }
    }
  };

  scrollToIndex = e => {
    this.ref.current?.scrollToIndex(e);
  };

  public clearImpressionAccumulatedItems = () => {
    this.accumulatedItems.clear();
    this.savedImpressions.length = 0;
    this.clearFlatListViewableItems();
  };

  render() {
    const {
      onRefresh,
      onViewableItemsChanged,
      logImpressionBatch,
      firehoseLogStateForDebug,
      animated,
      ...flatListProps
    } = this.props;

    // should be more careful about the props for FlatList
    // using deconstruct object to prevent typescript error for FlatList(not good, but only dev)
    const ignoreProps = Config.SHOULD_LOG_FIREHOSE
      ? { firehoseLogStateForDebug }
      : {};

    const FlatListComponent = animated ? Animated.FlatList : FlatList;

    return (
      <FlatListComponent
        testID="flatListWithImpression"
        // onViewableItemsChanged 一定要拆出去才行 不然會噴錯
        // https://github.com/facebook/react-native/issues/17408
        onViewableItemsChanged={this.onViewableItemsChanged}
        viewabilityConfig={VIEWABLE_CONFIG}
        ref={this.ref}
        {...ignoreProps}
        {...flatListProps}
      />
    );
  }
}
