import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { RecommendSubject } from '../../types/RecommendSubjectResponse';
import { actionsList } from '../rootAction';

const { getRecommendSubjectActions } = actionsList;

export interface State {
  isFetching: boolean;
  hasError: boolean;
  error?: Error | null;
  data: RecommendSubject[];
}

export const defaultState: State = {
  isFetching: false,
  hasError: false,
  error: null,
  data: [],
};

export default produce(
  (draft: State, action: ActionType<typeof getRecommendSubjectActions>) => {
    switch (action.type) {
      case getType(getRecommendSubjectActions.request): {
        draft.isFetching = true;
        break;
      }
      case getType(getRecommendSubjectActions.success): {
        const { data } = action.payload;

        draft.isFetching = false;
        draft.hasError = false;
        draft.error = null;
        draft.data = data.recommended_subjects;

        break;
      }
      case getType(getRecommendSubjectActions.failure): {
        draft.isFetching = false;
        draft.hasError = true;
        draft.error = action.payload.error;
        break;
      }
      default: {
        break;
      }
    }
  },
  defaultState
);
