import { createAsyncAction } from 'typesafe-actions';

import { Data, Label } from '../../types/WordsLabelsResponse';

export interface WordsLabelsPayload {
  queryText: string;
  success?: (labels: Label[]) => void;
}

export interface WordsLabelsSuccessPayload {
  data: Data;
  queryText: string;
}

export interface WordsLabelsFailPayload {
  error: Error;
  queryText: string;
}

export const getWordsLabelsActions = createAsyncAction(
  'verybuy/GET_WORDS_LABELS',
  'verybuy/GET_WORDS_LABELS_SUCCESS',
  'verybuy/GET_WORDS_LABELS_FAIL'
)<WordsLabelsPayload, WordsLabelsSuccessPayload, WordsLabelsFailPayload>();
