export const testIDs = {
  ListItem: {
    LIST_ITEM: 'listItem_',
  },
  ItemPage: {
    ITEM_PAGE_FLAT_LIST: 'ItemPageFlatList',
    SELECT_ITEM_SKU: 'selectItemSku', // 選擇商品規格
    RECOMMEND_LIST_CONTAINER: 'itemRecommendList',
    TOOL_SECTION(itemID) { return `ItemPage-${itemID}-ToolSection`; },
  },
  ItemPageHeader: {
    SearchIcon: 'ItemPage_SearchIcon',
  },
  ItemPageFooter: {
    ADD_TO_CART_BTN: 'addToCartBtn', // 外層加入購物車按鈕
    BUY_IT_BTN: 'buyItBtn', // 外層立即購買按鈕
    SHOPPING_CART: 'shoppingCart',
  },
  ItemSKU: {
    ITEM_PAGE_COMFIRM: 'ItemPageComfirm', // 顏色尺寸選擇頁的按鈕
    ADD_TO_CART: 'addToCart', // 內層加入購物車按鈕
    BUY_IT: 'buyIt', // 內層立即購買按鈕
    SIZE_BTN: 'sizeBtn_', // 需搭配 index
    COLOR_BTN: 'colorBtn_', // 需搭配 index
    SIZE_VIEW: 'sizeView',
    COLOR_VIEW: 'colorView',
    OUT_OF_STOCK: 'out-of-stock-btn',
  },
  ItemSKUModal: {
    COLOR_SIZE_MODAL: 'colorSizeModal',
  },
  CartPage: {
    CART_PAGE: 'cartPage',
    CHECKOUT_BTN: 'checkoutBtn', // 結帳
    CART_ITEM_: 'cartItem_',
    CONTENT_LIST: 'CartContentListView',
  },
  RecipientPage: {
    // 宅配收件者資料
    DELIVERY_NAME: 'deliveryName', // 名字
    AREA_PHONE: 'areaPhone', // 手機號碼
    DELIVERY_CITY_DISTRICK: 'deliveryCityDistrick', // 縣市、市區鄉鎮
    DELIVERY_ADDRESS: 'deliveryAddress', // 地址
    SAVE_BUTTON: 'NavRightSaveButton',
    SAVE_TEXT: 'saveText', // 儲存
  },
  ReceiptSection: {
    RECEIPT_OPTION: 'optionSection', // 發票選項
    ADD_RECEIPT_DETAIL: 'addDeliveryInfo', //新增發票資訊
    RECEIPT_IDENTIFIER: 'buyerIdentifier', //統編
    RECEIPT_NAME: 'buyerName',
  },
  CheckoutPage: {
    CHECKOUT_PAGE: 'checkoutPage',
    SHIPPING_METHOD: 'shippingMethod-', // 可搭配home、cvs
    SHIPPING_METHOD_HOME: 'shippingMethod-home', // 宅配
    SHIPPING_METHOD_CVS: 'shippingMethod-cvs', // 超商取貨
    ADD_DELIVERY_INFO: 'addDeliveryInfo', // 新增收件人資訊
    EDIT_TEXT: 'editText', // 編輯
    BILL_SECTION: 'billDetailSection',
    GO_PAYING_BTN: 'goPayingBtn', // 去付款
  },
  FilterTag: 'filterTag',
  Price: {
    priceText: 'priceText',
  },
  PaymentPage: {
    PAYMENT_PAGE: 'paymentPage',
    PAYMENT_METHOD: 'paymentMethod-', // 需搭配 ATM、CARD、CVS
    PAYMENT_METHOD_CARD: 'paymentMethod-CARD', // 信用卡付款
    PAYMENT_METHOD_ATM: 'paymentMethod-ATM', // ATM匯款
    PAYMENT_METHOD_CVS: 'paymentMethod-CVS', // 超商代碼繳費
    CHOOSE_CARD: 'chooseCard', // 管理卡片按鈕
    SUBMIT_BTN: 'submitBtn', // 送出
  },
  ChooseCreditCardPage: {
    GOT_IT: 'gotIt', // 我知道了
    DELETE_CARD: 'deleteCard', // 刪除卡片
    COMFIRM_DELETE: 'comfirmDelete', // 確認刪除卡片按鈕
  },
  HomePage: {
    HOME_PAGE: 'HomePage',
    CATEGORY_LIST: 'categoryList',
    HOMEPAGE_SEARCHBAR: 'HomePageSearchBar',
  },
  WelcomeLoginPage: {
    LOGIN_PAGE: 'loginPage',
    LOGIN_PAGE_CLOSE_BTN: 'loginPageCloseBtn',
    LOGIN_BTN: 'loginBtn',
    REGISTER_BTN: 'registerBtn'
  },
  LoginForm: {
    LOGIN_FORM: 'loginForm',
    LOGIN_SUBMIT_BTN: 'loginSubmitBtn',
  },
  LoginOrRegisterForm: {
    EMAIL_INPUT: 'emailInput',
    PASSWORD_INPUT: 'passwordInput',
  },
  MyPage: {
    MY_PAGE: 'myPage',
    MY_FUNC: 'my-func',
    LOGOUT: 'logout',
  },
  ProfileRow: {
    LOGIN_OR_REGISTER_BTN: 'LoginOrRegisterBtn',
  },
  SearchNavBar: {
    fakeSearchText: 'fakeSearchText',
    SEARCH_NAV_BAR: 'searchNavBar',
  },
  SearchBar: {
    SEARCH_INPUT: 'searchInput',
    SEARCH_CANCEL: 'searchBarCancel'
  },
  SearchResultPage: {
    SEARCH_RESULT_LIST: 'productItems',
    ITEM_LIST: 'itemList',
  },
  CategoryItem: {
    CATEGORY_PAIR: 'categoryPair_', //需配 index
  },
  ListPage: {
    LISTPAGE_FLATLIST: 'listPageFlatList',
  },
  FilterBar: {
    categoryDropdown: 'categoryDropdown',
    sortDropdown: 'sortDropdown',
    filterButton: 'filterButton'
  },
  FilterSection: {
    commonButton: 'common-button',
  },
  FilterPrice: {
    minPriceInput: 'minPriceInput',
    maxPriceInput: 'maxPriceInput'
  },
  FilterBarSubCatBox: {
    catScroll: 'catScroll',
    catItem: 'catItem',
  },
  FilterBarSortBox: {
    sortItem: 'sortItem',
  },
  CheckoutItemCard: {
    CART_ITEM: 'cartItem_',
    REMOVE_ITEM_BTN: 'removeItemBtn_',
    RIBBON_LABEL: 'ribbonLabel_',
  },
  TransactionResultPage: {
    CheckOrderButton: 'TransactionResultCheckOrder',
  },
  TransactionDetailPage: {
    SAFE_AREA: 'transactionDetailSafeArea',
    PAYMENT_STATUS: 'paymentStatus',
    CANCEL_ORDER: 'transactionDetailCancelOrder',
  },
  TransactionList: {
    TRANSACTION_LIST_ITEM: 'transactionListItem', // 訂單列表項目
  },
  BurgerItem: {
    MEMBER_CONTENT: 'BurgerMemberContent'
  },
  GeneralMobileWebHeader: {
    CONTAINER: 'GeneralMobileWebHeader',
    HAMBURGER_SEARCH_BUTTON: 'HamburgerSearchButton',
    HAMBURGER_BUTTON: 'HamburgerButton',
    SEARCH_BAR: 'SearchBar',
    LINK_TO_HOME_LOGO: 'LinkToHomeLogo',
  },
  LovesElement: {
    Button: 'loveElementBtn',
  },
};
