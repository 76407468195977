import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { patchMemberActions } from '../actions/patchMemberActions';
import { patchMember } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: patchMemberActions.success,
  failure: patchMemberActions.failure,
};

type RequestActionType = ActionType<typeof patchMemberActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const patchMemberEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(patchMemberActions.request)),
    switchMap((action: RequestActionType) => {
      const { failure, success, data } = action.payload;

      return patchMember(data)
        .then(response => {
          success();

          return patchMemberActions.success({ data: response.data });
        })
        .catch((error: AxiosError) => {
          failure();

          return patchMemberActions.failure({
            error,
            customErrorHandling: true,
          });
        });
    })
  );
};

export default patchMemberEpic;
