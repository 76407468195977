import { translate } from '../../../../../i18n';
import { SearchType } from '../../../../../types/SearchType';
import {
  TopKeywords,
  TopKeywordsStyle,
} from '../../../../../types/TopKeywordsResponse';
import Bubble from '../Bubble';
import HotBubble from '../HotBubble/HotBubble';
import { S } from './style';

interface Props {
  onHotBubblePress: ({
    topKeyword,
    searchType,
  }: {
    topKeyword: TopKeywords;
    searchType: SearchType;
  }) => void;
  hotSearchList: TopKeywords[];
}

const HotSearchSection = ({ hotSearchList, onHotBubblePress }: Props) => {
  if (hotSearchList.length === 0) {
    return null;
  }

  const renderBubble = (topKeyword: TopKeywords) => {
    const { style, title } = topKeyword;
    const handleOnPress = () => {
      onHotBubblePress({ topKeyword, searchType: SearchType.TopKeyword });
    };

    switch (style) {
      case TopKeywordsStyle.FIRE:
        return (
          <HotBubble
            key={`${style}-${title}`}
            onPress={handleOnPress}
            data={topKeyword}
          />
        );
      case TopKeywordsStyle.NONE:
        return (
          <Bubble
            key={`${style}-${title}`}
            bubbleText={topKeyword.title}
            onPress={handleOnPress}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <S.TitleContainer>
        <S.Title>{translate('hot-search')}</S.Title>
      </S.TitleContainer>
      <S.HotBubblesContainer>
        {hotSearchList.map(renderBubble)}
      </S.HotBubblesContainer>
    </>
  );
};

export default HotSearchSection;
