export interface PromotionalThemesResponse {
  data: Data;
}

export interface Data {
  promotional_themes: PromotionalTheme[];
}

export interface PromotionalTheme {
  id: string;
  title: string;
  url: string;
  image_urls: string[];
  landing_page: AllowedLandingPage;
}

export enum AllowedLandingPage {
  BestSeller = 'best_seller',
}
