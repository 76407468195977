import { AxiosResponse } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { LoginOrRegisterMethod } from '../../constants/LoginOrRegisterMethod';
import { MemberLoginByLineResponse } from '../../types/Member';

export interface LoginByLinePayload {
  code: string;
  fcm_token?: string;
  redirectUri?: string;
  loginMethod: LoginOrRegisterMethod;
  success?: (arg: MemberLoginByLineResponse['data']) => void;
  inviteCode?: string;
}

export interface LoginByLineSuccessPayload {
  result: AxiosResponse<MemberLoginByLineResponse['data']>;
  lineID: string;
  account: string;
  logEvent: AnalyticsEvent;
  method: LoginOrRegisterMethod;
}

export interface LoginByLineFailPayload {
  error: Error;
}

export const loginByLine = createAsyncAction(
  'verybuy/LOGIN_BY_LINE',
  'verybuy/LOGIN_BY_LINE_SUCCESS',
  'verybuy/LOGIN_BY_LINE_FAIL'
)<LoginByLinePayload, LoginByLineSuccessPayload, LoginByLineFailPayload>();
