import { Animated } from 'react-native';
import styled from 'styled-components/native';

import { coral, white } from '../../constants/color';

export const SHORT_WIDTH = 4;
export const LONG_WIDTH = 16;

export default {
  PaginationWrapper: styled.View`
    position: absolute;
    bottom: 21px;
    width: 100%;
    height: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  DotContainer: styled(Animated.View)`
    position: relative;
    margin: 0 2.5px;
    width: ${SHORT_WIDTH}px;
    height: 4px;
    border-radius: 10px;
    overflow: hidden;
  `,
  Dot: styled.View`
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-color: ${white};
  `,
  CurrentForeground: styled(Animated.View)`
    height: 4px;
    border-radius: 10px;
    background-color: ${coral};
    opacity: 1;
    position: absolute;
    top: 0;
    width: ${LONG_WIDTH}px;
  `,
};
