import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getCountriesActions } from '../actions/getCountriesActions';
import { getCountries } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getCountriesActions.success,
  failure: getCountriesActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getCountriesEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getCountriesActions.request)),
    switchMap(() => {
      return getCountries()
        .then(response =>
          getCountriesActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) => getCountriesActions.failure({ error }));
    })
  );
};

export default getCountriesEpic;
