import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getWordsLabelsActions } from '../actions/getWordsLabelsActions';
import { getWordsLabels } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getWordsLabelsActions.success,
  failure: getWordsLabelsActions.failure,
};

type RequestActionType = ActionType<typeof getWordsLabelsActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getWordsLabelsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getWordsLabelsActions.request)),
    switchMap((action: RequestActionType) => {
      const { queryText, success } = action.payload;

      return getWordsLabels(queryText)
        .then(response => {
          const { data } = response;

          if (success) {
            success(data.labels);
          }

          return getWordsLabelsActions.success({
            data,
            queryText,
          });
        })
        .catch((error: AxiosError) =>
          getWordsLabelsActions.failure({ error, queryText })
        );
    })
  );
};

export default getWordsLabelsEpic;
