import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { putPhoneVerificationCodeActions } from '../actions/putPhoneVerificationCodeActions';
import { putPhoneVerificationCode } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: putPhoneVerificationCodeActions.success,
  failure: putPhoneVerificationCodeActions.failure,
};

type RequestActionTypes = ActionType<
  typeof putPhoneVerificationCodeActions.request
>;

type OutputActionTypes = ActionType<typeof outputActions>;

const onFailure = (failure: (error) => void, error: Error) => {
  if (failure) {
    failure(error);
  }

  return putPhoneVerificationCodeActions.failure({
    error,
    customErrorHandling: true,
  });
};

const putPhoneVerificationCodeEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(putPhoneVerificationCodeActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { failure } = action.payload;

      return putPhoneVerificationCode()
        .then(response => {
          const remainingTimes = response?.data?.remaining_times;

          if (typeof remainingTimes === 'undefined') {
            return onFailure(failure, new Error('remaining_times not found'));
          }

          return putPhoneVerificationCodeActions.success({ remainingTimes });
        })
        .catch((error: AxiosError) => onFailure(failure, error));
    })
  );
};

export default putPhoneVerificationCodeEpic;
