import { Fragment, PureComponent } from 'react';
import {
  AccessibilityRole,
  Modal,
  Platform,
  Text,
  TextStyle,
  View,
} from 'react-native';

import IconSearch from '../../../assets/svg/IconSearch';
import IconVBLogo from '../../../assets/svg/IconVBLogo';
import { AnalyticsEvent } from '../../../constants/AnalyticsEvent';
import {
  coral,
  neutralGrey,
  veryBuyPink,
  white,
} from '../../../constants/color';
import { isCypress } from '../../../constants/Cypress';
import { SEARCH_RESULT_NOTFOUND_HINT } from '../../../constants/guideKeys';
import { testIDs } from '../../../constants/testIDs';
import GoBackBtn from '../../../elements/GoBackBtn';
import HeaderTitleWithHelmet from '../../../elements/HeaderTitleWithHelmet';
import Hint from '../../../elements/Hint';
import LinearGradient from '../../../elements/LinearGradient';
import Touchable from '../../../elements/Touchable';
import { translate } from '../../../i18n';
import { CatItem } from '../../../types/CatsResponse';
import { TopKeywords } from '../../../types/TopKeywordsResponse';
import * as navigationService from '../../../utils/navigationService';
import routeUtils from '../../../utils/routes';
import { PropsNavigationType } from '../navFactoryTypes';
import SearchFormPage from './SearchForm';
import styles from './styles';

const isWeb = Platform.OS === 'web';

interface Props {
  category?: CatItem;
  enableHelmet?: boolean;
  index: number;
  isFocused: boolean;
  shouldRenderHint: boolean;
  isLogin?: boolean;
  leftOpts?: Record<string, any>;
  logEvent: Function;
  navigation?: PropsNavigationType;
  placeholder: TopKeywords | null;
  queryText?: string;
  shouldLogin?: boolean;
}
interface State {
  isVisible: boolean;
}

export default class SearchNavBar extends PureComponent<Props, State> {
  static defaultProps = {
    enableHelmet: true,
    queryText: null,
  };

  state = {
    isVisible: false,
  };

  observableSource: any = null;

  get hasFilter() {
    const activeRoute = navigationService.getActiveRoute();
    const params = activeRoute?.params || {};

    const hasFilter = ['max_price', 'min_price', 'color'].some(key =>
      Object.keys(params).includes(key)
    );

    return hasFilter;
  }

  get theDisplayPlaceholder() {
    const { placeholder } = this.props;
    const candidates = [
      {
        type: 'query',
        text: this.searchQueryText(),
      },
      {
        type: 'top_keywords',
        text: placeholder?.title,
      },
      {
        type: 'default',
        text: translate('searchPlaceHolder'),
      },
    ];

    return candidates.find(
      item => typeof item.text === 'string' && item.text.length
    );
  }

  onPressNavBar = () => {
    const { logEvent } = this.props;

    this.setState({
      isVisible: true,
    });

    logEvent({
      logEvent: AnalyticsEvent.SearchOpen,
      data: {
        placeholder: this.theDisplayPlaceholder!.text,
        location: 'header',
      },
    });
  };

  onCloseSearchForm = (callback?: any) => {
    let stateCallback;

    // If not given callback function, onRequestClose has its callback function
    // and its type is not function.
    if (typeof callback === 'function') {
      stateCallback = () => {
        if (isWeb) {
          // https://github.com/Dekoruma/react-native-web-modal/blob/master/packages/modal-enhanced-react-native-web/src/index.js#L88
          // modal 關掉需要 300 ms
          setTimeout(callback, 300);
        } else {
          callback();
        }
      };
    }

    this.setState(
      {
        isVisible: false,
      },
      stateCallback
    );
  };

  handleGoHome = () => {
    navigationService.reset();
  };

  renderLeft = () => {
    if (this.props.leftOpts?.hidden) {
      return <View style={styles.emptyLeft} />;
    }

    return (
      <View style={styles.leftField}>
        {
          // index 0 means home page
          // currentScene had a delay bug here
          this.props.index === 0 ? (
            <Touchable
              accessibilityRole="link"
              onPress={this.handleGoHome}
              href={routeUtils.compileToPath('Home')}
            >
              <IconVBLogo size={32} />
            </Touchable>
          ) : (
            <GoBackBtn />
          )
        }
      </View>
    );
  };

  searchQueryText = () => {
    const { queryText, category } = this.props;

    if (category && Object.keys(category).length > 0) return category.name;

    return queryText;
  };

  renderSearchText = () => {
    interface PlaceholderProps {
      accessibilityRole?: AccessibilityRole;
      style: Array<TextStyle>;
      testID: string;
    }

    const thePlaceholderInfo = this.theDisplayPlaceholder;
    let theProps: PlaceholderProps = {
      style: [styles.fakeSearchBasicStyle, styles.fakeSearchPlaceholder],
      testID: 'fakeSearchPlaceholder',
    };

    if (thePlaceholderInfo!.type === 'query') {
      theProps = {
        accessibilityRole: 'header',
        style: [styles.fakeSearchBasicStyle, { color: neutralGrey }],
        testID: testIDs.SearchNavBar.fakeSearchText,
      };
    }

    return <Text {...theProps}>{thePlaceholderInfo!.text}</Text>;
  };

  componentDidUpdate() {
    if (this.state.isVisible && !this.props.isFocused) {
      this.setState({ isVisible: false });
    }
  }

  render() {
    const { shouldLogin, isLogin, enableHelmet } = this.props;

    if (shouldLogin && !isLogin) {
      return null;
    }

    const query = this.searchQueryText();

    return (
      <Fragment>
        {enableHelmet && (
          <HeaderTitleWithHelmet
            headerTitle={translate('search-title', {
              query,
            })}
            headerSuffix={translate('vb-title-suffix-medium')}
            hidden
          />
        )}
        <LinearGradient
          colors={[veryBuyPink, coral]}
          style={[styles.container, { position: 'relative' }]}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
        >
          {this.renderLeft()}

          {
            // A fake text input here
          }
          <Touchable
            accessibilityRole="button"
            style={[
              styles.middleField,
              styles.middleFieldWithMarginRight,
              { backgroundColor: white },
            ]}
            onPress={this.onPressNavBar}
            activeOpacity={1}
          >
            <View style={styles.fakeSearchInput} testID="searchNavBar">
              <IconSearch fillColor={coral} />
              {this.renderSearchText()}
            </View>
            {this.theDisplayPlaceholder?.type === 'query' &&
              !this.props.category &&
              this.props.shouldRenderHint &&
              !this.hasFilter &&
              !isCypress && (
                <Hint
                  guideKey={SEARCH_RESULT_NOTFOUND_HINT}
                  routeName="SearchResultPage"
                  hintDirection="top"
                  triangleAlignment="flex-start"
                  text={translate('SearchNotFoundHint')}
                  triangleOffset={10}
                  style={{ top: 30, left: 0 }}
                  shouldCloseAfterScreenDiff={false}
                />
              )}
          </Touchable>
        </LinearGradient>
        <Modal
          hardwareAccelerated
          transparent
          visible={this.state.isVisible}
          onRequestClose={this.onCloseSearchForm}
          // https://github.com/react-native-community/react-native-modal#the-modal-is-not-covering-the-entire-screen
          {...(isWeb ? { style: { margin: 0 } } : {})}
        >
          <SearchFormPage
            queryText={this.props.queryText}
            onClose={this.onCloseSearchForm}
          />
        </Modal>
      </Fragment>
    );
  }
}
