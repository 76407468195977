import { Text, TextInput, View } from 'react-native';
import styled from 'styled-components/native';

import {
  coral,
  neutralGrey,
  neutralLight,
  warrior,
  white,
} from '../../constants/color';
import Touchable from '../../elements/Touchable';

export default {
  RowItem: styled(View)`
    padding: 10px 15px;
    border-style: solid;
    border-bottom-width: 1px;
    border-color: ${neutralLight};
    background-color: ${white};
  `,
  Label: styled(Text)<{ flex?: boolean; warning?: boolean }>`
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 15px;
    color: ${({ warning }) => (warning ? coral : warrior)};
    ${({ flex }) => flex && '  flex:1'};
  `,
  Desc: styled(Text)<{ flex?: boolean; warning?: boolean; right?: boolean }>`
    font-size: 15px;
    color: ${({ right, warning }) =>
      warning ? coral : right ? warrior : neutralGrey};
    ${({ right }) =>
      right &&
      `
    padding-bottom: 10px;
    padding-top: 10px;
    `};
  `,
  Spacer: styled(View)`
    height: 15px;
  `,
  ViewForm: styled(View)`
    flex-direction: column;
    display: flex;
    width: 100%;
    justify-content: space-between;
  `,
  FlexRow: styled(View)`
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  `,
  FormInput: styled(TextInput)<{ centered?: boolean }>`
    flex: 1;
    border-color: ${neutralLight};
    border-width: 1px;
    margin: 7px;
    padding: 10px;
    font-size: 15px;
    text-align: ${props => (props.centered ? 'center' : 'left')};
  `,
  InfoItem: styled.View`
    padding: 10px 15px;
    border-style: solid;
    border-bottom-width: 1px;
    border-color: ${neutralLight};
    background-color: ${white};
    display: flex;
    flex-direction: row;
  `,
  FeedBackBtn: styled(Touchable)`
    padding: 15px 18px;
    background-color: ${white};
    border-style: solid;
    border-bottom-width: 1px;
    border-color: ${neutralLight};
  `,
  FeedBackBtnTitle: styled.Text`
    color: ${warrior};
    font-size: 15px;
  `,
};
