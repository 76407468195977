export default function withDecodeSomeProps(
  TheComponent,
  decodeProps: string[]
) {
  return props => {
    const decodedProps = decodeProps.reduce((result, k) => {
      return {
        ...result,
        [k]: decodeURIComponent(props[k]),
      };
    }, {});

    return (
      <TheComponent testID="withDecodeSomeProps" {...props} {...decodedProps} />
    );
  };
}
