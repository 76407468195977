/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { TransactionDetailData } from '../../types/TransactionDetail';
import { patchCancelTransactionActions } from '../actions/patchCancelTransactionActions';
import { patchTransactionAction } from '../actions/patchTransactionActions';
import { fetchTransactionDetail } from '../actions/transactionDetailActions';
import { getTransaction } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof fetchTransactionDetail.request>;
const outputActions = {
  success: fetchTransactionDetail.success,
  failure: fetchTransactionDetail.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const fetchTransactionDetailEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(fetchTransactionDetail.request),
      getType(patchTransactionAction.success),
      getType(patchCancelTransactionActions.success),
      getType(patchCancelTransactionActions.failure)
    ),
    concatMap((action: RequestActionTypes) => {
      const { transactionID, loadingType, onSuccess } = action.payload;

      return getTransaction(action.payload.transactionID)
        .then((response: AxiosResponse<TransactionDetailData>) => {
          if (typeof onSuccess === 'function') {
            onSuccess();
          }

          return fetchTransactionDetail.success({
            data: response.data,
            transactionID,
            loadingType,
          });
        })
        .catch((error: AxiosError) => {
          return fetchTransactionDetail.failure({ error, transactionID });
        });
    })
  );
};

export default fetchTransactionDetailEpic;
