import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes, TransactionStatus } from '../../types/Common';
import TransactionItemForList from '../../types/TransactionItemForList';

export interface GetTransactionListPayload {
  loadingType: ListLoadingTypes;
  type: TransactionStatus | 'all';
  page: number;
}

export interface GetTransactionListSuccessPayload {
  loadingType: ListLoadingTypes;
  type: TransactionStatus | 'all';
  items: TransactionItemForList[];
  page?: number;
}

export interface GetTransactionListFailPayload {
  type: TransactionStatus | 'all';
  error: Error;
}

const getTransactionListActions = createAsyncAction(
  'verybuy/GET_TRANSACTION_LIST',
  'verybuy/GET_TRANSACTION_LIST_SUCCESS',
  'verybuy/GET_TRANSACTION_LIST_FAIL'
)<
  GetTransactionListPayload,
  GetTransactionListSuccessPayload,
  GetTransactionListFailPayload
>();

export default getTransactionListActions;
