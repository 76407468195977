// FB standard event
// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events

export enum FBConversionAPIEvent {
  // ScreenView = 'ScreenView',
  ViewItem = 'ViewContent',
  AddToCart = 'AddToCart',
  ECommercePurchase = 'Purchase',
  // AddToWishList = 'AddToWishlist',
  // BeginCheckout = 'InitialCheckout',
  // SignUp = 'CompleteRegistration',
  // Search = 'Search',
}
