import { createSelector } from 'reselect';

import { NotificationGroup } from '../../types/Common';
import { AdditionalData, Notification } from '../../types/Notifications';
import { DataByIdKeyArgs, DataByKeyArgs } from '../../types/SelectorArgs';
import { State } from '../reducers/notificationsReducer';
import { RootState } from '../rootReducer';

const notificationsRow = (state: RootState) =>
  state?.notificationsReducer || {};

/**
 * Get notificationsReducer data by key
 *
 * #### Example
 *
 * ```ts
 * createStructuredSelector({
 *   // get notifications is fetching
 *   isFetching: makeNotificationsDataByKey({
 *     key: 'isFetching',
 *     defaultValue: false,
 *   })
 * })
 * ```
 *
 * @param key - key of the data
 * @param defaultValue - (optional) default value of the data
 */
export const makeNotificationsDataByKey = <
  K extends keyof State,
  T extends State[K]
>({
  key,
  defaultValue,
}: DataByKeyArgs<K, T>) => {
  return createSelector(notificationsRow, data => data?.[key] || defaultValue);
};

/**
 * Get notification ids by group, will return empty array if no found
 *
 * #### Example
 *
 * ```ts
 * createStructuredSelector({
 *   // get notifications id array by group
 *   notifications: makeNotificationsByGroup(NotificationGroup.Transaction)
 * })
 * ```
 */
export const makeNotificationsByGroup = (group: NotificationGroup) => {
  return createSelector(notificationsRow, data => data?.groups?.[group] || []);
};

export const makeNotificationById = (id: string) => {
  return createSelector(
    notificationsRow,
    data => data?.notifications?.[id] || {}
  );
};

/**
 * Use key to get data from the specific notification
 *
 * #### Example
 *
 * ```ts
 * createStructuredSelector({
 *   // get title of the notification
 *   title: makeDataFromNotification({
 *     id,
 *     key: 'title',
 *     defaultValue: '',
 *   })
 * })
 * ```
 *
 * @param id - id of the notification
 * @param key - key of the data
 * @param defaultValue - (optional) default value of the data
 */
export const makeDataFromNotification = ({
  id,
  key,
  defaultValue,
}: DataByIdKeyArgs<keyof Notification>) => {
  return createSelector(
    notificationsRow,
    data => data?.notifications?.[id]?.[key] || defaultValue
  );
};

/**
 * Use key to get specific additional data from the specific notification
 *
 * #### Example
 *
 * ```ts
 * createStructuredSelector({
 *   // get transaction id of the notification
 *   title: makeAdditionalDataFromNotification({
 *     id,
 *     key: 'transaction_id',
 *   })
 * })
 * ```
 *
 * @param id - id of the notification
 * @param key - key of the additional data
 * @param defaultValue - (optional) default value of the data
 */
export const makeAdditionalDataFromNotification = ({
  id,
  key,
  defaultValue,
}: DataByIdKeyArgs<keyof AdditionalData>) => {
  return createSelector(
    makeDataFromNotification({ id, key: 'additional_data' }),
    data => data?.[key] || defaultValue
  );
};

export const makeHasUnreadNotification = () => {
  return createSelector(
    makeNotificationsDataByKey({
      key: 'notifications',
    }),
    (notifications = {}) => {
      return Object.values(notifications).some(
        notification => !notification.is_read
      );
    }
  );
};
