import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { logAnalyticsEvent } from '../../../redux/actions/analyticsLog';
import { makeCatById } from '../../../redux/selectors/catsSelector';
import { makePlaceholder } from '../../../redux/selectors/SearchFormPageSelector';
import { getShouldRenderSearchBarEmptyHintSelector } from '../../../redux/selectors/searchResult';
import SearchNavBar from './SearchNavBar';

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logEvent: logAnalyticsEvent,
    },
    dispatch
  );
};

const mapStateToProps = (_initialState, initialOwnProps) => {
  const catId = Number(initialOwnProps.navigation?.state?.params?.typeId);

  const selector = createStructuredSelector({
    category: makeCatById(catId),
    placeholder: makePlaceholder(),
    shouldRenderHint: getShouldRenderSearchBarEmptyHintSelector(),
  });

  return state => selector(state);
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchNavBar);
