import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { Item } from '../../types/similarItems';
import { getSimilarItemsActions } from '../actions/getSimilarItemsActions';
import { getSameSellerItems } from '../api';
import { RootAction } from '../rootAction';

const outputActions = {
  success: getSimilarItemsActions.success,
  failure: getSimilarItemsActions.failure,
};

type RequestActionTypes = ActionType<typeof getSimilarItemsActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getSimilarItemsEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getSimilarItemsActions.request)),
    switchMap((action: RequestActionTypes) => {
      const {
        itemId,
        page = 1,
        perpage = 20,
        loadingType,
        success,
      } = action.payload;

      return getSameSellerItems({
        product_id: itemId,
        page,
        perpage,
      })
        .then((response: AxiosResponse<Item[]>) => {
          const products = response.data.map(({ image_url, ...rest }) => ({
            ...rest,
            // 因 image_url 會在 reducer 被覆寫，造成元件 source 變化，進而踩到 iOS
            // FastImage 的 bug，在這裡先將尺寸鎖定在 600
            image_url: image_url.replace(/_v_250.(.+)$/, '_v_600.$1'),
          }));

          success(products);

          return getSimilarItemsActions.success({
            itemId,
            loadingType,
            products,
          });
        })
        .catch((error: AxiosError) =>
          getSimilarItemsActions.failure({ error })
        );
    })
  );
};

export default getSimilarItemsEpic;
