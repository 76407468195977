import { isIOS, isWebPlatform } from '../../boot/utils';

export const createFormData = (
  assets,
  args = { body: {}, assetsKey: 'images[]' } as Record<string, any>
) => {
  const data = new FormData();

  if (assets && assets.length) {
    assets.forEach(asset => {
      const payload = isWebPlatform
        ? asset.file
        : {
            name: asset.fileName,
            type: asset.type,
            uri: isIOS ? asset.uri.replace('file://', '') : asset.uri,
          };

      data.append(args.assetsKey, payload);
    });
  }

  Object.keys(args.body).forEach(key => {
    if (!args.body[key]) return;

    data.append(key, args.body[key]);
  });

  return data;
};
