import axios from 'axios';
import Base64 from 'crypto-js/enc-base64';
import UTF8 from 'crypto-js/enc-utf8';

import Config from '../../configs';
import { getCookie } from '../../cookieHelper';

export const sendConversionApi = payload => {
  const CNVAxios = axios.create();

  CNVAxios.post(Config.FACEBOOK_CAPI_ENDPOINT!, payload);
};

export const sendConversionApiEvents = async (
  logEvent,
  logData,
  enhanceMatchingData,
  eventID
) => {
  const generalData = {
    event_name: logEvent,
    event_time: Math.floor(Date.now() / 1000),
    event_source_url: document.URL,
    action_source: 'website',
  };

  const userData = {
    fbp: await getCookie('_fbp')
      .then(cookie => cookie)
      .catch(() => null),
    fbc: await getCookie('_fbc')
      .then(cookie => cookie)
      .catch(() => null),
    ...enhanceMatchingData,
  };

  // 基於數據機密考量傳送的用戶資料需要作加密
  // 但是後端的 SDK 又會自己多做一次加密  所以先使用 Base64 送到後端做解密  再由ＳＤＫ加密送給 Pixel
  const encodedWord = UTF8.parse(JSON.stringify(userData));
  const encodedPayload = Base64.stringify(encodedWord);

  const eventPayload = {
    event_data: {
      ...generalData,
      event_id: eventID,
    },
    user_data: encodedPayload,
    custom_data: logData,
  };

  sendConversionApi(eventPayload);

  return;
};
