import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getCategoryListActions } from '../actions/getCategoryListActions';
import { getCategoryList } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getCategoryListActions.success,
  failure: getCategoryListActions.failure,
};

type RequestActionType = ActionType<typeof getCategoryListActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getCategoryListEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getCategoryListActions.request)),
    switchMap((_action: RequestActionType) => {
      return getCategoryList()
        .then(response =>
          getCategoryListActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) =>
          getCategoryListActions.failure({ error })
        );
    })
  );
};

export default getCategoryListEpic;
