import { memo } from 'react';
import { StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';

import { translate } from '../../i18n';
import { PriceRangeType } from '../../types/Common';
import Price from '../Price';
import styles from './styles';

interface Props {
  minPrice: number;
  maxPrice: number;
  rangeType?: PriceRangeType;
  rangeContainerStyle?: StyleProp<TextStyle>;
  labelStyle?: StyleProp<TextStyle>;
  priceStyle?: StyleProp<TextStyle>;
  currencyStyle?: StyleProp<TextStyle>;
  tildeStyle?: StyleProp<TextStyle>;
  postfixStyle?: StyleProp<TextStyle>;
  priceContainerStyle?: StyleProp<ViewStyle>;
}

function PriceRange({
  minPrice,
  maxPrice,
  rangeType = PriceRangeType.OmitRange,
  rangeContainerStyle,
  labelStyle,
  priceStyle,
  currencyStyle,
  tildeStyle,
  postfixStyle,
  priceContainerStyle,
}: Props) {
  const renderPrice = args => {
    return (
      <Price
        labelStyle={labelStyle}
        priceStyle={priceStyle}
        currencyStyle={currencyStyle}
        containerStyle={priceContainerStyle}
        {...args}
      />
    );
  };

  const renderPriceWithRange = () => {
    return (
      <View style={styles.container}>
        {renderPrice({ price: minPrice })}
        <Text style={tildeStyle}>~</Text>
        {renderPrice({ price: maxPrice, hideCurrency: true })}
      </View>
    );
  };

  const renderPriceWithOmittedRange = () => {
    return (
      <View style={[styles.container, rangeContainerStyle]}>
        {renderPrice({ price: minPrice })}
        <Text style={[styles.postfixStyle, postfixStyle]}>
          {translate('price-postfix')}
        </Text>
      </View>
    );
  };

  const renderPriceRange = () => {
    switch (rangeType) {
      case PriceRangeType.OmitRange:
        return renderPriceWithOmittedRange();
      case PriceRangeType.Range:
        return renderPriceWithRange();
      default:
        return null;
    }
  };

  if (minPrice && maxPrice && minPrice === maxPrice) {
    return renderPrice({ price: minPrice });
  }

  if (minPrice && maxPrice) {
    return renderPriceRange();
  }

  return null;
}

export default memo(PriceRange);
