import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { ListLoadingTypes } from '../../types/Common';
import { PromotionalTheme } from '../../types/PromotionalThemesResponse';
import { State } from '../reducers/promotionalThemesReducer';
import { RootState } from '../rootReducer';

const reducerRaw = (state: RootState) => state.promotionalThemesReducer || {};

export const makePromoteItemDataById = (id: string) => {
  return createSelector(reducerRaw, (state: State) => {
    return state.data?.[id] || {};
  });
};

export const makePromoteItemDataByIdKey = <K extends keyof PromotionalTheme>(
  id: string,
  key: K,
  defaultValue: any
) => {
  return createSelector(
    makePromoteItemDataById(id),
    (item: PromotionalTheme) => {
      return item[key] ?? defaultValue;
    }
  );
};

export const makePromoteDataByKey = (key: keyof State, defaultValue: any) => {
  return createSelector(reducerRaw, (state: State) => {
    return state[key] ?? defaultValue;
  });
};

const makePlaceHolderKeys = (count: number) => {
  return new Array(count).fill('').map((_v, i) => `promote-blank${i}`);
};

export const makePromoteItems = () => {
  const placeholderCount = 2;
  const maxItemCount = 2;

  return createSelector(
    makePromoteDataByKey('dataKeyList', []),
    makePromoteDataByKey('isFetching', true),
    makePromoteDataByKey('loadingType', 'new'),
    (
      originItems: string[],
      isFetching: boolean,
      loadingType: ListLoadingTypes
    ) => {
      const items =
        isFetching && loadingType === 'new'
          ? makePlaceHolderKeys(placeholderCount)
          : originItems.slice(0, maxItemCount);

      return items;
    }
  );
};

// 目前需求是三天換一次
const PROMOTE_ITEMS_BADGE_THROTTLE_DAYS = 3;

export const makePromoteItemBadgeIndex = () => {
  return createSelector(makePromoteItems(), (items: string[]) => {
    const diff = dayjs().diff(0, 'day');
    const x = diff % (items.length * PROMOTE_ITEMS_BADGE_THROTTLE_DAYS);

    return Math.floor(x / PROMOTE_ITEMS_BADGE_THROTTLE_DAYS);
  });
};
