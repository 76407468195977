import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getTopKeywordsActions } from '../actions/getTopKeywordsActions';
import { getTopKeywords } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getTopKeywordsActions.success,
  failure: getTopKeywordsActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getTopKeywordsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getTopKeywordsActions.request)),
    switchMap(() => {
      return getTopKeywords()
        .then(response =>
          getTopKeywordsActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) => getTopKeywordsActions.failure({ error }));
    })
  );
};

export default getTopKeywordsEpic;
