import equal from 'fast-deep-equal';
import { FC, MutableRefObject, memo, useEffect, useMemo, useRef } from 'react';
import { Animated, StyleProp, ViewStyle } from 'react-native';

import S, { LONG_WIDTH, SHORT_WIDTH } from './PaginationStyles';

export type DotStyle = {
  shortWidth?: number;
  longWidth?: number;
};

interface Props {
  currentSlideIndex: number;
  slideCount: number;
  bannerProgress: MutableRefObject<Animated.Value>;
  wrapperStyle?: StyleProp<ViewStyle>;
  dotStyle?: DotStyle;
}

const INITIAL_VALUE = -16;
const CurrentPagination: FC<{
  isCurrent: boolean;
  bannerProgress: MutableRefObject<Animated.Value>;
}> = ({ isCurrent, bannerProgress }) => {
  const style = useMemo(() => {
    const translateX = bannerProgress.current.interpolate({
      inputRange: [0, 1],
      outputRange: [INITIAL_VALUE, 0],
    });

    return {
      transform: [{ translateX }],
    };
  }, []);

  if (!isCurrent) {
    return null;
  }

  return <S.CurrentForeground testID="currentPagination" style={style} />;
};

interface DotProps {
  isCurrent: boolean;
  bannerProgress: MutableRefObject<Animated.Value>;
  style?: DotStyle;
}

const Dot: FC<DotProps> = ({ isCurrent, style, bannerProgress }) => {
  const shortWidth = style?.shortWidth || SHORT_WIDTH;
  const longWidth = style?.longWidth || LONG_WIDTH;

  const startValue = isCurrent ? shortWidth : longWidth;
  const width = useRef(new Animated.Value(startValue)).current;

  useEffect(() => {
    const endValue = isCurrent ? longWidth : shortWidth;

    Animated.timing(width, {
      toValue: endValue,
      duration: 100,
      useNativeDriver: false,
    }).start();
  }, [isCurrent]);

  return (
    <S.DotContainer testID="paginationDotContainer" style={{ width }}>
      <S.Dot testID="paginationDot" />
      <CurrentPagination
        isCurrent={isCurrent}
        bannerProgress={bannerProgress}
      />
    </S.DotContainer>
  );
};

const BannerPagination: FC<Props> = ({
  currentSlideIndex,
  slideCount,
  wrapperStyle,
  dotStyle,
  bannerProgress,
}): JSX.Element => {
  const renderPagination = () => {
    const pagination = [] as JSX.Element[];

    for (let i = 0; i < slideCount; i++) {
      const isCurrent = currentSlideIndex === i;

      pagination.push(
        <Dot
          key={`paginationDot-${i}`}
          isCurrent={isCurrent}
          style={dotStyle}
          bannerProgress={bannerProgress}
        />
      );
    }

    return pagination;
  };

  return (
    <S.PaginationWrapper style={wrapperStyle}>
      {renderPagination()}
    </S.PaginationWrapper>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) =>
  equal(prevProps.currentSlideIndex, nextProps.currentSlideIndex);

export default memo(BannerPagination, areEqual);
