import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { SearchUserSubmit } from '../../types/SearchUserSubmit';
import {
  UserSubmitListSuccessPayload,
  getUserSubmitList as getUserSubmitListAction,
} from '../actions/getUserSubmitList';
import { searchUserSubmit } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof getUserSubmitListAction.request>;
const outputActions = {
  success: getUserSubmitListAction.success,
  failure: getUserSubmitListAction.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const getUserSubmitListEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getUserSubmitListAction.request)),
    switchMap((action: RequestActionTypes) => {
      const { success, loadingType, ...params } = action.payload;

      return searchUserSubmit(params)
        .then((response: AxiosResponse<SearchUserSubmit>) => {
          const { data } = response;
          const successPayload: UserSubmitListSuccessPayload = {
            data,
            loadingType,
          };

          success(data.products);

          return outputActions.success(successPayload);
        })
        .catch((error: AxiosError) => outputActions.failure({ error }));
    })
  );
};
