import { createAsyncAction } from 'typesafe-actions';

import { CouponType } from '../../constants/Coupon';
import { ListLoadingTypes } from '../../types/Common';
import { Coupon } from '../../types/Coupon';

export interface CouponListPayload {
  loadingType: ListLoadingTypes;
  page: number;
  limit: number;
  couponType: CouponType;
  isSelectPage?: boolean;
}

export interface CouponListSuccessPayload {
  coupons: Coupon[];
  page: number;
  couponType: CouponType;
}

export interface CouponListFailPayload {
  error: Error;
  couponType: CouponType;
}

export const getCouponListActions = createAsyncAction(
  'verybuy/GET_COUPON_LIST',
  'verybuy/GET_COUPON_LIST_SUCCESS',
  'verybuy/GET_COUPON_LIST_FAIL'
)<CouponListPayload, CouponListSuccessPayload, CouponListFailPayload>();
