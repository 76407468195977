import { FC } from 'react';
import { Animated, StyleProp, ViewStyle } from 'react-native';

import useActions from '../../hooks/useActions';
import { logAnalyticsEvent } from '../../redux/actions/analyticsLog';
import Touchable from './Touchable';
import { LogDataProps, TouchableProps } from './types';

interface Props extends Omit<TouchableProps, 'style'> {
  style?:
    | StyleProp<ViewStyle>
    | Animated.WithAnimatedValue<StyleProp<ViewStyle>>;
  animated?: boolean;
  logData?: LogDataProps | LogDataProps[];
}

// Workaround: <GlobalNormalDialog /> render 時無法正確呼叫 useActions 取得 store
// 故判斷存在 logData 時再傳入 logEvent
// https://verybuy99.slack.com/archives/CB1NQELCE/p1669627893783539
const TouchableWithLogEvent: FC<Props> = props => {
  const logEvent = useActions(logAnalyticsEvent);

  return <Touchable logEvent={logEvent} {...props} />;
};

const TouchableWithLog: FC<Props> = props => {
  const { logData } = props;

  return logData ? (
    <TouchableWithLogEvent {...props} />
  ) : (
    <Touchable {...props} />
  );
};

export default TouchableWithLog;
