import { useCallback, useMemo, useRef } from 'react';
import { Animated, View } from 'react-native';

import { isAndroid, isIOS, isWebPlatform } from '../../../../boot/utils';
import { AnalyticsEvent } from '../../../../constants/AnalyticsEvent';
import { MktType } from '../../../../constants/MktType';
import withDisplayImpression from '../../../../decorators/withDisplayImpression';
import Banner from '../../../../elements/Banner';
import {
  GET_BANNER_HEIGHT,
  GET_BANNER_WIDTH,
} from '../../../../elements/Banner/styles';
import Touchable from '../../../../elements/Touchable';
import useActions from '../../../../hooks/useActions';
import { logAnalyticsEvent } from '../../../../redux/actions/analyticsLog';
import { makeBannerDataByID } from '../../../../redux/selectors/bannersSelector';
import { useSelector } from '../../../../redux/utils';
import { bannerNavigation } from '../../../../utils/pageNavigation';
import { basicProducer } from '../../../FlatListWithImpression/utils';
import S, { styles } from './styles';

interface Props {
  index: number;
  layoutName: string;
  bannerID: string;
}

export default withDisplayImpression((props: Props) => {
  const logEvent = useActions(logAnalyticsEvent);
  const { index, bannerID, layoutName } = props;

  const shadowAnimated = useRef(new Animated.Value(0.4));
  const banner = useSelector(makeBannerDataByID(bannerID));

  const notDefaultBanner = banner && banner.image_urls;
  const sources = useMemo(() => {
    if (notDefaultBanner) {
      return {
        1: { uri: banner?.image_urls.sm },
        2: { uri: banner?.image_urls.md },
        3: { uri: banner?.image_urls.lg },
      };
    }

    return { 1: require('./assets/homepage_banner.png') };
  }, [notDefaultBanner, banner]);

  const shouldAnimateShadow = notDefaultBanner && isIOS;

  const handlePressIn = () => {
    if (shouldAnimateShadow) {
      Animated.timing(shadowAnimated.current, {
        toValue: 1,
        duration: 500,
        useNativeDriver: !isWebPlatform,
      }).start();
    }
  };
  const handlePressOut = () => {
    if (shouldAnimateShadow) {
      Animated.timing(shadowAnimated.current, {
        toValue: 0.4,
        duration: 500,
        useNativeDriver: !isWebPlatform,
      }).start();
    }
  };

  const bannerImageStyle = {
    height: GET_BANNER_HEIGHT(),
    width: GET_BANNER_WIDTH(),
  };

  const logData = useMemo(
    () =>
      basicProducer(
        { item: bannerID, index },
        { layoutName, mktType: MktType.Image }
      ),
    [bannerID, index, layoutName]
  );

  const navigateTo = useCallback(() => {
    if (banner) {
      const { type, url, title } = banner;

      if (url) {
        bannerNavigation(banner);

        logEvent({
          logEvent: AnalyticsEvent.Click,
          data: logData,
        });

        logEvent({
          logEvent: AnalyticsEvent.HomePageClick,
          data: {
            // 需求：實際 UI 上由 1 開始的 Banner 順序
            index: index + 1,
            type,
            title,
            layoutName,
          },
        });
      }
    }
  }, [banner, logEvent, logData, index, layoutName]);

  return (
    <Touchable
      disabled={typeof banner === 'undefined'}
      key={`banner${banner?.id}`}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      navigateTo={navigateTo}
      accessibilityRole="button"
      withoutFeedback
    >
      <View>
        {!isAndroid && (
          <S.BannerShadow
            style={[{ shadowOpacity: shadowAnimated.current }]}
            width={bannerImageStyle.width}
            height={bannerImageStyle.height}
          />
        )}
        <S.BannerWrapper>
          <Banner
            sources={sources}
            containerStyle={[styles.bannerContainer]}
            bannerStyle={[styles.banner, bannerImageStyle]}
            isFetching={/^blank/.test(bannerID)}
          />
        </S.BannerWrapper>
      </View>
    </Touchable>
  );
});
