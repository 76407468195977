import { createAsyncAction } from 'typesafe-actions';

import { SearchRecommend } from '../../types/SearchRecommend';

export interface SearchRecommendPayload {
  sortBy: string;
  queryText: string;
}

export interface SearchRecommendSuccessPayload {
  data: SearchRecommend;
  queryText: string;
}

export interface SearchRecommendFailPayload {
  error: Error;
}

export const searchRecommendActions = createAsyncAction(
  'verybuy/SEARCH_RECOMMEND',
  'verybuy/SEARCH_RECOMMEND_SUCCESS',
  'verybuy/SEARCH_RECOMMEND_FAIL'
)<
  SearchRecommendPayload,
  SearchRecommendSuccessPayload,
  SearchRecommendFailPayload
>();
