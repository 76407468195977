import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { MailType } from '../../types/Common';
import { Support } from '../../types/MailboxListResponse';

export interface MailboxListPayload {
  page: number;
  loadingType: ListLoadingTypes;
  mailType: MailType;
  perPage: number;
}

export interface MailboxListSuccessPayload {
  data: Support[];
  page: number;
  loadingType: ListLoadingTypes;
  mailType: MailType;
}

export interface MailboxListFailPayload {
  error: Error;
  mailType: MailType;
}

export const getMailboxListActions = createAsyncAction(
  'verybuy/GET_MAILBOX_LIST',
  'verybuy/GET_MAILBOX_LIST_SUCCESS',
  'verybuy/GET_MAILBOX_LIST_FAIL'
)<MailboxListPayload, MailboxListSuccessPayload, MailboxListFailPayload>();
