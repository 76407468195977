import { useCallback } from 'react';
import styled from 'styled-components/native';

import { AnalyticsEvent } from '../../../../../constants/AnalyticsEvent';
import { MktType } from '../../../../../constants/MktType';
import Touchable from '../../../../../elements/Touchable';
import useActions from '../../../../../hooks/useActions';
import { useTranslation } from '../../../../../i18n';
import { logAnalyticsEvent } from '../../../../../redux/actions/analyticsLog';
import { RecommendSubject } from '../../../../../types/RecommendSubjectResponse';
import pageNavigation from '../../../../../utils/pageNavigation';

const RecommendSubjectSection = ({ data }: { data: RecommendSubject[] }) => {
  const { t } = useTranslation();
  const logEvent = useActions(logAnalyticsEvent);

  const handlePress = useCallback(
    subject => {
      logEvent({
        logEvent: AnalyticsEvent.Click,
        data: {
          layoutName: 'recommend_topic',
          mktType: MktType.Word,
          element_content: subject.subject,
        },
      });
      pageNavigation(subject.url);
    },
    [logEvent]
  );

  return (
    <S.Container>
      <S.Title>{t('recommend-subjects')}</S.Title>
      <S.Subjects>
        {data.map((subject, index) => {
          return (
            <S.SubjectContainer
              key={index}
              href={subject.url}
              onPress={() => handlePress(subject)}
            >
              <S.SubjectText>{'#' + subject.subject}</S.SubjectText>
            </S.SubjectContainer>
          );
        })}
      </S.Subjects>
    </S.Container>
  );
};

const S = {
  Container: styled.View``,
  Title: styled.Text`
    margin: 12px 0;
    color: ${({ theme }) => theme.palette.neutralDark};
    font-size: 15px;
    line-height: 21px;
  `,
  Subjects: styled.View`
    flex-direction: row;
    flex-wrap: wrap;
  `,
  SubjectContainer: styled(Touchable)`
    padding: 6px 10px;
    margin: 0 10px 10px 0;
    background-color: ${({ theme }) => theme.palette.neutralWhite};
  `,
  SubjectText: styled.Text`
    color: ${({ theme }) => theme.palette.warrior};
    font-size: 13px;
  `,
};

export default RecommendSubjectSection;
