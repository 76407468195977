import { createAsyncAction } from 'typesafe-actions';

import { Asset } from '../../elements/UploadImage/types';

export interface SupportsReplyPayload {
  id: string;
  text: string;
  photos: Asset[];
  onSuccess: () => void;
  onFailure: () => void;
}

export interface SupportsReplySuccessPayload {
  id: string;
  text: string;
  photos: Asset[];
}

export interface SupportsReplyFailPayload {
  id: string;
  error: Error;
}

export const postSupportsReplyActions = createAsyncAction(
  'verybuy/POST_SUPPORTS_REPLY',
  'verybuy/POST_SUPPORTS_REPLY_SUCCESS',
  'verybuy/POST_SUPPORTS_REPLY_FAIL'
)<
  SupportsReplyPayload,
  SupportsReplySuccessPayload,
  SupportsReplyFailPayload
>();
