import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { LoginOrRegisterMethod } from '../../../constants/LoginOrRegisterMethod';
import { globalNormalDialogProxy } from '../../../elements/Dialog';
import useActions from '../../../hooks/useActions';
import { translate } from '../../../i18n';
import { loginByLine } from '../../../redux/actions/loginByLine';
import { MemberLoginByLineResponse } from '../../../types/Member';
import { NavigationParams } from '../../../types/NavigationParams';
import { ThirdPartyBtn } from '../ThirdPartySection';
import useLineLogin from './hooks/useLineLogin';

interface Props {
  [NavigationParams.EncodedPageParams]?: string;
  [NavigationParams.BackToPageKey]?: string;
  inviteCode?: string;
}

const onLoginSuccess = (data: MemberLoginByLineResponse['data']) => {
  if (data.is_bound) {
    globalNormalDialogProxy.open({
      contentText: translate('line-login-duplicated-email-dialog.content'),
      positiveText: translate('got-it'),
    });
  }
};

const LineLoginButton = (navigationParams: Props) => {
  const lineLogin = useActions(loginByLine.request);
  const state = useRef<string>(uuidv4());

  const handleLogin = useLineLogin({
    lineLogin,
    state,
    navigationParams,
    onSuccess: onLoginSuccess,
  });

  return (
    <ThirdPartyBtn type={LoginOrRegisterMethod.LINE} onPress={handleLogin} />
  );
};

export default LineLoginButton;
