import ResponsiveImage from '@expo/react-native-responsive-image';
import { Animated } from 'react-native';
import styled from 'styled-components/native';

import { GET_LAYOUT_MAX_WIDTH } from '../../constants/layout';

export const BANNER_MAX_WIDTH = 768;
export const BANNER_RATIO = 160 / 375;
export const GET_BANNER_WIDTH = () =>
  Math.min(GET_LAYOUT_MAX_WIDTH(), BANNER_MAX_WIDTH);
export const GET_BANNER_HEIGHT = () => GET_BANNER_WIDTH() * BANNER_RATIO;

export const S = {
  ResponsiveImage: styled(ResponsiveImage)`
    width: 100%;
    height: ${GET_BANNER_HEIGHT()}px;
  `,
  PlaceHolderContainer: styled(Animated.View)`
    width: 100%;
    height: 100%;
    position: absolute;
  `,
};
