import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import withAuth from '../../decorators/withAuth';
import withIsFocused from '../../decorators/withIsFocused';
import withLoadingView from '../../decorators/withLoadingView';
import { loadCartContent } from '../../redux/actions/loadCartContentActions';
import { makeIsLoading, makeLoginMethod } from '../../redux/selectors/member';
import WelcomeLoginPage from './WelcomeLoginPage';

const mapStateToProps = () => {
  const selector = createStructuredSelector({
    isLoading: makeIsLoading(),
    loginMethod: makeLoginMethod(),
  });

  return state => selector(state);
};

const mapDispatchToProps = {
  loadCartContent: loadCartContent.request,
};

const enhance = compose(withAuth, withIsFocused, withLoadingView);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enhance(WelcomeLoginPage));
