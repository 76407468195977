/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { SearchUserSubmit } from '../../types/SearchUserSubmit';
import { SearchSuccessPayload, searchActions } from '../actions/searchActions';
import { searchRecommendActions } from '../actions/searchRecommendActions';
import { searchUserSubmit } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof searchActions.request>;

const searchActionsEpic = (action$: Observable<RootAction>) => {
  return action$.pipe(
    ofType(getType(searchActions.request)),
    switchMap((action: RequestActionTypes) => {
      const {
        page,
        loadingType,
        queryText,
        isHistoryWordApplied,
        sortBy,
        minPrice,
        maxPrice,
        fuzzy,
        success,
        isFuzzyDiscoverySearch,
      } = action.payload;

      return from(searchUserSubmit(action.payload).then()).pipe(
        switchMap((response: AxiosResponse<SearchUserSubmit>) => {
          const { data } = response;
          const products = data.products || [];
          const successPayload: SearchSuccessPayload = {
            data,
            page,
            loadingType,
            sortBy,
            minPrice,
            maxPrice,
            queryText,
            fuzzy,
            isFuzzyDiscoverySearch,
          };

          success(products);

          if (loadingType === 'new') {
            successPayload.logEvent = AnalyticsEvent.ViewSearchResults;
            successPayload.isHistoryWordApplied = isHistoryWordApplied;
            successPayload.isSuccess = products.length > 0;
            successPayload.resultNumber = products.length;
          }

          if (products.length === 0 && page === 1) {
            return of(
              searchActions.success(successPayload),
              fuzzy === 1
                ? searchRecommendActions.request({ sortBy, queryText })
                : searchActions.request({
                    ...action.payload,
                    fuzzy: 1,
                    isFuzzyDiscoverySearch: true,
                  })
            );
          } else if (products.length === 0 && !fuzzy) {
            return searchUserSubmit({
              ...action.payload,
              page: 1,
              fuzzy: 1,
            }).then((fuzzyResponse: AxiosResponse<SearchUserSubmit>) => {
              return searchActions.success({
                ...successPayload,
                isFuzzyResult: fuzzyResponse.data.products.length > 0,
              });
            });
          } else {
            return of(searchActions.success(successPayload));
          }
        }),
        catchError((error: AxiosError) => {
          return of(searchActions.failure({ error }));
        })
      );
    })
  );
};

export default searchActionsEpic;
