import { FC, useEffect, useState } from 'react';
import { Animated, View } from 'react-native';

import IconNaviDown from '../../assets/svg/IconNaviDown';
import Touchable from '../../elements/Touchable';
import S from './style';

interface Prop {
  title?: string;
  expanded?: boolean;
}
const Expander: FC<Prop> = props => {
  const [animation] = useState(new Animated.Value(0));
  const [iconAnimate] = useState(new Animated.Value(0));
  const [expanded, setExpanded] = useState(props.expanded);
  const [maxHeight, setMaxHeight] = useState(0);
  const [minHeight, setMinHeight] = useState(0);
  const [measuring, setMeasuring] = useState(true);
  const _setMinHeight = e => {
    if (minHeight === 0) {
      setMinHeight(e.nativeEvent.layout.height);
    }
  };
  const _SetMaxHeight = e => {
    if (maxHeight === 0) {
      setMaxHeight(e.nativeEvent.layout.height);
    }
  };

  useEffect(() => {
    if (minHeight !== 0 && maxHeight !== 0) {
      setMeasuring(false);
    }
  }, [minHeight, maxHeight]);
  useEffect(() => {
    if (measuring) return;
    animation.stopAnimation(() => {
      Animated.stagger(0, [
        Animated.spring(animation, {
          friction: 8,
          toValue: expanded ? maxHeight + minHeight : minHeight,
          useNativeDriver: false,
        }),
        Animated.timing(iconAnimate, {
          toValue: expanded ? 1 : 0,
          useNativeDriver: false,
        }),
      ]).start();
    });
  }, [expanded, measuring]);
  const spin = iconAnimate.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  });

  return (
    <Animated.View
      style={[{ overflow: 'hidden' }, !measuring && { height: animation }]}
    >
      <S.RowItem testID="minHeightItem" onLayout={_setMinHeight}>
        <Touchable
          testID="minHeightItemBtn"
          onPress={() => setExpanded(!expanded)}
        >
          <S.FlexRow>
            <S.Label flex>{props.title}</S.Label>
            <Animated.View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 5,
                transform: [{ rotate: spin }],
              }}
            >
              <IconNaviDown size={15} />
            </Animated.View>
          </S.FlexRow>
        </Touchable>
      </S.RowItem>
      <View testID="maxHeightItem" onLayout={_SetMaxHeight}>
        {props.children}
      </View>
    </Animated.View>
  );
};

Expander.defaultProps = {
  title: '',
  expanded: false,
};
export default Expander;
