import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { debounceTime, map, scan } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { Impression } from '../../types/CTR';
import { RootAction, actionsList } from '../rootAction';

const { logAnalyticsEvent, logImpressionBatch } = actionsList;
const batchInitial = [];
let shouldResetStream = false;

type OutputActionTypes = ActionType<typeof logAnalyticsEvent>;

function handleBatch(acc, value) {
  const data = value?.payload?.data || [];
  const result = shouldResetStream ? batchInitial : acc;

  shouldResetStream = false;

  return result.concat(data);
}

const impressionBatchEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> =>
  action$.pipe(
    ofType(getType(logImpressionBatch)),
    scan(handleBatch, batchInitial),
    debounceTime(1000),
    map((data: Impression[]) => {
      // 因 scan 會將先前的內容囤著，故需要個開關來控制重置這個池子
      shouldResetStream = true;

      return logAnalyticsEvent({
        logEvent: AnalyticsEvent.Impression,
        data,
      });
    })
  );

export default impressionBatchEpic;
