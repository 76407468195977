import omit from 'lodash/omit';
import { FC, useCallback, useMemo } from 'react';
import {
  Animated,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ViewStyle,
} from 'react-native';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import withAnimated from '../../decorators/withAnimated';
import { WebLinkOpenType } from '../../types/Common';
import Config from '../../utils/configs';
import { trackAllHeatMap } from '../../utils/sensorsHelpers';
import { LogDataProps } from './types';
import { TouchableProps } from './types';

interface Props extends Omit<TouchableProps, 'style'> {
  style?:
    | StyleProp<ViewStyle>
    | Animated.WithAnimatedValue<StyleProp<ViewStyle>>;
  animated?: boolean;
  logData?: LogDataProps | LogDataProps[];
  logEvent?: Function;
}

const Touchable: FC<Props> = props => {
  const {
    onPress,
    navigateTo,
    target,
    logData,
    logEvent,
    href,
    accessibilityRole,
  } = props;
  const handleOnPress = useCallback(
    e => {
      const isValidLink = () => {
        return accessibilityRole === 'link' && href && href.length > 0;
      };

      const isExternal = () => {
        if (typeof href === 'undefined') {
          return false;
        }

        const vbURLRegex = new RegExp(
          `^https://(${Config.REACT_APP_HOST}|${Config.MHOST})`
        );

        if (/^https?/.test(href) && !vbURLRegex.test(href)) {
          return true;
        }

        return false;
      };
      const isBlank = target === WebLinkOpenType.BLANK;

      if (logData && logEvent) {
        const logGeneralEvent = data => {
          logEvent({
            logEvent: AnalyticsEvent.GeneralEvent,
            data: {
              action_name: data.actionName,
              event_category: data.eventCategory,
              event_label: data.eventLabel,
            },
          });
        };

        if (Array.isArray(logData)) {
          logData.forEach(data => logGeneralEvent(data));
        } else {
          logGeneralEvent(logData);
        }
      }

      if (!isBlank && !isExternal()) {
        e.preventDefault();
      }

      if (!isBlank && navigateTo) {
        navigateTo(e);
      }
      if (onPress) {
        onPress(e);
      }

      /** The onPress callback on Touchables is now backed by native click events and will be called
       * in almost every situation a native click event is called.
       * The onPress prop of Touchable* components now receives a SyntheticMouseEvent not a SyntheticResponderEvent.
       * It may be fired without a preceding onPressIn.
       * ref: https://github.com/necolas/react-native-web/releases/tag/0.13.0
       */
      if (!isValidLink()) {
        // add sensors global click events.
        trackAllHeatMap(e.currentTarget);
      }
    },
    [target, logData, navigateTo, onPress, accessibilityRole, href, logEvent]
  );

  // the target prop does not work, it turns to hrefAttrs
  // -> https://github.com/necolas/react-native-web/pull/1996
  // -> https://necolas.github.io/react-native-web/docs/accessibility/
  const {
    withoutFeedback = false,
    activeOpacity = 0.7,
    children,
    disabled,
    animated,
  } = props;
  const hrefAttrs = { target };
  const rest = omit(
    {
      ...props,
      hrefAttrs,
    },
    [
      'withoutFeedback',
      'children',
      'target',
      'onPress',
      'onPressIn',
      'onPressOut',
      'navigateTo',
      'activeOpacity',
    ]
  );
  const TouchableComponent = useMemo(() => {
    const TheTouchElement = withoutFeedback
      ? TouchableWithoutFeedback
      : TouchableOpacity;

    return animated ? withAnimated(TheTouchElement) : TheTouchElement;
  }, [animated, withoutFeedback]);

  return (
    <TouchableComponent
      onPress={handleOnPress}
      // @ts-expect-error animated style & style 型別衝突，待解決
      activeOpacity={activeOpacity}
      {...(disabled ? { pointerEvents: 'none' } : {})}
      {...rest}
    >
      {children}
    </TouchableComponent>
  );
};

export default Touchable;
