export const CLOSE_APP_DOWNLOAD_MODAL = 'hasCloseAppDownloadModal';
export const CLOSE_APP_DOWNLOAD_BANNER = 'hasCloseAppDownloadBanner';
export const CLOSE_FLOAT_ICON = 'hasCloseFloatIcon';
export const CAT_TAB_DOT = 'hasReadCatTabDot';
export const COUPON_GUIDE = 'hasReadCouponGuide';
export const CREATE_CARD_GUIDE = 'hasReadCreateCardGuide';
export const NOTIFICATION_DIALOG = 'hasReadNotificationDialog';
export const NOTIFICATION_PAGE_BAR = 'hasReadNotificationPageBar';
export const CUSTOMER_SERVICES_HINT = 'customerServicesHint';
export const FILTER_CONFIG_NOTIFICATION = 'hasFilterConfigNotificationGuide';
export const GET_COUPON_GUIDE = 'hasReadGetCouponGuide';
export const CAT_TAB_HINT = 'hasReadCatTabHint';
export const NOTIFICATION_GUIDE = 'hasReadNotificationGuide';
export const SEARCH_RESULT_CATEGORY_HINT = 'hasSearchResultCategoryHint';
export const CAT_SCROLL_HINT = 'categoryScrollHint';
export const SEARCH_RESULT_LABEL_HINT = 'searchResultLabelHint';
export const SEARCH_RESULT_NOTFOUND_HINT = 'searchResultNotFoundHint';
export const HAMBURGER_HINT = 'thisIsHamburgerHintKey';
export const GENERAL_WEB_HEADER_HINT = 'thisIsNewWebHeader';
export const WEBPUSH_SUBSCRIBE_MODAL = 'webPushSubscribeModal';
export const CLOSE_WEBPUSH_SUBSCRIBE_MODAL = 'hasCloseWebPushSubscribeModal';
export const FILTER_EMPTY_RESULT_HINT = 'hasFilterEmptyResultHint';
export const CONFIRM_R18_HINT = 'hasConfirmR18Hint';
