import { memo } from 'react';
import styled from 'styled-components/native';

import { black, warrior } from '../../../constants/color';
import { translate } from '../../../i18n';

interface Props {
  size: number;
  isNotAllowed: boolean;
}

function InvalidHint(props: Readonly<Props>) {
  const { size, isNotAllowed } = props;

  return (
    <S.MaskView size={size}>
      <S.InvalidHint testID="InvalidHint">
        <S.InvalidText>
          {isNotAllowed ? translate('is-not-allowed') : translate('sold-out')}
        </S.InvalidText>
      </S.InvalidHint>
    </S.MaskView>
  );
}

export default memo(InvalidHint);

const S = {
  MaskView: styled.View<{ size: number }>`
    align-items: center;
    background-color: ${black}4D; /* alpha 0.3 */
    display: flex;
    height: ${props => props.size}px;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  `,
  InvalidHint: styled.View`
    align-items: center;
    background-color: ${warrior};
    display: flex;
    height: 24px;
    justify-content: center;
    width: 100%;
  `,
  InvalidText: styled.Text`
    color: white;
  `,
};
