import { createAsyncAction } from 'typesafe-actions';

import type { DefaultShippingFeesData } from '../../types/DefaultShippingFeesData';

export interface GetDefaultShippingFeesPayload {}

export interface GetDefaultShippingFeesSuccessPayload {
  data: DefaultShippingFeesData;
}

export interface GetDefaultShippingFeesFailPayload {
  error: { data: { reason: string } };
}

export const getDefaultShippingFeesActions = createAsyncAction(
  'verybuy/GET_DEFAULT_SHIPPING_FEES',
  'verybuy/GET_DEFAULT_SHIPPING_FEES_SUCCESS',
  'verybuy/GET_DEFAULT_SHIPPING_FEES_FAIL'
)<
  GetDefaultShippingFeesPayload,
  GetDefaultShippingFeesSuccessPayload,
  GetDefaultShippingFeesFailPayload
>();
