import { createSelector } from 'reselect';

import type { Detail } from '../reducers/vendorDetailReducer';
import type { RootState } from '../rootReducer';

const reducer = (state: RootState) => state.vendorDetailReducer;

export const makeDataByIdKey = <T extends keyof Detail>(id: string, key: T) => {
  return createSelector(reducer, data => {
    return data[id]?.[key];
  });
};
