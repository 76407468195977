import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { CommonErrors } from '../../constants/VBErrorCode';
import { getSupportDetailActions } from '../actions/getSupportDetailActions';
import { getSupportDetail } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getSupportDetailActions.success,
  failure: getSupportDetailActions.failure,
};

type RequestActionTypes = ActionType<typeof getSupportDetailActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getSupportDetailEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getSupportDetailActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { id, onSuccess, onFailure } = action.payload;

      return getSupportDetail(id)
        .then(response => {
          const { data } = response;

          onSuccess(data);

          return getSupportDetailActions.success({
            id,
            data,
          });
        })
        .catch((error: AxiosError) => {
          const particularError = [
            CommonErrors.ERR_NOT_FOUND,
            CommonErrors.ERR_INVALID_ARGUMENT,
          ];
          const { error_code: errorCode } = error?.response?.data || {};
          const preventCommonError = particularError.some(
            theError => errorCode === theError
          );

          onFailure(errorCode);

          return getSupportDetailActions.failure({
            id,
            error,
            customErrorHandling: preventCommonError || undefined,
          });
        });
    })
  );
};

export default getSupportDetailEpic;
