/**
 * 儲存列表商品資料的封裝
 *
 * 使用方法：以 source 為例
 *
 * ### Component:
 * 1. new 一個 ProductStorage key 與 data 的取得方式 ，並在 fetchList 的 success 接上其 processData
 *
 * ```ts
 * export default class VeryCoolComponent extends Component{
 *  sources = new ProductStorage({
 *    productKey: product => product.id,
 *    productData: product => product.source,
 *  });
 *
 *  fetchList(){
 *    this.props.fetchList({
 *      // ....
 *      success: products => {
 *        this.sources.processData(products);
 *      }
 *    })
 *  }
 *
 *  // ...
 * }
 * ```
 *
 * 2. 在 ListItem 給予 source
 *
 * ```ts
 * renderItem item => {
 *  return <ListItem
 *            // ...
 *            source={this.sources.get(item)}
 *            />
 * }
 * ```
 *
 * 3. 在 FlatListWithImpression 給予 sources
 *
 * ```ts
 * render(){
 *    return <FlatListWithImpression
 *            // ...
 *            sources={this.sources}
 *            />
 * }
 * ```
 *
 *
 * ### Epic:
 * - api then 時呼叫 payload.success，並給予商品陣列
 *
 * ```ts
 * export const veryCoolEpic = (
 * // ...
 *  switchMap((action: RequestActionTypes) => {
 *    const { success } = action.payload;
 *
 *     return fetchVeryCoolStuff()
 *       .then((response: AxiosResponse) => {
 *         const { data } = response;
 *
 *         // data.product is an array
 *         success(data.product)
 * // ...
 * ```
 */

export interface Props<T> {
  productKey: (product: T) => string;
  productData: (product: T) => string;
  initialValue?: readonly (readonly [string, string])[];
}

/**
 * @param productKey 用來在商品陣列中取得商品的 key, e.g. `product => product.id`
 * @param productData 用來在商品陣列中取得商品資料, e.g. `product => product.source`
 * @param initialValue source 初始值，主要是給單元測試使用
 */
export default class ProductStorage<T = any> extends Map<string, string> {
  _keyExtractor: (product: T) => string;
  _dataExtractor: (product: T) => string;

  constructor({ initialValue, productKey, productData }: Props<T>) {
    super(initialValue);

    this._keyExtractor = productKey;
    this._dataExtractor = productData;
  }

  /**
   * 從商品陣列中獲取 data 的處理器
   * @param products 商品陣列
   */
  processData = (products: Array<T>) => {
    products.forEach(product => {
      const data = this._dataExtractor(product);

      if (data) {
        const key = this._keyExtractor(product);

        if (!this.has(key)) {
          this.set(key, data);
        }
      }
    });
  };
}
