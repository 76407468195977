import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getAnnouncementsActions } from '../actions/getAnnouncementsActions';
import { getAnnouncements } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getAnnouncementsActions.success,
  failure: getAnnouncementsActions.failure,
};

type RequestActionType = ActionType<typeof getAnnouncementsActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getAnnouncementsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getAnnouncementsActions.request)),
    concatMap((_action: RequestActionType) => {
      return getAnnouncements()
        .then(response =>
          getAnnouncementsActions.success({
            data: response.data.announcements,
          })
        )
        .catch((error: AxiosError) =>
          getAnnouncementsActions.failure({ error })
        );
    })
  );
};

export default getAnnouncementsEpic;
