import { useCallback, useMemo } from 'react';
import { Text } from 'react-native';

import { SearchType } from '../../../../../../../types/SearchType';
import { CatSuggestion } from '../../../../../../../types/SuggestionResponse';
import { checkPageNavigationAbility } from '../../../../../../../utils/pageNavigation';
import { getTextFromFinder } from '../../KeywordItem/utils';
import S from './styles';

interface Props {
  catSuggestion: CatSuggestion;
  logAndCloseModal: (text: string, searchType: SearchType) => void;
}

export default (props: Props) => {
  const { catSuggestion, logAndCloseModal } = props;
  const { finder, url } = catSuggestion;

  const { enabled, navigate } = useMemo(
    () => checkPageNavigationAbility(url),
    [url]
  );

  const handlePress = useCallback(() => {
    navigate();

    logAndCloseModal(catSuggestion.title, SearchType.AutocompleteCategory);
  }, [logAndCloseModal, catSuggestion.title]);

  if (!enabled) {
    return null;
  }

  return (
    <S.Touchable accessibilityRole="link" href={url} onPress={handlePress}>
      <S.Image source={require('./assets/ic_all_v2.png')} />
      <Text>
        {getTextFromFinder(finder).map(({ highlight, text }, index) => {
          return (
            <S.Text highlight={highlight} key={`${text}-${index}`}>
              {text}
            </S.Text>
          );
        })}
      </Text>
    </S.Touchable>
  );
};
