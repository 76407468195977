import { PureComponent } from 'react';

import { LoginOrRegisterMethod } from '../../../constants/LoginOrRegisterMethod';
import { ThirdPartyBtn } from '../ThirdPartySection';
import faceBookLogin, {
  redirectIfFromFBAuthWhoIsConnected,
} from './facebookLogin';

interface Props {
  loginByFacebook: Function;
}

interface State {}

export default class FBLoginButton extends PureComponent<Props, State> {
  componentDidMount() {
    const loginProps = this.props;

    redirectIfFromFBAuthWhoIsConnected({
      ...loginProps,
    }).catch(([error]) => {
      console.log(error);
    });
  }

  handleFacebookLogin = () => {
    const loginProps = this.props;

    // avoid tslint error
    // https://stackoverflow.com/questions/43980188/what-could-this-be-about-tslint-error-promises-must-be-handled-appropriately
    void faceBookLogin({
      ...loginProps,
    });
  };

  render() {
    return (
      <ThirdPartyBtn
        type={LoginOrRegisterMethod.FACEBOOK}
        onPress={this.handleFacebookLogin}
      />
    );
  }
}
