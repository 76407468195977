import { Platform, StatusBar, StyleSheet } from 'react-native';

import { veryBuyPink, warrior, white } from '../constants/color';
import statusBarHeight from '../constants/statusBarHeight';
import deviceInfo from '../utils/deviceInfo';
import { isIOS } from './utils';

const androidHasNotchPaddingBottom = deviceInfo.hasNotch() ? 17 : 10;

const styles = StyleSheet.create({
  stack: {
    paddingTop:
      Platform.OS === 'android'
        ? StatusBar.currentHeight && StatusBar.currentHeight + 15
        : 0,
    paddingBottom: Platform.OS === 'android' ? androidHasNotchPaddingBottom : 0,
    // ios的StatusBar.currentHeight是undefined，android的statusBarHeight是runtime value
    // 所以結合兩種使用
    minHeight:
      Platform.OS === 'android'
        ? StatusBar.currentHeight && StatusBar.currentHeight + 47
        : statusBarHeight + 47,
  },
  navigationBar: {
    backgroundColor: veryBuyPink,
    borderBottomColor: 'transparent',
    elevation: 0,
  },
  title: {
    color: white,
  },
  tabBar: {
    backgroundColor: veryBuyPink,
  },
  tab: {
    backgroundColor: veryBuyPink,
  },
  indicator: {
    borderColor: white,
    backgroundColor: white,
  },
  devTab: {
    backgroundColor: white,
  },
  devTabBar: {
    backgroundColor: white,
  },
  devTabLabel: {
    color: warrior,
  },
  navBackWrapper: {
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  navBack: {
    marginLeft: 8,
    minWidth: 50,
    maxWidth: 50,
  },
  floatIconStack: {
    position: 'absolute',
    alignItems: 'flex-end',
    right: 15,
    /**
     * Workaround:
     * iOS 瀏海機底部會較高，造成 50px 還是會擋到 footer bar，推到 85px 才會正常
     */
    bottom: isIOS && deviceInfo.hasNotch() ? 85 : 50,
  },
});

export default styles;
