import { TouchableHighlight } from 'react-native';
import styled from 'styled-components/native';

import { buttonPressedColor, coral } from '../../constants/color';
import LoadingView from '../LoadingView';
import NoScaleText from '../NoScaleText';
import {
  ButtonProps,
  TextProps,
  buttonStyle,
  loadingViewStyle,
  textStyle,
} from './basicStyle';

const backgroundColor = 'transparent';
const textColor = coral;
const borderColor = coral;

const getButtonBorderColor = (pressed, disabled) => {
  if (disabled) {
    return `${borderColor}66`;
  }

  return pressed ? buttonPressedColor : borderColor;
};

const getTextColor = (pressed, disabled) => {
  if (disabled) {
    return `${textColor}66`;
  }

  return pressed ? buttonPressedColor : textColor;
};

const secondaryStyles = {
  Button: styled(TouchableHighlight)<ButtonProps>`
    ${buttonStyle};
    background-color: ${backgroundColor};
    border-color: ${({ pressed, disabled }) =>
      getButtonBorderColor(pressed, disabled)};
  `,
  Text: styled(NoScaleText)<TextProps>`
    ${textStyle};
    color: ${({ pressed, disabled }) => getTextColor(pressed, disabled)};
  `,
  LoadingView: styled(LoadingView).attrs({
    color: coral,
  })`
    ${loadingViewStyle};
  `,
};

export default secondaryStyles;
