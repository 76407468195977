export function fixSafariIndexedDB(): void {
  if (!window.indexedDB) return;
  const dummyDbName = 'safariIdbFix';

  try {
    window.indexedDB.open(dummyDbName);
    window.indexedDB.deleteDatabase(dummyDbName);
  } catch (error) {
    console.log(error);
  }
}

fixSafariIndexedDB();
