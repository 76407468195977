import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const checkoutReducerRaw = (state: RootState) => state?.checkoutReducer || {};

export const makeReceiptFormData = () => {
  return createSelector(checkoutReducerRaw, data => ({
    buyer_identifier: data.receiptData?.buyer_identifier,
    buyer_name: data.receiptData?.buyer_name,
  }));
};

export const makeHasReceiptFormData = () => {
  return createSelector(
    makeReceiptFormData(),
    data => !!data.buyer_identifier && !!data.buyer_name
  );
};
