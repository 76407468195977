import { createAsyncAction } from 'typesafe-actions';

export interface PhoneVerificationCodePayload {
  failure: (error: Error) => void;
}

export interface PhoneVerificationCodeSuccessPayload {
  remainingTimes: number;
}

export interface PhoneVerificationCodeFailPayload {
  error: Error;
  customErrorHandling: boolean;
}

export const putPhoneVerificationCodeActions = createAsyncAction(
  'verybuy/PUT_PHONE_VERIFICATION_CODE',
  'verybuy/PUT_PHONE_VERIFICATION_CODE_SUCCESS',
  'verybuy/PUT_PHONE_VERIFICATION_CODE_FAIL'
)<
  PhoneVerificationCodePayload,
  PhoneVerificationCodeSuccessPayload,
  PhoneVerificationCodeFailPayload
>();
