import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

const useAnimationFrame = () => {
  const ref = useRef<number | null>(null);

  const cancelIfNeeded = useCallback(() => {
    if (ref.current) {
      _utils.cancelAnimationFrame(ref.current);

      ref.current = null;
    }
  }, []);

  const requestFrame = useCallback((cb: () => void) => {
    cancelIfNeeded();

    ref.current = _utils.requestAnimationFrame(cb);
  }, []);

  useEffect(() => {
    return () => {
      cancelIfNeeded();
    };
  }, []);

  return { requestFrame, cancelRequestedFrame: cancelIfNeeded };
};

export type Methods = {
  requestFrame: (cb: () => void) => void;
  cancelRequestedFrame: () => void;
};

/**
 * utils component for class components
 *
 * ```ts
 * import { createRef } from 'react';
 * import {
 *  AnimationFrameUtils,
 *  Methods,
 * } from 'path/to/useAnimationFrame';
 *
 * class MyComponent extends Component<Props>{
 *
 *   animationFrameUtils = createRef<Methods>();
 *
 *   doSomethingInRequestAnimationFrame() => {
 *     this.animationFrameUtils.current?.requestFrame(()=>{
 *       doSomething();
 *     })
 *   }
 *
 *   render(){
 *     return <AnimationFrameUtils ref={this.animationFrameUtils} />
 *   }
 * }
 * ```
 */
export const AnimationFrameUtils = forwardRef<Methods, {}>((_, ref) => {
  const utils = useAnimationFrame();

  useImperativeHandle(ref, () => utils, []);

  return null;
});

/**
 * 為了單元測試定義的常數，不要在其他地方使用
 */
export const _utils = {
  requestAnimationFrame: requestAnimationFrame.bind(window),
  cancelAnimationFrame: cancelAnimationFrame.bind(window),
};

export default useAnimationFrame;
