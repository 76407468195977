/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { StateObservable, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap, throttleTime } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { GuestToken } from '../../types/GuestToken';
import { postGuestToken, setAxiosDefaultHeader } from '../api';
import { RootAction, actionsList } from '../rootAction';
import { RootState } from '../rootReducer';

const getGuestTokenAction = actionsList.getGuestToken;

type RequestActionTypes = ActionType<typeof getGuestTokenAction.request>;
const outputActions = {
  success: getGuestTokenAction.success,
  failure: getGuestTokenAction.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const guestTokenEpic = (
  action$: Observable<RootAction>,
  _state$: StateObservable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getGuestTokenAction.request)),
    throttleTime(200),
    switchMap((action: RequestActionTypes) => {
      return postGuestToken()
        .then((response: AxiosResponse<GuestToken>) => {
          const success = action.payload?.success || undefined;

          setAxiosDefaultHeader('api-key', response.data.guest_token);

          if (success !== undefined) {
            success(response.data);
          }

          return getGuestTokenAction.success({
            data: response.data,
          });
        })
        .catch((error: AxiosError) => {
          const failure = action.payload?.failure || undefined;

          if (failure !== undefined) {
            failure();
          }

          return getGuestTokenAction.failure({ error });
        });
    })
  );
};

export default guestTokenEpic;
