import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { LightBox } from '../../types/LightBox';
import getLightBoxActions from '../actions/getLightBoxActions';
import { getLightBox } from '../api';
import { RootAction } from '../rootAction';

const outputActions = {
  success: getLightBoxActions.success,
  failure: getLightBoxActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getLightBoxEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getLightBoxActions.request)),
    switchMap(() => {
      return getLightBox()
        .then((response: AxiosResponse<LightBox>) => {
          return getLightBoxActions.success({
            data: response.data,
          });
        })
        .catch((error: AxiosError) => getLightBoxActions.failure({ error }));
    })
  );
};

export default getLightBoxEpic;
