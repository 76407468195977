import { ActionType } from 'typesafe-actions';

import { addToCart } from './actions/addToCartActions';
import {
  analyticsLog,
  logAnalyticsEvent,
  logImpressionBatch,
} from './actions/analyticsLog';
import {
  clearApiCommonError,
  updateApiCommonError,
} from './actions/apiCommonErrorActions';
import { fetchCategories } from './actions/categoriesActions';
import { clearData } from './actions/clearDataActions';
import { fetchCookie } from './actions/cookieActions';
import {
  clearFormAction,
  updateSelectionAction,
  updateSuggestionAction,
} from './actions/deleteAccountActions';
import { deleteCartItem } from './actions/deleteCartItemActions';
import { deleteDirectPayCardActions } from './actions/deleteDirectPayCardActions';
import { deleteItemLovesActions } from './actions/deleteItemLovesActions';
import { deleteVendorCollectActions } from './actions/deleteVendorCollectActions';
import { directPayTokenAction } from './actions/directPayTokenActions';
import { getAnnouncementDetailActions } from './actions/getAnnouncementDetailActions';
import { getAnnouncementsActions } from './actions/getAnnouncementsActions';
import getBannersActions from './actions/getBannersActions';
import { getCategoryListActions } from './actions/getCategoryListActions';
import { getCatsActions } from './actions/getCatsActions';
import { getCountriesActions } from './actions/getCountriesActions';
import { getCouponListActions } from './actions/getCouponListActions';
import { getDefaultShippingFeesActions } from './actions/getDefaultShippingFeesActions';
import getDirectPayCardActions from './actions/getDirectPayCardAction';
import { getEventCouponActions } from './actions/getEventCouponActions';
import { getEventPageCoverActions } from './actions/getEventPageCoverActions';
import { getFaqActions } from './actions/getFaqActions';
import { getFilterConfigActions } from './actions/getFilterConfigActions';
import { getFreeDeliveryActions } from './actions/getFreeDeliveryActions';
import { getGuestToken } from './actions/getGuestTokenActions';
import { getItemComboListActions } from './actions/getItemComboListActions';
import { getItemDetail } from './actions/getItemDetail';
import { getLabelItemsActions } from './actions/getLabelItemsActions';
import { getLandingPageListActions } from './actions/getLandingPageListActions';
import getLightBoxActions from './actions/getLightBoxActions';
import { getLoveListActions } from './actions/getLoveListActions';
import { getLoveVendorsActions } from './actions/getLoveVendorsActions';
import { getMailboxListActions } from './actions/getMailboxListActions';
import getMarketingEventsActions from './actions/getMarketingEvents';
import {
  getMarketingLandingPageActions,
  leaveMarketingLandingPageAction,
} from './actions/getMarketingLandingPageActions';
import { getMemberInfoActions } from './actions/getMemberInfoActions';
import { getMemberProfileActions } from './actions/getMemberProfileActions';
import { getNewArrivalActions } from './actions/getNewArrivalActions';
import { getNotificationsActions } from './actions/getNotificationsActions';
import { getOnboardingActions } from './actions/getOnboardingActions';
import {
  getIndexOnSaleActions,
  getOnSaleActions,
} from './actions/getOnSaleActions';
import { getPayment } from './actions/getPaymentActions';
import { getPeriodTaskActions } from './actions/getPeriodTaskActions';
import { getPreviewTransactionsActions } from './actions/getPreviewTransactionsActions';
import { getProductReviewDetailActions } from './actions/getProductReviewDetailActions';
import { getProductReviewListDataActions } from './actions/getProductReviewListDataActions';
import { getPromotionalThemesActions } from './actions/getPromotionalThemesActions';
import { getQueryItemsActions } from './actions/getQueryItemsActions';
import { getRecommendLandingListActions } from './actions/getRecommendLandingListActions';
import { getRecommendList } from './actions/getRecommendList';
import { getRecommendProductsActions } from './actions/getRecommendProductsActions';
import { getRecommendSubjectActions } from './actions/getRecommendSubjectActions';
import { getSearchWithTokenActions } from './actions/getSearchWithTokenActions';
import { getShippingLogisticsActions } from './actions/getShippingLogisticsActions';
import { getSimilarItemsActions } from './actions/getSimilarItemsActions';
import { getSupportDetailActions } from './actions/getSupportDetailActions';
import { getSupportMessagesActions } from './actions/getSupportMessagesActions';
import { getTopKeywordsActions } from './actions/getTopKeywordsActions';
import { getTopRatedActions } from './actions/getTopRatedActions';
import { getTopSalesActions } from './actions/getTopSalesActions';
import getTransactionListActions from './actions/getTransactionList';
import { getUserSubmitDetail } from './actions/getUserSubmitDetail';
import { getUserSubmitList } from './actions/getUserSubmitList';
import { getVBConfigActions } from './actions/getVBConfigActions';
import { getVendorDetailActions } from './actions/getVendorDetailActions';
import { getWordsLabelsActions } from './actions/getWordsLabelsActions';
import { getZipCode } from './actions/getZipCodeActions';
import { initCouponRedeem } from './actions/initCouponRedeem';
import { initMemberInfo } from './actions/initMemberInfoActions';
import { getItemContentData } from './actions/itemContentActions';
import { loadCartContent } from './actions/loadCartContentActions';
import { loadDeliveryData } from './actions/loadDeliveryDataActions';
import { loginByApple } from './actions/loginByApple';
import { loginByEmail } from './actions/loginByEmail';
import { loginByFacebook } from './actions/loginByFacebook';
import { loginByLine } from './actions/loginByLine';
import logoutAction from './actions/logoutAction';
import { patchCancelTransactionActions } from './actions/patchCancelTransactionActions';
import { patchMemberActions } from './actions/patchMemberActions';
import { patchNotificationReadActions } from './actions/patchNotificationReadActions';
import { patchTransactionAction } from './actions/patchTransactionActions';
import { postApplePayPrimeActions } from './actions/postApplePayPrimeActions';
import { postCouponRedeemActions } from './actions/postCouponRedeemActions';
import { postDirectPayCardActions } from './actions/postDirectPayCardActions';
import { postForgetPasswordActions } from './actions/postForgetPasswordActions';
import { postLinePayPrimeActions } from './actions/postLinePayPrimeActions';
import { postMemberVerifySMSActions } from './actions/postMemberVerifySMSActions';
import { postPeriodTaskActions } from './actions/postPeriodTaskActions';
import { postRatingActions } from './actions/postRatingActions';
import postRegisterActions from './actions/postRegisterActions';
import { postSupportsReplyActions } from './actions/postSupportsReplyActions';
import { putDirectPayPrimaryCardAction } from './actions/putDirectPayPrimaryCardActions';
import { putItemLovesActions } from './actions/putItemLovesActions';
import { putMemberVerifyPhoneActions } from './actions/putMemberVerifyPhoneActions';
import { putPhoneVerificationCodeActions } from './actions/putPhoneVerificationCodeActions';
import { putSubtotalActions } from './actions/putSubtotalActions';
import { putVendorCollectActions } from './actions/putVendorCollectActions';
import { putVerifyCouponActions } from './actions/putVerifyCouponActions';
import resetTransaction from './actions/resetTransaction';
import { searchActions } from './actions/searchActions';
import { searchRecommendActions } from './actions/searchRecommendActions';
import {
  selectedAllCart,
  updateSelectedCart,
} from './actions/selectCartActions';
import { submitBill } from './actions/submitBillActions';
import { fetchTransactionDetail } from './actions/transactionDetailActions';
import {
  clearGuideStatus,
  setGuideStatus,
} from './actions/tutorialGuideActions';
import { updateCartItemAmount } from './actions/updateCartItemAmountActions';
import { saveCouponAction } from './actions/updateCouponActions';
import { updateCVSReceivingData } from './actions/updateCVSReceivingDataActions';
import { updateDeliveryData } from './actions/updateDeliveryDataActions';
import {
  setViewedHome,
  updateMemberNotifications,
} from './actions/updateMemberInfoActions';
import updateReceiptDataAction from './actions/updateReceiptDataAction';
import { updateRemoteValuesAction } from './actions/updateRemoteValuesAction';
import { updateTransaction } from './actions/updateTransactionActions';
import { updateUseDiscountsAction } from './actions/updateUseDiscountsActions';

// import all actions to build a action map for ts check in epics / reducers
export const actionsList = {
  getLandingPageListActions,
  getCountriesActions,
  getProductReviewListDataActions,
  getProductReviewDetailActions,
  postApplePayPrimeActions,
  postRatingActions,
  deleteVendorCollectActions,
  putVendorCollectActions,
  getVendorDetailActions,
  getLoveVendorsActions,
  getRecommendLandingListActions,
  postForgetPasswordActions,
  patchMemberActions,
  getCategoryListActions,
  getPreviewTransactionsActions,
  getSupportMessagesActions,
  getRecommendSubjectActions,
  getAnnouncementDetailActions,
  postSupportsReplyActions,
  getWordsLabelsActions,
  getAnnouncementsActions,
  getMailboxListActions,
  getFreeDeliveryActions,
  getQueryItemsActions,
  getLabelItemsActions,
  getSearchWithTokenActions,
  getItemComboListActions,
  getIndexOnSaleActions,
  getOnSaleActions,
  leaveMarketingLandingPageAction,
  getCatsActions,
  updateRemoteValuesAction,
  getNewArrivalActions,
  getRecommendProductsActions,
  getPromotionalThemesActions,
  getTopKeywordsActions,
  getSupportDetailActions,
  getMemberProfileActions,
  getVBConfigActions,
  getOnboardingActions,
  analyticsLog,
  logAnalyticsEvent,
  logImpressionBatch,
  getItemDetail,
  getUserSubmitDetail,
  getUserSubmitList,
  getRecommendList,
  addToCart,
  getItemContentData,
  loadCartContent,
  updateCartItemAmount,
  deleteCartItem,
  updateDeliveryData,
  updateCVSReceivingData,
  updateSelectedCart,
  selectedAllCart,
  loadDeliveryData,
  getZipCode,
  getNotificationsActions,
  patchNotificationReadActions,
  getPayment,
  submitBill,
  resetTransaction,
  saveCouponAction,
  updateTransaction,
  loginByApple,
  loginByEmail,
  loginByFacebook,
  loginByLine,
  postDirectPayCardActions,
  postRegisterActions,
  getGuestToken,
  logoutAction,
  initMemberInfo,
  initCouponRedeem,
  fetchCookie,
  fetchCategories,
  putVerifyCouponActions,
  searchActions,
  searchRecommendActions,
  fetchTransactionDetail,
  setGuideStatus,
  clearGuideStatus,
  putSubtotalActions,
  patchTransactionAction,
  patchCancelTransactionActions,
  getMarketingEventsActions,
  getMemberInfoActions,
  getTransactionListActions,
  postLinePayPrimeActions,
  postMemberVerifySMSActions,
  directPayTokenAction,
  getDirectPayCardActions,
  getEventPageCoverActions,
  getEventCouponActions,
  getPeriodTaskActions,
  getBannersActions,
  getLightBoxActions,
  postCouponRedeemActions,
  putDirectPayPrimaryCardAction,
  postPeriodTaskActions,
  putItemLovesActions,
  putMemberVerifyPhoneActions,
  deleteItemLovesActions,
  getShippingLogisticsActions,
  getLoveListActions,
  getTopSalesActions,
  getTopRatedActions,
  getMarketingLandingPageActions,
  updateApiCommonError,
  clearApiCommonError,
  getCouponListActions,
  clearData,
  deleteDirectPayCardActions,
  getSimilarItemsActions,
  putPhoneVerificationCodeActions,
  setViewedHome,
  updateMemberNotifications,
  updateReceiptDataAction,
  updateUseDiscountsAction,
  getFilterConfigActions,
  getFaqActions,
  getDefaultShippingFeesActions,
  updateSelectionAction,
  updateSuggestionAction,
  clearFormAction,
};

export type RootAction = ActionType<typeof actionsList>;
