import { createAsyncAction } from 'typesafe-actions';

import { CardDatum } from '../../types/DirectPayCard';

export interface GetDirectPayCardActionSuccessPayload {
  card_data: CardDatum[];
}

export interface GetDirectPayCardActionFailPayload {
  error: Error;
}

const getDirectPayCardActions = createAsyncAction(
  'verybuy/GET_DIRECT_PAY_CARD',
  'verybuy/GET_DIRECT_PAY_CARD_SUCCESS',
  'verybuy/GET_DIRECT_PAY_CARD_FAIL'
)<
  void,
  GetDirectPayCardActionSuccessPayload,
  GetDirectPayCardActionFailPayload
>();

export default getDirectPayCardActions;
