import styled from 'styled-components/native';

import { warrior } from '../../../constants/color';
import Touchable from '../../../elements/Touchable';

export default {
  LovesElementContent: styled(Touchable)`
    align-items: center;
    margin-left: 16px;
  `,
  LovesElementText: styled.Text`
    color: ${warrior};
    font-size: 11px;
    line-height: 16px;
  `,
};
