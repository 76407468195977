import { FC, memo } from 'react';
import { Platform } from 'react-native';
import { Header } from 'react-navigation-stack';

import { neutralLight } from '../../../constants/color';
import statusBarHeight from '../../../constants/statusBarHeight';
import withIsFocused from '../../../decorators/withIsFocused';
import HeaderTitleWithHelmet from '../../../elements/HeaderTitleWithHelmet';
import { DefaultCustomNavbarParams, NavOptions } from '../navFactoryTypes';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

const DefaultHeader: FC<NavOptions> = memo(props => {
  const { headerTitle } = props;

  return (
    <>
      <HeaderTitleWithHelmet headerTitle={headerTitle} hidden />
      {/* @ts-ignore */}
      <Header {...props} />
    </>
  );
});

// TODO
// - 尚未處理 title 部分
// - custom left、right，可以看是否能管理 context navbar，但需要考慮連續兩個頁面都客製化的狀況
// - 需要透過 e2e 檢查
export default function createDefaultCustomNavbar(
  {
    leftOpts = {},
    navigation,
    rightOpts = {},
    screen,
    withoutNavLeft = false,
    headerTitle,
    ...rest
  }: DefaultCustomNavbarParams = {} as DefaultCustomNavbarParams
): NavOptions {
  return {
    headerTitle,
    header: props => <DefaultHeader {...props} headerTitle={headerTitle} />,
    headerLeft: ({ scene }) => {
      return (
        <HeaderLeft
          navigation={navigation}
          screen={screen}
          withoutNavLeft={withoutNavLeft}
          isFocused={scene.isActive}
          {...leftOpts}
        />
      );
    },
    headerRight: () => {
      const DecoratedRight = withIsFocused(HeaderRight);

      return (
        <DecoratedRight
          navigation={navigation}
          screen={screen}
          {...rightOpts}
        />
      );
    },
    headerStyle: {
      ...{
        height: 47,
      },
      // 僅動態島機型 statusBarHeight > 47，與 UED 討論後針對動態導版型做特別處理，+/- 20 為調整後適中的版型
      ...(Platform.OS === 'ios'
        ? {
            paddingTop: statusBarHeight > 47 ? statusBarHeight - 20 : 0,
            height: statusBarHeight > 47 ? statusBarHeight + 20 : 47,
          }
        : {}),
      ...(Platform.OS === 'android'
        ? {
            paddingTop: statusBarHeight,
            height: statusBarHeight + 45,
          }
        : {}),
      ...(Platform.OS === 'web'
        ? {
            boxShadow: '',
            borderBottomWidth: 1,
            borderBottomColor: neutralLight,
          }
        : {}),
    },
    ...(rest || {}),
  };
}
