import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { PreviewTransactionsData } from '../../types/ShippingLogistics';

export interface PreviewTransactionsPayload {
  transactionID: string;
  loadingType: ListLoadingTypes;
}

export interface PreviewTransactionsSuccessPayload {
  data: PreviewTransactionsData;
  transactionID: string;
}

export interface PreviewTransactionsFailPayload {
  transactionID: string;
  error: Error;
}

export const getPreviewTransactionsActions = createAsyncAction(
  'verybuy/GET_PREVIEW_TRANSACTIONS',
  'verybuy/GET_PREVIEW_TRANSACTIONS_SUCCESS',
  'verybuy/GET_PREVIEW_TRANSACTIONS_FAIL'
)<
  PreviewTransactionsPayload,
  PreviewTransactionsSuccessPayload,
  PreviewTransactionsFailPayload
>();
