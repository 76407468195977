import { Image } from 'react-native';

import { coral, veryBuyPink, white } from '../../../constants/color';
import HeaderTitleWithHelmet from '../../../elements/HeaderTitleWithHelmet';
import ShareBtn, { getShareMessage } from '../../../elements/ShareBtn';
import HeaderLeft from '../defaultCustomNavbar/HeaderLeft';
import HeaderRight from '../defaultCustomNavbar/HeaderRight';
import { PropsNavigationType, ShareOpts } from '../navFactoryTypes';
import { S } from './styles';

interface AnyForObj {
  [key: string]: any;
}

interface Props {
  isFocused: boolean | undefined;
  screen: string;
  title: string;
  shareOpts: ShareOpts;
  isLogin?: boolean;
  leftOpts?: AnyForObj;
  navigation?: PropsNavigationType;
  rightOpts?: AnyForObj;
  shouldLogin?: boolean;
  enableHelmet?: boolean;
  enableShareBtn?: boolean;
  headerTitleImage?: NodeRequire;
  description?: string;
}

export default function LinearGradientBar(
  {
    isFocused,
    isLogin,
    leftOpts,
    navigation,
    rightOpts,
    screen,
    shouldLogin,
    title,
    enableHelmet,
    enableShareBtn,
    shareOpts,
    headerTitleImage,
    description,
  }: Props = {} as Props
) {
  if (shouldLogin && !isLogin) {
    return null;
  }

  const realEnableShare =
    typeof enableShareBtn === 'boolean'
      ? enableShareBtn
      : Object.keys(shareOpts).length > 0;

  const titleText = title ? (
    <S.TitleText numberOfLines={1}>{title}</S.TitleText>
  ) : null;

  const titleImage = headerTitleImage ? (
    <Image source={headerTitleImage} testID="headerTitleImage" />
  ) : null;
  const { leaderMsg, pathParams, ...restShareOpts } = shareOpts;

  return (
    <S.LinearGradient
      testID="linearGradientBar"
      colors={[veryBuyPink, coral]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
    >
      <S.Left>
        <HeaderLeft
          navigation={navigation}
          screen={screen}
          fillColor={white}
          isFocused={isFocused}
          {...(leftOpts || {})}
        />
      </S.Left>
      <S.Center>
        {enableHelmet && (
          <HeaderTitleWithHelmet
            headerTitle={title}
            description={description}
            hidden
          />
        )}
        <S.Title>{titleImage || titleText}</S.Title>
      </S.Center>
      <S.Right>
        {realEnableShare && (
          <ShareBtn
            shareMessage={getShareMessage(leaderMsg, pathParams, restShareOpts)}
            iconWrapperStyle={{ marginRight: 8 }}
          />
        )}
        <HeaderRight
          navigation={navigation}
          screen={screen}
          isFocused={isFocused}
          {...(rightOpts || {})}
        />
      </S.Right>
    </S.LinearGradient>
  );
}

LinearGradientBar.defaultProps = {
  shareOpts: {},
};
