import { FC } from 'react';
import styled from 'styled-components/native';

import { warrior } from '../../constants/color';
import AnimatedCheck from '../AnimatedCheck';

interface Props {
  text: string;
}

const WithAnimatedCheckContent: FC<Props> = props => {
  const { text } = props;

  return (
    <S.Container>
      <AnimatedCheck>
        <S.Text>{text}</S.Text>
      </AnimatedCheck>
    </S.Container>
  );
};

export default WithAnimatedCheckContent;

const S = {
  Container: styled.View`
    padding: 20px 0;
  `,
  Text: styled.Text`
    line-height: 26px;
    font-size: 15px;
    color: ${warrior};
    text-align: center;
    margin-top: 10px;
  `,
};
