import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { PromotionalTheme } from '../../types/PromotionalThemesResponse';

export interface PromotionalThemesPayload {
  loadingType: ListLoadingTypes;
}

export interface PromotionalThemesSuccessPayload {
  data: PromotionalTheme[];
}

export interface PromotionalThemesFailPayload {
  error: Error;
}

export const getPromotionalThemesActions = createAsyncAction(
  'verybuy/GET_PROMOTIONAL_THEMES',
  'verybuy/GET_PROMOTIONAL_THEMES_SUCCESS',
  'verybuy/GET_PROMOTIONAL_THEMES_FAIL'
)<
  PromotionalThemesPayload,
  PromotionalThemesSuccessPayload,
  PromotionalThemesFailPayload
>();
