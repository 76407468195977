import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as memberSelector from '../../redux/selectors/member';

interface ContainerSettings {
  shouldLogin?: boolean;
}

export default function headerContainerCreator(
  Header: ComponentType<any>,
  settings: ContainerSettings = {}
): ComponentType<any> {
  const config = {
    shouldLogin: {
      isLogin: memberSelector.makeIsLogin(),
    },
  };
  const selectors = Object.keys(settings).reduce((result, key) => {
    if (settings[key] && config[key]) {
      return {
        ...result,
        ...config[key],
      };
    }

    return result;
  }, {});

  if (!Object.keys(selectors).length) {
    return Header;
  }

  const mapStateToProps = () => {
    return createStructuredSelector(selectors);
  };

  return connect(mapStateToProps)(Header);
}
