import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { loginByApple } from '../actions/loginByApple';
import { postLoginByApple, setAxiosDefaultHeader } from '../api';
import { RootAction } from '../rootAction';

const outputActions = {
  success: loginByApple.success,
  failure: loginByApple.failure,
};

type RequestActionTypes = ActionType<typeof loginByApple.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const loginByAppleEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(loginByApple.request)),
    switchMap((action: RequestActionTypes) => {
      const { success, loginMethod, ...otherPayload } = action.payload;

      return postLoginByApple(otherPayload)
        .then(response => {
          const data = response.data;
          const { login_id: appleID } = data;

          setAxiosDefaultHeader('api-key', data.token);
          success(data);

          return loginByApple.success({
            result: response,
            account: appleID,
            logEvent: data.is_login
              ? AnalyticsEvent.Login
              : AnalyticsEvent.SignUp,
            method: loginMethod,
          });
        })
        .catch((error: AxiosError) => {
          return loginByApple.failure({ error });
        });
    })
  );
};

export default loginByAppleEpic;
