import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { Data as EventPageCoverData } from '../../types/EventPageCoverResponse';
import { getEventPageCoverActions } from '../actions/getEventPageCoverActions';
import { getEventPageCover } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getEventPageCoverActions.success,
  failure: getEventPageCoverActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getEventPageCoverEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getEventPageCoverActions.request)),
    switchMap(() => {
      return getEventPageCover()
        .then((response: AxiosResponse<EventPageCoverData>) =>
          getEventPageCoverActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) =>
          getEventPageCoverActions.failure({ error })
        );
    })
  );
};

export default getEventPageCoverEpic;
