import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { ListBase } from '../../types/ItemCommon';

export interface GetItemComboListPayload {
  id: string;
}

export interface GetItemComboListSuccessPayload {
  id: string;
  data: { products: ListBase[] };
}

export interface GetItemComboListFailPayload {
  error: Error;
}

export const getItemComboListActions = createAsyncAction(
  'verybuy/GET_ITEM_COMBO_LIST',
  'verybuy/GET_ITEM_COMBO_LIST_SUCCESS',
  'verybuy/GET_ITEM_COMBO_LIST_FAIL'
)<
  GetItemComboListPayload,
  GetItemComboListSuccessPayload,
  GetItemComboListFailPayload
>();

export interface UpdateComboListActionPayload {
  id: string;
  comboListIds: string[];
}

export const updateComboListAction = createStandardAction(
  'verybuy/UPDATE_COMBO_LIST'
)<UpdateComboListActionPayload>();
