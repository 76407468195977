import InApp from 'detect-inapp';
import { browserName, deviceType, isIOS, osVersion } from 'react-device-detect';

declare global {
  interface Window {
    opera: any;
  }
}

declare const __appVersion: any;

const browserAgent = window.navigator.userAgent;
const browserVendor = window.navigator.vendor;
const OperaPlatform = window.opera;

export const inapp = new InApp(browserAgent || browserVendor || OperaPlatform);

export default {
  hasNotch: () => false,
  getUniqueId: () => '',
  getReadableVersion: () => __appVersion,
  getVersionWithCodePush: () => Promise.resolve(__appVersion),
  getModel: () => '',
  getSystemVersion: () => osVersion,
  getDeviceType: () => deviceType,
  getBrowser: (): string => browserName,
  isInApp: (): boolean => inapp.isInApp,
  isIOS: (): boolean => isIOS,
};
