import { memo } from 'react';
import styled from 'styled-components/native';

import Image from '../../../elements/Image';

interface Props {
  index: number;
}

const images = [
  require('../assets/img_hotsale_top1.png'),
  require('../assets/img_hotsale_top2.png'),
  require('../assets/img_hotsale_top3.png'),
];

function HotSaleIcon(props: Readonly<Props>) {
  const { index } = props;
  const shouldRenderHotSaleIcon = index < 3;

  if (!shouldRenderHotSaleIcon) {
    return null;
  }

  return <S.HotSaleIcon testID="hotSaleIcon" source={images[index]} />;
}

export default memo(HotSaleIcon, () => true);

export const S = {
  HotSaleIcon: styled(Image)`
    position: absolute;
    top: 0;
    right: 6px;
    z-index: 1;
    width: 42px;
    height: 56px;
  `,
};
