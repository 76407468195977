import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getVBConfigActions } from '../actions/getVBConfigActions';
import { getVBConfig } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getVBConfigActions.success,
  failure: getVBConfigActions.failure,
};

type RequestActionTypes = ActionType<typeof getVBConfigActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getVBConfigEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getVBConfigActions.request)),
    switchMap((action: RequestActionTypes) => {
      return getVBConfig()
        .then(response =>
          getVBConfigActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) =>
          getVBConfigActions.failure({ error, requestAction: action })
        );
    })
  );
};

export default getVBConfigEpic;
