import { FormattedContent } from '../types/FormattedContent';

const formatSKUData = ({ options, info }): FormattedContent => {
  const colorContent = options.color;
  const sizeContent = options.size;
  const infoContent = {};

  if (info) {
    Object.keys(info).forEach(key => {
      const value = info[key];
      const [colorID, sizeID] = key.split(':');

      infoContent[`s${sizeID}@c${colorID}`] = value;
      infoContent[`c${colorID}@s${sizeID}`] = value;
    });
  }

  return {
    color: colorContent,
    size: sizeContent,
    info: infoContent,
  };
};

export default formatSKUData;
