import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { logAnalyticsEvent } from '../../../redux/actions/analyticsLog';
import getBannersActions from '../../../redux/actions/getBannersActions';
import {
  makeBannerIDs,
  makeBannersLoadingType,
  makeIsBannersLoading,
} from '../../../redux/selectors/bannersSelector';
import { makeIsReloadAboutWholeHome } from '../../../redux/selectors/homepageSelector';
import HomeBanner, { layoutName } from './HomeBanner';

const mapStateToProps = () => {
  const selector = createStructuredSelector({
    bannerIDs: makeBannerIDs(layoutName),
    isFetchingBanner: makeIsBannersLoading(layoutName),
    loadingType: makeBannersLoadingType(layoutName),
    isHomePageReload: makeIsReloadAboutWholeHome(),
  });

  return state => selector(state);
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getBanners: getBannersActions.request,
      logEvent: logAnalyticsEvent,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(HomeBanner);
