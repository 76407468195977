import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { deleteVendorCollectActions } from '../actions/deleteVendorCollectActions';
import { putVendorCollectActions } from '../actions/putVendorCollectActions';
import { deleteLovesSeller, putLovesSeller } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';
import { makeDataByIdKey } from '../selectors/vendorDetailSelector';

const putOutputActions = {
  success: putVendorCollectActions.success,
  failure: putVendorCollectActions.failure,
};

const deleteOutputActions = {
  success: deleteVendorCollectActions.success,
  failure: deleteVendorCollectActions.failure,
};

type RequestActionType = ActionType<
  | typeof putVendorCollectActions.request
  | typeof deleteVendorCollectActions.request
>;

type OutputActionTypes = ActionType<
  typeof putOutputActions | typeof deleteOutputActions
>;

export default (
  action$: Observable<RootAction>,
  state$: BehaviorSubject<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(putVendorCollectActions.request),
      getType(deleteVendorCollectActions.request)
    ),
    debounceTime(200),
    filter((action: RequestActionType) => {
      const { id } = action.payload;
      const isPut = action.type === getType(putVendorCollectActions.request);
      const isLoved = makeDataByIdKey(id, 'is_loved')(state$.value);

      return isPut !== isLoved;
    }),
    switchMap((action: RequestActionType) => {
      const { id, success, failure } = action.payload;

      const isPut = action.type === getType(putVendorCollectActions.request);

      const request = isPut ? putLovesSeller : deleteLovesSeller;

      return request(id)
        .then(() => {
          success();

          return isPut
            ? putVendorCollectActions.success({ id })
            : deleteVendorCollectActions.success({
                id,
                shouldRemoveFromList: action.payload.shouldRemoveFromList,
              });
        })
        .catch((error: AxiosError) => {
          const errorResp = error.response;

          failure(errorResp);

          return isPut
            ? putVendorCollectActions.failure({ id, error })
            : deleteVendorCollectActions.failure({ id, error });
        });
    })
  );
};
