enum ElementType {
  Item = 'Item',
  CartContentItem = 'CartContentItem',
  CategoryListItem = 'CategoryListItem',
  HistoryItem = 'HistoryItem',
  PromoteItem = 'PromoteItem',
  RecommendComboItem = 'RecommendComboItem',
}

export default ElementType;
