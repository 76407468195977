export type ThirdParty = Exclude<
  LoginOrRegisterMethod,
  LoginOrRegisterMethod.EMAIL
>;

export enum LoginOrRegisterMethod {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  LINE = 'line',
}
