import 'number-to-locale-string-polyfill';

export const showNumberWithComma = (
  value: number,
  showComma: boolean = true
) => {
  return showComma ? value.toLocaleString() : `${value}`;
};

export const escapeHtml = (html: string) => {
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;');
};
