import { createAsyncAction } from 'typesafe-actions';

import { Coupon, CouponForRedeem } from '../../types/Coupon';
import { SubtotalData } from '../../types/Subtotal';

export interface VerifyCouponPayload {
  couponCode: string;
  couponData?: Coupon;
  success?: Function;
  failure?: Function;
}

export interface VerifyCouponSuccessPayload {
  data: SubtotalData;
  couponData?: CouponForRedeem | Coupon;
}

export interface VerifyCouponFailPayload {
  error: Error;
}

export const putVerifyCouponActions = createAsyncAction(
  'verybuy/PUT_VERIFY_COUPON',
  'verybuy/PUT_VERIFY_COUPON_SUCCESS',
  'verybuy/PUT_VERIFY_COUPON_FAIL'
)<VerifyCouponPayload, VerifyCouponSuccessPayload, VerifyCouponFailPayload>();
