import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { deleteCartItem } from '../actions/deleteCartItemActions';
import { deleteCartVirtualProduct } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof deleteCartItem.request>;
const outputActions = {
  success: deleteCartItem.success,
  failure: deleteCartItem.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const deleteCartItemEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(deleteCartItem.request)),
    switchMap((action: RequestActionTypes) => {
      const { virtualProductIDs } = action.payload;

      return deleteCartVirtualProduct(virtualProductIDs)
        .then(() => {
          return deleteCartItem.success({ virtualProductIDs });
        })
        .catch((error: AxiosError) =>
          deleteCartItem.failure({
            error,
            requestAction: action,
          })
        );
    })
  );
};
