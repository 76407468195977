import { useMemo } from 'react';

import { translate } from '../../../../../../i18n';
import { SearchType } from '../../../../../../types/SearchType';
import { CatSuggestion } from '../../../../../../types/SuggestionResponse';
import CategoryItem from './CategoryItem';
import S from './styles';

export const MAXIMUM_COUNT = 3;

interface Props {
  logAndCloseModal: (text: string, searchType: SearchType) => void;
  categories?: CatSuggestion[];
  filters?: CatSuggestion[];
}

export default (props: Props) => {
  const { categories = [], filters = [], logAndCloseModal } = props;

  const data = useMemo(() => {
    const combinedData = [...categories, ...filters];

    return combinedData.slice(0, MAXIMUM_COUNT);
  }, [categories, filters]);

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      <S.Title>{translate('category')}</S.Title>
      {data.map(catSuggestion => (
        <CategoryItem
          key={`${catSuggestion.title}-${catSuggestion.type}`}
          catSuggestion={catSuggestion}
          logAndCloseModal={logAndCloseModal}
        />
      ))}
    </>
  );
};
