import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getItemContentData } from '../actions/itemContentActions';
import { getItemContent } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof getItemContentData.request>;
const outputActions = {
  success: getItemContentData.success,
  failure: getItemContentData.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const fetchItemContentEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getItemContentData.request)),
    switchMap((action: RequestActionTypes) => {
      const { id } = action.payload;

      return getItemContent(id)
        .then(response => {
          const { data } = response;

          return getItemContentData.success({
            id,
            data,
          });
        })
        .catch((error: AxiosError) =>
          getItemContentData.failure({ id, error })
        );
    })
  );
};
