import AsyncStorage from '@react-native-async-storage/async-storage';
import { Component } from 'react';
import { SafeAreaView, Text, View } from 'react-native';

import IconCancel from '../../assets/svg/IconCancel';
import { isWebPlatform } from '../../boot/utils';
import { veryBuyPink, warrior } from '../../constants/color';
import { LoginOrRegisterMethod } from '../../constants/LoginOrRegisterMethod';
import HeaderTitleWithHelmet from '../../elements/HeaderTitleWithHelmet';
import Image from '../../elements/Image';
import LinearGradient from '../../elements/LinearGradient';
import Touchable from '../../elements/Touchable';
import { translate } from '../../i18n';
import { NavigationParams } from '../../types/NavigationParams';
import { WebPropsInterface } from '../../types/WebPropsInterface';
import * as navigationService from '../../utils/navigationService';
import routeUtils from '../../utils/routes';
import PromoteSection from './PromoteSection';
import styles from './styles';
import ThirdPartySection from './ThirdPartySection';

interface Props {
  [NavigationParams.BackToPageKey]: string;
  [NavigationParams.BackWithKey]?: string;
  [NavigationParams.EncodedPageParams]?: string;
  closeFullScreenLoading: Function;
  isFocused: boolean;
  isLoading: boolean;
  isLogin: boolean;
  isLoginLoading: boolean;
  loadCartContent: Function;
  loginMethod: LoginOrRegisterMethod;
  loginedAndReplaceWith: Function;
  showFullScreenLoading: Function;
}

interface State {
  lastLoginMethod: LoginOrRegisterMethod | null;
  inviteCode?: string;
}

export default class WelcomeLoginPage extends Component<Props, State> {
  static defaultProps = {
    [NavigationParams.BackToPageKey]: 'Home',
  };

  constructor(props) {
    super(props);
    const { loginedAndReplaceWith, isLogin } = props;

    this.state = {
      lastLoginMethod: null,
      inviteCode: undefined,
    };

    loginedAndReplaceWith(
      isLogin,
      this.props[NavigationParams.BackToPageKey],
      this.pageParamsForBack,
      this.props[NavigationParams.BackWithKey]
    );
  }

  thirdPartyLogin = [
    LoginOrRegisterMethod.FACEBOOK,
    LoginOrRegisterMethod.APPLE,
    LoginOrRegisterMethod.LINE,
  ];

  get pageParamsForBack() {
    return routeUtils.decodePageParams(
      this.props[NavigationParams.EncodedPageParams]
    );
  }

  componentDidMount() {
    this.setState({
      inviteCode: navigationService.getActiveRoute()?.params.invite_code,
    });

    AsyncStorage.getItem('lastLoginMethod')
      .then(value => {
        this.setState({
          lastLoginMethod: value as LoginOrRegisterMethod,
        });
      })
      .catch(console.log);
  }

  componentDidUpdate(prevProps) {
    const {
      closeFullScreenLoading,
      isFocused,
      isLoading,
      isLogin,
      isLoginLoading,
      loadCartContent,
      loginMethod,
      showFullScreenLoading,
    } = this.props;
    const shouldShowFullScreenLoading =
      this.thirdPartyLogin.includes(loginMethod);

    if (prevProps.isLoading !== isLoading && shouldShowFullScreenLoading) {
      if (isLoading) {
        showFullScreenLoading();
      } else {
        closeFullScreenLoading();
      }
    }

    const isLoggedIn = !prevProps.isLogin && isLogin && isFocused;
    const isBackWithLoggedIn = !prevProps.isFocused && isFocused && isLogin;

    if (isLoggedIn || isBackWithLoggedIn) {
      const isThirdPartySuccessLogined =
        prevProps.isLoginLoading && !isLoginLoading;

      if (isThirdPartySuccessLogined) {
        loadCartContent();
      }

      if (
        this.props.loginMethod === LoginOrRegisterMethod.LINE &&
        isWebPlatform
      ) {
        navigationService.replace(
          this.props[NavigationParams.BackToPageKey],
          this.pageParamsForBack
        );
      } else {
        navigationService.goBack(
          this.props[NavigationParams.BackToPageKey],
          this.pageParamsForBack,
          this.props[NavigationParams.BackWithKey]
        );
      }
    }
  }

  createWebProps = (screenName): Partial<WebPropsInterface> => {
    const { isLogin } = this.props;

    if (isLogin) {
      return {};
    }

    const pathInfo = routeUtils.getPathAndParamsFromData(screenName, {
      [NavigationParams.BackToPageKey]:
        this.props[NavigationParams.BackToPageKey],
      [NavigationParams.EncodedPageParams]:
        this.props[NavigationParams.EncodedPageParams],
    });

    return {
      accessibilityRole: 'link',
      href: pathInfo.fullPath,
    };
  };

  handleClose = () => {
    navigationService.goBack();
  };

  handleLoginOrRegister = (shouldRenderLogin: boolean) => {
    const { inviteCode } = this.state;

    navigationService.navigate(
      shouldRenderLogin ? 'LoginPage' : 'RegisterPage',
      {
        [NavigationParams.BackToPageKey]:
          this.props[NavigationParams.BackToPageKey],
        [NavigationParams.EncodedPageParams]:
          this.props[NavigationParams.EncodedPageParams],
        [NavigationParams.BackWithKey]:
          this.props[NavigationParams.BackWithKey],
        inviteCode,
      }
    );
  };

  render() {
    const { isLogin } = this.props;
    const { lastLoginMethod, inviteCode } = this.state;

    if (isLogin) {
      return null;
    }

    return (
      <LinearGradient
        colors={[veryBuyPink, '#FC616A']}
        style={[styles.background, !isWebPlatform && { position: 'relative' }]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <HeaderTitleWithHelmet
          headerTitle={translate('welcome-to-login-or-register')}
          hidden
        />
        <View style={styles.bgImageWrapper}>
          <Image
            style={styles.bgImage}
            source={require('./assets/img_registration_bg.png')}
          />
        </View>
        <Touchable
          style={styles.close}
          onPress={this.handleClose}
          testID="loginPageCloseBtn"
          accessibilityRole="button"
        >
          <IconCancel size={24} fillColor={warrior} />
        </Touchable>
        <SafeAreaView style={styles.container}>
          <View style={styles.flex} />
          <View style={styles.logoWrapper}>
            <Image
              style={styles.logo}
              source={require('./assets/img_registration_logo.png')}
            />
          </View>
          <View style={styles.board}>
            <PromoteSection />
            {lastLoginMethod && (
              <Text testID="lastLoginMethod" style={styles.lastLoginMethod}>
                {translate(`last-login-method.${lastLoginMethod}`)}
              </Text>
            )}
            <ThirdPartySection
              {...{
                [NavigationParams.EncodedPageParams]:
                  this.props[NavigationParams.EncodedPageParams],
                [NavigationParams.BackToPageKey]:
                  this.props[NavigationParams.BackToPageKey],
                [NavigationParams.BackWithKey]:
                  this.props[NavigationParams.BackWithKey],
                inviteCode,
              }}
            />
            <View style={styles.divider} />
            <Text style={styles.introText}>
              ・{translate('welcomeLogin-login-desc')}・
            </Text>
            <View style={styles.btnGroup}>
              <Touchable
                style={[styles.actionBtn, styles.actionBtnLeft]}
                onPress={() => this.handleLoginOrRegister(true)}
                testID="loginBtn"
                {...this.createWebProps('LoginPage')}
              >
                <Text style={styles.btnText}>{translate('login')}</Text>
              </Touchable>
              <Touchable
                style={[styles.actionBtn, styles.actionBtnRight]}
                onPress={() => this.handleLoginOrRegister(false)}
                testID="registerBtn"
                {...this.createWebProps('RegisterPage')}
              >
                <Text style={styles.btnText}>{translate('register')}</Text>
              </Touchable>
            </View>
          </View>
        </SafeAreaView>
      </LinearGradient>
    );
  }
}
