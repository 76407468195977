import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { PersistConfig, persistReducer } from 'redux-persist';

import Config from '../utils/configs';
import mmkvStorage from '../utils/mmkvStorage';
import reducerRegistry from '../utils/reducerRegistry';
import { migrateRootPersist } from './migrateStorage';
import rootEpic from './rootEpic';
import { RootReducer, defaultReducers, rootReducer } from './rootReducer';
import { assignStore } from './store';

const persistConfig: PersistConfig<any> = {
  storage: mmkvStorage.asAsyncStorage,
  key: 'root', // 在 AsyncStorage 的 key 會是 `persist-${key}`
  timeout: 0,
  version: 3,
  migrate: migrateRootPersist,
  // only cache necessary data
  whitelist: ['cartContentReducer', 'sortsReducer', 'tutorialGuideReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// to enable devTools
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    encodeURIComponent: Function;
  }
}

export default function configureStore(): any {
  const epicMiddleware = createEpicMiddleware();
  const reduxCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
    shouldHotReload: false,
  });
  const enhancer =
    Config.REACT_APP_ENV_TYPE === 'Release' ? compose : reduxCompose || compose;
  const middlewares = [epicMiddleware];

  if (__DEV__) {
    const createDebugger = require('redux-flipper').default;

    middlewares.push(createDebugger());
  }

  // To disable debTools if not DEV mode
  const store = createStore(
    persistedReducer,
    enhancer(applyMiddleware(...middlewares))
  );

  epicMiddleware.run(rootEpic);
  reducerRegistry.setDefaultReducers(defaultReducers);
  reducerRegistry.emitChange = (reducers: RootReducer) => {
    // @ts-ignore: some reducer types are not correct, so ignore them first
    store.replaceReducer(reducerRegistry.combine(reducers));
  };

  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept(() => {
      const nextRootReducer = require('./rootReducer').rootReducer;

      store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    });
  }

  assignStore(store);

  return store;
}
