import { ofType } from 'redux-observable';
/* eslint-disable camelcase */
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { patchTransactionAction } from '../actions/patchTransactionActions';
import { patchTransaction } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof patchTransactionAction.request>;
const outputActions = {
  success: patchTransactionAction.success,
  failure: patchTransactionAction.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const patchTransactionEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(patchTransactionAction.request)),
    switchMap((action: RequestActionTypes) => {
      const { transactionID, pay_by: payBy, success, failure } = action.payload;

      return patchTransaction(transactionID, payBy)
        .then(() => {
          if (success) {
            success();
          }

          return patchTransactionAction.success({
            transactionID: action.payload.transactionID,
          });
        })
        .catch(error => {
          if (failure) {
            failure(error.response);
          }

          return patchTransactionAction.failure({
            transactionID: action.payload.transactionID,
          });
        });
    })
  );
};

export default patchTransactionEpic;
