import { PureComponent, createRef } from 'react';
import { ListRenderItemInfo } from 'react-native';

import { OrientationContext } from '../../../boot/OrientationContext';
import { GET_LAYOUT_MAX_WIDTH } from '../../../constants/layout';
import { checkItemViewed } from '../../../context/devLogFirehose';
import { GET_BANNER_WIDTH } from '../../../elements/Banner/styles';
import BannerCarousel, {
  ExportProperties,
} from '../../../elements/BannerCarousel';
import getBannersActions from '../../../redux/actions/getBannersActions';
import { ListLoadingTypes } from '../../../types/Common';
import BannerSlide, { GET_HOME_BANNER_HEIGHT } from './BannerSlide';
import S from './styles';

export const layoutName = 'index_banner';
export const bannerPositions = ['index_all'];

interface Props {
  getBanners: typeof getBannersActions.request;
  bannerIDs: string[];
  isFocused: boolean;
  isFetchingBanner: boolean;
  isHomePageReload: boolean;
  logEvent: Function;
  loadingType: ListLoadingTypes;
}

interface State {
  isVisible: boolean;
}

export default class HomeBanner extends PureComponent<Props, State> {
  static contextType = OrientationContext;

  state = {
    isVisible: false,
  };

  componentDidUpdate(prevProps: Props) {
    const { isHomePageReload } = this.props;

    if (!prevProps.isHomePageReload && isHomePageReload) {
      this.fetchData('reload');
    }
  }

  fetchData = (loadingType: ListLoadingTypes) => {
    const { getBanners, isFetchingBanner } = this.props;

    if (!isFetchingBanner) {
      getBanners({ positions: bannerPositions, layoutName, loadingType });
    }
  };

  private carouselRef = createRef<ExportProperties>();

  renderSlide = ({ item: bannerID, index }: ListRenderItemInfo<string>) => {
    return (
      <BannerSlide
        key={bannerID}
        bannerID={bannerID}
        index={index}
        layoutName={layoutName}
        shouldDisplayImpressionForDebug={checkItemViewed(
          this.carouselRef.current?.accumulatedItems,
          bannerID
        )}
      />
    );
  };

  public setIsVisible = (isVisible: boolean) => {
    this.setState({
      isVisible,
    });
  };

  render() {
    const { bannerIDs } = this.props;

    const bannerWidth = GET_BANNER_WIDTH();
    const layoutWidth = GET_LAYOUT_MAX_WIDTH();

    const activate = this.state.isVisible && this.props.isFocused;

    return (
      <S.Container
        key={`banner-${this.context}`}
        testID="banner"
        minHeight={GET_HOME_BANNER_HEIGHT()}
        width={bannerWidth}
        marginLeft={(layoutWidth - bannerWidth) / 2}
      >
        <BannerCarousel
          ref={this.carouselRef}
          carouselData={bannerIDs}
          renderSlide={this.renderSlide}
          play={activate}
          isVisible={activate}
          bannerWidth={bannerWidth}
          layoutName={layoutName}
          shouldLogImpression
        />
      </S.Container>
    );
  }
}
