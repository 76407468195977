import { StyleSheet } from 'react-native';

import { coral, warrior, white } from '../../constants/color';
import { GET_LAYOUT_MAX_WIDTH } from '../../constants/layout';

const MAX_DIALOG_WIDTH = 414;

export const dialogWidth =
  Math.min(GET_LAYOUT_MAX_WIDTH(), MAX_DIALOG_WIDTH) - 60;

const styles: any = StyleSheet.create({
  dialogContainer: {
    width: dialogWidth,
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    backgroundColor: white,
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: '600',
    height: 25,
    color: warrior,
    marginBottom: 10,
  },
  dialogContent: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 37,
    paddingBottom: 33,
    paddingHorizontal: 20,
  },
  dialogThumbnail: {
    width: 60,
    height: 60,
    marginBottom: 20,
    borderRadius: 3,
  },
  dialogContentText: {
    fontSize: 15,
    color: warrior,
    fontWeight: 'normal',
    textAlign: 'center',
    lineHeight: 24,
  },
  dialogNegativeButtonContainerForVertical: {
    marginBottom: 10,
  },
  dialogNegativeButtonText: {
    fontSize: 15,
    color: warrior,
    textAlign: 'center',
    fontWeight: 'normal',
  },
  dialogPositiveButtonTextForHorizontal: {
    fontSize: 15,
    color: coral,
    textAlign: 'center',
    fontWeight: 'normal',
  },
  vertical: {
    flexDirection: 'column',
    paddingHorizontal: 30,
    paddingBottom: 30,
  },
});

export default styles;
