import { AnalyticsEvent } from '../../constants/AnalyticsEvent';

export enum PushNotificationType {
  Firebase = 'Firebase',
  Notifee = 'Notifee',
  Iterable = 'Iterable',
}

export interface PushNotificationNavigationParams {
  eventName: AnalyticsEvent;
  type: PushNotificationType;
}
