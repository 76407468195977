import { getType } from 'typesafe-actions';

import { actionsList } from '../rootAction';

const {
  loadCartContent,
  deleteCartItem,
  updateCartItemAmount,
  updateSelectedCart,
  selectedAllCart,
  putSubtotalActions,
  putVerifyCouponActions,
  updateUseDiscountsAction,
} = actionsList;

export default function (
  state: { isLoading?: boolean; currentDiscount?: string; data?: object } = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case getType(loadCartContent.success):
    case getType(deleteCartItem.success):
    case getType(updateCartItemAmount.success):
    case getType(updateSelectedCart):
    case getType(selectedAllCart):
    case getType(putSubtotalActions.request):
    case getType(putVerifyCouponActions.request): {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getType(putSubtotalActions.success): {
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload.data,
      };
    }
    case getType(putSubtotalActions.failure):
    case getType(putVerifyCouponActions.failure): {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload.error.data,
      };
    }

    case getType(putVerifyCouponActions.success): {
      const { data } = action.payload;
      const errorMsg = data?.coupon?.error_message || '';
      const isVerificationError = errorMsg.length > 0;

      if (isVerificationError) {
        return {
          ...state,
          isLoading: false,
        };
      }

      return state;
    }
    case getType(updateUseDiscountsAction): {
      const { currentDiscount } = action.payload;

      return {
        ...state,
        currentDiscount,
      };
    }
    default: {
      return state;
    }
  }
}
