import { PureComponent } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Keyboard } from 'react-native';

import IconNewNavLeft from '../../assets/svg/IconNewNavLeft';
import { white } from '../../constants/color';
import * as navigationService from '../../utils/navigationService';
import Touchable from '../Touchable';

interface Props {
  style?: StyleProp<ViewStyle>;
  size?: number;
  color?: string;
  onPress?: Function;
}

export default class GoBackBtn extends PureComponent<Props> {
  onPressBack = () => {
    Keyboard.dismiss();
    navigationService.goBack();
  };

  handlePress = () => {
    const { onPress = this.onPressBack } = this.props;

    onPress();
  };

  render() {
    return (
      <Touchable
        accessibilityRole="button"
        style={this.props.style}
        hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
        onPress={this.handlePress}
      >
        <IconNewNavLeft
          size={this.props.size || 20}
          fillColor={this.props.color || white}
        />
      </Touchable>
    );
  }
}
