import { createAsyncAction } from 'typesafe-actions';

export interface RatingPayload {
  transactionID: string;
  products: { id: string; score: number }[];
  success: () => void;
  failure: () => void;
}

export interface RatingSuccessPayload {
  transactionID: string;
}

export interface RatingFailPayload {
  error: Error;
}

export const postRatingActions = createAsyncAction(
  'verybuy/POST_RATING',
  'verybuy/POST_RATING_SUCCESS',
  'verybuy/POST_RATING_FAIL'
)<RatingPayload, RatingSuccessPayload, RatingFailPayload>();
