import Config from '../../configs';

export const getEventID = eventName => {
  return eventName + Date.now() + '-' + Math.floor(Math.random() * 10000);
};

export function setCurrentScreen() {
  window.fbq(
    'trackSingleCustom',
    Config.REACT_APP_FACEBOOK_WEB_PIXEL_ID,
    'PageView'
  );
}
