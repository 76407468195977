import { Component } from 'react';
import { Animated, StyleProp, ViewStyle } from 'react-native';

import { OrientationContext } from '../../boot/OrientationContext';
import { DEVICE_TYPE } from '../../constants/layout';
import { DeviceType } from '../../types/Common';
import Image from '../Image';
import Placeholder from '../Placeholder';
import { GET_BANNER_HEIGHT, GET_BANNER_WIDTH, S } from './styles';

interface Props {
  sources: any;
  withoutLoading: boolean;
  isFetching: boolean;
  containerStyle?: any;
  bannerStyle?: StyleProp<ViewStyle>;
  id?: string;
  height?: number;
  width?: number;
}

interface State {
  isLoading: boolean;
}

export default class Banner extends Component<Props> {
  static contextType = OrientationContext;
  static defaultProps = {
    isFetching: false,
    withoutLoading: false,
  };

  loadingAnimated = new Animated.Value(1);

  state: State = { isLoading: true };

  onLoadEnd = () => {
    if (this.props.isFetching) {
      return;
    }

    Animated.timing(this.loadingAnimated, {
      toValue: 0,
      duration: 300,
      delay: 100,
      useNativeDriver: false,
    }).start(() => this.setState({ isLoading: false }));
  };

  renderImageElement = props => {
    return <Image {...props} onLoadEnd={this.onLoadEnd} />;
  };

  render() {
    const { isLoading } = this.state;
    const {
      id,
      sources,
      containerStyle,
      bannerStyle,
      withoutLoading,
      height,
      width,
    } = this.props;
    const showLoading = !withoutLoading && isLoading;
    const isDesktop = DEVICE_TYPE === DeviceType.DESKTOP;

    return (
      <Animated.View style={containerStyle}>
        <S.ResponsiveImage
          sources={sources}
          renderImageElement={this.renderImageElement}
          preferredPixelRatio={isDesktop ? 3 : undefined}
          style={bannerStyle}
        />
        {showLoading && (
          <S.PlaceHolderContainer style={{ opacity: this.loadingAnimated }}>
            <Placeholder
              key={`${id}-${width}-${height}`}
              width={width || GET_BANNER_WIDTH()}
              height={height || GET_BANNER_HEIGHT()}
              boxStyle={bannerStyle}
            />
          </S.PlaceHolderContainer>
        )}
      </Animated.View>
    );
  }
}
