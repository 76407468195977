import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';

import Image from '../Image';

export const styles = StyleSheet.create({
  placeholder: {
    marginVertical: 2.5,
  },
});

export const S = {
  Container: styled.View<{ isSearchBarVendor?: boolean }>`
    background-color: ${({ theme }) => theme.palette.white};
    padding: ${({ isSearchBarVendor }) =>
      isSearchBarVendor ? '12px 0' : '12px 16px'};
    flex-direction: row;
  `,
  LogoContainer: styled.View<{
    borderWidth: number;
    isSearchBarVendor?: boolean;
  }>`
    border: ${({ borderWidth }) => borderWidth}px solid
      ${({ theme }) => theme.palette.neutralLight};
    border-radius: ${({ isSearchBarVendor }) => (isSearchBarVendor ? 10 : 5)}px;
    width: ${({ isSearchBarVendor }) => (isSearchBarVendor ? 50 : 62)}px;
    height: ${({ isSearchBarVendor }) => (isSearchBarVendor ? 50 : 62)}px;
  `,
  Logo: styled(Image)<{ isSearchBarVendor?: boolean }>`
    width: 100%;
    height: 100%;
    border-radius: ${({ isSearchBarVendor }) => (isSearchBarVendor ? 10 : 5)}px;
  `,
  OfflineMask: styled.View`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.warrior}4D;
    border-radius: 5px;
  `,
  OfflineBanner: styled.View`
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.warrior}CC;
    padding: 3px;
  `,
  OfflineBannerText: styled.Text`
    color: ${({ theme }) => theme.palette.white};
    margin: auto;
    font-size: 13px;
    line-height: 18px;
  `,
  Body: styled.View`
    margin: 0 0 0 12px;
    flex: 1;
  `,
  Title: styled.Text<{ disabled?: boolean }>`
    color: ${({ disabled, theme }) =>
      disabled ? theme.palette.neutralGrey : theme.palette.warrior};
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 4px;
  `,
  Subtitle: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  BrandIconWrapper: styled.View`
    margin-right: 4px;
  `,
  SubtitleText: styled.Text<{ disabled?: boolean }>`
    color: ${({ disabled, theme }) =>
      disabled ? theme.palette.neutralGrey : theme.palette.neutralDark};
    font-size: 13px;
    line-height: 15px;
  `,
  NavIconWrapper: styled.View`
    flex-direction: row;
    align-items: center;
  `,
};
