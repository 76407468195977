import styled from 'styled-components/native';

import Touchable from '../../../../../../elements/Touchable';
import Vendor from '../../../../../../elements/Vendor/Vendor';
import { SellersSuggestion } from '../../../../../../types/SuggestionResponse';
import { checkPageNavigationAbility } from '../../../../../../utils/pageNavigation';

interface Props {
  sellers: SellersSuggestion[];
}

export const MAX_SUGGESTION_SELLERS = 5;

const SellerSection = ({ sellers = [] }: Props) => {
  if (sellers.length === 0) {
    return null;
  }

  return (
    <>
      {sellers.slice(0, MAX_SUGGESTION_SELLERS).map((seller, index) => {
        const handlePress = () => {
          const { navigate } = checkPageNavigationAbility(seller.url);

          navigate();
        };

        return (
          <Touchable onPress={handlePress} key={index}>
            <Vendor
              img={seller.image}
              type={seller.extra?.seller_type}
              name={seller.title}
              isSearchBarVendor
            />
            <S.Separator />
          </Touchable>
        );
      })}
    </>
  );
};

const S = {
  Separator: styled.View`
    flex: 1;
    max-height: 1px;
    min-height: 1px;
    margin-bottom: 0px;
    padding: 0px;
    border-style: solid;
    border-top-width: 1px;
    border-color: ${({ theme }) => theme.palette.neutralLight};
  `,
};

export default SellerSection;
