import { createAsyncAction } from 'typesafe-actions';

import { CountriesData } from '../../types/Countries';

export interface CountriesPayload {}

export interface CountriesSuccessPayload {
  data: CountriesData;
}

export interface CountriesFailPayload {
  error: Error;
}

export const getCountriesActions = createAsyncAction(
  'verybuy/GET_COUNTRIES',
  'verybuy/GET_COUNTRIES_SUCCESS',
  'verybuy/GET_COUNTRIES_FAIL'
)<CountriesPayload, CountriesSuccessPayload, CountriesFailPayload>();
