import sha256 from 'crypto-js/sha256';

import { AnalyticsEvent } from '../../../constants/AnalyticsEvent';
import { FBAnalyticsEvent } from '../../../constants/FBAnalyticsEvent.web';
import { FBConversionAPIEvent } from '../../../constants/FBConversionAPIEvent';
import { isBot } from '../../../constants/misc';
import { makeMemberInfo } from '../../../redux/selectors/member';
import Config from '../../../utils/configs';
import { transformToStandardEvent } from '../transformToStandardEvent';
import { getPixelEventPayload } from './getPixelEventPayload.web';
import { sendConversionApiEvents } from './sendConversionApiEvents.web';
import { getEventID } from './utils.web';

export function sendPixelEvents({ logEvent, logData, currentStore }) {
  if (isBot) return;

  const standardEventList = transformToStandardEvent(FBAnalyticsEvent);

  // filter event which not in the white list
  if (Object.values(FBAnalyticsEvent).indexOf(standardEventList[logEvent]) < 0)
    return;

  // filter empty view screenView event
  if (logEvent === AnalyticsEvent.ScreenView) return;

  const logPayload = getPixelEventPayload(logEvent, logData, currentStore);
  const {
    MID,
    email: memberEmail,
    account: memberAccount,
    fb_login_id: FbLoginID,
  } = makeMemberInfo()(currentStore);
  const eventID = getEventID(logEvent);
  const email = memberEmail || memberAccount;

  // Advanced Matching   https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching/
  if (email || MID) {
    window.fbq('init', Config.REACT_APP_FACEBOOK_WEB_PIXEL_ID, {
      em: email && sha256(email).toString(),
      external_id: MID,
    });
  }

  // only web need to send conversionAPI
  if (
    Object.values(FBConversionAPIEvent).indexOf(standardEventList[logEvent]) >
    -1
  ) {
    sendConversionApiEvents(
      standardEventList[logEvent],
      logPayload,
      {
        // backend SDK 會自己做 hash 所以不用再做一次
        email,
        external_id: MID,
        ...(FbLoginID ? { fb_login_id: FbLoginID } : {}),
      },
      eventID
    );
  }

  // reference
  // https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  window.fbq('track', standardEventList[logEvent], logPayload, { eventID });
}
