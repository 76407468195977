import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { Data as PeriodTaskData } from '../../types/PeriodTaskResponse';
import { getPeriodTaskActions } from '../actions/getPeriodTaskActions';
import { getPeriodTask } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getPeriodTaskActions.success,
  failure: getPeriodTaskActions.failure,
};

type RequestActionTypes = ActionType<typeof getPeriodTaskActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getPeriodTaskEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getPeriodTaskActions.request)),
    switchMap((action: RequestActionTypes) => {
      return getPeriodTask()
        .then((response: AxiosResponse<PeriodTaskData>) =>
          getPeriodTaskActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) =>
          getPeriodTaskActions.failure({ error, requestAction: action })
        );
    })
  );
};

export default getPeriodTaskEpic;
