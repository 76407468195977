import hoistStatics from 'hoist-non-react-statics';
import { ComponentType, ReactElement, forwardRef } from 'react';

import { S } from './styles';

interface Props {
  shouldDisplayImpressionForDebug: boolean;
  layoutName?: string;
}

export default function <T>(WrappedComponent: ComponentType<T>) {
  type P = T & Props;

  const DisplayImpression = forwardRef<ReactElement, P>((props, ref) => {
    const { shouldDisplayImpressionForDebug, ...restProps } = props;

    if (shouldDisplayImpressionForDebug) {
      return (
        <>
          <S.ViewedWrapper pointerEvents="none">
            <S.ViewedText>
              {restProps.layoutName ? restProps.layoutName : '👀'}
            </S.ViewedText>
          </S.ViewedWrapper>
          <WrappedComponent {...(restProps as T)} ref={ref} />
        </>
      );
    }

    return <WrappedComponent {...(restProps as T)} ref={ref} />;
  });

  return hoistStatics(DisplayImpression, WrappedComponent);
}
