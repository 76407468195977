import { Animated } from 'react-native';
import styled from 'styled-components/native';

import { PureImage } from '../../elements/Image';
import Touchable from '../../elements/Touchable';

export const S = {
  Container: styled(Animated.View)`
    margin-bottom: 15px;
  `,
  ButtonContainer: styled(Touchable)`
    width: 30px;
    height: 30px;
  `,
  SmartAdBtnContainer: styled(Touchable)`
    margin-top: -15px;
    margin-left: 15px;
    z-index: 0;
  `,
  Image: styled(PureImage)`
    width: 70px;
    height: 70px;
  `,
};
