import hoistStatics from 'hoist-non-react-statics';
import { Fragment, forwardRef } from 'react';

import AppHelmet from './AppHelmet';

interface Props {
  meta: { title: string; ogTitle: string; canonical: string };
}

const withHelmet = WrappedComponent => {
  const Helmet = forwardRef<any, Props>((props, ref) => {
    const helmetData = props.meta || {};

    return (
      <Fragment>
        <AppHelmet {...helmetData} />
        <WrappedComponent {...props} ref={ref} />
      </Fragment>
    );
  });

  return hoistStatics(Helmet, WrappedComponent);
};

export default withHelmet;
