import { Image } from 'react-native';

const FastImage = props => <Image {...props} />;

FastImage.preload = sources => {
  console.log('TODO: preload', sources);
};
FastImage.clearDiskCache = () => {
  console.log('TODO: clearDiskCache');

  return Promise.resolve();
};
FastImage.clearMemoryCache = () => {
  console.log('TODO: clearMemoryCache');

  return Promise.resolve();
};

export default FastImage;
