import { Dispatch } from 'react';
import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Data as LabelItemsData } from '../../types/LabelItemsResponse';
import { Product } from '../../types/QueryItemsResponse';
import { LabelType } from '../../types/WordsLabelsResponse';

export interface LabelItemsPayload {
  dispatch?: Dispatch<any>;
  loadingType: ListLoadingTypes;
  page: number;
  success: (products: Product[]) => void;
  failure: (status: number) => void;
  id: string;
  type: LabelType;
}

export interface LabelItemsSuccessPayload {
  loadingType: ListLoadingTypes;
  data: LabelItemsData;
}

export interface LabelItemsFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getLabelItemsActions.request>;
}

export const getLabelItemsActions = createAsyncAction(
  'verybuy/GET_LABEL_ITEMS',
  'verybuy/GET_LABEL_ITEMS_SUCCESS',
  'verybuy/GET_LABEL_ITEMS_FAIL'
)<LabelItemsPayload, LabelItemsSuccessPayload, LabelItemsFailPayload>();
