import { FC } from 'react';
import { Animated, ViewProps } from 'react-native';
import styled from 'styled-components/native';

interface ContainerProps extends ViewProps {
  readonly width: number;
  readonly height: number;
  readonly backgroundColor: string;
}

const S = {
  Container: styled.View<{
    width: number;
    height: number;
    backgroundColor: string;
  }>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    overflow: hidden;
  ` as FC<ContainerProps>,
  AnimatedView: styled(Animated.View)`
    position: absolute;
    top: 0;
    left: 0;
  `,
};

export default S;
