import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import {
  CatItem,
  CategoryFromBE,
  RecommendLabelFromBE,
} from '../../types/CatsResponse';
import { convertArrayToObject } from '../../utils/arrayHandler';
import { getCatsActions } from '../actions/getCatsActions';

export interface State {
  isFetching: boolean;
  data: Record<string, CatItem | undefined>;
  parentCatChildIds: Record<string, string[] | undefined>;
}

export const defaultState: State = {
  isFetching: false,
  data: {},
  parentCatChildIds: {},
};

/**
 * 後端改到這隻 api 之前我們先自己轉成較合適的格式，同時也試用看看有沒有問題
 */
export const convertToNewFormat = (
  data: Record<string, CategoryFromBE | RecommendLabelFromBE>
) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    // @ts-expect-error 忽略格式不符
    const { parent_id, name, title, icon_url, image_url, filter } = value;

    acc[key] = {
      type: !!title ? 'recommend_label' : 'category',
      id: key,
      name: name || title,
      image_url,
      icon_url,
      parent_id: typeof parent_id !== 'undefined' ? `${parent_id}` : undefined,
      filter,
    };

    return acc;
  }, {} as Record<string, CatItem>);
};

export default produce(
  (draft: State, action: ActionType<typeof getCatsActions>) => {
    switch (action.type) {
      case getType(getCatsActions.request): {
        draft.isFetching = true;
        break;
      }
      case getType(getCatsActions.success): {
        const { categories, recommend_labels } = action.payload.data;

        draft.isFetching = false;
        draft.parentCatChildIds = {};

        // = deprecated workaround start =

        const allLabels = Object.values(recommend_labels).reduce(
          (acc, value) => acc.concat(value),
          []
        );

        const catData = convertArrayToObject(categories, 'id', {});
        const labelData = convertArrayToObject(allLabels, 'label_key', {});

        // parentCatChildIds 是給分類頁的下拉子分類選單用的，可能存在順序問題
        categories.forEach(({ parent_id, id }) => {
          if (!parent_id) {
            return;
          }

          const idStr = `${id}`;
          const parentIdStr = `${parent_id}`;

          if (typeof draft.parentCatChildIds[parentIdStr] === 'undefined') {
            draft.parentCatChildIds[parentIdStr] = [];
          }

          draft.parentCatChildIds[parentIdStr]!.push(idStr);
        });

        const data = {
          ...catData,
          ...labelData,
        };

        // = deprecated workaround end =

        draft.data = convertToNewFormat(data);

        break;
      }
      case getType(getCatsActions.failure): {
        draft.isFetching = false;
        break;
      }
      default: {
        break;
      }
    }
  },
  defaultState
);
