interface Params {
  [key: string]: string | number;
}

export const sendEvent = (
  eventName: string,
  ...args: Array<number | Params>
) => {
  // @ts-ignore
  if (!eventName || !args || !dataLayer) return;
  // @ts-ignore
  dataLayer.push({ event: eventName, gtmDatas: args });
};
