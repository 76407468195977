import { DependencyList, useMemo } from 'react';
import { bindActionCreators } from 'redux';

import { useDispatch } from '../redux/utils';

// ref: https://react-redux.js.org/api/hooks#recipe-useactions
function useActions<T>(actions: T, deps?: DependencyList): T;
function useActions(actions, deps?) {
  const dispatch = useDispatch();

  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(action => bindActionCreators(action, dispatch));
      }

      return bindActionCreators(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch]
  );
}

export default useActions;
