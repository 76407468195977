import { AnalyticsEvent } from '../../../constants/AnalyticsEvent';

export const getLineTagEvenData = (
  eventName: AnalyticsEvent,
  params: Record<string, string | number> = {}
) => {
  switch (eventName) {
    case AnalyticsEvent.AddToCart:
      return {
        type: 'AddToCart',
      };
    case AnalyticsEvent.ECommercePurchaseFE:
      return {
        type: 'Purchase',
        value: params.totalPriceTW,
        currency: 'TWD',
      };
    case AnalyticsEvent.ViewItem:
      return {
        type: 'ViewItemDetail',
      };
    default:
      break;
  }

  return null;
};
