import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Animated } from 'react-native';

import IconCloseFloatBtn from '../../assets/svg/IconCloseFloatBtn';
import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { CLOSE_FLOAT_ICON } from '../../constants/guideKeys';
import { SetGuideStatusPayload } from '../../redux/actions/tutorialGuideActions';
import keyBehaviorSubject, { KeyAction } from '../../utils/keyBehaviorSubject';
import * as navigationService from '../../utils/navigationService';
import { DOWNLOAD_APP_URLS, WHITE_LIST } from './constants';
import { S } from './styles';
import { getFloatIconInfo, useGetDownloadLinks } from './utils';

interface Props {
  logEvent: Function;
  setGuideStatus: (args: SetGuideStatusPayload) => void;
  hasClosedFloatIcon: boolean;
}

export const keyActionTriggers: KeyAction[] = [
  'hamburgerMenuOpen',
  'hamburgerMenuClose',
  'filterBackdropOpen',
  'filterBackdropClose',
];

const FloatActionButton: FC<Props> = ({
  logEvent,
  setGuideStatus,
  hasClosedFloatIcon,
}) => {
  const [isShowBtn, setIsShowBtn] = useState(false);
  const opacity = useRef(new Animated.Value(0)).current;
  const [activeRoute, setActiveRoute] = useState(
    navigationService.getActiveRoute() || {}
  );
  const { routeName, params } = activeRoute;
  const defaultUrl = DOWNLOAD_APP_URLS[routeName] || DOWNLOAD_APP_URLS.ItemPage;
  const payload = useMemo(() => {
    return {
      fallbackLink: defaultUrl,
      params,
      routeName,
    };
  }, [defaultUrl, params, routeName]);

  const downloadUrl = useGetDownloadLinks(payload);

  const iconInfo = getFloatIconInfo();

  const displayButton = show => {
    if (show) {
      setIsShowBtn(true);
    }

    Animated.timing(opacity, {
      toValue: show ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start(e => {
      if (e.finished && !show) {
        setIsShowBtn(false);
      }
    });
  };

  const closeBtn = () => {
    displayButton(false);
    logEvent({
      logEvent: AnalyticsEvent.CloseFloatIcon,
    });
    setGuideStatus({ guideKey: CLOSE_FLOAT_ICON });
  };

  const openSmartAdBtn = useCallback(() => {
    iconInfo.navigate(downloadUrl);
    logEvent({
      logEvent: AnalyticsEvent.ClickFloatIcon,
    });
  }, [downloadUrl, iconInfo, logEvent]);

  // listen navigation state change
  const subscribeSceneChanged = currentScene => {
    if (hasClosedFloatIcon) return;

    if (WHITE_LIST.includes(currentScene)) {
      displayButton(true);
      logEvent({
        logEvent: AnalyticsEvent.ViewFloatIcon,
      });
    } else {
      displayButton(false);
    }
  };

  const handleSubject = (action: KeyAction) => {
    if (hasClosedFloatIcon) return;

    const { routeName: currentScene } =
      navigationService.getActiveRoute() || {};

    // 避免聽到已經不在畫面的 modal state change
    if (
      WHITE_LIST.includes(currentScene) &&
      keyActionTriggers.includes(action)
    ) {
      displayButton(/Close/.test(action));
    }
  };

  useEffect(() => {
    let observableSource;
    let widgetDisplaySubscription;

    if (!hasClosedFloatIcon) {
      observableSource = navigationService.screenSubject$.subscribe(
        (info: any) => {
          setActiveRoute(navigationService.getActiveRoute() || {});
          subscribeSceneChanged(info?.currentScreen?.routeName);
        }
      );
      widgetDisplaySubscription = keyBehaviorSubject.subscribe(handleSubject);
    }

    return () => {
      if (observableSource) {
        observableSource.unsubscribe();
      }

      if (widgetDisplaySubscription) {
        widgetDisplaySubscription.unsubscribe();
      }
    };
  }, [hasClosedFloatIcon]);

  if (hasClosedFloatIcon || !isShowBtn) {
    return null;
  }

  return (
    <S.Container
      style={{
        opacity,
      }}
      testID="floatActionButton"
    >
      <S.ButtonContainer
        testID="closeBtn"
        accessibilityRole="button"
        onPress={closeBtn}
      >
        <IconCloseFloatBtn />
      </S.ButtonContainer>
      <S.SmartAdBtnContainer
        testID="floatingBtn"
        accessibilityRole="button"
        onPress={openSmartAdBtn}
      >
        <S.Image source={iconInfo.source} />
      </S.SmartAdBtnContainer>
    </S.Container>
  );
};

export default FloatActionButton;
