import { NavigationStackOptions } from 'react-navigation-stack';

import defaultCustomNavbarCreator from './defaultCustomNavbar';
import defaultHeaderCreator from './defaultHeader';
import linearGradientCreator from './linearGradient';
import SearchOrLinearGradientCreator from './searchOrLinearGradient';

export enum NavbarType {
  DefaultCustom = 'DefaultCustom',
  DefaultHeader = 'DefaultHeader',
  LinearGradient = 'LinearGradient',
  SearchOrLinearGradient = 'SearchOrLinearGradient',
}

const templateCreators = {
  [NavbarType.DefaultCustom]: defaultCustomNavbarCreator,
  [NavbarType.DefaultHeader]: defaultHeaderCreator,
  [NavbarType.LinearGradient]: linearGradientCreator,
  [NavbarType.SearchOrLinearGradient]: SearchOrLinearGradientCreator,
};

export default function navbarCreator(
  navbarType: NavbarType,
  params
): NavigationStackOptions {
  return templateCreators[navbarType](params);
}
