import { createStandardAction } from 'typesafe-actions';

import { CommonErrors } from '../../constants/VBErrorCode';
import { ListLoadingTypes } from '../../types/Common';

export interface GetItemDetailPayload {
  id: string;
  success: Function;
  failure: (errorCode?: CommonErrors) => void;
  loadingType: ListLoadingTypes;
}

export const getItemDetail = createStandardAction(
  'verybuy/GET_ITEM_DETAIL'
)<GetItemDetailPayload>();
