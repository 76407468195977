import { Component } from 'react';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { CommonErrors } from '../../constants/VBErrorCode';
import Dialog from '../../elements/Dialog';
import { LogEventPayload } from '../../redux/actions/analyticsLog';
import { getUserInfo } from '../../utils/getUserInfo';
import * as navigationService from '../../utils/navigationService';

interface Props {
  errorCode: CommonErrors;
  contentText: string;
  positiveText: string;
  clearApiCommonError: Function;
  clearData: Function;
  login: Function;
  isLogin: boolean;
  logEvent: (payload: LogEventPayload) => void;
  negativeText?: string;
}

interface State {
  showDialog: boolean;
}

export default class ApiCommonError extends Component<Props, State> {
  state = {
    showDialog: false,
  };

  static getDerivedStateFromProps(nextProps) {
    const { contentText } = nextProps;
    const hasContentText = !!contentText && contentText !== '';

    return {
      showDialog: hasContentText,
    };
  }

  async componentDidUpdate(prevProps) {
    const { contentText, logEvent, errorCode } = this.props;

    if (
      prevProps.contentText !== contentText &&
      contentText !== '' &&
      errorCode === CommonErrors.ERR_SYSTEM_ERROR
    ) {
      const { version, path, userAgent } = await getUserInfo();

      logEvent({
        logEvent: AnalyticsEvent.View500KERKER,
        data: {
          version,
          path,
          userAgent,
        },
      });
    }
  }

  private clearData = () => {
    const { clearApiCommonError, clearData, errorCode } = this.props;
    const tokenInvalid = errorCode === CommonErrors.ERR_UNAUTHORIZED_USER;

    if (tokenInvalid) {
      clearData();
    }

    clearApiCommonError();
  };

  private hideDialog = (callback?: () => void) => {
    this.setState({ showDialog: false }, callback);
  };

  private handleDialogPositiveAction = () => {
    const { errorCode, login, isLogin } = this.props;
    const hideDialogCallback = () => {
      switch (errorCode) {
        case CommonErrors.ERR_SYSTEM_ERROR: {
          return navigationService.reset();
        }
        case CommonErrors.ERR_UNAUTHORIZED_USER: {
          return isLogin ? login() : navigationService.reset();
        }

        default:
          break;
      }
    };

    this.hideDialog(hideDialogCallback);
    this.clearData();
  };

  private handleDialogNegativeAction = () => {
    const { errorCode } = this.props;
    const hideDialogCallback = () => {
      switch (errorCode) {
        case CommonErrors.ERR_UNAUTHORIZED_USER: {
          return navigationService.reset();
        }
        default:
          break;
      }
    };

    this.hideDialog(hideDialogCallback);
    this.clearData();
  };

  private handleCloseDialog = () => {
    const { errorCode } = this.props;
    const tokenInvalid = errorCode === CommonErrors.ERR_UNAUTHORIZED_USER;

    // 如是 tokenInvalid 則不因點背景而關閉 dialog
    if (tokenInvalid) {
      return;
    }

    const hideDialogCallback = () => {
      switch (errorCode) {
        case CommonErrors.ERR_SYSTEM_ERROR: {
          return navigationService.reset();
        }
        default:
          break;
      }
    };

    this.hideDialog(hideDialogCallback);
    this.clearData();
  };

  render() {
    const { contentText, positiveText, negativeText } = this.props;
    const { showDialog } = this.state;

    return (
      <Dialog
        testID="apiCommonErrorDialog"
        contentText={contentText}
        positiveText={positiveText}
        negativeText={negativeText}
        handleCloseDialog={this.handleCloseDialog}
        handleDialogPositiveAction={this.handleDialogPositiveAction}
        handleDialogNegativeAction={this.handleDialogNegativeAction}
        showDialog={showDialog}
      />
    );
  }
}
