/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { isWebPlatform } from '../../boot/utils';
import { CookieData } from '../../types/Cookie';
import * as cookieHelper from '../../utils/cookieHelper';
import { ForceLogoutClassicWeb } from '../../utils/memberHelpers';
import { fetchCookie } from '../actions/cookieActions';
import { getCookie } from '../api';
import { RootAction, actionsList } from '../rootAction';

type RequestActionTypes = ActionType<typeof fetchCookie.request>;
const outputActions = {
  success: fetchCookie.success,
  failure: fetchCookie.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const { loginByApple, loginByFacebook, loginByEmail, postRegisterActions } =
  actionsList;

const cookieEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(fetchCookie.request),
      getType(loginByApple.success),
      getType(loginByFacebook.success),
      getType(loginByEmail.success),
      getType(postRegisterActions.success)
    ),
    switchMap((action: RequestActionTypes) => {
      const success = action?.payload?.success;

      return getCookie()
        .then(async (response: AxiosResponse<CookieData>) => {
          if (isWebPlatform) {
            // Note: this is workaround to fix login account is different in RNWEB/Web
            // https://verybuycc.atlassian.net/browse/RNWEB-552
            try {
              await ForceLogoutClassicWeb();
            } catch (e) {
              // @ts-ignore
              // ignore error
            }
          }

          return response;
        })
        .then(response => {
          const { data } = response;

          return Promise.all([
            data,
            cookieHelper.setCookie({ name: 'MID', value: data.MID }),
            cookieHelper.setCookie({
              name: 'login_status',
              value: data.login_status,
            }),
          ]);
        })
        .then(([cookieData]) => {
          if (typeof success === 'function') {
            success(cookieData);
          }

          return fetchCookie.success({ data: cookieData });
        })
        .catch((error: AxiosError) => fetchCookie.failure({ error }));
    })
  );
};

export default cookieEpic;
