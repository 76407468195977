import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { patchNotificationReadActions } from '../actions/patchNotificationReadActions';
import { patchNotificationRead } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<
  typeof patchNotificationReadActions.request
>;
const outputActions = {
  success: patchNotificationReadActions.success,
  failure: patchNotificationReadActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const patchNotificationReadEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(patchNotificationReadActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { id } = action.payload;

      return patchNotificationRead(id)
        .then(() =>
          patchNotificationReadActions.success({
            id,
          })
        )
        .catch((error: AxiosError) =>
          patchNotificationReadActions.failure({ id, error })
        );
    })
  );
};

export default patchNotificationReadEpic;
