/* eslint-disable camelcase */
import { createAsyncAction } from 'typesafe-actions';

import { SortBy } from '../../constants/sortData';
import { ListLoadingTypes } from '../../types/Common';
import { PayloadWithFilter } from '../../types/PayloadWithFIlter';
import {
  SearchUserSubmit,
  SearchUserSubmitItem,
} from '../../types/SearchUserSubmit';

export interface UserSubmitListPayload extends PayloadWithFilter {
  loadingType: ListLoadingTypes;
  page: number;
  sortBy: SortBy;
  success: (products: SearchUserSubmitItem[]) => void;
  isListPageDefaultSort?: boolean;
}

export interface UserSubmitListSuccessPayload {
  data: SearchUserSubmit;
  loadingType: ListLoadingTypes;
}
export interface UserSubmitListFailPayload {
  error: Error;
}

export const getUserSubmitList = createAsyncAction(
  'verybuy/GET_USER_SUBMIT_LIST',
  'verybuy/GET_USER_SUBMIT_LIST_SUCCESS',
  'verybuy/GET_USER_SUBMIT_LIST_FAIL'
)<
  UserSubmitListPayload,
  UserSubmitListSuccessPayload,
  UserSubmitListFailPayload
>();
