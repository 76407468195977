import { Component } from 'react';
import { Animated, Easing, StyleProp, ViewStyle } from 'react-native';

import { isWebPlatform } from '../../boot/utils';
import { neutralLight } from '../../constants/color';
import LinearGradient from '../LinearGradient';
import S from './styles';

interface Props {
  width: number;
  height: number;
  boxStyle?: StyleProp<ViewStyle>;
  testID?: string;
}

interface State {
  delayed: boolean;
}

export default class Placeholder extends Component<Props> {
  state: State = {
    delayed: true,
  };

  // 漸層寬為容器寬度一點五倍，讓漸層效果更柔和
  private linerGradientWidth = this.props.width * 1.5;
  private x = new Animated.Value(-this.linerGradientWidth);
  private duration = 1300;
  private backgroundColor = neutralLight;
  private lightColor = '#ECEEF1';
  private greetingA = `${this.backgroundColor}00`;
  private greetingB = `${this.lightColor}80`;
  private greetingC = `${this.lightColor}ff`;
  private timer?: NodeJS.Timer;
  private unmounted = false;

  shouldComponentUpdate(_nextProps: Props, nextState: State) {
    const { delayed } = this.state;

    return delayed !== nextState.delayed;
  }

  componentDidMount() {
    const { width } = this.props;
    const animation = Animated.loop(
      Animated.timing(this.x, {
        toValue: width,
        easing: Easing.linear,
        duration: this.duration,
        useNativeDriver: !isWebPlatform,
      })
    );

    // 為優化性能，讓部分組件延遲顯示
    this.timer = setTimeout(() => {
      if (!this.unmounted) {
        this.setState({ delayed: false }, animation.start);
      }
    }, 300);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.unmounted = true;
    }
  }

  renderLinearGradient = () => {
    const { height } = this.props;

    return (
      <LinearGradient
        // 光線漸層顏色
        colors={[
          this.greetingA,
          this.greetingB,
          this.greetingC,
          this.greetingC,
          this.greetingB,
          this.greetingA,
        ]}
        locations={[0, 0.2, 0.4, 0.6, 0.8, 1]}
        style={{ width: this.linerGradientWidth, height }}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      />
    );
  };

  render() {
    const { width, height, boxStyle } = this.props;
    const { delayed } = this.state;

    return (
      <S.Container
        backgroundColor={this.backgroundColor}
        width={width}
        height={height}
        style={boxStyle}
      >
        <S.AnimatedView style={{ transform: [{ translateX: this.x }] }}>
          {!delayed && this.renderLinearGradient()}
        </S.AnimatedView>
      </S.Container>
    );
  }
}
