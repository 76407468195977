import { createSelector } from 'reselect';

import { translate } from '../../../i18n';
import { Tag } from '../../../types/ProductTagsResponse';
import { UserSubmitItemForReducer } from '../../reducers/userSubmitDetailReducer';
import { RootState } from '../../rootReducer';
import { makeCatById } from '../catsSelector';
import * as userSubmitByIdSelector from '../productById';
import { getItemDetailJsonLd } from './itemDetailJsonLd';

export const makeItemDetailByIdType = (id: string) => {
  return userSubmitByIdSelector.makeProductByIdSelector(id);
};

export const makeItemIsLoadingByIdType = (id: string) => {
  return userSubmitByIdSelector.makeProductByIdIsFetchingSelector(id);
};

export const makeItemDetailImagesByIdType = (id: string) => {
  return userSubmitByIdSelector.makeDetailImagesByIdSelector(id);
};

export const makeItemIsProductAllowCVSByIdType = (id: string) => {
  return userSubmitByIdSelector.makeProductByIdIsProductAllowCVSSelector(id);
};

export const makeItemIsLovedByIdType = (id: string) => {
  return userSubmitByIdSelector.makeProductIsLovedByIdSelector(id);
};

export const makeItemIsFetchingLovesByIdType = (id: string) => {
  return userSubmitByIdSelector.makeProductIsFetchingLovesByIdSelector(id);
};

export const makeItemColorAmountById = (id: string) => {
  return userSubmitByIdSelector.makeProductColorAmountByIdSelector(id);
};

export const makeItemDetailMetaByIdType = (id: string, tags: Tag[] = []) => {
  return createSelector(
    userSubmitByIdSelector.makeProductByIdSelector(id),
    (state: RootState) => state,
    (data = {} as UserSubmitItemForReducer, rawState) => {
      const parentCateName = makeCatById(data.parent_category_id)(
        rawState
      )?.name;
      const ogTitle = `${translate('search-title', {
        query: data.title,
      })} | ${translate('vb-title-suffix-medium')}`;

      const ogImage = data.image_url;
      const canonical = `/user_submit/item/${id}`;

      const tagsText = tags.map(x => x.text);
      const description = translate('item-page-desc-meta', {
        title: data.title,
        categoryName: parentCateName,
      });

      const jsonld = getItemDetailJsonLd(rawState, id, tagsText);

      return {
        title: ogTitle,
        ogTitle,
        ogDescription: description,
        description,
        ogImage,
        canonical,
        jsonld,
        keyword: tagsText.slice(0, 5).join('、'),
      };
    }
  );
};
