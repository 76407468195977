import { createAsyncAction } from 'typesafe-actions';

export interface UpdateCartItemAmountPayload {
  virtualProductID: number;
  amount: number;
}

export interface UpdateCartItemAmountSuccessPayload {
  virtualProductID: number;
  amount: number;
}

export interface UpdateCartItemAmountFailPayload {
  virtualProductID: number;
  prevAmount: number;
  error: Error;
}

export const updateCartItemAmount = createAsyncAction(
  'verybuy/UPDATE_CART_ITEM_AMOUNT',
  'verybuy/UPDATE_CART_ITEM_AMOUNT_SUCCESS',
  'verybuy/UPDATE_CART_ITEM_AMOUNT_FAIL'
)<
  UpdateCartItemAmountPayload,
  UpdateCartItemAmountSuccessPayload,
  UpdateCartItemAmountFailPayload
>();
