// NOTE: Avoid circular import, because boot/utils uses it
import { Platform } from 'react-native';

const isApp = /(android)|(ios)/i.test(Platform.OS);
const isServer = typeof window === 'undefined' && !isApp;
const isWebPlatform = !isServer && Platform.OS === 'web';

export const DEFAULT_LANG = 'en';
export const allowedLangs = ['zh', 'en'];

export const maxFontSizeMultiplier: number = 1.3;
// item page 切換 header 樣式的 Y 軸判斷數值
export const itemPageScrollYBegin: number = 300;
export const itemPageScrollYEnd: number = 400;
export const REMOTE_CONFIG_CACHE_MILLIS = 60 * 60 * 1000;

// Same as cloudfront function
const botRegex =
  /bot|crawler|spider|crawling|facebook|twitter|slack|Lighthouse|X-Prerender/i;

export const isBot = isWebPlatform ? botRegex.test(navigator.userAgent) : false;

export enum ItemPageSectionTab {
  Review = 'view-rate',
  RecommendList = 'recommend',
}
