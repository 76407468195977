// Ref: https://github.com/piotrwitek/typesafe-actions#behold-the-mighty-tutorial
import { createAsyncAction } from 'typesafe-actions';

import { CommonErrors } from '../../constants/VBErrorCode';
import { ListLoadingTypes } from '../../types/Common';
import { UserSubmitItem } from '../../types/UserSubmitItem';

export interface UserSubmitDetailPayload {
  id: string;
  success: Function;
  failure: (errorCode?: CommonErrors) => void;
  loadingType: ListLoadingTypes;
}

export interface UserSubmitDetailSuccessPayload {
  id: string;
  data: UserSubmitItem;
}

export interface UserSubmitDetailFailPayload {
  id: string;
  error: Error;
  requestAction: ReturnType<typeof getUserSubmitDetail.request>;
  customErrorHandling?: boolean;
}

export const getUserSubmitDetail = createAsyncAction(
  'verybuy/GET_USERSUBMIT_DETAIL',
  'verybuy/GET_USERSUBMIT_DETAIL_SUCCESS',
  'verybuy/GET_USERSUBMIT_DETAIL_FAIL'
)<
  UserSubmitDetailPayload,
  UserSubmitDetailSuccessPayload,
  UserSubmitDetailFailPayload
>();
