import { PersistMigrate } from 'redux-persist';

import { MigrateMemberPersist } from './types';

export const migrateRootPersist: PersistMigrate = state =>
  Promise.resolve(state);

export const migrateMemberPersist: MigrateMemberPersist =
  _secretKey => async state =>
    Promise.resolve(state);
