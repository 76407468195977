import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import getBannersActions from '../actions/getBannersActions';
import { getBanners } from '../api';
import { RootAction } from '../rootAction';

const outputActions = {
  success: getBannersActions.success,
  failure: getBannersActions.failure,
};

type RequestActionTypes = ActionType<typeof getBannersActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getBannersEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getBannersActions.request)),
    mergeMap((action: RequestActionTypes) => {
      const { positions, layoutName } = action.payload;

      return getBanners(positions)
        .then(response => {
          return getBannersActions.success({
            banners: response.data.banners,
            layoutName,
          });
        })
        .catch((error: AxiosError) =>
          getBannersActions.failure({ error, layoutName })
        );
    })
  );
};

export default getBannersEpic;
