type MapToKeyVal<T extends object> = (x: T) => [string | number, T];

export function uniqueArray<T extends string[] | number[]>(targetArr: T): T;
export function uniqueArray<T extends object>(
  targetArr: T[],
  mapToKeyVal: MapToKeyVal<T>
): T[];
export function uniqueArray(targetArr, mapToKeyVal?) {
  const mapFunction = mapToKeyVal ? mapToKeyVal : x => [x, x];
  const resultMap = new Map();

  targetArr.forEach(item => {
    const data = mapFunction(item);

    if (!resultMap.has(data[0])) {
      resultMap.set(data[0], data[1]);
    }
  });
  const result = [...resultMap.values()];

  if (result.length === targetArr.length) {
    return targetArr;
  }

  return result;
}

export const arrayMove = (arr: any[], from: number, to: number) => {
  const newArr = [...arr];

  newArr.splice(to, 0, newArr.splice(from, 1)[0]);

  return newArr;
};

export const convertArrayToObject = <T extends object>(
  array: T[],
  key: string,
  initialValue: Record<string, T> = {}
) => {
  return array.reduce<Record<string, T>>((obj, item) => {
    if (!(key in item)) {
      return obj;
    }

    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};
