import { ComponentType } from 'react';

import NavLeft, { Props as NavLeftProps } from '../../NavLeft';
import { PropsNavigationType } from '../navFactoryTypes';

interface HeaderLeftProps extends Partial<NavLeftProps> {
  CustomNavLeft?: ComponentType<any>;
  isFocused: boolean | undefined;
  navigation?: PropsNavigationType;
  screen: string;
  screenKey?: string;
  withoutNavLeft?: boolean;
}

export default function HeaderLeft({
  CustomNavLeft,
  screenKey,
  withoutNavLeft = false,
  ...rest
}: HeaderLeftProps) {
  if (withoutNavLeft) {
    return null;
  }

  const defaultScreenKey = screenKey || rest.screen;
  const navigation = rest.navigation || ({} as PropsNavigationType);
  const Left = CustomNavLeft ? CustomNavLeft : NavLeft;

  return (
    <Left screenKey={navigation?.state?.key || defaultScreenKey} {...rest} />
  );
}
