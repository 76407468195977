import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { ApplePayPrimeResponse } from '../../types/ApplePayPrimeResponse';
import { postApplePayPrimeActions } from '../actions/postApplePayPrimeActions';
import { postApplePayPrime } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<typeof postApplePayPrimeActions.request>;
const outputActions = {
  success: postApplePayPrimeActions.success,
  failure: postApplePayPrimeActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const postApplePayPrimeEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(postApplePayPrimeActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { transactionID, prime } = action.payload;

      return postApplePayPrime(transactionID, prime)
        .then((response: AxiosResponse<ApplePayPrimeResponse>) => {
          const success = action.payload?.success;

          if (success !== undefined) {
            success(response.data);
          }

          return postApplePayPrimeActions.success({
            data: response.data,
            transactionID,
          });
        })
        .catch((error: AxiosError) => {
          const failure = action.payload?.failure;

          if (failure !== undefined) {
            failure({ error, transactionID });
          }

          return postApplePayPrimeActions.failure({ error, transactionID });
        });
    })
  );
};

export default postApplePayPrimeEpic;
