import { createAsyncAction } from 'typesafe-actions';

import { MemberVerifyFail } from '../../types/MemberVerify';

export interface MemberVerifySMSPayload {
  verificationCode: string;
  success: () => void;
  failure: (error?: MemberVerifyFail) => void;
}

export interface MemberVerifySMSFailPayload {
  error: Error;
  customErrorHandling: boolean;
}

export const postMemberVerifySMSActions = createAsyncAction(
  'verybuy/POST_MEMBER_VERIFY_SMS',
  'verybuy/POST_MEMBER_VERIFY_SMS_SUCCESS',
  'verybuy/POST_MEMBER_VERIFY_SMS_FAIL'
)<MemberVerifySMSPayload, void, MemberVerifySMSFailPayload>();
