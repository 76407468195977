import { createAsyncAction } from 'typesafe-actions';

export interface DeleteCartItemPayload {
  virtualProductIDs: string[];
}

export interface DeleteCartItemSuccessPayload {
  virtualProductIDs: string[];
}

export interface DeleteCartItemFailPayload {
  error: Error;
  requestAction: ReturnType<typeof deleteCartItem.request>;
}

export const deleteCartItem = createAsyncAction(
  'verybuy/DELETE_CART_ITEM',
  'verybuy/DELETE_CART_ITEM_SUCCESS',
  'verybuy/DELETE_CART_ITEM_FAIL'
)<
  DeleteCartItemPayload,
  DeleteCartItemSuccessPayload,
  DeleteCartItemFailPayload
>();
