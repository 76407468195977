import { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import type { DefaultShippingFeesData } from '../../types/DefaultShippingFeesData';
import { getDefaultShippingFeesActions } from '../actions/getDefaultShippingFeesActions';
import { getDefaultShippingFees } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getDefaultShippingFeesActions.success,
  failure: getDefaultShippingFeesActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const getDefaultShippingFeesEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getDefaultShippingFeesActions.request)),
    switchMap(() => {
      return getDefaultShippingFees()
        .then((response: AxiosResponse<DefaultShippingFeesData>) =>
          getDefaultShippingFeesActions.success({
            data: response.data,
          })
        )
        .catch(error => getDefaultShippingFeesActions.failure({ error }));
    })
  );
};
