import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { MemberErrors } from '../../constants/VBErrorCode';
import { loginByFacebook } from '../actions/loginByFacebook';
import { postLoginByFacebook, setAxiosDefaultHeader } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof loginByFacebook.request>;
const outputActions = {
  success: loginByFacebook.success,
  failure: loginByFacebook.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const loginByFacebookEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(loginByFacebook.request)),
    switchMap((action: RequestActionTypes) => {
      const { userID, success, loginMethod, ...otherPayload } = action.payload;

      return postLoginByFacebook(otherPayload)
        .then(response => {
          const { data } = response;

          setAxiosDefaultHeader('api-key', data.token);
          success(data);

          return loginByFacebook.success({
            result: response,
            account: userID,
            logEvent: data.is_login
              ? AnalyticsEvent.Login
              : AnalyticsEvent.SignUp,
            method: loginMethod,
          });
        })
        .catch(() => {
          // 不管 error_code 一率顯示登入失敗，維持與 v2 相同的邏輯
          return loginByFacebook.failure({
            error: {
              // @ts-ignore
              response: {
                data: {
                  error_code: MemberErrors.ERR_MEMBER_LOGIN_FAILED,
                },
              },
            },
          });
        });
    })
  );
};

export default loginByFacebookEpic;
