import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Product } from '../../types/TopSales';

export interface TopSalesPayload {
  page: number;
  loadingType: ListLoadingTypes;
  success: (products: Product[]) => void;
  limit?: number;
}

export interface TopSalesSuccessPayload {
  items: Product[];
  page: number;
  loadingType: ListLoadingTypes;
}

export interface TopSalesFailPayload {
  error: Error;
}

export const getTopSalesActions = createAsyncAction(
  'verybuy/GET_TOP_SALES',
  'verybuy/GET_TOP_SALES_SUCCESS',
  'verybuy/GET_TOP_SALES_FAIL'
)<TopSalesPayload, TopSalesSuccessPayload, TopSalesFailPayload>();
