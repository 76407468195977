import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { CouponStatus } from '../../constants/Coupon';
import { SubtotalData, SubtotalRequest } from '../../types/Subtotal';
import { putSubtotal } from '../api';
import { RootAction, actionsList } from '../rootAction';
import { RootState } from '../rootReducer';
import { getCheckedItems } from '../selectors/cartContentSelector';

const { putVerifyCouponActions, postCouponRedeemActions } = actionsList;
const outputActions = {
  success: putVerifyCouponActions.success,
  failure: putVerifyCouponActions.failure,
};

type RequestActionTypes = ActionType<
  typeof putVerifyCouponActions.request | typeof postCouponRedeemActions.success
>;
type OutputActionTypes = ActionType<typeof outputActions>;

const putVerifyCouponEpic = (
  action$: Observable<RootAction>,
  state$: BehaviorSubject<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(putVerifyCouponActions.request),
      getType(postCouponRedeemActions.success)
    ),
    filter((action: RequestActionTypes) => {
      if (action.type === getType(postCouponRedeemActions.success)) {
        return action.payload.shouldVerify;
      }

      return true;
    }),
    switchMap((action: RequestActionTypes) => {
      const { couponCode = '', couponData, success, failure } = action.payload;
      const virtualProducts = getCheckedItems()(state$.value);
      const subtotalRequest = {
        virtual_products: virtualProducts,
        coupon_code: couponCode,
      };

      return putSubtotal(subtotalRequest as SubtotalRequest)
        .then((response: AxiosResponse<SubtotalData>) => {
          const errorMsg = response?.data?.coupon?.error_message || '';

          if (errorMsg.length === 0) {
            if (typeof success === 'function') {
              success({ couponData });
            }
          } else if (typeof failure === 'function') {
            failure({ message: errorMsg });
            if (couponData) {
              couponData.status = CouponStatus.UNDER_THRESHOLD;
            }
          }

          return putVerifyCouponActions.success({
            data: response.data,
            couponData,
          });
        })
        .catch((error: AxiosError) => {
          if (failure) {
            failure(error.response);
          }

          return putVerifyCouponActions.failure({ error });
        });
    })
  );
};

export default putVerifyCouponEpic;
