import { memo } from 'react';
import styled from 'styled-components/native';

import { white } from '../../../constants/color';
import { ribbonBGColors } from '../../../constants/ribbon';
import { makeRibbonsByIdSelector } from '../../../redux/selectors/productById';
import { useSelector } from '../../../redux/utils';
import { Ribbon as IRibbon, RibbonType } from '../../../types/ItemCommon';

interface Props {
  id: string;
}

function Ribbon({ ribbon }: { ribbon: IRibbon }) {
  if (!ribbon.text || !ribbon.type) {
    return null;
  }

  return (
    <S.Container type={ribbon.type}>
      <S.Text>{ribbon.text}</S.Text>
    </S.Container>
  );
}

function Ribbons(props: Readonly<Props>) {
  const { id } = props;
  const ribbons = useSelector(makeRibbonsByIdSelector(id));

  if (ribbons.length === 0) {
    return null;
  }

  return (
    <>
      {ribbons.map(ribbon => (
        <Ribbon key={ribbon.type} ribbon={ribbon} />
      ))}
    </>
  );
}

export default memo(Ribbons);

const S = {
  Container: styled.View<{ type: RibbonType }>`
    background-color: ${({ type }) => ribbonBGColors[type]};
    padding: 3px 6px;
    border-bottom-right-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  `,
  Text: styled.Text`
    color: ${white};
    font-size: 13px;
    line-height: 18px;
    border: 1px solid transparent;
  `,
};
