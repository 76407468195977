import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getMailboxListActions } from '../actions/getMailboxListActions';
import { getMailboxList } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getMailboxListActions.success,
  failure: getMailboxListActions.failure,
};

type RequestActionTypes = ActionType<typeof getMailboxListActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getMailboxListEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getMailboxListActions.request)),
    concatMap((action: RequestActionTypes) => {
      const { page, loadingType, mailType, perPage } = action.payload;
      const requestArgs = {
        type: mailType,
        page,
        per_page: perPage,
      };

      return getMailboxList(requestArgs)
        .then(response =>
          getMailboxListActions.success({
            data: response.data.supports,
            page,
            loadingType,
            mailType,
          })
        )
        .catch((error: AxiosError) =>
          getMailboxListActions.failure({ error, mailType })
        );
    })
  );
};

export default getMailboxListEpic;
