import { PureComponent } from 'react';
import { Text, View } from 'react-native';
import Dialog, { ScaleAnimation } from 'react-native-popup-dialog';

import { isWebPlatform } from '../../boot/utils';
import isExists from '../../utils/isExists';
import CommonButton, { ButtonType } from '../CommonButton/CommonButton';
import { PureImage } from '../Image';
import DialogButton from './DialogButton';
import styles from './styles';
import { DialogBaseProps } from './types';

interface Props extends DialogBaseProps {
  positiveText?: string;
  handleCloseDialog: Function;
  showDialog: boolean;
  imageProps?: object;
  testID?: string;
}

export default class AlertDialog extends PureComponent<Props> {
  static defaultProps = {
    useVerticalBtn: false,
    imageProps: {},
  };

  handleAndroidBack = () => {
    const { showDialog, handleCloseDialog } = this.props;

    if (showDialog) {
      handleCloseDialog();
    }

    return true;
  };

  handleTouchOutside = () => {
    const { handleCloseDialog, handleTouchOutside } = this.props;

    if (typeof handleTouchOutside === 'function') {
      handleTouchOutside();
    }

    handleCloseDialog();
  };

  renderPositiveBtn = () => {
    const { useVerticalBtn, positiveText, handleDialogPositiveAction } =
      this.props;
    const basicProps = {
      key: 'dialogPositive',
      onPress: handleDialogPositiveAction,
      text: positiveText,
    };

    return useVerticalBtn ? (
      <CommonButton {...basicProps} buttonType={ButtonType.PRIMARY} />
    ) : (
      <DialogButton
        textStyle={styles.dialogPositiveButtonTextForHorizontal}
        {...basicProps}
      />
    );
  };

  renderNegativeBtn = () => {
    const { useVerticalBtn, negativeText, handleDialogNegativeAction } =
      this.props;
    const basicProps = {
      key: 'dialogNegative',
      onPress: handleDialogNegativeAction,
      text: negativeText,
    };

    return useVerticalBtn ? (
      <View style={styles.dialogNegativeButtonContainerForVertical}>
        <CommonButton {...basicProps} />
      </View>
    ) : (
      <DialogButton
        textStyle={styles.dialogNegativeButtonText}
        {...basicProps}
      />
    );
  };

  render() {
    const {
      titleText,
      useVerticalBtn,
      negativeText,
      contentText,
      dialogImageUrl,
      imageProps,
      showDialog,
      children,
      handleDismiss,
      testID,
    } = this.props;

    const actions = isExists(negativeText)
      ? [this.renderNegativeBtn(), this.renderPositiveBtn()]
      : [this.renderPositiveBtn()];

    return (
      <Dialog
        visible={showDialog}
        actionsBordered={false}
        onTouchOutside={this.handleTouchOutside}
        dialogStyle={styles.dialogContainer}
        onHardwareBackPress={this.handleAndroidBack}
        dialogAnimation={
          new ScaleAnimation({
            toValue: 0.6,
            useNativeDriver: !isWebPlatform,
          })
        }
        actionContainerStyle={useVerticalBtn && styles.vertical}
        actions={actions}
        onDismiss={handleDismiss}
        testID={testID}
        nativeID="AlertDialog"
      >
        {children ? (
          children
        ) : (
          <View style={styles.dialogContent}>
            {isExists(dialogImageUrl) && (
              <PureImage
                style={styles.dialogThumbnail}
                source={{ uri: dialogImageUrl }}
                {...imageProps}
              />
            )}
            {titleText && <Text style={styles.dialogTitle}>{titleText}</Text>}
            <Text style={styles.dialogContentText}>{contentText}</Text>
          </View>
        )}
      </Dialog>
    );
  }
}
