import { StyleSheet } from 'react-native';

import { maskBackgroundColor } from '../../constants/color';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: maskBackgroundColor,
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

export default styles;
