import { createAsyncAction } from 'typesafe-actions';

import { CommonErrors } from '../../constants/VBErrorCode';
import { Data as SupportDetailData } from '../../types/SupportDetailResponse';

export interface SupportDetailPayload {
  id: string;
  onSuccess: (data: SupportDetailData) => void;
  onFailure: (errorCode: CommonErrors) => void;
}

export interface SupportDetailSuccessPayload {
  id: string;
  data: SupportDetailData;
}

export interface SupportDetailFailPayload {
  id: string;
  error: Error;
  customErrorHandling?: true;
}

export const getSupportDetailActions = createAsyncAction(
  'verybuy/GET_SUPPORT_DETAIL',
  'verybuy/GET_SUPPORT_DETAIL_SUCCESS',
  'verybuy/GET_SUPPORT_DETAIL_FAIL'
)<
  SupportDetailPayload,
  SupportDetailSuccessPayload,
  SupportDetailFailPayload
>();
