import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import withAuth from '../../decorators/withAuth';
import { deleteItemLovesActions } from '../../redux/actions/deleteItemLovesActions';
import { getItemDetail } from '../../redux/actions/getItemDetail';
import { putItemLovesActions } from '../../redux/actions/putItemLovesActions';
import {
  makeItemDetailByIdType,
  makeItemIsFetchingLovesByIdType,
  makeItemIsLovedByIdType,
} from '../../redux/selectors/itemDetailSelector';
import LovesElement from './LovesElement';

const mapStateToProps = (_initialState, initialOwnProps) => {
  const { id } = initialOwnProps;
  const selector = createStructuredSelector({
    isCollected: makeItemIsLovedByIdType(id),
    isFetchingLoves: makeItemIsFetchingLovesByIdType(id),
    itemDetail: makeItemDetailByIdType(id),
  });

  return state => selector(state);
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getItemDetail,
      putItemLoves: putItemLovesActions.request,
      deleteItemLoves: deleteItemLovesActions.request,
    },
    dispatch
  );
};

const enhance = compose(withAuth);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enhance(LovesElement));
