import { FC } from 'react';
import styled from 'styled-components/native';

import { black, white } from '../../../constants/color';
import {
  LoginOrRegisterMethod,
  ThirdParty,
} from '../../../constants/LoginOrRegisterMethod';
import Image from '../../../elements/Image';
import Touchable from '../../../elements/Touchable';

interface Props {
  type: ThirdParty;
  onPress: () => void;
  isLoading?: boolean;
}

function getInfoByType(type: ThirdParty) {
  switch (type) {
    case LoginOrRegisterMethod.FACEBOOK:
      return {
        image: require('./assets/FACEBOOK_Brand_icon.png'),
      };
    case LoginOrRegisterMethod.LINE:
      return { image: require('./assets/LINE_Brand_icon.png') };
    case LoginOrRegisterMethod.APPLE:
      return { image: require('./assets/APPLE_Brand_icon.png') };
    default:
      return { image: '' };
  }
}

const ThirdPartyBtn: FC<Props> = props => {
  const { type, onPress, isLoading = false } = props;
  const { image } = getInfoByType(type);

  return (
    <Touchable
      accessibilityRole="button"
      onPress={onPress}
      disabled={isLoading}
    >
      <S.LogoWrapper>
        <S.Image source={image} />
        {isLoading && <S.ActivityIndicator color={black} />}
      </S.LogoWrapper>
    </Touchable>
  );
};

export default ThirdPartyBtn;

const S = {
  LogoWrapper: styled.View`
    border-radius: 10px;
    height: 50px;
    width: 50px;
    margin: 0 15px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  `,
  Image: styled(Image)`
    width: 100%;
    height: 100%;
  `,
  ActivityIndicator: styled.ActivityIndicator`
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${white};
    width: 100%;
    height: 100%;
  `,
};
