import dayjs from 'dayjs';
import { getType } from 'typesafe-actions';

import { actionsList } from '../rootAction';

export interface State {
  // 舊版的是 true，新版的是 number，未設定是 undefined
  [key: string]: true | number | undefined;
}

const { setGuideStatus, clearGuideStatus } = actionsList;
const initialState: State = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case getType(setGuideStatus): {
      const { guideKey } = action.payload;

      return { ...state, [guideKey]: dayjs().valueOf() };
    }
    case getType(clearGuideStatus): {
      const { guideKey } = action.payload;

      return { ...state, [guideKey]: undefined };
    }

    default: {
      return state;
    }
  }
}
