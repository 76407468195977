import { useCallback, useState } from 'react';
import { useMount } from 'react-use';

import useActions from '../../hooks/useActions';
import { getTopRatedActions } from '../../redux/actions/getTopRatedActions';
import {
  makeTopRatedDataByKey,
  makeTopRatedList,
} from '../../redux/selectors/topRatedSelector';
import { useSelector } from '../../redux/utils';
import { Product } from '../../types/TopRated';
import ProductStorage from '../../utils/ProductStorage';

export const useData = () => {
  const [sources] = useState(() => {
    return new ProductStorage<Product>({
      productKey: product => `${product.id}`,
      productData: product => product.source,
    });
  });

  const getTopRatedList = useActions(getTopRatedActions.request);

  const fetchData = useCallback(
    (args = {}) => {
      getTopRatedList({
        page: 1,
        loadingType: 'new',
        success: products => sources.processData(products),
        ...args,
      });
    },
    [getTopRatedList, sources]
  );

  useMount(() => {
    fetchData();
  });

  const isFetching = useSelector(makeTopRatedDataByKey('isFetching'));
  const loadingType = useSelector(makeTopRatedDataByKey('loadingType'));
  const hasMoreItems = useSelector(makeTopRatedDataByKey('hasMoreItems'));
  const page = useSelector(makeTopRatedDataByKey('page'));
  const data = useSelector(makeTopRatedList());

  return {
    loadingType,
    hasMoreItems,
    fetchData,
    page,
    data,
    sources,
    isFetching,
  };
};
