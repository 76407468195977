// 付款方式
export const PAY_VALUE_BY_NAME = {
  // ATM 們
  // 舊 ATM，後端已棄用
  ATM: 0,
  // 永豐 atm
  SINOPAC_VIRTUAL_ACCOUNT: 13,

  // 信用卡們
  // CREDIT_CARD 和 CARD_LINK 會慢慢消失
  CREDIT_CARD: 1,
  CARD_LINK: 7,

  APPLE_PAY: 5,

  DIRECT_PAY_PRIME: 9,
  DIRECT_PAY_TOKEN: 10,

  // 便利商店
  CONVENIENCE_STORE: 3,

  // 0 元訂單
  NO_CHARGE: 8,

  // AFTEE
  AFTEE: 11,

  // LINE Pay
  LINE_PAY: 15,

  COD: 16,
};

export const PAY_NAME_BY_VALUE = Object.keys(PAY_VALUE_BY_NAME).reduce(
  (result, name) => {
    return {
      ...result,
      [PAY_VALUE_BY_NAME[name]]: name,
    };
  },
  {}
);

export function retryMethodByCard(payName) {
  switch (payName) {
    case 'CREDIT_CARD':
    case 'CARD_LINK':
      return 'CREDIT_CARD';
    case 'APPLE_PAY':
    case 'AFTEE':
    case 'LINE_PAY':
    case 'DIRECT_PAY_PRIME':
    // 上述情形原只能使用 PRIME，因應此次 tappay 只有提供 TOKEN，因此先轉為 TOKEN
    // 並且 api 的 patchTransaction 會提供 TOKEN 的付款方式
    // return 'DIRECT_PAY_PRIME';
    case 'DIRECT_PAY_TOKEN':
      return 'DIRECT_PAY_TOKEN';
    default:
      return '';
  }
}

export function retryMethodByLinePay(payName) {
  if (payName === 'LINE_PAY') {
    return 'LINE_PAY';
  }

  return '';
}

export function retryMethodByApplePay(payName) {
  if (payName === 'APPLE_PAY') {
    return 'APPLE_PAY';
  }

  return '';
}
