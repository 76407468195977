import axios from 'axios';

import { SuggestionResponse } from '../../types/SuggestionResponse';
import Config from '../../utils/configs';

const DFAxios = axios.create();
const isProd = Config.REACT_APP_ENV_TYPE === 'Release';

export const getSuggestion = (term: string) => {
  return DFAxios.get<SuggestionResponse | undefined>(
    `https://c.verybuy.io/${isProd ? 'prod' : 'dev'}/v2/suggestion/${term}`
  );
};
