// https://github.com/uuidjs/uuid#react-native
import 'react-native-get-random-values';

import AsyncStorage from '@react-native-async-storage/async-storage';
import safeAwait from 'safe-await';
import { v4 as uuidV4 } from 'uuid';

import mmkvStorage from './mmkvStorage';

let _getGhostID: string | null = null;
let _getAltGhostID: string | null = null;

export function setGhostID(id: string) {
  _getGhostID = id;
  AsyncStorage.setItem('ghostID', id).catch(error =>
    console.log('setGhostID error', error)
  );
}

function makeGhostID() {
  const now = Date.now();
  const UUID = uuidV4();
  const ghostID = `${UUID}-${now}`;

  setGhostID(ghostID);

  return ghostID;
}

export async function getGhostID() {
  if (_getGhostID === null) {
    const [err, ghostID] = await safeAwait(AsyncStorage.getItem('ghostID'));

    _getGhostID = err || !ghostID ? makeGhostID() : ghostID;
  }

  return _getGhostID;
}

export async function removeGhostID() {
  try {
    _getGhostID = null;
    await AsyncStorage.removeItem('ghostID');
  } catch (error) {
    return console.log('removeGhostID error', error);
  }
}

export function setAltGhostID(id: string) {
  _getAltGhostID = id;
  mmkvStorage.asAsyncStorage
    .setItem('altGhostID', id)
    .catch(error => console.log('setAltGhostID error', error));
}

export async function getAltGhostID() {
  if (_getAltGhostID === null) {
    const [, altGhostID] = await safeAwait(
      mmkvStorage.asAsyncStorage.getItem('altGhostID')
    );

    if (altGhostID) {
      _getAltGhostID = altGhostID;
    }
  }

  return _getAltGhostID;
}

export async function removeAltGhostID() {
  try {
    _getAltGhostID = null;
    await mmkvStorage.asAsyncStorage.removeItem('altGhostID');
  } catch (error) {
    return console.log('removeAltGhostID error', error);
  }
}
