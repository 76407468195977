import { StyleSheet } from 'react-native';
import styled, { css } from 'styled-components/native';

import { coral, neutralLight, white } from '../../constants/color';
import Image from '../../elements/Image';

const itemImageHeight = 175;
const itemLabelHeight = 60;

export const LIST_ITEM_CONTENT_PADDING = 10;
export const LIST_ITEM_TITLE_DEFAULT_MARGIN_BOTTOM = 4;
export const ITEM_LABEL_HEIGHT = 18;
export const ITEM_LABEL_MARGIN_BOTTOM = 8;
export const DEFAULT_LINE_HEIGHT = 20;

export const heightPerItem = itemImageHeight + itemLabelHeight;

const withBorder = css`
  border-color: #e6ecf5;
  border-width: 1px;
`;

const withBorderRadius = css`
  border-radius: 3px;
  overflow: hidden;
`;

export const S = {
  ImageWrapper: styled.View<{
    size: number;
    withBorder?: boolean;
    withBorderRadius?: boolean;
  }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    background-color: ${neutralLight};
    ${props => props.withBorder && withBorder};
    ${props => props.withBorderRadius && withBorderRadius};
  `,
  Content: styled.View`
    background-color: ${white};
    padding: ${LIST_ITEM_CONTENT_PADDING}px;
    justify-content: space-between;
    flex: 1 auto;
  `,
  LoveIconWrapper: styled.View`
    width: 26px;
    height: 26px;
    margin-bottom: 4px;
    margin-right: 4px;
  `,
  LoveImage: styled(Image)`
    width: 100%;
    height: 100%;
  `,
  PriceWrapper: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  // 收藏的 container 26px
  // icon 大小 12
  // (26 - 12) / 2
  // 因愛心圖形造成眼睛上方視覺比較擠，top 微調 1
  IconWrapper: styled.View`
    left: 7px;
    position: absolute;
    top: 8px;
  `,
  SKUTagWrapper: styled.View`
    display: flex;
    flex-direction: row;
  `,
  ColorAmountTag: styled.View`
    border: ${({ theme }) => theme.palette.neutralLight}80 1px solid;
    border-radius: 10px;
    margin-bottom: 5px;
    padding: 1px 4px;
  `,
  ColorAmountTagText: styled.Text`
    font-size: 13px;
    color: ${({ theme }) => theme.palette.neutralDark};
  `,
  AvgRatingWrapper: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 8px;
  `,
};

const styles = StyleSheet.create({
  emptyLoadingSceneWrapper: {
    marginBottom: 20,
  },
  listItem: {
    flex: 1,
    marginTop: 10,
  },
  priceText: {
    fontWeight: '700',
    fontSize: 18,
    lineHeight: DEFAULT_LINE_HEIGHT,
  },
  itemLabelStyle: {
    marginBottom: 8,
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  priceCurrency: {
    fontWeight: 'normal',
    marginRight: 2,
    fontSize: 13,
    lineHeight: 15,
  },
  tildeText: {
    fontSize: 15,
    lineHeight: DEFAULT_LINE_HEIGHT,
    color: coral,
  },
  loveElement: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 10,
  },
});

export default styles;
