import { createAsyncAction } from 'typesafe-actions';

import { MemberProfileData } from '../../types/MemberProfileResponse';

export interface MemberProfileSuccessPayload {
  data: MemberProfileData;
}

export interface MemberProfileFailPayload {
  error: Error;
  customErrorHandling: boolean;
}

export const getMemberProfileActions = createAsyncAction(
  'verybuy/GET_MEMBER_PROFILE',
  'verybuy/GET_MEMBER_PROFILE_SUCCESS',
  'verybuy/GET_MEMBER_PROFILE_FAIL'
)<undefined, MemberProfileSuccessPayload, MemberProfileFailPayload>();
