import { createSelector } from 'reselect';

import { VBConfigData } from '../../types/VBConfigResponse';
import { defaultState } from '../reducers/VBConfigReducer';
import { RootState } from '../rootReducer';

const VBConfigReducer = (state: RootState) => state.VBConfigReducer;

const makeVBConfigData = () => {
  return createSelector(VBConfigReducer, reducer => {
    return reducer?.data || defaultState.data;
  });
};

export const makeDefaultCurrencyData = () => {
  return createSelector(VBConfigReducer, reducer => {
    return reducer?.data?.currencies?.[0] || defaultState.data.currencies[0];
  });
};

export const makeDefaultShippingFeesData = () => {
  return createSelector(VBConfigReducer, reducer => {
    return (
      reducer?.data?.default_shipping_fees ||
      defaultState.data.default_shipping_fees
    );
  });
};

export const makeVBConfigDataByKey = (key: keyof VBConfigData) => {
  return createSelector(makeVBConfigData(), data => {
    return data[key];
  });
};

export const makeRemoteValuesByKey = (key: string) => {
  return createSelector(VBConfigReducer, reducer => {
    return reducer?.remoteValues[key] || false;
  });
};

export const makeIsCountriesFetching = () => {
  return createSelector(
    VBConfigReducer,
    reducer => reducer?.countries?.isFetching || false
  );
};

export const makeCountriesData = () => {
  return createSelector(
    VBConfigReducer,
    reducer => reducer?.countries?.data || defaultState.countries.data
  );
};

export const makeCountriesList = () => {
  return createSelector(makeCountriesData(), countries => {
    return Object.values(countries);
  });
};
