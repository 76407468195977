import produce from 'immer';
import { getType } from 'typesafe-actions';

import { ListLoadingTypes, NotificationGroup } from '../../types/Common';
import { Notification } from '../../types/Notifications';
import { NotificationsSuccessPayload } from '../actions/getNotificationsActions';
import { actionsList } from '../rootAction';

const { getNotificationsActions, patchNotificationReadActions, clearData } =
  actionsList;

type TabGroups = Record<NotificationGroup, string[]> | {};

export interface State {
  isFetching: boolean;
  loadingType: ListLoadingTypes;
  groups: TabGroups;
  notifications: Record<string, Notification>;
  hasError: boolean;
  error?: Error | null;
}

export const defaultState: State = {
  isFetching: false,
  loadingType: 'new',
  groups: {},
  notifications: {},
  hasError: false,
};

export function formatGroupsValue(
  currentGroups: TabGroups,
  actionPayload: NotificationsSuccessPayload
): TabGroups {
  const { notifications, group } = actionPayload;
  const format = notifications.map(notification => notification.id);

  return { ...currentGroups, [group]: format };
}

export function formatNotifications(
  currentNotifications: Record<string, Notification>,
  actionPayload: NotificationsSuccessPayload
) {
  const { notifications } = actionPayload;
  const format = notifications.reduce(
    (acc, notification) => ({
      ...acc,
      [notification.id]: notification,
    }),
    currentNotifications
  );

  return format;
}

export default produce((draft: State, action) => {
  switch (action.type) {
    case getType(getNotificationsActions.request): {
      const { loadingType } = action.payload;

      draft.loadingType = loadingType;
      draft.isFetching = true;
      break;
    }
    case getType(getNotificationsActions.success): {
      draft.isFetching = false;
      draft.groups = formatGroupsValue(draft.groups, action.payload);
      draft.notifications = formatNotifications(
        draft.notifications,
        action.payload
      );
      draft.hasError = false;
      draft.error = null;
      break;
    }
    case getType(getNotificationsActions.failure): {
      draft.isFetching = false;
      draft.hasError = true;
      draft.error = action.payload.error;
      break;
    }
    case getType(patchNotificationReadActions.request): {
      const { id } = action.payload;

      if (draft.notifications[id]) {
        draft.notifications[id].is_read = true;
      }
      break;
    }
    case getType(clearData): {
      Object.entries(defaultState).forEach(([k, v]) => {
        draft[k] = v;
      });
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
