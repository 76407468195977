import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import * as arrayHandler from '../../utils/arrayHandler';
import { actionsList } from '../rootAction';

const { getQueryItemsActions } = actionsList;

export interface State {
  isFetching: boolean;
  hasError: boolean;
  items: string[];
  currentPage: number;
  loadingType: ListLoadingTypes;
  hasMoreItems?: boolean;
  error?: Error | null;
}

export const defaultState: State = {
  isFetching: true,
  hasError: false,
  currentPage: 1,
  loadingType: 'new',
  items: [],
};

export default produce(
  (draft: State, action: ActionType<typeof getQueryItemsActions>) => {
    switch (action.type) {
      case getType(getQueryItemsActions.request): {
        const { loadingType, page } = action.payload;
        const newItems = loadingType === 'new' ? [] : draft.items;

        draft.currentPage = page;
        draft.isFetching = true;
        draft.items = newItems;
        draft.loadingType = loadingType;
        break;
      }
      case getType(getQueryItemsActions.success): {
        const { data, loadingType } = action.payload;
        const itemsIds = data?.products?.map(d => `${d.id}`) || [];
        const newItems =
          loadingType === 'loadMore' ? [...draft.items, ...itemsIds] : itemsIds;

        draft.isFetching = false;
        draft.hasError = false;
        draft.error = null;
        draft.items = arrayHandler.uniqueArray(newItems);
        draft.hasMoreItems = itemsIds.length > 0;
        break;
      }
      case getType(getQueryItemsActions.failure): {
        draft.isFetching = false;
        draft.hasError = true;
        draft.error = action.payload.error;
        break;
      }
      default: {
        break;
      }
    }
  },
  defaultState
);
