/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */

import { createAsyncAction } from 'typesafe-actions';

import { DirectPayTokenData } from '../../types/DirectPayToken';

export interface DirectPayTokenPayload {
  cardID: number;
  screen: string;
  success(data: DirectPayTokenData): Function;
  transactionID: number;
}

export interface DirectPayTokenSuccessPayload {
  transactionID: number;
  data: DirectPayTokenData;
}

export interface DirectPayTokenFailPayload {
  transactionID: number;
  error: Error;
}

export const directPayTokenAction = createAsyncAction(
  'verybuy/DIRECT_PAY_TOKEN',
  'verybuy/DIRECT_PAY_TOKEN_SUCCESS',
  'verybuy/DIRECT_PAY_TOKEN_FAIL'
)<
  DirectPayTokenPayload,
  DirectPayTokenSuccessPayload,
  DirectPayTokenFailPayload
>();
