import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getFaqActions } from '../actions/getFaqActions';
import { getFaq } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getFaqActions.success,
  failure: getFaqActions.failure,
};

type RequestActionType = ActionType<typeof getFaqActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getFaqEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getFaqActions.request)),
    switchMap((action: RequestActionType) => {
      const { faqVersionCode, failure } = action.payload;

      return getFaq(faqVersionCode)
        .then((response: AxiosResponse<any>) =>
          getFaqActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) => {
          if (failure) {
            failure();
          }

          return getFaqActions.failure({ error });
        });
    })
  );
};

export default getFaqEpic;
