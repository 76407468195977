import styled from 'styled-components/native';

import { neutralLight, warrior, white } from '../../../constants/color';
import statusBarHeight from '../../../constants/statusBarHeight';

export const S = {
  // border style is from navbar of react-navigation
  HeaderWrapper: styled.View`
    align-items: center;
    background-color: ${white};
    border-bottom-color: ${neutralLight};
    border-bottom-width: 1px;
    flex-direction: row;
    height: ${47 + statusBarHeight}px;
    justify-content: center;
    padding-top: ${statusBarHeight}px;
    text-align: center;
    width: 100%;
  `,
  Title: styled.View`
    flex: 1;
    justify-content: center;
  `,
  TitleText: styled.Text`
    align-self: center;
    color: ${warrior};
    font-size: 18px;
    text-align: center;
  `,
  Left: styled.View`
    align-items: flex-start;
    width: 50px;
  `,
  Center: styled.View`
    align-items: center;
    align-self: stretch;
    flex: 1;
  `,
  Right: styled.View`
    align-items: flex-end;
    width: 50px;
  `,
};
