import { View as AnimatableView } from 'react-native-animatable';
import styled, { css } from 'styled-components/native';

import { peacock, warrior, white } from '../../constants/color';
import Touchable from '../Touchable';

interface Props {
  hintDirection: 'top' | 'bottom' | 'left' | 'right' | 'none';
  triangleOffset?: number;
  triangleAlignment?: 'flex-start' | 'center' | 'flex-end';
}

const oppositeDirection = {
  top: 'bottom',
  right: 'left',
  left: 'right',
  bottom: 'top',
};

const makeTriangleDirection = ({
  hintDirection,
}: Pick<Props, 'hintDirection'>) => {
  const opposite = oppositeDirection[hintDirection];

  return css`
        /* stylelint-disable property-no-unknown */
        margin-${opposite}: -1px;
        border-color: transparent;
        border-width: 8px;
        border-${opposite}-width: 12px;
        border-${hintDirection}-width: 0px;
        border-${opposite}-color: ${peacock};
        /* stylelint-enable property-no-unknown */
      `;
};

const makeTriangleOffset = ({
  hintDirection,
  triangleOffset,
}: Pick<Props, 'hintDirection' | 'triangleOffset'>) => {
  if (!triangleOffset) {
    return css``;
  }

  switch (hintDirection) {
    case 'top':
    case 'bottom':
      return css`
        transform: translate(${triangleOffset}px, 0);
      `;
    case 'left':
    case 'right':
      return css`
        transform: translate(0, ${triangleOffset}px);
      `;
    case 'none':
      return css`
        display: none;
      `;
    default:
      return css``;
  }
};

const commonTextStyle = () => {
  return css`
    color: ${white};
  `;
};

export const S = {
  TooltipWrapper: styled(AnimatableView)`
    position: absolute;
    z-index: 10;
  `,
  Triangle: styled.View<Pick<Props, 'hintDirection' | 'triangleOffset'>>`
    width: 0;
    height: 0;
    z-index: 2;
    ${makeTriangleDirection}
    ${makeTriangleOffset}
  `,
  Tooltip: styled.View`
    flex-direction: row;
    padding: 8px 10px;
    border-radius: 6px;
    background-color: ${peacock};
    shadow-color: ${warrior};
    shadow-opacity: 0.2;
    shadow-radius: 6px;
    shadow-offset: 0 3px;
    z-index: 1;
  `,
  Content: styled.View<Pick<Props, 'hintDirection' | 'triangleAlignment'>>`
    flex-direction: ${({ hintDirection }) => {
      switch (hintDirection) {
        case 'top':
          return 'column';
        case 'bottom':
          return 'column-reverse';
        case 'right':
          return 'row-reverse';
        case 'left':
        default:
          return 'row';
      }
    }};
    align-items: ${({ triangleAlignment }) => triangleAlignment};
    justify-content: center;
  `,
  TextWrapper: styled.View<{ maxWidth?: number }>`
    ${({ maxWidth }) => {
      if (!maxWidth) {
        return css``;
      }

      return css`
        max-width: ${maxWidth}px;
      `;
    }}
  `,
  Text: styled.Text<{ isBold?: boolean }>`
    font-size: 13px;
    line-height: 18px;
    font-weight: ${({ isBold }) => (isBold ? '500' : 'normal')};
    ${commonTextStyle}
  `,
  SubText: styled.Text<{ children?: string }>`
    display: ${({ children }) => (!!children ? 'flex' : 'none')};
    margin-top: ${({ children }) => (!!children ? '2px' : '0')};
    font-size: 11px;
    line-height: 16px;
    ${commonTextStyle}
  `,
  IconWrapper: styled(Touchable)`
    margin-left: 8px;
  `,
};
