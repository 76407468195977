/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { DirectPayTokenData } from '../../types/DirectPayToken';
import { directPayTokenAction } from '../actions/directPayTokenActions';
import { postDirectPayToken } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof directPayTokenAction.request>;
const outputActions = {
  success: directPayTokenAction.success,
  failure: directPayTokenAction.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const directPayTokenEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(directPayTokenAction.request)),
    switchMap((action: RequestActionTypes) => {
      const { transactionID, cardID, screen } = action.payload;

      return postDirectPayToken(transactionID, cardID, screen)
        .then((response: AxiosResponse<DirectPayTokenData>) => {
          const success = action.payload?.success || undefined;

          if (success !== undefined) {
            success(response.data);
          }

          return directPayTokenAction.success({
            data: response.data,
            transactionID,
          });
        })
        .catch((error: AxiosError) =>
          directPayTokenAction.failure({ error, transactionID })
        );
    })
  );
};

export default directPayTokenEpic;
