import { createAsyncAction } from 'typesafe-actions';

import { RecommendSubjectResponse } from '../../types/RecommendSubjectResponse';

export interface RecommendSubjectPayload {}

export interface RecommendSubjectPayloadSuccessPayload
  extends RecommendSubjectResponse {}

export interface RecommendSubjectPayloadFailPayload {
  error: Error;
}

export const getRecommendSubjectActions = createAsyncAction(
  'verybuy/GET_RECOMMEND_SUBJECT',
  'verybuy/GET_RECOMMEND_SUBJECT_SUCCESS',
  'verybuy/GET_RECOMMEND_SUBJECT_FAIL'
)<
  RecommendSubjectPayload,
  RecommendSubjectPayloadSuccessPayload,
  RecommendSubjectPayloadFailPayload
>();
