import { useEffect, useState } from 'react';

import IconQuestion from '../../assets/svg/IconQuestion/IconQuestion';
import { white } from '../../constants/color';
import * as navigationService from '../../utils/navigationService';
import S from './style';

// bad boy
function defaultFunction() {
  return false;
}

interface Props {
  screenKey: string;
  isFocused?: boolean | undefined;
}

export default function NavRightQuestionButton({
  isFocused,
  screenKey,
}: Props) {
  const [data, setData] = useState({
    onPress: () => false,
  });

  function subscribeHeader(target, info) {
    const { type, ...restData } = info.data;

    if (info.from === `${target}-component` && type === 'navRightQuest') {
      setData({
        ...data,
        ...restData,
      });
    }
  }

  useEffect(() => {
    let observableSource;

    if (isFocused) {
      observableSource = navigationService.navbarSubject$.subscribe(info => {
        subscribeHeader(screenKey, info);
      });
    }

    return () => {
      if (observableSource) {
        observableSource.unsubscribe();
      }
    };
  }, [isFocused]);

  const onPress = data.onPress ? data.onPress : defaultFunction;

  return (
    <S.QuestionButton
      testID="QuestionButton"
      accessibilityRole="button"
      onPress={onPress}
      hitSlop={{ top: 5, right: 15, bottom: 5, left: 15 }}
    >
      <IconQuestion size={25} fillColor={white} />
    </S.QuestionButton>
  );
}
