import styled from 'styled-components/native';

export default {
  Container: styled.View<{
    minHeight: number;
    marginLeft: number;
    width: number;
  }>`
    min-height: ${({ minHeight }) => minHeight}px;
    width: ${({ width }) => width}px;
    margin-left: ${({ marginLeft }) => marginLeft}px;
    overflow: hidden;
  `,
};
