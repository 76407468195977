import { Dispatch } from 'react';
import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { ListLoadingTypes } from '../../types/Common';
import { RecommendListItem } from '../../types/RecommendListResponse';

export interface Request {
  id: string;
  loadingType: ListLoadingTypes;
  page: number;
  per_page?: number;
  success: (products: RecommendListItem[]) => void;
  dispatch: Dispatch<any>;
}

export interface Success {
  id: string;
  items: RecommendListItem[];
  page: number;
  logEvent?: AnalyticsEvent;
}

export interface Failure {
  error: Error;
  id: string;
  requestAction?: ReturnType<typeof getRecommendLandingListActions.request>;
}

export const getRecommendLandingListActions = createAsyncAction(
  'verybuy/GET_RECOMMEND_LANDING_LIST',
  'verybuy/GET_RECOMMEND_LANDING_LIST_SUCCESS',
  'verybuy/GET_RECOMMEND_LANDING_LIST_FAIL'
)<Request, Success, Failure>();
