import { TouchableHighlight } from 'react-native';
import styled from 'styled-components/native';

import { buttonPressedColor, coral, white } from '../../constants/color';
import LoadingView from '../LoadingView';
import NoScaleText from '../NoScaleText';
import {
  ButtonProps,
  TextProps,
  buttonStyle,
  loadingViewStyle,
  textStyle,
} from './basicStyle';

const backgroundColor = coral;
const textColor = white;
const borderColor = 'transparent';

const getButtonBackgroundColor = (pressed, disabled) => {
  if (disabled) {
    return `${backgroundColor}66`;
  }

  return pressed ? buttonPressedColor : backgroundColor;
};

const primaryStyles = {
  Button: styled(TouchableHighlight)<ButtonProps>`
    ${buttonStyle};
    background-color: ${({ pressed, disabled }) =>
      getButtonBackgroundColor(pressed, disabled)};
    border-color: ${borderColor};
  `,
  Text: styled(NoScaleText)<TextProps>`
    ${textStyle};
    color: ${textColor};
  `,
  LoadingView: styled(LoadingView).attrs({
    color: white,
  })`
    ${loadingViewStyle};
  `,
};

export default primaryStyles;
