import { useMemo } from 'react';
import { useCallback } from 'react';
import { Text } from 'react-native';

import { AnalyticsEvent } from '../../../../../constants/AnalyticsEvent';
import { MktType } from '../../../../../constants/MktType';
import { defaultSortBy } from '../../../../../constants/sortData';
import Image from '../../../../../elements/Image';
import Touchable from '../../../../../elements/Touchable';
import useActions from '../../../../../hooks/useActions';
import { logAnalyticsEvent } from '../../../../../redux/actions/analyticsLog';
import { CatItem } from '../../../../../types/CatsResponse';
import { WebLinkOpenType } from '../../../../../types/Common';
import * as navigationService from '../../../../../utils/navigationService';
import routeUtils from '../../../../../utils/routes';
import styles from './styles';

function getWebAttributes(data: CategoryExtends) {
  const isExternalLink = !!data.href;

  return {
    href: isExternalLink
      ? data.href
      : routeUtils.getPathAndParamsFromData('ListPage', {
          typeId: data.id,
          sortBy: defaultSortBy,
        }).fullPath,
    target: isExternalLink ? WebLinkOpenType.BLANK : WebLinkOpenType.SELF,
  };
}

interface Props {
  afterNavigate: () => void;
  item: CategoryExtends;
  layoutName: string;
  pitNumber: number;
}

interface CategoryExtends extends CatItem {
  href?: string;
}

export default function CategoryItem({
  afterNavigate,
  item,
  layoutName,
  pitNumber,
}: Props) {
  const logEvent = useActions(logAnalyticsEvent);
  const webAttributes = useMemo(() => getWebAttributes(item), [item]);
  const handlePressCategory = useCallback(() => {
    logEvent({
      logEvent: AnalyticsEvent.Click,
      data: {
        layoutName,
        mktType: MktType.Icon,
        pitNumber,
        additionalLogData: {
          parent_category_id: `${item.parent_id}`,
          category_id: `${item.id}`,
          name: item.name,
        },
      },
    });

    if (item.href) {
      return;
    }

    navigationService.navigate('ListPage', {
      typeId: item.id,
      sortBy: defaultSortBy,
    });

    afterNavigate();
  }, [afterNavigate, logEvent, layoutName, item, pitNumber]);

  return (
    <Touchable
      accessibilityRole="link"
      onPress={handlePressCategory}
      testID={`burgerCategory_${item.id}`}
      style={styles.categoryContent}
      {...webAttributes}
    >
      <Image style={styles.categoryIcon} source={{ uri: item.icon_url }} />
      <Text style={styles.categoryTitle}>{item.name}</Text>
    </Touchable>
  );
}
