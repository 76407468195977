import deepEqual from 'fast-deep-equal';
import { ActionType, getType } from 'typesafe-actions';

import { SearchRecommend } from '../../types/SearchRecommend';
import { UserSubmitItem } from '../../types/UserSubmitItem';
import * as arrayHandler from '../../utils/arrayHandler';
import { getSimilarItemsActions } from '../actions/getSimilarItemsActions';
import { getTopRatedActions } from '../actions/getTopRatedActions';
import { actionsList } from '../rootAction';

export interface UserSubmitItemForReducer extends UserSubmitItem {
  source?: string;
}

const {
  getUserSubmitList,
  searchActions,
  searchRecommendActions,
  getUserSubmitDetail,
  getRecommendList,
  getRecommendLandingListActions,
  putItemLovesActions,
  deleteItemLovesActions,
  getLoveListActions,
  getTopSalesActions,
  getMarketingLandingPageActions,
  getNewArrivalActions,
  getOnSaleActions,
  getQueryItemsActions,
  getLabelItemsActions,
  getIndexOnSaleActions,
  getItemComboListActions,
  getOnboardingActions,
  getRecommendProductsActions,
  getSearchWithTokenActions,
} = actionsList;
const initialState = {};

function specialProcess(
  state = {
    item: {},
    isFetching: false,
    isDetailed: false,
  },
  actionType,
  actionResult
) {
  switch (actionType) {
    case getType(getTopSalesActions.success):
    case getType(getTopRatedActions.success):
    case getType(getRecommendProductsActions.success):
    case getType(getRecommendList.success):
    case getType(getRecommendLandingListActions.success):
    case getType(getSearchWithTokenActions.success):
    case getType(getSimilarItemsActions.success):
    case getType(getOnboardingActions.success):
    case getType(getNewArrivalActions.success):
    case getType(getOnSaleActions.success):
    case getType(getItemComboListActions.success):
    case getType(getQueryItemsActions.success):
    case getType(getLabelItemsActions.success):
    case getType(getIndexOnSaleActions.success):
    case getType(getMarketingLandingPageActions.success): {
      const item = {
        ...state.item,
        ...actionResult,
      };

      const newItem = deepEqual(state.item, item) ? state.item : item;

      return {
        ...state,
        isFetching: false,
        item: newItem,
      };
    }

    case getType(getUserSubmitList.success):
    case getType(searchActions.success): {
      const item = {
        ...state.item,
        ...actionResult,
      };
      const newItem = deepEqual(state.item, item) ? state.item : item;

      return {
        ...state,
        isFetching: false,
        item: newItem,
      };
    }

    case getType(searchRecommendActions.success): {
      const item = {
        ...state.item,
        ...actionResult,
      };
      const newItem = deepEqual(state.item, item) ? state.item : item;

      return {
        ...state,
        isFetching: false,
        item: newItem,
      };
    }

    case getType(getLoveListActions.success): {
      const newItem = { ...state.item, ...actionResult, is_loved: true };

      return {
        ...state,
        item: newItem,
      };
    }

    default: {
      return state;
    }
  }
}

function process(
  state = {
    detailLoadingType: 'new',
    isFetchingItemDetail: false,
    isFetchingRecommendList: false,
    item: {},
    recommendList: [],
    recommendListLoadingType: 'new',
    recommendListPage: 1,
  },
  action
) {
  const { loadingType } = action.payload;

  switch (action.type) {
    case getType(getUserSubmitDetail.failure): {
      return {
        ...state,
        isFetchingItemDetail: false,
        error: action.error,
      };
    }

    case getType(getUserSubmitDetail.success): {
      const item = {
        ...state.item,
        ...action.payload.data,
      };

      const newItem = deepEqual(state.item, item) ? state.item : item;

      return {
        ...state,
        isFetchingItemDetail: false,
        isDetailed: true,
        item: newItem,
      };
    }
    case getType(getUserSubmitDetail.request): {
      return {
        ...state,
        isFetchingItemDetail: true,
        detailLoadingType: loadingType,
      };
    }

    case getType(getSimilarItemsActions.request): {
      return {
        ...state,
        isFetchingSimilarItems: true,
        similarItemsLoadingType: loadingType,
      };
    }
    case getType(getSimilarItemsActions.success): {
      const { products } = (
        action as ActionType<typeof getSimilarItemsActions.success>
      ).payload;
      const newItems =
        (products && products.map && products.map(d => `${d.id}`)) || [];

      return {
        ...state,
        isFetchingSimilarItems: false,
        similarItems: arrayHandler.uniqueArray(newItems),
      };
    }
    case getType(getSimilarItemsActions.failure): {
      return {
        ...state,
        isFetchingSimilarItems: false,
      };
    }

    case getType(getRecommendList.request): {
      return {
        ...state,
        isFetchingRecommendList: true,
        recommendListLoadingType: loadingType,
      };
    }
    case getType(getRecommendList.success): {
      const { items, page } = (
        action as ActionType<typeof getRecommendList.success>
      ).payload;
      const theRecommendList =
        (items && items.map && items.map(d => `${d.id}`)) || [];
      const thePage = page || 1;
      const newItems =
        thePage === 1
          ? theRecommendList
          : [...state.recommendList, ...theRecommendList];
      const recommendListPage = theRecommendList.length
        ? thePage
        : state.recommendListPage;

      const hasMoreRecommends = theRecommendList.length > 0;

      return {
        ...state,
        isFetchingRecommendList: false,
        recommendList: arrayHandler.uniqueArray(newItems),
        recommendListPage,
        hasMoreRecommends,
      };
    }
    case getType(getRecommendList.failure): {
      return {
        ...state,
        isFetchingRecommendList: false,
        error: action.payload.error,
      };
    }

    case getType(putItemLovesActions.request):
    case getType(deleteItemLovesActions.request): {
      return {
        ...state,
        isFetchingLoves: true,
      };
    }
    case getType(putItemLovesActions.success): {
      const newItem = { ...state.item, is_loved: true };

      return {
        ...state,
        item: newItem,
        isFetchingLoves: false,
      };
    }
    case getType(deleteItemLovesActions.success): {
      const newItem = { ...state.item, is_loved: false };

      return {
        ...state,
        item: newItem,
        isFetchingLoves: false,
      };
    }
    case getType(putItemLovesActions.failure): {
      const newItem = { ...state.item, is_loved: false };

      return {
        ...state,
        item: newItem,
        isFetchingLoves: false,
        error: action.payload.error,
      };
    }
    case getType(deleteItemLovesActions.failure): {
      const newItem = { ...state.item, is_loved: true };

      return {
        ...state,
        item: newItem,
        isFetchingLoves: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}

// handle a list of items
function processReduce(state, action, data, result) {
  return (data || []).reduce((collection, item) => {
    const { id } = item;

    collection[id] = specialProcess(state[id], action.type, item);

    return collection;
  }, result);
}

/**
 * 因 v4 api 的 type 欄位為 product_type，而以後此部分會統一，
 * 為了方便之後更換，故將 function 分出來，之後若 processReduce 沒在用則可以直接移除。
 */
function processReduceForV4(state, action, data = [], result) {
  return data.reduce((collection, item) => {
    const { id } = item;

    collection[id] = specialProcess(state[id], action.type, item);

    return collection;
  }, result);
}

const userSubmitDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case getType(getTopRatedActions.success):
    case getType(getTopSalesActions.success): {
      const { items } = action.payload;

      return {
        ...state,
        ...processReduceForV4(state, action, items, {}),
      };
    }

    case getType(getMarketingLandingPageActions.success):
    case getType(getOnboardingActions.success):
    case getType(getNewArrivalActions.success):
    case getType(getItemComboListActions.success):
    case getType(getOnSaleActions.success):
    case getType(getQueryItemsActions.success):
    case getType(getLabelItemsActions.success):
    case getType(getSearchWithTokenActions.success):
    case getType(getIndexOnSaleActions.success):
    case getType(getRecommendProductsActions.success):
    case getType(searchActions.success):
    case getType(getUserSubmitList.success): {
      const { products: items } = action.payload.data;

      return {
        ...state,
        ...processReduce(state, action, items, {}),
      };
    }

    case getType(searchRecommendActions.success): {
      const { products } = action.payload.data as SearchRecommend;

      return {
        ...state,
        ...processReduce(state, action, products, {}),
      };
    }

    case getType(getUserSubmitDetail.request): {
      return {
        ...state,
        [action.payload.id]: process(state[action.payload.id], action),
      };
    }

    case getType(getUserSubmitDetail.success):
    case getType(getUserSubmitDetail.failure): {
      return {
        ...state,
        [action.payload.id]: process(state[action.payload.id], action),
      };
    }
    case getType(getSimilarItemsActions.request):
    case getType(getSimilarItemsActions.failure): {
      const id = action.payload.itemId;

      return {
        ...state,
        [id]: process(state[id], action),
      };
    }
    case getType(putItemLovesActions.request):
    case getType(putItemLovesActions.success):
    case getType(putItemLovesActions.failure):
    case getType(deleteItemLovesActions.request):
    case getType(deleteItemLovesActions.success):
    case getType(deleteItemLovesActions.failure):
    case getType(getRecommendList.request):
    case getType(getRecommendList.failure): {
      return {
        ...state,
        [action.payload.id]: process(state[action.payload.id], action),
      };
    }
    case getType(getRecommendList.success): {
      const items = action.payload.items;

      return {
        ...state,
        ...processReduce(state, action, items, {}),
        [action.payload.id]: process(state[action.payload.id], action),
      };
    }

    case getType(getRecommendLandingListActions.success):
    case getType(getLoveListActions.success): {
      const items = action.payload.items || [];

      return {
        ...state,
        ...processReduce(state, action, items, {}),
      };
    }

    case getType(getSimilarItemsActions.success): {
      const items = action.payload.products;
      const id = action.payload.itemId;

      return {
        ...state,
        ...processReduce(state, action, items, {}),
        [id]: process(state[id], action),
      };
    }

    default:
  }

  return state;
};

export default userSubmitDetailReducer;
