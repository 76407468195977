import hoistStatics from 'hoist-non-react-statics';
import { Component, FC, PureComponent } from 'react';
import { NavigationState } from 'react-navigation';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Subscription } from 'rxjs';

import WelcomeLoginPage from '../../components/WelcomeLoginPage';
import { logAnalyticsEvent } from '../../redux/actions/analyticsLog';
import * as memberSelector from '../../redux/selectors/member';
import { NavigationParams } from '../../types/NavigationParams';
import keyBehaviorSubject from '../../utils/keyBehaviorSubject';
import * as navigationService from '../../utils/navigationService';
import routeUtils from '../../utils/routes';
import withIsFocused from '../withIsFocused';

function mapToWelcomePageProps({ routeName, key, params }) {
  return routeName
    ? {
        [NavigationParams.BackToPageKey]: routeName,
        [NavigationParams.BackWithKey]: key,
        [NavigationParams.EncodedPageParams]:
          routeUtils.encodePageParams(params),
      }
    : {};
}

interface Props {
  isLogin: boolean;
  isFocused: boolean;
  logEvent: Function;
}

interface State {
  defaultScreen: NavigationState | undefined;
}

function decorate(WrappedComponent: typeof Component | FC) {
  class PrivateRoute extends PureComponent<Props, State> {
    state = {
      defaultScreen: undefined,
    };

    componentDidMount() {
      const currentScreenValue = navigationService.screenSubject$.getValue();

      if (currentScreenValue.currentScreen) {
        this.subscribeCurrentScene(currentScreenValue);
      } else {
        this.observableSource = navigationService.screenSubject$.subscribe(
          this.subscribeCurrentScene
        );
      }
    }

    componentWillUnmount() {
      this.unsubscribeCurrentScene();
    }

    get currentRouteInfo() {
      const activeRouteInfo =
        navigationService.getActiveRoute() || this.state.defaultScreen;

      return activeRouteInfo || {};
    }

    private observableSource: Subscription | null = null;

    subscribeCurrentScene = ({ currentScreen }) => {
      if (currentScreen) {
        this.setState({ defaultScreen: currentScreen });
        this.unsubscribeCurrentScene();
      }
    };

    unsubscribeCurrentScene = () => {
      if (this.observableSource) {
        this.observableSource.unsubscribe();
        this.observableSource = null;
      }
    };

    render() {
      const { isLogin } = this.props;
      let currentRouteInfo;

      if (!isLogin) {
        currentRouteInfo = this.currentRouteInfo;
        keyBehaviorSubject.next('privateRouteOpen');
      }

      return isLogin ? (
        <WrappedComponent testID="wrappedComponent" {...this.props} />
      ) : (
        <WelcomeLoginPage {...mapToWelcomePageProps(currentRouteInfo)} />
      );
    }
  }

  return hoistStatics(withIsFocused(PrivateRoute), WrappedComponent);
}

export const decorateExportForTest = decorate;

const mapStateToProps = () => {
  const selector = createStructuredSelector({
    isLogin: memberSelector.makeIsLogin(),
  });

  return state => selector(state);
};

const mapDispatchToProps = {
  logEvent: logAnalyticsEvent,
};

export default (WrappedComponent: typeof Component | FC<any>) => {
  return connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(decorate(WrappedComponent));
};
