import { FunctionComponent, memo } from 'react';

import IconLikeBroke from '../../assets/svg/IconLikeBroke';
import { white } from '../../constants/color';
import { translate } from '../../i18n';
import { S } from './styles';

export const LovesOverLimit: FunctionComponent = memo(() => (
  <S.LovesOverLimitWrapper testID="LovesOverLimit">
    <IconLikeBroke size={24} fillColor={white} />
    <S.LovesOverLimitText>{translate('collection-full')}</S.LovesOverLimitText>
  </S.LovesOverLimitWrapper>
));
