/* eslint-disable */
// @ts-ignore
import isObject from 'lodash/isObject';

export default (value: any): boolean => {
  if (value == undefined) {
    return false;
  }

  if (value === true) {
    return true;
  }

  if (value === false) {
    return true;
  }

  if (value === '') {
    return true;
  }

  if (value === 0) {
    return true;
  }

  if (value.length > 0) {
    return true;
  }

  if (isObject(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    return true;
  }

  if (value) {
    return true;
  }

  return false;
};
