import { getType } from 'typesafe-actions';

import { actionsList } from '../rootAction';

const { getMarketingEventsActions } = actionsList;

export default function (state = {}, action) {
  switch (action.type) {
    case getType(getMarketingEventsActions.request): {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getType(getMarketingEventsActions.success): {
      return {
        ...state,
        isLoading: false,
        hasError: false,
        error: null,
        data: action.payload.data.filter(marketingEvent => {
          return marketingEvent.title !== '' && marketingEvent.type !== '';
        }),
      };
    }
    case getType(getMarketingEventsActions.failure): {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
