import styled from 'styled-components/native';

export const S = {
  Container: styled.View`
    align-items: center;
    justify-content: center;
    padding: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
  `,
  AnimatedContainer: styled.View`
    width: 24px;
    height: 24px;
  `,
};
