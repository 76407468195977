import { Picker } from '@react-native-picker/picker';
import { useState } from 'react';

import CommonButton from '../../elements/CommonButton';
import * as navigationService from '../../utils/navigationService';
import S from './style';

export default () => {
  const [inputId, setInputId] = useState('ali:22249');
  const [inputType, setInputType] = useState('product');
  const handlePressInput = () => {
    switch (inputType) {
      case 'product':
        navigationService.navigate('ItemPage', {
          id: inputId,
        });
        break;
      case 'transaction':
        navigationService.navigate('TransactionResultPage', {
          transactionID: inputId,
        });
        break;
      case 'transaction_detail':
        navigationService.navigate('TransactionDetailPage', {
          transactionID: inputId,
        });
        break;
      default:
        break;
    }
  };

  return (
    <S.RowItem>
      <S.ViewForm>
        <S.Label>Navigate to</S.Label>
        <S.FlexRow>
          <Picker
            style={{ flex: 1, margin: 5 }}
            selectedValue={inputType}
            onValueChange={v => setInputType(`${v}`)}
            itemStyle={{ height: 50 }}
          >
            <Picker.Item label="product" value="product" />
            <Picker.Item label="transaction" value="transaction" />
            <Picker.Item
              label="transaction_detail"
              value="transaction_detail"
            />
          </Picker>
          <S.FormInput
            maxLength={40}
            textAlign={'center'}
            placeholder={`${inputType} id`}
            onChangeText={v => setInputId(v.trim())}
            autoCorrect={false}
            autoCapitalize="none"
            value={inputId}
          />
        </S.FlexRow>
      </S.ViewForm>
      <CommonButton onPress={handlePressInput} text="GO" />
    </S.RowItem>
  );
};
