import axios, { AxiosRequestConfig } from 'axios';
import { Platform } from 'react-native';

import { DeviceType } from '../../types/Common';
import Config from '../../utils/configs';
import { getDeviceType } from '../../utils/layoutHelpers';

export function createAxiosInstance(config: AxiosRequestConfig = {}) {
  return axios.create({
    responseType: 'json',
    transformResponse: r => r?.data || r,
    ...config,
  });
}

export const baseAxios = createAxiosInstance();
export const BEAxios = createAxiosInstance();

export function setAxiosDefaultHeader(key, value) {
  BEAxios.defaults.headers[key] = value;
}

export function deleteAxiosDefaultHeader(key) {
  delete BEAxios.defaults.headers[key];
}

let apiUrl = {};
let webViewUrl = '';

export function setupBaseUrl(baseUrl) {
  apiUrl = {
    v4: `${baseUrl}/v4`,
  };
}

export function getBaseUrl(version) {
  return apiUrl[version];
}

export function setWebViewUrl(url) {
  webViewUrl = url;
}

export function getWebViewUrl() {
  return webViewUrl;
}

export function getS3ApiUrl() {
  return Config.S3_API_URL;
}

export function getPlatformCode() {
  switch (Platform.OS) {
    case 'ios':
      return 1;
    case 'android':
      return 2;
    default:
      return 3;
  }
}

export function getApiToken() {
  return BEAxios.defaults.headers['api-key'];
}

const perPageMapping = {
  [DeviceType.MOBILE]: 20,
  [DeviceType.TABLET]: 40,
  [DeviceType.DESKTOP]: 40,
};

export function getListItemPerPage() {
  const device = getDeviceType();
  const defaultValue = 20;

  return perPageMapping[device] || defaultValue;
}

export async function requestWithRetry<T>(
  promiseFn: () => Promise<T>,
  maxAttempts = 3
) {
  let attempts = 0;
  let error: any = null;

  while (attempts < maxAttempts) {
    try {
      const result = await promiseFn();

      return result;
    } catch (_error) {
      error = _error;
    } finally {
      attempts = attempts + 1;
    }
  }

  throw error;
}
