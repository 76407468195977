import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getEventCouponActions } from '../actions/getEventCouponActions';
import { getEventCoupon } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getEventCouponActions.success,
  failure: getEventCouponActions.failure,
};

type RequestActionTypes = ActionType<typeof getEventCouponActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getEventCouponEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getEventCouponActions.request)),
    switchMap((action: RequestActionTypes) => {
      return getEventCoupon()
        .then(response =>
          getEventCouponActions.success({
            coupons: response.data.coupons,
          })
        )
        .catch((error: AxiosError) =>
          getEventCouponActions.failure({ error, requestAction: action })
        );
    })
  );
};

export default getEventCouponEpic;
