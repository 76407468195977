import { StyleSheet } from 'react-native';
import styled, { css } from 'styled-components/native';

import { neutralDark, peacock, white } from '../../../../constants/color';
import statusBarHeight from '../../../../constants/statusBarHeight';

const SEARCH_BAR_HEIGHT = 45;
const CONTAINER_TOP = SEARCH_BAR_HEIGHT + statusBarHeight;

const fontCSS = css`
  font-size: 15px;
  line-height: 21px;
`;

export const S = {
  Container: styled.View`
    margin-top: ${CONTAINER_TOP}px;
    flex: 1;
  `,
  HistoriesTitleSection: styled.View`
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  `,
  HistoriesTitle: styled.Text`
    color: ${neutralDark};
    ${fontCSS};
  `,
  ClearHistoriesBtnText: styled.Text`
    color: ${peacock};
    ${fontCSS};
  `,
  BubblesSection: styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  `,
};

const styles = StyleSheet.create({
  viewer: { backgroundColor: white, flex: 1 },
  containerForShown: {
    opacity: 1,
  },
  tipText: {
    fontSize: 12,
    lineHeight: 18,
    color: neutralDark,
    textAlign: 'center',
  },
  bubbleListScrollView: {
    width: '100%',
  },
  scrollContent: {
    marginHorizontal: 15,
  },
});

export default styles;
