import { ViewToken } from 'react-native';

import ElementType from '../../constants/ElementType';
import { MktType } from '../../constants/MktType';
import { PickRequired } from '../../types';
import { AdditionalCategory } from '../../types/Categories';
import { CatItem } from '../../types/CatsResponse';
import { ItemTypes } from '../../types/Common';
import { Impression } from '../../types/CTR';
import { ImpressionDataProps } from './types';

export function basicProducer(
  data: Pick<ViewToken, 'item' | 'index'>,
  props: ImpressionDataProps
) {
  const sourceFromSources = props.sources
    ? props.sources.get(data.item)
    : undefined;

  return {
    layoutName: props.layoutName,
    // type of index is probably null, set to -1 as undefined pitNumber
    pitNumber: data.index === null ? -1 : data.index + 1,
    source: sourceFromSources || props.source,
    elementType: props.elementType,
    mktType: props.mktType,
  };
}

export function itemProducer(
  data: ViewToken,
  props: ImpressionDataProps
): Impression[] {
  const { item } = data;

  if (/^blank/.test(item)) {
    return [];
  }

  const { marketID } = props;

  return [
    {
      ...basicProducer(data, props),
      id: item,
      itemType: ItemTypes.USER_SUBMIT,
      marketID,
      elementType: ElementType.Item,
      mktType: MktType.Image,
    },
  ];
}

export function adItemProducer(
  data: Pick<ViewToken, 'item' | 'index'>,
  props: ImpressionDataProps
): Impression[] {
  const { item } = data;

  if (/^blank/.test(item)) {
    return [];
  }

  return [
    {
      ...basicProducer(data, props),
      id: item,
      itemType: 'ad',
      elementType: ElementType.Item,
      mktType: MktType.Ad,
    },
  ];
}

export function categoryListItemProducer(
  data: ViewToken,
  props: ImpressionDataProps
): Impression[] {
  const { item } = data;

  if (/^blank/.test(item)) {
    return [];
  }

  const { currentSubCat } = props;

  return [
    {
      ...basicProducer(data, props),
      id: item,
      itemType: ItemTypes.USER_SUBMIT,
      currentSubCat,
      elementType: ElementType.CategoryListItem,
      mktType: MktType.Image,
    },
  ];
}

interface CategoryData extends ViewToken {
  item: (CatItem | AdditionalCategory)[];
}

export function categoryProducer(
  data: CategoryData,
  props: ImpressionDataProps
) {
  const { index, item } = data;
  const result: Impression[] = item
    .filter(({ id }) => !/blank/.test(id.toString()))
    .map(({ id, parent_id, name }, cIndex) => {
      return {
        ...basicProducer(data, props),
        // type of index is probably null, set to -1 as undefined pitNumber
        pitNumber: index === null ? -1 : index * 2 + cIndex + 1,
        additionalLogData: {
          parent_category_id: `${parent_id || 0}`,
          category_id: `${id}`,
          name,
        },
      };
    });

  return result;
}

export function historyProducer(
  data: ViewToken,
  props: ImpressionDataProps
): Impression[] {
  const { item } = data;

  if (/^blank/.test(item)) {
    return [];
  }

  const [id, tid] = item.split('-');

  return [
    {
      ...basicProducer(data, props),
      id,
      itemType: ItemTypes.USER_SUBMIT,
      elementType: ElementType.HistoryItem,
      mktType: MktType.Image,
      source: 'vieweditem',
      tid,
    },
  ];
}

export function categoryPageChildIdProducer(
  data: { item: string; index: number },
  props: PickRequired<
    ImpressionDataProps<{
      parent_category_id?: string;
      getItemName: (id: string) => string | undefined;
      getItemType: (id: string) => string | undefined;
    }>,
    'additionalLogData' | 'layoutName'
  >
) {
  const { item } = data;
  const { additionalLogData } = props;
  const { parent_category_id, getItemName, getItemType } = additionalLogData;

  let workaroundParams = {};

  /**
   * Workaround: 分類導航數據格式待討論，先照之前的做法做
   *
   * 問題如下
   * 1. category_id 和 label_key 若變成 uuid 是否還有帶的必要？
   * 2. 如果是為了分辨類型是否可以傳 type 就好？
   * 3. name 也會遇到跟搜尋標籤一樣的問題，是否有更好的做法？
   */
  switch (true) {
    case getItemType(item) === 'category':
      workaroundParams = {
        category_id: item,
      };
      break;
    case getItemType(item) === 'recommend_label':
      workaroundParams = {
        label_key: item,
      };
      break;
    default:
      break;
  }

  return {
    ...basicProducer(data, props),
    mktType: MktType.Image,
    additionalLogData: {
      parent_category_id,
      name: getItemName(item) || 'unknown',
      ...workaroundParams,
    },
  };
}

export function cartContentItemProducer(
  data: ViewToken,
  props: ImpressionDataProps
): Impression[] {
  const { item } = data;
  const [userSubmitId, vid] = item.split('-');

  return [
    {
      ...basicProducer(data, props),
      id: userSubmitId,
      itemType: ItemTypes.USER_SUBMIT,
      vid: vid,
      elementType: ElementType.CartContentItem,
      mktType: MktType.Image,
    },
  ];
}

export function searchLabelItemProducer(
  data: { item: string; index: number },
  props: ImpressionDataProps
) {
  const { makeLabelKey, query_text } = props.additionalLogData || {};

  return {
    ...basicProducer(data, props),
    additionalLogData: {
      query_text,
      label_id: data.item,
      label_key: makeLabelKey(data.item),
    },
  };
}
