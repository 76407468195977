import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { CommonErrors, DBErrors } from '../../constants/VBErrorCode';
import { getVendorDetailActions } from '../actions/getVendorDetailActions';
import { getVendorDetail } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getVendorDetailActions.success,
  failure: getVendorDetailActions.failure,
};

type RequestActionType = ActionType<typeof getVendorDetailActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getVendorDetailEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getVendorDetailActions.request)),
    switchMap((action: RequestActionType) => {
      const { failure, success, ...restParams } = action.payload;

      return getVendorDetail(restParams)
        .then(response => {
          if (success) {
            success(response.data);
          }

          return getVendorDetailActions.success({
            data: response.data,
            id: restParams.id,
          });
        })
        .catch((error: AxiosError) => {
          const perticularError = [
            CommonErrors.ERR_LAKE_ARGUMENT,
            DBErrors.ERR_DB_NOT_FOUND,
          ];
          const { error_code: errorCode } = error?.response?.data || {};
          const preventCommonError = perticularError.some(
            theError => errorCode === theError
          );

          if (preventCommonError) {
            failure();
          }

          return getVendorDetailActions.failure({
            customErrorHandling: preventCommonError || undefined,
            error,
            id: restParams.id,
            requestAction: action,
          });
        });
    })
  );
};

export default getVendorDetailEpic;
