import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { ReducerData } from '../../types/Announcement';
import { ID } from '../../types/Common';
import { convertArrayToObject } from '../../utils/arrayHandler';
import { getAnnouncementDetailActions } from '../actions/getAnnouncementDetailActions';
import { getAnnouncementsActions } from '../actions/getAnnouncementsActions';

export interface State {
  isFetching: boolean;
  data: Record<ID, ReducerData>;
  itemsIds: string[];
}

export const defaultState: State = {
  isFetching: false,
  data: {},
  itemsIds: [],
};

type Action =
  | typeof getAnnouncementsActions
  | typeof getAnnouncementDetailActions;

export default produce((draft: State, action: ActionType<Action>) => {
  switch (action.type) {
    case getType(getAnnouncementsActions.request): {
      const { loadingType } = action.payload;
      const isNewRequest = loadingType === 'new';

      draft.data = isNewRequest ? {} : draft.data;
      draft.itemsIds = isNewRequest ? [] : draft.itemsIds;
      draft.isFetching = true;
      break;
    }
    case getType(getAnnouncementsActions.success): {
      const { data } = action.payload;
      const itemsIds = data.map(datum => datum.id);

      draft.isFetching = false;
      draft.data = convertArrayToObject(data, 'id', draft.data);
      draft.itemsIds = itemsIds;
      break;
    }
    case getType(getAnnouncementsActions.failure): {
      draft.isFetching = false;
      break;
    }
    case getType(getAnnouncementDetailActions.request): {
      const { id } = action.payload;

      draft.data[id] = draft.data[id] || {};
      draft.data[id].isFetching = true;
      break;
    }
    case getType(getAnnouncementDetailActions.success): {
      const { id, data } = action.payload;

      draft.data[id] = data;
      draft.data[id].isFetching = false;
      break;
    }
    case getType(getAnnouncementDetailActions.failure): {
      const { id } = action.payload;

      draft.data[id] = draft.data[id] || {};
      draft.data[id].isFetching = false;
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
