import type { ItemTypes } from './Common';
import type { ProductReview } from './ProductReviewListDataResponse';
import type { VendorDetail } from './VendorDetail';

export interface ImageURL {
  sm: string;
  md: string;
  lg: string;
}

export interface ItemBase {
  id: string;
  title: string;
  image_url: string;
  min_price: number;
  max_price: number;
  original_min_price: number;
  original_max_price: number;
  type: ItemTypes;
  marketing_product_id: string;
}

export interface ListBase extends ItemBase {
  source: string;
}

export interface ListBaseV4 extends Omit<ItemBase, 'type'> {
  source: string;
  ribbons: Ribbons;
  color_amount: number;
  seller_customize_name: string;
  child_category_id: number;
  parent_category_id: number;
  total_sales_quantity: number;
}

// 因欄位 ribbon 不敷使用，商品有多個 ribbon 成立的狀況
// 有新 type 的時候，需要處理目前可使用的排序最前的 type（避免未更新 app 的問題）
export enum RibbonType {
  KeyAccount = 1,
  NewArrival,
  Discount,
  InStock,
}

export interface Ribbon {
  text?: string;
  type?: RibbonType;
}

export type Ribbons = Ribbon[];

export interface ItemVideo {
  src: string;
}

export interface ItemDetailBase extends ItemBase {
  detail_image_urls: string[];
  is_product_allow_cvs: boolean;
  tags: string[];
  max_price_ntd: number;
  min_price_ntd: number;
  original_min_price_ntd: number;
  original_max_price_ntd: number;
  desc_text: string;
  desc_videos: { src: string }[];
  pictures: string[];
  videos: ItemVideo[];
  is_not_allowed: boolean;
  is_loved: boolean;
  is_offline: boolean;
  parent_category_id: number;
  child_category_id: number;
  size_chart_image_urls: ImageURL[];
  size_info_image_url: string;
  ribbon: Ribbon;
  ribbons: Ribbons;
  delivery_days: number;
  seller_nick: string;
  seller: Omit<VendorDetail, 'product_amount'>;
  reviews: {
    average_score: number;
    review_count: number;
    product_skus: Omit<ProductReview, 'id'>[];
  };
}
