import { Suspense, forwardRef, lazy } from 'react';

import type { PickerRef, Props } from './types';

const LazyPickerComponent = lazy(() => import('./PickerComponent'));

export default forwardRef<PickerRef, Props>((props, ref) => (
  <Suspense fallback={null}>
    <LazyPickerComponent {...props} ref={ref} />
  </Suspense>
));
