import { persistStore } from 'redux-persist';

let persiststore;

export const setPersistStore = store => {
  persiststore = persistStore(store);

  return persiststore;
};

export { persiststore };
