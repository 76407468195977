import { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import type { DeliveryData } from '../../types/DeliveryData';
import { loadDeliveryData } from '../actions/loadDeliveryDataActions';
import { getDeliveryData } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: loadDeliveryData.success,
  failure: loadDeliveryData.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

export const loadDeliveryDataEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(loadDeliveryData.request)),
    switchMap(() => {
      return getDeliveryData()
        .then((response: AxiosResponse<DeliveryData>) =>
          loadDeliveryData.success({
            data: response.data,
          })
        )
        .catch(error => loadDeliveryData.failure({ error }));
    })
  );
};
