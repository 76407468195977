import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import postRegisterActions from '../actions/postRegisterActions';
import { postRegister, setAxiosDefaultHeader } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof postRegisterActions.request>;
const outputActions = {
  success: postRegisterActions.success,
  failure: postRegisterActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const postRegisterEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(postRegisterActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { success, failure, loginMethod, ...rest } = action.payload;

      return postRegister(rest)
        .then(response => {
          const { data } = response;

          setAxiosDefaultHeader('api-key', data.token);

          if (success !== undefined) {
            success(data);
          }

          return postRegisterActions.success({
            result: response,
            account: action.payload.account,
            logEvent: AnalyticsEvent.SignUp,
            method: loginMethod,
          });
        })
        .catch((error: AxiosError) => {
          if (failure !== undefined) {
            failure(error.response);
          }

          return postRegisterActions.failure({
            error,
            account: action.payload.account,
            logEvent: AnalyticsEvent.SignUp,
            method: loginMethod,
          });
        });
    })
  );
};

export default postRegisterEpic;
