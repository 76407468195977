/* eslint-disable camelcase */
import { createAsyncAction } from 'typesafe-actions';

import { CheckoutSteps, DeliveryMode } from '../../types/Common';
import { SubtotalData } from '../../types/Subtotal';

export interface PutSubtotalPayload {
  checkoutStep?: CheckoutSteps;
  selected_delivery_mode?: DeliveryMode;
}
export interface PutSubtotalSuccessPayload {
  data: SubtotalData;
  logEvent?: CheckoutSteps;
}

export interface PutSubtotalFailPayload {
  error: Error;
}

export const putSubtotalActions = createAsyncAction(
  'verybuy/PUT_SUBTOTAL',
  'verybuy/PUT_SUBTOTAL_SUCCESS',
  'verybuy/PUT_SUBTOTAL_FAIL'
)<PutSubtotalPayload, PutSubtotalSuccessPayload, PutSubtotalFailPayload>();
