import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const apiCommonErrorRaw = (state: RootState) =>
  state?.apiCommonErrorReducer || {};

export const makeApiCommonErrorDataByKey = (key: string) => {
  return createSelector(apiCommonErrorRaw, data => data?.[key]);
};
