export enum CouponStatus {
  VALID = 'valid',
  EXPIRING = 'expiring',
  UNDER_THRESHOLD = 'under_threshold',
  NEW = 'new',
  USED = 'used',
  EXPIRED = 'expired',
}

export enum CouponType {
  AVAILABLE = 'available',
  EXPIRED = 'expired',
  USED = 'used',
}
