import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { ReviewStatus } from '../../types/Common';
import type { Product } from '../../types/TransactionDetail';
import type { ProductForTransactionList } from '../../types/TransactionItemForList';
import { actionsList } from '../rootAction';

const { getTransactionListActions, fetchTransactionDetail, postRatingActions } =
  actionsList;

type Action = ActionType<
  | typeof getTransactionListActions.success
  | typeof fetchTransactionDetail.success
  | typeof postRatingActions.success
>;

export interface RateItem {
  virtual_product_id: string;
  image_url: string;
  title: string;
  size_text?: string;
  color_text?: string;
  sku_image_url?: string;
}

interface Detail {
  delivery_date: string;
  transaction_no: string;
  items: RateItem[];
}

type State = Record<string, Detail | undefined>;

export const defaultState: State = {};

const makeRateItem = (products: (Product | ProductForTransactionList)[]) => {
  const map = new Map<string, RateItem>();

  products
    .filter(item => item.review_status === ReviewStatus.NotReviewed)
    .forEach(item => {
      const skuId = String(item.virtual_product_id);

      const { sku_image_url, image_url, size_text, color_text, title } = item;

      map.set(skuId, {
        virtual_product_id: skuId,
        sku_image_url,
        image_url,
        size_text,
        color_text,
        title,
      });
    });

  return [...map.values()];
};

export default produce((draft: State, action: Action) => {
  switch (action.type) {
    case getType(getTransactionListActions.success): {
      const { payload } = action;

      payload.items
        .filter(transaction => {
          const { products } = transaction;

          return !!products.find(
            item => item.review_status === ReviewStatus.NotReviewed
          );
        })
        .forEach(transaction => {
          const transactionId = String(transaction.transaction_id);

          draft[transactionId] = {
            delivery_date: transaction.delivery_date,
            transaction_no: transaction.transaction_no,
            items: makeRateItem(transaction.products),
          };
        });

      break;
    }
    case getType(fetchTransactionDetail.success): {
      const { payment_data, product_data, transaction_no } =
        action.payload.data;

      if (product_data.shipped.length === 0) {
        break;
      }

      if (
        !product_data.shipped[0].products.find(
          item => item.review_status === ReviewStatus.NotReviewed
        )
      ) {
        break;
      }

      const transactionId = String(action.payload.transactionID);

      const shippedProducts = product_data.shipped.flatMap(
        packet => packet.products
      );

      draft[transactionId] = {
        delivery_date: payment_data.delivery_date,
        transaction_no: transaction_no,
        items: makeRateItem(shippedProducts),
      };

      break;
    }
    case getType(postRatingActions.success): {
      const transactionId = String(action.payload.transactionID);

      delete draft[transactionId];

      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
