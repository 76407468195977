import { FC } from 'react';
import styled from 'styled-components/native';

import { NavigationParams } from '../../../types/NavigationParams';
import { getIsTW } from '../../../utils/countryInfo';
import AppleLoginButton from '../AppleLoginButton';
import FBLoginButton from '../FBLoginButton';
import LineLoginButton from '../LineLoginButton';

interface Props {
  [NavigationParams.BackToPageKey]: string;
  [NavigationParams.BackWithKey]?: string;
  [NavigationParams.EncodedPageParams]?: string;
  inviteCode?: string;
}

const ThirdPartySection: FC<Props> = props => {
  return (
    <S.Container>
      <FBLoginButton
        {...{
          [NavigationParams.EncodedPageParams]:
            props[NavigationParams.EncodedPageParams],
          [NavigationParams.BackToPageKey]:
            props[NavigationParams.BackToPageKey],
          [NavigationParams.BackWithKey]: props[NavigationParams.BackWithKey],
          inviteCode: props.inviteCode,
        }}
      />
      {getIsTW() && (
        <LineLoginButton
          {...{
            [NavigationParams.EncodedPageParams]:
              props[NavigationParams.EncodedPageParams],
            [NavigationParams.BackToPageKey]:
              props[NavigationParams.BackToPageKey],
            inviteCode: props.inviteCode,
          }}
        />
      )}
      <AppleLoginButton inviteCode={props.inviteCode} />
    </S.Container>
  );
};

export default ThirdPartySection;

const S = {
  Container: styled.View`
    flex-direction: row;
    margin: 20px 0 10px;
  `,
};
