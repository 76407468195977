// NavLeft 的 back 定義有點多元
// * 如果是有連結的狀況下，web 在流程上不一定適合使用 history.back（目前使用 Actions.pop 的狀況）
// * backToPageKey：原因同上 + web 不容易知道 back 幾個，因此需搭配 replace 或直接 到達頁面
// * 搭配 dialog 的時候，closeDialog 和 backToPageKey，可能不是同一個頁面，如使用信用卡付款(搭配WebViewPage)在關閉的時候，跳出 dialog 的行為
// 基於以上原因，這部分的定義會需要觀察、討論可能會重新定義
// 與此相關有
// * src/boot/navbarFactory 內 navLeft 相關

import { Component } from 'react';
import { View } from 'react-native';

import IconNavLeft from '../assets/svg/IconNavLeft';
import { warrior } from '../constants/color';
import { globalNormalDialogProxy } from '../elements/Dialog/GlobalNormalDialog';
import Touchable from '../elements/Touchable';
import { translate } from '../i18n';
import { NavigationParams } from '../types/NavigationParams';
import * as navigationService from '../utils/navigationService';
import AndroidBack from './nav/AndroidBack';
import styles from './styles';

export interface Props {
  [NavigationParams.BackToPageKey]?: string;
  backToPageParams?: { [key: string]: any };
  backWithoutLocationChange: boolean;
  contentText?: string;
  fillColor?: string;
  hasDialog: boolean;
  icon?: JSX.Element;
  isFocused?: boolean | undefined;
  negativeActions?: Function;
  negativeText?: string;
  positiveAction?: Function;
  positiveText?: string;
  screen: string;
  screenKey: string;
}

interface State {
  beforeLeft: () => boolean;
  customBack?: () => void;
}

export class NavLeft extends Component<Props, State> {
  static defaultProps = {
    backToPageParams: {},
    backWithoutLocationChange: false,
    get contentText() {
      return translate('nav-left-confirm');
    },
    hasDialog: false,
    get negativeText() {
      return translate('cancel');
    },
    get positiveText() {
      return translate('confirm');
    },
    screen: '',
    screenKey: '',
  };

  state = {
    beforeLeft: () => true,
  } as State;

  componentDidMount() {
    if (this.props.isFocused && !this.observableSource) {
      this.observableSource = navigationService.navbarSubject$.subscribe(
        this.subscribeNavbarSubject
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { isFocused } = this.props;

    if (isFocused !== prevProps.isFocused) {
      if (this.observableSource) {
        this.unsubscribeNavbarSubject();
      }
      if (isFocused) {
        this.observableSource = navigationService.navbarSubject$.subscribe(
          this.subscribeNavbarSubject
        );
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribeNavbarSubject();
  }

  observableSource: any = null;

  subscribeNavbarSubject = info => {
    const { screenKey } = this.props;
    const { type, beforeLeft, customBack } = info.data;
    const newState = {} as State;

    if (info.from === `${screenKey}-component` && type === 'navleft') {
      const keys = Object.keys(info.data);

      if (keys.includes('beforeLeft')) {
        newState.beforeLeft = beforeLeft;
      }
      if (keys.includes('customBack')) {
        newState.customBack = customBack;
      }
    }

    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  };

  unsubscribeNavbarSubject = () => {
    if (this.observableSource) {
      this.observableSource.unsubscribe();
      this.observableSource = null;
    }
  };

  openDialog = () => {
    const { contentText, positiveText, negativeText } = this.props;

    globalNormalDialogProxy.open({
      contentText,
      positiveText: positiveText!,
      negativeText,
      handleDialogNegativeAction: this.closeDialogAndPop,
    });
  };

  closeDialogAndPop = () => {
    setTimeout(() => {
      this.navigateBack();
    }, 100);
  };

  navigateBack = () => {
    const backToPageKey = this.props[NavigationParams.BackToPageKey];

    if (backToPageKey !== undefined && backToPageKey !== '') {
      navigationService.goBack(backToPageKey);
    } else {
      navigationService.goBack();
    }
  };

  handleBack = () => {
    const { beforeLeft, customBack } = this.state;

    if (customBack) {
      customBack();

      return;
    }

    const callDialog = beforeLeft();

    if (this.props.hasDialog && callDialog) {
      this.openDialog();
    } else {
      this.navigateBack();
    }
  };

  render() {
    const { icon } = this.props;

    return (
      <AndroidBack handleAndroidBack={this.handleBack}>
        <Touchable
          style={styles.navBack}
          hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
          onPress={this.handleBack}
        >
          {icon ? (
            icon
          ) : (
            <View style={styles.navBackWrapper}>
              <IconNavLeft
                size={22}
                fillColor={this.props.fillColor || warrior}
              />
            </View>
          )}
        </Touchable>
      </AndroidBack>
    );
  }
}

export default NavLeft;
