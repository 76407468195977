import pickBy from 'lodash/pickBy';
import { withMappedNavigationParams } from 'react-navigation-props-mapper';

import IconCancel from '../../assets/svg/IconCancel';
import DevGeneral from '../../components/DeveloperTool';
import NetworkLogger from '../../components/DeveloperTool/networkLogger';
import TopRatedListPage from '../../components/TopRatedListPage';
import { warrior } from '../../constants/color';
import withDecodeSomeProps from '../../decorators/withDecodeSomeProps';
import withPrivateRoute from '../../decorators/withPrivateRoute';
import NavRightQuestionButton from '../../elements/NavRightQuestionButton';
import NavRightSaveButton from '../../elements/NavRightSaveButton';
import { translate } from '../../i18n';
import AboutPage from '../../pages/AboutPage';
import AnnouncementPage from '../../pages/AnnouncementPage';
import BestItemPage from '../../pages/BestItemPage';
import BestSellerPage from '../../pages/BestSellerPage';
import BrowseHistoryListPage from '../../pages/BrowseHistoryListPage';
import CampaignThemePage from '../../pages/CampaignThemePage';
import CartPage from '../../pages/CartPage';
import CategoriesLabelPage from '../../pages/CategoriesLabelPage';
import CategoriesPage from '../../pages/CategoriesPage';
import CheckoutPage from '../../pages/CheckoutPage';
import ChooseCreditCardPage from '../../pages/ChooseCreditCardPage';
import CouponListPage from '../../pages/CouponListPage';
import CreateCreditCardPage from '../../pages/CreateCreditCardPage';
import EventPage from '../../pages/EventPage';
import FaqDetailPage from '../../pages/FaqDetailPage';
import FaqPage from '../../pages/FaqPage';
import HomePage from '../../pages/HomePage';
import IconsPage from '../../pages/IconsPage';
import ItemComboPage from '../../pages/ItemComboPage';
import ItemPage from '../../pages/ItemPage';
import ItemPageRecommends from '../../pages/ItemPageRecommends';
import ItemReviewsPage from '../../pages/ItemReviewsPage';
import LabelPage from '../../pages/LabelPage';
import ListPage from '../../pages/ListPage';
import LoginPage from '../../pages/LoginPage';
import LoveListPage from '../../pages/LoveListPage';
import MailboxDetailPage from '../../pages/MailboxDetailPage';
import MailboxPage from '../../pages/MailboxPage';
import MemberBasicInfoEditPage from '../../pages/MemberBasicInfoEditPage';
import MemberContactAuthPage from '../../pages/MemberContactAuthPage';
import MemberContactEditPage from '../../pages/MemberContactEditPage';
import MemberDeleteFirstPage from '../../pages/MemberDeleteFirstPage';
import MemberDeleteSecondPage from '../../pages/MemberDeleteSecondPage';
import MemberDeleteThirdPage from '../../pages/MemberDeleteThirdPage';
import MemberEditPage from '../../pages/MemberEditPage';
import MemberSettingsPage from '../../pages/MemberSettingsPage';
import MemberVerifyPhonePage from '../../pages/MemberVerifyPhonePage';
import MemberVerifySMSPage from '../../pages/MemberVerifySMSPage';
import MyPage from '../../pages/MyPage';
import NewArrivalsPage from '../../pages/NewArrivalsPage';
import NotFoundPage from '../../pages/NotFoundPage';
import NotificationsPage from '../../pages/NotificationsPage';
import OnboardingPage from '../../pages/OnboardingPage';
import OnSalePage from '../../pages/OnSalePage';
import PaymentPage from '../../pages/PaymentPage';
import ProductTagsPage from '../../pages/ProductTagsPage';
import QueriesPage from '../../pages/QueriesPage';
import ReceiptPage from '../../pages/ReceiptPage';
import ReceivingShopDetailPage from '../../pages/ReceivingShopDetailPage';
import RecipientDetailPage from '../../pages/RecipientDetailPage';
import RegisterPage from '../../pages/RegisterPage';
import ResetPasswordPage from '../../pages/ResetPasswordPage';
import SearchResultPage from '../../pages/SearchResultPage';
import SetNewPwdPage from '../../pages/SetNewPwdPage';
import ShareFriendPage from '../../pages/ShareFriendPage';
import ShippingLogisticsPage from '../../pages/ShippingLogisticsPage';
import SupportFaqPage from '../../pages/SupportFaqPage';
import SupportPage from '../../pages/SupportPage';
import TopSalesListPage from '../../pages/TopSalesListPage';
import TransactionDetailPage from '../../pages/TransactionDetailPage';
import TransactionListPage from '../../pages/TransactionListPage';
import TransactionRatesCreatePage from '../../pages/TransactionRatesCreatePage';
import TransactionResultPage from '../../pages/TransactionResultPage';
import VendorPage from '../../pages/VendorPage';
import WebViewPage from '../../pages/WebViewPage';
import WelcomeLoginPage from '../../pages/WelcomeLoginPage';
import { NavigationParams } from '../../types/NavigationParams';
import Config from '../../utils/configs';
import routeUtils, { routesConfig } from '../../utils/routes';
import navbarCreator, { NavbarType } from '../navbarFactory';
import { isApp } from '../utils';
import * as webviewNavbarUtils from './webviewNavbarUtils';

const withStaticProps = (PageComponent, otherParams: any = {}) => {
  return (props: any = {}) => <PageComponent {...otherParams} {...props} />;
};

const routeConfigMapAll = {
  Home: {
    screen: HomePage,
    path: routesConfig.Home,
    navigationOptions: () => ({
      header: null,
      animationEnabled: false,
      gestureEnabled: false,
    }),
  },
  Categories: {
    screen: CategoriesPage,
    path: routesConfig.Categories,
    navigationOptions: ({ navigation }) =>
      isApp
        ? navbarCreator(NavbarType.SearchOrLinearGradient, {
            enableHelmet: false,
            navigation,
            screen: 'Categories',
            leftOpts: {
              hidden: true,
            },
            animationEnabled: false,
            gestureEnabled: false,
          })
        : {
            header: null,
            animationEnabled: false,
            gestureEnabled: false,
          },
  },
  Cart: {
    screen: CartPage,
    path: routesConfig.Cart,
    navigationOptions: ({ navigation }) =>
      isApp
        ? navbarCreator(NavbarType.DefaultCustom, {
            screen: 'CartPage',
            navigation,
            headerTitle: translate('cart'),
            withoutNavLeft: true,
            animationEnabled: false,
            gestureEnabled: false,
          })
        : {
            header: null,
            animationEnabled: false,
            gestureEnabled: false,
          },
  },
  Me: {
    screen: MyPage,
    path: routesConfig.Me,
    navigationOptions: () => ({
      header: null,
      animationEnabled: false,
      gestureEnabled: false,
    }),
  },
  AnnouncementPage: {
    screen: withMappedNavigationParams()(AnnouncementPage),
    navigationOptions: ({ navigation }) => {
      const headerTitle =
        navigation?.state?.params?.title || translate('loading');

      return navbarCreator(NavbarType.LinearGradient, {
        navigation,
        headerTitle,
        screen: 'AnnouncementPage',
      });
    },
    path: routesConfig.AnnouncementPage,
  },
  ListPage: {
    screen: withMappedNavigationParams()(ListPage),
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.SearchOrLinearGradient, {
        navigation,
        screen: 'ListPage',
        enableHelmet: false,
      }),
    path: routesConfig.ListPage,
  },
  TopSalesListPage: {
    screen: TopSalesListPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('sales-charts'),
        description: translate('sales-charts-desc'),
        navigation,
        screen: 'TopSalesListPage',
      }),
    path: routesConfig.TopSalesListPage,
  },
  TopRatedListPage: {
    screen: TopRatedListPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('top-rated'),
        description: translate('top-rated-og-desc'),
        enableShareBtn: true,
        navigation,
        screen: 'TopRatedListPage',
      }),
    path: routesConfig.TopRatedListPage,
  },
  SearchResultPage: {
    screen: withMappedNavigationParams()(SearchResultPage),
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.SearchOrLinearGradient, {
        navigation,
        screen: 'SearchResultPage',
      }),
    path: routesConfig.SearchResultPage,
  },
  LabelPage: {
    screen: withMappedNavigationParams()(LabelPage),
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};
      const headerTitle = params.title || translate('loading');

      return navbarCreator(NavbarType.LinearGradient, {
        enableShareBtn: true,
        headerTitle,
        navigation,
        screen: 'LabelPage',
      });
    },
    path: routesConfig.LabelPage,
  },
  SearchResultPageTopId: {
    screen: withMappedNavigationParams()(SearchResultPage),
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.SearchOrLinearGradient, {
        navigation,
        screen: 'SearchResultPageTopId',
      }),
    path: routesConfig.SearchResultPageTopId,
  },
  BestSellerPage: {
    screen: withMappedNavigationParams()(BestSellerPage),
    navigationOptions: ({}) => {
      return { header: null };
    },
    path: routesConfig.BestSellerPage,
  },
  BestItemPage: {
    screen: withMappedNavigationParams()(BestItemPage),
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};
      const headerTitle = params.title || translate('loading');

      return navbarCreator(NavbarType.LinearGradient, {
        enableHelmet: false,
        headerTitle,
        navigation,
        screen: 'BestItemPage',
      });
    },
    path: routesConfig.BestItemPage,
  },
  IconsPage: {
    screen: withMappedNavigationParams()(IconsPage),
    path: routesConfig.IconsPage,
    navigationOptions: () => ({
      header: null,
    }),
  },
  ItemPage: {
    screen: withMappedNavigationParams()(withDecodeSomeProps(ItemPage, ['id'])),
    path: routesConfig.ItemPage,
    navigationOptions: () => ({
      header: null,
    }),
  },
  ItemPageRecommends: {
    screen: withMappedNavigationParams()(
      withDecodeSomeProps(ItemPageRecommends, ['id'])
    ),
    path: routesConfig.ItemPageRecommends,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        enableShareBtn: true,
        screen: 'ItemPageRecommends',
        navigation,
        headerTitle: translate('itemRecommend'),
      }),
  },
  ItemComboPage: {
    screen: withMappedNavigationParams()(
      withDecodeSomeProps(ItemComboPage, ['id'])
    ),
    path: routesConfig.ItemComboPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'ItemComboPage',
        navigation,
        headerTitle: translate('item-combo-title'),
      }),
  },
  ItemReviewsPage: {
    screen: withMappedNavigationParams()(
      withDecodeSomeProps(ItemReviewsPage, ['id'])
    ),
    path: routesConfig.ItemReviewsPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'ItemReviewsPage',
        navigation,
        headerTitle: translate('item-reviews-title'),
      }),
  },
  CartPage: {
    screen: CartPage,
    path: routesConfig.CartPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'CartPage',
        navigation,
        headerTitle: translate('cart'),
      }),
  },
  CheckoutPage: {
    screen: CheckoutPage,
    path: routesConfig.CheckoutPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'CheckoutPage',
        navigation,
        headerTitle: translate('checkout-step-1'),
        gesturesEnabled: false,
      }),
  },
  CategoriesLabelPage: {
    screen: withMappedNavigationParams()(CategoriesLabelPage),
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};
      const headerTitle = params.title || translate('loading');

      return navbarCreator(NavbarType.LinearGradient, {
        enableShareBtn: true,
        headerTitle,
        navigation,
        screen: 'CategoriesLabelPage',
      });
    },
    path: routesConfig.CategoriesLabelPage,
  },
  PaymentPage: {
    screen: PaymentPage,
    path: routesConfig.PaymentPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'PaymentPage',
        navigation,
        headerTitle: translate('checkout-step-2'),
        gesturesEnabled: false,
      }),
  },
  ChooseCreditCardPage: {
    screen: withMappedNavigationParams()(ChooseCreditCardPage),
    path: routesConfig.ChooseCreditCardPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'ChooseCreditCardPage',
        navigation,
        headerTitle: translate('chooseCreditCard'),
        rightOpts: {
          CustomNavRight: NavRightSaveButton,
          text: translate('finish'),
        },
        gesturesEnabled: false,
      }),
  },
  CreateCreditCardPage: {
    screen: withMappedNavigationParams()(CreateCreditCardPage),
    path: routesConfig.CreateCreditCardPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'CreateCreditCardPage',
        navigation,
        headerTitle: translate('create-credit-card'),
        leftOpts: {
          icon: <IconCancel size={20} fillColor={warrior} />,
        },
        rightOpts: {
          CustomNavRight: NavRightSaveButton,
          text: translate('finish'),
        },
        gesturesEnabled: false,
      }),
  },
  RecipientDetailPage: {
    screen: withMappedNavigationParams()(RecipientDetailPage),
    path: routesConfig.RecipientDetailPage,
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};

      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'RecipientDetailPage',
        navigation,
        leftOpts: {
          hasDialog: true,
          contentText: translate('leaveMsgInDialog'),
          positiveText: translate('continueEdit'),
          negativeText: translate('breakEdit'),
        },
        rightOpts: {
          CustomNavRight: NavRightSaveButton,
        },
        headerTitle: params?.title || translate('create-home-delivery-info'),
        gesturesEnabled: false,
      });
    },
  },
  ReceiptPage: {
    screen: withMappedNavigationParams()(ReceiptPage),
    path: routesConfig.ReceiptPage,
    navigationOptions: ({ navigation }) => {
      const isCreate = navigation?.state?.params?.isCreate || false;

      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'ReceiptPage',
        navigation,
        leftOpts: {
          hasDialog: true,
          contentText: translate('leaveMsgInDialog'),
          positiveText: translate('continueEdit'),
          negativeText: translate('breakEdit'),
          ...(isCreate
            ? { icon: <IconCancel size={20} fillColor={warrior} /> }
            : {}),
        },
        rightOpts: {
          CustomNavRight: NavRightSaveButton,
        },
        headerTitle: isCreate
          ? translate('create-receipt-info')
          : translate('edit-receipt-info'),
        gesturesEnabled: false,
      });
    },
  },
  ReceivingShopDetailPage: {
    screen: ReceivingShopDetailPage,
    path: routesConfig.ReceivingShopDetailPage,
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};

      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'ReceivingShopDetailPage',
        navigation,
        leftOpts: {
          hasDialog: true,
          contentText: translate('leaveMsgInDialog'),
          positiveText: translate('continueEdit'),
          negativeText: translate('breakEdit'),
        },
        rightOpts: {
          CustomNavRight: NavRightSaveButton,
        },
        headerTitle: params?.title || translate('create-cvs-delivery-info'),
        gesturesEnabled: false,
      });
    },
  },
  MemberSettingsPage: {
    screen: MemberSettingsPage,
    path: routesConfig.MemberSettingsPage,
    navigationOptions: () => {
      return navbarCreator(NavbarType.DefaultHeader, {
        screen: 'MemberSettingsPage',
        headerTitle: translate('member-settings'),
      });
    },
  },
  MemberVerifyPhonePage: {
    screen: MemberVerifyPhonePage,
    path: routesConfig.MemberVerifyPhonePage,
    navigationOptions: ({ navigation }) => {
      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'MemberVerifyPhonePage',
        navigation,
        leftOpts: {
          icon: <IconCancel size={20} fillColor={warrior} />,
          hasDialog: true,
          contentText: translate('verify-quit-confirm'),
          positiveText: translate('verify-quit-continue'),
          negativeText: translate('verify-quit'),
        },
        headerTitle: translate('member-identity-verify'),
        gesturesEnabled: false,
      });
    },
  },
  MemberVerifySMSPage: {
    screen: MemberVerifySMSPage,
    path: routesConfig.MemberVerifySMSPage,
    navigationOptions: ({ navigation }) => {
      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'MemberVerifySMSPage',
        navigation,
        headerTitle: translate('member-identity-verify'),
        gesturesEnabled: false,
      });
    },
  },
  NewArrivalsPage: {
    screen: NewArrivalsPage,
    path: routesConfig.NewArrivalsPage,
    navigationOptions: () => {
      return navbarCreator(NavbarType.LinearGradient, {
        screen: 'NewArrivalsPage',
        headerTitle: translate('new-arrivals'),
        description: translate('new-arrivals-desc'),
        headerTitleImage: require('../../assets/png/img_newarrivals_logotype.png'),
        shareOpts: {
          leaderMsg: translate('new-arrivals-share-message'),
          pathParams: {
            openExternalBrowser: 1,
          },
        },
      });
    },
  },
  TransactionListPage: {
    screen: withPrivateRoute(TransactionListPage),
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('my-order'),
        navigation,
        screen: 'TransactionListPage',
        shouldLogin: true,
      }),
    path: routesConfig.TransactionListPage,
  },
  CouponListPage: {
    screen: withPrivateRoute(CouponListPage),
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('my-coupon'),
        navigation,
        screen: 'CouponListPage',
        rightOpts: {
          CustomNavRight: NavRightQuestionButton,
        },
        shouldLogin: true,
      }),
    path: routesConfig.CouponListPage,
  },
  BrowseHistoryListPage: {
    screen: BrowseHistoryListPage,
    path: routesConfig.BrowseHistoryListPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('my-browse-history'),
        navigation,
        screen: 'BrowseHistoryListPage',
      }),
  },
  TransactionResultPage: {
    screen: withMappedNavigationParams()(
      withPrivateRoute(TransactionResultPage)
    ),
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultHeader, {
        screen: 'TransactionResultPage',
        navigation,
        headerTitle: translate('successOrder'),
        leftOpts: {
          [NavigationParams.BackToPageKey]: 'Cart',
        },
        gesturesEnabled: false,
        shouldLogin: true,
      }),
    path: routesConfig.TransactionResultPage,
  },
  TransactionDetailPage: {
    screen: withMappedNavigationParams()(
      withPrivateRoute(TransactionDetailPage)
    ),
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};
      const headerTitle = params.transactionNo
        ? `${translate('orderDetail-transaction-no')} : ${params.transactionNo}`
        : translate('loading');

      return navbarCreator(NavbarType.LinearGradient, {
        headerTitle,
        navigation,
        screen: 'TransactionDetailPage',
        shouldLogin: true,
      });
    },
    path: routesConfig.TransactionDetailPage,
  },
  TransactionRatesCreatePage: {
    screen: withMappedNavigationParams()(TransactionRatesCreatePage),
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'TransactionRatesCreatePage',
        navigation,
        leftOpts: {
          hasDialog: true,
          contentText: translate('rate-leave-rating-dialog-msg'),
          positiveText: translate('rate-continue-rating'),
          negativeText: translate('rate-break-rating'),
        },
        headerTitle: translate('rate-transaction'),
        gestureEnabled: false,
      }),
    path: routesConfig.TransactionRatesCreatePage,
  },
  ShippingLogisticsPage: {
    screen: withMappedNavigationParams()(ShippingLogisticsPage),
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('shippingLogistics'),
        navigation,
        screen: 'ShippingLogisticsPage',
      }),
    path: routesConfig.ShippingLogisticsPage,
  },
  LoveListPage: {
    screen: LoveListPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('my-collection'),
        navigation,
        screen: 'LoveListPage',
      }),
    path: routesConfig.LoveListPage,
  },
  LoginPage: {
    screen: withMappedNavigationParams()(LoginPage),
    path: routesConfig.LoginPage,
    navigationOptions: () => ({
      header: null,
    }),
  },
  RegisterPage: {
    screen: withMappedNavigationParams()(RegisterPage),
    path: routesConfig.RegisterPage,
    navigationOptions: () => ({
      header: null,
    }),
  },
  WebViewPage: {
    screen: withMappedNavigationParams()(WebViewPage),
    path: routesConfig.WebViewPage,
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};

      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'WebViewPage',
        navigation,
        headerTitle: params?.title || '',
        leftOpts: webviewNavbarUtils.createNavLeftOpts(params),
        gesturesEnabled: false,
      });
    },
  },
  ShoppingWebView: {
    screen: withMappedNavigationParams()(WebViewPage),
    path: routesConfig.ShoppingWebView,
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};

      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'ShoppingWebView',
        navigation,
        headerTitle: params?.title || '',
        leftOpts: webviewNavbarUtils.createNavLeftOpts(params),
        gesturesEnabled: false,
      });
    },
  },
  MemberEditPage: {
    screen: withMappedNavigationParams()(withPrivateRoute(MemberEditPage)),
    path: routesConfig.MemberEditPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        screen: 'MemberEditPage',
        navigation,
        headerTitle: translate('member-profile'),
        shouldLogin: true,
      }),
  },
  MemberContactAuthPage: {
    screen: withMappedNavigationParams()(MemberContactAuthPage),
    path: routesConfig.MemberContactAuthPage,
    navigationOptions: ({ navigation }) => {
      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'MemberContactAuthPage',
        headerTitle: translate('member-contact-edit'),
        navigation,
        gesturesEnabled: false,
      });
    },
  },
  MemberBasicInfoEditPage: {
    screen: MemberBasicInfoEditPage,
    path: routesConfig.MemberBasicInfoEditPage,
    navigationOptions: ({ navigation }) => {
      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'MemberBasicInfoEditPage',
        headerTitle: translate('member-info-edit'),
        navigation,
        leftOpts: {
          hasDialog: true,
          icon: <IconCancel size={20} fillColor={warrior} />,
          contentText: translate('leaveMsgInDialog'),
          positiveText: translate('continueEdit'),
          negativeText: translate('breakEdit'),
        },
        rightOpts: {
          CustomNavRight: NavRightSaveButton,
        },
        gesturesEnabled: false,
      });
    },
  },
  MemberContactEditPage: {
    screen: MemberContactEditPage,
    path: routesConfig.MemberContactEditPage,
    navigationOptions: ({ navigation }) => {
      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'MemberContactEditPage',
        navigation,
        leftOpts: {
          hasDialog: true,
          icon: <IconCancel size={20} fillColor={warrior} />,
          contentText: translate('leaveMsgInDialog'),
          positiveText: translate('continueEdit'),
          negativeText: translate('breakEdit'),
        },
        rightOpts: {
          CustomNavRight: NavRightSaveButton,
        },
        headerTitle: translate('member-contact-edit'),
        gesturesEnabled: false,
      });
    },
  },
  VcoinPage: {
    screen: withMappedNavigationParams()(
      withStaticProps(withPrivateRoute(WebViewPage), {
        needCookie: true,
        screenViewName: 'member_vcoin',
        url: `${Config.REACT_APP_WEB_VIEW_URL}/member/vcoin?no_header=1&no_footer=1`,
      })
    ),
    path: routesConfig.VcoinPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        screen: 'VcoinPage',
        navigation,
        headerTitle: translate('vcoinPage'),
        gesturesEnabled: false,
        shouldLogin: true,
      }),
  },
  MailboxPage: {
    screen: withMappedNavigationParams()(withPrivateRoute(MailboxPage)),
    path: routesConfig.MailboxPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        screen: 'MailboxPage',
        navigation,
        headerTitle: translate('mailboxPage'),
        leftOpts: {
          [NavigationParams.BackToPageKey]:
            navigation?.state?.params?.[NavigationParams.BackToPageKey],
        },
        gesturesEnabled: false,
        shouldLogin: true,
      }),
  },
  MailboxDetailPage: {
    screen: withMappedNavigationParams()(withPrivateRoute(MailboxDetailPage)),
    path: routesConfig.MailboxDetailPage,
    navigationOptions: ({ navigation }) => {
      const headerTitle =
        navigation?.state?.params?.title || translate('loading');

      return navbarCreator(NavbarType.LinearGradient, {
        screen: 'MailboxDetailPage',
        navigation,
        leftOpts: {
          hasDialog: true,
          contentText: translate('mailbox-detail-quit-confirm'),
          positiveText: translate('continueEdit'),
          negativeText: translate('quit'),
        },
        headerTitle,
        shouldLogin: true,
      });
    },
  },
  EventPage: {
    screen: withMappedNavigationParams()(EventPage),
    path: routesConfig.EventPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('eventPage'),
        navigation,
        screen: 'EventPage',
      }),
  },
  RefundPage: {
    screen: withMappedNavigationParams()(
      withStaticProps(withPrivateRoute(WebViewPage), {
        needCookie: true,
        url: `${Config.REACT_APP_WEB_VIEW_URL}/member/refund_request?no_header=1&no_footer=1`,
        screenViewName: 'return_request',
      })
    ),
    path: routesConfig.RefundPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultHeader, {
        screen: 'RefundPage',
        navigation,
        headerTitle: translate('refundPage'),
        gesturesEnabled: false,
        shouldLogin: true,
      }),
  },
  SupportPage: {
    screen: withMappedNavigationParams()(withPrivateRoute(SupportPage)),
    path: routesConfig.SupportPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        screen: 'SupportPage',
        navigation,
        leftOpts: {
          hasDialog: true,
          contentText: translate('mailbox-detail-quit-confirm'),
          positiveText: translate('continueEdit'),
          negativeText: translate('quit'),
        },
        headerTitle: translate('supportPage'),
        gesturesEnabled: false,
        shouldLogin: true,
      }),
  },
  SupportFaqPage: {
    screen: withMappedNavigationParams()(withPrivateRoute(SupportFaqPage)),
    path: routesConfig.SupportFaqPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        screen: 'SupportFaqPage',
        navigation,
        headerTitle: translate('supportPage'),
        gesturesEnabled: false,
        shouldLogin: true,
      }),
  },
  FaqPage: {
    screen: withMappedNavigationParams()(FaqPage),
    path: routesConfig.FaqPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        screen: 'FaqPage',
        navigation,
        headerTitle: translate('faqPage'),
        gesturesEnabled: false,
      }),
  },
  FaqDetailPage: {
    screen: withMappedNavigationParams()(FaqDetailPage),
    path: routesConfig.FaqDetailPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        screen: 'FaqDetailPage',
        navigation,
        headerTitle: translate('faqPage'),
        gesturesEnabled: false,
      }),
  },
  VcoinToCashPage: {
    screen: withMappedNavigationParams()(
      withStaticProps(withPrivateRoute(WebViewPage), {
        needCookie: true,
        url: `${Config.REACT_APP_WEB_VIEW_URL}/member/vcoin_to_cash?no_header=1&no_footer=1`,
        screenViewName: 'vcoin_to_cash',
      })
    ),
    path: routesConfig.VcoinToCashPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        screen: 'VcoinToCashPage',
        navigation,
        headerTitle: translate('vcoin-to-cash'),
        gesturesEnabled: false,
        shouldLogin: true,
      }),
  },
  AboutLegal: {
    screen: withMappedNavigationParams()(
      withStaticProps(WebViewPage, {
        needCookie: false,
        url: `https://${Config.MHOST}/aboutus/legal`,
        screenViewName: 'about_legal',
        [NavigationParams.BackToPageKey]: 'RegisterPage',
      })
    ),
    path: routesConfig.AboutLegal,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'AboutLegal',
        navigation,
        headerTitle: translate('memberTreaty'),
        gesturesEnabled: false,
      }),
  },
  AboutPrivacy: {
    screen: withMappedNavigationParams()(
      withStaticProps(WebViewPage, {
        needCookie: false,
        url: `https://${Config.MHOST}/aboutus/privacy`,
        screenViewName: 'about_privacy',
        [NavigationParams.BackToPageKey]: 'RegisterPage',
      })
    ),
    path: routesConfig.AboutPrivacy,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'AboutPrivacy',
        navigation,
        headerTitle: translate('privacyPolicy'),
        gesturesEnabled: false,
      }),
  },
  WelcomeLoginPage: {
    screen: withMappedNavigationParams()(WelcomeLoginPage),
    path: routesConfig.WelcomeLoginPage,
    navigationOptions: () => ({
      header: null,
    }),
  },
  AboutPage: {
    screen: AboutPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'AboutPage',
        navigation,
        headerTitle: translate('about'),
      }),
    path: routesConfig.AboutPage,
  },
  NotFoundPage: {
    screen: NotFoundPage,
    path: routesConfig.NotFoundPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'NotFoundPage',
        navigation,
        headerTitle: translate('not-found-page'),
      }),
  },
  NotificationsPage: {
    screen: NotificationsPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('notifications-page'),
        navigation,
        screen: 'NotificationsPage',
      }),
    path: routesConfig.NotificationsPage,
  },
  VendorPage: {
    screen: withMappedNavigationParams()(VendorPage),
    path: routesConfig.VendorPage,
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};
      const headerTitle = params.title || translate('loading');

      return navbarCreator(NavbarType.LinearGradient, {
        enableShareBtn: true,
        headerTitle,
        navigation,
        screen: 'VendorPage',
      });
    },
  },
  OnSalePage: {
    screen: withMappedNavigationParams()(OnSalePage),
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('onsale-page'),
        navigation,
        screen: 'OnSalePage',
        shareOpts: {
          leaderMsg: translate('on-sale-share-message'),
          pathParams: {
            openExternalBrowser: 1,
          },
        },
      }),
    path: routesConfig.OnSalePage,
  },
  OnboardingPage: {
    screen: withMappedNavigationParams()(OnboardingPage),
    navigationOptions: () => ({
      header: null,
    }),
    path: routesConfig.OnboardingPage,
  },
  QueriesPage: {
    screen: QueriesPage,
    path: routesConfig.QueriesPage,
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};
      const headerTitle = params.title || translate('pick-for-you');

      return navbarCreator(NavbarType.LinearGradient, {
        screen: 'QueriesPage',
        headerTitle,
        enableShareBtn: true,
      });
    },
  },
  DeveloperTool: {
    screen: DevGeneral,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'DeveloperTool',
        navigation,
        headerTitle: 'DevGeneral',
      }),
  },
  MemberDeleteFirstPage: {
    screen: MemberDeleteFirstPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'MemberDeleteFirstPage',
        navigation,
        headerTitle: translate('member-delete'),
        gesturesEnabled: false,
      }),
  },
  MemberDeleteSecondPage: {
    screen: MemberDeleteSecondPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'MemberDeleteSecondPage',
        navigation,
        headerTitle: translate('member-delete'),
        gesturesEnabled: false,
      }),
  },
  MemberDeleteThirdPage: {
    screen: MemberDeleteThirdPage,
    navigationOptions: ({ navigation }) =>
      navbarCreator(NavbarType.DefaultCustom, {
        screen: 'MemberDeleteThirdPage',
        navigation,
        headerTitle: translate('member-delete'),
        gesturesEnabled: false,
      }),
  },
  NetworkLogger: {
    screen: () => <NetworkLogger theme="dark" />,
  },
  CampaignThemePage: {
    screen: withMappedNavigationParams()(CampaignThemePage),
    path: routesConfig.CampaignThemePage,
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};
      const headerTitle = params?.title || translate('loading');

      return navbarCreator(NavbarType.LinearGradient, {
        gesturesEnabled: false,
        headerTitle,
        leftOpts: webviewNavbarUtils.createNavLeftOpts(params),
        navigation,
        screen: 'CampaignThemePage',
        shareOpts: {
          leaderMsg: translate('campaign-share-message'),
          pathParams: {
            openExternalBrowser: 1,
          },
        },
      });
    },
  },
  SetNewPwdPage: {
    screen: withMappedNavigationParams()(SetNewPwdPage),
    path: routesConfig.SetNewPwdPage,
    navigationOptions: () => ({
      header: null,
    }),
  },
  ResetPasswordPage: {
    screen: withMappedNavigationParams()(ResetPasswordPage),
    path: routesConfig.ResetPasswordPage,
    navigationOptions: () => ({
      header: null,
    }),
  },
  ProductTagsPage: {
    screen: withMappedNavigationParams()(ProductTagsPage),
    path: routesConfig.ProductTagsPage,
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};
      const headerTitle = params?.tag || translate('loading');

      return navbarCreator(NavbarType.LinearGradient, {
        headerTitle,
        navigation,
        screen: 'ProductTagsPage',
        enableShareBtn: true,
      });
    },
  },
  BlogPage: {
    screen: withMappedNavigationParams()(props => {
      const Path = props.path ? decodeURIComponent(props.path) : '';

      return withStaticProps(WebViewPage, {
        needCookie: false,
        url: `https://blog.verybuy.cc${Path}`,
        screenViewName: 'blog',
      })(props);
    }),
    path: routesConfig.BlogPage,
    navigationOptions: ({ navigation }) => {
      const params = navigation?.state?.params || {};

      return navbarCreator(NavbarType.DefaultCustom, {
        screen: 'BlogPage',
        navigation,
        leftOpts: webviewNavbarUtils.createNavLeftOpts(params),
        gesturesEnabled: false,
      });
    },
  },
  ShareFriendPage: {
    screen: withMappedNavigationParams()(withPrivateRoute(ShareFriendPage)),
    path: routesConfig.ShareFriendPage,
    navigationOptions: ({ navigation }) => {
      return navbarCreator(NavbarType.LinearGradient, {
        headerTitle: translate('shareFriendPage'),
        screen: 'ShareFriendPage',
        navigation,
        gesturesEnabled: false,
      });
    },
  },
};

export const routeConfigMap = pickBy(routeConfigMapAll, (_, routeName) => {
  return routeUtils.isSupportRoute(routeName);
});
