import { translate } from '../../i18n';
import { openLoginOrRegisterFailDialog } from '../../utils/memberHelpers';

export function openFailDialog(lightboxName: string, content: string) {
  openLoginOrRegisterFailDialog({
    contentText: translate('login-fail-default-desc'),
    lightboxName,
    lightboxContent: content,
  });
}
