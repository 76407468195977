import { AxiosResponse } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { LoginOrRegisterMethod } from '../../constants/LoginOrRegisterMethod';
import { MemberLoginByEmailResponse } from '../../types/Member';

export interface LoginByEmailPayload {
  account: string;
  password: string;
  fcm_token: string;
  success: Function;
  failure: Function;
  loginMethod: LoginOrRegisterMethod;
  recaptcha_token: string;
}

export interface LoginByEmailSuccessPayload {
  result: AxiosResponse<MemberLoginByEmailResponse['data']>;
  account: string;
  logEvent: AnalyticsEvent;
  method: LoginOrRegisterMethod;
}

export interface LoginByEmailFailPayload {
  error: Error;
  account: string;
  logEvent: AnalyticsEvent;
  method: LoginOrRegisterMethod;
}

export const loginByEmail = createAsyncAction(
  'verybuy/LOGIN_BY_EMAIL',
  'verybuy/LOGIN_BY_EMAIL_SUCCESS',
  'verybuy/LOGIN_BY_EMAIL_FAIL'
)<LoginByEmailPayload, LoginByEmailSuccessPayload, LoginByEmailFailPayload>();
