import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getProductReviewDetailActions } from '../actions/getProductReviewDetailActions';
import { getProductReviewDetail } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getProductReviewDetailActions.success,
  failure: getProductReviewDetailActions.failure,
};

type RequestActionType = ActionType<
  typeof getProductReviewDetailActions.request
>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getProductReviewDetailEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getProductReviewDetailActions.request)),
    switchMap((action: RequestActionType) => {
      const { dispatch, id } = action.payload;

      dispatch(action);

      return getProductReviewDetail({ id })
        .then(response => {
          const successAction = getProductReviewDetailActions.success({
            data: response.data,
          });

          dispatch(successAction);

          return successAction;
        })
        .catch((error: AxiosError) => {
          const failureAction = getProductReviewDetailActions.failure({
            error,
          });

          dispatch(failureAction);

          return failureAction;
        });
    })
  );
};

export default getProductReviewDetailEpic;
