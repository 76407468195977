import { connect } from 'react-redux';

import { loginByApple } from '../../../redux/actions/loginByApple';
import AppleLogin from './AppleLogin';

const mapDispatchToProps = {
  loginByApple: loginByApple.request,
};

export default connect(null, mapDispatchToProps)(AppleLogin);
