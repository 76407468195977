import styled from 'styled-components/native';

import { neutralLight } from '../../../../../constants/color';

export default {
  Separator: styled.View`
    margin: 12px 16px 0px 16px;
    height: 1px;
    background-color: ${neutralLight}66;
  `,
};
