/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */

import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { TransactionDetailData } from '../../types/TransactionDetail';

export interface TransactionDetailPayload {
  transactionID: number;
  loadingType?: ListLoadingTypes;
  onSuccess?: Function;
  silent?: boolean;
}

export interface TransactionDetailSuccessPayload {
  transactionID: number;
  data: TransactionDetailData;
  loadingType?: ListLoadingTypes;
}

export interface TransactionDetailFailPayload {
  transactionID: number;
  error: Error;
}

export const fetchTransactionDetail = createAsyncAction(
  'verybuy/TRANSACTION_DETAIL',
  'verybuy/TRANSACTION_DETAIL_SUCCESS',
  'verybuy/TRANSACTION_DETAIL_FAIL'
)<
  TransactionDetailPayload,
  TransactionDetailSuccessPayload,
  TransactionDetailFailPayload
>();
