import shuffle from 'lodash/shuffle';
import { ActionType, getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import * as arrayHandler from '../../utils/arrayHandler';
import { actionsList } from '../rootAction';

export interface InitialState {
  items: string[];
  loadingType: ListLoadingTypes;
  isFetching: boolean;
  hasMoreItems: boolean;
  randomFirstPageItems: string[];
  page: number;
  error?: Error;
}

type ReducerAction = ActionType<
  | typeof getTopRatedActions.request
  | typeof getTopRatedActions.success
  | typeof getTopRatedActions.failure
>;

const { getTopRatedActions } = actionsList;
const INITIAL_STATE: InitialState = {
  items: [],
  randomFirstPageItems: [],
  loadingType: 'new',
  isFetching: false,
  hasMoreItems: false,
  page: 1,
};

export default function (
  state = INITIAL_STATE,
  action: ReducerAction
): InitialState {
  switch (action.type) {
    case getType(getTopRatedActions.request): {
      const { loadingType, page } = action.payload;
      const isLoadMore = loadingType === 'loadMore';
      const newItems = isLoadMore ? state.items : [];
      const hasMoreItems = isLoadMore ? state.hasMoreItems : true;

      return {
        ...state,
        items: newItems,
        randomFirstPageItems: isLoadMore ? state.randomFirstPageItems : [],
        isFetching: true,
        hasMoreItems,
        loadingType,
        page,
      };
    }
    case getType(getTopRatedActions.success): {
      const { items, loadingType, page, ...otherData } = action.payload;

      const isLoadMore = loadingType === 'loadMore';
      const itemsID = items.map(d => `${d.id}`);
      const newItems = isLoadMore ? state.items.concat(itemsID) : itemsID;

      const randomFirstPageItems = isLoadMore
        ? itemsID
        : shuffle(itemsID.map(d => d)).slice(0, 10);

      return {
        ...state,
        isFetching: false,
        error: undefined,
        items: arrayHandler.uniqueArray(newItems),
        randomFirstPageItems: isLoadMore
          ? state.randomFirstPageItems
          : randomFirstPageItems,
        hasMoreItems: items.length > 0,
        loadingType,
        page,
        ...otherData,
      };
    }
    case getType(getTopRatedActions.failure): {
      return {
        ...state,
        isFetching: false,
        hasMoreItems: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
