import { Platform } from 'react-native';
import ToastAlt from 'react-native-root-tips';

import { black } from '../constants/color';
import { LAYOUT_MAX_WIDTH } from '../constants/layout';

const ToastPosition = {
  TOP:
    Platform.OS === 'ios'
      ? ToastAlt.positions.TOP + 60
      : ToastAlt.positions.TOP + 70,
  CENTER: ToastAlt.positions.CENTER,
  BOTTOM: ToastAlt.positions.BOTTOM,
  PLATFORM:
    Platform.OS === 'android'
      ? ToastAlt.positions.BOTTOM - 70
      : ToastAlt.positions.CENTER,
};

export type TOAST_POSITION = 'TOP' | 'CENTER' | 'BOTTOM' | 'PLATFORM';

export default function showToast(
  text: string,
  position: TOAST_POSITION = 'BOTTOM',
  customComponent?: JSX.Element,
  duration: number = 2000
) {
  const settings = {
    duration,
    position: ToastPosition[position],
    shadow: false,
    showText: !customComponent,
    backgroundColor: black,
    textStyle: {
      fontSize: 15,
      lineHeight: 21,
    },
    containerStyle: {
      padding: 10,
      paddingBottom: 10,
      paddingTop: 10,
    },
    windowWidth: Platform.OS === 'web' ? LAYOUT_MAX_WIDTH : undefined,
  };

  if (!!customComponent) {
    settings['customComponent'] = () => customComponent;
  }

  ToastAlt.show(text, settings);
}
