import AsyncStorage from '@react-native-async-storage/async-storage';
import safeAwait from 'safe-await';

export const getAll = async () => {
  const [err, res] = await safeAwait(AsyncStorage.getItem('searchRecords'));

  if (err) {
    return [];
  }

  const records = res && JSON.parse(res);

  return records || [];
};

export const update = async (text: string) => {
  const noSpaceText = text.trim();

  if (noSpaceText.length === 0) {
    return;
  }

  const records = await getAll();
  const UPPER_LIMIT = 8;

  if (records.includes(noSpaceText)) {
    const index = records.indexOf(noSpaceText);

    records.splice(index, 1);
  }

  records.unshift(noSpaceText);

  if (records.length > UPPER_LIMIT) {
    records.pop();
  }

  const [err] = await safeAwait(
    AsyncStorage.setItem('searchRecords', JSON.stringify(records))
  );

  if (err) {
    return console.log('update search records error:', err);
  }
};

export const clear = async () => {
  const [err] = await safeAwait(AsyncStorage.removeItem('searchRecords'));

  if (err) {
    return console.log('clear search records error:', err);
  }
};
