import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { State } from '../reducers/tutorialGuideReducer';
import { RootState } from '../rootReducer';

const tutorialGuideRaw = (state: RootState) =>
  state?.tutorialGuideReducer || {};

export const makeHasReadGuideByKey = (key: string, expireDays?: number) => {
  return createSelector(tutorialGuideRaw, (data: State) => {
    const keyData = data[key];

    if (typeof expireDays === 'undefined') {
      return typeof keyData !== 'undefined';
    }

    const readDate = (keyData === true ? 0 : keyData) || 0;
    const expiredDate = dayjs(readDate).add(expireDays, 'day');

    return dayjs().isBefore(expiredDate);
  });
};
