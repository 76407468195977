import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { ShippingLogisticsResponse } from '../../types/ShippingLogistics';

export interface ShippingLogisticsPayload {
  transactionID: string;
  loadingType: ListLoadingTypes;
  packageNum?: number;
}

export interface ShippingLogisticsSuccessPayload {
  data: ShippingLogisticsResponse['data'];
  transactionID: string;
  packageNum?: number;
}

export interface ShippingLogisticsFailPayload {
  transactionID: string;
  error: Error;
  requestAction: ReturnType<typeof getShippingLogisticsActions.request>;
}

export const getShippingLogisticsActions = createAsyncAction(
  'verybuy/GET_SHIPPING_LOGISTICS',
  'verybuy/GET_SHIPPING_LOGISTICS_SUCCESS',
  'verybuy/GET_SHIPPING_LOGISTICS_FAIL'
)<
  ShippingLogisticsPayload,
  ShippingLogisticsSuccessPayload,
  ShippingLogisticsFailPayload
>();
