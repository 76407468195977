import pick from 'lodash/pick';
import { ActionType, getType } from 'typesafe-actions';

import { PAY_VALUE_BY_NAME } from '../../constants/payby';
import { DeliveryMode } from '../../types/Common';
import { Coupon } from '../../types/EventCoupon';
import { actionsList } from '../rootAction';

const {
  submitBill,
  updateTransaction,
  loadDeliveryData,
  fetchTransactionDetail,
  clearData,
  resetTransaction,
  putSubtotalActions,
} = actionsList;

export const initialState = {
  isLoading: false,
  pay_by: PAY_VALUE_BY_NAME.CREDIT_CARD,
};

type Actions =
  | typeof submitBill.request
  | typeof submitBill.success
  | typeof submitBill.failure
  | typeof loadDeliveryData.success
  | typeof fetchTransactionDetail.request
  | typeof putSubtotalActions.request
  | typeof putSubtotalActions.success
  | typeof updateTransaction
  | typeof resetTransaction
  | typeof clearData;

export default function (
  state: {
    isLoading: boolean;
    pay_by?: number;
    delivery_mode?: DeliveryMode;
    couponData?: Partial<Coupon>;
    transactionID?: number;
    deliveryData?: number;
  } = initialState,
  action: ActionType<Actions>
) {
  switch (action.type) {
    case getType(putSubtotalActions.request): {
      if (action.payload?.selected_delivery_mode) {
        return {
          ...state,
          delivery_mode: action.payload.selected_delivery_mode,
        };
      }

      return { ...state };
    }
    case getType(putSubtotalActions.success): {
      if (action.payload?.data?.cart_price === 0) {
        return { ...initialState };
      }

      return { ...state };
    }
    case getType(submitBill.request): {
      return { ...state, isLoading: true };
    }

    case getType(submitBill.success): {
      return {
        ...initialState,
        ...pick(state, ['pay_by', 'deliveryData', 'delivery_mode']),
      };
    }

    case getType(submitBill.failure): {
      return { ...state, isLoading: false };
    }

    case getType(loadDeliveryData.success): {
      return action.payload.data.home_list.length
        ? { ...state, deliveryData: 0 }
        : state;
    }

    case getType(updateTransaction): {
      return {
        ...state,
        ...action.payload,
      };
    }

    case getType(fetchTransactionDetail.request): {
      return {
        ...state,
        transactionID: action.payload.transactionID,
      };
    }

    case getType(resetTransaction): {
      return {
        ...initialState,
        couponData: state.couponData,
        delivery_mode: state.delivery_mode,
      };
    }

    case getType(clearData): {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
