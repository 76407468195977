/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */

import { createAsyncAction } from 'typesafe-actions';

import { CookieData } from '../../types/Cookie';

export interface CookiePayload {
  success(data: CookieData): void;
}

export interface CookieSuccessPayload {
  data: CookieData;
}

export interface CookieFailPayload {
  error: Error;
}

export const fetchCookie = createAsyncAction(
  'verybuy/COOKIE',
  'verybuy/COOKIE_SUCCESS',
  'verybuy/COOKIE_FAIL'
)<CookiePayload, CookieSuccessPayload, CookieFailPayload>();
