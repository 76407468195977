import deepEqual from 'fast-deep-equal';
import { CSSProperties, Component } from 'react';

import { isWebPlatform } from '../../boot/utils';
import FastImage from './FastImage';
import { ImageProps } from './types';

export default class PureImage extends Component<ImageProps> {
  static defaultProps: Partial<ImageProps> = {
    enableLazyLoad: false,
  };

  state = {
    loadImageFail: false,
  };

  webImgStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  componentDidUpdate(prevProps: ImageProps) {
    const { source } = this.props;
    const { loadImageFail } = this.state;

    if (!deepEqual(source, prevProps.source)) {
      if (loadImageFail) {
        this.setState({ loadImageFail: false });
      }
    }
  }

  onWebImgLoad = e => {
    const { onLoad, onLoadEnd } = this.props;

    if (typeof onLoad === 'function') {
      onLoad(e);
    }
    if (typeof onLoadEnd === 'function') {
      onLoadEnd();
    }
  };

  replaceImageAsDefault = () => {
    this.setState({ loadImageFail: true });
  };

  render() {
    // https://github.com/DylanVann/react-native-fast-image/issues/221
    // remove testId props
    const { style, source, testID, webImgRef, ...otherProps } = this.props;
    const enableLazyLoad = isWebPlatform && this.props.enableLazyLoad;
    const imgSource = !this.state.loadImageFail
      ? source
      : require('./defaultImage.png'); // eslint-disable-line global-require

    return enableLazyLoad ? (
      <img
        ref={webImgRef}
        loading="lazy"
        src={imgSource?.uri || imgSource}
        style={this.webImgStyle}
        onLoad={this.onWebImgLoad}
        onError={this.replaceImageAsDefault}
      />
    ) : (
      <FastImage
        source={imgSource}
        onError={this.replaceImageAsDefault}
        resizeMode="cover"
        style={style}
        {...otherProps}
      />
    );
  }
}
