import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { CommonErrors } from '../../constants/VBErrorCode';
import { MarketingLandingPage } from '../../types/MarketingLandingPageResponse';
import {
  MarketingLandingPageSuccessPayload,
  getMarketingLandingPageActions,
} from '../actions/getMarketingLandingPageActions';
import { getMarketingLandingPage } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getMarketingLandingPageActions.success,
  failure: getMarketingLandingPageActions.failure,
};

type RequestActionTypes = ActionType<
  typeof getMarketingLandingPageActions.request
>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getMarketingLandingPageEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getMarketingLandingPageActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { id, page, loadingType, failure, success, ...restParams } =
        action.payload;

      return getMarketingLandingPage({ id, page, ...restParams })
        .then((response: AxiosResponse<MarketingLandingPage>) => {
          const data = response.data;

          success(data.products);

          const successPayload: MarketingLandingPageSuccessPayload = {
            data,
            id,
            loadingType,
            page,
          };

          if (loadingType === 'new') {
            successPayload.logEvent = AnalyticsEvent.ViewBestSeller;
          }

          return getMarketingLandingPageActions.success(successPayload);
        })
        .catch((error: AxiosError) => {
          const perticularError = [
            CommonErrors.ERR_INVALID_ARGUMENT,
            CommonErrors.ERR_NOT_FOUND,
          ];
          const { error_code: errorCode } = error?.response?.data || {};
          const preventCommonError = perticularError.some(
            theError => errorCode === theError
          );

          if (preventCommonError && failure) {
            failure(page);
          }

          return getMarketingLandingPageActions.failure({
            // set customErrorHandling undefined, then go through common error
            customErrorHandling: preventCommonError || undefined,
            error,
            id,
            requestAction: action,
          });
        });
    })
  );
};

export default getMarketingLandingPageEpic;
