import { createStandardAction } from 'typesafe-actions';

import { DeliveryMode, ReceiptType } from '../../types/Common';
import { Coupon } from '../../types/EventCoupon';

export interface UpdateTransactionPayload {
  couponData?: Partial<Coupon> | undefined;
  deliveryData?: number;
  delivery_mode?: DeliveryMode;
  receiptType?: ReceiptType;
  pay_by?: number;
}

export const updateTransaction = createStandardAction(
  'verybuy/UPDATE_TRANSACTION'
)<UpdateTransactionPayload>();
