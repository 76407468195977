import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';
import { Switch } from 'react-native';

import Config from '../../utils/configs';
import { applyCustomConfig } from '../../utils/configs/utils';
import S from './style';

export default function OnlyDevLogFirehose() {
  const theKey = 'SHOULD_LOG_FIREHOSE';
  const [shouldLog, setShouldLog] = useState(false);

  useEffect(() => {
    AsyncStorage.getItem(theKey)
      .then(shouldLogFromStorage => {
        setShouldLog(!!shouldLogFromStorage && shouldLogFromStorage === 'true');
      })
      .catch(null);
  }, []);

  useEffect(() => {
    AsyncStorage.setItem(theKey, shouldLog.toString()).catch(null);
    applyCustomConfig(Config, { [theKey]: shouldLog }).catch(null);
  }, [shouldLog]);

  const onPress = checked => {
    setShouldLog(checked);
  };

  return (
    <S.RowItem>
      <S.FlexRow>
        <S.Label>Log Firehose</S.Label>
        <Switch onValueChange={onPress} value={shouldLog} />
      </S.FlexRow>
    </S.RowItem>
  );
}
