import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import type { ItemDetailBase } from '../../types/ItemCommon';
import type { CollectedVendor } from '../../types/LoveVendorsResponse';
import type { VendorDetail } from '../../types/VendorDetail';
import { VendorType } from '../../types/VendorType';
import { actionsList } from '../rootAction';

const {
  getUserSubmitDetail,
  getLoveVendorsActions,
  getVendorDetailActions,
  putVendorCollectActions,
  deleteVendorCollectActions,
} = actionsList;

type Action = ActionType<
  | typeof getUserSubmitDetail.success
  | typeof getLoveVendorsActions.success
  | typeof getVendorDetailActions.success
  | typeof putVendorCollectActions.request
  | typeof putVendorCollectActions.success
  | typeof putVendorCollectActions.failure
  | typeof deleteVendorCollectActions.request
  | typeof deleteVendorCollectActions.success
  | typeof deleteVendorCollectActions.failure
>;

export interface Detail extends Partial<VendorDetail> {
  isCollectFetching?: boolean;
}

// Export for test
export const defaultState: Record<string, Detail | undefined> = {};

const allowedType = Object.values(VendorType)
  .filter(x => typeof x === 'number')
  .reduce((acc, cur) => acc.add(cur), new Set());

const sellerWithTypeCorrection = (
  seller: ItemDetailBase['seller'] | VendorDetail | CollectedVendor
) => {
  return {
    ...seller,
    seller_type: allowedType.has(seller.seller_type)
      ? seller.seller_type
      : VendorType.Seller,
  };
};

export default produce(
  (draft: Record<string, Detail | undefined>, action: Action) => {
    switch (action.type) {
      case getType(getUserSubmitDetail.success): {
        const { seller_id } = action.payload.data.seller || {};

        if (!!seller_id) {
          draft[seller_id] = {
            ...(draft[seller_id] || {}),
            ...sellerWithTypeCorrection(action.payload.data.seller),
          };
        }

        break;
      }
      case getType(getLoveVendorsActions.success): {
        const { data } = action.payload;

        data.sellers.forEach(item => {
          draft[item.seller_id] = {
            ...(draft[item.seller_id] || {}),
            ...sellerWithTypeCorrection(item),
          };
        });

        break;
      }
      case getType(getVendorDetailActions.success): {
        const { id, data } = action.payload;

        draft[id] = {
          ...(draft[id] || {}),
          ...sellerWithTypeCorrection({
            seller_id: id,
            ...data.seller,
          }),
        };

        break;
      }
      case getType(deleteVendorCollectActions.request):
      case getType(putVendorCollectActions.request): {
        const { id } = action.payload;

        const item = draft[id];

        if (item) {
          item.isCollectFetching = true;
        }

        break;
      }
      case getType(putVendorCollectActions.success): {
        const { id } = action.payload;
        const item = draft[id];

        if (item) {
          item.isCollectFetching = false;
          item.is_loved = true;
        }

        break;
      }
      case getType(deleteVendorCollectActions.success): {
        const { id } = action.payload;
        const item = draft[id];

        if (item) {
          item.isCollectFetching = false;
          item.is_loved = false;
        }

        break;
      }
      case getType(deleteVendorCollectActions.failure):
      case getType(putVendorCollectActions.failure): {
        const { id } = action.payload;
        const item = draft[id];

        if (item) {
          item.isCollectFetching = false;
        }

        break;
      }
      default:
        break;
    }
  },
  defaultState
);
