const DULPLICATE_ROUTE_LIMIT = 3;
const ROUTE_LIMIT = 10;

/*
 * 目前 web 取得 stack 要移除的 index, 完整情境可參考相關測試
 * 刪除對象是排除 Root 的第一個
 * -1: 不移除
 * 0: 移除 index 0
 * 1: 移除 index 1
 *
 * 參數
 * list: stack 陣列
 * currentIndex: 從 react-navigation 取得 index
 * checkRootFunction: 判斷是否 root，會影響移除位置
 * checkNameFunction: 判斷是否符合規則，會影響 dulplicate 和 firstIdx 的結果
 * dulplicateLimit: 第一個和 AA pattern 相同的 index 位置距離
 * limit: 最多幾個 stack
 * */
export function getRemoveStackIndex(
  list,
  currentIndex,
  checkRootFunction,
  checkNameFunction,
  dulplicateLimit = DULPLICATE_ROUTE_LIMIT,
  limit = ROUTE_LIMIT
) {
  const listLength = list.length;
  const candidateIndex = checkRootFunction(list[0]) ? 1 : 0;
  const isPrevEqualCurrent =
    currentIndex > 1 ? checkNameFunction(list[currentIndex - 1]) : false;
  const firstIdxEqualCurrent =
    isPrevEqualCurrent && listLength > dulplicateLimit
      ? list.findIndex(checkNameFunction)
      : -1;

  if (
    listLength > limit ||
    (firstIdxEqualCurrent > -1 &&
      currentIndex - firstIdxEqualCurrent >= dulplicateLimit)
  ) {
    return candidateIndex;
  }

  return -1;
}
