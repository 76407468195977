/*
 * 這個 Component 為搭配 rnrf 中，key 為 lightboxLoadingPage 的蓋版 loading 畫面
 * 使用方式
 *
 * Actions.lightboxLoadingPage({
 *  waitForClose,
 *  onCloseForTransitionEnd,
 * });
 *
 * 範例可參考：
 *   基本範例 => DeveloperTool/GeneralPage 的 openLightbox 中的方法
 *   需要搭配 request 可參考 => components/PaymentPage 內 handleSubmitTransaction
 */
import { PureComponent } from 'react';
import { Alert, Button, Dimensions, View } from 'react-native';

import { white } from '../../constants/color';
import LoadingView from '../../elements/LoadingView';
import { translate } from '../../i18n';
import styles from './FullLoadingView.styles';

const { height: deviceHeight, width: deviceWidth } = Dimensions.get('window');

interface Props {
  closeFullScreenLoading: Function;
  errorMessage: string;
  hasCloseBtn?: boolean; // it's for testing or demo
  horizontalPercent?: number;
  verticalPercent?: number;
  loadingViewStyles?: any;
}

interface State {
  errorMessage: string;
}

export default class LightboxLoading extends PureComponent<Props, State> {
  static defaultProps = {
    errorMessage: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errorMessage !== prevState.errorMessage) {
      return { errorMessage: nextProps.errorMessage };
    }

    return null;
  }

  state = {
    errorMessage: this.props.errorMessage,
  };

  close = () => {
    this.props.closeFullScreenLoading();
  };

  renderErrorDialog = () => {
    Alert.alert(this.state.errorMessage, undefined, [
      {
        text: translate('lightboxLoading-error-positive'),
        onPress: () => {
          this.close();
        },
        style: 'destructive',
      },
    ]);

    return null;
  };

  renderLoading = () => {
    const {
      horizontalPercent = 1,
      verticalPercent = 1,
      hasCloseBtn = false,
      loadingViewStyles = {},
    } = this.props;
    const height = verticalPercent
      ? deviceHeight * verticalPercent
      : deviceHeight;
    const width = horizontalPercent
      ? deviceWidth * horizontalPercent
      : deviceWidth;

    return (
      <View style={styles.container} testID="fullLoading">
        <LoadingView
          style={{
            width,
            height,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            ...loadingViewStyles,
          }}
          color={white}
        />
        {hasCloseBtn && <Button title="close" onPress={this.close} />}
      </View>
    );
  };

  render() {
    return this.state.errorMessage.length
      ? this.renderErrorDialog()
      : this.renderLoading();
  }
}
