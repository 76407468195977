import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getLoveVendorsActions } from '../actions/getLoveVendorsActions';
import { getLoveVendors } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getLoveVendorsActions.success,
  failure: getLoveVendorsActions.failure,
};

type RequestActionType = ActionType<typeof getLoveVendorsActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getLoveVendorsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getLoveVendorsActions.request)),
    switchMap((action: RequestActionType) => {
      const { page } = action.payload;

      return getLoveVendors(page)
        .then(response => {
          return getLoveVendorsActions.success({
            data: response.data,
            ...action.payload,
          });
        })
        .catch((error: AxiosError) => {
          const failure = getLoveVendorsActions.failure({
            error,
            requestAction: action,
          });

          return failure;
        });
    })
  );
};

export default getLoveVendorsEpic;
