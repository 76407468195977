import produce from 'immer';
import { getType } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { OnboardingItem } from '../../types/OnboardingResponse';
import * as arrayHandler from '../../utils/arrayHandler';
import { actionsList } from '../rootAction';

const { getOnboardingActions } = actionsList;

export interface State {
  currentPage?: number;
  error?: Error | null;
  hasError: boolean;
  hasMoreItems: boolean;
  isFetching: boolean;
  listLoadingType?: ListLoadingTypes;
  page: number;
  products: string[];
}

export const defaultState: State = {
  isFetching: false,
  hasError: false,
  hasMoreItems: true,
  page: 1,
  products: [],
};

export const MAX_LIST_LIMIT = 50;

export default produce((draft: State, action) => {
  switch (action.type) {
    case getType(getOnboardingActions.request): {
      const { loadingType } = action.payload;
      const newItems = loadingType === 'new' ? [] : draft.products;

      draft.error = null;
      draft.hasError = false;
      draft.isFetching = true;
      draft.listLoadingType = loadingType;
      draft.products = newItems;
      break;
    }
    case getType(getOnboardingActions.success): {
      const { data, page, loadingType } = action.payload;
      const rawProducts: OnboardingItem[] = data.products || [];
      const itemsTypeAndId = rawProducts.map(d => `${d.id}`);
      let newItems =
        loadingType === 'loadMore'
          ? [...draft.products, ...itemsTypeAndId]
          : itemsTypeAndId;

      newItems = arrayHandler.uniqueArray(newItems);

      const hasMoreItems =
        newItems.length < MAX_LIST_LIMIT && data.has_next_page;

      draft.isFetching = false;
      draft.hasError = false;
      draft.hasMoreItems = hasMoreItems;
      draft.page = page;
      draft.error = null;
      draft.products = newItems.slice(0, MAX_LIST_LIMIT);
      break;
    }
    case getType(getOnboardingActions.failure): {
      draft.isFetching = false;
      draft.hasError = true;
      draft.error = action.payload.error;
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
