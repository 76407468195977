import { createAsyncAction } from 'typesafe-actions';

import { Data as CatsData } from '../../types/CatsResponse';

export interface CatsPayload {}

export interface CatsSuccessPayload {
  data: CatsData;
}

export interface CatsFailPayload {
  error: Error;
}

export const getCatsActions = createAsyncAction(
  'verybuy/GET_CATS',
  'verybuy/GET_CATS_SUCCESS',
  'verybuy/GET_CATS_FAIL'
)<CatsPayload, CatsSuccessPayload, CatsFailPayload>();
