import { AxiosError } from 'axios';
import { getType } from 'typesafe-actions';

import { CouponForRedeem } from '../../types/Coupon';
import { initCouponRedeem } from '../actions/initCouponRedeem';
import { actionsList } from '../rootAction';

const { postCouponRedeemActions, putVerifyCouponActions } = actionsList;
const initialState: {
  isFetching: boolean;
  hasError: boolean;
  coupons: CouponForRedeem[];
  error?: AxiosError;
} = {
  isFetching: false,
  hasError: false,
  coupons: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case getType(postCouponRedeemActions.request): {
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: null,
      };
    }
    case getType(putVerifyCouponActions.success): {
      const { couponData = {} } = action.payload;

      // 因驗證可能透過「兌換」，或頁面上的 submit 按鈕，故不保證 couponData 一定有資料，需處理
      if (Object.keys(couponData).length === 0) {
        return {
          ...state,
          isFetching: false,
          hasError: false,
          error: null,
        };
      }

      const newCoupons = state.coupons.slice();

      newCoupons.unshift(couponData);

      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: null,
        coupons: newCoupons,
      };
    }
    case getType(putVerifyCouponActions.failure): {
      return {
        ...state,
        isFetching: false,
      };
    }
    case getType(postCouponRedeemActions.success): {
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: null,
      };
    }
    case getType(postCouponRedeemActions.failure): {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.payload.error,
      };
    }
    case getType(initCouponRedeem): {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
