import { createAsyncAction } from 'typesafe-actions';

export interface AddToCartPayload {
  id: string;
  amount: number;
  color_id: string;
  size_id: string;
  failure: Function;
  color_name?: string;
  size_name?: string;
  isComboCart?: boolean;
}

export interface AddToCartSuccessPayload {
  id: string;
  colorName?: string;
  sizeName?: string;
  isComboCart?: boolean;
  amount?: number;
}

export interface AddToCartFailPayload {
  id: string;
  error: Error;
}

export const addToCart = createAsyncAction(
  'verybuy/ADD_TO_CART',
  'verybuy/ADD_TO_CART_SUCCESS',
  'verybuy/ADD_TO_CART_FAIL'
)<AddToCartPayload, AddToCartSuccessPayload, AddToCartFailPayload>();
