import { StyleProp, TextStyle } from 'react-native';

import { showNumberWithComma } from '../../utils/stringHelper';
import { S } from './styles';

interface Props {
  currentPrice: number;
  originalPrice: number;
  style?: StyleProp<TextStyle>;
}

export default function LineThroughPrice({
  currentPrice,
  originalPrice,
  style,
}: Props) {
  if (!currentPrice || !originalPrice) return null;

  if (currentPrice === originalPrice) return null;

  return (
    <S.Text
      style={style}
      testID="LineThroughPrice"
      numberOfLines={1}
      allowFontScaling={false}
    >
      {showNumberWithComma(originalPrice)}
    </S.Text>
  );
}
