import { AnalyticsEvent } from '../../../constants/AnalyticsEvent';
import { isBot } from '../../../constants/misc';
import { RTBHouseAnalyticsEvent } from '../../../constants/RTBHouseAnalyticsEvent';
import { makeMemberInfoByKey } from '../../../redux/selectors/member';
import { transformToStandardEvent } from '../transformToStandardEvent';
import { sendEvent } from './sendEvents';

export const sendRTBHouseEventFactory = (logEvent, logData, currentStore) => {
  if (isBot) return;

  const MID = makeMemberInfoByKey('MID')(currentStore) || 0;
  const RTBHouseEvents = transformToStandardEvent(RTBHouseAnalyticsEvent);

  switch (logEvent) {
    case AnalyticsEvent.ViewSearchResults:
    case AnalyticsEvent.ViewBestSeller:
      sendEvent(RTBHouseEvents[logEvent], {
        MID,
        contentIds:
          logData?.data?.products.map(value => value.marketing_product_id) ||
          [],
      });
      break;
    case AnalyticsEvent.ViewItem:
      sendEvent(RTBHouseAnalyticsEvent.ViewItem, {
        MID,
        id: logData?.data?.marketing_product_id || 'EMPTY_MARKETING_PRODUCT_ID',
      });

      break;
    case AnalyticsEvent.ViewItemList:
      sendEvent(RTBHouseAnalyticsEvent.ViewItemList, {
        MID,
        id: logData?.data?.item_category_id || 'EMPTY_CATEGORY_ID',
      });
      break;
    case AnalyticsEvent.ViewCart: {
      const contentIds = logData?.data.map(content => content.content_id) || [];

      // PM: RTB house 僅在購物車有東西才送該事件
      if (contentIds.length > 0) {
        sendEvent(RTBHouseAnalyticsEvent.ViewCart, {
          MID,
          contentIds,
        });
      }

      break;
    }
    case AnalyticsEvent.ViewHome:
      sendEvent(RTBHouseAnalyticsEvent.ViewHome, {
        MID,
        pageName: 'home',
      });
      break;
    case AnalyticsEvent.BeginCheckout:
      sendEvent(RTBHouseAnalyticsEvent.BeginCheckout, {
        MID,
        pageName: 'startorder',
      });

      break;
    case AnalyticsEvent.ViewNewArrivals:
      sendEvent(RTBHouseAnalyticsEvent.ViewNewArrivals, {
        MID,
        pageName: 'newoffers',
      });
      break;
    case AnalyticsEvent.OrderConfirmation:
      const orderConfirmationDetailData = logData?.data?.detail_data || [];

      sendEvent(RTBHouseAnalyticsEvent.OrderConfirmation, {
        MID,
        ids: orderConfirmationDetailData.map(
          value => value.marketing_product_id
        ),
        orderId: logData?.data?.transaction_id,
        price: logData?.data?.price,
      });
      break;
    default:
      return;
  }
};
