import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Data as PeriodTaskData } from '../../types/PeriodTaskResponse';

export interface PeriodTaskPayload {
  loadingType: ListLoadingTypes;
}

export interface PeriodTaskSuccessPayload {
  data: PeriodTaskData;
}

export interface PeriodTaskFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getPeriodTaskActions.request>;
}

export const getPeriodTaskActions = createAsyncAction(
  'verybuy/GET_PERIOD_TASK',
  'verybuy/GET_PERIOD_TASK_SUCCESS',
  'verybuy/GET_PERIOD_TASK_FAIL'
)<PeriodTaskPayload, PeriodTaskSuccessPayload, PeriodTaskFailPayload>();
