import { connect } from 'react-redux';

import { loginByFacebook } from '../../../redux/actions/loginByFacebook';
import FBLoginButton from './FBLoginButton';

const mapDispatchToProps = {
  loginByFacebook: loginByFacebook.request,
};

export default connect(null, mapDispatchToProps)(FBLoginButton);
