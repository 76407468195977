import { Platform, Text, TextInput } from 'react-native';

import { maxFontSizeMultiplier } from '../constants/misc';
import { getPlatformCode, setAxiosDefaultHeader } from '../redux/api';
import { MemberInfo } from '../types/MemberInfo';
import deviceInfo from '../utils/deviceInfo';
import firebase from '../utils/packages/firebase';
import sendGtagEvent from '../utils/sendGtagEvent';

export const isApp = /(android)|(ios)/i.test(Platform.OS);
export const isServer = typeof window === 'undefined' && !isApp;
export const isClient = !isServer; // app + web client
export const isWebPlatform = !isServer && Platform.OS === 'web';
export const isAndroid = Platform.OS === 'android';
export const isIOS = Platform.OS === 'ios';

export const limitGlobalFontSize = (): void => {
  if (!(Text as any).defaultProps) {
    (Text as any).defaultProps = {};
  }
  (Text as any).defaultProps.maxFontSizeMultiplier = maxFontSizeMultiplier;

  if (!(TextInput as any).defaultProps) {
    (TextInput as any).defaultProps = {};
  }
  (TextInput as any).defaultProps.maxFontSizeMultiplier = maxFontSizeMultiplier;
  (TextInput as any).defaultProps.underlineColorAndroid = 'transparent';
};

export const setUserInfo = (memberInfo: Partial<MemberInfo>) => {
  const { MID, email, phone } = memberInfo;

  if (MID === undefined || isServer) return;
  if (isWebPlatform) {
    sendGtagEvent('config', 'MEASUREMENT_ID', {
      user_id: MID,
    });

    if (email || phone) {
      sendGtagEvent('set', 'user_data', {
        ...(email ? { email } : {}),
        ...(phone ? { phone_number: '+886' + phone } : {}),
      });
    }
  } else {
    firebase
      .analytics()
      .setUserId(MID)
      .catch(e => {
        console.log('firebase setUserId error = ', e);
      });
  }
};

export const setDefaultRequestHeaders = () => {
  const regex = /\d+\.\d+\.\d+/;
  const version = deviceInfo.getReadableVersion().match(regex);

  if (version) {
    setAxiosDefaultHeader('X-App-Version', version[0]);
  }
  setAxiosDefaultHeader('platform', getPlatformCode());
};

const getActiveRouteName = navigationState => {
  if (!navigationState) {
    return null;
  }

  const route = navigationState.routes[navigationState.index];

  // Dive into nested navigators
  if (route.routes) {
    return getActiveRouteName(route);
  }

  if (route.routeName === 'WebViewPage') {
    return route.params.screenViewName;
  } else if (route.routeName === 'ItemPage') {
    return `${route.routeName}-${route.params.itemType}-${route.params.id}`;
  }

  return route.routeName;
};
