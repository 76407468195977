import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import { StateType } from 'typesafe-actions';

import mmkvStorage from '../utils/mmkvStorage';
import { migrateMemberPersist } from './migrateStorage';
import announcementsReducer from './reducers/announcementsReducer';
import apiCommonErrorReducer from './reducers/apiCommonErrorReducer';
import bannersReducer from './reducers/bannersReducer';
import cartContentReducer from './reducers/cartContentReducer';
import categoriesReducer from './reducers/categoriesReducer';
import categoryListReducer from './reducers/categoryListReducer';
import catsReducer from './reducers/catsReducer';
import checkoutReducer from './reducers/checkoutReducer';
import couponListReducer from './reducers/couponListReducer';
import couponRedeemReducer from './reducers/couponRedeemReducer';
import deleteAccountFormReducer from './reducers/deleteAccountFormReducer';
import directPayCardsReducer from './reducers/directPayCardsReducer';
import eventPageReducer from './reducers/eventPageReducer';
import faqReducer from './reducers/faqReducer';
import freeDeliveryReducer from './reducers/freeDeliveryReducer';
import itemComboReducer from './reducers/itemComboReducer';
import itemContentReducer from './reducers/itemContentReducer';
import landingPageListReducer from './reducers/landingPageListReducer';
import lightBoxReducer from './reducers/lightBoxReducer';
import { LoveListReducer } from './reducers/loveListReducer';
import loveVendorListReducer from './reducers/loveVendorListReducer';
import mailboxReducer from './reducers/mailboxReducer';
import marketingEventsReducer from './reducers/marketingEventsReducer';
import marketingLandingPageReducer from './reducers/marketingLandingPageReducer';
import memberReducer from './reducers/memberReducer';
import notificationsReducer from './reducers/notificationsReducer';
import onboardingReducer from './reducers/onboardingReducer';
import onSaleReducer from './reducers/onSaleReducer';
import orderById from './reducers/orderById';
import promotionalThemesReducer from './reducers/promotionalThemesReducer';
import queryItemsReducer from './reducers/queryItemsReducer';
import recommendProductsReducer from './reducers/recommendProductsReducer';
import recommendSubjectReducer from './reducers/recommendSubjectReducer';
import searchReducer from './reducers/searchReducer';
import submitBillReducer from './reducers/submitBillReducer';
import subtotalReducer from './reducers/subtotalReducer';
import supportDetailReducer from './reducers/supportDetailReducer';
import topKeywordsReducer from './reducers/topKeywordsReducer';
import topRatedReducer from './reducers/topRatedReducer';
import topSalesReducer from './reducers/topSalesReducer';
import transactionListReducer from './reducers/transactionListReducer';
import transactionRatingReducer from './reducers/transactionRatingReducer';
import tutorialGuideReducer from './reducers/tutorialGuideReducer';
import userSubmitDetailReducer from './reducers/userSubmitDetailReducer';
import userSubmitListReducer from './reducers/userSubmitListReducer';
import VBConfigReducer from './reducers/VBConfigReducer';
import vendorDetailReducer from './reducers/vendorDetailReducer';
import wordsLabelsReducer from './reducers/wordsLabelsReducer';

export interface AsyncReducers {
  loveListReducer: LoveListReducer;
}

const SECRET_KEY = 'very-racer-champion-is-me';
const encryptor = createEncryptor({
  secretKey: SECRET_KEY,
  onError: err => console.log(err),
});

const memberPersistConfig: PersistConfig<any> = {
  key: 'memberReducer',
  storage: mmkvStorage.asAsyncStorage,
  timeout: 0,
  version: 2,
  migrate: migrateMemberPersist(SECRET_KEY),
  transforms: [encryptor],
};

export const defaultReducers = {
  categoryListReducer,
  wordsLabelsReducer,
  announcementsReducer,
  mailboxReducer,
  deleteAccountFormReducer,
  freeDeliveryReducer,
  faqReducer,
  queryItemsReducer,
  onSaleReducer,
  catsReducer,
  promotionalThemesReducer,
  topKeywordsReducer,
  supportDetailReducer,
  VBConfigReducer,
  onboardingReducer,
  userSubmitListReducer,
  recommendSubjectReducer,
  userSubmitDetailReducer,
  itemComboReducer,
  itemContentReducer,
  cartContentReducer,
  checkoutReducer,
  submitBillReducer,
  memberReducer: persistReducer(memberPersistConfig, memberReducer),
  notificationsReducer,
  categoriesReducer,
  searchReducer,
  orderById,
  subtotalReducer,
  marketingEventsReducer,
  transactionListReducer,
  transactionRatingReducer,
  directPayCardsReducer,
  eventPageReducer,
  bannersReducer,
  couponRedeemReducer,
  couponListReducer,
  topSalesReducer,
  topRatedReducer,
  vendorDetailReducer,
  marketingLandingPageReducer,
  recommendProductsReducer,
  tutorialGuideReducer,
  apiCommonErrorReducer,
  loveVendorListReducer,
  landingPageListReducer,
  // web only
  lightBoxReducer,
};

export const rootReducer = combineReducers(defaultReducers);

export type DefaultReducers = typeof defaultReducers;
export type RootReducer = DefaultReducers & Partial<AsyncReducers>;
export type RootState = StateType<RootReducer>;
