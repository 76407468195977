import { PureComponent } from 'react';
import { ActivityIndicator, StyleProp, View, ViewStyle } from 'react-native';

import { neutralGrey } from '../../constants/color';
import styles from './styles';

interface Props {
  style?: StyleProp<ViewStyle>;
  size?: 'large' | 'small';
  color?: string;
  testID?: string;
}

export default class LoadingView extends PureComponent<Props> {
  static defaultProps = {
    size: 'large',
    color: neutralGrey,
  };

  render() {
    return (
      <View style={[styles.loading, this.props.style]}>
        <ActivityIndicator size={this.props.size} color={this.props.color} />
      </View>
    );
  }
}
