import produce from 'immer';
import { getType } from 'typesafe-actions';

import { EventErrors } from '../../constants/VBErrorCode';
import { ListLoadingTypes } from '../../types/Common';
import { CouponForReducer } from '../../types/EventCoupon';
import {
  CoverImages,
  CoverImagesNotSet,
} from '../../types/EventPageCoverResponse';
import {
  Data as PeriodTask,
  PeriodTaskNotSet,
} from '../../types/PeriodTaskResponse';
import {
  EventCouponPayload,
  EventCouponSuccessPayload,
} from '../actions/getEventCouponActions';
import {
  EventPageCoverPayload,
  EventPageCoverSuccessPayload,
} from '../actions/getEventPageCoverActions';
import {
  PeriodTaskPayload,
  PeriodTaskSuccessPayload,
} from '../actions/getPeriodTaskActions';
import {
  CouponRedeemFailPayload,
  CouponRedeemPayload,
  CouponRedeemSuccessPayload,
} from '../actions/postCouponRedeemActions';
import {
  PostPeriodTaskFailPayload,
  PostPeriodTaskSuccessPayload,
} from '../actions/postPeriodTaskActions';
import {
  SaveCouponActionPayload,
  saveCouponAction,
} from '../actions/updateCouponActions';
import { actionsList } from '../rootAction';

const {
  getEventCouponActions,
  getEventPageCoverActions,
  getPeriodTaskActions,
  postCouponRedeemActions,
  postPeriodTaskActions,
} = actionsList;

type CouponCode = string;
export type Coupons = Record<CouponCode, CouponForReducer>;

export interface CouponData {
  isFetching: boolean;
  loadingType: ListLoadingTypes;
  coupons: Coupons;
  savedCouponCode: string | null;
  hasRedeem: boolean;
}

export interface CoverData {
  isFetching: boolean;
  loadingType: ListLoadingTypes;
  source: CoverImages | CoverImagesNotSet;
}

export interface PeriodTaskData {
  isFetching: boolean;
  isPostLoading: boolean;
  loadingType: ListLoadingTypes;
  periodTask: PeriodTask | PeriodTaskNotSet;
}

export interface State {
  couponData: CouponData;
  coverData: CoverData;
  periodTaskData: PeriodTaskData;
}

export const defaultState: State = {
  couponData: {
    isFetching: false,
    loadingType: 'new',
    coupons: {},
    savedCouponCode: null,
    hasRedeem: false,
  },
  coverData: {
    isFetching: false,
    loadingType: 'new',
    source: {},
  },
  periodTaskData: {
    isFetching: false,
    isPostLoading: false,
    loadingType: 'new',
    periodTask: {},
  },
};

const couponDataProcess = produce((draft: CouponData, action) => {
  switch (action.type) {
    case getType(saveCouponAction): {
      const { couponCode } = action.payload as SaveCouponActionPayload;

      draft.savedCouponCode = couponCode;
      break;
    }

    case getType(getEventCouponActions.request): {
      const { loadingType } = action.payload as EventCouponPayload;

      draft.isFetching = true;
      draft.loadingType = loadingType;
      draft.hasRedeem = false;
      break;
    }
    case getType(getEventCouponActions.success): {
      const { coupons } = action.payload as EventCouponSuccessPayload;
      const { savedCouponCode } = draft;
      const formatCoupons = coupons.reduce<Coupons>(
        (acc, cur) => ({
          ...acc,
          [cur.code]: {
            ...cur,
            isFetching: false,
          },
        }),
        {}
      );

      // 若 api response 中無該張 coupon，則將保留的 coupon 放進列表中
      if (savedCouponCode && !formatCoupons[savedCouponCode]) {
        formatCoupons[savedCouponCode] = draft.coupons[savedCouponCode];
      }

      draft.isFetching = false;
      draft.coupons = formatCoupons;
      draft.savedCouponCode = null;
      break;
    }
    case getType(getEventCouponActions.failure): {
      draft.isFetching = false;
      break;
    }

    case getType(postCouponRedeemActions.request): {
      const { couponCode } = action.payload as CouponRedeemPayload;

      draft.coupons[couponCode] = draft.coupons[couponCode] || {};
      draft.coupons[couponCode].isFetching = true;
      break;
    }
    case getType(postCouponRedeemActions.success): {
      const { couponCode } = action.payload as CouponRedeemSuccessPayload;

      draft.coupons[couponCode] = draft.coupons[couponCode] || {};
      draft.coupons[couponCode].isFetching = false;
      draft.coupons[couponCode].is_redeemed = true;
      draft.hasRedeem = true;
      break;
    }
    case getType(postCouponRedeemActions.failure): {
      const { couponCode, error } = action.payload as CouponRedeemFailPayload;
      const errorCode: EventErrors = error.response?.data?.error_code;

      draft.coupons[couponCode] = draft.coupons[couponCode] || {};

      switch (errorCode) {
        case EventErrors.ERR_COUPON_IS_OVER: {
          draft.coupons[couponCode].isFetching = false;
          draft.coupons[couponCode].is_available = false;
          break;
        }
        case EventErrors.ERR_COUPON_REDEEMED: {
          draft.coupons[couponCode].isFetching = false;
          draft.coupons[couponCode].is_redeemed = true;
          break;
        }
        case EventErrors.ERR_COUPON_NOT_FOUND:
        case EventErrors.ERR_COUPON_EXPIRED: {
          delete draft.coupons[couponCode];
          break;
        }
        default: {
          // @TODO: 讓列表重整
          draft.coupons[couponCode].isFetching = false;
          break;
        }
      }
      break;
    }
    default: {
      break;
    }
  }
});

const coverDataProcess = produce((draft: CoverData, action) => {
  switch (action.type) {
    case getType(getEventPageCoverActions.request): {
      const { loadingType } = action.payload as EventPageCoverPayload;

      draft.isFetching = true;
      draft.loadingType = loadingType;
      break;
    }
    case getType(getEventPageCoverActions.success): {
      const { data } = action.payload as EventPageCoverSuccessPayload;

      draft.isFetching = false;
      draft.source = data.cover_images;
      break;
    }
    case getType(getEventPageCoverActions.failure): {
      draft.isFetching = false;
      break;
    }
    default: {
      break;
    }
  }
});

const periodTaskDataProcess = produce((draft: PeriodTaskData, action) => {
  switch (action.type) {
    case getType(getPeriodTaskActions.request): {
      const { loadingType } = action.payload as PeriodTaskPayload;

      draft.isFetching = true;
      draft.loadingType = loadingType;
      break;
    }
    case getType(getPeriodTaskActions.success): {
      const { data } = action.payload as PeriodTaskSuccessPayload;

      draft.isFetching = false;
      draft.periodTask = data;
      break;
    }
    case getType(getPeriodTaskActions.failure): {
      draft.isFetching = false;
      break;
    }
    case getType(postPeriodTaskActions.request): {
      draft.isPostLoading = true;
      break;
    }
    case getType(postPeriodTaskActions.success): {
      const { status } = action.payload as PostPeriodTaskSuccessPayload;

      draft.isPostLoading = false;
      (draft.periodTask as PeriodTask).status = status;
      break;
    }
    case getType(postPeriodTaskActions.failure): {
      const { status } = action.payload as PostPeriodTaskFailPayload;

      if (status) {
        (draft.periodTask as PeriodTask).status = status;
      }
      draft.isPostLoading = false;
      break;
    }
    default: {
      break;
    }
  }
});

export default produce((draft: State, action) => {
  switch (action.type) {
    case getType(saveCouponAction):
    case getType(getEventCouponActions.request):
    case getType(getEventCouponActions.success):
    case getType(getEventCouponActions.failure): {
      draft.couponData = couponDataProcess(draft.couponData, action);
      break;
    }
    case getType(postCouponRedeemActions.request):
    case getType(postCouponRedeemActions.success):
    case getType(postCouponRedeemActions.failure): {
      const { couponCode } = action.payload;
      const isExistsCoupon = !!draft.couponData.coupons[couponCode];

      if (isExistsCoupon) {
        draft.couponData = couponDataProcess(draft.couponData, action);
      }

      break;
    }
    case getType(getEventPageCoverActions.request):
    case getType(getEventPageCoverActions.success):
    case getType(getEventPageCoverActions.failure): {
      draft.coverData = coverDataProcess(draft.coverData, action);
      break;
    }
    case getType(postPeriodTaskActions.request):
    case getType(postPeriodTaskActions.success):
    case getType(postPeriodTaskActions.failure):
    case getType(getPeriodTaskActions.request):
    case getType(getPeriodTaskActions.success):
    case getType(getPeriodTaskActions.failure): {
      draft.periodTaskData = periodTaskDataProcess(
        draft.periodTaskData,
        action
      );
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
