import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { CouponForRedeem } from '../../types/Coupon';

export interface CouponRedeemPayload {
  couponCode: string;
  shouldVerify?: boolean;
  success?: Function;
  redeemSuccess?: () => void;
  redeemFailure?: Function;
  verifyFailure?: Function;
}

export interface CouponRedeemSuccessPayload {
  couponCode: string;
  couponData: CouponForRedeem;
  logEvent: AnalyticsEvent;
  shouldVerify: boolean;
  success?: Function;
  failure?: Function;
}

export interface CouponRedeemFailPayload {
  error: AxiosError;
  couponCode: string;
  logEvent: AnalyticsEvent;
  customErrorHandling: boolean;
}

export const postCouponRedeemActions = createAsyncAction(
  'verybuy/POST_COUPON_REDEEM',
  'verybuy/POST_COUPON_REDEEM_SUCCESS',
  'verybuy/POST_COUPON_REDEEM_FAIL'
)<CouponRedeemPayload, CouponRedeemSuccessPayload, CouponRedeemFailPayload>();
