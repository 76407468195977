import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { makeItemIsLovedByIdType } from '../../redux/selectors/itemDetailSelector';
import { deleteItemLovesActions } from '../actions/deleteItemLovesActions';
import { putItemLovesActions } from '../actions/putItemLovesActions';
import { deleteItemLoves, putItemLoves } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

type RequestActionTypes = ActionType<
  typeof putItemLovesActions.request | typeof deleteItemLovesActions.request
>;
const outputActions = {
  success: putItemLovesActions.success,
  failure: putItemLovesActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const itemLovesEpic = (
  action$: Observable<RootAction>,
  state$: BehaviorSubject<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(putItemLovesActions.request),
      getType(deleteItemLovesActions.request)
    ),
    debounceTime(200),
    filter((action: RequestActionTypes) => {
      const { id } = action.payload;
      const isCollectAction =
        action.type === getType(putItemLovesActions.request);
      const hasBeenCollected = makeItemIsLovedByIdType(id)(state$.value);

      // 從 store 中檢查收藏狀態，排除多餘的 action。
      return isCollectAction !== hasBeenCollected;
    }),
    switchMap(action => {
      const { id, success, failure } = action.payload;
      const isCollectAction =
        action.type === getType(putItemLovesActions.request);
      const theApi = isCollectAction ? putItemLoves : deleteItemLoves;
      const theActions = isCollectAction
        ? putItemLovesActions
        : deleteItemLovesActions;

      return theApi(id)
        .then(() => {
          if (typeof success === 'function') {
            success(isCollectAction);
          }

          return isCollectAction
            ? putItemLovesActions.success({ id })
            : deleteItemLovesActions.success({
                id,
                shouldRemoveFromList: action.payload.shouldRemoveFromList,
              });
        })
        .catch((error: AxiosError) => {
          const errorResp = error.response;

          if (typeof failure === 'function') {
            failure(isCollectAction, errorResp);
          }

          return theActions.failure({ id, error });
        });
    })
  );
};

export default itemLovesEpic;
