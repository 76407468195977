/**
 * [Purpose]
 * For package resolve which will never be used on web.
 *
 * [Usage]
 * config.resolve.alias = {
 *   ...
 *   'package-never-use': path.resolve(__dirname, 'src/utils/emptyObject.ts'),
 * }
 */
export default {};
