import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { CategoriesPosition } from '../../types/Categories';
import { fetchCategories } from '../actions/categoriesActions';

type PositionState = {
  isFetching: boolean;
  items: number[];
  error: Error | null;
};

interface CategoriesState {
  [CategoriesPosition.Home]: PositionState;
  [CategoriesPosition.Hamburger]: PositionState;
}

const defaultValue: PositionState = {
  isFetching: false,
  items: [],
  error: null,
};

export const defaultState = {
  [CategoriesPosition.Home]: defaultValue,
  [CategoriesPosition.Hamburger]: defaultValue,
};

export default produce(
  (state: CategoriesState, action: ActionType<typeof fetchCategories>) => {
    switch (action.type) {
      case getType(fetchCategories.request): {
        const { position } = action.payload;

        state[position] = { ...(state[position] || defaultValue) };
        state[position].isFetching = true;
        state[position].error = null;
        break;
      }
      case getType(fetchCategories.success): {
        const { categories, position } = action.payload;

        state[position] = { ...(state[position] || defaultValue) };
        state[position].isFetching = false;
        state[position].items = categories;
        break;
      }
      case getType(fetchCategories.failure): {
        const { position } = action.payload;

        state[position] = { ...(state[position] || defaultValue) };
        state[position].error = action.payload.error;
        state[position].isFetching = false;
        break;
      }
      default:
        break;
    }
  },
  defaultState
);
