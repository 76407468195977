import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { Message } from '../../types/SupportDetailResponse';

export interface SupportMessagesPayload {
  id: string;
  page: number;
  loadingType: ListLoadingTypes;
  perPage: number;
}

export interface SupportMessagesSuccessPayload {
  id: string;
  data: Message[];
  page: number;
  perPage: number;
  loadingType: ListLoadingTypes;
}

export interface SupportMessagesFailPayload {
  id: string;
  error: Error;
}

export const getSupportMessagesActions = createAsyncAction(
  'verybuy/GET_SUPPORT_MESSAGES',
  'verybuy/GET_SUPPORT_MESSAGES_SUCCESS',
  'verybuy/GET_SUPPORT_MESSAGES_FAIL'
)<
  SupportMessagesPayload,
  SupportMessagesSuccessPayload,
  SupportMessagesFailPayload
>();
