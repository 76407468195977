import produce from 'immer';
import { getType } from 'typesafe-actions';

import { postMemberVerifySMSActions } from '../actions/postMemberVerifySMSActions';
import { putMemberVerifyPhoneActions } from '../actions/putMemberVerifyPhoneActions';
import {
  PhoneVerificationCodeSuccessPayload,
  putPhoneVerificationCodeActions,
} from '../actions/putPhoneVerificationCodeActions';

export interface State {
  isVerifySMSFetching: boolean;
  isVerifyPhoneFetching: boolean;
  isVerifyCodeLoading: boolean;
  hasError: boolean;
  error: Error | null;
  phone?: number;
  remainingTimes?: number;
}

export const defaultState: State = {
  isVerifySMSFetching: false,
  isVerifyPhoneFetching: false,
  isVerifyCodeLoading: false,
  hasError: false,
  error: null,
};

export default produce((draft: State, action) => {
  switch (action.type) {
    case getType(putPhoneVerificationCodeActions.request): {
      draft.isVerifyCodeLoading = true;
      break;
    }
    case getType(putPhoneVerificationCodeActions.success): {
      const payload: PhoneVerificationCodeSuccessPayload = action.payload;

      draft.isVerifyCodeLoading = false;
      draft.remainingTimes = payload.remainingTimes;
      draft.hasError = false;
      draft.error = null;
      break;
    }
    case getType(putPhoneVerificationCodeActions.failure): {
      draft.isVerifyCodeLoading = false;
      draft.hasError = true;
      draft.error = action.payload.error;
      break;
    }

    case getType(postMemberVerifySMSActions.request): {
      draft.isVerifySMSFetching = true;
      break;
    }
    case getType(postMemberVerifySMSActions.success): {
      draft.isVerifySMSFetching = false;
      draft.hasError = false;
      draft.error = null;
      break;
    }
    case getType(postMemberVerifySMSActions.failure): {
      draft.isVerifySMSFetching = false;
      draft.hasError = true;
      draft.error = action.payload.error;
      break;
    }

    case getType(putMemberVerifyPhoneActions.request): {
      draft.isVerifyPhoneFetching = true;
      break;
    }
    case getType(putMemberVerifyPhoneActions.success): {
      draft.isVerifyPhoneFetching = false;
      draft.hasError = false;
      draft.error = null;
      break;
    }
    case getType(putMemberVerifyPhoneActions.failure): {
      draft.isVerifyPhoneFetching = false;
      draft.hasError = true;
      draft.error = action.payload.error;
      break;
    }
    default: {
      break;
    }
  }
}, defaultState);
