// Ref: https://github.com/piotrwitek/typesafe-actions#behold-the-mighty-tutorial
import { createAsyncAction } from 'typesafe-actions';

import { Data as ItemContent } from '../../types/ItemContent';

export interface ItemContentPayload {
  id: string;
}

export interface ItemContentSuccessPayload {
  id: string;
  data: ItemContent;
}

export interface ItemContentFailPayload {
  id: string;
  error: Error;
}

export const getItemContentData = createAsyncAction(
  'verybuy/GET_ITEM_CONTENT',
  'verybuy/GET_ITEM_CONTENT_SUCCESS',
  'verybuy/GET_ITEM_CONTENT_FAIL'
)<ItemContentPayload, ItemContentSuccessPayload, ItemContentFailPayload>();
