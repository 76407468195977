import { Platform, StyleSheet } from 'react-native';

import { white } from '../../../constants/color';
import statusBarHeight from '../../../constants/statusBarHeight';

const styles = StyleSheet.create({
  container: {
    height: 45 + statusBarHeight,
    paddingTop: statusBarHeight,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 1,
    overflow: 'visible',
  },
  emptyLeft: {
    width: 16,
  },
  leftField: {
    paddingHorizontal: 10,
    width: 50,
  },
  middleField: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#BF494F',
    borderRadius: 3,
    paddingLeft: 7,
    paddingRight: 10,
    height: 33,
  },
  middleFieldWithMarginLeft: {
    marginLeft: 15,
  },
  middleFieldWithMarginRight: {
    marginRight: 10,
  },
  fakeSearchInput: {
    position: 'relative',
    flexDirection: 'row',
    borderRadius: 3,
    paddingBottom: 0,
    marginRight: 10,
    alignItems: 'center',
  },
  searchInput: {
    flex: 1,
    color: white,
    alignContent: 'center',
    width: Platform.OS === 'web' ? '100%' : undefined,
    height: Platform.OS === 'web' ? '100%' : undefined,
    fontSize: 15,
    marginLeft: 5,
    ...Platform.select({
      android: {
        paddingTop: 1.5,
        paddingBottom: 0,
        paddingLeft: 0,
      },
    }),
  },
  fakeSearchBasicStyle: {
    flex: 1,
    width: '100%',
    fontSize: 15,
    marginLeft: 5,
  },
  fakeSearchText: {
    color: white,
  },
  fakeSearchPlaceholder: {
    color: `${white}80`,
  },
  modalStyle: {
    position: 'absolute',
    top: 30,
    width: '100%',
    height: 600,
    backgroundColor: white,
    opacity: 0.01,
  },
  cancelBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 10,
    height: '100%',
  },
  cancelBtnText: {
    fontSize: 15,
    color: white,
  },
});

export default styles;
