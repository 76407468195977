import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { logAnalyticsEvent } from '../../../../redux/actions/analyticsLog';
import { makeRecommendSubjects } from '../../../../redux/selectors/recommendSubjectSelector';
import {
  getTopKeywordsData,
  makePlaceholder,
} from '../../../../redux/selectors/SearchFormPageSelector';
import SearchFormPage from './SearchFormPage';

const mapStateToProps = () => {
  const selector = createStructuredSelector({
    topKeywords: getTopKeywordsData('data', []),
    recommendSubjects: makeRecommendSubjects(),
    placeholder: makePlaceholder(),
    isFetchingTopKeywords: getTopKeywordsData('isFetching', false),
  });

  return state => selector(state);
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logEvent: logAnalyticsEvent,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SearchFormPage);
