import pick from 'lodash/pick';
import { createSelector } from 'reselect';

import { DeliveryMode } from '../../types/Common';
import type { CVSDeliveryDataFromSelector } from '../../types/DeliveryData';
import * as countryInfo from '../../utils/countryInfo';
import { countryCodeHandler } from '../../utils/countryInfo';
import isExists from '../../utils/isExists';
import { RootState } from '../rootReducer';

const cvsListRow = (state: RootState) => state?.checkoutReducer?.cvsList || {};

export const makeIsCVSListLoading = () => {
  return createSelector(cvsListRow, data => {
    const isLoading = data?.isLoading;

    return isExists(isLoading) ? isLoading : true;
  });
};

export const makeIsCVSListExist = () => {
  return createSelector(cvsListRow, data => isExists(data?.data?.[0]));
};

export const makeCVSListByIndex = (
  index,
  countryCode = countryCodeHandler.get()
) => {
  return createSelector(cvsListRow, data => {
    const country = countryInfo.getCountryInfoByCode(countryCode);
    const result = data?.data?.[index] || {
      area_phone_code: country.areaPhoneCode,
    };

    return result;
  });
};

export const makeLatestCVSList = (countryCode = countryCodeHandler.get()) => {
  return createSelector(makeCVSListByIndex(0, countryCode), data => {
    const personInfo = pick(data, ['name', 'area_phone', 'area_phone_code']);
    const cityRegexp = new RegExp(
      '([^縣市]{1,3}[縣市])([^鄉鎮市區]{1,3}[鄉鎮市區]?)(.*)',
      'u'
    );

    const specialZone = ['平鎮', '前鎮', '新市'];

    const address = data?.store?.address;
    let cityAndZone = data?.store?.address
      ? address?.replace(cityRegexp, '$1 $2')
      : '';
    let road = address?.replace(address.replace(cityRegexp, '$1$2'), '');

    specialZone.forEach(zone => {
      if (zone === address?.replace(cityRegexp, '$2')) {
        const replaceRegexp = new RegExp(
          '([^縣市]{1,3}[縣市])([^鄉區]{1,3}[鄉鎮市區]?)(.*)',
          'u'
        );

        cityAndZone = data?.store?.address
          ? address?.replace(replaceRegexp, '$1 $2')
          : '';
        road = address?.replace(address.replace(replaceRegexp, '$1$2'), '');
      }
    });

    const deliveryData: CVSDeliveryDataFromSelector = {
      ...personInfo,
      delivery_mode: DeliveryMode.CONVENIENCE_STORE_PICKUP.valueOf(),
      address_label: data?.store ? data?.store?.name : '',
      address,
      storeID: data?.store?.id,
      store: data?.store || {},
      city: data?.store?.city ?? '',
      road: road || data?.store?.road || '',
      city_and_zone: cityAndZone,
      hasRoadData: false,
    };

    return deliveryData;
  });
};
