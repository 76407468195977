import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  listItemBox: {
    marginTop: 10,
    marginBottom: 8,
  },
  avgRatingStars: {
    marginTop: 8,
  },
});

export default styles;
