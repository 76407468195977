import safeAwait from 'safe-await';

import firebaseConfig from '../../../firebase.json';
import { isServer } from '../../boot/utils';
import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import {
  RESULTS,
  checkNotifications,
  requestNotifications,
} from '../../utils/RNPermission';
import sensors from '../analytics/sensors';
import firebase from '../packages/firebase';
import notifee, { AndroidImportance, EventType } from '../packages/notifee';
import logPushNotification from './logPushNotification';
import { PushNotificationType } from './types';

interface Args {
  done: () => void;
  isFirstLaunch?: boolean;
}

export function requestNotiPermission(args: Args) {
  if (isServer) {
    return;
  }

  const { done, isFirstLaunch } = args;

  requestNotifications(['alert', 'sound'])
    .then(async () => {
      const [error, permission] = await safeAwait(checkNotifications());

      if (!error && permission) {
        const enabled =
          permission.status === RESULTS.GRANTED ||
          permission.status === RESULTS.LIMITED;

        const profileParams: Record<string, boolean> = {
          noti: enabled,
        };

        if (isFirstLaunch) {
          profileParams.is_first_noti_allowed = enabled;
        }

        sensors.profileSet(profileParams);
      }
    })
    .catch(e => {
      console.log('Request notification permission error:', e);
    })
    .finally(() => {
      done();
    });
}

export default () => {
  if (isServer) {
    return;
  }

  const { messaging } = firebase;

  const androidChannelId =
    firebaseConfig['react-native'].messaging_android_notification_channel_id;

  notifee
    .isChannelCreated(androidChannelId)
    .then(exists => {
      if (exists) {
        return;
      }

      notifee
        .createChannel({
          id: androidChannelId,
          name: 'Verybuy',
          // importance should be HEIGH to show notification in front of application
          importance: AndroidImportance.HIGH,
        })
        .catch(() => {
          console.log('createChannel error');
        });
    })
    .catch(() => {
      console.log('isChannelCreated error');
    });

  notifee.setBadgeCount(0).catch(e => {
    console.log('setBadgeCount error:', e);
  });

  // app open by notification
  // When the application is running, but in the background.
  // https://rnfirebase.io/messaging/notifications#handling-interaction
  messaging().onNotificationOpenedApp(remoteMessage => {
    logPushNotification(remoteMessage, {
      eventName: AnalyticsEvent.PushClick,
      type: PushNotificationType.Firebase,
    });
  });

  // should handle press event for foreground notifications
  // https://notifee.app/react-native/docs/android/interaction#handling-interaction
  notifee.onForegroundEvent(({ type, detail }) => {
    if (type === EventType.PRESS) {
      logPushNotification(detail, {
        eventName: AnalyticsEvent.PushClick,
        type: PushNotificationType.Notifee,
      });
    }
  });

  // should handle press event for background notifications
  notifee.onBackgroundEvent(async ({ type, detail }) => {
    if (type === EventType.PRESS) {
      logPushNotification(detail, {
        eventName: AnalyticsEvent.PushClick,
        type: PushNotificationType.Notifee,
      });
    }
  });

  messaging()
    .getInitialNotification()
    .then(remoteMessage => {
      if (remoteMessage) {
        logPushNotification(remoteMessage, {
          eventName: AnalyticsEvent.PushClick,
          type: PushNotificationType.Firebase,
        });
      }
    })
    .catch(e => {
      console.log('firebase messaging getInitialNotification error:', e);
    });
};
