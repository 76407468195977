import { createAsyncAction } from 'typesafe-actions';

import MarketingEvent from '../../types/MarketingEvent';

export interface GetMarketingEventsPayload {}

export interface GetMarketingEventsSuccessPayload {
  data: MarketingEvent[];
}

export interface GetMarketingEventsFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getMarketingEventsActions.request>;
}

const getMarketingEventsActions = createAsyncAction(
  'verybuy/GET_MARKETING_EVENTS',
  'verybuy/GET_MARKETING_EVENTS_SUCCESS',
  'verybuy/GET_MARKETING_EVENTS_FAIL'
)<
  GetMarketingEventsPayload,
  GetMarketingEventsSuccessPayload,
  GetMarketingEventsFailPayload
>();

export default getMarketingEventsActions;
