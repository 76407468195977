import { createAsyncAction } from 'typesafe-actions';

import { ListLoadingTypes } from '../../types/Common';
import { LoveItem } from '../../types/LoveList';

export interface LoveListPayload {
  loadingType: ListLoadingTypes;
  page: number;
  success: (products: LoveItem[]) => void;
}

export interface LoveListSuccessPayload {
  items: LoveItem[];
  loadingType: ListLoadingTypes;
  page: number;
}

export interface LoveListFailPayload {
  error: Error;
  requestAction: ReturnType<typeof getLoveListActions.request>;
}

export const getLoveListActions = createAsyncAction(
  'verybuy/GET_LOVE_LIST',
  'verybuy/GET_LOVE_LIST_SUCCESS',
  'verybuy/GET_LOVE_LIST_FAIL'
)<LoveListPayload, LoveListSuccessPayload, LoveListFailPayload>();
