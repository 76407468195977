import { useEffect, useState } from 'react';

import {
  ExperimentName,
  getABTestingGroup,
} from '../../utils/abTestingHelpers';

export default function useABTestingGroup() {
  const [abTestingGroup, setAbTestingGroup] = useState('{}');

  useEffect(() => {
    Object.values(ExperimentName).forEach(name => {
      getABTestingGroup(name as unknown as ExperimentName).then(group => {
        setAbTestingGroup(prev => {
          const parse = JSON.parse(prev);

          parse[name] = group;

          return JSON.stringify(parse, null, 2);
        });
      });
    });
  }, []);

  return abTestingGroup;
}
