import { createAsyncAction } from 'typesafe-actions';

import { Announcements } from '../../types/Announcement';
import { ListLoadingTypes } from '../../types/Common';

export interface AnnouncementsPayload {
  loadingType: ListLoadingTypes;
}

export interface AnnouncementsSuccessPayload {
  data: Announcements[];
}

export interface AnnouncementsFailPayload {
  error: Error;
}

export const getAnnouncementsActions = createAsyncAction(
  'verybuy/GET_ANNOUNCEMENTS',
  'verybuy/GET_ANNOUNCEMENTS_SUCCESS',
  'verybuy/GET_ANNOUNCEMENTS_FAIL'
)<
  AnnouncementsPayload,
  AnnouncementsSuccessPayload,
  AnnouncementsFailPayload
>();
