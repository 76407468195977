import { AxiosResponse } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { LoginOrRegisterMethod } from '../../constants/LoginOrRegisterMethod';
import { MemberLoginByFacebookResponse } from '../../types/Member';

export interface LoginByFacebookPayload {
  fb_access_token: string;
  userID: string;
  success: Function;
  loginMethod: LoginOrRegisterMethod;
  fcm_token?: string;
}

export interface LoginByFacebookSuccessPayload {
  result: AxiosResponse<MemberLoginByFacebookResponse['data']>;
  account: string;
  logEvent: AnalyticsEvent;
  method: LoginOrRegisterMethod;
}

export interface LoginByFacebookFailPayload {
  error: Error;
}

export const loginByFacebook = createAsyncAction(
  'verybuy/LOGIN_BY_FACEBOOK',
  'verybuy/LOGIN_BY_FACEBOOK_SUCCESS',
  'verybuy/LOGIN_BY_FACEBOOK_FAIL'
)<
  LoginByFacebookPayload,
  LoginByFacebookSuccessPayload,
  LoginByFacebookFailPayload
>();
