import HeaderTitleWithHelmet from '../../../elements/HeaderTitleWithHelmet';
import HeaderLeft from '../defaultCustomNavbar/HeaderLeft';
import HeaderRight from '../defaultCustomNavbar/HeaderRight';
import { PropsNavigationType } from '../navFactoryTypes';
import { S } from './styles';

interface AnyForObj {
  [key: string]: any;
}

interface Props {
  isFocused: boolean | undefined;
  isLogin?: boolean;
  leftOpts?: AnyForObj;
  navigation?: PropsNavigationType;
  rightOpts?: AnyForObj;
  screen: string;
  shouldLogin?: boolean;
  title: string;
}

export default function DefaultHeader(
  {
    isFocused,
    isLogin,
    leftOpts,
    navigation,
    rightOpts,
    screen,
    shouldLogin,
    title,
  }: Props = {} as Props
) {
  if (shouldLogin && !isLogin) {
    return null;
  }

  return (
    <S.HeaderWrapper>
      <S.Left>
        <HeaderLeft
          navigation={navigation}
          screen={screen}
          isFocused={isFocused}
          {...(leftOpts || {})}
        />
      </S.Left>
      <S.Center>
        <S.Title>
          <HeaderTitleWithHelmet headerTitle={title} hidden />
          <S.TitleText accessibilityRole="header" numberOfLines={1}>
            {title}
          </S.TitleText>
        </S.Title>
      </S.Center>
      <S.Right>
        <HeaderRight
          navigation={navigation}
          screen={screen}
          isFocused={isFocused}
          {...(rightOpts || {})}
        />
      </S.Right>
    </S.HeaderWrapper>
  );
}
