import { isBot } from '../../constants/misc';
import {
  getActiveRouteName,
  processItemPageScreenView,
  processScreenView,
} from '../../utils/analytics/getScreenView';
import { setCurrentScreen as sensorsSetScreen } from '../../utils/analytics/sendSensorsEvents';
import * as navigationService from '../navigationService';
import { setCurrentScreen as firehoseSetScreen } from './awsFirehose';
import { setCurrentScreen as pixelSetScreen } from './FacebookPixel/utils.web';
import { setCurrentScreen as firebaseSetScreen } from './firebase/getLogPayloadForFirebase';

export const setCurrentScreen = (_screen: string, screenName: string) => {
  // ignore bot request
  if (isBot) return;
  const sendScreenName = screenName || 'UNKNOWN_SCREEN_NAME';

  firebaseSetScreen({ screen_name: sendScreenName });
  pixelSetScreen();
};

/**
 * @name sendWebScreenView
 * @description This method is used on web send pageview.
 */
export const sendWebScreenView = () => {
  firehoseSetScreen();
  sensorsSetScreen();
};

export const sendScreenViewEvent = (_prevState, currentState) => {
  const currentScreen = currentState.hasOwnProperty('index')
    ? navigationService.getActiveRoute(currentState)
    : currentState;
  const currentRouteName = getActiveRouteName(currentScreen);

  if (!currentState.isTransitioning) {
    // Don't send screen view event for searchResultPage and listPage,
    // because SearchResultPage and ListPage components did it.
    if (['SearchResultPage', 'ListPage'].includes(currentRouteName)) {
      return;
    }

    const processedScreenView = processScreenView(
      processItemPageScreenView(currentRouteName)
    );

    const { routeName } = currentScreen;

    setCurrentScreen(processedScreenView, routeName);
  }
};
