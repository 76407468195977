import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { MemberVerifyFail } from '../../types/MemberVerify';
import { postMemberVerifySMSActions } from '../actions/postMemberVerifySMSActions';
import { postMemberVerifySMS } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: postMemberVerifySMSActions.success,
  failure: postMemberVerifySMSActions.failure,
};

type RequestActionTypes = ActionType<typeof postMemberVerifySMSActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const postMemberVerifySMSEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(postMemberVerifySMSActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { verificationCode, success, failure } = action.payload;

      return postMemberVerifySMS(verificationCode)
        .then(() => {
          success();

          return postMemberVerifySMSActions.success();
        })
        .catch((error: AxiosError<MemberVerifyFail>) => {
          failure(error.response?.data);

          return postMemberVerifySMSActions.failure({
            error,
            customErrorHandling: true,
          });
        });
    })
  );
};

export default postMemberVerifySMSEpic;
