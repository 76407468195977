import { createAsyncAction } from 'typesafe-actions';

import { PeriodTaskStatus } from '../../types/PeriodTaskResponse';

export interface PostPeriodTaskPayload {
  id: string;
  onSuccess: () => void;
  onFailure: (status: PeriodTaskStatus) => void;
}

export interface PostPeriodTaskSuccessPayload {
  status: PeriodTaskStatus;
}

export interface PostPeriodTaskFailPayload {
  error?: Error;
  status?: PeriodTaskStatus;
  customErrorHandling?: boolean;
}

export const postPeriodTaskActions = createAsyncAction(
  'verybuy/POST_PERIOD_TASK',
  'verybuy/POST_PERIOD_TASK_SUCCESS',
  'verybuy/POST_PERIOD_TASK_FAIL'
)<
  PostPeriodTaskPayload,
  PostPeriodTaskSuccessPayload,
  PostPeriodTaskFailPayload
>();
