import { NavigationStackOptions } from 'react-navigation-stack';

import headerContainerCreator from '../headerContainerCreator';
import { LinearGradientNavbarParams } from '../navFactoryTypes';
import LinearGradientBar from './LinearGradientBar';

export default function createLinearGradientNavbar(
  {
    headerTitle,
    headerTitleImage,
    leftOpts = {},
    navigation,
    rightOpts = {},
    screen,
    shouldLogin,
    withoutNavLeft = false,
    enableHelmet = true,
    enableShareBtn,
    shareOpts = {},
    description,
    ...rest
  }: LinearGradientNavbarParams = {} as LinearGradientNavbarParams
): NavigationStackOptions {
  return {
    header: ({ scene }) => {
      const HeaderContainer = headerContainerCreator(LinearGradientBar, {
        shouldLogin,
      });

      return (
        <HeaderContainer
          isFocused={scene.isActive}
          leftOpts={leftOpts}
          navigation={navigation}
          rightOpts={rightOpts}
          screen={screen}
          shouldLogin={shouldLogin}
          title={headerTitle}
          headerTitleImage={headerTitleImage}
          withoutNavLeft={withoutNavLeft}
          enableHelmet={enableHelmet}
          enableShareBtn={enableShareBtn}
          shareOpts={shareOpts}
          description={description}
        />
      );
    },
    ...(rest || {}),
  };
}
