import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getRecommendSubjectActions } from '../actions/getRecommendSubjectActions';
import { getRecommendSubjects } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getRecommendSubjectActions.success,
  failure: getRecommendSubjectActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getTopKeywordsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getRecommendSubjectActions.request)),
    switchMap(() => {
      return getRecommendSubjects()
        .then(response =>
          getRecommendSubjectActions.success({
            data: response.data,
          })
        )
        .catch((error: AxiosError) =>
          getRecommendSubjectActions.failure({ error })
        );
    })
  );
};

export default getTopKeywordsEpic;
