import { Dimensions, Platform } from 'react-native';

import { DeviceType } from '../types/Common';
import { getDeviceType, getScrollbarWidth } from '../utils/layoutHelpers';

export const DIMENSION_WIDTH = Dimensions.get('window').width;
export const GET_DIMENSION_WIDTH = () => Dimensions.get('window').width;
export const DIMENSION_HEIGHT = Dimensions.get('window').height;
export const GET_DIMENSION_HEIGHT = () => Dimensions.get('window').height;
export const WEB_MAX_WIDTH = 1024;
export const DEVICE_TYPE = getDeviceType();
export const IS_TABLET = DEVICE_TYPE === DeviceType.TABLET;

export const GET_LAYOUT_MAX_WIDTH = () => {
  const maxWidth =
    Platform.OS === 'web' && GET_DIMENSION_WIDTH() >= WEB_MAX_WIDTH
      ? WEB_MAX_WIDTH
      : GET_DIMENSION_WIDTH();

  return maxWidth - getScrollbarWidth();
};
export const LAYOUT_MAX_WIDTH = GET_LAYOUT_MAX_WIDTH();

export const EMPTY_SCREEN_CONTENT_TOP_OFFSET = 80;

export enum LAYOUT_SPAN {
  'ITEM_SPAN_1' = 1,
  'ITEM_SPAN_2' = 2,
  'ITEM_SPAN_4' = 4,
}

export enum LAYOUT_TYPE {
  'ITEM_SPAN_1' = 'ITEM_SPAN_1',
  'ITEM_SPAN_2' = 'ITEM_SPAN_2',
  'ITEM_SPAN_4' = 'ITEM_SPAN_4',
}

const LIST_ITEM_MAPPING = {
  [DeviceType.MOBILE]: 2,
  [DeviceType.TABLET]: 4,
  [DeviceType.DESKTOP]: 4,
};

const DEFAULT_NUM = 2;

export const LIST_ITEM_NUM = LIST_ITEM_MAPPING[DEVICE_TYPE] || DEFAULT_NUM;
export const GET_LIST_ITEM_NUM = () => {
  const deviceType = getDeviceType();

  return LIST_ITEM_MAPPING[deviceType] || DEFAULT_NUM;
};

export const LIST_LAYOUT_TYPE = {
  [DeviceType.MOBILE]: LAYOUT_TYPE.ITEM_SPAN_2,
  [DeviceType.TABLET]: LAYOUT_TYPE.ITEM_SPAN_1,
  [DeviceType.DESKTOP]: LAYOUT_TYPE.ITEM_SPAN_1,
};

export const LIST_LAYOUT_SPAN = {
  [DeviceType.MOBILE]: LAYOUT_SPAN.ITEM_SPAN_2,
  [DeviceType.TABLET]: LAYOUT_SPAN.ITEM_SPAN_1,
  [DeviceType.DESKTOP]: LAYOUT_SPAN.ITEM_SPAN_1,
};
