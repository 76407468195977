import { useMemo } from 'react';
import { useCallback } from 'react';

import { AnalyticsEvent } from '../../../constants/AnalyticsEvent';
import { MktType } from '../../../constants/MktType';
import Banner from '../../../elements/Banner';
import Touchable from '../../../elements/Touchable';
import useActions from '../../../hooks/useActions';
import { logAnalyticsEvent } from '../../../redux/actions/analyticsLog';
import { makeBannerDataByID } from '../../../redux/selectors/bannersSelector';
import { useSelector } from '../../../redux/utils';
import { bannerNavigation } from '../../../utils/pageNavigation';
import { basicProducer } from '../../FlatListWithImpression/utils';

export interface Props {
  bannerID: string;
  bannerSize: { width: number; height: number };
  index: number;
  showLoading: boolean;
}

export const layoutName = 'index_operation_banner';
export const bannerPositions = ['index_operation'];

export default (props: Props) => {
  const { showLoading, bannerID, bannerSize, index } = props;
  const banner = useSelector(makeBannerDataByID(bannerID));

  const sources = useMemo(() => {
    if (banner?.image_urls) {
      return {
        1: { uri: banner.image_urls.sm },
        2: { uri: banner.image_urls.md },
        3: { uri: banner.image_urls.lg },
      };
    }

    return { 1: require('./assets/img_cbn_default.png') };
  }, [banner]);

  const bannerStyle = useMemo(() => {
    const marginRight = 4;
    const marginBottom = 8;

    return {
      width: bannerSize.width - marginRight,
      marginRight,
      height: bannerSize.height - marginBottom,
      marginBottom,
      borderRadius: 3,
    };
  }, [bannerSize]);

  const logEvent = useActions(logAnalyticsEvent);

  const logData = useMemo(
    () =>
      basicProducer(
        { item: bannerID, index },
        { layoutName, mktType: MktType.Image }
      ),
    [bannerID, index, layoutName]
  );

  const handleBannerPressed = useCallback(() => {
    if (banner) {
      const { url, title } = banner;

      if (url) {
        bannerNavigation(banner);
      }

      logEvent({
        logEvent: AnalyticsEvent.Click,
        data: logData,
      });
      logEvent({
        logEvent: AnalyticsEvent.HomePageClick,
        data: {
          layoutName,
          title,
          index,
        },
      });
    }
  }, [banner, logEvent, logData, index]);

  return (
    <Touchable
      disabled={typeof banner === 'undefined' || showLoading}
      key={`promote-banner${banner?.id}`}
      accessibilityRole="button"
      navigateTo={handleBannerPressed}
    >
      <Banner
        id={bannerID}
        sources={sources}
        bannerStyle={bannerStyle}
        isFetching={showLoading}
        height={bannerStyle.height}
        width={bannerStyle.width}
      />
    </Touchable>
  );
};
