import { createAction } from 'typesafe-actions';

import { isWebPlatform } from '../../boot/utils';
import * as cookieHelper from '../../utils/cookieHelper';
import { ForceLogoutClassicWeb } from '../../utils/memberHelpers';
import * as sensorsHelpers from '../../utils/sensorsHelpers';
import { logout as logoutRequest } from '../api';
import { persiststore } from '../persiststore';

// logout  action 不管 API 成功與否, 因此直接call API, 並且直接 resolve
const logoutAction = createAction('verybuy/LOGOUT', resolve => {
  return () => {
    // fire and forget
    // catch to avoid unhandled promise warning
    logoutRequest()
      // 不管有沒有登出成功都要執行這些動作
      .finally(async () => {
        if (isWebPlatform) {
          // Note: this is workaround to fix login account is different in RNWEB/Web
          // https://verybuycc.atlassian.net/browse/RNWEB-552
          try {
            await ForceLogoutClassicWeb();
          } catch (e) {
            // @ts-ignore
            // ignore error
          }
        }
        await cookieHelper.clearMemberCookie();
        persiststore.purge();
        sensorsHelpers.handleLogout();
      });

    // then always resolve
    return resolve();
  };
});

export default logoutAction;
