import { Component, FC, PureComponent } from 'react';

import showToast from '../../utils/showToast';

/**
 * withShowToast 已棄用，請改用 src/utils/showToast.ts
 *
 * @deprecated
 */
export interface WithShowToastProps {
  showToast: typeof showToast;
}

/**
 * withShowToast 已棄用，請改用 src/utils/showToast.ts
 *
 * @deprecated
 */
export default (WrappedComponent: typeof Component | FC<any>) => {
  return class extends PureComponent<any> {
    public render() {
      return <WrappedComponent {...this.props} showToast={showToast} />;
    }
  };
};
