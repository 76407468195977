import { memo } from 'react';

import IconFire from '../../../../../assets/svg/IconFire';
import { TopKeywords } from '../../../../../types/TopKeywordsResponse';
import isExists from '../../../../../utils/isExists';
import { S } from './style';

interface Props {
  onPress: () => void;
  data: TopKeywords;
}

const HotBubble = ({ onPress, data }: Props) => {
  if (!isExists(data.title) || data.title.length === 0) {
    return null;
  }

  return (
    <S.HotBubbleBtn accessibilityRole="link" onPress={onPress}>
      <IconFire size={18} />
      <S.HotBubbleText numberOfLines={1}>{data.title}</S.HotBubbleText>
    </S.HotBubbleBtn>
  );
};

export default memo(HotBubble);
