import { AxiosResponse } from 'axios';
import useSWR, { SWRConfiguration } from 'swr';

import { baseAxios } from '../redux/api/utils';

type Args<P> = {
  params?: P;
  config?: SWRConfiguration;
};
type Key<T, P> = ((params: P) => Promise<AxiosResponse<T>>) | string | null;

export function getFetcher<T, P extends object>(
  key: Exclude<Key<T, P>, null>,
  params: P
) {
  const axiosPromise =
    typeof key === 'function' ? key(params) : baseAxios.get<T>(key, { params });

  return axiosPromise.then(resp => resp.data);
}

function useRequest<T, P extends object = object>(
  key: Key<T, P>,
  args: Args<P> = {}
) {
  const { params = {}, config } = args;

  return useSWR<T>([key, params], {
    fetcher: getFetcher,
    revalidateOnFocus: false,
    ...config,
  });
}

export default useRequest;
