import { AxiosError, AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { TransactionList } from '../../types/TransactionListForResponse';
import getTransactionListActions from '../actions/getTransactionList';
import { getTransactionList } from '../api';
import { RootAction } from '../rootAction';

type RequestActionTypes = ActionType<typeof getTransactionListActions.request>;

const outputActions = {
  success: getTransactionListActions.success,
  failure: getTransactionListActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;

const getTransactionListEpic = (
  action$: Observable<RootAction>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getTransactionListActions.request)),
    concatMap((action: RequestActionTypes) => {
      const { page, loadingType, type } = action.payload;

      return getTransactionList({ page, type })
        .then((response: AxiosResponse<TransactionList>) => {
          return getTransactionListActions.success({
            items: response.data?.transactions || [],
            loadingType,
            page,
            type,
          });
        })
        .catch((error: AxiosError) => {
          return getTransactionListActions.failure({
            error,
            type,
          });
        });
    })
  );
};

export default getTransactionListEpic;
