import CookieManager from 'js-cookie';

import Config from '../configs';
import { ClearCookieParams, SetCookieParams } from './types';

const cookies = CookieManager.withConverter({
  // @ts-expect-error: Type 'string | object' is not assignable to type 'string'
  write(value) {
    return value;
  },
});

export function getCookie(name: string) {
  const value = CookieManager.get(name);

  return Promise.resolve(value);
}

export function clearCookie({ name, options }: ClearCookieParams) {
  const domain = `.${Config.COOKIE_DOMAIN}`;

  cookies.remove(name, options || { path: '/', domain });

  return Promise.resolve();
}

export function setCookie({ name, value, options }: SetCookieParams) {
  const domain = `.${Config.COOKIE_DOMAIN}`;

  cookies.set(name, value, options || { path: '/', domain });

  return Promise.resolve();
}

export async function clearMemberCookie() {
  await clearCookie({ name: 'MID' });
  await clearCookie({ name: 'login_status' });
}
