import type firebaseType from 'firebase';

import { FirebaseConfig } from '../../../constants/FirebaseConfig';
import { REMOTE_CONFIG_CACHE_MILLIS } from '../../../constants/misc';
import sensors from '../../analytics/sensors';
import Configs from '../../configs';

let _firebase: typeof import('firebase/app').default;

function initFirebase() {
  if (_firebase) {
    return Promise.resolve(_firebase);
  }

  return Promise.all([
    import('firebase/app'),
    import('firebase/database'),
    import('firebase/remote-config'),
    import('firebase/messaging'),
  ])
    .then(([firebaseModule]) => firebaseModule.default)
    .then(firebase => {
      // ref: https://github.com/vercel/next.js/issues/1999#issuecomment-302244429
      if (!firebase.apps.length) {
        firebase.initializeApp(FirebaseConfig);
      }

      if (!firebase) {
        return Promise.reject(new Error('firebase loading error.'));
      }

      _firebase = firebase;

      return firebase;
    })
    .catch(error => {
      throw new Error(error);
    });
}

export const isSupportedWebPush = () => {
  // bollowing code is copied from /node_modules/@firebase/messaging/src/index.ts#95 isSupported
  return (
    'indexedDB' in window &&
    indexedDB !== null &&
    navigator.cookieEnabled &&
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window &&
    'fetch' in window &&
    ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
    PushSubscription.prototype.hasOwnProperty('getKey')
  );
};

const remoteConfig = () =>
  initFirebase().then(firebase => firebase.remoteConfig);

export default {
  dynamicLinks: () => ({
    resolveLink: () => Promise.reject(),
  }),
  analytics: () => ({
    // Don't send any analytics message to firebase.
    logScreenView: async () => null,
    setUserId: async () => null,
    logEvent: async () => null,
  }),
  notifications: () => ({
    getInitialNotification: async () => {
      console.log('TODO: firebase notifications getInitialNotification');
    },
    onNotificationOpened: _notificationHandler => {
      console.log('TODO: firebase notifications onNotificationOpened');
    },
    onNotification: _sentNotification => {
      console.log('TODO: firebase notifications onNotification');
    },
    setBadge: _badge => {
      console.log('TODO: firebase notifications setBadge');
    },
  }),
  database: () => initFirebase().then(firebase => firebase.database),
};

let _fetchedFCMToekenPromise: Promise<string> | undefined;

const fetchedFCMToken = async () => {
  const firebase = await initFirebase();
  const messaging = firebase.messaging();

  if (_fetchedFCMToekenPromise === undefined) {
    _fetchedFCMToekenPromise = messaging.getToken({
      vapidKey: Configs.REACT_APP_FIREBASE_VAPIDKEY,
    });
  }

  return _fetchedFCMToekenPromise;
};

export async function getFCMToken(checkPermission = false) {
  if (!isSupportedWebPush()) {
    return '';
  }

  if (!checkPermission) {
    if (Notification.permission === 'default') {
      return '';
    }
  }

  try {
    const FCMToken = await fetchedFCMToken();

    sensors.setProfile({ browserTokens: FCMToken });

    return FCMToken;
  } catch (e) {
    console.error(e);
    _fetchedFCMToekenPromise = undefined;
  }

  return '';
}

let _fetchedRemotelyPromise: Promise<boolean>;

export const fetchConfig = () => {
  if (typeof _fetchedRemotelyPromise === 'undefined') {
    _fetchedRemotelyPromise = remoteConfig().then(config => {
      config().settings.minimumFetchIntervalMillis = REMOTE_CONFIG_CACHE_MILLIS;

      return config().fetchAndActivate();
    });
  }

  return _fetchedRemotelyPromise;
};

export const getRemoteValue = (key: string) => {
  return Promise.all([remoteConfig(), fetchConfig()]).then(([config]) =>
    config().getValue(key)
  );
};

export type FirebaseRemoteConfigTypes = firebaseType.remoteConfig.RemoteConfig;
export type FirebaseAnalyticsTypes = firebaseType.analytics.Analytics;
export type FirebaseMessagingTypes = firebaseType.messaging.Messaging;
