// 考慮"直接進入頁面"的情況(如: web url, pageNavigation)，若該參數
// 1. 多餘的，不需要透過該方式進入 react-navigation lifecycle
// 2. 該數值可能暴露敏感資訊，僅作用在某些特定時機點(如: LineID)
// 3. 該數值可能影響數據（如: IsUserUsedFilter)
// 以上情況可在 utils/handleIllegalParams 中加入

export enum NavigationParams {
  AlsoLikeGroup = 'alg',
  BackToPageKey = 'btpk',
  BackWithKey = 'bwk',
  EncodedPageParams = 'ep',
  IsUserUsedFilter = 'iuuf',
  LayoutName = 'ln',
  LineID = 'clid',
  ShouldScrollToProductList = 'sstp',
}
