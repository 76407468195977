/* eslint-disable global-require */

import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import { Component } from 'react';
import { KeyboardAvoidingView, ScrollView, View } from 'react-native';

import { AnalyticsEvent } from '../../../../constants/AnalyticsEvent';
import LoadingView from '../../../../elements/LoadingView';
import Touchable from '../../../../elements/Touchable';
import { translate } from '../../../../i18n';
import { getSuggestion } from '../../../../redux/api/df';
import { RecommendSubject } from '../../../../types/RecommendSubjectResponse';
import { SearchType } from '../../../../types/SearchType';
import { SuggestionResponse } from '../../../../types/SuggestionResponse';
import {
  LandingPageType,
  TopKeywords,
} from '../../../../types/TopKeywordsResponse';
import * as navigationService from '../../../../utils/navigationService';
import pageNavigation from '../../../../utils/pageNavigation';
import { isIOS } from '../../../utils';
import SearchBar from '../SearchBar';
import AutocompleteMenu from './AutocompleteMenu';
import Bubble from './Bubble';
import HotCategories from './HotCategories';
import HotSearchSection from './HotSearchSection';
import RecommendSubjectSection from './RecommendSubjectSection';
import styles, { S } from './styles';
import * as searchRecords from './utils/searchRecords';

interface Props {
  onClose: Function;
  logEvent: Function;
  isFetchingTopKeywords: boolean;
  topKeywords: TopKeywords[];
  recommendSubjects: RecommendSubject[];
  placeholder: TopKeywords | null;
  queryText?: string;
}

interface State {
  [key: string]: any;
  isFetchingHistories: boolean;
  searchHistoriesList: string[];
  suggestionTerm?: string;
  suggestionData?: SuggestionResponse;
}

/**
 * Android & web: View 即可滿足鍵盤拉起時的滑動，用 KeyboardAvoidingView 會有不必要的跳動
 * iOS: View 鍵盤拉起時無法滑動，需使用 KeyboardAvoidingView 行為才會正常
 */
const Container = props => {
  if (isIOS) {
    return <KeyboardAvoidingView behavior="padding" {...props} />;
  }

  return <View {...props} />;
};

export class SearchFormPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchHistoriesList: [],
      isFetchingHistories: true,
      suggestionTerm: props.queryText,
    };
  }

  componentDidMount() {
    if (this.props.queryText) {
      this.handleTextChanged(this.props.queryText);
    }

    this.fetchSearchHistories().catch(error => console.log(error));
  }

  isMount = true;

  componentWillUnmount() {
    this.isMount = false;
  }

  fetchSearchHistories = async () => {
    const searchHistories = await searchRecords.getAll();

    if (this.isMount) {
      this.setState({
        searchHistoriesList: searchHistories ?? [],
        isFetchingHistories: false,
      });
    }
  };

  _onSubmit = async (oriQueryText: string, searchType: SearchType) => {
    const queryText = oriQueryText;

    if (!queryText) {
      const { placeholder } = this.props;

      if (placeholder) {
        this.onTopKeywordSubmit({
          topKeyword: placeholder,
          searchType: SearchType.Placeholder,
        });
      } else {
        this.closeModal();
      }

      return;
    }

    const { currentScreen } = navigationService.screenSubject$.getValue() || {};
    const { routeName } = currentScreen || {};

    const theNavigationFunc =
      routeName === 'SearchResultPage'
        ? navigationService.replace
        : navigationService.navigate;

    await searchRecords.update(queryText);
    await this.fetchSearchHistories();

    const isHistoryWordApplied = searchType === SearchType.History;

    theNavigationFunc('SearchResultPage', {
      queryText,
      isHistoryWordApplied,
    });

    this.logAndCloseModal(queryText, searchType, isHistoryWordApplied);
  };

  logAndCloseModal = (
    text: string,
    searchType: SearchType,
    isHistoryWordApplied?: boolean
  ) => {
    this.props.logEvent({
      logEvent: AnalyticsEvent.Search,
      data: {
        queryText: text,
        isHistoryWordApplied,
        searchType,
      },
    });

    this.closeModal();
  };

  onTopKeywordSubmit = ({
    topKeyword,
    searchType,
  }: {
    topKeyword: TopKeywords;
    searchType: SearchType;
  }) => {
    const { url, title, display_name, landing_page } = topKeyword;

    switch (landing_page) {
      case LandingPageType.SEARCH: {
        pageNavigation(url);

        break;
      }
      case LandingPageType.BESTSELLER: {
        pageNavigation(url, { title: display_name });

        break;
      }
      default: {
        return;
      }
    }

    this.logAndCloseModal(title, searchType);
  };

  onSearchBarSubmit = async (queryText: string) => {
    await this._onSubmit(queryText, SearchType.UserInput);
  };

  onBubblePressed = async (queryText: string) => {
    await this._onSubmit(queryText, SearchType.History);
  };

  onAutoCompleteKeywordSubmit = async (queryText: string) => {
    await this._onSubmit(queryText, SearchType.Autocomplete);
  };

  closeModal = () => {
    this.props.onClose();
  };

  clearHistories = () => {
    searchRecords.clear().catch(error => console.log(error));

    this.setState({
      searchHistoriesList: [],
    });
  };

  renderSearchHistories = (searchHistoriesList: string[]) => {
    if (searchHistoriesList.length === 0) {
      return null;
    }

    return (
      <>
        <S.HistoriesTitleSection>
          <S.HistoriesTitle>{translate('recent-search')}</S.HistoriesTitle>
          <Touchable
            accessibilityRole="button"
            testID="clearHistoriesBtn"
            onPress={this.clearHistories}
          >
            <S.ClearHistoriesBtnText>
              {translate('clear-histories')}
            </S.ClearHistoriesBtnText>
          </Touchable>
        </S.HistoriesTitleSection>
        <S.BubblesSection>
          {searchHistoriesList.map(text => (
            <Bubble
              key={text}
              onPress={this.onBubblePressed}
              bubbleText={text}
            />
          ))}
        </S.BubblesSection>
      </>
    );
  };

  renderBubbleList = () => {
    const { searchHistoriesList } = this.state;
    const { topKeywords, recommendSubjects } = this.props;

    return (
      <>
        {this.renderSearchHistories(searchHistoriesList)}
        {topKeywords.length > 0 && (
          <HotSearchSection
            onHotBubblePress={this.onTopKeywordSubmit}
            hotSearchList={topKeywords}
          />
        )}
        {recommendSubjects?.length > 0 && (
          <RecommendSubjectSection data={recommendSubjects} />
        )}
      </>
    );
  };

  renderAboutSearch = () => {
    return (
      <ScrollView
        style={styles.bubbleListScrollView}
        contentContainerStyle={styles.scrollContent}
        keyboardShouldPersistTaps="handled"
      >
        {this.renderBubbleList()}
        <HotCategories closeSearchModal={this.closeModal} />
      </ScrollView>
    );
  };

  renderContent = () => {
    const { isFetchingHistories, suggestionData } = this.state;
    const { isFetchingTopKeywords } = this.props;

    const hasSuggestion =
      typeof suggestionData !== 'undefined' &&
      Object.values(
        // NOTE: 僅開放目前支援的欄位
        pick(suggestionData, ['categories', 'keywords', 'filters', 'sellers'])
      ).some(data => data.length > 0);

    const showLoading = isFetchingHistories || isFetchingTopKeywords;

    switch (true) {
      case showLoading: {
        return <LoadingView />;
      }
      case hasSuggestion: {
        return (
          <AutocompleteMenu
            data={suggestionData as SuggestionResponse}
            submitKeyword={this.onAutoCompleteKeywordSubmit}
            logAndCloseModal={this.logAndCloseModal}
          />
        );
      }
      default: {
        return this.renderAboutSearch();
      }
    }
  };

  fetchSuggestion = debounce(term => {
    getSuggestion(term)
      .then(({ data }) => {
        const { suggestionTerm } = this.state;

        if (suggestionTerm === term && this.isMount) {
          this.setState({ suggestionData: data });
        }
      })
      .catch(e => {
        console.log('error', e);
      });
  }, 250);

  handleTextChanged = term => {
    if (!term) {
      this.setState({
        suggestionTerm: undefined,
        suggestionData: undefined,
      });

      return;
    }

    this.setState({ suggestionTerm: term }, () => this.fetchSuggestion(term));
  };

  render() {
    return (
      <Container style={styles.viewer}>
        <SearchBar
          placeholder={this.props.placeholder}
          onClose={this.closeModal}
          onSubmit={this.onSearchBarSubmit}
          queryText={this.props.queryText}
          onTextChanged={this.handleTextChanged}
          searchIconClickable
        />
        <S.Container>{this.renderContent()}</S.Container>
      </Container>
    );
  }
}

export default SearchFormPage;
