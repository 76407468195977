import styled from 'styled-components/native';

import { neutralDark } from '../../constants/color';

export const S = {
  Text: styled.Text`
    color: ${neutralDark};
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: line-through;
    margin: 1px 0px 0px 4px;
  `,
};
