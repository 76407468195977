/**
 * 使用時需在外包裝容器下以下 css
 * flex-direction: row;
 * flex-wrap: wrap;
 * width: 100%;
 */

import { FunctionComponent } from 'react';

import isExists from '../../../../../utils/isExists';
import routeUtils from '../../../../../utils/routes';
import { S } from './styles';

interface Props {
  onPress: Function;
  bubbleText: string;
  bubbleColor?: string;
}

const Bubble: FunctionComponent<Props> = (props: Props) => {
  const { bubbleText, bubbleColor } = props;

  if (!isExists(bubbleText) || bubbleText.length === 0) {
    return null;
  }

  const onPress = () => props.onPress(bubbleText);
  const webUrl = routeUtils.compileToPath('SearchResultPage', {
    queryText: bubbleText,
  });

  return (
    <S.BubbleBtn
      accessibilityRole="link"
      href={webUrl}
      onPress={onPress}
      bubbleColor={bubbleColor}
    >
      <S.BubbleText numberOfLines={1} bubbleColor={bubbleColor}>
        {bubbleText}
      </S.BubbleText>
    </S.BubbleBtn>
  );
};

export default Bubble;
