import { createSelector } from 'reselect';

import { CategoriesPosition } from '../../types/Categories';
import { defaultState } from '../reducers/categoriesReducer';
import { RootState } from '../rootReducer';
import { makeCatById } from './catsSelector';

export const categoriesReducerRaw = (state: RootState) =>
  state?.categoriesReducer || defaultState;

// TODO: 改成 id 陣列
export const makeCategorySelector = (position: CategoriesPosition) => {
  return createSelector(
    (state: RootState) => state,
    categoriesReducerRaw,
    (rawData, data) => {
      const categories = data[position].items || [];

      return categories.reduce<any[]>((result, categoryId) => {
        const category = makeCatById(categoryId)(rawData);

        if (category) {
          result.push(category);
        }

        return result;
      }, []);
    }
  );
};

export const makeCategoryIsLoading = (positions?: CategoriesPosition[]) => {
  return createSelector(categoriesReducerRaw, data => {
    const pos = positions ?? Object.values(CategoriesPosition);

    return pos.some(position => data[position].isFetching);
  });
};
