import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getMemberProfileActions } from '../actions/getMemberProfileActions';
import { loginByApple } from '../actions/loginByApple';
import { loginByEmail } from '../actions/loginByEmail';
import { loginByFacebook } from '../actions/loginByFacebook';
import { loginByLine } from '../actions/loginByLine';
import { submitBill } from '../actions/submitBillActions';
import { getMemberProfile } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getMemberProfileActions.success,
  failure: getMemberProfileActions.failure,
};

type OutputActionTypes = ActionType<typeof outputActions>;
type RequestActionTypes = ActionType<typeof getMemberProfileActions.request>;

const getMemberProfileEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(
      getType(getMemberProfileActions.request),
      getType(submitBill.success),
      getType(loginByEmail.success),
      getType(loginByApple.success),
      getType(loginByFacebook.success),
      getType(loginByLine.success)
    ),
    switchMap((_action: RequestActionTypes) => {
      return getMemberProfile()
        .then(response => {
          return getMemberProfileActions.success({
            data: response.data,
          });
        })
        .catch((error: AxiosError) =>
          getMemberProfileActions.failure({ error, customErrorHandling: true })
        );
    })
  );
};

export default getMemberProfileEpic;
