export enum RTBHouseAnalyticsEvent {
  ViewHome = 'rtb_house_enter_home_page',
  ViewSearchResults = 'rtb_house_enter_result_page',
  BeginCheckout = 'rtb_house_enter_process_checkout_page',
  ViewItemList = 'rtb_house_enter_item_page',
  ViewItem = 'rtb_house_enter_product_detail_page',
  ViewCart = 'rtb_house_enter_cart_page',
  ViewBestSeller = 'rtb_house_enter_best_seller_page',
  ViewNewArrivals = 'rtb_house_enter_new_arrivals',
  OrderConfirmation = 'rtb_house_order_confirmation',
}
