import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getPreviewTransactionsActions } from '../actions/getPreviewTransactionsActions';
import { getPreviewTransactions } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getPreviewTransactionsActions.success,
  failure: getPreviewTransactionsActions.failure,
};

type RequestActionType = ActionType<
  typeof getPreviewTransactionsActions.request
>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getPreviewTransactionsEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getPreviewTransactionsActions.request)),
    switchMap((action: RequestActionType) => {
      const { transactionID } = action.payload;

      return getPreviewTransactions(transactionID)
        .then(response =>
          getPreviewTransactionsActions.success({
            transactionID,
            data: response.data,
          })
        )
        .catch((error: AxiosError) =>
          getPreviewTransactionsActions.failure({ transactionID, error })
        );
    })
  );
};

export default getPreviewTransactionsEpic;
