import { AxiosError } from 'axios';
import omit from 'lodash/omit';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { getOnSaleActions } from '../actions/getOnSaleActions';
import { getOnSale } from '../api';
import { RootAction } from '../rootAction';
import { RootState } from '../rootReducer';

const outputActions = {
  success: getOnSaleActions.success,
  failure: getOnSaleActions.failure,
};

type RequestActionTypes = ActionType<typeof getOnSaleActions.request>;
type OutputActionTypes = ActionType<typeof outputActions>;

const getOnSaleEpic = (
  action$: Observable<RootAction>,
  _state$?: Observable<RootState>
): Observable<OutputActionTypes> => {
  return action$.pipe(
    ofType(getType(getOnSaleActions.request)),
    switchMap((action: RequestActionTypes) => {
      const { page, success } = action.payload;
      const requestParams = omit(action.payload, ['loadingType', 'success']);

      return getOnSale(requestParams)
        .then(response => {
          const { data } = response;

          if (typeof success === 'function') {
            success(data.products);
          }

          return getOnSaleActions.success({
            data: response.data,
            page,
          });
        })
        .catch((error: AxiosError) =>
          getOnSaleActions.failure({ error, requestAction: action })
        );
    })
  );
};

export default getOnSaleEpic;
