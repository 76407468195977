import { MMKV } from 'react-native-mmkv';

const instance = new MMKV();

const asAsyncStorage = {
  setItem: (key: string, value: string) => {
    try {
      instance.set(key, value);
    } catch (e) {
      console.log('asAsyncStorage-setItem error', e);
    }

    return Promise.resolve(true);
  },
  getItem: (key: string) => {
    const value = instance.getString(key);

    return Promise.resolve(value);
  },
  removeItem: (key: string) => {
    instance.delete(key);

    return Promise.resolve();
  },
};

export default {
  instance,
  asAsyncStorage,
};
