import { PureComponent, createRef } from 'react';
import { Platform, Text, TextInput, View } from 'react-native';

import IconSearch from '../../../assets/svg/IconSearch';
import { coral, veryBuyPink, white } from '../../../constants/color';
import { testIDs } from '../../../constants/testIDs';
import LinearGradient from '../../../elements/LinearGradient';
import Touchable from '../../../elements/Touchable';
import { translate } from '../../../i18n';
import { TopKeywords } from '../../../types/TopKeywordsResponse';
import { isWebPlatform } from '../../utils';
import styles from './styles';

interface Props {
  placeholder: TopKeywords | null;
  onClose?: Function;
  onSubmit: Function;
  queryText?: string;
  searchIconClickable?: boolean;
  onTextChanged?: (text?: string) => void;
}

interface State {
  queryText: string;
}
export default class SearchBar extends PureComponent<Props, State> {
  static defaultProps: {
    onClose: () => {};
    queryText: '';
  };

  state = {
    queryText: this.props.queryText || '',
    searchIconClickable: false,
  };

  private setWrappedInstance = ref => {
    this._input = ref;
  };

  private _input: any = createRef();

  handleGoBack = () => {
    if (typeof this._input.focus === 'function') {
      this._input.blur();
    }
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  handleChangeText = queryText => {
    this.setState({ queryText });

    if (this.props.onTextChanged) {
      this.props.onTextChanged(queryText);
    }
  };

  handleSubmitSearch = async () => {
    await this.props.onSubmit(this.state.queryText);
    this.setState({ queryText: '' });
  };

  renderQueryInput = () => {
    const selectionColorProp =
      Platform.OS === 'ios'
        ? {
            selectionColor: white,
          }
        : {};

    const { queryText, placeholder, ...otherProps } = this.props;

    return (
      <TextInput
        accessibilityRole="search"
        testID="searchInput"
        {...otherProps}
        clearButtonMode="while-editing"
        {...selectionColorProp}
        style={styles.searchInput}
        ref={this.setWrappedInstance}
        onChangeText={this.handleChangeText}
        placeholder={
          placeholder ? placeholder.title : translate('searchPlaceHolder')
        }
        onSubmitEditing={this.handleSubmitSearch}
        // alpha 0.5
        placeholderTextColor={`${white}80`}
        value={this.state.queryText}
        keyboardType={isWebPlatform ? 'web-search' : 'default'}
        returnKeyType="search"
        autoFocus
      />
    );
  };

  renderQueryInputWithFormForWeb = () => {
    const onSubmit = async e => {
      e.preventDefault();

      try {
        await this.handleSubmitSearch();
      } catch (error) {
        console.log(`submit search error: ${error}`);
      }
    };

    return (
      <form
        onSubmit={onSubmit}
        style={{
          position: 'relative',
          flex: 1,
          height: 33,
        }}
      >
        {this.renderQueryInput()}
      </form>
    );
  };

  renderMiddle = () => {
    const onSubmit = async e => {
      e.preventDefault();

      try {
        await this.handleSubmitSearch();
      } catch (error) {
        console.log(`submit search error: ${error}`);
      }
    };

    return (
      <View style={[styles.middleField, styles.middleFieldWithMarginLeft]}>
        {Platform.OS === 'web'
          ? this.renderQueryInputWithFormForWeb()
          : this.renderQueryInput()}
        <Touchable
          accessibilityRole="button"
          onPress={onSubmit}
          disabled={!this.props.searchIconClickable}
        >
          <IconSearch />
        </Touchable>
      </View>
    );
  };

  render() {
    return (
      <LinearGradient
        colors={[veryBuyPink, coral]}
        style={styles.container}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      >
        {this.renderMiddle()}

        <View style={{ width: 60 }}>
          <Touchable
            testID={testIDs.SearchBar.SEARCH_CANCEL}
            accessibilityRole="button"
            onPress={this.handleGoBack}
            style={styles.cancelBtn}
          >
            <Text numberOfLines={1} style={styles.cancelBtnText}>
              {translate('cancel')}
            </Text>
          </Touchable>
        </View>
      </LinearGradient>
    );
  }
}
