let userContext;

export function setUserContext(userInfo = {}) {
  const specialMapping = {
    MID: 'id',
  };
  const reduceFunction = (prevResult, key) => {
    if (userInfo[key]) {
      const specialMappingKey = specialMapping[key] || key;

      return {
        ...prevResult,
        [specialMappingKey]: userInfo[key],
      };
    }

    return prevResult;
  };
  const info = ['email', 'MID'].reduce(reduceFunction, {});
  const extra = ['role'].reduce(reduceFunction, {});

  userContext = {
    ...info,
    extra,
  };
}

export function getUserContext() {
  return userContext;
}
