import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import withAuth from '../../decorators/withAuth';
import { logAnalyticsEvent } from '../../redux/actions/analyticsLog';
import { actionsList } from '../../redux/rootAction';
import { makeApiCommonErrorDataByKey } from '../../redux/selectors/apiCommonErrorSelector';
import ApiCommonError from './ApiCommonError';

const { clearApiCommonError, clearData } = actionsList;

const mapStateToProps = () => {
  const selector = createStructuredSelector({
    errorCode: makeApiCommonErrorDataByKey('errorCode'),
    contentText: makeApiCommonErrorDataByKey('contentText'),
    positiveText: makeApiCommonErrorDataByKey('positiveText'),
    negativeText: makeApiCommonErrorDataByKey('negativeText'),
  });

  return state => selector(state);
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearApiCommonError,
      clearData,
      logEvent: logAnalyticsEvent,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth(ApiCommonError));
