import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';

import useDynamicLink from '../../hooks/useDynamicLink';
import Config from '../../utils/configs';
import { getIsTW } from '../../utils/countryInfo';
import routeUtils from '../../utils/routes';
import { DOWNLOAD_APP_URLS } from './constants';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IconInfo {
  startTime: string;
  endTime: string;
  source: number;
  navigate?: () => void;
}

export const defaultIcon = {
  get src() {
    if (getIsTW()) {
      return require('./assets/default_float_icon.png');
    }

    return require('./assets/default_float_icon_gc.png');
  },
};
export const defaultNavigate = (
  downloadAppUrl = DOWNLOAD_APP_URLS.ItemPage
) => {
  window.location.href = downloadAppUrl;
};

export const useGetDownloadLinks = ({ fallbackLink, routeName, params }) => {
  const pageUrl = routeUtils.getPathAndParamsFromData(
    routeName,
    params
  ).fullPath;
  const payload = useMemo(() => {
    return {
      link: Config.REACT_APP_WEB_VIEW_URL + pageUrl,
      fallbackLink,
    };
  }, [fallbackLink, pageUrl]);

  const { link } = useDynamicLink(payload);

  return link || fallbackLink;
};

function getEventGroup(): IconInfo[] {
  if (getIsTW()) {
    // 台灣站活動
    return [
      {
        startTime: '2023-03-15 00:00:00',
        endTime: '2025-12-31 23:59:59',
        source: require('./assets/float_icon_for_2023_03_15.png'),
      },
    ];
  }

  // 海外站活動
  return [];
}

// testGroup is for unit test
export function getFloatIconInfo(testGroup?: any[]) {
  const eventGroup: IconInfo[] = testGroup || getEventGroup();

  const nowAt = dayjs().unix();
  const find = eventGroup.find(event => {
    const startTimeAt = dayjs(event.startTime).tz('Asia/Taipei').unix();
    const endTimeAt = dayjs(event.endTime).tz('Asia/Taipei').unix();

    return nowAt >= startTimeAt && nowAt <= endTimeAt;
  });

  return {
    source: find?.source || defaultIcon.src,
    navigate: find?.navigate || defaultNavigate,
  };
}
