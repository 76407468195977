import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { CartContent, CartContentElement } from '../../types/CartContent';

export interface LoadCartContentPayload {
  preserveSelection?: boolean;
  success?: (products: CartContentElement[]) => void;
}

export interface LoadCartContentSuccessPayload {
  result: CartContent;
  preserveSelection: boolean;
  logEvent: AnalyticsEvent;
}

export interface LoadCartContentFailPayload {
  error: Error;
  customErrorHandling?: boolean;
}

export const loadCartContent = createAsyncAction(
  'verybuy/LOAD_CART_CONTENT',
  'verybuy/LOAD_CART_CONTENT_SUCCESS',
  'verybuy/LOAD_CART_CONTENT_FAIL'
)<
  LoadCartContentPayload | void,
  LoadCartContentSuccessPayload,
  LoadCartContentFailPayload
>();
