export function getTextFromFinder(finder: string) {
  const result: { text: string; highlight: boolean }[] = [];

  function pushCommonText(startIndex: number, length: number) {
    result.push({
      text: finder.substr(startIndex, length),
      highlight: false,
    });
  }

  const regex = RegExp(/<b>(.*?)<\/b>/gi);

  let match: RegExpExecArray | null = null;
  let current = 0;

  while ((match = regex.exec(finder))) {
    const commonTextLength = match.index - current;

    // push common text before the match if exists
    if (commonTextLength > 0) {
      pushCommonText(current, commonTextLength);
    }

    // push matches text as bold text
    result.push({ text: match[1], highlight: true });

    // set the current value for next loop
    current = regex.lastIndex;
  }

  const remains = finder.length - current;

  // push remains text as common text if exists
  if (remains > 0) {
    pushCommonText(current, remains);
  }

  return result;
}
