// NOTE: Avoid circular import, because boot/utils uses it
import { Dimensions, PixelRatio, Platform } from 'react-native';

import { maxFontSizeMultiplier } from '../constants/misc';
import { DeviceType } from '../types/Common';

const isApp = /(android)|(ios)/i.test(Platform.OS);
const isServer = typeof window === 'undefined' && !isApp;
const isWebPlatform = !isServer && Platform.OS === 'web';

export const getDeviceType = () => {
  const DIMENSION_WIDTH = Dimensions.get('window').width;

  if (DIMENSION_WIDTH >= 1025) {
    return DeviceType.DESKTOP;
  }

  if (DIMENSION_WIDTH >= 768) {
    return DeviceType.TABLET;
  }

  return DeviceType.MOBILE;
};

export const getFontScale = () =>
  Math.min(PixelRatio.getFontScale(), maxFontSizeMultiplier);

let _cachedScrollbarWidth: number | undefined = undefined;

// ref: https://stackoverflow.com/a/13382873/9636125
export const getScrollbarWidth = () => {
  if (!isWebPlatform) {
    return 0;
  }

  if (typeof _cachedScrollbarWidth !== 'undefined') {
    return _cachedScrollbarWidth;
  }

  // Creating invisible container
  const outer = document.createElement('div');

  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');

  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer);
  _cachedScrollbarWidth = scrollbarWidth;

  return scrollbarWidth;
};
