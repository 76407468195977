import styled from 'styled-components/native';

import { neutralDark } from '../../../../../../constants/color';

export default {
  Title: styled.Text`
    margin: 12px 0;
    color: ${neutralDark};
    font-size: 15px;
    line-height: 21px;
  `,
};
