import { getType } from 'typesafe-actions';

import { CardDatum } from '../../types/DirectPayCard';
import { getPayment } from '../actions/getPaymentActions';
import { postDirectPayCardActions } from '../actions/postDirectPayCardActions';
import { putDirectPayPrimaryCardAction } from '../actions/putDirectPayPrimaryCardActions';
import { actionsList } from '../rootAction';

const { getDirectPayCardActions, deleteDirectPayCardActions } = actionsList;

export default function (
  state: {
    card_data: CardDatum[] | [];
    isFetchingCardList: boolean;
    isSavingCard: boolean;
    isFetchingPaymentMethods: boolean;
  } = {
    card_data: [],
    isFetchingCardList: false,
    isSavingCard: false,
    isFetchingPaymentMethods: false,
  },
  action
) {
  switch (action.type) {
    case getType(deleteDirectPayCardActions.request):
    case getType(putDirectPayPrimaryCardAction.request):
    case getType(getDirectPayCardActions.request): {
      return {
        ...state,
        isFetchingCardList: true,
      };
    }

    case getType(getDirectPayCardActions.success): {
      return {
        ...state,
        isFetchingCardList: false,
        card_data: action.payload.card_data as CardDatum[],
      };
    }

    case getType(deleteDirectPayCardActions.failure):
    case getType(putDirectPayPrimaryCardAction.failure):
    case getType(getDirectPayCardActions.failure): {
      return {
        ...state,
        isFetchingCardList: false,
        error: action.payload.error,
      };
    }
    case getType(postDirectPayCardActions.request): {
      return {
        ...state,
        isSavingCard: true,
      };
    }
    case getType(postDirectPayCardActions.success):
    case getType(postDirectPayCardActions.failure): {
      return {
        ...state,
        isSavingCard: false,
      };
    }

    case getType(getPayment.request): {
      return {
        ...state,
        isFetchingPaymentMethods: true,
      };
    }
    case getType(getPayment.success):
    case getType(getPayment.failure): {
      return {
        ...state,
        isFetchingPaymentMethods: false,
      };
    }

    case getType(putDirectPayPrimaryCardAction.success): {
      const card_id = action.payload.card_id;
      const cardData = state?.card_data as CardDatum[];

      cardData.forEach(cardDatum => {
        cardDatum.is_primary = cardDatum.id === card_id;
      });

      return {
        ...state,
        isFetchingCardList: false,
        card_data: cardData,
      };
    }

    default: {
      return state;
    }
  }
}
