// 參考後端 error code
// https://bitbucket.org/verybuy/vb-src/src/sa/libs/Common/VBErrorCode.php

export enum CommonErrors {
  ERR_LAKE_ARGUMENT = 400,
  ERR_SYSTEM_ERROR = 1000,
  ERR_UNAUTHORIZED_USER = 1003,
  ERR_INVALID_ARGUMENT = 1001,
  ERR_INVALID_PHONE_NUMBER = 1016,
  ERR_NOT_FOUND = 1005,
  ERR_VERIFICATION_CODE_NOT_MATCH = 1017,
  ERR_VERIFICATION_DATA_ALREADY_EXISTS = 1018,
  ERR_REACH_LIMIT = 1019,
}

export enum DBErrors {
  ERR_DB_NOT_FOUND = 2002,
}

export enum MemberErrors {
  ERR_MEMBER_RECAPTCHA_INVALID = 3009,
  ERR_MEMBER_MEMBERS_ONLY = 3002,
  ERR_MEMBER_INCORRECT_EMAIL = 3003,
  ERR_MEMBER_EMAIL_USED = 3004,
  ERR_MEMBER_PASSWORD_INSUFFICIENT_LENGTH = 3005,
  ERR_MEMBER_PASSWORD_INSUFFICIENT_STRENGTH = 3006,
  ERR_MEMBER_BLACK_EMAIL = 3008,
  ERR_MEMBER_NEED_LOGIN_BY_FACEBOOK = 3010,
  ERR_MEMBER_LOVE_QUANTITY_EXCEEDED = 3011,
  ERR_MEMBER_NEED_LOGIN_BY_APPLE = 3014,
  ERR_MEMBER_NEED_LOGIN_BY_FACEBOOK_OR_APPLE = 3015,
  ERR_MEMBER_LOGIN_FAILED = 3016,
  ERR_MEMBER_NEED_LOGIN_BY_LINE = 3020,
  ERR_MEMBER_PASSWORD_INCORRECT = 3021,
}

export enum PaymentErrors {
  ERR_PAYMENT_ERROR = 4000,
  ERR_PAYMENT_NEED_TO_VERIFY_PHONE = 4016,
}

export enum TransactionErrors {
  ERR_TRANSACTION_CHANGE_FAILED_ALREADY_PAID = 5003,
}

export enum EventErrors {
  ERR_EVENT_ERROR = 12000,
  ERR_COUPON_NOT_FOUND = 12001,
  ERR_COUPON_EXPIRED = 12002,
  ERR_COUPON_USED = 12003,
  ERR_COUPON_REDEEMED = 12004,
  ERR_COUPON_CAN_NOT_BE_REDEEM = 12005,
  ERR_EVENT_INVALID_TIME = 12006,
  ERR_COUPON_IS_OVER = 12008,
}

export enum CustomErrors {
  GET_PRIME_RESPONSE_ERROR = 'FE-4000',
}

export type ErrorCode =
  | CommonErrors
  | DBErrors
  | MemberErrors
  | PaymentErrors
  | CustomErrors;
