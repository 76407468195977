import { AxiosResponse } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { LoginOrRegisterMethod } from '../../constants/LoginOrRegisterMethod';
import { MemberRegisterByEmailResponse } from '../../types/Member';

export interface PostRegisterPayload {
  account: string;
  password: string;
  subscribe_edm: boolean;
  fcm_token: string;
  success: Function;
  failure: Function;
  loginMethod: LoginOrRegisterMethod;
  recaptcha_token: string;
}

export interface PostRegisterSuccessPayload {
  result: AxiosResponse<MemberRegisterByEmailResponse['data']>;
  account: string;
  logEvent: AnalyticsEvent;
  method: LoginOrRegisterMethod;
}

export interface PostRegisterFailPayload {
  error: Error;
  account: string;
  logEvent: AnalyticsEvent;
  method: LoginOrRegisterMethod;
}

const postRegisterActions = createAsyncAction(
  'verybuy/POST_REGISTER',
  'verybuy/POST_REGISTER_SUCCESS',
  'verybuy/POST_REGISTER_FAIL'
)<PostRegisterPayload, PostRegisterSuccessPayload, PostRegisterFailPayload>();

export default postRegisterActions;
