export enum IterableEvents {
  Login = 'Login',
  SignUp = 'SignUp',
  AddToCart = 'AddToCart',
  ECommercePurchase = 'Purchase',
  BeginCheckout = 'BeginCheckout',
  Search = 'Search',
  ViewItem = 'ViewItem',
  AddToWishList = 'AddToWishList',
  HomePageClick = 'HomePageClick',
  CouponRedeem = 'CouponRedeem',
  CouponGet = 'CouponGet',
  UpdateCart = 'UpdateCart',
  RecommendList = 'RecommendList',
}
