import AsyncStorage from '@react-native-async-storage/async-storage';
import dayjs, { OpUnitType, QUnitType } from 'dayjs';
import pick from 'lodash/pick';
import { ParsedQuery, parseUrl } from 'query-string';
import safeAwait from 'safe-await';

export const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_campaign',
] as const;

// sbid is for shopback
const ADDITIONAL_KEYS = ['sbid'] as const;

type Additional = {
  [K in typeof ADDITIONAL_KEYS[number]]: string;
} & {
  updatedAt: number;
};
type UTM = typeof UTM_KEYS[number];
type UTMGroup = Partial<Record<UTM, string | string[]>>;
type SavedData = UTMGroup & Additional;

export type PickUTM = Pick<ParsedQuery, UTM>;

export const storageKey = 'utmGroup';

export async function getUTMGroup(): Promise<SavedData> {
  const [error, UTMGroupString] = await safeAwait(
    AsyncStorage.getItem(storageKey)
  );

  if (error) {
    return {} as SavedData;
  }

  return JSON.parse(UTMGroupString || '{}');
}

export function formatUTMGroup(pickedUTM: PickUTM) {
  const formatStr = (s: string) => {
    const MAX_LEN = 100;

    return s.substr(0, MAX_LEN);
  };

  return Object.entries(pickedUTM).reduce<UTMGroup>((acc, [key, value]) => {
    if (!value) {
      return acc;
    }

    return {
      ...acc,
      [key]: Array.isArray(value) ? value.map(formatStr) : formatStr(value),
    };
  }, {});
}

export function getUTMGroupFromURL(url: string) {
  const { query } = parseUrl(url);
  const pickedUTM = pick(query, UTM_KEYS);
  const hasUTM = Object.keys(pickedUTM).length > 0;

  if (hasUTM) {
    return { ...formatUTMGroup(pickedUTM), ...pick(query, ADDITIONAL_KEYS) };
  }

  return null;
}

export async function handleSaveUTMIfExists(url: string) {
  const formattedUTMGroup = getUTMGroupFromURL(url);

  if (formattedUTMGroup) {
    const [, prevUTMGroup] = await safeAwait(getUTMGroup());
    const saveData = {
      ...prevUTMGroup,
      ...formattedUTMGroup,
      updatedAt: Date.now(),
    };

    AsyncStorage.setItem(storageKey, JSON.stringify(saveData)).catch(null);
  }
}

export function checkIsExpired(
  time: number,
  condition: number,
  unit: QUnitType | OpUnitType = 'h'
) {
  return dayjs().diff(time || 0, unit) >= condition;
}

/**
 *
 * @param key
 * @param value '' or undefined will delete the key
 * @returns {}
 */
export async function updateUTMValueByKey(key, value = '') {
  const UTM = await getUTMGroup();
  const hasUTM = Object.keys(UTM).length > 0;
  let saveData = {};

  if (hasUTM) {
    saveData = {
      ...UTM,
    };

    if (value) {
      const override = {
        [key]: value,
      };

      saveData = {
        ...UTM,
        ...override,
      };
    } else {
      delete saveData[key];
    }

    await AsyncStorage.setItem(storageKey, JSON.stringify(saveData)).catch(
      null
    );
  }

  return saveData;
}
