import { AxiosResponse } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { AnalyticsEvent } from '../../constants/AnalyticsEvent';
import { LoginOrRegisterMethod } from '../../constants/LoginOrRegisterMethod';
import { MemberLoginByAppleResponse } from '../../types/Member';
import { AppleRequestResponseFullName } from '../../utils/appleAuth';

export interface LoginByApplePayload {
  identity_token: string;
  auth_code: string;
  full_name: Pick<AppleRequestResponseFullName, 'givenName'> | null;
  success: Function;
  loginMethod: LoginOrRegisterMethod;
  fcm_token?: string;
  invite_code?: string;
}

export interface LoginByAppleSuccessPayload {
  result: AxiosResponse<MemberLoginByAppleResponse['data']>;
  account: string;
  logEvent: AnalyticsEvent;
  method: LoginOrRegisterMethod;
}

export interface LoginByAppleFailPayload {
  error: Error;
}

export const loginByApple = createAsyncAction(
  'verybuy/LOGIN_BY_APPLE',
  'verybuy/LOGIN_BY_APPLE_SUCCESS',
  'verybuy/LOGIN_BY_APPLE_FAIL'
)<LoginByApplePayload, LoginByAppleSuccessPayload, LoginByAppleFailPayload>();
