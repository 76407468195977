import hoistStatics from 'hoist-non-react-statics';
import { ComponentType, PureComponent } from 'react';
import { RefObject, createRef } from 'react';
import { Modal } from 'react-native';

import { isApp, isWebPlatform } from '../../boot/utils';
import * as navigationService from '../../utils/navigationService';
import FullLoadingView from './FullLoadingView';

interface State {
  isVisible: boolean;
  onCloseForTransitionEnd?: Function;
  hasCloseBtn?: boolean; // it's for testing or demo
  horizontalPercent?: number;
  verticalPercent?: number;
  waitForClose?: Promise<any>;
  errorMessage?: string;
  loadingViewStyles?: any;
}

export interface WithLoadingViewProps {
  showFullScreenLoading: (args?: Omit<State, 'isVisible'>) => void;
  closeFullScreenLoading: () => void;
}

export default function withLoadingView<Props = {}>(
  WrappedComponent: ComponentType<Props>
): ComponentType<Omit<Props, keyof WithLoadingViewProps>> {
  class LoadingView extends PureComponent<Props, State> {
    constructor(props) {
      super(props);

      this._loadingModalRef = createRef();
    }

    private defaultState: State = {
      isVisible: false,
      hasCloseBtn: false,
    };

    private readonly _loadingModalRef: RefObject<Modal>;

    state = { ...this.defaultState };

    close = () => {
      this.setState({
        isVisible: false,
      });

      if (isApp) {
        navigationService.refresh({
          panHandlers: {},
        });
      }
    };

    show = (args: State = {} as State) => {
      this.setState({
        ...{ isVisible: true },
        ...args,
      });

      if (isApp) {
        navigationService.refresh({
          panHandlers: null,
        });
      }

      /* Note:
       * modal 在 react-native 和 web 套件的實作方式略有差異
       * 因此屏棄 FullLoadingView(原 LightboxLoading) 原來的 componentDidMount 等 lifecycle 相關的定義
       */
      if (args.waitForClose) {
        args.waitForClose
          .then(() => {
            const currentModal = this._loadingModalRef?.current;

            if (currentModal) {
              this.close();
            }
          })
          .catch(errorMessage => {
            const currentModal = this._loadingModalRef?.current;

            if (currentModal) {
              if (errorMessage) {
                this.setState({
                  errorMessage,
                });
              } else {
                this.close();
              }
            }
          });
      }
    };

    render() {
      const { isVisible, waitForClose, ...restState } = this.state;

      return (
        <>
          <WrappedComponent
            {...this.props}
            closeFullScreenLoading={this.close}
            showFullScreenLoading={this.show}
          />
          <Modal
            ref={this._loadingModalRef}
            hardwareAccelerated
            transparent
            visible={isVisible}
            animationType="fade"
            // https://github.com/react-native-community/react-native-modal#the-modal-is-not-covering-the-entire-screen
            {...(isWebPlatform ? { style: { margin: 0 } } : {})}
          >
            <FullLoadingView
              {...restState}
              closeFullScreenLoading={this.close}
            />
          </Modal>
        </>
      );
    }
  }

  return hoistStatics(LoadingView, WrappedComponent);
}
