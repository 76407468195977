import { createSelector } from 'reselect';

import { RibbonType } from '../../types/ItemCommon';
import { Attributes, UserSubmitItem } from '../../types/UserSubmitItem';
import { userSubmitDetailByIdSelector } from './productResourceByType';

export const makeProductByIdIsInvalidSelector = (id: string) =>
  createSelector(userSubmitDetailByIdSelector, productById => {
    const item = productById?.[id]?.item;

    return (
      item?.is_not_allowed ||
      item?.is_offline ||
      item?.seller?.is_offline ||
      false
    );
  });

export const makeProductIsOutOfStockByIdSelector = (id: string) =>
  createSelector(userSubmitDetailByIdSelector, productById => {
    const item = productById?.[id]?.item;

    return item?.is_offline || item?.seller?.is_offline || false;
  });

export const makeProductIsNotAllowedByIdSelector = (id: string) =>
  createSelector(
    userSubmitDetailByIdSelector,
    productById => productById?.[id]?.item?.is_not_allowed || false
  );

export const makeProductByIdIsFetchingSelector = (id: string) =>
  createSelector(
    userSubmitDetailByIdSelector,
    productById => productById?.[id]?.isFetchingItemDetail || false
  );

export const makeProductByIdByKeySelector = (
  id: string,
  key: string,
  initValue: any
) =>
  createSelector(
    userSubmitDetailByIdSelector,
    productById => productById?.[id]?.[key] || initValue
  );

export const makeProductVendorId = (id: string) =>
  createSelector(makeProductByIdSelector(id), item => {
    return item?.seller?.seller_id || '';
  });

export const makeProductMapByIdSelector = (id: string) => {
  const defaultValue = {};

  return createSelector(
    userSubmitDetailByIdSelector,
    productMap => productMap?.[id] || defaultValue
  );
};

export const makeProductByIdSelector = (id: string) =>
  createSelector(makeProductMapByIdSelector(id), product => {
    const item = product.item;

    if (item) {
      const isDetailed = product.isDetailed || false;

      // Use original price when price_ntd not exist;
      item.min_price_ntd = item.min_price_ntd || item.min_price;
      item.max_price_ntd = item.max_price_ntd || item.max_price;

      item.isDetailed = isDetailed;

      return item;
    }

    return {};
  });

export const makeHasSizeChartImages = (id: string) =>
  createSelector(makeProductByIdSelector(id), item => {
    return (item.size_chart_image_urls || []).length > 0;
  });

export const makeSizeChartImages = (id: string) =>
  createSelector(makeProductByIdSelector(id), item => {
    return (item.size_chart_image_urls || []).map(img => ({
      1: img.sm,
      2: img.md,
      3: img.lg,
    }));
  });

export const makeTitleByIdSelector = (id: string) =>
  createSelector(makeProductByIdSelector(id), item => item.title);

export const makeItemAttributes = (id: string) =>
  createSelector(
    makeProductByIdSelector(id),
    item => (item.attributes || {}) as Attributes
  );

export const makeItemDescVideos = (id: string) =>
  createSelector(makeProductByIdSelector(id), item => item.desc_videos || []);

export const makeItemDescText = (id: string) =>
  createSelector(makeProductByIdSelector(id), item => item.desc_text || '');

export const makeDetailImagesByIdSelector = (id: string) =>
  createSelector(makeProductByIdSelector(id), item => {
    return item.detail_image_urls || [];
  });

export const makeSizeInfoIndex = (id: string) =>
  createSelector(
    makeProductByIdSelector(id),
    makeDetailImagesByIdSelector(id),
    (item, detailImages) => {
      return detailImages.indexOf(item.size_info_image_url);
    }
  );

export const makeMarketIdByIdSelector = (id: string) =>
  createSelector(
    makeProductByIdSelector(id),
    item => item.marketing_product_id
  );

export const makeRibbonsByIdSelector = (id: string) => {
  return createSelector(makeProductByIdSelector(id), (item: UserSubmitItem) => {
    const { ribbons = [], ribbon = {} } = item;
    const filteredRibbons = ribbons.filter(
      ({ type = '' }) => type in RibbonType
    );

    if (filteredRibbons.length > 0) {
      return filteredRibbons;
    }

    const { type = '' } = ribbon;
    const isAllowedType = type in RibbonType;

    return isAllowedType ? [ribbon] : [];
  });
};

export const makeProductByIdIsProductAllowCVSSelector = (id: string) =>
  createSelector(
    userSubmitDetailByIdSelector,
    productById => productById?.[id]?.item?.is_product_allow_cvs || false
  );

export const makeProductIsLovedByIdSelector = (id: string) =>
  createSelector(
    userSubmitDetailByIdSelector,
    productById => productById?.[id]?.item?.is_loved || false
  );

export const makeProductIsFetchingLovesByIdSelector = (id: string) =>
  createSelector(
    userSubmitDetailByIdSelector,
    productById => productById?.[id]?.isFetchingLoves || false
  );

export const makeProductColorAmountByIdSelector = (id: string) =>
  createSelector(
    userSubmitDetailByIdSelector,
    productById => productById?.[id]?.item.color_amount
  );

export const makeVendorNameById = (id: string) => {
  return createSelector(makeProductByIdSelector(id), (item: any) => {
    return item?.seller_customize_name || '';
  });
};

export const makeProductSalesAmountByIdSelector = (id: string) =>
  createSelector(
    userSubmitDetailByIdSelector,
    productById => productById?.[id]?.item.total_sales_quantity
  );
