import { email as validateEmail } from '../../../utils/validate';

export const getIterableUpdateCartPayload = logData => {
  const cartContents = logData?.result?.cart_contents || [];
  const inStockCartContents = cartContents.filter(({ in_stock }) => !!in_stock);
  const logPayload = {
    shoppingCartItems: [] as any,

    // for iterable line ads
    shopCartCount: Math.min(inStockCartContents.length, 5),
  };

  inStockCartContents.map(content => {
    // @ts-ignore
    logPayload.shoppingCartItems.push({
      quantity: content?.amount || 0,
      name: content?.title || 'EMPTY_ITEM_NAME',
      id: content?.marketing_product_id || '',
      price: content?.price || 0,
      item_sku1: content?.size_text || '',
      item_sku2: content?.color_text || '',
      image_url: content?.image_url || '',
      parent_category_id: logData?.parent_category_id,
      child_category_id: logData?.child_category_id,
      tags: logData?.tags,
    });
  });

  return logPayload;
};

export function findValidEmail(...emails) {
  return emails.find(validateEmail);
}
