import { createSelector } from 'reselect';

import { State } from '../reducers/recommendSubjectReducer';
import { RootState } from '../rootReducer';

const reducer = (state: RootState) => state?.recommendSubjectReducer || {};

export const makeRecommendSubjects = () => {
  return createSelector(reducer, (state: State) => {
    return state.data || [];
  });
};

export const makeRecommendSubjectsIsFetching = () => {
  return createSelector(reducer, (state: State) => {
    return state.isFetching || false;
  });
};
