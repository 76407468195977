import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import { actionsList } from '../rootAction';

const { getFreeDeliveryActions } = actionsList;

export interface State {
  isFetching: boolean;
  hasError: boolean;
  error?: Error | null;
  threshold: number | undefined;
}

export const defaultState: State = {
  isFetching: false,
  hasError: false,
  threshold: undefined,
};

export default produce(
  (draft: State, action: ActionType<typeof getFreeDeliveryActions>) => {
    switch (action.type) {
      case getType(getFreeDeliveryActions.request): {
        draft.isFetching = true;
        break;
      }
      case getType(getFreeDeliveryActions.success): {
        const { data } = action.payload;

        draft.isFetching = false;
        draft.hasError = false;
        draft.error = null;
        draft.threshold = data.threshold;
        break;
      }
      case getType(getFreeDeliveryActions.failure): {
        draft.isFetching = false;
        draft.hasError = true;
        draft.error = action.payload.error;
        break;
      }
      default: {
        break;
      }
    }
  },
  defaultState
);
